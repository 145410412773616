export default {
  shipments: {
    shipmentFetchError: 'Failed to load shipment details',
  },
  trackingEvents: {
    shipmentTrackingEventsFetchError: 'Failed to load shipment tracking events',
    shipmentTrackingEventsNotFound: 'There are no tracking events for this shipment yet',
  },
  users: {
    userNotFound: 'User was not found',
    userDeleteSuccess: 'User was deleted successfully',
    invitationNotFound: 'Invitation was not found',
  },
  trackingPage: {
    imagesSizeLimitExceeded: 'You have uploaded more than 5MB of images to your page. '
      + 'Please select smaller images.',
  },
  unknownErrorOccurred: 'Unknown error occurred. Please try again later.',
};
