const colors = [
  '#A1DAB4',
  '#41B6C4',
  '#2C7FB8',
  '#253494',
  '#001219',
  '#ee9b00',
  '#ca6702',
  '#bb3e03',
  '#ae2012',
  '#9b2226',
  '#582f0e',
  '#7f4f24',
  '#936639',
  '#a68a64',
  '#b6ad90',
  '#c2c5aa',
  '#a4ac86',
  '#656d4a',
  '#414833',
  '#333d29',
];

export default colors;
