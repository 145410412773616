const typography = {
  home: {
    title: 'Home',
    subTitle: 'This dashboard shows how many of your shipments are currently in red, amber, or green status.',
  },
  calculatedEvents: {
    title: 'Calculated events',
    subTitle: 'Calculated events give you a view of shipments that may have gone missing, or which have passed their delivery promised date.',
  },
  proactiveAlerting: {
    title: 'Proactive alerting (Beta)',
    subTitle: 'Monitor shipment delivery issues with advanced warnings of a potential delivery exception.',
  },
  shipments: {
    title: 'Shipments',
    subTitle: 'Manage your registered shipments. Select an individual shipment to view or add filters to filter your shipments.',
  },
  trackingPages: {
    title: 'Tracking pages',
    subTitle: 'Manage your tracking pages. Create branded tracking pages to enhance customers’ post-purchase experience.',
  },
  emailStatistics: {
    title: 'Email statistics',
    subTitle: 'View high level email performance statistics and email activity. Change the date range you want to view by selecting the date range dropdown.',
  },
  smartFilterStatistics: {
    title: 'Smart filter statistics',
    subTitle: 'View statistics for average waiting times, for a certain period of time, for email notifications. The period can be changed by using the date pickers below.',
  },
  settingsPage: {
    title: 'Settings',
    subTitle: 'Easily configure your settings for Track.',
  },
  APIKeys: {
    title: 'API keys',
    subTitle: 'Generate API keys to connect your system with Track.',
  },
  carriers: {
    title: 'Carriers',
    subTitle: 'Allows Track to connect your carriers; enabling tracking updates to be received for your shipments.',
  },
  SFTPAccounts: {
    title: 'SFTP accounts',
    subTitle: 'Recommended to register shipments, if you have a large number of shipments to register use the Track SFTP upload facility.',
  },
  shipmentGroupings: {
    title: 'Shipment groupings',
    subTitle: 'Enables you to specify a name for up to three of the metadata keys that you use for your shipment groupings.',
  },
  customiseShipmentStates: {
    title: 'Customise shipment states',
    subTitle: 'Customise your shipment statuses into your own brand tone  of voice (e.g. In transit to `On its way`) in multiple languages.',
  },
  notifications: {
    title: 'Notifications',
    subTitle: 'Keep customers engaged by sending branded communications to your customers informing them of the latest delivery update.',
  },
  notificationConnectors: {
    title: 'Notification connectors',
    subTitle: 'You can set up connectors for your existing Postmark (email) Twilio (SMS) and SendGrid (email) accounts. Alternatively, you can set up Track connector, which enables you to send emails without the need for a third-party service.',
  },
  notificationTemplates: {
    title: 'Notification templates',
    subTitle: 'Enables you to create and edit email templates for your branded emails.',
  },
  notificationSetUp: {
    title: 'Notification set up',
    subTitle: 'This page enables you to set up notifications and edit or remove existing ones.',
  },
  shipmentFilters: {
    title: 'Shipment filters',
    subTitle: 'Allows you to configure the delivery events that get tracked against your shipments. These are required before setting up notifications and webhooks.',
  },
  webhooks: {
    title: 'Webhooks',
    subTitle: 'Use webhooks to trigger communications from your own platform. Receive tracking updates from your shipments by an automated push message.',
  },
  webhookLogs: {
    title: 'Webhook logs',
    subTitle: 'Manage your webhook logs. Select an individual record to view or add filters to filter your data.',
  },
  webhooksPopup: {
    title: 'Add webhook filters',
    subTitle: '',
    // subTitle: 'Filters enable you to refine the webhook logs. You can combine filters if needed.',
  },
  users: {
    title: 'Users',
    subTitle: 'Manage permissions for each Track user.',
  },
  editTrackingPage: {
    title: 'Edit tracking page',
    subTitle: '',
  },
  createTrackingPage: {
    title: 'Create tracking page',
    subTitle: '',
  },
  createNotificationConnection: {
    title: 'Create Notification Connection',
    subTitle: '',
  },
  editNotificationConnection: {
    title: 'Edit Notification Connection',
    subTitle: '',
  },
  createNotification: {
    title: 'Create Notification',
    subTitle: 'This page enables you to set up a notification using one of the notification connections previously configured.',
  },
  editNotification: {
    title: 'Edit Notification',
    subTitle: 'This page enables you to edit a notification previously configured.',
  },
  requestDetails: {
    title: 'Request Details',
    subTitle: 'Here you can view individual request details.',
  },
  customLabels: {
    title: 'Custom Labels',
    subTitle: 'You can choose your own labels for Track shipment states. Unless you specify a language when interacting with Track, English (GB) ones will be used by default.',
  },
  filtersPopupWindow: {
    title: 'Filters',
    subTitle: '',
    // subTitle: 'Filters enable you to refine the data shown in the dashboard. You can combine filters if needed.',
  },
};

export default typography;
