import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { format, parse } from 'date-fns';
import BackButton from 'components/BackButton';
import Spinner from 'components/Spinner';
import Button from 'components/ReactButton';
import { CSSTransition } from 'react-transition-group';
import {
  General, GridEdit, Link as TrackingPageLink, NavigationBar, NavigationBarItems,
} from 'components/TrackingPage';
import { MobileLayoutType, StandardLayoutType } from 'constants/trackingPage/layoutTypes';
import {
  getTrackingPage as getTrackingPageAction,
  getTrackingPages as getTrackingPagesAction,
  updateTrackingPage as updateTrackingPageAction,
} from 'actions/trackingPagesActions';
import { NonPublished, Preview, Published } from 'constants/trackingPage/trackingPageVersions';
import { getTrackingPagePath } from 'helpers/API';
import {
  getAllTouched,
  getRefToErrorElement,
  getServerValidationErrors,
  hasErrors,
  validateNestedForm,
} from 'helpers/validateForm';
import {
  isUrl, maxLength, minArrayLength, minLength, required, validateEach,
} from 'helpers/validate';
import Font from 'components/TrackingPage/Font';
import NavigationBarItemAdd from 'components/TrackingPage/NavigationBarItems/NavigationBarItemAdd';
import errorConstants from 'constants/errors';
import { closePopup as closePopupAction, showPopup as showPopupAction } from 'actions';
import InvalidTrackingRefPageOverride from 'components/TrackingPage/Overrides/InvalidTrackingRef';
import styles from './styles.scss';
import typography from '../../../helpers/appTypography';

class EditTrackingPage extends Component {
  static dateFormat = 'DD.MM.YY';

  static validationRules = {
    title: [required(() => 'Please enter a title for your page. You can change it later if needed.'),
    // eslint-disable-next-line indent
    maxLength(50, () => 'Please enter a shorter title. Page titles should be 50 characters or less.')],
    font: Font.validationRules,
    navigationBar: {
      backgroundColour: [minLength(7), maxLength(7)],
      borderColour: [minLength(7), maxLength(7)],
      font: Font.validationRules,
      rootUrl: [isUrl(), maxLength(400, () => 'Please enter a shorter URL. URLs should be 400 characters or less.')],
      navigationBarItems: [validateEach(NavigationBarItemAdd.validationRules)],
    },
    layouts: [validateEach({ rows: [minArrayLength(1, () => 'Please add a row.')] })],
  };

  publishButton = React.createRef();

  innerRefs = {
    title: React.createRef(),
    font: {
      size: React.createRef(),
    },
    navigationBar: {
      rootUrl: React.createRef(),
      font: {
        size: React.createRef(),
      },
      navigationBarItems: Array.from({ length: NavigationBarItems.MaxItems }, () => ({
        text: React.createRef(),
        redirectionUrl: React.createRef(),
      })),
    },
    layouts: Array.from({ length: 2 }, () => ({
      rows: React.createRef(),
    })),
  };

  constructor(props) {
    super(props);
    this.state = {
      trackingPageData: null,
      publishSuccess: false,
      saveSuccess: false,
      touched: {},
      errors: {},
      invalidTrackingRefPageOverride: null,
      hasMadeChanges: false,
    };
  }

  componentDidMount() {
    this.loadTrackingPage(NonPublished);
    this.props.getTrackingPages();
  }

  get desktopLayoutIndex() {
    return this.state.trackingPageData.layouts.findIndex(x => x.layoutType === StandardLayoutType);
  }

  get mobileLayoutIndex() {
    return this.state.trackingPageData.layouts.findIndex(x => x.layoutType === MobileLayoutType);
  }

  getMergedErrors = () => {
    const serverValidationErrors = getServerValidationErrors(this.props.errorResponse);
    return { ...this.state.errors, ...serverValidationErrors };
  };

  loadTrackingPage = (version) => {
    const { match: { params: { name } } } = this.props;
    this.props.getTrackingPage(name, version)
      .then(() => this.setState({
        invalidTrackingRefPageOverride: this.props.invalidTrackingPageOverrideName,
        trackingPageData: this.props.trackingPageData,
        errors: validateNestedForm(this.props.trackingPageData, EditTrackingPage.validationRules),
      }));
  };

  handleChange = (field, value) => {
    this.setState((state) => {
      const trackingPageData = {
        ...state.trackingPageData,
        [field]: value,
      };
      const errors = validateNestedForm(trackingPageData, EditTrackingPage.validationRules);
      return {
        trackingPageData,
        errors,
        hasMadeChanges: true,
      };
    });
  };

  handleTouch = (field, value) => {
    this.setState(state => ({
      touched: {
        ...state.touched,
        [field]: value,
      },
    }));
  };

  animateSaveButton = () => {
    this.setState({ saveSuccess: true, hasMadeChanges: false });
    setTimeout(() => this.setState({ saveSuccess: false }), 1000);
  };

  updatePage = (trackingPageName, version, trackingPageData, invalidTrackingRefPageOverride) => {
    const publishedOn = version === Published
      ? Math.round(parse(new Date()).getTime() / 1000)
      : null;

    this.props.updateTrackingPage(trackingPageName, version, publishedOn, trackingPageData, invalidTrackingRefPageOverride)
      .then(() => {
        if (version === Preview) window.open(getTrackingPagePath(trackingPageName, version, true));
        if (version === Published) this.setState({ publishSuccess: true, hasMadeChanges: false });
        if (version === NonPublished) this.animateSaveButton();
      })
      .catch((response) => {
        if (response.status === 413) {
          this.props.showPopup({
            title: 'Error',
            description: errorConstants.trackingPage.imagesSizeLimitExceeded,
          });
        } else if (response.status !== 400) {
          // Show an error popup. 400s are validation errors and they are handled in getServerErrors() method
          this.props.showPopup({
            title: 'Error',
            description: errorConstants.unknownErrorOccurred,
          });
        }
      });
  };

  handleSubmit = (version) => {
    const {
      trackingPageData,
      errors,
      invalidTrackingRefPageOverride,
    } = this.state;
    const {
      trackingPageName,
    } = this.props;
    if (!hasErrors(errors)) {
      this.updatePage(trackingPageName, version, trackingPageData, invalidTrackingRefPageOverride);
    } else {
      const ref = getRefToErrorElement(errors, this.innerRefs);
      ref.current.focus();

      const touched = getAllTouched(trackingPageData, EditTrackingPage.validationRules);
      this.setState({ touched });
    }
  };

  handleRevert = () => {
    const {
      publishedOn,
      showPopup,
      closePopup,
    } = this.props;
    if (publishedOn) {
      showPopup({
        title: 'Are you sure?',
        description: 'Any unsaved changes will be lost.',
        onAccept: () => {
          this.loadTrackingPage(Published);
          closePopup();
        },
      });
    }
  };

  handleInvalidTrackingRefPageOverrideChange = (name) => {
    this.setState({
      invalidTrackingRefPageOverride: name !== 'None' ? name : '',
    });
  }

  handleBack = () => {
    const {
      history,
      showPopup,
      closePopup,
    } = this.props;

    if (this.state.hasMadeChanges) {
      showPopup({
        title: 'Are you sure?',
        description: 'Any unsaved changes will be lost.',
        onAccept: () => {
          history.push('/tracking-pages');
          closePopup();
        },
      });
    } else {
      history.push('/tracking-pages');
    }
  }

  render() {
    const {
      isLoading,
      trackingPageName,
      publishedOn,
      createdOn,
      trackingPagesList,
    } = this.props;
    const {
      trackingPageData,
      touched,
      publishSuccess,
      saveSuccess,
    } = this.state;
    const errors = this.getMergedErrors();
    if (!trackingPageData) return <Spinner />;

    return (
      <div>
        {isLoading ? <Spinner /> : null}
        <BackButton onBack={() => this.handleBack()} />
        <div>
          <h1 className="h2" style={{ margin: 0 }}>{typography.editTrackingPage.title}</h1>
        </div>
        <div>
          {
            publishedOn
              ? (
                <p>Status: Published <br />
                  Last published on: {format(new Date(publishedOn * 1000), EditTrackingPage.dateFormat)}
                </p>
              )
              : (
                <p>Status: Saved <br />
                  Last saved on: {format(new Date(createdOn * 1000), EditTrackingPage.dateFormat)}
                </p>
              )
          }
        </div>
        <div className={styles.pageButtonsEdit}>
          <div style={{ visibility: 'hidden' }}>&nbsp</div>
          <div>
            <Button
              disabled={!publishedOn}
              className={styles.button}
              theme="grey"
              onClick={() => this.handleRevert()}
            >
              Revert
            </Button>
          </div>
          <div>
            <Button
              // disabled={!publishedOn}
              className={styles.button}
              onClick={() => this.handleSubmit(Preview)}
              theme="grey"
            >
              Preview
            </Button>
          </div>
          <div>
            <Button
              // disabled={!publishedOn}
              className={styles.button}
              theme="grey"
              onClick={() => this.handleSubmit(NonPublished)}
            >
              {saveSuccess ? 'Saved' : 'Save'}
            </Button>
          </div>
          <CSSTransition
            in={publishSuccess}
            timeout={{ enter: 1000 }}
            classNames={{ enterActive: styles.publishButtonContainerEnterActive }}
            onEntered={() => this.setState({ publishSuccess: false })}
          >
            <Button
              ref={this.publishButton}
              // disabled={!publishedOn}
              className={styles.publishBtn}
              theme="grey"
              onClick={() => this.handleSubmit(Published)}
              onEntered={() => this.setState({ publishSuccess: false })}
            >
              {publishSuccess ? 'Published' : 'Publish'}
            </Button>
          </CSSTransition>
        </div>
        <div className="container__items-large mt-5">
          <div className="container__header-large">
            <TrackingPageLink pageName={trackingPageName} />
          </div>
          <div>
            <General
              onChange={this.handleChange}
              onTouch={this.handleTouch}
              name={trackingPageName}
              title={trackingPageData.title}
              icon={trackingPageData.base64FavIcon}
              font={trackingPageData.font}
              isCarrierLinkActive={trackingPageData.isCarrierLinkActive}
              isCarrierLinkActiveToggle={() => this.handleChange('isCarrierLinkActive', !trackingPageData.isCarrierLinkActive)}
              errors={errors}
              touched={touched}
              innerRefs={this.innerRefs}
              noRename
            />
          </div>
          <div>
            <NavigationBar
              onChange={e => this.handleChange('navigationBar', e)}
              onTouch={e => this.handleTouch('navigationBar', e)}
              value={trackingPageData.navigationBar}
              errors={errors.navigationBar}
              touched={touched.navigationBar}
              innerRefs={this.innerRefs.navigationBar}
            />
          </div>
          <div>
            <NavigationBarItems
              items={trackingPageData.navigationBar.navigationBarItems}
              errors={errors.navigationBar && errors.navigationBar.navigationBarItems}
              touched={touched.navigationBar && touched.navigationBar.navigationBarItems}
              onChange={e => this.handleChange('navigationBar', {
                ...trackingPageData.navigationBar,
                navigationBarItems: e,
              })}
              onTouch={e => this.handleTouch('navigationBar', { ...touched.navigationBar, navigationBarItems: e })}
              innerRefs={this.innerRefs.navigationBar.navigationBarItems}
            />
          </div>
          <div>
            <GridEdit
              name="Desktop"
              onChange={this.handleChange}
              globalFont={trackingPageData.font}
              layouts={trackingPageData.layouts}
              layoutType={StandardLayoutType}
              errors={errors.layouts && errors.layouts[this.desktopLayoutIndex]}
              innerRefs={this.innerRefs.layouts && this.innerRefs.layouts[this.desktopLayoutIndex]}
            />
          </div>
          <div>
            <GridEdit
              name="Mobile"
              onChange={this.handleChange}
              globalFont={trackingPageData.font}
              layouts={trackingPageData.layouts}
              layoutType={MobileLayoutType}
              errors={errors.layouts && errors.layouts[this.mobileLayoutIndex]}
              innerRefs={this.innerRefs.layouts && this.innerRefs.layouts[this.mobileLayoutIndex]}
            />
          </div>
          <div>
            {trackingPagesList
              && (
                <InvalidTrackingRefPageOverride
                  trackingPagesList={[...trackingPagesList].filter(x => x.trackingPageName !== trackingPageName)}
                  onChange={this.handleInvalidTrackingRefPageOverrideChange}
                  selectedPage={this.state.invalidTrackingRefPageOverride}
                />
              )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  editTrackingPage: {
    isLoading,
    trackingPageName,
    trackingPageData,
    createdOn,
    publishedOn,
    version,
    errorResponse,
    invalidTrackingPageOverrideName,
  },
  trackingPages: {
    trackingPagesList,
  },
}) => ({
  isLoading,
  trackingPageName,
  trackingPageData,
  createdOn,
  publishedOn,
  version,
  errorResponse,
  invalidTrackingPageOverrideName,
  trackingPagesList,
});

const mapDispatchToProps = dispatch => ({
  getTrackingPage: (trackingPageName, version) => dispatch(getTrackingPageAction(trackingPageName, version)),
  updateTrackingPage: (trackingPageName, version, publishedOn, trackingPageData, invalidTrackingRefPageOverride) => (
    dispatch(updateTrackingPageAction(trackingPageName, version, publishedOn, trackingPageData, invalidTrackingRefPageOverride))),
  showPopup: config => dispatch(showPopupAction(config)),
  closePopup: () => dispatch(closePopupAction()),
  getTrackingPages: () => dispatch(getTrackingPagesAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditTrackingPage));
