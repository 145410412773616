import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import LoginFormButton from 'components/RegistrationButton/LoginFormButton';
import FormErrors from 'components/FormErrors';
import {
  maxLength,
  minLength,
  compareValues,
} from 'helpers/validate';
import { validateForm } from 'helpers/validateForm';
import {
  resetPasswordToNewPassword as resetPasswordToNewPasswordAction,
} from 'actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './forgot-password.scss';
import AuthenticationInput from '../../components/AuthenticationInput/AuthenticationInput';

class ForgotPasswordNewPassword extends React.Component {
  validationRules = {
    password: [minLength(6, () => 'Password must be between 6 and 100 characters'), maxLength(100, () => 'Password must be between 6 and 100 characters')],
    confirmPassword: [compareValues('Password', 'Confirm password', null, () => this.state.password)],
  };

  static propTypes = {
    onResetPasswordToNewPassword: propTypes.func.isRequired,
    token: propTypes.string.isRequired,
  };

  static defaultProps = {
  };

  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
    };
  }

  handleOnChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const errors = validateForm(this.validationRules, this.state);
    if (errors.hasErrors) {
      this.setState({ errors });
      return;
    }
    this.setState({ isLoading: true, errors: {} });
    this.props.onResetPasswordToNewPassword(this.props.token, this.state.password)
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false, errors: e });
        return Promise.resolve();
      });
  };

  render() {
    const {
      isLoading,
      errors,
      password,
      confirmPassword,
    } = this.state;
    return (
      <div className={styles.forgotPasswordForm}>
        <div className={styles.registrationPageInner}>
          <div className={styles.welcome}>
            <h1 className={`${styles.welcomeHeader}`}>Create new password</h1>
          </div>

          <div className={styles.formErrorContainer}>
            <FormErrors errors={errors} />
          </div>

          <form onSubmit={this.handleSubmit}>

            <div className={`${styles.inputContainer} ${errors && errors.password ? styles.inputErrorContainer : ''}`}>
              <AuthenticationInput
                label="New password"
                type="password"
                value={password}
                name="password"
                onChange={this.handleOnChange}
                error={errors && errors.password && 'Password must be between 6 and 100 characters'}
              />
            </div>

            <div className={`${styles.inputContainer} ${errors && errors.confirmPassword ? styles.inputErrorContainer : ''}`}>
              <AuthenticationInput
                label="Confirm new password"
                type="password"
                value={confirmPassword}
                name="confirmPassword"
                onChange={this.handleOnChange}
                error={errors && errors.confirmPassword && 'Both passwords must match'}
              />
            </div>
            <LoginFormButton
              type="submit"
              onClick={this.handleSubmit}
              className={classnames(styles.button, { [styles.buttonDisabled]: isLoading })}
              disabled={isLoading}
            >
              {isLoading && <CircularProgress thickness={8} color="inherit" size={40} variant="indeterminate" className={styles.loadingSpinner} />}
              {!isLoading && 'Reset password'}
            </LoginFormButton>
          </form>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onResetPasswordToNewPassword: (token, password) => dispatch(resetPasswordToNewPasswordAction(token, password)),
});

export default connect(null, mapDispatchToProps)(ForgotPasswordNewPassword);
