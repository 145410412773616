import React from 'react';
import { Switch } from 'react-router';
import AuthRoute from 'routes/Auth/AuthRoute';
import resources from 'constants/resources';
import TrackingPageList from './TrackingPagesList';
import CreateTrackingPage from './CreateTrackingPage';
import EditTrackingPage from './EditTrackingPage';

function TrackingPages() {
  return (
    <Switch>
      <AuthRoute exact path="/tracking-pages/" component={TrackingPageList} resource={resources.trackingPages.read} />
      <AuthRoute exact path="/tracking-pages/create" component={CreateTrackingPage} resource={resources.trackingPages.edit} />
      <AuthRoute exact path="/tracking-pages/edit/:name" component={EditTrackingPage} resource={resources.trackingPages.edit} />
    </Switch>
  );
}

export default TrackingPages;
