import React, { Component } from 'react';
import { connect } from 'react-redux';
import Svg from 'components/Svg';
import { event as gaEvent } from 'react-ga';
import { bindActionCreators } from 'redux';
import ColouredDot from 'components/ColouredDot';
import {
  clearEditCustomStateLabel as clearEditCustomStateLabelAction,
  saveEditCustomStateLabel as saveEditCustomStateLabelAction,
  setEditCustomStateLabel as setEditCustomStateLabelAction,
} from 'actions/customStateLabelsActions';
import CardShell from 'components/CardShell';
import DualButtons from 'components/DualButtons';
import InputWithValidation from 'components/InputWithValidation';

import cx from 'classnames';
import styles from './customStateLabels.scss';
import typography from '../../../helpers/appTypography';

export class CEditCustomStateLabel extends Component {
    getCustomisationStateName = (code) => {
      const customisation = this.props.editCustomStateLabel.customStateLabel?.customisations
        .find(x => x.localeCode.toLowerCase() === code.toLowerCase());
      return customisation == null ? '' : customisation?.stateName;
    };

    getCategroryName = (category) => {
      switch (category) {
        case 'Red':
          return 'Critical';
        case 'Amber':
          return 'Warnings';
        case 'Green':
          return 'All Good';
        default:
          return '';
      }
    };

    handleCancelClick = () => {
      gaEvent({
        category: 'CustomStaterLabel',
        action: 'Cancel edit',
      });
      this.props.actions.clearEditCustomStateLabel();
    };

    handleSaveClick = () => {
      gaEvent({
        category: 'CustomStaterLabel',
        action: 'Save edits',
      });
      this.props.actions.saveEditCustomStateLabel(this.props.editCustomStateLabel.customStateLabel);
    };

    handleChange = (locale, value) => {
      gaEvent({
        category: 'CustomStaterLabel',
        action: 'Editing label field',
      });

      let { customisations } = this.props.editCustomStateLabel.customStateLabel;

      const customisation = customisations.find(x => x.localeCode.toLowerCase() === locale.code.toLowerCase());

      if (customisation == null) {
        customisations.push({
          id: 0,
          localeCode: locale.code,
          stateName: value,
        });
      } else {
        customisations = customisations.map(x => (x.localeCode.toLowerCase() === locale.code.toLowerCase()
          ? { ...customisation, stateName: value }
          : x));
      }

      const editCustomStateLabel = {
        ...this.editCustomStateLabel,
        customStateLabel: {
          ...this.props.editCustomStateLabel.customStateLabel,
          customisations,
        },
      };

      this.props.actions.setEditCustomStateLabel(editCustomStateLabel);
    };

    render() {
      const { editCustomStateLabel, locales } = this.props;
      return (
        <div>
          <div>
            <h1 className="h2" style={{ margin: 0 }}>{typography.customLabels.title}</h1>
            <div style={{ margin: '0 0 20px 0' }}>{typography.customLabels.subTitle}</div>
          </div>
          <div className="container__cards-large">
            <CardShell>
              <div className={cx(styles.editCardInner)}>
                <h4
                  className="mb-0"
                >
                  <span className={styles.lighth4}>REACT State: </span>
                  {editCustomStateLabel.customStateLabel?.stateName}
                </h4>
                <h4 className="mt-2 subtitle1">
                  <span className={styles.lighth4}>Classed as:</span>
                  <span
                    className={styles.colouredDot}
                  >
                    <ColouredDot
                      theme={editCustomStateLabel.customStateLabel?.category.toLowerCase()}
                      medium
                    />
                  </span>
                  {this.getCategroryName(editCustomStateLabel.customStateLabel?.category)}
                </h4>
                <p className="mb-5">
                  What this state typically means:<br />{editCustomStateLabel.customStateLabel?.description}
                </p>
                {locales.map(x => (
                  <div key={x.code}>
                    <div className={styles.labelInputHolder}>
                      <p className={styles.label}>{x.name}</p>
                      <InputWithValidation
                        onChange={e => this.handleChange(x, e.target.value)}
                        value={this.getCustomisationStateName(x.code)}
                        // label="Name your connection"
                        placeholder="Enter your custom label"
                        className={styles.labelInput}
                        maxLength="50"
                      />
                      <button
                        type="button"
                        name="clear"
                        disabled={this.getCustomisationStateName(x.code) === ''}
                        onClick={() => {
                          gaEvent({
                            category: 'CustomStaterLabel',
                            action: `Clear field ${x.name}`,
                          });
                          this.handleChange(x, '');
                        }}
                        className={styles.labelClear}
                      >
                        {this.getCustomisationStateName(x.code) && <Svg name="circleXmarkSolid" />}
                      </button>
                    </div>
                  </div>
                ))}
                <div className={styles.errorLabel}>{editCustomStateLabel.error}</div>
              </div>
              <DualButtons
                rightFunction={this.handleSaveClick}
                rightText="Save"
                leftFunction={this.handleCancelClick}
                leftText="Cancel"
                theme="rightPink"
              />
            </CardShell>
          </div>
        </div>
      );
    }
}

const mapStateToProps = ({ editCustomStateLabel, locales }) => ({
  editCustomStateLabel,
  locales,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    saveEditCustomStateLabel: saveEditCustomStateLabelAction,
    setEditCustomStateLabel: setEditCustomStateLabelAction,
    clearEditCustomStateLabel: clearEditCustomStateLabelAction,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CEditCustomStateLabel);
