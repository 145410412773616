import React, { Component } from 'react';
import { connect } from 'react-redux';
import { event as gaEvent } from 'react-ga';
import { withRouter } from 'react-router-dom';
import {
  getCreateConfiguration as getCreateConfigurationAction,
} from 'actions';
import styles from './styles.scss';

export class CTrackingService extends Component {
  handleConfigureClick = () => {
    gaEvent({
      category: 'CarrierConnector',
      action: 'Create configuration',
    });
    this.props.history.push(`/settings/carrier-connectors/create/${this.props.trackingService.trackingServiceId}`);
  };

  render() {
    return (
      <div className={styles.container}>
        <h3 className={`subtitle1 textCenter ${styles.cardTitle}`}>{this.props.trackingService.carrier}</h3>
        <p className={`textCenter ${styles.cardCopy}`}>{this.props.trackingService.carrierIntegrationName}</p>
        <div className={styles.logoHolder}>
          <img
            className={styles.logo}
            src={this.props.trackingService.carrierLogoUrl}
            alt=""
          />
        </div>
        <div>
          <button
            type="button"
            name="create"
            onClick={this.handleConfigureClick}
            className={styles.carrierConnectorButton}
          >Connect
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getCreateConfiguration: id => dispatch(getCreateConfigurationAction(id)),
});

export default withRouter(connect(state => ({
  editTrackingService: state.editTrackingService,
}), mapDispatchToProps)(CTrackingService));
