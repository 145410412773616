/* eslint-disable max-len */
import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import AuthRoute from 'routes/Auth/AuthRoute';
import resources from 'constants/resources';
import ShipmentMetadataGroupings from 'routes/Settings/ShipmentMetadataGroupings/ShipmentMetadataGroupings';
import ApiKeys from './ApiKeys';
import ShipmentFilters from './ShipmentFilters';
import Webhooks from './Webhooks';
import TrackingServices from './TrackingServices';
import CustomStateLabels from './CustomStateLabels';
import SftpAccount from './SftpAccount';
import Notifications from './Notifications';
import RegisterShipmentWebhooks from './RegisterShipmentWebhooks';
import SettingsSummaryPage from '../../components/SettingsSummaryPage/SettingsSummaryPage';
import TrackingPageList from './TrackingPages/TrackingPagesList';
import CreateTrackingPage from './TrackingPages/CreateTrackingPage';
import EditTrackingPage from './TrackingPages/EditTrackingPage';
import Users from '../Users/index';

export default () => (
  <Switch>
    <Route exact path="/settings" component={SettingsSummaryPage} />
    <Route path="/settings/users" component={Users} />
    <AuthRoute path="/settings/api-keys" component={ApiKeys} resource={resources.apiKeys.read} />
    <AuthRoute path="/settings/shipment-filters" component={ShipmentFilters} resource={resources.shipmentFilters.read} />
    <AuthRoute path="/settings/webhooks" component={Webhooks} resource={resources.webhooks.read} />
    <AuthRoute path="/settings/carrier-connectors" component={TrackingServices} resource={resources.carrierConnectors.read} />
    <AuthRoute path="/settings/custom-state-labels" component={CustomStateLabels} resource={resources.customStateLabels.read} />
    <AuthRoute path="/settings/sftp-account" component={SftpAccount} resource={resources.sftpAccount.read} />

    <AuthRoute exact path="/tracking-pages/" component={TrackingPageList} resource={resources.trackingPages.read} />
    <AuthRoute exact path="/tracking-pages/create" component={CreateTrackingPage} resource={resources.trackingPages.edit} />
    <AuthRoute exact path="/tracking-pages/edit/:name" component={EditTrackingPage} resource={resources.trackingPages.edit} />

    <AuthRoute path="/settings/notification-centre" component={Notifications} resource={resources.notificationCentre.read} />
    <AuthRoute path="/settings/register-shipment-webhooks" component={RegisterShipmentWebhooks} resource={resources.emailShipmentRegistration.read} />
    <AuthRoute path="/settings/shipment-metadata-groupings" component={ShipmentMetadataGroupings} resource={resources.shipmentMetadataGroupings.edit} />
    <Redirect exact from="/settings" to="/settings/api-keys" />
    <Route render={() => 'not found'} />
  </Switch>
);
