import React from 'react';

/* eslint-disable */
export default props => (
    <svg id='webhookSymbol' width='84' height='78' viewBox='0 0 84 78' xmlns='http://www.w3.org/2000/svg'>
    <g id='Create-Webhook-Create' fill='none' fillRule='evenodd'>
        <g id='07---Create-new---JWT' transform='translate(-357 -284)'>
            <g id='API-key-1-Copy-3' transform='translate(255 213)'>
                <g id='Webhook-simbol' transform='translate(104 55)'>
                    <circle id='Oval-4' fill='#FF055E' cx='15.5' cy='34.5' r='6.5' />
                    <path d='M26.1988673,21.6295795 C23.430028,19.3625474 19.8759629,18 16,18 C7.163444,18 0,25.0820412 0,33.8181818 C0,42.5543224 7.163444,49.6363636 16,49.6363636 C18.8314168,49.6363636 21.491056,48.9092538 23.7982997,47.6335997 L40,76'
                    id='Oval-4' stroke='#FF055E' strokeWidth='3.15' strokeLinecap='round' />
                    <path d='M57.313604,6.45008754 C54.5447647,4.18305547 50.9906997,2.82050808 47.1147367,2.82050808 C38.2781807,2.82050808 31.1147367,9.90254931 31.1147367,18.6386899 C31.1147367,27.3748305 38.2781807,34.4568717 47.1147367,34.4568717 C49.9461535,34.4568717 52.6057927,33.7297619 54.9130364,32.4541078 L71.1147367,60.8205081'
                    id='Oval-4-Copy-4' stroke='#FF055E' strokeWidth='3.15' strokeLinecap='round'
                    transform='rotate(120 51.115 31.82)' />
                    <path d='M55.313604,40.4500875 C52.5447647,38.1830555 48.9906997,36.8205081 45.1147367,36.8205081 C36.2781807,36.8205081 29.1147367,43.9025493 29.1147367,52.6386899 C29.1147367,61.3748305 36.2781807,68.4568717 45.1147367,68.4568717 C47.9461535,68.4568717 50.6057927,67.7297619 52.9130364,66.4541078 L69.1147367,94.8205081'
                    id='Oval-4-Copy-3' stroke='#FF055E' strokeWidth='3.15' strokeLinecap='round'
                    transform='rotate(-120 49.115 65.82)' />
                    <circle id='Oval-4-Copy' fill='#FF055E' cx='63.5' cy='34.5' r='6.5' />
                    <circle id='Oval-4-Copy-2' fill='#FF055E' cx='39.5' cy='76.5' r='6.5'
                    />
                </g>
            </g>
        </g>
    </g>
</svg>
)
/* eslint-enable */
