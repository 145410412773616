import React, { Component } from 'react';
import Svg from 'components/Svg';
import { reactLocalStorage } from 'reactjs-localstorage';
import styles from './styles.scss';
import trimLongNames from '../../helpers/trimLongNames';

class LocalStorageTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      informationalText: reactLocalStorage.getObject(this.props.localStorageName) === true,
    };
  }

  render() {
    const {
      pageTitle,
      children,
      localStorageName,
    } = this.props;

    return (
      <div>
        <h1 className="h2" title={pageTitle} style={{ marginTop: 0 }}>
          {trimLongNames(pageTitle, true, false, 40, 40, 40)}
          <button
            type="button"
            className={this.state.informationalText ? styles.circleInfoLightButton : styles.circleInfoLightButtonActive}
            onClick={() => this.setState(prevState => ({ informationalText: !prevState.informationalText }))}
            aria-label="Show info button"
          >
            <Svg name="circleInfoLight" className={styles.infoButtonStyle} />
          </button>
        </h1>
        {!this.state.informationalText && (
          <div>
            {children}
          </div>
        )}
        <span className="displayNone">{reactLocalStorage.setObject(localStorageName, true)}</span>
      </div>
    );
  }
}

export default LocalStorageTitle;
