const settingsDescription = [
  {
    gettingStarted: [
      {
        svg: 'rightLeft',
        title: 'SFTP accounts',
        description: 'Recommended to register shipments, if you have a large number of shipments to register use the Track SFTP upload facility.',
        navLink: '/settings/sftp-account',
      },
      {
        svg: 'shipments',
        title: 'Carriers',
        description: 'Allows Track to connect your carriers; enabling tracking updates to be received for your shipments.',
        navLink: '/settings/carrier-connectors',
      },
      {
        svg: 'tag',
        title: 'Customise shipment states',
        description: 'Customise your shipment statuses into your own brand tone  of voice (e.g. In transit to `On its way`) in multiple languages.',
        navLink: '/settings/custom-state-labels',
      },
      {
        svg: 'code',
        title: 'API keys',
        description: 'Generate API keys to connect your system with Track.',
        navLink: '/settings/api-keys',
      },
      {
        svg: 'largeGroup',
        title: 'Shipment groupings',
        description: 'Enables you to specify a name for up to three of the metadata keys that you use for your shipment groupings.',
        navLink: '/settings/shipment-metadata-groupings',
      },
    ],
    notificationSettings: [
      {
        svg: 'filterSettings',
        title: 'Shipment filters',
        description: 'Allows you to configure the delivery events that get tracked against your shipments. These are required before setting up notifications and webhooks.',
        navLink: '/settings/shipment-filters',
      },
      {
        svg: 'bell',
        title: 'Notifications',
        description: 'Keep customers engaged by sending branded communications to your customers informing them of the latest delivery update.',
        navLink: '/settings/notification-centre',
      },
      {
        svg: 'webhooksNew',
        title: 'Webhooks',
        description: 'Use webhooks to trigger communications from your own platform. Receive tracking updates from your shipments by an automated push message.',
        navLink: '/settings/webhooks',
      },
    ],
    accountSettings: [
      {
        svg: 'users',
        title: 'Users',
        description: 'Manage permissions for each Track user.',
        navLink: '/settings/users',
      },
    ],
  },
];

export default settingsDescription;
