import { validateObject } from 'helpers/validateConfiguration';

function extractRule(validationRule) {
  return {
    ruleType: validationRule.type,
    parameterValue: validationRule.parameterValue,
    errorMessage: validationRule.errorMessage,
  };
}

function extractRules(sinkConfiguration) {
  const rules = sinkConfiguration.values.reduce((acc, x) => {
    acc[x.name] = x.validationRules.map(rule => extractRule(rule));
    return acc;
  }, {});
  rules.configurationName = [
    {
      ruleType: 'required',
      errorMessage: 'Configuration Name should not be empty.',
    },
    {
      ruleType: 'max_length',
      parameterValue: '100',
      errorMessage: 'The length of the Configuration Name should not exceed 100 characters.',
    },
  ];
  return rules;
}

function extractValues(sinkConfiguration) {
  const values = sinkConfiguration.values.reduce((acc, x) => {
    acc[x.name] = x.value;
    return acc;
  }, {});
  values.configurationName = sinkConfiguration.name;
  return values;
}

function isRequired(sinkConfiguration, propName) {
  const rules = extractRules(sinkConfiguration);
  return rules[propName].some(({ ruleType }) => ruleType === 'required');
}

function validateSinkConfiguration(sinkConfiguration) {
  const rules = extractRules(sinkConfiguration);
  const values = extractValues(sinkConfiguration);
  return validateObject(values, rules);
}

export {
  isRequired,
  validateSinkConfiguration,
};
