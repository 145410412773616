import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import SmartFilters from './SmartFilters';
import EmailStatisticsRoutes from './emailStatisticsRoutes';

export default () => (
  <Switch>
    <Route path="/reporting/smart-filters" component={SmartFilters} />
    <Route path="/reporting/email-statistics" component={EmailStatisticsRoutes} />
    <Redirect exact from="/reporting" to="/reporting/smart-filters" />
    <Route render={() => 'not found'} />
  </Switch>
);
