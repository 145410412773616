import React from 'react';
import ColouredDot from 'components/ColouredDot';
import styles from './styles.scss';

const StateFilter = ({ onHandleCategorySelect }) => (
  <div className={styles.filter}>
    <p className={styles.filterHeader}>Filters</p>
    <button
      type="button"
      className={styles.filterButton}
      onClick={() => onHandleCategorySelect('all')}
    >
      <div className={styles.stateCategory} />
      All states
    </button>
    <button
      type="button"
      className={styles.filterButton}
      onClick={() => onHandleCategorySelect('red')}
    >
      <ColouredDot theme="red" />
      All Red states
    </button>
    <button
      type="button"
      className={styles.filterButton}
      onClick={() => onHandleCategorySelect('amber')}
    >
      <ColouredDot theme="amber" />
      All Amber states
    </button>
    <button
      type="button"
      className={styles.filterButton}
      onClick={() => onHandleCategorySelect('green')}
    >
      <ColouredDot theme="green" />
      All Green states
    </button>
  </div>
);

export default StateFilter;
