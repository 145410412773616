const getLabelStyle = (event) => {
  switch (event) {
    case 'Processed':
      return 'processed';
    case 'Delivered':
      return 'delivered';
    case 'Opened':
      return 'opened';
    case 'LinkClicked':
      return 'clicked';
    case 'Soft bounce':
    case 'Auto Responder':
      return 'softbounce';
    case 'SubscriptionChanged':
    case 'Deactivated':
      return 'deactivated';
    default:
      return 'bounce';
  }
};

export default getLabelStyle;
