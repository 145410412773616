import React from 'react';
import * as propTypes from 'prop-types';
import ContextMenuItem from 'components/ContextMenu/ContextMenuItem';
import styles from './styles.scss';

function TextMenuItem({
  icon: Icon, text, clickHandler, disabled,
}) {
  return (
    <ContextMenuItem
      clickHandler={clickHandler}
      disabled={disabled}
    >
      <div className={styles.container}>
        <span className={styles.text}>{text}</span>
        {Icon && <Icon className={styles.icon} />}
      </div>
    </ContextMenuItem>
  );
}

TextMenuItem.defaultProps = {
  icon: null,
  disabled: false,
};

TextMenuItem.propTypes = {
  text: propTypes.string.isRequired,
  clickHandler: propTypes.func.isRequired,
  icon: propTypes.elementType,
  disabled: propTypes.bool,
};

export default TextMenuItem;
