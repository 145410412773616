import API from 'helpers/API';

import {
  REVOKE_API_KEY_SUCCESS,
  UPDATE_API_KEY,
  UPDATE_API_KEY_SUCCESS,
  API_KEYS_FETCH,
  API_KEYS_SUCCESS,
  CREATE_API_KEY_FETCH,
  CREATE_API_KEY_SUCCESS,
} from './types';

export function revokeApiKey(id) {
  return dispatch => API.deleteKey(id).then(() => Promise.resolve(() => dispatch(
    { type: REVOKE_API_KEY_SUCCESS, id },
  )));
}

export function updateApiKey({ id, name }) {
  return (dispatch) => {
    dispatch({ type: UPDATE_API_KEY, key: { id, name } });

    return API.updateApiKey({ id, name })
      .then(dispatch({ type: UPDATE_API_KEY_SUCCESS, id, name }));
  };
}

export function getApiKeys() {
  return (dispatch) => {
    dispatch({ type: API_KEYS_FETCH });

    return API.getApiKeys()
      .then((response) => {
        dispatch({ type: API_KEYS_SUCCESS, keys: response.api_keys });
      })
      .catch((e) => {
        if (e.status === 404) {
          dispatch({ type: API_KEYS_SUCCESS, keys: [] });
          return Promise.resolve();
        }
        return Promise.reject();
      });
  };
}

export function createApiKey(key) {
  return (dispatch) => {
    dispatch({ type: CREATE_API_KEY_FETCH, key });

    return API.createApiKey({ name: key.name }).then((response) => {
      dispatch({ type: CREATE_API_KEY_SUCCESS, key: response.api_key });
      return Promise.resolve(response);
    });
  };
}
