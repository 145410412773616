import React from 'react';
import cx from 'classnames';
import styles from './EventType.scss';
import getLabelStyle from './getLabelStyle';

export default function EventType({ value }) {
  getLabelStyle();
  const fixLabel = (label) => {
    if (label === 'Transient') {
      return 'Delayed';
    }
    if (label === 'LinkClicked') {
      return 'Clicked';
    }
    if (label === 'Message delayed') {
      return 'Undeliverable';
    }
    if (label === 'SubscriptionChanged') {
      return 'Deactivated';
    }
    return label;
  };
  return (
    <div className={cx(styles.cellWrapper, styles.cellDecorated, styles[getLabelStyle(value)])}>
      <span>{fixLabel(value)}</span>
    </div>
  );
}
