import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import API from 'helpers/API';
import styles from './styles.scss';

const visibleCharacterCount = 30;

class ShippingState extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    this.fetchShipping();
  }

  getShippingList = () => {
    const sortedArray = this.state.data.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    return sortedArray.map(this.renderItem);
  };

  fetchShipping = () => {
    API.getShipmentFilters()
      .then((response) => {
        this.setState({
          data: response.shipment_filters
            .map(group => ({
              ...group,
              truncatedName: group.name.length > visibleCharacterCount
                ? `${group.name.substring(0, visibleCharacterCount)}...`
                : group.name,
            })),
        });
      });
  };

  renderItem = (element) => {
    const { selectedStates, onShippingItemClick } = this.props;
    const foundState = selectedStates.find(item => item === element.id);

    return (
      <li key={element.id} className={cx(styles.shippingItems, `${foundState ? styles.selected : ''}`)}>
        <button
          type="button"
          title={element.name}
          onClick={() => onShippingItemClick(element)}
        >
          {element.truncatedName}
        </button>
      </li>
    );
  };

  render() {
    return (
      <div className={styles.innerCardBody}>
        <div className={styles.descriptionArea}>
          <p>Select the shipment filters that will trigger your webhook.</p>
          <p>
            <Link to="/settings/shipment-filters" className={styles.shippingLink}>
              Create shipment filter
            </Link>
          </p>
          <ul className={styles.shippingList}>
            {this.getShippingList()}
          </ul>
        </div>
      </div>
    );
  }
}

export default ShippingState;
