import React, { PureComponent } from 'react';
import cx from 'classnames';
import { format } from 'date-fns';
import { event as gaEvent } from 'react-ga';
import propTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getTrackingPagePath } from 'helpers/API';
import BinSvg from 'images/bin.svg';
import CardEllipsisMenu from 'components/CardEllipsisMenu';
import TextMenuItem from 'components/ContextMenu/TextMenuItem';
import styles from './TrackingPageCard.scss';
import Svg from '../../../components/Svg';
import CardShell from '../../../components/CardShell';
import {
  deleteTrackingPage as deleteTrackingPageAction,
} from '../../../actions/trackingPagesActions';
import { closePopup as closePopupAction, showPopup as showPopupAction } from '../../../actions';
import { NonPublished } from '../../../constants/trackingPage/trackingPageVersions';

class TrackingPageCard extends PureComponent {
  static dateFormat = 'DD/MM/YYYY | h.mma';

  static propTypes = {
    name: propTypes.string.isRequired,
    title: propTypes.string.isRequired,
    publishedOn: propTypes.number,
    createdOn: propTypes.number.isRequired,
  };

  static defaultProps = {
    publishedOn: undefined,
  };

  openPreview = () => {
    window.open(getTrackingPagePath(this.props.name, NonPublished, true));
  };

  promptDelete = () => {
    const {
      name,
      deleteTrackingPage,
      showPopup,
      closePopup,
    } = this.props;
    showPopup({
      title: 'Are you sure?',
      description: <span>Delete of tracking page <u>deletes</u> it completely.</span>,
      onAccept: () => deleteTrackingPage(name)
        .then(() => {
          gaEvent({
            category: 'Tracking Page',
            action: 'Delete tracking page',
          });
          closePopup();
        }),
    });
  };

  handleDuplicateClick = () => {
    this.props.history.push({
      pathname: '/tracking-pages/create',
      state: {
        sourceTrackingPageName: this.props.name,
      },
    });
  };

  render() {
    const {
      name,
      title,
      publishedOn,
      createdOn,
      history,
    } = this.props;
    return (
      <CardShell noOverflow>
        <div className={styles.cardContainer}>
          <div className={cx('textCenter', styles.cardContent)}>
            <h4 className={styles.cardTitle}>{title}</h4>
            <div className={styles.svg}><Svg name="mapPin" /></div>
            {publishedOn
              ? <p className="detail2">Published on: {format(publishedOn * 1000, TrackingPageCard.dateFormat)}</p>
              : <p className="detail2">Saved on: {format(new Date(createdOn * 1000), TrackingPageCard.dateFormat)}</p>}
          </div>
          <CardEllipsisMenu
            primaryText="View"
            primaryClickHandler={() => history.push(`/tracking-pages/edit/${name}`)}
          >
            <TextMenuItem text="Preview" clickHandler={this.openPreview} />
            <TextMenuItem text="Duplicate" clickHandler={this.handleDuplicateClick} />
            <TextMenuItem text="Delete" clickHandler={this.promptDelete} icon={BinSvg} />
          </CardEllipsisMenu>
        </div>
      </CardShell>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  deleteTrackingPage: trackingPageName => dispatch(deleteTrackingPageAction(trackingPageName)),
  showPopup: config => dispatch(showPopupAction(config)),
  closePopup: () => dispatch(closePopupAction()),
});

export default withRouter(connect(undefined, mapDispatchToProps)(TrackingPageCard));
