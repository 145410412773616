import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.scss';

function ExportButton({ onClick }) {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    setHasError(false);

    try {
      await onClick();
    } catch (error) {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <button
        type="button"
        className={styles.exportButton}
        onClick={handleClick}
        disabled={loading}
      >
        {loading ? 'Scheduling export...' : 'Export'}
      </button>
      {hasError && <p className={styles.errorMessage}>Export failed. Please try again.</p>}
    </>
  );
}

ExportButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ExportButton;
