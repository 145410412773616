// API KEY

export const REVOKE_API_KEY_SUCCESS = 'REVOKE_API_KEY_SUCCESS';
export const UPDATE_API_KEY = 'UPDATE_API_KEY';
export const UPDATE_API_KEY_SUCCESS = 'UPDATE_API_KEY_SUCCESS';
export const API_KEYS_FETCH = 'API_KEYS_FETCH';
export const API_KEYS_SUCCESS = 'API_KEYS_SUCCESS';
export const CREATE_API_KEY_FETCH = 'CREATE_API_KEY_FETCH';
export const CREATE_API_KEY_SUCCESS = 'CREATE_API_KEY_SUCCESS';

// CUSTOM STATE LABELS

export const CUSTOM_STATE_LABELS_FETCH = 'CUSTOM_STATE_LABELS_FETCH';
export const CUSTOM_STATE_LABELS_SUCCESS = 'CUSTOM_STATE_LABELS_SUCCESS';
export const CUSTOM_STATE_LABELS_ERROR = 'CUSTOM_STATE_LABELS_ERROR';
export const EDIT_CUSTOM_STATE_LABEL_CLEAR = 'EDIT_CUSTOM_STATE_LABEL_CLEAR';
export const EDIT_CUSTOM_STATE_LABEL_SET = 'EDIT_CUSTOM_STATE_LABEL_SET';
export const EDIT_CUSTOM_STATE_LABEL_SUCCESS = 'EDIT_CUSTOM_STATE_LABEL_SUCCESS';
export const CUSTOM_STATE_LABELS_FILTER_SET = 'CUSTOM_STATE_LABELS_FILTER_SET';
export const EDIT_CUSTOM_STATE_LABEL_ERROR = 'EDIT_CUSTOM_STATE_LABEL_ERROR';

// SFTP

export const CREATE_SFTP_ACCOUNT = 'CREATE_SFTP_ACCOUNT';
export const CREATE_SFTP_ACCOUNT_SUCCESS = 'CREATE_SFTP_ACCOUNT_SUCCESS';
export const CREATE_SFTP_ACCOUNT_ERROR = 'CREATE_SFTP_ACCOUNT_ERROR';

// SHIPMENT FILTERS

export const SHIPMENT_FILTERS_FETCH = 'SHIPMENT_FILTERS_FETCH';
export const SHIPMENT_FILTERS_SUCCESS = 'SHIPMENT_FILTERS_SUCCESS';
export const SHIPMENT_AVAILABLE_STATES_FETCH = 'SHIPMENT_AVAILABLE_STATES_FETCH';
export const SHIPMENT_AVAILABLE_STATES_SUCCESS = 'SHIPMENT_AVAILABLE_STATES_SUCCESS';
export const SHIPMENT_AVAILABLE_STATES_ERROR = 'SHIPMENT_AVAILABLE_STATES_ERROR';

// SMART FILTERS
export const SMART_FILTERS_FETCH = 'SMART_FILTERS_FETCH';
export const SMART_FILTERS_SUCCESS = 'SMART_FILTERS_SUCCESS';
export const SMART_FILTERS_ERROR = 'SMART_FILTERS_ERROR';

// WEBHOOKS

export const WEBHOOK_FETCH = 'WEBHOOK_FETCH';
export const WEBHOOK_SUCCESS = 'WEBHOOK_SUCCESS';
export const CREATE_WEBHOOK_FETCH = 'CREATE_WEBHOOK_FETCH';
export const CREATE_WEBHOOK_SUCCESS = 'CREATE_WEBHOOK_SUCCESS';
export const TOGGLE_WEBHOOK = 'TOGGLE_WEBHOOK';
export const SEND_WEBHOOK = 'SEND_WEBHOOK';
export const SEND_WEBHOOK_SUCCESS = 'SEND_WEBHOOK_SUCCESS';
export const SEND_WEBHOOK_ERROR = 'SEND_WEBHOOK_ERROR';
export const REVOKE_WEBHOOK_SUCCESS = 'REVOKE_WEBHOOK_SUCCESS';

// TRACKING PAGES

export const TRACKING_PAGES_FETCH = 'TRACKING_PAGES_FETCH';
export const TRACKING_PAGES_FETCH_SUCCESS = 'TRACKING_PAGES_FETCH_SUCCESS';

export const TRACKING_PAGE_RESET = 'TRACKING_PAGE_RESET';
export const TRACKING_PAGE_FETCH = 'TRACKING_PAGE_FETCH';
export const TRACKING_PAGE_FETCH_SUCCESS = 'TRACKING_PAGE_FETCH_SUCCESS';
export const UPDATE_TRACKING_PAGE_FETCH = 'UPDATE_TRACKING_PAGE_FETCH';
export const UPDATE_TRACKING_PAGE_SUCCESS = 'UPDATE_TRACKING_PAGE_SUCCESS';
export const UPDATE_TRACKING_PAGE_ERROR = 'UPDATE_TRACKING_PAGE_ERROR';
export const CREATE_TRACKING_PAGE_FETCH = 'CREATE_TRACKING_PAGE_FETCH';
export const CREATE_TRACKING_PAGE_SUCCESS = 'CREATE_TRACKING_PAGE_SUCCESS';
export const CREATE_TRACKING_PAGE_ERROR = 'CREATE_TRACKING_PAGE_ERROR';
export const DELETE_TRACKING_PAGE_FETCH = 'DELETE_TRACKING_PAGE_FETCH';
export const DELETE_TRACKING_PAGE_SUCCESS = 'DELETE_TRACKING_PAGE_SUCCESS';

// SMART FILTERS
export const EMAIL_STATISTICS_FETCH = 'EMAIL_STATISTICS_FETCH';
export const EMAIL_STATISTICS_SUCCESS = 'EMAIL_STATISTICS_SUCCESS';
export const EMAIL_STATISTICS_ERROR = 'EMAIL_STATISTICS_ERROR';

// SIDE BAR MENU

export const SET_SIDEBAR_MENU_VALUE = 'SET_SIDEBAR_MENU_VALUE';
