import React from 'react';
import Select from 'react-select';
import cx from 'classnames';
import { ValidationErrors } from 'components/InputWithValidation';
import styles from './styles.scss';

const pickColor = (isFocussed, hasError) => {
  if (isFocussed) {
    return '2px solid #0065B3';
  }
  if (hasError) {
    return '1px solid #ce283c';
  }
  return '1px solid #8a8a8a';
};

const customStyles = hasError => ({
  control: (base, state) => ({
    color: '#474747',
    ...base,
    borderRadius: '2px',
    height: 46,
    boxShadow: 'none',
    borderColor: hasError ? '#c12547' : '#8a8a8a',
    // eslint-disable-next-line no-nested-ternary
    border: pickColor(state.isFocused, hasError),
    '&:hover': {
      borderColor: !state.isFocused && '#fc835a',
    },
    backgroundColor: state.isDisabled ? '#ebebeb' : base.backgroundColor,
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? '#0065B3' : base.backgroundColor,
    color: state.isSelected ? 'white' : base.color,
  }),
  noOptionsMessage: base => ({ ...base, color: '#616161' }),
  singleValue: (base, state) => ({ ...base, color: state.isDisabled ? '#616161' : base.singleValue }),
  indicatorSeparator: base => ({
    ...base,
    backgroundColor: '#e7e7e7',
  }),
  placeholder: (base) => ({
    ...base,
    color: '#616161',
  }),
});

const SelectWithValidation = ({
  label,
  placeholder,
  value,
  defaultValue,
  name,
  onChange,
  errors,
  options,
  required,
  disabled,
  components,
  id,
}) => {
  const hasError = errors && errors[name];
  return (
    <div className={styles.inputField}>
      <label className={cx(styles.loginFormLabel)}>
        {required && <span className={styles.asterisk}>*</span>} {label}
      </label>
      <div className={errors?.[name] && styles.inputErrorContainer}>
        {hasError && (
          <ValidationErrors
            errors={errors}
            name={name}
            label={label}
            value={value}
          />

        )}
        <Select
          options={options}
          defaultValue={options.find(x => x.value === defaultValue)}
          value={options.find(x => x.value === value)}
          onChange={e => onChange({
            name,
            value: e.value,
          })}
          name={name}
          placeholder={placeholder}
          hideSelectedOptions={false}
          closeMenuOnSelect
          backspaceRemovesValue={false}
          maxMenuHeight={250}
          isDisabled={disabled}
          styles={customStyles(hasError)}
          isOptionDisabled={option => option.disabled}
          components={components || null}
          id={id}
        />
      </div>
    </div>
  );
};

export default SelectWithValidation;
