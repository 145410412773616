import React from 'react';

/* eslint-disable */
export default props => (
  <svg {...props} id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 71.9 71.9'>
    <path d='M21.6,6.4V0.8c0-0.5-0.4-0.8-0.8-0.8H0.8C0.4,0,0,0.4,0,0.8v19.9c0,0.5,0.4,0.8,0.8,0.8h5.5 c0.5,0,0.8-0.4,0.8-0.8v-6.5c0-0.7,0.9-1.1,1.4-0.6l11.5,11.5c0.3,0.3,0.9,0.3,1.2,0l3.9-3.9c0.3-0.3,0.3-0.9,0-1.2L13.7,8.6 c-0.5-0.5-0.2-1.4,0.6-1.4h6.4C21.2,7.2,21.6,6.8,21.6,6.4z'
    />
    <path d='M63.3,58.2L51.8,46.7c-0.3-0.3-0.9-0.3-1.2,0l-3.9,3.9c-0.3,0.3-0.3,0.9,0,1.2l11.5,11.5 c0.5,0.5,0.2,1.4-0.6,1.4h-6.4c-0.5,0-0.8,0.4-0.8,0.8v5.5c0,0.5,0.4,0.8,0.8,0.8h19.9c0.5,0,0.8-0.4,0.8-0.8V51.2 c0-0.5-0.4-0.8-0.8-0.8h-5.5c-0.5,0-0.8,0.4-0.8,0.8v6.4C64.7,58.4,63.8,58.7,63.3,58.2z'
    />
    <path d='M50.4,0.8v5.5c0,0.5,0.4,0.8,0.8,0.8h6.5c0.7,0,1.1,0.9,0.6,1.4L46.7,20.2c-0.3,0.3-0.3,0.9,0,1.2l3.9,3.9 c0.3,0.3,0.9,0.3,1.2,0l11.5-11.5c0.5-0.5,1.4-0.2,1.4,0.6v6.4c0,0.5,0.4,0.8,0.8,0.8h5.5c0.5,0,0.8-0.4,0.8-0.8V0.8 c0-0.5-0.4-0.8-0.8-0.8H51.2C50.7,0,50.4,0.4,50.4,0.8z'
    />
    <path d='M20.2,46.7L8.6,58.2c-0.5,0.5-1.4,0.2-1.4-0.6v-6.5c0-0.5-0.4-0.8-0.8-0.8H0.8c-0.5,0-0.8,0.4-0.8,0.8v19.9 c0,0.5,0.4,0.8,0.8,0.8h19.9c0.5,0,0.8-0.4,0.8-0.8v-5.5c0-0.5-0.4-0.8-0.8-0.8h-6.4c-0.7,0-1.1-0.9-0.6-1.4l11.5-11.5 c0.3-0.3,0.3-0.9,0-1.2l-3.9-3.9C21,46.4,20.5,46.4,20.2,46.7z'
    />
  </svg>
)

/* eslint-enable */
