const emailStatisticsMenuPaths = (id) => [
  {
    name: 'Statistics',
    pathName: `/reporting/email-statistics/${id}`,
    exact: true,
  },
  {
    name: 'Activity',
    pathName: `/reporting/email-statistics/${id}/activity`,
    exact: false,
  },
  {
    name: 'Suppressions',
    pathName: `/reporting/email-statistics/${id}/suppressions`,
    exact: true,
  },
];

export default emailStatisticsMenuPaths;
