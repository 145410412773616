import { removeAuthToken, getAuthTokenValue } from 'helpers/auth';

const inactivityLocalStorageName = 'inactivityTime';
// use wasReloaded in order to sync between tabs. we can logout on one tab, but another one will not know about it.
// so we use wasReloaded on another tab to understand that there was auth token before but tab was not reloaded.
let wasLoggedIn = false;
const timerIntervalMs = 1000 * 60;
const allowedInactivityTimeMs = 1000 * 60 * 20;

// use local storage to sync between tabs
function timerIncrement() {
  if (wasLoggedIn) {
    let idleTime = window.localStorage.getItem(inactivityLocalStorageName);
    if (!idleTime) {
      idleTime = new Date(new Date().getTime() - timerIntervalMs).toString();
      window.localStorage.setItem(inactivityLocalStorageName, idleTime);
    }

    if (new Date() - new Date(idleTime) > allowedInactivityTimeMs) {
      removeAuthToken();
      window.location.reload();
    }
  }
}

function inactivityOnLogout() {
  wasLoggedIn = false;
}

function inactivityOnLogin() {
  wasLoggedIn = true;
  window.localStorage.setItem(inactivityLocalStorageName, new Date());
}

function zeroIdleTime() {
  window.localStorage.setItem(inactivityLocalStorageName, new Date());
}

function trackInactivity() {
  if (getAuthTokenValue()) {
    wasLoggedIn = true;
  }

  setInterval(timerIncrement, timerIntervalMs);

  document.addEventListener('click', zeroIdleTime);
  document.addEventListener('keypress', zeroIdleTime);
  document.addEventListener('touchstart', zeroIdleTime);
}

export { trackInactivity, inactivityOnLogout, inactivityOnLogin };
