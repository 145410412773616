import React from 'react';
import { Switch, Route } from 'react-router';
import ConfiguredDashboard from './ConfiguredDashboard';
import ShipmentList from '../Shipments/ShipmentList/ShipmentList';
import DashboardTotalViewTable from '../../../components/DashboardTotalViewTable/index';

export default () => (
  <Switch>
    <Route path="/dashboards/proactive-alerting/configured-dashboards/:dashboard/:alert" component={ShipmentList} />
    <Route exact path="/dashboards/proactive-alerting" component={ConfiguredDashboard} />
    <Route exact path="/dashboards/proactive-alerting/configured-dashboards/:dashboard" component={DashboardTotalViewTable} />
  </Switch>
);
