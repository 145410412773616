import React from 'react';
import cx from 'classnames';
import { format } from 'date-fns';
import { useParams, NavLink } from 'react-router-dom';
import EventLabel from '../EventLabel';
import styles from './styles.scss';
import getLabelStyle from '../EventLabel/getLabelStyle';
import BounceDetails from '../BounceDetails';

const EventTimeline = (props) => {
  const { id } = useParams();
  const ev = 'eventHeader--';

  let current = null;
  const formatDate = (event) => {
    const dateTime = format(event.timeStamp, 'DD MMM YYYY HH:mm:ss');
    if (current == null) {
      current = format(event.timeStamp, 'DD MMM YYYY');
      return {
        ...event, date: current, dateTime, time: format(event.timeStamp, 'HH:mm'),
      };
    }

    if (current !== format(event.timeStamp, 'DD MMM YYYY')) {
      current = format(event.timeStamp, 'DD MMM YYYY');
      return {
        ...event, date: current, dateTime, time: format(event.timeStamp, 'HH:mm'),
      };
    }

    return { ...event, dateTime, time: format(event.timeStamp, 'HH:mm') };
  };

  return (
    <div className={styles.timelineContainer}>
      {props.showtop && (<div className={styles.timelineTop} />)}
      {props.events.map(formatDate).map(eventDetail => (

        <div className={styles.timelineEvent}>
          <div className={styles.leftSide}>
            {eventDetail.date && (
            <div>{eventDetail.date}</div>
            )}
            <div title={eventDetail.dateTime}>{eventDetail.time}</div>
          </div>
          <div className={styles.rightSide}>
            <div className={cx(styles.eventHeader, styles[`${ev}${getLabelStyle(eventDetail.status)}`])}><EventLabel value={eventDetail.status} /></div>
            <div className={styles.eventDetails}>
              {eventDetail.details}
            </div>
            {eventDetail.destinationServer && (
              <div className={styles.eventDetails}>
                From: {eventDetail.destinationServer}
              </div>
            )}
            {eventDetail.subject && eventDetail.id && (
              <div role="button">
                <NavLink
                  exact
                  to={`/reporting/email-statistics/${id}/activity/${eventDetail.id}/detail`}
                  className={styles.eventLink}
                >
                  {eventDetail.subject}
                </NavLink>
              </div>
            )}
            {eventDetail.bounceId && (
              <div>
                <BounceDetails eventDetail={eventDetail} />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default EventTimeline;
