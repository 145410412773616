import vocabulary from 'constants/vocabulary';
import dates, { format } from 'date-fns';

const unknownDate = 'unknown';

const toUnixTime = date => Math.trunc(date.getTime() / 1000);

const toDate = unixTime => new Date(unixTime * 1000);

const subDaysFromToday = (days, now = Date.now()) => {
  const startOfToday = dates.startOfDay(now);
  return toUnixTime(dates.subDays(startOfToday, days - 1));
};

const matchStringToObjectWithFromToDates = (dateFrom, now) => {
  if (!vocabulary[dateFrom]) return {};

  const startOfToday = dates.startOfDay(now);
  const endOfToday = dates.endOfDay(now);

  if (dateFrom.includes('last')) {
    return {
      from: dates.subDays(startOfToday, Number(dateFrom.substring(4, dateFrom.indexOf('days'))) - 1),
      to: endOfToday,
    };
  }
  if (dateFrom.includes('next')) {
    return {
      from: startOfToday,
      to: dates.addDays(endOfToday, Number(dateFrom.substring(4, dateFrom.indexOf('days'))) - 1),
    };
  }
  if (dateFrom === 'today') {
    return {
      from: startOfToday,
      to: endOfToday,
    };
  }
  if (dateFrom === 'yesterday') {
    return {
      from: dates.subDays(startOfToday, 1),
      to: dates.subDays(endOfToday, 1),
    };
  }
  if (dateFrom === 'tomorrow') {
    return {
      from: dates.addDays(startOfToday, 1),
      to: dates.addDays(endOfToday, 1),
    };
  }
  return {};
};

const matchStringToDate = (from, now = Date.now()) => {
  const parsed = matchStringToObjectWithFromToDates(from, now);
  return {
    from: parsed.from ? toUnixTime(parsed.from) : null,
    to: parsed.to ? toUnixTime(parsed.to) : null,
  };
};

const replaceDates = (datesToReplace) => {
  const {
    customReference,
    trackingReference,
    packageTrackingReference,
    registeredDate,
    orderDate,
    shippedDate,
    deliveryDate,
    promisedDate,
    estimatedDate,
    carriers,
    states,
    origins,
    destinations,
    shippingDirection,
    page,
    pageSize,
    late,
    mayBeMissing,
    calculatedEventsOperator,
    metadataKey,
    metadataValue,
    originPostalCode,
    destinationPostalCode,
    emailAddress,
  } = datesToReplace;

  const getGlobalDate = (shipmentDate) => {
    if (!shipmentDate) return matchStringToDate('any');
    if (vocabulary[shipmentDate.from]) return matchStringToDate(shipmentDate.from);
    return shipmentDate;
  };

  return {
    customReference,
    trackingReference,
    packageTrackingReference,
    registeredDate: getGlobalDate(registeredDate),
    orderDate: getGlobalDate(orderDate),
    shippedDate: getGlobalDate(shippedDate),
    deliveryDate: getGlobalDate(deliveryDate),
    promisedDate: getGlobalDate(promisedDate),
    estimatedDate: getGlobalDate(estimatedDate),
    carriers: carriers && carriers.map(carrier => carrier.key),
    states: states && states.map(state => state.value),
    origins,
    destinations,
    shippingDirection,
    page,
    pageSize,
    late,
    mayBeMissing,
    calculatedEventsOperator,
    metadataKey,
    metadataValue,
    originPostalCode,
    destinationPostalCode,
    emailAddress,
  };
};

const flattenData = (data) => {
  const {
    trackingReference,
    customReference,
    packageTrackingReference,
    registeredDate,
    orderDate,
    shippedDate,
    deliveryDate,
    promisedDate,
    estimatedDate,
    carriers,
    states,
    origins,
    destinations,
    shippingDirection,
    page,
    pageSize,
    late,
    mayBeMissing,
    calculatedEventsOperator,
    metadataKey,
    metadataValue,
    originPostalCode,
    destinationPostalCode,
    emailAddress,
  } = data;

  const flattenField = (object, fieldName) => object && {
    [`${fieldName}From`]: object.from,
    [`${fieldName}To`]: object.to,
  };

  return {
    trackingReference,
    customReference,
    packageTrackingReference,
    ...flattenField(registeredDate, 'dateCreated'),
    ...flattenField(orderDate, 'orderDate'),
    ...flattenField(shippedDate, 'shippedDate'),
    ...flattenField(deliveryDate, 'deliveryDate'),
    ...flattenField(promisedDate, 'promisedDate'),
    ...flattenField(estimatedDate, 'estimatedDate'),
    carriers,
    states,
    origins,
    destinations,
    shippingDirection,
    page,
    page_size: pageSize,
    late,
    mayBeMissing,
    calculatedEventsOperator,
    metadataKey,
    metadataValue,
    originPostalCode,
    destinationPostalCode,
    emailAddress,
  };
};

const groupByDateToObject = (itemsArray, datePropertyName) => itemsArray.reduce((result, item) => {
  const date = item[datePropertyName] ? format(new Date(item[datePropertyName]), 'DD/MM/YYYY') : unknownDate;
  result[date] = result[date] || []; // eslint-disable-line
  result[date].push(item);
  return result;
}, Object.create(null));

const orderByDate = (itemsArray, datePropertyName, isAsc = true) => itemsArray.sort((o1, o2) => {
  const d1 = o1[datePropertyName];
  const d2 = o2[datePropertyName];
  if (d1 < d2) return isAsc ? -1 : 1;
  if (d1 > d2) return isAsc ? 1 : -1;
  return 0;
});

export {
  toUnixTime,
  toDate,
  subDaysFromToday,
  matchStringToDate,
  groupByDateToObject,
  orderByDate,
  replaceDates,
  flattenData,
};
