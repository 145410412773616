const initialState = {
  smartFiltersStatistics: [],
  isLoading: false,
  requestDidTimeout: false,
};

export default function smartFiltersReducer(state = initialState, action) {
  switch (action.type) {
    case 'SMART_FILTERS_FETCH':
      return {
        ...state,
        smartFiltersStatistics: [],
        isLoading: true,
      };
    case 'SMART_FILTERS_SUCCESS':
      return {
        ...state,
        smartFiltersStatistics: action.smartFilters,
        isLoading: false,
        requestDidTimeout: false,
      };
    case 'SMART_FILTERS_ERROR':
      return {
        ...state,
        smartFiltersStatistics: [],
        isLoading: false,
        requestDidTimeout: true,
      };
    default:
      return state;
  }
}
