import React, { useState } from 'react';
import API from 'helpers/API';
import Spinner from 'components/Spinner';
import CardShell from 'components/CardShell';
import ClipboardCopy from 'components/ClipboardCopy';
import DualButtons from 'components/DualButtons';
import styles from '../styles.scss';

function VerifyDomain({
  sinkConfigurationId, onVerified, onCanceled, dkimHost, dkimTextValue,
}) {
  const [verificationFailed, setVerificationFailed] = useState(false);
  const [loading, setLoading] = useState(false);

  const verify = async () => {
    setLoading(true);

    let isVerified = false;
    try {
      const verifyResponse = await API.verifyDomain(sinkConfigurationId);
      isVerified = verifyResponse.dkimVerified;
    } catch (e) {
      // continue regardless of error
    } finally {
      setLoading(false);
    }
    if (isVerified) {
      onVerified();
    } else {
      setVerificationFailed(true);
    }
  };

  if (loading) {
    return (
      <Spinner />
    );
  }

  return (
    <CardShell>
      <div className={styles.verifyDomainWrapper}>
        <div className={styles.verifyDomainHeaderWrapper}>
          <div className={styles.alertMessagesHeader}>
            <h3 className="textCenter">Domain verification</h3>
            <div className={styles.copyInputWrapper}>
              <label className={styles.copyInputLabel}>DKIM Host</label>
              <ClipboardCopy value={dkimHost} />
              <label htmlFor="dkimTextValue" className={styles.copyInputLabel}>DKIM Text</label>
              <ClipboardCopy value={dkimTextValue} />
            </div>

            <p>
              To verify your domain please create a new TXT DNS record on your domain with the above values,
              where:<br />
              Name = DKIM Host<br />
              Value = DKIM Text
            </p>
            {verificationFailed && (
              <p className={styles.warning}>
                Verification was not successful, it may take up to 48 hours after the DNS record has been created
                to become verifiable.
              </p>
            )}
          </div>
        </div>
        <DualButtons
          leftFunction={onCanceled}
          rightFunction={verify}
          leftText="Cancel"
          rightText="Verify"
          theme="rightPink"
        />
      </div>
    </CardShell>
  );
}

export default VerifyDomain;
