import React, {
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import DropdownIcon from 'images/dropdown.svg';
import API from 'helpers/API';
import { reactLocalStorage } from 'reactjs-localstorage';
import styles from './styles.scss';
import LayoutContext from '../../Layout/LayoutContext';

const ENTER_CHAR_CODE = 13;
const SEARCH_FILTER = 'SearchFilter';
const DEFAULT_SEARCH_FILTERS = [
  { value: 'trackingReference', name: 'Tracking reference' },
  { value: 'customReference', name: 'Custom reference' },
  { value: 'packageTrackingReference', name: 'Package number' },
  { value: 'originPostalCode', name: 'Origin postal code' },
  { value: 'destinationPostalCode', name: 'Destination postal code' },
  { value: 'emailAddress', name: 'Recipient email' },
];

export function CMobileSearch({ isOpen }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchFilterOpen, setIsSearchFilterOpen] = useState(false);
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0);
  const [hoveredFilterIndex, setHoveredFilterIndex] = useState(0);
  const [searchFilters, setSearchFilters] = useState(DEFAULT_SEARCH_FILTERS);

  const history = useHistory();
  const { shipmentMetadataGroupings } = useContext(LayoutContext);
  const wrapperRef = useRef(null);

  const containerStyle = isOpen ? {
    transform: 'scale(1)',
  } : {
    opacity: 0,
    pointerEvents: 'none',
    transform: 'scale(0.9)',
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsSearchFilterOpen(false);
        setHoveredFilterIndex(selectedFilterIndex);
      }
    };
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [selectedFilterIndex]);

  useEffect(() => {
    const metadataGroups = shipmentMetadataGroupings.map(x => (
      {
        name: x.displayName,
        value: x.metadataKey,
        isMetadata: true,
      }));
    const filters = [
      ...DEFAULT_SEARCH_FILTERS,
      ...metadataGroups,
    ];
    setSearchFilters(filters);

    const search = reactLocalStorage.get(SEARCH_FILTER);
    const index = filters.findIndex(x => x.value === search);
    const updatedSelectedFilterIndex = index > 0 ? index : 0;
    setSelectedFilterIndex(updatedSelectedFilterIndex);
  }, [shipmentMetadataGroupings]);

  async function handleKeyPress(event) {
    if (event.charCode === ENTER_CHAR_CODE) {
      const params = new URLSearchParams();
      const selected = searchFilters[selectedFilterIndex];

      let searchQueryKey = selected.value;
      if (selected.isMetadata) {
        params.set('metadataKey', selected.value);
        searchQueryKey = 'metadataValue';
      }
      if (searchQuery) {
        params.set(searchQueryKey, searchQuery);
      }

      const response = await API.getShipments(params.toString());

      let url = '/shipments';
      if (response.shipments.length === 1 || (selected.isMetadata && response.shipments.length)) {
        url = `${url}/${response.shipments[0].id}`;
        if (selected.isMetadata) {
          url = `${url}?${params.toString()}`;
        }
      } else if (params.toString()) {
        url = `${url}?${params.toString()}`;
      }
      history.push(url);
    }
  }

  function handleChange(event) {
    setSearchQuery(event.target.value);
  }

  function handleFilterClicked(i) {
    setSelectedFilterIndex(i);
    reactLocalStorage.set(SEARCH_FILTER, searchFilters[i].value);
  }

  function handleSearchFilters() {
    setIsSearchFilterOpen(x => !x);
    setHoveredFilterIndex(selectedFilterIndex);
  }

  function handleOnHover(i) {
    setHoveredFilterIndex(i);
  }

  return (
    <div
      className={styles.container}
      style={containerStyle}
      role="button"
    >
      <div className={styles.searchContainer}>
        <div role="button" className={styles.iconContainer} onClick={handleSearchFilters} ref={wrapperRef}>
          <DropdownIcon className={styles.icon} />
          <span className={styles.verticalLine} />
        </div>
        <input
          className={styles.search}
          type="search"
          value={searchQuery}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          placeholder="Search..."
          title="search"
        />
      </div>
      {isSearchFilterOpen
        && (
          <ul className={styles.searchFilterItemContainer}>
            {searchFilters.map((filter, i) => (
              <li
                role="presentation"
                key={filter.value}
                className={styles.searchFilterItem}
                onClick={() => handleFilterClicked(i)}
                onMouseEnter={() => handleOnHover(i)}
                style={{ backgroundColor: i === hoveredFilterIndex ? 'red' : 'white' }}
              >
                {filter.name}
              </li>
            ))}
          </ul>
        )}
    </div>
  );
}

export default CMobileSearch;
