import React, { useState, useEffect } from 'react';
import API from 'helpers/API';
import CircularProgress from '@material-ui/core/CircularProgress';
import { format } from 'date-fns';
import ShipmentNoteDay from './ShipmentNoteDay';
import AddShipmentNote from './AddShipmentNote';
import styles from './style.scss';

export default function ShipmentNotes({ shipmentId }) {
  const [dateMappedShipmentNotes, setDateMappedShipmentNotes] = useState([]);
  const [notesLoading, setNotesLoading] = useState(true);
  const [addNoteIsOpen, setAddNoteIsOpen] = useState(false);
  const [reload, setReload] = useState(false);

  const mapNotesToDates = (shipmentNotesArr) => {
    const days = [...new Set(shipmentNotesArr.map(note => format(new Date(note.createdOn), 'D/M/YYYY dddd')))];

    return days.map(day => (
      { day, notes: shipmentNotesArr.filter(note => format(new Date(note.createdOn), 'D/M/YYYY dddd') === day) }
    ));
  };

  const cancelAddNote = () => {
    setAddNoteIsOpen(false);
  };

  const saveNote = async (shipmentNote) => {
    setNotesLoading(true);
    await API.createShipmentNote(shipmentNote);
    setNotesLoading(false);
    setReload(!reload);
    setAddNoteIsOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setNotesLoading(true);
      const shipmentNotesResponse = await API.getShipmentNotes(shipmentId);
      setDateMappedShipmentNotes(mapNotesToDates(shipmentNotesResponse.shipmentNotes));
      setNotesLoading(false);
    };

    fetchData();
  }, [shipmentId, reload]);

  return (
    <div className={styles.notesBlockGrid}>
      <div className={styles.notesBlock}>
        <div className={styles.notesBlockHeader}>
          <p>
            <b>Notes</b>
          </p>
          <div
            role="button"
            className={(notesLoading ? styles.hide : styles.addNoteButton)}
            onClick={() => setAddNoteIsOpen(true)}
          >Add Note
          </div>
        </div>
        {notesLoading
          && (
            <div className={styles.spinner}>
              <CircularProgress thickness={8} color="inherit" size={80} variant="indeterminate" />
            </div>
          )}
        {addNoteIsOpen && <AddShipmentNote cancelAddNote={cancelAddNote} saveNote={saveNote} shipmentId={shipmentId} />}
        {dateMappedShipmentNotes.length > 0 ? (
          <>
            {dateMappedShipmentNotes.map(date => (
              <ShipmentNoteDay key={date.day} date={date} />
            ))}
          </>
        ) : (<p className={(notesLoading ? styles.hide : null)}>No notes found for this shipment</p>)}
      </div>
    </div>
  );
}
