export const Published = 'published';
export const NonPublished = 'non_published';
export const Preview = 'preview';

export const TrackingPageVersions = [
  Published,
  NonPublished,
  Preview,
];

export default TrackingPageVersions;
