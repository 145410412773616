/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import API from 'helpers/API';
import ReactTable from 'react-table';
import Spinner from 'components/Spinner';
import { useHistory, Link } from 'react-router-dom';
import styles from './styles.scss';
import renderStatus from './renderStatus';

function DashboardTable({
  dashboardName,
}) {
  const history = useHistory();
  const [dashboardFriendlyName, setDashboardFriendlyName] = useState('');
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(true);

  const getAlerts = async () => {
    setLoading(true);
    try {
      const dashboardAlertsToSet = await API.getConfiguredDashboardAlertData(dashboardName, '?top=5&minVolume=1');
      if (dashboardAlertsToSet.dashboard && 'alerts' in dashboardAlertsToSet.dashboard) {
        dashboardAlertsToSet.dashboard.alerts = dashboardAlertsToSet.dashboard.alerts.filter(alert => alert.category !== 'unknown');
        setAlerts(dashboardAlertsToSet.dashboard.alerts);
        setDashboardFriendlyName(dashboardAlertsToSet.dashboard.text);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (dashboardName) {
      getAlerts();
    }
    // eslint-disable-next-line
  }, [dashboardName]);

  return (
    <div className={styles.container}>
      {loading
        ? <Spinner />
        : (
          <>
            <div className={styles.titleHeaders}>
              <h2 className="h2" style={{ fontSize: '28px' }}>{dashboardFriendlyName}</h2>
              <Link to={`proactive-alerting/configured-dashboards/${dashboardName}`}>{`View all ${dashboardFriendlyName}`}</Link>
            </div>
            {alerts.length > 0
              ? (
                <div className={styles.tableBorder}>
                  <ReactTable
                    data={alerts}
                    columns={[
                      {
                        Header: 'Alert rule name',
                        accessor: 'description',
                        Cell: row => <span title={row.value}>{row.value}</span>,
                        headerStyle: {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          fontSize: '18px',
                          background: 'white',
                          padding: '0',
                          cursor: 'context-menu',
                          borderBottom: '1px solid gray',
                        },
                        style: {
                          textAlign: 'left',
                          alignSelf: 'center',
                        },
                      },
                      {
                        Header: 'Alert status',
                        accessor: 'data',
                        Cell: (props) => renderStatus(props),
                        style: {
                          textAlign: 'left',
                          alignSelf: 'center',
                        },
                        headerStyle: {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          fontSize: '18px',
                          background: 'white',
                          padding: '0',
                          cursor: 'context-menu',
                          borderBottom: '1px solid gray',
                        },
                      },
                    ]}
                    defaultPageSize={alerts.length}
                    showPageJump={false}
                    showPageSizeOptions={false}
                    sortable={false}
                    resizable={false}
                    getTrProps={(state, rowInfo, column, instance) => ({
                      onClick: (e, handleOriginal) => {
                        history.push(`proactive-alerting/configured-dashboards/${dashboardName}/${rowInfo.original.name}`);
                        if (handleOriginal) {
                          handleOriginal();
                        }
                      },
                    })}
                    showPaginationBottom={false}
                  />
                </div>
              )
              : <p>No alerts triggered.</p>}
          </>
        )}
    </div>
  );
}

export default withRouter(DashboardTable);
