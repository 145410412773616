import React from 'react';
import CardShell from 'components/CardShell';
import Hr from 'components/CardShell/Hr';
import Header from 'components/CardShell/Header';
import InputWithValidation from 'components/InputWithValidation';
import SelectWithValidation from 'components/SelectWithValidation';
import AceEditor from 'react-ace';
import inputStyles from 'components/InputWithValidation/styles.scss';
import Spinner from 'components/Spinner';
import styles from './styles.scss';

import 'brace/mode/html';
import 'brace/theme/xcode';

export default function TemplateForm({
  title,
  buttons,
  notificationConnectorOptions,
  template,
  onFormChange,
  errors,
  isLoading,
}) {
  return (
    <>
      {isLoading && <Spinner />}
      {buttons}
      <h1 className="h2">{title}</h1>
      <div className="mt-5">
        <CardShell noOverflow>
          <div className={styles.cardContent}>
            <Header>General</Header>
            <Hr />
            <div className={styles.generalInputs}>
              <InputWithValidation
                value={template.name}
                onChange={e => onFormChange(e.target)}
                name="name"
                label="Name"
                type="text"
                placeholder="Enter template name"
                errors={errors}
                required
              />
              <InputWithValidation
                value={template.subject}
                onChange={e => onFormChange(e.target)}
                name="subject"
                label="Subject"
                type="text"
                placeholder="Enter template subject"
                errors={errors}
                required
              />
              <SelectWithValidation
                label="Notification connector"
                name="notificationConnectorId"
                onChange={onFormChange}
                errors={errors}
                required
                {...notificationConnectorOptions}
              />
            </div>
          </div>
        </CardShell>
      </div>
      <div className="mt-5 mb-5">
        <CardShell>
          <div className={styles.cardContent}>
            <Header>HTML</Header>
            <Hr />
            <div className="mt-5 mb-5">
              <AceEditor
                mode="html"
                theme="xcode"
                fontSize={14}
                width="100%"
                onChange={html => onFormChange({ name: 'html', value: html })}
                showPrintMargin={false}
                value={template.html}
                className={styles.editor}
              />
              {errors.html
                && <p className={inputStyles.errorMessage}>HTML should not be empty.</p>}
            </div>
          </div>
        </CardShell>
      </div>
    </>
  );
}
