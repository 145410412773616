import React, { Component } from 'react';
import Spinner from 'components/Spinner';
import cx from 'classnames';
import styles from 'routes/Dashboards/States/styles.scss';
import Svg from 'components/Svg';
import AngleUp from 'images/AngleUp.svg';
import Filters from 'components/Filters';
import { connect } from 'react-redux';
import joinQueries from 'helpers/joinQueryStrings';
import { withRouter } from 'react-router';
import FilterSummary from 'components/FilterSummary';
import { event as gaEvent } from 'react-ga';
import {
  closePopup as closePopupAction,
  showPopup as showPopupAction,
  toggleCalcEventsMap as toggleCalcEventsMapAction,
} from 'actions';
import { getSelectedFilters } from 'helpers/selectedFilters';
import CalculatedEventsMap from './CalculatedEventsMap';
import CalculatedEventSummaries from './CalculatedEventSummaries';
import TitleNavBlock from '../../../components/TitleNavBlock/TitleNavBlock';
import typography from '../../../helpers/appTypography';
import AddBtnRedesigned from '../../../components/AddFiltersBtnRedesigned/AddBtnRedesigned';

class CalculatedEventsDashboard extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      filtersOpen: false,
      loadingCount: 0,
    };
    let { search } = this.props.location;
    if (getSelectedFilters() && !search) {
      search = getSelectedFilters();
      this.handleSubmit(search);
    }
  }

  handleOpenFilters = () => {
    gaEvent({
      category: 'Navigation',
      action: 'Filters dropdown',
    });
    this.setState({ filtersOpen: true });
  };

  handleSubmit = (searchQuery) => {
    this.props.history.push({
      search: joinQueries(searchQuery),
    });
  };

  handleRedirect = (route) => {
    const {
      history,
      location: { search },
    } = this.props;
    history.push({
      ...route,
      search: joinQueries(route.search || '', search),
      state: {
        ...route.state,
        dashboard: 'calculated-events',
      },
    });
  };

  showLoading = () => {
    this.setState(x => ({
      loadingCount: x.loadingCount + 1,
    }));
  };

  hideLoading = () => {
    this.setState(x => ({
      loadingCount: x.loadingCount - 1,
    }));
  };

  render() {
    return (
      <div>
        {this.state.loadingCount > 0 ? <Spinner /> : null}
        {this.props.showCalcEventsMap
          && (
            <CalculatedEventsMap
              redirect={this.handleRedirect}
            />
          )}
        <div
          className={cx(styles.filtersContainer, this.state.filtersOpen ? styles.open : styles.closed)}
        >
          <div
            className={cx(styles.filtersShowMapButton, 'subtitle2', styles.centeredText)}
            onClick={this.props.toggleCalcEventsMap}
            role="button"
          >
            <div className={styles.showMapBlock}>
              {this.props.showCalcEventsMap
                ? <>Hide map <AngleUp className={styles.icon} /></>
                : <>Show map <Svg name="angleDown" className={styles.icon} /></>}
            </div>
          </div>
          {this.state.filtersOpen && (
            <Filters
              showPopup={this.props.showPopup}
              closePopup={this.props.closePopup}
              onSubmit={this.handleSubmit}
              closeFilters={() => this.setState({ filtersOpen: false })}
            />
          )}
        </div>
        <div className={styles.titleNavBlockWrapper}>
          <TitleNavBlock
            title={typography.calculatedEvents.title}
            subTitle={typography.calculatedEvents.subTitle}
            onBack={() => window.history.back()}
          />
        </div>
        <div>
          <div className={styles.outerWrapper}>
            <AddBtnRedesigned
              onClick={this.handleOpenFilters}
            >+ Add filters
            </AddBtnRedesigned>
            <FilterSummary />
          </div>
          <CalculatedEventSummaries
            redirect={this.handleRedirect}
            showLoading={this.showLoading}
            hideLoading={this.hideLoading}
            handleOpenFilters={this.handleOpenFilters}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  showCalcEventsMap: state.toggleMap.showCalcEventsMap,
});

const mapDispatchToProps = dispatch => ({
  showPopup: config => dispatch(showPopupAction(config)),
  closePopup: () => dispatch(closePopupAction()),
  toggleCalcEventsMap: () => dispatch(toggleCalcEventsMapAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CalculatedEventsDashboard));
