import React, { useState } from 'react';
import propTypes from 'prop-types';
import {
  Collapse, List, ListItem, ListItemIcon, ListItemText, Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import NavItem from '../../NavItem';
import Svg from '../../../Svg';
import styles from '../styles.scss';
import resources from '../../../../constants/resources';
import RestrictedAccess from '../../../RestrictedAccess';
import DropdownItemUpdated from '../../DropdownItem/DropDownItemUpdated';
import useStyles from '../useStyles';

const ReactDashboards = ({
  expanded,
  currentUrl,
  title,
}) => {
  const [expandedMenus, setExpandedMenus] = useState({});
  const path = window.location.pathname;
  const updatedPath = path.match(/^\/([\w\d-]+)/);
  const firstMatchedPath = updatedPath ? updatedPath[0] : '';

  const handleClickNavMenu = (menu) => {
    setExpandedMenus((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu], // Toggle the expanded state
    }));
  };

  const itemClasses = classNames(
    styles.navItem,
    { [styles.expanded]: expandedMenus.dashboards || expandedMenus.reporting },
  );

  return (
    <>
      {
        expanded && (
        <List className={itemClasses} style={{ paddingTop: 0, paddingBottom: 0 }}>
          <ListItem
            onClick={() => {
              handleClickNavMenu('dashboards');
            }}
            className={`${styles.listItem} ${expandedMenus.dashboards ? styles.listItemExpanded : ''}`}
          >
            <Svg
              name="dashboards"
              className={styles.iconSVG}
            />
            <ListItemIcon style={useStyles.icon}>
              {expandedMenus.dashboards ? (
                <div
                  className={styles.chevronDown}
                />
              ) : (
                <div
                  className={styles.chevronRight}
                />
              )}
            </ListItemIcon>
            {
              expanded && (
                <ListItemText
                  primary={(
                    <Typography style={useStyles.parentMenuItem}>
                      Dashboards
                    </Typography>
                  )}
                />
              )
            }
          </ListItem>
          <Collapse in={expandedMenus.dashboards} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <NavLink to="/dashboards/states" style={useStyles.linksStyle}>
                <ListItem
                  className={classNames(styles.listItem, ` ${(window.location.pathname === '/dashboards/states') ? styles.active : ''}`)}
                >
                  <ListItemText primary={(
                    <Typography style={useStyles.nestedMenuItem}>
                      Home
                    </Typography>
                  )}
                  />
                </ListItem>
              </NavLink>
              <NavLink to="/dashboards/calculated-events" style={useStyles.linksStyle}>
                <ListItem
                  className={classNames(styles.listItem, ` ${(window.location.pathname === '/dashboards/calculated-events') ? styles.active : ''}`)}
                >
                  <ListItemText primary={(
                    <Typography style={useStyles.nestedMenuItem}>
                      Calculated events
                    </Typography>
                  )}
                  />
                </ListItem>
              </NavLink>
              <NavLink to="/dashboards/proactive-alerting" style={useStyles.linksStyle}>
                <ListItem
                  className={classNames(styles.listItem, ` ${(firstMatchedPath === '/dashboards/proactive-alerting') ? styles.active : ''}`)}
                >
                  <ListItemText primary={(
                    <Typography style={useStyles.nestedMenuItem}>
                      Proactive alerting (Beta)
                    </Typography>
                  )}
                  />
                </ListItem>
              </NavLink>
            </List>
          </Collapse>

          <NavLink to="/shipments" style={useStyles.linksStyle}>
            <ListItem
              className={classNames(styles.listItem, ` ${(firstMatchedPath === '/shipments') ? styles.active : ''}`)}
            >
              <Svg name="shipments" className={styles.iconSVG} />
              {expanded && (
              <ListItemText
                primary={(
                  <Typography style={useStyles.parentMenuItem}>
                    Shipments
                  </Typography>
                  )}
              />
              )}
            </ListItem>
          </NavLink>

          <RestrictedAccess resource={[
            resources.shipmentFilters.read,
            resources.sftpAccount.read,
            resources.shipmentFilters.read,
            resources.webhooks.read,
            resources.carrierConnectors.read,
            resources.customStateLabels.read,
            resources.trackingPages.read,
            resources.notificationCentre.read,
            resources.emailShipmentRegistration.read,
          ]}
          >
            <NavLink to="/tracking-pages" style={useStyles.linksStyle}>
              <ListItem
                className={classNames(styles.listItem, ` ${(firstMatchedPath === '/tracking-pages') ? styles.active : ''}`)}
              >
                <Svg name="trackingPage" className={styles.iconSVG} />
                {expanded && (
                <ListItemText
                  primary={(
                    <Typography style={useStyles.parentMenuItem}>
                      Tracking pages
                    </Typography>
                    )}
                />
                )}
              </ListItem>
            </NavLink>

            <ListItem
              onClick={() => handleClickNavMenu('reporting')}
              className={`${styles.listItem} ${expandedMenus.reporting ? styles.listItemExpanded : ''}`}
            >
              <Svg
                name="chartLine"
                className={styles.iconSVG}
              />
              <ListItemIcon style={useStyles.icon}>
                {expandedMenus.reporting ? (
                  <div
                    className={styles.chevronDown}
                  />
                ) : (
                  <div
                    className={styles.chevronRight}
                  />
                )}
              </ListItemIcon>
              {
                expanded && (
                  <ListItemText
                    primary={(
                      <Typography style={useStyles.parentMenuItem}>
                        Reporting
                      </Typography>
                    )}
                  />
                )
              }
            </ListItem>
            <Collapse in={expandedMenus.reporting} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <NavLink to="/reporting/email-statistics" style={useStyles.linksStyle}>
                  <ListItem
                    className={classNames(styles.listItem, ` ${(window.location.pathname.includes('/reporting/email-statistics/')) ? styles.active : ''}`)}
                  >
                    <ListItemText primary={(
                      <Typography style={useStyles.nestedMenuItem}>
                        Email statistics
                      </Typography>
                    )}
                    />
                  </ListItem>
                </NavLink>
                <NavLink to="/reporting/smart-filters" style={useStyles.linksStyle}>
                  <ListItem
                    className={classNames(styles.listItem, ` ${(window.location.pathname === '/reporting/smart-filters') ? styles.active : ''}`)}
                  >
                    <ListItemText primary={(
                      <Typography style={useStyles.nestedMenuItem}>
                        Smart filters statistics
                      </Typography>
                    )}
                    />
                  </ListItem>
                </NavLink>
              </List>
            </Collapse>
          </RestrictedAccess>
        </List>
        )
      }
      {
        !expanded && (
        <>
          <NavItem title={title} url="dashboards" currentUrl={currentUrl} expanded={expanded}>
            <DropdownItemUpdated baseUrl="/dashboards/states">
              Home
            </DropdownItemUpdated>
            <DropdownItemUpdated baseUrl="/dashboards/calculated-events">
              Calculated events
            </DropdownItemUpdated>
            <DropdownItemUpdated baseUrl="/dashboards/proactive-alerting">
              Proactive alerting (Beta)
            </DropdownItemUpdated>
          </NavItem>

          <NavItem title={title} url="shipments" currentUrl={currentUrl} expanded={expanded} imageOverride="shipments" svgOverride>
            <DropdownItemUpdated baseUrl="/shipments">
              Shipments
            </DropdownItemUpdated>
          </NavItem>
          <RestrictedAccess resource={[
            resources.shipmentFilters.read,
            resources.sftpAccount.read,
            resources.shipmentFilters.read,
            resources.webhooks.read,
            resources.carrierConnectors.read,
            resources.customStateLabels.read,
            resources.trackingPages.read,
            resources.notificationCentre.read,
            resources.emailShipmentRegistration.read,
          ]}
          >
            <NavItem title={title} url="tracking-pages" currentUrl={currentUrl} expanded={expanded} imageOverride="trackingPage" svgOverride>
              <DropdownItemUpdated baseUrl="/tracking-pages">
                Tracking pages
              </DropdownItemUpdated>
            </NavItem>

            <NavItem title={title} url="reporting" currentUrl={currentUrl} expanded={expanded} imageOverride="chartLine" svgOverride>
              <DropdownItemUpdated baseUrl="/reporting/email-statistics">
                Email statistics
              </DropdownItemUpdated>
              <DropdownItemUpdated baseUrl="/reporting/smart-filters">
                Smart filters statistics
              </DropdownItemUpdated>
            </NavItem>
          </RestrictedAccess>
        </>
        )
      }
    </>
  );
};

ReactDashboards.propTypes = {
  expanded: propTypes.bool.isRequired,
  currentUrl: propTypes.string.isRequired,
  title: propTypes.string.isRequired,
};

export default ReactDashboards;
