import {
  getAuthTokenValue,
  setAuthTokenValue,
  removeAuthToken,
  setEmailAddress,
  removeEmailAddress,
  getUserId,
} from 'helpers/auth';
import { removeSelectedFilters } from 'helpers/selectedFilters';
import { inactivityOnLogout, inactivityOnLogin } from 'helpers/inactivity';
import { event as gaEvent } from 'react-ga';
import { parseFiltersSearchQuery } from 'helpers/parseFiltersSearchQuery';
import { stringify as stringifyQuery } from 'helpers/queryString';
import {
  replaceDates,
  flattenData,
} from 'helpers/datetime';
import API from 'helpers/API';
import { getFormErrorsFromApiError, mapErrorObjectPropertiesMessagesToInputNamesErrors } from 'helpers/apiError';
import errorConstants from 'constants/errors';
import casing from 'casing';
import reactKeyGenerator from 'helpers/renderKeyGenerator';
import { datadogOnLogin, datadogOnLogout } from 'helpers/datadog';

// eslint-disable-next-line no-promise-executor-return
const delay = ms => new Promise(r => setTimeout(r, ms));
const responsePropertyKey = 'Response';

export function refreshToken() {
  return delay(1000);
}

export function login() {
  return (dispatch) => {
    dispatch({ type: 'LOGGING_IN' });

    const token = getAuthTokenValue();
    if (token) {
      inactivityOnLogin();
      datadogOnLogin();
      dispatch({ type: 'LOG_IN_SUCCESS' });
    } else {
      dispatch({ type: 'LOG_IN_TOKEN_EXPIRED' });
    }
  };
}

export function loginUsernamePassword(email, password) {
  return (dispatch) => {
    dispatch({ type: 'LOGGING_IN' });

    return API.loginUsernamePassword(email, password).then((response) => {
      setAuthTokenValue(response.access_token, response.refresh_token);
      setEmailAddress(email);
      inactivityOnLogin();
      datadogOnLogin();
      dispatch({ type: 'LOG_IN_SUCCESS' });
      return Promise.resolve();
    }).catch((apiErrorResponse) => {
      if (apiErrorResponse.status === 403) {
        dispatch({ type: 'LOG_IN_FAIL_INACTIVE_ACCOUNT', resendConfirmationToEmail: email });
        return Promise.resolve();
      }

      const errors = { formError: 'Incorrect email address or password' };
      return Promise.reject(errors);
    });
  };
}

export function logout() {
  removeAuthToken();
  removeEmailAddress();
  removeSelectedFilters();
  inactivityOnLogout();
  datadogOnLogout();
  gaEvent({
    category: 'Navigation',
    action: 'Log out',
  });
  return {
    type: 'LOG_OUT',
  };
}

export function showSpinner() {
  return (dispatch) => {
    dispatch({
      type: 'SHOW_APPLICATION_LOADER',
    });
  };
}

export function closeSpinner() {
  return (dispatch) => {
    dispatch({
      type: 'HIDE_APPLICATION_LOADER',
    });
  };
}

export function showPopup({ onDecline, ...config }) {
  return (dispatch) => {
    dispatch({
      type: 'OPEN_POPUP',
      ...config,
      onDecline: onDecline || (() => dispatch({ type: 'CLOSE_POPUP' })),
    });
  };
}

export function showPopClipboard(onClose) {
  return (dispatch) => {
    dispatch({
      type: 'OPEN_CLIPBOARD',

      onDecline: () => dispatch({ type: 'CLOSE_CLIPBOARD' }),

      onAccept: () => {
        dispatch({ type: 'CLOSE_CLIPBOARD' });
        dispatch({ type: 'CLOSE_CUSTOM_POPUP' });
        dispatch({ type: 'CLEAR_INPUT' });
        onClose();
      },
    });
  };
}

export function clearInputName() {
  return (dispatch) => {
    dispatch({
      type: 'CLEAR_INPUT',
    });
  };
}

export function setNewNameInput(inputName) {
  return (dispatch) => {
    dispatch({
      type: 'SET_INPUT_NAME',
      inputName,
    });
  };
}

export function closePopClipboard() {
  return dispatch => dispatch({ type: 'CLOSE_CLIPBOARD' });
}

export function showCustomPopup(component, onClose) {
  return (dispatch) => {
    dispatch({
      type: 'OPEN_CUSTOM_POPUP',
      onClose: onClose || (() => dispatch({ type: 'CLOSE_CUSTOM_POPUP' })),
      component,
    });
  };
}

export function closeCustomPopup() {
  return dispatch => dispatch({ type: 'CLOSE_CUSTOM_POPUP' });
}

export function closePopup() {
  return dispatch => dispatch({ type: 'CLOSE_POPUP' });
}

export function openCopyrightPopup() {
  return dispatch => dispatch({ type: 'OPEN_COPYRIGHT_POPUP' });
}

export function openTermsPopup() {
  return dispatch => dispatch({ type: 'OPEN_TERMS_POPUP' });
}

export function getAvailableCarriers() {
  return (dispatch) => {
    dispatch({ type: 'CARRIERS_FETCH' });

    return API.getAvailableCarriers().then((response) => {
      dispatch({ type: 'CARRIERS_SUCCESS', carriers: response.carriers });
      return Promise.resolve(response.carriers);
    });
  };
}

export function getShipmentSummaries(searchQuery) {
  return (dispatch, getState) => {
    const parsedQuery = parseFiltersSearchQuery(searchQuery, getState().carriers.carriers);
    dispatch({ type: 'SHIPMENT_SUMMARIES_FETCH', parsedQuery, searchQuery });

    // here we want do do a request using URL, that we use for filters.
    // url structure is next: `someFiled=` JSON stringified object with fields 'from' and/or 'to'
    // so ideally when everything fits it should be like this: take url => parse it =>
    // => change all dates to global (moving from 'today' or 'next7days' to a strict date object, thats the hardest
    // work, done by matchStringToDate())
    // => flatten (moving from obecjt with orderDate.from and orderDate.to to 2 fileds orderDateFrom and orderDateTo)
    // => stringify using package query-string (search in npm) => send this url string query to API

    return API.getShipmentSummaries(stringifyQuery(flattenData(replaceDates(parsedQuery)))).then((response) => {
      const states = {
        ...response.state_summaries,
      };

      Object.keys(states).forEach((color) => {
        states[color] = states[color].map(state => ({
          name: state.state_name,
          key: state.state_key,
          shipmentsAmount: state.shipments_in_state,
        }));
      });

      dispatch({ type: 'SHIPMENT_SUMMARIES_SUCCESS', states });
      return Promise.resolve(states);
    });
  };
}

export function getShipmentsMarkers(searchQuery) {
  return (dispatch, getState) => {
    const parsedQuery = parseFiltersSearchQuery(searchQuery, getState().carriers.carriers);
    dispatch({ type: 'SHIPMENTS_MARKERS_FETCH', parsedQuery, searchQuery });

    return API.getShipmentsMarkers(stringifyQuery(flattenData(replaceDates(parsedQuery))))
      .then((markers) => {
        dispatch({ type: 'SHIPMENTS_MARKERS_SUCCESS', markers });
        return Promise.resolve(markers);
      }).catch(() => {
        dispatch({ type: 'SHIPMENTS_MARKERS_ERROR' });
      });
  };
}

export function getShipments(searchQuery, ids) {
  return (dispatch, getState) => {
    const parsedQuery = parseFiltersSearchQuery(searchQuery, getState().carriers.carriers);
    dispatch({ type: 'SHIPMENTS_FETCH', parsedQuery, searchQuery });

    // here we want do do a request using URL, that we use for filters.
    // url structure is next: `someFiled=` JSON stringified object with fields 'from' and/or 'to'
    // so ideally when everything fits it should be like this: take url => parse it =>
    // => change all dates to global (moving from 'today' or 'next7days' to a strict date object, thats the hardest
    // work, done by matchStringToDate())
    // => flatten (moving from obecjt with orderDate.from and orderDate.to to 2 fileds orderDateFrom and orderDateTo)
    // => stringify using package query-string (search in npm) => send this url string query to API.
    const parsedDatesResult = replaceDates(parsedQuery);

    const flattenedDataResult = flattenData(parsedDatesResult);
    const stringifyResult = stringifyQuery({
      ...flattenedDataResult,
      limit: parsedQuery.pageSize,
    });

    return API.getShipments(stringifyResult, ids).then((response) => {
      dispatch({ type: 'SHIPMENTS_SUCCESS', ...response });
      return Promise.resolve(response);
    }).catch((error) => {
      if (error.status === 500 || error.status === 503 || error.status === 504) {
        dispatch({ type: 'SHIPMENTS_TIMEOUT', error: error.json });
      } else {
        dispatch({ type: 'SHIPMENTS_ERROR', error: error.json });
      }
    });
  };
}

export function register(companyName, firstName, lastName, email, password) {
  return (dispatch) => {
    dispatch({ type: 'REGISTERING' });
    return API.createCustomer(companyName, firstName, lastName, email, password)
      .then((response) => {
        dispatch({ type: 'REGISTER_SUCCESS', resendConfirmationToEmail: email });
        return Promise.resolve(response);
      })
      .catch((apiErrorResponse) => {
        if (apiErrorResponse.status === 400) {
          const errorsToInputNameMap = {
            customer_name: { inputName: 'companyName', label: 'Company name' },
            'primary_user._first_name': { inputName: 'firstName', label: 'First name' },
            'primary_user._last_name': { inputName: 'lastName', label: 'Last name' },
            'primary_user._email': { inputName: 'email', label: 'Email' },
            'primary_user._password': { inputName: 'password', label: 'Password' },
          };
          const errors = mapErrorObjectPropertiesMessagesToInputNamesErrors(
            getFormErrorsFromApiError(apiErrorResponse.json),
            errorsToInputNameMap,
          );
          return Promise.reject(errors);
        }
        return Promise.reject({ formError: 'Unknown error occured' }); // eslint-disable-line
      });
  };
}

export function resendEmailConfirmation(email) {
  return () => API.resendEmailConfirmation(email)
    .then(() => Promise.resolve('Email was successfully re-sent'))
    .catch((e) => {
      if (e.status === 404) {
        return Promise.resolve('Email was not found');
      }
      return Promise.resolve('Unknown error occured');
    });
}

export function getCreateConfiguration(id) {
  return (dispatch) => {
    dispatch({ type: 'CREATE_CONFIGURATION_FETCH' });

    return API.getTrackingService(id).then((response) => {
      const configuration = { id: 0, trackingServiceId: id, ...(casing.camelize(response)) };
      dispatch({ type: 'CREATE_CONFIGURATION_SUCCESS', configuration });
      return Promise.resolve(configuration);
    }).catch(() => {
      dispatch({ type: 'TRACKING_SERVICES_ERROR', error: 'Failed to load the Connector.' });
    });
  };
}

export function getEditConfiguration(id) {
  return (dispatch) => {
    dispatch({ type: 'EDIT_CONFIGURATION_FETCH' });

    return API.getConfiguration(id).then((response) => {
      const configuration = { id, ...(casing.camelize(response)) };
      dispatch({ type: 'EDIT_CONFIGURATION_SUCCESS', configuration });
      return Promise.resolve(configuration);
    }).catch(() => {
      dispatch({ type: 'TRACKING_SERVICES_ERROR', error: 'Failed to load the Connector.' });
    });
  };
}

export function clearCreateConfiguration() {
  return (dispatch) => {
    dispatch({ type: 'CREATE_CONFIGURATION_CLEAR' });
  };
}

export function clearEditConfiguration() {
  return (dispatch) => {
    dispatch({ type: 'EDIT_CONFIGURATION_CLEAR' });
  };
}

const getTrackingServicesApi = (dispatch) => {
  dispatch({ type: 'TRACKING_SERVICES_FETCH' });

  return API.getTrackingServices().then((response) => {
    const { trackingServices } = casing.camelize(response);
    dispatch({ type: 'TRACKING_SERVICES_SUCCESS', keys: trackingServices });
    return Promise.resolve(trackingServices);
  }).catch(() => {
    dispatch({ type: 'TRACKING_SERVICES_ERROR', error: 'Failed to load the Connectors.' });
  });
};

export function getTrackingServices() {
  return dispatch => getTrackingServicesApi(dispatch);
}

export function addConfiguration(configuration) {
  return dispatch => API.addConfiguration({ configuration: casing.snakeize(configuration) }).then(() => {
    dispatch({ type: 'CREATE_CONFIGURATION_CLEAR' });

    return getTrackingServicesApi(dispatch);
  }).catch(() => {
    dispatch({ type: 'CREATE_CONFIGURATION_ERROR', error: 'Failed to save the Connector.' });
  });
}

export function updateConfiguration(configuration) {
  return dispatch => API.updateConfiguration(configuration.id, { configuration: casing.snakeize(configuration) })
    .then(() => {
      dispatch({ type: 'EDIT_CONFIGURATION_CLEAR' });
      return getTrackingServicesApi(dispatch);
    }).catch(() => {
      dispatch({ type: 'EDIT_CONFIGURATION_ERROR', error: 'Failed to save the Connector.' });
    });
}

export function deleteConfiguration(id) {
  return dispatch => API.deleteConfiguration(id).then(() => getTrackingServicesApi(dispatch)).catch(() => {
    dispatch({ type: 'TRACKING_SERVICES_ERROR', error: 'Failed to delete the Connector.' });
  });
}

export function enableConfiguration(id) {
  return dispatch => API.enableConfiguration(id).then(() => getTrackingServicesApi(dispatch)).catch(() => {
    dispatch({ type: 'TRACKING_SERVICES_ERROR', error: 'Failed to enable the Connector.' });
  });
}

export function disableConfiguration(id) {
  return dispatch => API.disableConfiguration(id).then(() => getTrackingServicesApi(dispatch)).catch(() => {
    dispatch({ type: 'TRACKING_SERVICES_ERROR', error: 'Failed to disable the Connector.' });
  });
}

export function setCreateConfiguration(createConfiguration) {
  return dispatch => dispatch({ type: 'CREATE_CONFIGURATION_SET', createConfiguration });
}

export function setEditConfiguration(editConfiguration) {
  return dispatch => dispatch({ type: 'EDIT_CONFIGURATION_SET', editConfiguration });
}

export function activateNonPrimaryUser(nonPrimaryUserEmailConfirmationToken, firstName, lastName, password, email) {
  return (dispatch) => {
    dispatch({ type: 'REGISTERING' });
    return API.activateNonPrimaryUser(nonPrimaryUserEmailConfirmationToken, firstName, lastName, password)
      .then((r) => {
        if (r.error) {
          dispatch({ type: 'NON_PRIMARY_USER_EMAIL_CONFIRMATION_TOKEN_EXPIRED', email: r.email });
          return Promise.resolve();
        }
        setAuthTokenValue(r.access_token, r.refresh_token);
        setEmailAddress(email);
        dispatch(login());
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.status === 404) {
          dispatch({ type: 'EMAIL_CONFIRMATION_TOKEN_NOT_FOUND' });
          return Promise.resolve();
        }
        return Promise.reject(getFormErrorsFromApiError(error.json));
      });
  };
}

export function confirmEmailByToken(token) {
  return dispatch => API.confirmEmailByToken(token)
    .then((r) => {
      if (r.error) {
        // error == 400 in case of token expired
        // if token is expired for non-primary user, will be redirected to contact admin page
        if (r.error === '400' && !r.is_primary) {
          dispatch({ type: 'NON_PRIMARY_USER_EMAIL_CONFIRMATION_TOKEN_EXPIRED' });
          return Promise.resolve();
        }
        // if token expired for primary user, will be redirected to resend email page
        if (r.error === '400' && r.is_primary) {
          dispatch({ type: 'EMAIL_CONFIRMATION_TOKEN_EXPIRED', resendConfirmationToEmail: r.email });
          return Promise.resolve();
        }
        // not primary user registration if token is not expired
        dispatch({
          type: 'NON_PRIMARY_USER_ACTIVATION_TOKEN_RETURNED',
          token: r.email_token,
          customerName: r.customer_name,
          firstName: r.first_name,
          lastName: r.last_name,
          email: r.email,
        });
        return Promise.resolve();
      }
      // primary user access
      setAuthTokenValue(r.access_token, r.refresh_token);
      dispatch(login());
      return Promise.resolve();
    })
    .catch((e) => {
      // if such token not found, will be redirected to login page
      if (e.status === 404) {
        dispatch({ type: 'EMAIL_CONFIRMATION_TOKEN_NOT_FOUND' });
        return Promise.resolve();
      }
      if (e.status === 400 && e.json) {
        const { details } = e.json;
        // check if there are details about an invalid response
        if (details
          && details.length > 0
          && details[0].property === responsePropertyKey) {
          const validationDetails = JSON.parse(details[0].message);
          if (validationDetails.IsPrimary) {
            dispatch({
              type: 'EMAIL_CONFIRMATION_TOKEN_EXPIRED',
              resendConfirmationToEmail: validationDetails.Email,
            });
            return Promise.resolve();
          }
          dispatch({ type: 'NON_PRIMARY_USER_EMAIL_CONFIRMATION_TOKEN_EXPIRED' });
          return Promise.resolve();
        }
      }

      // if auth api is unavailable
      dispatch({ type: 'EMAIL_CONFIRMATION_TOKEN_UNKNOWN_ERROR' });
      return Promise.resolve();
    });
}

export function registerValidationErrorsOccured(errors) {
  return (dispatch) => {
    dispatch({ type: 'REGISTER_VALIDATION_ERRORS', errors });
  };
}

export function showForgotPasswordNewPassword(token) {
  return (dispatch) => {
    dispatch({ type: 'FORGOT_PASSWORD_TOKEN_IN_URL', token });
  };
}

export function registerLoginNewTabSelected(tabIndex) {
  return (dispatch) => {
    dispatch({ type: 'LOGIN_REGISTER_TAB_SELECTED', tabIndex });
  };
}

export function forgotPasswordClick() {
  gaEvent({
    category: 'Login',
    action: 'Forgot password click',
  });
  return (dispatch) => {
    dispatch({ type: 'FORGOT_PASSWORD_CLICK' });
  };
}

export function goBackToLogin() {
  gaEvent({
    category: 'Login',
    action: 'Go back to login',
  });
  return (dispatch) => {
    dispatch({ type: 'GO_BACK_TO_LOGIN' });
  };
}

export function resetPasswordToNewPassword(token, password) {
  return dispatch => API.resetPasswordToNewPassword(token, password)
    .then(() => {
      dispatch({ type: 'RESET_FORGOT_PASSWORD_NEW_PASSWORD_SENT' });
      return Promise.resolve();
    })
    .catch((e) => {
      if (e.status === 404) {
        dispatch({ type: 'RESET_FORGOT_PASSWORD_TOKEN_NOT_FOUND' });
        return Promise.resolve();
      }
      if (e.status === 400) {
        dispatch({ type: 'RESET_FORGOT_PASSWORD_TOKEN_EXPIRED' });
        return Promise.resolve();
      }
      return Promise.reject(getFormErrorsFromApiError(e.json));
    });
}

export function resetPasswordByEmail(email) {
  return dispatch => API.resetPasswordByEmail(email)
    .then(() => {
      dispatch({ type: 'FORGOT_PASSWORD_EMAIL_SENT', email });
      return Promise.resolve();
    })
    .catch((e) => {
      const errorCode = e.json.code;
      if (errorCode) {
        if (errorCode === 'non_activated_user') {
          dispatch({ type: 'FORGOT_PASSWORD_EMAIL_INACTIVE_ACCOUNT', email });
          return Promise.resolve();
        }
        if (errorCode === 'email_for_invitation') {
          dispatch({ type: 'FORGOT_PASSWORD_EMAIL_FOR_INVITATION', email });
          return Promise.resolve();
        }
      }
      return Promise.reject(getFormErrorsFromApiError(e.json));
    });
}

export function getUsers() {
  return (dispatch) => {
    dispatch({ type: 'USERS_FETCH' });
    return API.getUsers()
      .then((r) => {
        dispatch({ type: 'USERS_SUCCESS', userItems: r.users, invitationItems: r.invitations });
      })
      .catch((r) => {
        if (r.status === 404) {
          dispatch({ type: 'USERS_SUCCESS', userItems: [], invitationItems: [] });
          return Promise.resolve();
        }
        return Promise.reject(r);
      });
  };
}

export function inviteUser(firstName, lastName, email, roleId) {
  return dispatch => API.inviteUser(firstName, lastName, email, roleId)
    .then(() => {
      dispatch({ type: 'INVITE_USER_CREATED' });
    })
    .catch((e) => {
      if (e.status === 400) {
        const erorrsMap = {
          first_name: { inputName: 'firstName', label: 'First name' },
          last_name: { inputName: 'lastName', label: 'Last name' },
          email: { inputName: 'email', label: 'Email' },
          role_id: { inputName: 'roleId', label: 'User type' },
        };
        const errors = getFormErrorsFromApiError(e.json);
        return Promise.reject(mapErrorObjectPropertiesMessagesToInputNamesErrors(errors, erorrsMap));
      }
      return Promise.reject({ formError: errorConstants.unknownErrorOccurred }); // eslint-disable-line
    });
}

export function deleteUser(userId, onDeleteSelfUserFunc) {
  return dispatch => API.deleteUser(userId)
    .then(() => {
      if (getUserId() === userId) {
        onDeleteSelfUserFunc();
        dispatch(logout());
        return;
      }
      dispatch({ type: 'USER_DELETE_SUCCESS', userId });
      dispatch(showPopup({ title: errorConstants.users.userDeleteSuccess }));
    })
    .catch((e) => {
      if (e.status === 404) {
        dispatch(showPopup({ title: 'Error', description: errorConstants.users.userNotFound }));
      } else {
        dispatch(showPopup({ title: 'Error', description: errorConstants.unknownErrorOccurred }));
      }
      getUsers();
      return Promise.resolve();
    });
}

export function deleteInvitation(invitationId) {
  return dispatch => API.deleteInvitation(invitationId)
    .then(() => {
      dispatch({ type: 'INVITATION_DELETE_SUCCESS', invitationId });
      dispatch(showPopup({ title: errorConstants.users.userDeleteSuccess }));
    })
    .catch((e) => {
      if (e.status === 404) {
        dispatch(showPopup({ title: 'Error', description: errorConstants.users.invitationNotFound }));
      } else {
        dispatch(showPopup({ title: 'Error', description: errorConstants.unknownErrorOccurred }));
      }
      getUsers();
      return Promise.resolve();
    });
}

export const getShipmentById = shipmentId => (dispatch) => {
  dispatch({ type: 'SHIPMENT_FETCH' });
  return API.getShipmentById(shipmentId)
    .then((shipmentDataResponse) => {
      dispatch({ type: 'SHIPMENT_SUCCESS', shipmentData: shipmentDataResponse.shipment });
    })
    .catch((errorResponse) => {
      if (errorResponse.status === 404) {
        dispatch({ type: 'SHIPMENT_NOT_FOUND' });
        return Promise.resolve(errorResponse);
      }
      dispatch({ type: 'SHIPMENT_ERROR' });
      return Promise.resolve(errorResponse);
    });
};

export const getTrackingEventGroupsById = shipmentId => (dispatch) => {
  dispatch({ type: 'SHIPMENT_TRACKING_EVENTS_FETCH' });
  API.getTrackingEventGroupsById(shipmentId)
    .then((trackingEventsDataResponse) => {
      if (!trackingEventsDataResponse.states || trackingEventsDataResponse.states.length === 0) {
        dispatch({ type: 'SHIPMENT_TRACKING_EVENTS_NOT_FOUND' });
        return;
      }
      dispatch({
        type: 'SHIPMENT_TRACKING_EVENTS_SUCCESS',
        states: trackingEventsDataResponse.states.map(x => ({
          ...x,
          renderKey: reactKeyGenerator.key(),
        })),
        signee: trackingEventsDataResponse.signee,
      });
    })
    .catch((errorResponse) => {
      if (errorResponse.status === 404) {
        dispatch({ type: 'SHIPMENT_TRACKING_EVENTS_NOT_FOUND' });
        return Promise.resolve(errorResponse);
      }
      dispatch({ type: 'SHIPMENT_TRACKING_EVENTS_ERROR' });
      return Promise.resolve(errorResponse);
    });
};

export function toggleMap() {
  return dispatch => dispatch({ type: 'TOGGLE_MAP' });
}

export function toggleCalcEventsMap() {
  return dispatch => dispatch({ type: 'TOGGLE_CALC_EVENTS_MAP' });
}
