import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { withRouter } from 'react-router';
import { validateForm } from 'helpers/validateForm';
import { required, maxLength } from 'helpers/validate';
import API from 'helpers/API';
import {
  showPopup as showPopupAction,
} from 'actions';
import { connect } from 'react-redux';
import ButtonToolbar from './ButtonToolbar';
import TemplateForm from './TemplateForm';
import styles from './styles.scss';

function validate(template) {
  const rules = {
    name: [required(() => '{0} should not be empty.'),
      maxLength(100, () => 'The length of Name must be 100 characters or fewer.'),
    ],
    subject: [required(() => '{0} should not be empty.')],
    html: [required(() => '{0} should not be empty.')],
  };
  return validateForm(rules, template);
}

function EditTemplate({ match, showPopup }) {
  const [notificationConnectorOptions, setNotificationConnector] = useState({
    disabled: true,
    options: [],
  });
  const [template, setTemplate] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isHtmlEdited, setIsHtmlEdited] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const { configurationId, templateId } = match.params;
      const response = await API.getTemplate(configurationId, templateId);
      const option = { value: response.sinkConfigurationName, label: response.sinkConfigurationName };

      setTemplate({ name: response.name, subject: response.subject, html: response.htmlBody });
      setNotificationConnector(prev => ({
        ...prev,
        options: [[option]],
        defaultValue: option.value,
      }));
      setIsLoading(false);
    };
    fetchData();
  }, [match]);

  const getServerErrors = (errorResponse) => {
    if (errorResponse && errorResponse.json && errorResponse.json.details) {
      return errorResponse.json.details.map(x => x.message);
    }
    return {};
  };

  const handleSubmit = async () => {
    if (!errors.hasErrors) {
      setIsLoading(true);
      const { configurationId, templateId } = match.params;
      const request = { name: template.name, subject: template.subject, htmlBody: template.html };
      try {
        await API.updateTemplate(configurationId, templateId, request);
        setIsHtmlEdited(false);
      } catch (e) {
        const serverErrors = getServerErrors(e);
        showPopup({ title: 'Your changes weren\'t saved', description: serverErrors[0] });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleFormChange = ({ name, value }) => {
    const updatedTemplate = { ...template, [name]: value };
    setTemplate(updatedTemplate);

    const validationErrors = validate(updatedTemplate);
    setErrors(validationErrors);

    setIsHtmlEdited(true);
  };

  const handlePreview = () => {
    const { configurationId, templateId } = match.params;
    window.open(`/settings/notification-centre/notification-connectors/${configurationId}/templates/${templateId}/preview`);
  };

  return (
    <TemplateForm
      title="Edit template"
      buttons={(
        <ButtonToolbar onSaveClick={handleSubmit}>
          <button
            className={classnames(styles.btnPreview)}
            onClick={handlePreview}
            type="button"
            disabled={isHtmlEdited}
          >Preview
          </button>
        </ButtonToolbar>
      )}
      notificationConnectorOptions={notificationConnectorOptions}
      template={template}
      onFormChange={handleFormChange}
      errors={errors}
      isLoading={isLoading}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  showPopup: config => dispatch(showPopupAction(config)),
});

export default withRouter(connect(null, mapDispatchToProps)(EditTemplate));
