import React from 'react';
import AuthorisedEmailIcon from 'images/authorisedEmail.svg';
import DualButtons from 'components/DualButtons';
import Switch from 'components/Switch';
import CardShell from 'components/CardShell';
import styles from './AuthorisedEmailCard.scss';

function AuthorisedEmailCard(
  {
    authorisedEmail: { id, authorisedSenderEmailAddress, isActive },
    toggleActive, deleteAuthorisedEmail, history,
  },
) {
  return (
    <CardShell>
      <div className={styles.container}>
        <h3 className={`textCenter subtitle1 ${styles.cardTitle}`}>{authorisedSenderEmailAddress}</h3>
        <AuthorisedEmailIcon className={styles.logoHolder} />

        <div className={styles.switchContainer}>
          <Switch
            id={id}
            enabled={isActive}
            toggle={() => toggleActive(id)}
          />
        </div>
        <DualButtons
          leftText="Edit"
          leftFunction={() => history.push(`/settings/register-shipment-webhooks/authorised-emails/${id}/edit`)}
          rightText="Delete"
          rightFunction={() => deleteAuthorisedEmail(id)}
        />
      </div>
    </CardShell>
  );
}

export default AuthorisedEmailCard;
