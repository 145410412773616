import React from 'react';
import styles from './AuthenticationInput.scss';

export default function AuthenticationInput({
  label, type, name, value, onChange, error, disabled,
}) {
  return (
    <>
      <label htmlFor={name} className={styles.formLabel}>{label}</label>
      {error && (
      <div className={styles.errorMessage}>
        {error}
      </div>
      ) }
      <input
        disabled={disabled}
        type={type}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        className={`${styles.formInput} ${error ? styles.formInputError : ''}
       `}
      />
    </>

  );
}
