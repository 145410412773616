export default (input) => {
  if (typeof input === 'object') return input;
  const value = `${input}`;
  let result = '';
  const num = +value;
  if (num >= 1000000) {
    result = `${(Math.round(num / 100000) / 10).toFixed(1)}m+`;
  } else if (num >= 1000) {
    result = `${value.substr(0, value.length - 3)},${value.substr(-3)}`;
  } else {
    result = value;
  }

  return result;
};
