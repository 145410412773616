import React, { Component } from 'react';
import cx from 'classnames';
import { format } from 'date-fns';
import EventItem from 'components/Timeline/EventItem';
import Carousel from 'components/Carousel';
import CarouselItem from 'components/Carousel/CarouselItem';
import List from 'components/List';
import ListItem from 'components/List/ListItem';
import { splitCamelCaseString } from 'helpers/letterCase';
import styles from 'components/Timeline/StateItem/style.scss';

class StateItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  handleStateClick = () => {
    this.setState(s => ({ isExpanded: !s.isExpanded }));
  };

  render() {
    const {
      code,
      timestamp,
      events,
      isActive,
    } = this.props;
    const {
      isExpanded,
    } = this.state;

    const category = this.props.category ? this.props.category.toLowerCase() : null;

    const orderedEvents = events.length > 0
      ? events
        .map(i => ({ ...i, timestamp: new Date(i.timestamp) }))
        .sort((a, b) => b.timestamp - a.timestamp)
      : [];

    return (
      <>
        <div
          role="button"
          className={cx(
            styles.state,
            isActive ? [styles.active, styles[category]] : null,
            isExpanded ? null : styles.collapsed,
          )}
          onClick={this.handleStateClick}
        >
          <div className={styles.stateName}>{splitCamelCaseString(code)}</div>
          <div className={styles.stateDateTime}>{format(timestamp, 'DD/MM HH:mma')}</div>
          <div className={styles.expandStatus}>{isExpanded ? '–' : '+'}</div>
        </div>
        {isExpanded && (
          <>
            <Carousel className={styles.eventsCarousel}>
              {orderedEvents.map((e, i) => (
                <CarouselItem key={e.timestamp} className={styles.carouselItem}>
                  <EventItem
                    timestamp={e.timestamp}
                    receivedByCarrierTimestamp={e.received_timestamp}
                    eventCode={e.event_code}
                    description={e.description}
                    location={e.location}
                    rawEvent={e.raw_event}
                    category={category}
                    isActive={isActive && i === 0}
                    isActiveState={isActive}
                  />
                </CarouselItem>
              ))}
            </Carousel>
            <List className={styles.eventsList}>
              {orderedEvents.map((e, i) => (
                <ListItem key={e.timestamp} className={styles.listItem}>
                  <EventItem
                    timestamp={e.timestamp}
                    receivedByCarrierTimestamp={e.received_timestamp}
                    eventCode={e.event_code}
                    description={e.description}
                    location={e.location}
                    rawEvent={e.raw_event}
                    category={category}
                    isActive={isActive && i === 0}
                    isActiveState={isActive}
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </>
    );
  }
}

export default StateItem;
