import React from 'react';
import { withRouter } from 'react-router';
import ShipmentFilterForm from 'containers/ShipmentFilterForm';
import CardShell from 'components/CardShell';

const CreateShipmentFilterPage = ({ history }) => {
  const backToShipmentFilterList = () => {
    history.push('/settings/shipment-filters');
  };

  return (
    <>
      <h2>Create Shipment Filter</h2>
      <div className="container__cards-large">
        <CardShell
          noOverflow
        >
          <ShipmentFilterForm flipCard={backToShipmentFilterList} />
        </CardShell>
      </div>
    </>
  );
};

export default withRouter(CreateShipmentFilterPage);
