import React, { Component } from 'react';
import CardShell from 'components/CardShell';
import CreateCard from 'components/CreateCard';
import { compareArrayByOrder } from 'helpers/common';
import PropTypes from 'prop-types';
import Hr from 'components/CardShell/Hr';
import LinkItem from './LinkItem';
import styles from '../style.scss';
import NavigationBarItemAdd from './NavigationBarItemAdd';

class NavigationBarItems extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    errors: PropTypes.arrayOf(PropTypes.any),
    touched: PropTypes.arrayOf(PropTypes.any),
  };

  static defaultProps = {
    errors: [],
    touched: [],
  };

  static MaxItems = 10;

  constructor(props) {
    super(props);
    this.state = {
      isAddRowPopupOpened: false,
    };
  }

  toggleAddNavigationBarItem = () => this.setState(prevState => ({
    isAddRowPopupOpened: !prevState.isAddRowPopupOpened,
  }));

  handleAddItem = (newItem) => {
    const updatedNavigationItems = [...this.props.items];
    const newItemWithOrder = { ...newItem, order: updatedNavigationItems.length };
    updatedNavigationItems.push(newItemWithOrder);
    this.props.onChange(updatedNavigationItems);
    this.toggleAddNavigationBarItem();
  };

  handleChange = (index, value) => {
    const items = [...this.props.items];
    items[index] = value;
    this.props.onChange(items);
  };

  handleTouch = (index, value) => {
    const touched = [...this.props.touched];
    touched[index] = value;
    this.props.onTouch(touched);
  };

  handleLinkChange = (index, field, value) => {
    const newLink = {
      ...this.props.items[index],
      [field]: value,
    };
    this.handleChange(index, newLink);
  };

  handleLinkTouch = (index, field, value) => {
    const newTouched = {
      ...this.props.touched[index],
      [field]: value,
    };
    this.handleTouch(index, newTouched);
  };

  alterOrder = (index, direction) => {
    const {
      items,
    } = this.props;
    const currentOrder = items[index].order;
    let newOrder = currentOrder;
    if (direction === 'up') {
      newOrder -= 1;
    } else if (direction === 'down') {
      newOrder += 1;
    }
    const replacementIndex = items.map(i => i.order).indexOf(newOrder);

    const newItems = [...items];
    newItems[index] = {
      ...newItems[index],
      order: newOrder,
    };
    newItems[replacementIndex] = {
      ...newItems[replacementIndex],
      order: currentOrder,
    };
    this.props.onChange(newItems);
  };

  canAlterOrder = (index, direction) => {
    const {
      items,
    } = this.props;
    let newOrder = items[index].order;
    if (direction === 'up') {
      newOrder -= 1;
    } else if (direction === 'down') {
      newOrder += 1;
    }
    return newOrder >= 0 && newOrder < items.length;
  };

  deleteLink = (index) => {
    const {
      items,
      touched,
      onChange,
      onTouch,
    } = this.props;
    const item = items[index];
    const { order } = item;
    const newItems = items.map(i => ({
      ...i,
      order: i.order > order ? i.order - 1 : i.order,
    }));
    newItems.splice(index, 1);
    onChange(newItems);
    const newTouched = [...touched];
    newTouched.splice(index, 1);
    onTouch(newTouched);
  };

  render() {
    const { isAddRowPopupOpened } = this.state;
    const {
      items,
      errors,
      touched,
      innerRefs,
    } = this.props;
    return (
      <>
        <CardShell noOverflow>
          <div className={styles.cardInner}>
            <div className={styles.cardInnerFix}>
              <h3 className={styles.title}>Nav items</h3>
              <Hr />
              {items && [...items].sort(compareArrayByOrder)
                .map((link) => {
                  const originalIndex = items.map(i => i.id).indexOf(link.id);
                  return (
                    <LinkItem
                      key={link.id}
                      id={link.id}
                      order={link.order}
                      redirectionUrl={link.redirectionUrl}
                      text={link.text}
                      items={items}
                      touched={touched && touched[originalIndex]}
                      alterOrder={direction => this.alterOrder(originalIndex, direction)}
                      canAlterOrder={direction => this.canAlterOrder(originalIndex, direction)}
                      deleteLink={() => this.deleteLink(originalIndex)}
                      errors={errors && errors[originalIndex]}
                      onChange={(field, value) => this.handleLinkChange(originalIndex, field, value)}
                      onTouch={(field, value) => this.handleLinkTouch(originalIndex, field, value)}
                      innerRefs={innerRefs[originalIndex]}
                    />
                  );
                })}
              <div
                className={styles.formGroup}
                role="button"
                onClick={this.toggleAddNavigationBarItem}
              >
                {items.length < NavigationBarItems.MaxItems && <CreateCard text="Add a new nav item" thin />}
              </div>
            </div>
          </div>
        </CardShell>
        {isAddRowPopupOpened && (
          <NavigationBarItemAdd
            onAdd={item => this.handleAddItem(item)}
            onClose={this.toggleAddNavigationBarItem}
          />
        )}
      </>
    );
  }
}

export default NavigationBarItems;
