const Int = 'int';
const Qa = 'qa';
const Perf = 'perf';
const Prod = 'prod';
const Sandbox = 'sandbox';

export function getEnvironmentLabel() {
  const { hostname } = window.location;

  if (hostname.match(/app\.sorted\.com/i)) {
    return Prod;
  }

  const hostnameRegex = /app-(\w+)\.sorted\.com/i;
  const match = hostname.match(hostnameRegex);

  if (match) {
    const label = match[1];
    return label.toLowerCase();
  }
  return null;
}

export const environmentLabels = {
  Int,
  Qa,
  Perf,
  Prod,
  Sandbox,
};
