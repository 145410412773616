import { addDays } from 'date-fns';

export function customCalculateStartDate(period) {
  const today = new Date();
  let result;
  switch (period) {
    case '1':
      result = addDays(today, -45);
      break;
    case '2':
      result = addDays(today, -30);
      break;
    case '3':
      result = addDays(today, -15);
      break;
    case '4':
      result = addDays(today, -7);
      break;
    case '5':
      result = today;
      break;
    default:
      result = addDays(today, -15);
  }
  return result;
}

export const customPeriods = [
  { key: '1', value: 'Last 45 days', periodStr: 'in the last 45 days' },
  { key: '2', value: 'Last 30 days', periodStr: 'in the last 30 days' },
  { key: '3', value: 'Last 15 days', periodStr: 'in the last 15 days' },
  { key: '4', value: 'Last 7 days', periodStr: 'in the last 7 days' },
  { key: '5', value: 'Today', periodStr: 'today' },
];
