const initialState = {
  open: false,
  title: '',
  description: '',
  onAccept: null,
  onDecline: null,
};

export default function popupReducer(state = initialState, action) {
  switch (action.type) {
    case 'OPEN_POPUP':
      return {
        open: true,
        title: action.title,
        description: action.description,
        onDecline: action.onDecline,
        onAccept: action.onAccept,
      };
    case 'CLOSE_POPUP':
      return {
        open: false,
      };
    default:
      return state;
  }
}
