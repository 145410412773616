import React from 'react';
import * as propTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import styles from './styles.scss';

const DropdownItem = ({
  baseUrl,
  urlRename,
  children,
  external,
}) => {
  const location = useLocation();

  if (external) {
    return (
      <a
        href={external}
        className={styles.item}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }
  return (
    <NavLink
      to={{ pathname: `/${baseUrl}/${urlRename || children.toLowerCase()}`, state: { prevPath: location.pathname } }}
      className={styles.item}
      activeClassName={styles.itemActive}
    >
      {children}
    </NavLink>
  );
};

DropdownItem.defaultProps = {
  baseUrl: '',
  external: '',
  urlRename: '',
  children: 'item',
};

DropdownItem.propTypes = {
  baseUrl: propTypes.string,
  external: propTypes.string,
  urlRename: propTypes.string,
  children: propTypes.node,
};

export default DropdownItem;
