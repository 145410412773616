const getPercentage = (data, total) => ((100 * data) / total).toFixed(3);

export const buildCustomLabel = (bounceData, i, bounceCount) => ` ${bounceData[i].name} - ${bounceData[i].count} (${getPercentage(bounceData[i].count, bounceCount)}%)`;

export const generateLabelsHelper = (chart, bounceData, bounceCount) => {
  if (chart.data.labels.length && chart.data.datasets.length && bounceData.length) {
    return chart.data.labels.map((label, i) => {
      const meta = chart.getDatasetMeta(0);
      const style = meta.controller.getStyle(i);
      return {
        text: buildCustomLabel(bounceData, i, bounceCount),
        fillStyle: style.backgroundColor,
        strokeStyle: style.borderColor,
        lineWidth: style.borderWidth,
        hidden: !chart.getDataVisibility(i),
        index: i,
        textAlign: 'left',
        pointStyle: 'rect',
        fullSize: true,
      };
    });
  }
  return [];
};

export const beforeFitHelper = (scale) => {
  let maxValue = 0;
  if (scale.chart.config && scale.chart.config.data && scale.chart.config.data.datasets) {
    scale.chart.config.data.datasets.forEach((dataset) => {
      if (dataset && dataset.data) {
        dataset.data.forEach((value, i) => {
          if (!scale.chart.legend.legendItems[i].hidden && value > maxValue) {
            maxValue = value;
          }
        });
      }
    });
  }
  // eslint-disable-next-line no-param-reassign
  scale.max = maxValue;
};
