import React from 'react';

const st0 = { fill: '#291D4F' };
const st1 = { fill: '#FFFFFF' };
const svgStyle = { enableBackground: 'new 0 0 55.4 60.7' };
/* eslint-disable */
export default props => (
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px"
       y="0px"
       viewBox="0 0 55.4 60.7" style={svgStyle}>
    <g>
	<path style={st0} d="M27.7,0.5c-15,0-27.2,12.2-27.2,27.2c0,13,9.1,23.8,21.2,26.5l6,6l6-6c12.1-2.7,21.2-13.6,21.2-26.5
		C54.9,12.7,42.7,0.5,27.7,0.5z M36.4,22.5h1.2h0.1V27h-0.1h-1.2h-6.1c-1.2,0-2.2-1-2.2-2.3s1-2.3,2.2-2.3H36.4z M18.3,41.9
		L18.3,41.9c-1.1,0-2-0.8-2.1-1.8V27.4v-0.1c0-1.2,1-2.1,2.1-2.1c2.5,0,3.4-5.6,3.4-5.6c0.7-4.3,0.9-7.2,0.9-7.2S22.7,9.8,24,10
		c0,0,3.7-0.2,3.3,5.5c0,0,0,0,0,0l0,0l-0.6,6c-0.1,0.5-0.3,2.2-1,3.3L26,25c0.5-0.7,0.8-1.8,1-2.5h1.8c-0.7,0.5-1.1,1.4-1.1,2.3
		c0,1.1,0.7,2.1,1.6,2.5c-0.9,0.4-1.6,1.4-1.6,2.5c0,1.1,0.7,2.1,1.6,2.5c-0.9,0.4-1.6,1.4-1.6,2.5c0,1.1,0.7,2.1,1.6,2.5
		c-0.9,0.4-1.6,1.4-1.6,2.5c0,0.8,0.4,1.6,1,2.1H18.3z M37.8,41.9h-7.5c-1.2,0-2.1-1-2.1-2.2c0-1.2,0.9-2.2,2.1-2.2c0,0,0,0,0,0h7.5
		V41.9z M38.8,37.1h-8.4c-1.2,0-2.2-1-2.2-2.3c0-1.2,1-2.3,2.2-2.3c0,0,0,0,0,0h8.4V37.1z M39.1,32.1h-8.8c0,0,0,0,0,0
		c-1.2,0-2.2-1-2.2-2.3c0-1.3,1-2.3,2.2-2.3h8.8V32.1z"/>
      <path style={st1}
            d="M28.1,29.8c0,1.2,1,2.3,2.2,2.3c0,0,0,0,0,0h8.8v-4.5h-8.8C29.1,27.5,28.1,28.5,28.1,29.8z"/>
      <path style={st1} d="M29.3,37.3c-0.9-0.4-1.6-1.4-1.6-2.5c0-1.1,0.7-2.1,1.6-2.5c-0.9-0.4-1.6-1.4-1.6-2.5c0-1.1,0.7-2.1,1.6-2.5
		c-0.9-0.4-1.6-1.4-1.6-2.5c0-0.9,0.5-1.8,1.1-2.3H27c-0.2,0.8-0.5,1.8-1,2.5l-0.4-0.3c0.7-1,0.9-2.7,1-3.3l0.6-6l0,0c0,0,0,0,0,0
		C27.7,9.8,24,10,24,10c-1.3-0.1-1.4,2.4-1.4,2.4s-0.2,2.8-0.9,7.2c0,0-0.9,5.6-3.4,5.6c-1.2,0-2.1,1-2.1,2.1v0.1v12.7
		c0.1,1,1.1,1.8,2.1,1.8v0h10.3c-0.6-0.5-1-1.3-1-2.1C27.7,38.7,28.3,37.7,29.3,37.3z"/>
      <path style={st1}
            d="M28.1,24.8c0,1.3,1,2.3,2.2,2.3h6.1h1.2h0.1v-4.5h-0.1h-1.2h-6.1C29.1,22.5,28.1,23.5,28.1,24.8z"/>
      <path style={st1}
            d="M30.2,37.5c-1.2,0-2.1,1-2.1,2.2c0,1.2,1,2.2,2.1,2.2h7.5v-4.4L30.2,37.5C30.3,37.5,30.2,37.5,30.2,37.5z"/>
      <path style={st1}
            d="M30.3,32.5c-1.2,0-2.2,1-2.2,2.3c0,1.3,1,2.3,2.2,2.3h8.4v-4.5L30.3,32.5C30.3,32.5,30.3,32.5,30.3,32.5z"/>
</g>
</svg>
)
