import React from 'react';

/* eslint-disable */
export default props => (
  <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42 28'>
      <path d='M 40 14 Q 40.004296875 18.9421875 36.5 22.5 32.9421875 26.004296875 28 26 23.011328125 26.004296875 19.5 22.5 L 19.5 22.5 Q 18.1666015625 21.0931640625 17.25 19.5 17.0720703125 19.151953125 16.65 19.05 16.2826171875 18.9294921875 15.9 19.1 15.56328125 19.327734375 15.45 19.7 15.3294921875 20.1287109375 15.5 20.5 16.5333984375 22.3068359375 18.05 23.9 L 18.1 23.9 Q 22.188671875 27.995703125 28 28 33.757421875 27.9958984375 37.9 23.9 41.9958984375 19.757421875 42 14 41.995703125 8.188671875 37.9 4.1 33.757421875 0.0041015625 28 0 22.188671875 0.004296875 18.1 4.1 16.4892578125 5.7044921875 15.5 7.5 15.329296875 7.8994140625 15.45 8.3 15.5654296875 8.6875 15.9 8.85 16.2984375 9.0708984375 16.7 8.95 17.087109375 8.83359375 17.25 8.45 18.110546875 6.8955078125 19.5 5.5 23.011328125 1.995703125 28 2 32.9421875 1.995703125 36.5 5.5 40.004296875 9.011328125 40 14 M 22.95 7 Q 22.953515625 7.4107421875 23.25 7.7 L 28.6 13.05 1 13.05 Q 0.5892578125 13.053515625 0.3 13.35 0.003515625 13.6392578125 0 14.05 0.003515625 14.4607421875 0.3 14.75 0.5892578125 15.046484375 1 15.05 L 28.6 15.05 23.25 20.4 Q 22.954296875 20.6900390625 22.95 21.1 22.9529296875 21.50859375 23.25 21.8 23.539453125 22.0962890625 23.95 22.1 24.358203125 22.096484375 24.65 21.8 L 31.7 14.75 Q 31.996484375 14.4607421875 32 14.05 31.996484375 13.6392578125 31.7 13.35 L 24.65 6.3 Q 24.3607421875 6.003515625 23.95 6 23.5392578125 6.003515625 23.25 6.3 22.953515625 6.5892578125 22.95 7 Z' />
  </svg>
)

/* eslint-enable */
