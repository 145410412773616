/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import React from 'react';
import Card from 'components/Card';
import Svg from 'components/Svg';
import styles from './FiltersCard.scss';

export default function FilterCard({
  title,
  link,
  amount,
  total,
  onClick,
  ...props
}) {
  return (
    <Card
      title={title}
      value={<Svg name={link} className={styles.icon} />}
      description={`${amount} ${title === 'Origin country' ? 'Origin shipping location' : title} filtered`}
      leftActionText="Add"
      leftAction={() => onClick(link)}
      {...props}
    />
  );
}
