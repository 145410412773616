import {
  TRACKING_PAGE_FETCH,
  TRACKING_PAGE_FETCH_SUCCESS,
  UPDATE_TRACKING_PAGE_FETCH,
  UPDATE_TRACKING_PAGE_SUCCESS,
  CREATE_TRACKING_PAGE_FETCH,
  CREATE_TRACKING_PAGE_SUCCESS,
  CREATE_TRACKING_PAGE_ERROR,
  UPDATE_TRACKING_PAGE_ERROR,
  TRACKING_PAGE_RESET,
} from 'actions/types';

const initialState = {
  id: null,
  trackingPageName: null,
  createdOn: null,
  publishedOn: null,
  version: null,
  trackingPageData: null,
  invalidTrackingPageOverrideName: null,
  isLoading: false,
  errorResponse: null,
};

function editTrackingPageReducer(state = initialState, action) {
  switch (action.type) {
    case TRACKING_PAGE_FETCH:
    case UPDATE_TRACKING_PAGE_FETCH:
    case CREATE_TRACKING_PAGE_FETCH:
      return {
        ...state,
        isLoading: true,
        errorResponse: null,
      };
    case TRACKING_PAGE_FETCH_SUCCESS:
    case UPDATE_TRACKING_PAGE_SUCCESS:
    case CREATE_TRACKING_PAGE_SUCCESS:
      return {
        id: action.trackingPage.id,
        trackingPageName: action.trackingPage.trackingPageName,
        createdOn: action.trackingPage.createdOn,
        publishedOn: action.trackingPage.publishedOn,
        version: action.trackingPage.version,
        trackingPageData: { ...action.trackingPage.trackingPageData },
        invalidTrackingPageOverrideName: action.trackingPage.invalidTrackingPageOverrideName,
        isLoading: false,
        errorResponse: null,
      };
    case UPDATE_TRACKING_PAGE_ERROR:
    case CREATE_TRACKING_PAGE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorResponse: action.errorResponse,
      };
    case TRACKING_PAGE_RESET:
      return initialState;
    default:
      return state;
  }
}

export default editTrackingPageReducer;
