import React from 'react';
import ChartComponent from 'components/ChartComponent';
import styles from './BounceBarChart.scss';
import colors from '../colors';
import ErrorMessage from '../pieCharts/components/ErrorMessage/ErrorMessage';
import { customPeriods } from '../statistics/BounceStatistics/customCalculateStartDate';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import uiBreakpoints from '../../../../helpers/uiBreakpoints';
import { generateLabelsHelper, beforeFitHelper, buildCustomLabel } from './ChartFunctions';

const buildBounceData = bounceData => Array.from(bounceData)?.filter((el) => (el.count !== 0));

export default function BounceBarChart({ bounces, dateKey }) {
  const isSmallScreen = useMediaQuery(`(max-width: ${uiBreakpoints.Desktop}px)`);
  const bounceData = buildBounceData(bounces.BounceReasons);
  const { TotalBounceCount: bounceCount } = bounces;

  const chartData = {
    type: 'bar',
    options: {
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        yAxes: {
          beforeFit(scale) {
            beforeFitHelper(scale);
          },
          ticks: {
            callback(value) {
              if (value % 1 === 0) return value;
              return null;
            },
          },
        },
      },
      plugins: {
        legend: {
          position: isSmallScreen ? 'bottom' : 'right',
          labels: {
            usePointStyle: true,
            pointStyleWidth: 20,
            generateLabels(chart) {
              return generateLabelsHelper(chart, bounceData, bounceCount);
            },
          },
          onClick(e, legendItem, legend) {
            legend.chart.toggleDataVisibility(legendItem.index);
            legend.chart.update();
          },
        },
        tooltip: {
          titleMarginBottom: 0,
          callbacks: {
            label(tooltipItem) {
              return buildCustomLabel(bounceData, tooltipItem.dataIndex, bounceCount);
            },
          },
        },
      },
    },
    chartData: {
      labels: Array(bounceData.length).fill(''),
      datasets: [
        {
          data: Array.from(bounceData)?.map((el) => el.count),
          backgroundColor: colors,
          borderWidth: 0,
        },
      ],
    },
  };

  const hasData = !!Array.from(bounceData).some((elt) => elt.count > 0);

  return (
    <div className={styles.barChart}>
      <div className={styles.chartContainerTitle}>Bounced email reasons</div>
      {hasData ? (
        <div className={styles.chartContainer}>
          <ChartComponent chartProps={chartData} />
        </div>
      ) : (
        <div className={styles.chartContainer}>
          <ErrorMessage dateKey={dateKey} customPeriods={customPeriods} />
        </div>
      )}
    </div>
  );
}
