import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import CreateCard from 'components/CreateCard';
import Spinner from 'components/Spinner';
import {
  showPopup as showPopupAction,
  closePopup as closePopupAction,
} from 'actions';
import {
  getTrackingPages as getTrackingPagesAction,
  deleteTrackingPage as deleteTrackingPageAction,
} from 'actions/trackingPagesActions';

import TrackingPageCard from './TrackingPageCard';
import TitleNavBlock from '../../../components/TitleNavBlock/TitleNavBlock';
import typography from '../../../helpers/appTypography';

class TrackingPagesList extends Component {
  componentDidMount() {
    this.props.getTrackingPages();
  }

  render() {
    const {
      history,
      trackingPagesList,
      isLoading,
    } = this.props;
    return (
      <div>
        { isLoading ? <Spinner /> : null }
        {/* <LocalStorageTitle localStorageName="trackingPagesList" pageTitle="Tracking pages"> */}
        {/*  <div className="text_container"> */}
        {/*    <div className="informationalTextBlock"> */}
        {/*      <p>You can create and edit tracking pages here.</p> */}
        {/*    </div> */}
        {/*  </div> */}
        {/* </LocalStorageTitle> */}
        <TitleNavBlock
          title={typography.trackingPages.title}
          subTitle={typography.trackingPages.subTitle}
          onBack={() => window.history.back()}
        />
        <div
          className="container__cards"
          style={{ height: '100%' }}
        >
          <div
            role="button"
            onClick={() => history.push('/tracking-pages/create')}
          >
            <CreateCard text="Create new Tracking Page" />
          </div>

          {trackingPagesList && [...trackingPagesList]
            .sort((a, b) => (a.title || '').localeCompare(b.title))
            .map(page => (
              <TrackingPageCard
                key={page.id}
                name={page.trackingPageName}
                title={page.title}
                createdOn={page.createdOn}
                publishedOn={page.publishedOn}
              />
            ))}

        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ trackingPages: { isLoading, trackingPagesList } }) => ({
  isLoading,
  trackingPagesList,
});

const mapDispatchToProps = dispatch => ({
  getTrackingPages: () => dispatch(getTrackingPagesAction()),
  deleteTrackingPage: trackingPageName => dispatch(deleteTrackingPageAction(trackingPageName)),
  showPopup: config => dispatch(showPopupAction(config)),
  closePopup: () => dispatch(closePopupAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TrackingPagesList));
