import React from 'react';
import styles from './styles.scss';
import CrossCircle from '../../images/crossCircle.svg';

const FormErrors = ({ errors }) => (
  <>
    {errors && errors.formError
      && (
      <div className={styles.container}>
        <CrossCircle width={20} height={20} />
        <p className={styles.errorMessage}>{errors.formError}</p>
      </div>
      )}
  </>
);

export default FormErrors;
