import API from 'helpers/API';

import {
  SMART_FILTERS_FETCH,
  SMART_FILTERS_SUCCESS,
  SMART_FILTERS_ERROR,
} from './types';

export function getSmartFilters(startDate, endDate) {
  return (dispatch) => {
    dispatch({ type: SMART_FILTERS_FETCH });

    return API.getSmartFilters(startDate, endDate).then((response) => {
      let r = response;
      if (!response || !response.smart_filter_stats_rows) {
        r = [];
      }
      const smartFilters = r.smart_filter_stats_rows;
      dispatch({ type: SMART_FILTERS_SUCCESS, smartFilters });
      return Promise.resolve(smartFilters);
    }).catch(() => { dispatch({ type: SMART_FILTERS_ERROR }); });
  };
}
export function getSmartFiltersStatistics() {
  return (dispatch) => {
    dispatch({ type: SMART_FILTERS_FETCH });

    return API.getSmartFilters().then((response) => {
      let r = response;
      if (!response) {
        r = [];
      }
      const smartFilters = r.smart_filter_stats_rows;
      dispatch({ type: SMART_FILTERS_SUCCESS, smartFilters });
      return Promise.resolve(smartFilters);
    }).catch(() => { dispatch({ type: SMART_FILTERS_ERROR }); });
  };
}
