import React, { useState, useEffect } from 'react';
import { required, maxLength } from 'helpers/validate';
import { validateForm } from 'helpers/validateForm';
import { connect } from 'react-redux';
import { showPopup as showPopupAction } from 'actions';
import API from 'helpers/API';
import TemplateForm from './TemplateForm';
import ButtonToolbar from './ButtonToolbar';

const DEFAULT_TEMPLATE = `<html>
  <head>
    <title></title>
  </head>
  <body>
  </body>
</html>`;

function validate(template) {
  const rules = {
    name: [required(() => '{0} should not be empty.'),
      maxLength(100, () => 'The length of Name must be 100 characters or fewer.'),
    ],
    subject: [required(() => '{0} should not be empty.')],
    notificationConnectorId: [required(() => '{0} should be selected.')],
    html: [required(() => '{0} should not be empty.')],
  };
  return validateForm(rules, template);
}

function CreateTemplate({ history, showPopup }) {
  const [notificationConnectorOptions, setNotificationConnector] = useState({ options: [] });
  const [template, setTemplate] = useState({
    name: '',
    subject: '',
    notificationConnectorId: null,
    html: DEFAULT_TEMPLATE,
  });
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getServerErrors = (errorResponse) => {
    if (errorResponse && errorResponse.json && errorResponse.json.details) {
      return errorResponse.json.details.map(x => x.message);
    }
    return {};
  };

  const handleSubmit = async () => {
    const validationErrors = validate(template);
    if (!validationErrors.hasErrors) {
      const request = {
        name: template.name,
        subject: template.subject,
        htmlBody: template.html,
      };
      setIsLoading(true);

      try {
        await API.createTemplate(template.notificationConnectorId, request);
        history.push('/settings/notification-centre/templates');
      } catch (e) {
        const serverErrors = getServerErrors(e);
        showPopup({ title: 'Your changes weren\'t saved', description: serverErrors[0] });
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrors(validationErrors);
      setIsSubmitClicked(true);
    }
  };

  const handleFormChange = ({ name, value }) => {
    const updatedTemplate = { ...template, [name]: value };
    setTemplate(updatedTemplate);
    if (isSubmitClicked) {
      const validationErrors = validate(updatedTemplate);
      setErrors(validationErrors);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await API.getSinkConfigurations({ areTemplatesEditable: true });
      const options = response.configurations.map(x => ({ value: x.id, label: x.name }));
      setNotificationConnector({ options });
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <TemplateForm
      title="Create template"
      buttons={<ButtonToolbar onSaveClick={handleSubmit} />}
      notificationConnectorOptions={notificationConnectorOptions}
      template={template}
      onFormChange={handleFormChange}
      errors={errors}
      isLoading={isLoading}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  showPopup: config => dispatch(showPopupAction(config)),
});

export default connect(null, mapDispatchToProps)(CreateTemplate);
