import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { generateId } from 'helpers/guid';
import BackButton from 'components/BackButton';
import Spinner from 'components/Spinner';
import ReactButton from 'components/ReactButton';
import NavigationBarItemAdd from 'components/TrackingPage/NavigationBarItems/NavigationBarItemAdd';
import {
  General, GridEdit, Link as TrackingPageLink, NavigationBar, NavigationBarItems,
} from 'components/TrackingPage';
import Font from 'components/TrackingPage/Font';
import { MobileLayoutType, StandardLayoutType } from 'constants/trackingPage/layoutTypes';
import { Medium } from 'constants/trackingPage/fontWeights';
import { LargeRowHeightType } from 'constants/trackingPage/rowHeightTypes';
import { StatusTimelineComponentType, TextComponentType } from 'constants/trackingPage/componentTypes';
import { TopLeft } from 'constants/trackingPage/rowTextPositions';
import {
  createNewTrackingPage as createNewTrackingPageAction,
  getTrackingPage as getTrackingPageAction,
  getTrackingPages as getTrackingPagesAction,
  resetTrackingPage,
} from 'actions/trackingPagesActions';
import { NonPublished, Preview } from 'constants/trackingPage/trackingPageVersions';
import { ShipmentState } from 'constants/trackingPage/displayOptions';
import { getTrackingPagePath } from 'helpers/API';
import { closePopup as closePopupAction, showPopup as showPopupAction } from 'actions';
import {
  isUrl, isUrlPart, maxLength, minArrayLength, minLength, required, validateEach,
} from 'helpers/validate';
import {
  getAllTouched,
  getRefToErrorElement,
  getServerValidationErrors,
  hasErrors,
  validateNestedForm,
} from 'helpers/validateForm';
import errorConstants from 'constants/errors';
import InvalidTrackingRefPageOverride from 'components/TrackingPage/Overrides/InvalidTrackingRef';
import styles from './styles.scss';
import typography from '../../../helpers/appTypography';

const initialFont = {
  colour: '#000000',
  fontWeight: Medium,
  name: 'Open Sans',
  size: 16,
  url: 'https://fonts.googleapis.com/css?family=Open+Sans',
};

class CreateTrackingPage extends Component {
  static validationRules = {
    name: [required(() => 'Please enter a page name. We\'ll use this in your page\'s link. '
      + 'You can\'t change it once you\'ve saved your page.'),
    maxLength(20, () => 'Please enter a shorter name. Page names should be 20 characters or less.'),
    isUrlPart(false, () => 'You can use letters, numbers, and the special characters $-_.+!*\'(),')],
    title: [required(() => 'Please enter a title for your page. You can change it later if needed.'),
      maxLength(50, () => 'Please enter a shorter title. Page titles should be 50 characters or less.')],
    font: Font.validationRules,
    navigationBar: {
      backgroundColour: [minLength(7), maxLength(7)],
      borderColour: [minLength(7), maxLength(7)],
      font: Font.validationRules,
      rootUrl: [isUrl(), maxLength(400, () => 'Please enter a shorter URL. URLs should be 400 characters or less.')],
      navigationBarItems: [validateEach(NavigationBarItemAdd.validationRules)],
    },
    layouts: [validateEach({ rows: [minArrayLength(1, () => 'Please add a row.')] })],
  };

  innerRefs = {
    name: React.createRef(),
    title: React.createRef(),
    font: {
      size: React.createRef(),
    },
    navigationBar: {
      rootUrl: React.createRef(),
      font: {
        size: React.createRef(),
      },
      navigationBarItems: Array.from({ length: NavigationBarItems.MaxItems }, () => ({
        text: React.createRef(),
        redirectionUrl: React.createRef(),
      })),
    },
    layouts: Array.from({ length: 2 }, () => ({
      rows: React.createRef(),
    })),
  };

  constructor(props) {
    super(props);
    const initialTrackingPageData = {
      title: '',
      name: '',
      isCarrierLinkActive: false,
      base64FavIcon: '',
      font: { ...initialFont },
      layouts: [
        {
          layoutType: MobileLayoutType,
          rows: [{
            id: generateId(),
            order: 0,
            rowHeight: LargeRowHeightType,
            rowItems: [
              {
                id: generateId(),
                order: 0,
                base64Image: null,
                componentType: StatusTimelineComponentType,
                redirectionUrl: '',
                rowTextPosition: TopLeft,
                text: '',
                font: {},
                displayOption: ShipmentState.code,
                locale: '',
              },
            ],
          }],
        },
        {
          layoutType: StandardLayoutType,
          rows: [{
            id: generateId(),
            order: 0,
            rowHeight: LargeRowHeightType,
            rowItems: [
              {
                id: generateId(),
                order: 0,
                base64Image: null,
                componentType: TextComponentType,
                redirectionUrl: '',
                rowTextPosition: TopLeft,
                text: '',
                font: {},
                displayOption: ShipmentState.code,
                locale: '',
              },
              {
                id: generateId(),
                order: 1,
                base64Image: null,
                componentType: StatusTimelineComponentType,
                rowTextPosition: TopLeft,
                text: '',
                font: {},
                displayOption: ShipmentState.code,
                locale: '',
              },
              {
                id: generateId(),
                order: 2,
                base64Image: null,
                componentType: TextComponentType,
                redirectionUrl: '',
                rowTextPosition: TopLeft,
                text: '',
                font: {},
                displayOption: ShipmentState.code,
                locale: '',
              },
            ],
          }],
        },
      ],
      navigationBar: {
        backgroundColour: '#FFFFFF',
        base64Logo: '',
        borderColour: '#000000',
        font: { ...initialFont },
        navigationBarItems: [],
        rootUrl: '',
      },
    };
    this.state = {
      trackingPageData: { ...initialTrackingPageData },
      touched: {},
      errors: {},
      invalidTrackingRefPageOverride: null,
      hasMadeChanges: false,
    };
    this.state.errors = validateNestedForm(initialTrackingPageData, CreateTrackingPage.validationRules);
  }

  componentDidMount() {
    const { state: { sourceTrackingPageName } = {} } = this.props.location;
    if (sourceTrackingPageName) {
      this.props.getTrackingPage(sourceTrackingPageName, NonPublished).then(() => this.setState({
        trackingPageData: this.props.trackingPageData,
        invalidTrackingRefPageOverride: this.props.invalidTrackingPageOverrideName,
        errors: validateNestedForm(this.props.trackingPageData, CreateTrackingPage.validationRules),
      }));
    }
    this.props.getTrackingPages();
  }

  componentWillUnmount() {
    this.props.resetTrackingPage();
  }

  get desktopLayoutIndex() {
    return this.state.trackingPageData.layouts.findIndex(x => x.layoutType === StandardLayoutType);
  }

  get mobileLayoutIndex() {
    return this.state.trackingPageData.layouts.findIndex(x => x.layoutType === MobileLayoutType);
  }

  getMergedErrors = () => {
    const serverValidationErrors = getServerValidationErrors(this.props.errorResponse);
    return { ...this.state.errors, ...serverValidationErrors };
  };

  handleCreateTrackingPage = (trackingPageName, version, publishedOn, trackingPageData, invalidTrackingRefPageOverride) => {
    this.props.createNewTrackingPage(trackingPageName, version, publishedOn, trackingPageData, invalidTrackingRefPageOverride)
      .then(() => {
        if (version === Preview) {
          window.open(getTrackingPagePath(trackingPageName, version, true));
        }
        if (version === NonPublished) {
          this.props.history.push(`/settings/tracking-pages/edit/${trackingPageName}`);
        }
      })
      .catch((response) => {
        if (response.status === 413) {
          this.props.showPopup({
            title: 'Error',
            description: errorConstants.trackingPage.imagesSizeLimitExceeded,
          });
        } else if (response.status !== 400) {
          // Show an error popup. 400s are validation errors and they are handled in getServerErrors() method
          this.props.showPopup({
            title: 'Error',
            description: errorConstants.unknownErrorOccurred,
          });
        }
      });
  };

  handleChange = (field, value) => {
    this.setState((state) => {
      const trackingPageData = {
        ...state.trackingPageData,
        [field]: value,
      };
      const errors = validateNestedForm(trackingPageData, CreateTrackingPage.validationRules);
      return {
        trackingPageData,
        errors,
      };
    });
  };

  handleTouch = (field, value) => {
    this.setState(state => ({
      touched: {
        ...state.touched,
        [field]: value,
      },
      hasMadeChanges: true,
    }));
  };

  handleSubmit = (version) => {
    const {
      trackingPageData,
      errors,
      invalidTrackingRefPageOverride,
    } = this.state;
    if (!hasErrors(errors)) {
      this.handleCreateTrackingPage(trackingPageData.name, version, null, trackingPageData, invalidTrackingRefPageOverride);
    } else {
      const ref = getRefToErrorElement(errors, this.innerRefs);
      ref.current.focus();

      const touched = getAllTouched(trackingPageData, CreateTrackingPage.validationRules);
      this.setState({ touched });
    }
  };

  handleInvalidTrackingRefPageOverrideChange = (name) => {
    this.setState({
      invalidTrackingRefPageOverride: name !== 'None' ? name : '',
    });
  }

  handleBack = () => {
    const {
      history,
      showPopup,
      closePopup,
    } = this.props;

    if (this.state.hasMadeChanges) {
      showPopup({
        title: 'Are you sure?',
        description: 'Any unsaved changes will be lost.',
        onAccept: () => {
          history.push('/tracking-pages');
          closePopup();
        },
      });
    } else {
      history.push('/tracking-pages');
    }
  }

  render() {
    const { isLoading, trackingPagesList } = this.props;
    const { trackingPageData, touched } = this.state;
    const errors = this.getMergedErrors();

    return (
      <div>
        {isLoading ? <Spinner /> : null}
        <div className={styles.pageButtonsCreate}>
          <div>
            <BackButton onBack={() => this.handleBack()} />
            <h1 className="h2" style={{ margin: 0 }}>{typography.createTrackingPage.title}</h1>
          </div>
        </div>
        <div className={styles.pageButtonsCreate}>
          <div />
          <div>
            <ReactButton
              className={styles.button}
              onClick={() => this.handleSubmit(Preview)}
              theme="grey"
            >
              Preview
            </ReactButton>
          </div>
          <div>
            <ReactButton
              ref={this.publishButton}
              className={styles.publishBtn}
              theme="grey"
              onClick={() => this.handleSubmit(NonPublished)}
            >
              Save
            </ReactButton>
          </div>
        </div>
        <div className="container__items-large mt-5">
          <div className="container__header-large">
            <TrackingPageLink pageName={trackingPageData.name} />
          </div>
          <div>
            <General
              onChange={this.handleChange}
              onTouch={this.handleTouch}
              name={trackingPageData.name}
              title={trackingPageData.title}
              icon={trackingPageData.base64FavIcon}
              font={trackingPageData.font}
              isCarrierLinkActive={trackingPageData.isCarrierLinkActive}
              isCarrierLinkActiveToggle={() => this.handleChange('isCarrierLinkActive', !trackingPageData.isCarrierLinkActive)}
              errors={errors}
              touched={touched}
              innerRefs={this.innerRefs}
            />
          </div>
          <div>
            <NavigationBar
              onChange={e => this.handleChange('navigationBar', e)}
              onTouch={e => this.handleTouch('navigationBar', e)}
              value={trackingPageData.navigationBar}
              errors={errors.navigationBar}
              touched={touched.navigationBar}
              innerRefs={this.innerRefs.navigationBar}
            />
          </div>
          <div>
            <NavigationBarItems
              items={trackingPageData.navigationBar.navigationBarItems}
              errors={errors.navigationBar && errors.navigationBar.navigationBarItems}
              touched={touched.navigationBar && touched.navigationBar.navigationBarItems}
              onChange={e => this.handleChange('navigationBar', {
                ...trackingPageData.navigationBar,
                navigationBarItems: e,
              })}
              onTouch={e => this.handleTouch('navigationBar', { ...touched.navigationBar, navigationBarItems: e })}
              innerRefs={this.innerRefs.navigationBar.navigationBarItems}
            />
          </div>
          <div>
            <GridEdit
              name="Desktop"
              onChange={this.handleChange}
              globalFont={trackingPageData.font}
              layouts={trackingPageData.layouts}
              layoutType={StandardLayoutType}
              errors={errors.layouts && errors.layouts[this.desktopLayoutIndex]}
              innerRefs={this.innerRefs.layouts && this.innerRefs.layouts[this.desktopLayoutIndex]}
            />
          </div>
          <div>
            <GridEdit
              name="Mobile"
              onChange={this.handleChange}
              globalFont={trackingPageData.font}
              layouts={trackingPageData.layouts}
              layoutType={MobileLayoutType}
              errors={errors.layouts && errors.layouts[this.mobileLayoutIndex]}
              innerRefs={this.innerRefs.layouts && this.innerRefs.layouts[this.mobileLayoutIndex]}
            />
          </div>
          <div>
            {trackingPagesList
              && (
                <InvalidTrackingRefPageOverride
                  trackingPagesList={[...trackingPagesList]}
                  onChange={this.handleInvalidTrackingRefPageOverrideChange}
                  selectedPage={this.state.invalidTrackingRefPageOverride}
                />
              )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  editTrackingPage: {
    isLoading,
    errorResponse,
    trackingPageData,
    invalidTrackingPageOverrideName,
  },
  trackingPages: {
    trackingPagesList,
  },
}) => ({
  isLoading,
  errorResponse,
  trackingPageData,
  trackingPagesList,
  invalidTrackingPageOverrideName,
});

const mapDispatchToProps = dispatch => ({
  getTrackingPage: (trackingPageName, version) => dispatch(getTrackingPageAction(trackingPageName, version)),
  createNewTrackingPage: (trackingPageName, version, publishedOn, trackingPageData, invalidTrackingRefPageOverride) => dispatch(
    createNewTrackingPageAction(trackingPageName, version, publishedOn, trackingPageData, invalidTrackingRefPageOverride),
  ),
  resetTrackingPage: () => dispatch(resetTrackingPage()),
  showPopup: config => dispatch(showPopupAction(config)),
  closePopup: () => dispatch(closePopupAction()),
  getTrackingPages: () => dispatch(getTrackingPagesAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateTrackingPage));
