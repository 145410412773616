import React from 'react';
import SelectWithValidation from '../../../../../components/SelectWithValidation';
import styles from './DatesDropdown.scss';

export default function DatesDropdown({ setSelectedPeriod, selectedPeriod, customPeriods = null }) {
  let periods;
  const defaultPeriods = [
    { key: '1', value: 'Last 12 months' },
    { key: '2', value: 'Last 6 months' },
    { key: '3', value: 'Last 30 days' },
    { key: '4', value: 'Last 7 days' },
    { key: '5', value: 'Today' },
  ];

  if (Array.isArray(customPeriods) && customPeriods.length > 1) {
    periods = customPeriods;
  } else {
    periods = defaultPeriods;
  }

  const handlePeriodSelection = (e) => {
    setSelectedPeriod(e.value);
  };

  return (
    <div className={styles.periodContainer}>
      <label className={styles.periodsLabel}>Date range:</label>
      <div className={styles.periods}>
        <SelectWithValidation
          value={selectedPeriod}
          id="periods"
          name="periods"
          onChange={(e) => handlePeriodSelection(e)}
          options={periods.map((el) => ({
            value: el.key,
            label: el.value,
          }))}
        />
      </div>
    </div>
  );
}
