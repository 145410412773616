import React from 'react';
import InputWithValidation from 'components/InputWithValidation';
import styles from '../styles.scss';

const BasicAuthentication = (props) => {
  const {
    authUser,
    authPass,
    onAuthUsernameChange,
  } = props;

  return (
    <div className={styles.formSection}>
      <div className={styles.methodHeader}>
        <p>Enter username and password for the API you want webhooks to be sent to.</p>
      </div>
      <div className={styles.methodsForm}>
        <div className={styles.formRow}>
          <InputWithValidation
            label="Username"
            type="text"
            name="User"
            onChange={onAuthUsernameChange}
            value={authUser}
            placeholder="Username"
          />
        </div>
        <div className={styles.formRow}>
          <InputWithValidation
            label="Password"
            onChange={onAuthUsernameChange}
            type="password"
            name="Pass"
            value={authPass}
            placeholder="Password"
          />
        </div>
      </div>
    </div>
  );
};

export default BasicAuthentication;
