import React, { Component } from 'react';
import cx from 'classnames';
import styles from './styles.scss';
import ApiKey from './AuthenticationMethods/ApiKey';
import BasicAuthentication from './AuthenticationMethods/BasicAuthentication';
import Jwt from './AuthenticationMethods/Jwt';

class AuthentMethod extends Component {
  constructor(props) {
    super(props);

    this.state = {
      methods: [
        {
          id: 1,
          label: 'API key',
          value: 'API key',
          component: properties => <ApiKey {...properties} />,
        },
        {
          id: 2,
          label: 'Basic Authentication',
          value: 'Basic authentication',
          component: properties => <BasicAuthentication {...properties} />,
        },
        {
          id: 3,
          label: 'JWT',
          value: 'JWT',
          component: properties => <Jwt {...properties} />,
        },
      ],
    };
  }

    getCurrentMethod = () => {
      let methodProps;

      const {
        methods,
      } = this.state;

      const {
        currentMethodIndex,
        jsonData,
        jsonKey,
        jsonValue,
        jsonKeyValid,
        jsonValueValid,
        jsonUrl,
        jsonUrlValid,
        jsonHeader,
        jsonMask,
        jsonHeaderValid,
        jsonMaskValid,
        onJsonInputChange,
        onJsonFormSubmit,
        onJsonRequestDelete,
        HttpMethodOptions,
        onRadioMethodChoose,
        switchActiveClass,
        onRadioCreateWebhookChange,
        defaultMethod,
        apiHeaderKey,
        apiKeyValue,
        apiHeaderKeyValid,
        apiKeyValueValid,
        onApiHeaderInputChange,
        onApiValueInputChange,
        authUser,
        authPass,
        authUserValid,
        authPassValid,
        onAuthUsernameChange,
        jsonEditorValue,
        jsonEditorValid,
        onJsonEditorChange,
        onJsonEditorValidate,
        selectedJwtHttpMethod,
        onJwtHttpMethodChange,
      } = this.props;

      switch (currentMethodIndex) {
        case 0:
          methodProps = {
            apiHeaderKey,
            apiHeaderKeyValid,
            onApiHeaderInputChange,
            onApiValueInputChange,
            apiKeyValue,
            apiKeyValueValid,
          };
          break;
        case 1:
          methodProps = {
            authUser,
            authPass,
            authUserValid,
            authPassValid,
            onAuthUsernameChange,
          };

          break;
        case 2:
          methodProps = {
            jsonKey,
            jsonValue,
            jsonData,
            jsonKeyValid,
            jsonValueValid,
            switchActiveClass,
            onJsonInputChange,
            onJsonFormSubmit,
            onJsonRequestDelete,
            HttpMethodOptions,
            defaultMethod,
            onRadioCreateWebhookChange,
            onRadioMethodChoose,
            jsonUrl,
            jsonUrlValid,
            jsonHeader,
            jsonMask,
            jsonHeaderValid,
            jsonMaskValid,
            jsonEditorValue,
            jsonEditorValid,
            onJsonEditorChange,
            onJsonEditorValidate,
            selectedJwtHttpMethod,
            onJwtHttpMethodChange,
          };
          break;
        default:

          break;
      }
      if (currentMethodIndex !== -1) {
        return methods[currentMethodIndex].component(methodProps);
      }
      return null;
    }

    renderMethodsPagination = (item, index) => {
      const radioId = item.label.replace(/\s/g, '').toLowerCase();
      return (
        <div
          key={item.id}
          className={cx(styles.methodRadioWrapper, `${this.props.currentMethodIndex === index ? styles.active : ''}`)}
        >
          <label htmlFor={radioId} className={styles.labelBtn}>
            <input
              onClick={() => this.props.radioClicked(index)}
              id={radioId}
              name="radioMethod"
              className={styles.formCheckInput}
              type="radio"
              value={item.value}
              onChange={event => this.props.onRadioMethodChoose(event)}
            />
            <span className={styles.radioBtnText}>{item.label}</span>
          </label>
        </div>
      );
    }

    render() {
      return (
        <div className={styles.innerCardBody}>
          <div className={styles.descriptionArea}>
            <p>
              Select the authentication method for the service you want the webhook to talk to
              (REACT currently supports Basic Auth, API Keys, and JWT).
            </p>
          </div>
          <div className={styles.inputArea}>
            <span className={styles.asterisk}>* </span>Choose your authentication method
            <div className={styles.methodOptionsWrapper}>
              <div className={styles.radioPaginationWrapper}>
                {this.state.methods.map(this.renderMethodsPagination)}
              </div>
              {this.getCurrentMethod()}
            </div>
          </div>
        </div>
      );
    }
}

export default AuthentMethod;
