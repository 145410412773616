import React, { useState } from 'react';
import {
  getUsername,
  getCustomerId,
  getUserId,
} from 'helpers/auth';
import { format } from 'date-fns';
import DualButtons from 'components/DualButtons';
import InputWithValidation from 'components/InputWithValidation';
import validateShipmentNote from './validateShipmentNote';
import styles from './style.scss';

export default function AddShipmentNote(props) {
  const [content, setContent] = useState('');
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const username = getUsername();

  const handleContentChange = ({ target: { value } }) => {
    setContent(value);
  };

  const saveNote = async () => {
    setSaving(true);

    const shipmentNote = {
      shipmentId: props.shipmentId,
      customerId: getCustomerId,
      userId: getUserId,
      username,
      content: content.trim(),
    };

    const shipmentNoteErrors = validateShipmentNote(shipmentNote);
    if (shipmentNoteErrors.content) {
      setErrors(shipmentNoteErrors);
      setSaving(false);
    } else {
      setErrors({});
      try {
        await props.saveNote(shipmentNote);
      } finally {
        setSaving(false);
      }
    }
  };

  return (
    <div className={styles.noteDay}>
      <div
        className={styles.noteDayHeader}
      >
        <b>{format(new Date(), 'D/M/YYYY dddd')} - New Note</b>
      </div>
      <div className={styles.note}>
        <div className={styles.noteLine}>
          <InputWithValidation
            name="content"
            value={content}
            inputTag="textarea"
            onChange={handleContentChange}
            placeholder="Add your message"
            errors={errors}
            ariaLabel="Add your message"
          />
        </div>
        <div className={styles.noteLine}>
          <span>User:</span>
          <span>{username}</span>
        </div>
      </div>
      <DualButtons
        leftFunction={() => props.cancelAddNote()}
        leftText="Cancel"
        rightFunction={() => saveNote()}
        rightFunctionDisabled={saving || content.length === 0}
        rightText="Save"
        theme="rightPink"
      />
    </div>
  );
}
