import React, { Component } from 'react';
import {
  string,
} from 'prop-types';
import cx from 'classnames';
import { getTrackingPagePath } from 'helpers/API';
import { Published } from 'constants/trackingPage/trackingPageVersions';
import CardShell from 'components/CardShell';
import Hr from 'components/CardShell/Hr';
import ClipboardCopy from 'components/ClipboardCopy';

import styles from '../style.scss';

const headerText = 'Your sharable tracking link you can use in emails, SMS, social etc';
const showMoreLabel = 'Show More';
const showLessLabel = 'Show Less';

const getPrimaryLinkTemplate = name => ({
  label: 'Shipment ID only',
  linkTemplate: `${getTrackingPagePath(name, Published, false)}&shipment_id=[shipment_id_here]`,
});

const getAdditionalLinkTemplates = (name) => {
  const path = getTrackingPagePath(name, Published, false);
  return [
    {
      label: 'Custom Reference only',
      linkTemplate: `${path}&custom_reference=[custom_reference_here]`,
    },
    {
      label: 'Tracking Reference only',
      linkTemplate: `${path}&tracking_reference=[tracking_reference_here]`,
    },
    {
      label: 'Tracking Reference + Custom Reference',
      linkTemplate: `${path}&tracking_reference=[tracking_reference_here]&custom_reference=[custom_reference_here]`,
    },
    {
      label: 'Tracking Reference + Custom Reference + Shipment ID',
      linkTemplate:
        `${path}&tracking_reference=[tracking_reference_here]`
        + '&custom_reference=[custom_reference_here]&shipment_id=[shipment_id_here]',
    },
    {
      label: 'Shipment Metadata',
      linkTemplate: `${path}&metadata_key=[metadata_key_here]&metadata_value=[metadata_value_here]`,
    },
  ];
};

class Link extends Component {
  static propTypes ={
    pageName: string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showMoreToggled: false,
    };
  }

  handleShowMoreClick = () => {
    this.setState(prevState => ({
      showMoreToggled: !prevState.showMoreToggled,
    }));
  }

  render() {
    const primaryLinkTemplate = getPrimaryLinkTemplate(this.props.pageName);
    const { showMoreToggled } = this.state;

    return (
      <CardShell>
        <div>
          <div className={styles.cardContent}>
            <div className={styles.headerContent}>
              <h3 className={styles.primaryHeaderTitle}>Link</h3>
              <p className={styles.secondaryHeaderTitle}>{headerText}</p>
            </div>
            <Hr />
            <div className={styles.links}>
              <div className={styles.linkItem}>
                <div className={styles.linkLabel}>
                  <span className={styles.linkId}>1</span>
                  <span>{primaryLinkTemplate.label}</span>
                </div>
                <ClipboardCopy value={primaryLinkTemplate.linkTemplate} />
              </div>
              {showMoreToggled && getAdditionalLinkTemplates(this.props.pageName).map((item, index) => (
                <div key={item.label} className={styles.linkItem}>
                  <div className={styles.linkLabel}>
                    <span className={styles.linkId}>{`${index + 2}`}</span>
                    <span>{item.label}</span>
                  </div>
                  <ClipboardCopy value={item.linkTemplate} />
                </div>
              ))}
            </div>
            <Hr />
          </div>
          <div className={styles.linkActions}>
            <button
              className={styles.showMoreButton}
              type="button"
              onClick={this.handleShowMoreClick}
            >
              {showMoreToggled ? showLessLabel : showMoreLabel}
              <span className={cx(styles.stateLabel, { [styles.stateLabelActive]: showMoreToggled })} />
            </button>
          </div>
        </div>
      </CardShell>
    );
  }
}

export default Link;
