export default [
  { value: 'action_required', label: 'Action Required' },
  { value: 'arrived_at_destination_country', label: 'Arrived At Destination Country' },
  { value: 'at_customs', label: 'At Customs' },
  { value: 'awaiting_drop_off', label: 'Awaiting Drop Off' },
  { value: 'carrier_changed', label: 'Carrier Changed' },
  { value: 'carrier_refused_to_collect', label: 'Carrier Refused To Collect' },
  { value: 'carrier_unable_to_collect', label: 'Carrier Unable To Collect' },
  { value: 'shipment_issue', label: 'Shipment Issue' },
  { value: 'cleared_through_customs', label: 'Cleared Through Customs' },
  { value: 'collected_by_carrier', label: 'Collected By Carrier' },
  { value: 'collection_rescheduled', label: 'Collection Rescheduled' },
  { value: 'collection_scheduled', label: 'Collection Scheduled' },
  { value: 'customs_charges_due', label: 'Customs Charges Due' },
  { value: 'damaged', label: 'Damaged' },
  { value: 'delayed', label: 'Delayed' },
  { value: 'delivered', label: 'Delivered' },
  { value: 'delivered_damaged', label: 'Delivered Damaged' },
  { value: 'delivered_to_neighbour', label: 'Delivered To Neighbour' },
  { value: 'delivered_to_reception', label: 'Delivered To Reception' },
  { value: 'delivered_to_safe_location', label: 'Delivered To Safe Location' },
  { value: 'delivery_address_changed', label: 'Delivery Address Changed' },
  { value: 'delivery_attempted', label: 'Delivery Attempted' },
  { value: 'delivery_failed', label: 'Delivery Failed' },
  { value: 'delivery_failed_card_left', label: 'Delivery Failed Card Left' },
  { value: 'delivery_refused', label: 'Delivery Refused' },
  { value: 'delivery_rescheduled', label: 'Delivery Rescheduled' },
  { value: 'delivery_scheduled', label: 'Delivery Scheduled' },
  { value: 'destroyed', label: 'Destroyed' },
  { value: 'dispatched', label: 'Dispatched' },
  { value: 'dropped_off', label: 'Dropped Off' },
  { value: 'exchange_failed', label: 'Exchange Failed' },
  { value: 'exchange_successful', label: 'Exchange Successful' },
  { value: 'failed_to_collect', label: 'Failed To Collect' },
  { value: 'final_delivery_attempt', label: 'Final Delivery Attempt' },
  { value: 'held_by_carrier', label: 'Held By Carrier' },
  { value: 'in_transit', label: 'In Transit' },
  { value: 'in_transit_waiting', label: 'In Transit Waiting' },
  { value: 'lost', label: 'Lost' },
  { value: 'missing', label: 'Missing' },
  { value: 'missing_manifest', label: 'Missing Manifest' },
  { value: 'misrouted', label: 'Misrouted' },
  { value: 'incorrect_label', label: 'Incorrect Label' },
  { value: 'out_for_delivery', label: 'Out For Delivery' },
  { value: 'partially_delivered', label: 'Partially Delivered' },
  { value: 'proof_of_delivery_available', label: 'Proof Of Delivery Available' },
  { value: 'ready_for_collection', label: 'Ready For Collection' },
  { value: 'refunded', label: 'Refunded' },
  { value: 'will_be_returned_to_sender', label: 'Will Be Returned To Sender' },
  { value: 'returned_to_sender', label: 'Returned To Sender' },
  { value: 'collection_reminder', label: 'Collection reminder' },
  { value: 'final_collection_reminder', label: 'Final collection reminder' },
  { value: 'unable_to_track', label: 'Unable To Track' },
];
