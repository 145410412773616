import React from 'react';
import {
  string, func, bool,
} from 'prop-types';
import cx from 'classnames';
import styles from './styles.scss';

const InputButtonGroup = ({
  placeholder,
  value,
  buttonTitle,
  isValid,
  onChange,
  onClick,
}) => (
  <div className={cx(styles.container, { [styles.invalid]: !isValid })}>
    <input
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={styles.inputControl}
    />
    <button
      type="button"
      onClick={onClick}
      className={styles.buttonControl}
    >
      {buttonTitle}
    </button>
  </div>
);

InputButtonGroup.propTypes = {
  placeholder: string.isRequired,
  value: string.isRequired,
  buttonTitle: string.isRequired,
  isValid: bool.isRequired,
  onChange: func.isRequired,
  onClick: func.isRequired,
};

export default InputButtonGroup;
