import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import cx from 'classnames';
import styles from './styles.scss';

const Spinner = ({ menuExpanded }) => {
  let menuExpandedStyle = menuExpanded
    ? styles.menuExpanded : styles.menuNotExpanded;
  menuExpandedStyle = menuExpanded === null ? '' : menuExpandedStyle;

  return (
    <div
      id="spinner"
      className={cx(styles.spinner, menuExpandedStyle)}
    >
      <CircularProgress thickness={8} color="inherit" size={80} variant="indeterminate" />
    </div>
  );
};

export default withStyles(styles)(Spinner);
