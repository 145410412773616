import { SET_SIDEBAR_MENU_VALUE } from './types';

function setSidebarMenuValue(isExpanded) {
  return (dispatch) => {
    dispatch({
      type: SET_SIDEBAR_MENU_VALUE,
      payload: isExpanded,
    });
  };
}

export default setSidebarMenuValue;
