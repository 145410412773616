import React, { useState } from 'react';
import { format } from 'date-fns';
import styles from './style.scss';

export default function ShipmentNoteDay({ date }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.noteDay}>
      <div
        role="button"
        className={styles.noteDayHeader}
        onClick={() => setIsOpen(!isOpen)}
      ><b>{date.day}</b>
      </div>
      {isOpen ? (
        <>
          {date.notes.map(note => (
            <div key={note.createdOn} className={styles.note}>
              <div className={styles.noteLine}>
                <span>{format(new Date(note.createdOn), 'H:mm a')}</span>
                <span>{note.content}</span>
              </div>
              <div className={styles.noteLine}>
                <span>User:</span>
                <span>{note.username}</span>
              </div>
            </div>
          ))}
          <div
            role="button"
            className={styles.closeNoteDay}
            onClick={() => setIsOpen(false)}
          >Close
          </div>
        </>
      ) : (
        <div
          role="button"
          className={styles.openNoteDay}
          onClick={() => setIsOpen(true)}
        >+
        </div>
      )}
    </div>
  );
}
