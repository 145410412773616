const initialState = {
  isOpen: false,
  isOpenTerms: false,
};

export default function copyrightPopUpReducer(state = initialState, action) {
  switch (action.type) {
    case 'CLOSE_POPUP':
      return {
        ...state,
        isOpen: false,
        isOpenTerms: false,
      };
    case 'OPEN_COPYRIGHT_POPUP':
      return {
        ...state,
        isOpen: true,
      };
    case 'OPEN_TERMS_POPUP':
      return {
        ...state,
        isOpenTerms: true,
      };
    default:
      return {
        ...state,
      };
  }
}
