import React from 'react';
import classnames from 'classnames';
import LoginFormButton from 'components/RegistrationButton/LoginFormButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './styles.scss';
import withResendEmailPopup from './WithResendEmailPopup';

const PrimaryUserTokenExpired = (props) => {
  const { onClick, isLoading } = props;
  return (
    <div className={styles.PrimaryUserTokenExpiredContainer}>
      <div className={styles.registrationPageInner}>
        <div className={styles.welcome}>
          <h1 className={`${styles.welcomeHeader}`}>
            Account activation expired
          </h1>
        </div>
        <div className={styles.confirmationWelcomeWrapper}>
          <p className="subtitle1">
            We have noticed that you have not activated your account in the 24 hour period given. We set a 24 hour activation period for security reasons.
            Please select the button below to resend your activation email.
          </p>
        </div>

        <LoginFormButton
          type="submit"
          onClick={onClick}
          className={classnames(styles.button, { [styles.buttonDisabled]: isLoading })}
          disabled={isLoading}
        >
          {isLoading && <CircularProgress thickness={8} color="inherit" size={40} variant="indeterminate" className={styles.loadingSpinner} />}
          {!isLoading && 'Resend email'}
        </LoginFormButton>
      </div>
    </div>
  );
};

export default withResendEmailPopup(PrimaryUserTokenExpired);
