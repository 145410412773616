import React from 'react';
import CardShell from 'components/CardShell';
import Hr from 'components/CardShell/Hr';
import SelectWithValidation from 'components/SelectWithValidation';

import styles from '../../style.scss';

const InvalidTrackingRefPageOverride = ({ trackingPagesList, onChange, selectedPage }) => (
  <CardShell noOverflow>
    <div className={styles.cardInner}>
      <div className={styles.cardInnerFix}>
        <h3 className={styles.title}>Page Overrides</h3>
        <Hr />
        <div style={styles.formItem}>
          <SelectWithValidation
            label="Invalid Tracking Ref page override"
            name="trackingPageName"
            value={selectedPage}
            options={[{
              value: null,
              label: 'None',
            }, ...trackingPagesList.map(x => ({
              value: x.trackingPageName,
              label: x.trackingPageName,
            }))]}
            onChange={e => onChange(e.value)}
          />
        </div>
      </div>
    </div>
  </CardShell>
);

export default InvalidTrackingRefPageOverride;
