/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { Component } from 'react';
import vocabulary from 'constants/vocabulary';
import Svg from 'components/Svg';
import cx from 'classnames';
import DateTime from './DateTime';
import styles from './styles.scss';

export default class DateFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggled: false,
    };
  }

  handleChange = (e) => {
    this.props.handleChange(this.props.name, { from: e.target.value });
  };

  handleDateRangePick = (e) => {
    const { name, value } = e.target;
    return value
      ? this.props.handleDateRangePick(this.props.name, { [name]: new Date(value).toISOString() })
      : this.props.handleChange(this.props.name, { from: this.props.options[0] });
  };

  render() {
    const from = this.props.values ? this.props.values.from : '';
    const to = this.props.values ? this.props.values.to : '';
    let selectedOption = from;
    let dateTimeValue = '';
    if (from && !vocabulary[from]) {
      dateTimeValue = from;
      selectedOption = 'custom';
    }
    return (
      <form className={styles.dateFilterColumn}>
        <div
          role="button"
          onClick={() => this.setState(prevState => ({ isToggled: !prevState.isToggled }))}
          className={styles.dateAreaToggleButton}
        >
          <h4 className="mb-2">{vocabulary[this.props.name]}</h4>
          <Svg
            name="angleDown"
            className={cx(styles.downChevron, this.state.isToggled ? styles.downChevronActive : '')}
          />
        </div>
        <div className={cx(styles.dateAreaToggle, this.state.isToggled ? styles.dateAreaToggleActive : '')}>
          <div className="mb-3">
            {this.props.options.map((option, i) => (
              <div key={option} className={styles.controlGroup}>
                <input
                  type="radio"
                  className={styles.radio}
                  id={`${this.props.name}${option}`}
                  value={option}
                  checked={selectedOption ? selectedOption === option : i === 0}
                  onChange={this.handleChange}
                />
                <label htmlFor={`${this.props.name}${option}`}>{vocabulary[option]}</label>
              </div>
            ))}
          </div>
          {selectedOption === 'custom' && (
            <div>
              <div className="mb-2">
                <DateTime
                  pickerName={this.props.name}
                  label="From: "
                  name="from"
                  value={dateTimeValue}
                  onChange={this.handleDateRangePick}
                />
              </div>
              <DateTime
                pickerName={this.props.name}
                label="To: "
                name="to"
                value={to || ''}
                onChange={this.handleDateRangePick}
              />
            </div>
          )}
        </div>
      </form>
    );
  }
}
