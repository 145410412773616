import React, { useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import StateCategoryRedSvg from 'images/stateCategoryRed.svg';
import ReactTooltip from 'react-tooltip';
import styles from './styles.scss';

function ExportSummary({ showProgress, showError, title }) {
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <div className={styles.container}>
      {showError
        && (
          <>
            <div className={styles.error} data-tip="There was a problem exporting your shipments." data-for={`summary${title}`}>
              <StateCategoryRedSvg />
              <ReactTooltip
                type="dark"
                id={`summary${title}`}
                className={styles.toolTip}
                effect="solid"
              />
            </div>
          </>
        )}
      {showProgress
        ? (
          <div className={styles.export}>
            <CircularProgress thickness={8} color="inherit" size={40} variant="indeterminate" />
          </div>
        )
        : 'Export'}
    </div>
  );
}

export default ExportSummary;
