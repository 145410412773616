import {
  customValidator, matchRegex, maxLength, required, validateEach,
} from 'helpers/validate';
import { validateNestedForm } from 'helpers/validateForm';

const readMode = 'readMode';
const editMode = 'editMode';
const maxGroupings = 3;

const validationRules = {
  groupings: [
    customValidator(
      (
        groupings => new Set(groupings.map(x => x.metadataKey)
          .filter(x => x)).size === groupings.filter(x => x.metadataKey).length),
      () => 'You must provide a unique metakey',
    ),
    customValidator(
      (
        groupings => new Set(groupings.map(x => x.displayName)
          .filter(x => x)).size === groupings.filter(x => x.displayName).length),
      () => 'You must provide a unique name',
    ),
    validateEach({
      metadataKey: [
        required(() => 'You must provide a unique metakey'),
        maxLength(30, () => 'A metakey must be a maximum of 30 characters'),
        matchRegex(
          /^[a-z][a-z_0-9.]*$/i,
          () => 'A metakey must start with a letter and only contain letters, numbers, underscores or dots',
        )],
      displayName: [
        required(() => 'You must provide a unique name'),
        maxLength(50, () => 'Name must be a maximum of 50 characters')],
    }),
  ],
};

/**
 * Remove empty groupings from the end of the array.
 */
function trimGroupings(groupings) {
  let count = 0;
  for (let i = groupings.length - 1; i >= 0; i -= 1) {
    if (groupings[i].metadataKey || groupings[i].displayName) {
      count = i + 1;
      break;
    }
  }
  return groupings.slice(0, count);
}

/**
 * Add empty groupings to the end of array if necessary to match with what we will render.
 */
function fillGroupings(groupings) {
  if (groupings) {
    const missingCount = Math.max(0, maxGroupings - groupings.length);
    return groupings.concat(Array(missingCount)
      .fill({}));
  }
  return Array(maxGroupings)
    .fill({});
}

function validate(groupings) {
  const errors = validateNestedForm({ groupings }, validationRules);
  return {
    rootErrors: errors.groupings && errors.groupings.every(x => typeof x === 'string') ? errors : {},
    fieldErrors: errors.groupings && errors.groupings.every(x => typeof x === 'string') ? {} : errors,
  };
}

export {
  readMode,
  editMode,
  trimGroupings,
  fillGroupings,
  validate,
};
