import React from 'react';
/* eslint-disable */

export default props => (
  <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'
    viewBox='0 0 68.2 68.1' style={{ borderRadius: '50%' }}>
    <style type="text/css">
      {`.st0{clip - path: url(#XMLID_2_);}
      .st1{clip - path: url(#XMLID_3_);fill:#FFFFFF;stroke:#2A3180;stroke-width:0.3;stroke-miterlimit:10;}
      .st2{clip - path: url(#XMLID_3_);fill:#2A3180;stroke:#2A3180;stroke-miterlimit:10;}
      .st3{clip - path: url(#XMLID_3_);fill:#2A3180;}
      .st4{clip - path: url(#XMLID_3_);fill:#DE0035;stroke:#DE0035;stroke-width:0.3;stroke-miterlimit:10;}
      .st5{clip - path: url(#XMLID_3_);fill:#DE0035;stroke:#DE0035;stroke-miterlimit:10;}
      .st6{clip - path: url(#XMLID_3_);fill:none;}`}
    </style>
    <g id='XMLID_94_'>
      <defs>
        <ellipse id='XMLID_95_' transform='rotate(-23.307 34.064 34.07)' cx='34.1'
          cy='34.1' rx='34.1' ry='34.1' />
      </defs>
      <clipPath id='XMLID_2_'>
        <use xlinkHref='#XMLID_95_' overflow='visible' />
      </clipPath>
      <g id='XMLID_510_' className='st0'>
        <defs>
          <rect id='XMLID_96_' x='-34.3' y='-0.7' width='136.8' height='69.5' />
        </defs>
        <clipPath id='XMLID_3_'>
          <use xlinkHref='#XMLID_96_' overflow='visible' />
        </clipPath>
        <rect id='XMLID_112_' x='-34.4' y='-0.7' className='st1' width='136.9'
          height='69.5' />
        <polygon id='XMLID_111_' className='st2' points='-19.4,-0.7 22,20.3 22,-0.7'
        />
        <polygon id='XMLID_110_' className='st3' points='-34.4,6.9 -4.5,22.2 -34.4,22.2'
        />
        <polygon id='XMLID_109_' className='st4' points='-34.4,-0.7 10.9,22.2 0.7,22.2 -34.4,4.6'
        />
        <polygon id='XMLID_108_' className='st2' points='86.8,68.8 45.4,47.8 45.4,68.8'
        />
        <polygon id='XMLID_107_' className='st2' points='102.2,61.2 72.3,45.9 102.2,45.9'
        />
        <polygon id='XMLID_106_' className='st2' points='86.8,-0.7 45.4,20.3 45.4,-0.7'
        />
        <polygon id='XMLID_105_' className='st3' points='102.5,6.9 72.5,22.2 102.5,22.2'
        />
        <polygon id='XMLID_104_' className='st4' points='102.5,68.8 57.1,45.9 67.3,45.9 102.5,63.6'
        />
        <polygon id='XMLID_103_' className='st4' points='102.5,-0.7 57.8,22.2 47.3,22.2 92.5,-0.7'
        />
        <polygon id='XMLID_102_' className='st2' points='-19.4,68.8 22,47.8 22,68.8'
        />
        <polygon id='XMLID_101_' className='st2' points='-34.4,61.2 -4.5,45.9 -34.4,45.9'
        />
        <polygon id='XMLID_100_' className='st5' points='-34.3,68.8 10.4,45.9 20.8,45.9 -24.4,68.8'
        />
        <rect id='XMLID_99_' x='-34.4' y='27.1' className='st4' width='136.9'
          height='13.6' />
        <rect id='XMLID_98_' x='27' y='-0.7' className='st4' width='13.5' height='69.5'
        />
        <rect id='XMLID_97_' x='-34.3' y='-0.7' className='st6' width='136.8'
          height='69.5' />
      </g>
    </g>
  </svg>
)

/* eslint-enable */
