import React, { PureComponent } from 'react';

import cx from 'classnames';
import { generateRandomNumber } from 'helpers/guid';
import styles from '../style.scss';

class GoogleFont extends PureComponent {
  componentClass = `class${Math.floor(generateRandomNumber() * Number.MAX_SAFE_INTEGER)}`;

  constructor(props) {
    super(props);
    this.state = {
      googleFonts: {
        error: false,
        loading: true,
        fonts: null,
      },
    };
  }

  componentDidMount() {
    const { selectedFontName } = this.props;
    fetch('https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyCK50KVwJs5PTMdSGd7Dl2daZRA2nPD9_4')
      .then(resp => resp.json())
      .then(data => this.setState({
        googleFonts: {
          error: false,
          loading: false,
          fonts: data.items,
        },
      }));
    this.applyFont(selectedFontName);
  }

  componentWillUnmount() {
    this.removeAppliedFonts();
  }

  handleFontChange = (e) => {
    const fontName = e.target.value;
    this.applyFont(fontName);
  };

  removeAppliedFonts = () => {
    const head = document.getElementsByTagName('head')[0];
    const appliedFonts = head.querySelectorAll(`.${this.componentClass}`);
    appliedFonts.forEach(e => e.remove());
  };

  applyFont = (fontName) => {
    const urlFontName = fontName.replace(/ /g, '+');
    const url = `https://fonts.googleapis.com/css?family=${urlFontName}:300,400,600,700`;
    const head = document.getElementsByTagName('head')[0];
    const appliedFonts = head.querySelectorAll(`.${this.componentClass}`);
    const newFont = document.createElement('link');
    newFont.addEventListener('load', () => {
      appliedFonts.forEach(e => e.remove());
      if (fontName !== this.props.selectedFontName) {
        this.props.onChange(fontName, url);
      }
    });
    newFont.className = this.componentClass;
    newFont.type = 'text/css';
    newFont.rel = 'stylesheet';
    newFont.title = 'customGFont';
    newFont.href = url;
    head.append(newFont);
  };

  render() {
    const { selectedFontName } = this.props;
    const { googleFonts } = this.state;

    if (googleFonts.loading) return <div>Loading...</div>;
    return (
      <div>
        <select
          id="generalFontFamily"
          className={cx(styles.formElement, styles.gFontSelect)}
          onChange={this.handleFontChange}
          value={selectedFontName}
        >
          {googleFonts.fonts.map(font => (
            <option key={font.family} value={font.family}>{font.family}</option>
          ))}
        </select>
        <div style={{ fontFamily: selectedFontName }} className={styles.fontPreview}>
          <p>The quick brown fox jumps over the lazy dog</p>
        </div>
      </div>
    );
  }
}

export default GoogleFont;
