import API from 'helpers/API';
import casing from 'casing';

import {
  CUSTOM_STATE_LABELS_FETCH,
  CUSTOM_STATE_LABELS_SUCCESS,
  CUSTOM_STATE_LABELS_ERROR,
  EDIT_CUSTOM_STATE_LABEL_CLEAR,
  EDIT_CUSTOM_STATE_LABEL_SET,
  EDIT_CUSTOM_STATE_LABEL_SUCCESS,
  CUSTOM_STATE_LABELS_FILTER_SET,
  EDIT_CUSTOM_STATE_LABEL_ERROR,
} from './types';

const getCustomStateLabelsApi = (dispatch) => {
  dispatch({ type: CUSTOM_STATE_LABELS_FETCH });

  return API.getCustomStateLabels().then((response) => {
    const { customStateLabels } = casing.camelize(response);
    dispatch({ type: CUSTOM_STATE_LABELS_SUCCESS, keys: customStateLabels });
    return Promise.resolve(customStateLabels);
  }).catch(() => {
    dispatch({ type: CUSTOM_STATE_LABELS_ERROR, error: 'Failed to load the Custom State Labels.' });
  });
};

export function clearEditCustomStateLabel() {
  return dispatch => dispatch({ type: EDIT_CUSTOM_STATE_LABEL_CLEAR });
}

export function setEditCustomStateLabel(editCustomStateLabel) {
  return dispatch => dispatch({ type: EDIT_CUSTOM_STATE_LABEL_SET, editCustomStateLabel });
}

export function getEditCustomStateLabel(customStateLabel) {
  return (dispatch) => {
    dispatch({ type: EDIT_CUSTOM_STATE_LABEL_SUCCESS, customStateLabel });
  };
}

export function setCustomStateLabelsFilter(filter) {
  return (dispatch) => {
    dispatch({ type: CUSTOM_STATE_LABELS_FILTER_SET, filter });
  };
}

export function getCustomStateLabels() {
  return dispatch => getCustomStateLabelsApi(dispatch).then(() => getCustomStateLabelsApi(dispatch));
}

export function saveEditCustomStateLabel(customStateLabel) {
  return dispatch => API.updateCustomStateLabels(casing.snakeize(customStateLabel)).then(() => {
    dispatch({ type: EDIT_CUSTOM_STATE_LABEL_CLEAR });
    return getCustomStateLabelsApi(dispatch);
  }).catch(() => {
    dispatch({ type: EDIT_CUSTOM_STATE_LABEL_ERROR, error: 'Failed to update the Custom State Label.' });
  });
}
