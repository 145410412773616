import React from 'react';
import SftpAccountForm from 'components/FormGroups/SftpAccountForm';
import { createSftpAccountTitle } from 'constants/titles';
import styles from './styles.scss';

const AddSftpAccount = () => (
  <div className={styles.contentWrapper}>
    <h1 className="h2">{createSftpAccountTitle}</h1>
    <div className="container__cards-large">
      <SftpAccountForm />
    </div>
  </div>
);

export default AddSftpAccount;
