const initialState = {
  sinkConfigurations: [],
  isLoading: false,
  requestDidTimeout: false,
};

export default function sinkConfigurationsReducer(state = initialState, action) {
  switch (action.type) {
    case 'SINK_CONFIG_FETCH':
      return {
        ...state,
        sinkConfigurations: [],
        isLoading: true,
        requestDidTimeout: false,
      };
    case 'SINK_CONFIG_SUCCESS':
      return {
        ...state,
        isLoading: false,
        requestDidTimeout: false,
        sinkConfigurations: action.configurations,
      };
    case 'SINK_CONFIG_ERROR':
      return {
        ...state,
        requestDidTimeout: true,
        isLoading: false,
      };
    default:
      return state;
  }
}
