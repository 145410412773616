const initialState = {
  configuration: null,
  errors: {},
  error: '',
};

export default function createConfigurationReducer(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_CONFIGURATION_FETCH':
    case 'CREATE_CONFIGURATION_CLEAR':
      return {
        configuration: null,
        errors: {},
        error: '',
      };
    case 'CREATE_CONFIGURATION_ERROR':
      return {
        ...state,
        error: action.error,
      };
    case 'CREATE_CONFIGURATION_SUCCESS':
      return {
        configuration: {
          ...action.configuration,
          permission: false,
          name: action.configuration.name ? action.configuration.name : '',
          integrationProperties: action.configuration.integrationProperties.map(x => ({
            ...x,
            fieldValue: x.fieldValue ? x.fieldValue : '',
          })),
        },
        errors: {},
        error: '',
      };
    case 'CREATE_CONFIGURATION_SET':
      return action.createConfiguration;
    default:
      return state;
  }
}
