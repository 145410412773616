import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import EditMember from 'routes/Users/EditMember';
import AuthRoute from 'routes/Auth/AuthRoute';
import resources from 'constants/resources';
import API from 'helpers/API';
import Spinner from 'components/Spinner';
import UsersContext from 'routes/Users/UsersContext';
import UsersList from './UsersList';
import AddMember from './AddMember';

function Users() {
  const [roles, setRoles] = useState(null);
  useEffect(() => {
    const getRoles = async () => {
      const rolesResponse = await API.getUserRoles();
      setRoles(rolesResponse.roles);
    };
    getRoles();
  }, []);
  if (!roles) {
    return <Spinner />;
  }
  return (
    <UsersContext.Provider value={{ roles }}>
      <Switch>
        <AuthRoute path="/settings/users/invite-a-user" component={AddMember} resource={resources.accountUsers.editUsers} />
        <AuthRoute path="/settings/users/:id" component={EditMember} resource={resources.accountUsers.editUsers} />
        <AuthRoute path="/settings/users" component={UsersList} resource={resources.accountUsers.readUsers} />
        {/* <Redirect exact from="/settings" to="/settings/users" /> */}
        <Route render={() => 'not found'} />
      </Switch>
    </UsersContext.Provider>
  );
}

export default Users;
