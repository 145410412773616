import React from 'react';
import { Line } from 'react-chartjs-2';
import styles from './areaChart.scss';
import colors from '../colors';

const AreaChart = ({ days }) => {
  const generateAreaChartDates = () => days?.map(elt => {
    const date = new Date(elt.date);
    return `${date.toLocaleString('en-UK', { month: 'short' })} ${date.getDate()}`;
  });

  const chartData = {
    labels: generateAreaChartDates(),
    datasets: [
      {
        label: 'Sent',
        data: days?.map(el => el.sent),
        backgroundColor: colors[1],
        borderColor: colors[1],
      },
      {
        label: 'Opened',
        data: days?.map(el => el.opened),
        backgroundColor: colors[2],
        borderColor: colors[2],
      },
      {
        label: 'Bounced',
        data: days?.map(el => el.bounced),
        backgroundColor: colors[4],
        borderColor: colors[4],
      },
    ],
  };
  return (
    <div className={styles.areaChart}>
      <h2>Number of sent, opened and bounced emails</h2>
      <Line data={chartData} />
    </div>
  );
};

export default AreaChart;
