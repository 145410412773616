export default {
  any: 'Any',
  today: 'Today',
  yesterday: 'Yesterday',
  tomorrow: 'Tomorrow',
  last3days: 'Last 3 days',
  last7days: 'Last 7 days',
  next3days: 'Next 3 days',
  next7days: 'Next 7 days',
  custom: 'Custom',
  '30minutes': '30 Minutes',
  '60minutes': '60 Minutes',
  '3hours': '3 Hours',
  registeredDate: 'Registered date',
  orderDate: 'Order date',
  shippedDate: 'Shipped date',
  promisedDate: 'Promised date',
  deliveryDate: 'Delivery date',
  estimatedDate: 'Estimated date',
  latestUpdate: 'Latest Update',
  shippingDirection: 'Shipment type',
  all: 'All',
  delivery: 'Delivery',
  return: 'Return',
  pick_up: 'Pick up',
  drop_off: 'Drop off',
  drop_off_pick_up: 'Pick up, drop off (PUDO)',
  return_drop_off: 'Return drop off',
  webhook_date: 'Date',
  webhook_status_code: 'Status',
  webhook_state_code_success: 'Success',
  webhook_state_code_failed: 'Failed',
};
