/* eslint-disable max-len */
import React, { Component } from 'react';
import { Switch } from 'react-router';
import { connect } from 'react-redux';
import Spinner from 'components/Spinner';
import API from 'helpers/API';
import {
  getShipmentFilters as getShipmentFiltersAction,
  getAvailableShipmentStates as getAvailableShipmentStatesAction,
} from 'actions/shipmentFiltersActions';
import AuthRoute from 'routes/Auth/AuthRoute';
import resources from 'constants/resources';
import ShipmentFilterContext from 'routes/Settings/ShipmentFilters/ShipmentFiltersContext';
import ShipmentFilterList from './ShipmentFilterList';
import CreateShipmentFilterPage from './CreateShipmentFilterPage';
import EditShipmentFilterPage from './EditShipmentFilterPage';

class ShipmentFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carrierIntegrations: [],
      metadataGroupings: [],
    };
  }

  componentDidMount() {
    this.props.getShipmentFilters();
    this.props.getAvailableShipmentStates();
    this.loadCarrierIntegrations();
    this.loadMetadataGroupings();
  }

  loadMetadataGroupings = async () => {
    const response = await API.getShipmentMetadataGroupings();
    this.setState({
      metadataGroupings: response.groupings,
    });
  }

  loadCarrierIntegrations = async () => {
    const carrierIntegrations = await API.getCarrierIntegrations();
    this.setState({
      carrierIntegrations,
    });
  };

  render() {
    if (this.props.groups.isLoading) {
      return <Spinner />;
    }

    return (
      <ShipmentFilterContext.Provider value={{
        carrierIntegrations: this.state.carrierIntegrations,
        metadataGroupings: this.state.metadataGroupings,
      }}
      >
        <Switch>
          <AuthRoute exact path="/settings/shipment-filters" component={ShipmentFilterList} resource={resources.shipmentFilters.read} />
          <AuthRoute exact path="/settings/shipment-filters/create" component={CreateShipmentFilterPage} resource={resources.shipmentFilters.edit} />
          <AuthRoute exact path="/settings/shipment-filters/:shipmentFilterId" component={EditShipmentFilterPage} resource={resources.shipmentFilters.edit} />
        </Switch>
      </ShipmentFilterContext.Provider>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getShipmentFilters: () => dispatch(getShipmentFiltersAction()),
  getAvailableShipmentStates: () => dispatch(getAvailableShipmentStatesAction()),
});

export default connect(state => ({
  groups: state.shipmentFilters,
}), mapDispatchToProps)(ShipmentFilters);
