import React, { useContext } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import styles from './styles.scss';
import API from '../../../../../helpers/API';
import TabErrorMessage from '../TabErrorMessage';
import Spinner from '../../../../../components/Spinner';
import EventTimeline from '../EventTimeline';
import EmailStatisticsContext from '../../EmailStatisticsContext';

const EmailActivityDetail = () => {
  const { id, emailActivityId } = useParams();
  const [isLoadingActivityDetail, setIsLoadingActivityDetail] = React.useState(true);
  const [isLoadingTemplate, setIsLoadingActivityTemplate] = React.useState(false);
  const [isActivityDetailError, setIsActivityDetailError] = React.useState(false);
  const [isTemplateError, setIsTemplateError] = React.useState(false);
  const [activityDetail, setActivityDetail] = React.useState();
  const [templateName, setTemplateName] = React.useState('');
  const [templateId, setTemplateId] = React.useState('');
  const { setPageTitle, setPageMessage, setSelectedServerId } = useContext(EmailStatisticsContext);

  setTimeout(() => {
    setSelectedServerId(id);
    setPageTitle('Activity Details');
    setPageMessage('This page contains email activity details.');
  });

  React.useEffect(() => {
    const fetchTemplate = async (templateIdParam) => {
      try {
        setIsLoadingActivityTemplate(true);
        const response = await API.getTemplate(id, templateIdParam);
        setTemplateName(response.name);
      } catch (e) {
        setIsTemplateError(true);
      }
      setIsLoadingActivityTemplate(false);
    };

    const fetchActivity = async () => {
      try {
        const response = await API.getEmailActivityDetail(id, emailActivityId);
        setActivityDetail(response);
        const templateIdMetadata = response.metadata.find(x => x.key === 'template_id');
        if (templateIdMetadata) {
          setTemplateId(templateIdMetadata.value);
          fetchTemplate(templateIdMetadata.value);
        }
      } catch (e) {
        setIsActivityDetailError(true);
      }
      setIsLoadingActivityDetail(false);
    };

    fetchActivity();
  }, [id, emailActivityId]);

  const buildEventsForTimeline = (events) => events.reverse().map(x => (
    {
      timeStamp: x.receivedAt,
      status: x.type,
      details: x.details.DeliveryMessage ?? x.details.Summary,
      destinationServer: x.details.DestinationServer,
      bounceId: x.details.BounceID ? x.details.BounceID : null,
      sinkConfigurationId: id,
    }
  ));
  return (
    <>
      {isLoadingActivityDetail && !activityDetail && <Spinner />}
      {!isLoadingActivityDetail && isActivityDetailError && <TabErrorMessage />}
      {!isLoadingTemplate && isTemplateError && <TabErrorMessage />}
      {!isLoadingActivityDetail && !isActivityDetailError && activityDetail && (
      <div className={styles.activityDetailsContainer} id="emailActivityDetail">
        <div>
          <div className={styles.labelContainer}>
            Subject:
          </div>
          <div className={styles.valueContainer}>
            {activityDetail.subject}
          </div>
        </div>
        <div>
          <div className={styles.labelContainer}>
            From:
          </div>
          <div className={styles.valueContainer}>
            {activityDetail.from}
          </div>
        </div>
        {isLoadingTemplate && !templateName && <Spinner />}
        {!isLoadingTemplate && !isTemplateError && templateName && (
        <div>
          <div className={styles.labelContainer}>
            Template:
          </div>
          <div className={styles.valueContainer}>
            {templateName} (ID:&nbsp;
            <a
              target="_blank"
              className={styles.toLink}
              href={`/settings/notification-centre/notification-connectors/${id}/templates/${templateId}/preview`}
              rel="noreferrer"
            >{templateId}
            </a>
            )
          </div>
        </div>
        )}
        {activityDetail.metadata && (
        <div>
          <div className={styles.labelContainer}>
            Metadata:
          </div>
          <div className={styles.valueContainer}>
            <div>
              {activityDetail.metadata.map(x => (
                <div key={x.value}>{x.key} {x.value}</div>
              ))}
            </div>
          </div>
        </div>
        )}
        <div>
          <div className={styles.labelContainer}>
            To:
          </div>
          <div className={styles.valueContainer}>
            <div>
              {activityDetail.to.map(x => (
                <div key={x.email}>
                  <NavLink
                    exact
                    to={`/reporting/email-statistics/${id}/activity/${x.email}/events`}
                    className={styles.toLink}
                  >
                    {x.email}
                  </NavLink>
                </div>
              ))}
            </div>
          </div>
        </div>
        <EventTimeline events={buildEventsForTimeline(activityDetail.events)} />
        <div className={styles.htmlBody}>
          {ReactHtmlParser(activityDetail.htmlBody)}
        </div>
      </div>
      )}
    </>
  );
};

export default EmailActivityDetail;
