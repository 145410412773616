import React from 'react';
import { Provider } from 'react-redux';
import { pageview } from 'react-ga';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { BrowserRouter } from 'react-router-dom';
import rootReducer from './reducers';
import './index.scss';

const history = createBrowserHistory();

// OLD CODE, Error: It looks like you are passing several store enhancers to createStore(). DevTools in browser didn't work.
// const store = createStore(
//   rootReducer,
//   // eslint-disable-next-line
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
//   compose(
//     applyMiddleware(
//       thunk,
//     ),
//   ),
// );

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(applyMiddleware(thunk));

export const store = createStore(rootReducer, enhancer);

export default ({ children }) => {
  history.listen((location) => {
    pageview(location.pathname);
  });

  return (
    <Provider store={store}>
      <BrowserRouter history={history}>
        {children}
      </BrowserRouter>
    </Provider>
  );
};
