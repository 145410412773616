import React from 'react';
import RecipientSvg from 'images/recipient.svg';
import CategoryIcon from 'components/CategoryIcon';
import cx from 'classnames';
import styles from './styles.scss';

function StateContainer(props) {
  const {
    children, recipient, isActive, className,
  } = props;
  const category = props.category ? props.category.toLowerCase() : null;

  let icon;
  if (recipient) {
    icon = (
      <RecipientSvg className={styles.categoryIcon} />
    );
  } else if (category) {
    icon = (
      <CategoryIcon
        category={category}
        isActive={isActive}
        isActiveHighlighted
        isOutlined
        className={styles.categoryIcon}
      />
    );
  } else {
    icon = <div className={cx(styles.emptyContainer)} />;
  }

  return (
    <li className={cx(styles.itemContainer, className)}>
      <div className={styles.lineContainer}>
        <div className={styles.line} />
      </div>
      {icon}
      {children}
    </li>
  );
}

export default StateContainer;
