import React, { Component } from 'react';
import { connect } from 'react-redux';
import PopupCentered from 'components/PopupCentered';
import CardShell from 'components/CardShell';
import FilePicker from 'components/FilePicker';
import SelectWithValidation from 'components/SelectWithValidation';
import DualButtons from 'components/DualButtons';
import {
  StatusTimelineComponentType,
  ImageComponentType,
  TextComponentType,
  ImageAndTextComponentType,
  EmptyComponentType,
} from 'constants/trackingPage/componentTypes';
import {
  TopLeft,
  TopMiddle,
  TopRight,
  MiddleLeft,
  Middle,
  MiddleRight,
  BottomLeft,
  BottomMiddle,
  BottomRight,
} from 'constants/trackingPage/rowTextPositions';
import displayOptions, {
  Locale,
  ShipmentState,
} from 'constants/trackingPage/displayOptions';
import cx from 'classnames';
import {
  isInteger,
  isUrl,
  maxLength,
  rangeOf,
  required,
} from 'helpers/validate';
import { getAllTouched, getRefToErrorElement, validateNestedForm } from 'helpers/validateForm';
import SupportedImageExtensions from 'constants/trackingPage/supportedImageExtensions';
import InputWithValidation, { showErrorStrategies } from 'components/InputWithValidation';
import Hr from 'components/CardShell/Hr';
import Font from '../../Font';

import styles from '../../style.scss';

const areImageControlsVisible = componentType => componentType === EmptyComponentType
  || componentType === ImageComponentType
  || componentType === ImageAndTextComponentType;
const areTextControlsVisible = componentType => componentType === EmptyComponentType
  || componentType === TextComponentType
  || componentType === ImageAndTextComponentType;
const areUrlControlsVisible = componentType => componentType !== StatusTimelineComponentType;
const areFontControlsVisible = componentType => componentType !== ImageComponentType;
const areLocaleControlsVisible = componentType => componentType === StatusTimelineComponentType;

const normalizeDataBasedOnType = (data) => {
  const normalizedData = { ...data };
  if (!areImageControlsVisible(data.componentType)) {
    normalizedData.base64Image = null;
  }
  if (!areTextControlsVisible(data.componentType)) {
    normalizedData.text = null;
  }
  if (areFontControlsVisible(data.componentType) && data.font.size === '') {
    normalizedData.font.size = null;
  }
  if (!areUrlControlsVisible(data.componentType)) {
    normalizedData.redirectionUrl = '';
  }
  if (data.componentType !== StatusTimelineComponentType) {
    normalizedData.displayOption = ShipmentState.code;
    normalizedData.locale = '';
  }

  return normalizedData;
};

class GridEditColumn extends Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    const dataState = {
      ...data,
      locale: data.locale || '',
    };
    this.state = {
      data: dataState,
      errors: validateNestedForm(dataState, this.getValidationRules(dataState)),
      touched: {},
    };
    this.innerRefs = {
      locale: React.createRef(),
      text: React.createRef(),
      redirectionUrl: React.createRef(),
      font: {
        size: React.createRef(),
      },
    };
  }

  getValidationRules = data => ({
    componentType: [required()],
    locale: data.componentType === StatusTimelineComponentType
      && data.displayOption === Locale.code
      ? [required(() => 'Please configure a locale, before selecting it')]
      : undefined,
    text: [maxLength(400, () => 'Your text should be 400 characters or less')],
    redirectionUrl: [isUrl(),
      maxLength(200, () => 'Please enter a shorter URL. URLs should be 200 characters or less')],
    font: {
      size: [rangeOf(10, 28, () => 'Please enter a font size between 10pt and 28pt'),
        isInteger()],
    },
  });

  handleChangeSelect = (target) => {
    const { name, value } = target;
    this.handleChangeByName(name, value);
  };

  handleChange = ({ target }) => {
    const { value } = target;
    const name = target.getAttribute('name');
    this.handleChangeByName(name, value);
  };

  handleChangeByName = (field, value) => {
    this.setState((state) => {
      const data = {
        ...state.data,
        [field]: value,
      };
      if (field === 'displayOption' && value === ShipmentState.code) {
        data.locale = '';
      }
      const errors = validateNestedForm(data, this.getValidationRules(data));
      return { data, errors };
    });
  };

  handleTouch = ({ target }) => {
    const name = target.getAttribute('name');
    this.handleTouchByName(name, true);
  };

  handleTouchByName= (field, value) => {
    this.setState(state => ({
      touched: {
        ...state.touched,
        [field]: value,
      },
    }));
  };

  save = () => {
    const {
      update,
      toggleEditColumn,
    } = this.props;

    update(normalizeDataBasedOnType(this.state.data));
    toggleEditColumn();
  };

  handleSubmit = () => {
    const {
      errors,
      data,
    } = this.state;

    if (Object.keys(errors).length === 0) {
      this.save();
    } else {
      const ref = getRefToErrorElement(errors, this.innerRefs);
      ref.current.focus();

      const touched = getAllTouched(data, this.getValidationRules(data));
      this.setState({ touched });
    }
  };

  render() {
    const { toggleEditColumn, locales } = this.props;
    const {
      data: {
        componentType,
        base64Image,
        text,
        rowTextPosition,
        redirectionUrl,
        font,
        displayOption,
        locale,
      },
      touched,
      errors,
    } = this.state;

    return (
      <PopupCentered>
        <CardShell>
          <div className={cx(styles.cardInner, styles.cardInnerScroll)}>
            <h3 className={styles.title}>Edit Column</h3>
            <Hr />

            <div className={styles.formGroup}>
              <label htmlFor="generalFontweight" className={styles.formLabel}>Type</label>
              <select
                value={componentType}
                id="generalFontweight"
                name="componentType"
                className={styles.formElement}
                onChange={this.handleChange}

              >
                <option value={ImageComponentType}>{ImageComponentType}</option>
                <option value={TextComponentType}>{TextComponentType}</option>
                <option value={ImageAndTextComponentType}>{ImageAndTextComponentType}</option>
                {this.props.hasStatusTimelineOption
                && <option value={StatusTimelineComponentType}>{StatusTimelineComponentType}</option>}
              </select>
            </div>

            {areLocaleControlsVisible(componentType) && (
              <>
                <div className={styles.formGroup}>
                  <label htmlFor="displayOption" className={styles.formLabel}>
                    Choose display option (locale or shipment state)
                  </label>
                  <select
                    value={displayOption}
                    id="displayOption"
                    name="displayOption"
                    className={styles.formElement}
                    onChange={this.handleChange}

                  >
                    {displayOptions.map(item => (
                      <option key={item.code} value={item.code}>{item.label}</option>
                    ))}
                  </select>
                </div>
                <div className={styles.formGroup}>
                  <SelectWithValidation
                    disabled={displayOption === ShipmentState.code}
                    value={locale}
                    id="locale"
                    name="locale"
                    label="Locale"
                    className={cx(styles.formElement, { [styles.hasError]: errors.locale })}
                    onChange={this.handleChangeSelect}
                    ref={this.innerRefs.locale}
                    options={[...locales.map(x => ({
                      value: x?.code.toLowerCase(),
                      label: x?.name,
                    }))]}
                    errors={errors}
                  />
                </div>
              </>
            )}

            {areImageControlsVisible(componentType) && (
              <div className={styles.formGroup}>
                <label className={styles.formLabel}>Image</label>
                <FilePicker
                  multiple={false}
                  value={base64Image}
                  accept={SupportedImageExtensions}
                  onChange={v => this.handleChangeByName('base64Image', v)}
                />
              </div>
            )}

            {areTextControlsVisible(componentType) && (
              <>
                <div className={styles.formGroup}>
                  <InputWithValidation
                    value={text}
                    label="Text"
                    id="text"
                    className={styles.formElement}
                    type="text"
                    placeholder="Enter your text"
                    name="text"
                    onChange={this.handleChange}
                    onBlur={this.handleTouch}
                    touched={touched.text}
                    errors={errors}
                    innerRef={this.innerRefs.text}
                    showErrorStrategy={showErrorStrategies.touched}
                  />
                </div>

                <div className={styles.formGroup}>
                  <label htmlFor="generalFontweight" className={styles.formLabel}>Text Position</label>
                  <select
                    value={rowTextPosition}
                    id="generalFontweight"
                    className={styles.formElement}
                    name="rowTextPosition"
                    onChange={this.handleChange}
                  >
                    <option value={TopLeft}>top left</option>
                    <option value={TopMiddle}>top center</option>
                    <option value={TopRight}>top right</option>
                    <option value={MiddleLeft}>middle left</option>
                    <option value={Middle}>middle center</option>
                    <option value={MiddleRight}>middle right</option>
                    <option value={BottomLeft}>bottom left</option>
                    <option value={BottomMiddle}>bottom center</option>
                    <option value={BottomRight}>bottom right</option>
                  </select>
                </div>
              </>
            )}

            {areUrlControlsVisible(componentType) && (
              <div className={styles.formGroup}>
                <InputWithValidation
                  label="Redirection Url"
                  value={redirectionUrl}
                  id="text"
                  className={styles.formElement}
                  type="text"
                  placeholder="Enter your redirection url"
                  name="redirectionUrl"
                  onChange={this.handleChange}
                  onBlur={this.handleTouch}
                  touched={touched.redirectionUrl}
                  errors={errors}
                  innerRef={this.innerRefs.redirectionUrl}
                  showErrorStrategy={showErrorStrategies.touched}
                />
              </div>
            )}

            {areFontControlsVisible(componentType) && (
              <div className={styles.formGroup}>
                <Font
                  globalFont={this.props.globalFont}
                  value={font}
                  errors={errors && errors.font}
                  touched={touched && touched.font}
                  onChange={v => this.handleChangeByName('font', v)}
                  onTouch={v => this.handleTouchByName('font', v)}
                  target="font"
                  customSection
                  innerRefs={this.innerRefs.font}
                />
              </div>
            )}

          </div>
          <DualButtons
            leftText="Close"
            leftFunction={toggleEditColumn}
            rightText="Save"
            rightFunction={this.handleSubmit}
          />
        </CardShell>
      </PopupCentered>
    );
  }
}

export default connect(state => ({
  locales: state.locales,
}))(GridEditColumn);
