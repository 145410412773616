import React, { Component } from 'react';
import { connect } from 'react-redux';
import { event as gaEvent } from 'react-ga';
import cx from 'classnames';
import API from 'helpers/API';
import shipment from 'helpers/shipment.json';
import fetchWithAuth from 'helpers/fetchWithAuth';
import {
  sendWebhook as sendWebhookAction,
  getWebhooks as getWebhooksAction,
} from 'actions/webhookActions';
import {
  closePopup as closePopupAction,
  showPopup as showPopupAction,
} from 'actions';
import webhookTitles from 'constants/webhooks';
import authMethodNames from 'constants/authMethods';
import httpMethods from 'constants/httpMethods';
import BackButton from 'components/BackButton';
import DualButtons from 'components/DualButtons';
import styles from './styles.scss';
import WebhookName from './WebhookName';
import ShippingState from './ShippingState';
import Locale from './Locale';
import AuthentMethod from './AuthentMethod';
import CreateWebhook from './CreateWebhook';
import RequestHeader from './RequestHeader';
import WebhookCardTitle from './WebhookCardTitle';

const initialState = {
  webhookTitle: 'Card Title',
  webhookTempTitle: '',
  webhookTempTitleValid: false,
  webhookPayloadVersion: 'V1X',
  currentWebhookIndex: 0,
  selectedShippingStates: [],
  currentMethodIndex: -1,
  selectedLocale: 'en-GB',
  apiHeaderKey: '',
  apiKeyValue: '',
  apiHeaderKeyValid: false,
  apiKeyValueValid: false,
  headersKey: '',
  headersValue: '',
  headersKeyValid: true,
  headersValueValid: true,
  jsonKey: '',
  jsonValue: '',
  jsonUrl: '',
  jsonHeader: '',
  jsonMask: '',
  jsonHeaderValid: false,
  jsonMaskValid: false,
  jsonUrlValid: false,
  jsonKeyValid: false,
  jsonValueValid: false,
  jsonEditorValue: '',
  jsonEditorValid: false,
  authUser: '',
  authPass: '',
  authUserValid: false,
  authPassValid: false,
  radioMethodChecked: '',
  urlAddress: '',
  urlAddressValid: false,
  jsonData: {},
  headersData: [],
  selectedHttpMethod: httpMethods.Put,
  selectedJwtHttpMethod: httpMethods.Put,
};

class WebhooksSteps extends Component {
  constructor(props) {
    super(props);

    const { locales, webhook } = props;
    this.state = {
      webhooksSteps: [
        properties => <WebhookName {...properties} />,
        properties => <ShippingState {...properties} />,
        properties => <Locale {...properties} />,
        properties => <AuthentMethod {...properties} />,
        properties => <RequestHeader {...properties} />,
        properties => <CreateWebhook {...properties} />,
      ],
      locales,
      ...initialState,
      isInEditMode: false,
      currentWebhookId: '',
      HttpMethodOptions: [
        {
          id: 1,
          value: httpMethods.Post,
          label: httpMethods.Post,
        },

        {
          id: 2,
          value: httpMethods.Put,
          label: httpMethods.Put,
        },
      ],

      stepValidators: [
        () => {
          const { webhookTitle, webhookPayloadVersion } = this.state;
          return webhookTitle !== ''
            && webhookTitle.length > 0
            && webhookTitle !== 'Card Title'
            && webhookTitle.replace(/ /g, '') !== ''
            && webhookPayloadVersion;
        },
        () => {
          const { selectedShippingStates } = this.state;
          return selectedShippingStates.length > 0;
        },
        () => true,
        () => {
          const {
            currentMethodIndex,
            apiHeaderKeyValid,
            apiKeyValueValid,
            authUserValid,
            authPassValid,
            jsonHeaderValid,
            jsonMaskValid,
            jsonUrlValid,
            jsonMask,

          } = this.state;

          switch (currentMethodIndex) {
            case 0:
              return apiHeaderKeyValid && apiKeyValueValid;
            case 1:
              return authUserValid && authPassValid;
            case 2:
              return jsonHeaderValid
                && jsonMaskValid && jsonUrlValid && jsonMask.length > 0 && jsonMask.indexOf('$JWT$') !== -1;

            default:
              return false;
          }
        },
        () => true,
        () => {
          const { urlAddressValid } = this.state;
          return urlAddressValid;
        },
      ],
    };

    if (webhook) {
      const webhookData = this.getWebhookData(webhook);
      this.state = {
        ...this.state,
        ...webhookData,
      };
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { webhook } = this.props;

    if (webhook && prevState.isInEditMode === false) {
      const webhookData = this.getWebhookData(webhook);
      this.setState(webhookData);
    }
  }

  onWebhookTempTitleChange = (event) => {
    gaEvent({
      category: 'Webhook',
      action: 'Webhook temp title change',
    });
    const inputValue = event.currentTarget.value;
    this.setState({
      webhookTempTitle: inputValue,
      webhookTitle: inputValue === '' ? 'Card Title' : inputValue,
      webhookTempTitleValid: inputValue.length > 0,
    });
  };

  onWebhookPayloadVersionChange = (event) => {
    gaEvent({
      category: 'Webhook',
      action: 'Webhook temp payload version change',
    });
    const inputValue = event.value;
    this.setState({
      webhookPayloadVersion: inputValue || 'V1X',
    });
  };

  onShippingItemClick = (element) => {
    const { selectedShippingStates } = this.state;
    const foundShippingStateId = selectedShippingStates.find(item => item === element.id);
    gaEvent({
      category: 'Webhook',
      action: 'Shipping item click',
    });
    if (foundShippingStateId) {
      this.setState({
        selectedShippingStates: selectedShippingStates.filter(item => item !== foundShippingStateId),
      });
    } else {
      this.setState({
        selectedShippingStates: [...selectedShippingStates, element.id],
      });
    }
  };

  onLocaleClick = (element) => {
    const { selectedLocale } = this.state;
    gaEvent({
      category: 'Webhook',
      action: `Locale change ${selectedLocale}`,
    });
    this.setState({
      selectedLocale: selectedLocale
        && selectedLocale.toLowerCase() === element.code.toLowerCase()
        ? null
        : element.code,
    });
  };

  onHeaderRequest = (e) => {
    e.preventDefault();
    const {
      headersData,
      headersKey,
      headersValue,
    } = this.state;

    const newHeader = {
      key: headersKey,
      value: headersValue,
    };

    if (headersKey && headersValue) {
      this.setState({
        headersData: [...headersData, newHeader],
        headersKey: '',
        headersValue: '',
      });
    } else {
      this.setState({
        headersKeyValid: headersKey && headersKey.length > 0,
        headersValueValid: headersValue && headersValue.length > 0,
      });
    }
  };

  onHeadersInputChange = (event) => {
    const { name, value } = event.currentTarget;
    const isValid = this.state[`headers${name}${'Valid'}`];
    gaEvent({
      category: 'Webhook',
      action: 'Headers input change',
    });

    // check if it is not valid to hide validation message if possible
    // or just change the value ohterwise
    if (!isValid) {
      this.setState({
        [`headers${name}`]: value,
        [`headers${name}${'Valid'}`]: value.length > 0,
      });
    } else {
      this.setState({
        [`headers${name}`]: value,
      });
    }
  };

  onJsonInputChange = (event) => {
    gaEvent({
      category: 'Webhook',
      action: 'JSON input change',
    });
    const { name, value } = event.currentTarget;
    this.setState({
      [`json${name}`]: value,
      [`json${name}${'Valid'}`]: value.length > 0,
    });
  };

  onJsonEditorChange = (name, value) => {
    gaEvent({
      category: 'Webhook',
      action: 'JSON editor change',
    });
    this.setState({
      [`jsonEditor${name}`]: value,
    });
  };

  onJsonEditorValidate = (validators) => {
    gaEvent({
      category: 'Webhook',
      action: 'JSON editor validate',
    });
    this.setState({
      jsonEditorValid: validators.length === 0,
    });
  };

  onApiHeaderInputChange = (event) => {
    gaEvent({
      category: 'Webhook',
      action: 'API header input change',
    });
    const { name, value } = event.currentTarget;
    this.setState({
      [`apiHeader${name}`]: value,
      [`apiHeader${name}${'Valid'}`]: value.length > 0,
    });
  };

  onApiValueInputChange = (event) => {
    gaEvent({
      category: 'Webhook',
      action: 'API value change',
    });
    const { name, value } = event.currentTarget;
    this.setState({
      [`apiKey${name}`]: value,
      [`apiKey${name}${'Valid'}`]: value.length > 0,
    });
  };

  onAuthUsernameChange = (event) => {
    gaEvent({
      category: 'Webhook',
      action: 'Auth username change',
    });
    const { name, value } = event.currentTarget;
    this.setState({
      [`auth${name}`]: value,
      [`auth${name}${'Valid'}`]: value.length > 0,
    });
  };

  onJsonFormSubmit = (event) => {
    gaEvent({
      category: 'Webhook',
      action: 'JSON form submit',
    });
    event.preventDefault();
    const {
      jsonData,
      jsonKey,
      jsonValue,
    } = this.state;

    const newKey = {
      ...jsonData,
      [jsonKey]: jsonValue,
    };

    if (jsonKey && jsonValue) {
      this.setState({
        jsonData: newKey,
        jsonKey: '',
        jsonValue: '',
      });
    }
  };

  onRequestHeaderDelete = (event, headerKey) => {
    gaEvent({
      category: 'Webhook',
      action: 'Request header delete',
    });
    const { headersData } = this.state;
    event.preventDefault();
    const filteredHeaderArray = headersData.filter(header => header.key !== headerKey);
    this.setState({
      headersData: filteredHeaderArray,
    });
  };

  onJsonRequestDelete = (event, index) => {
    gaEvent({
      category: 'Webhook',
      action: 'JSON request delete',
    });
    const { jsonData } = this.state;
    event.preventDefault();
    let result = {};
    Object.entries(jsonData).forEach((keyValue, id) => {
      if (index !== id) {
        result = {
          ...result,
          [keyValue[0]]: keyValue[1],
        };
      }
    });
    this.setState({
      jsonData: result,
    });
  };

  onHttpMethodChange = (value) => {
    gaEvent({
      category: 'Webhook',
      action: 'Http method change',
    });
    this.setState({
      selectedHttpMethod: value,
    });
  };

  onJwtHttpMethodChange = (value) => {
    gaEvent({
      category: 'Webhook',
      action: 'JWT Http method change',
    });
    this.setState({
      selectedJwtHttpMethod: value,
    });
  };

  onRadioMethodChoose = (event) => {
    gaEvent({
      category: 'Webhook',
      action: 'Radio method change',
    });
    this.setState({
      radioMethodChecked: event.currentTarget.value,
    });
  };

  onUpdateUrlAddress = (event) => {
    gaEvent({
      category: 'Webhook',
      action: 'Update email',
    });
    const urlAddressValue = event.currentTarget.value;
    this.setState({
      urlAddress: urlAddressValue,
      urlAddressValid: urlAddressValue.length > 0,
    });
  };

  onTestWebhookSubmit = (event) => {
    event.preventDefault();
    gaEvent({
      category: 'Webhook',
      action: 'Test webhook submit',
    });
    const { urlAddress } = this.state;
    const newWebhook = this.getTestSendConfiguration();
    const body = {
      payload: shipment,
    };

    let authData = {};
    if (newWebhook.scheme === authMethodNames.apiKey) {
      authData = {
        apiKeyHeader: newWebhook.authentication_settings.api_key,
        apiKeyValue: newWebhook.authentication_settings.api_value,
      };
    } else if (newWebhook.scheme === authMethodNames.basic) {
      authData = {
        username: newWebhook.authentication_settings.basic_username,
        password: newWebhook.authentication_settings.basic_password,
      };
    } else {
      authData = {
        jwtRequestUrl: newWebhook.authentication_settings.jwt_url,
        jwtRequestMethod: newWebhook.authentication_settings.jwt_method,
        jwtRequestBody: newWebhook.authentication_settings.jwt_payload,
        jwtRequestHeaders: newWebhook.authentication_settings.jwt_headers,
        jwtHeaderKey: newWebhook.authentication_settings.jwt_header_key,
        jwtHeaderMask: newWebhook.authentication_settings.jwt_header_value_mask,
      };
    }

    fetchWithAuth(
      urlAddress,
      newWebhook.scheme,
      authData,
      {
        method: newWebhook.http_method,
        body,
      },
    ).catch(() => {
      this.props.showPopup({
        title: 'URL provided can\'t be reached',
        description: 'The URL provided can\'t be reached with specified details',
        onDecline: () => this.props.closePopup(),
      });
    });
  };

  onCancelClick = () => {
    gaEvent({
      category: 'Webhook',
      action: 'Cancel webhook create/edit',
    });
    this.setState({
      ...initialState,
      isInEditMode: false,
    });
    this.props.flipCard();
  };

  onCreateClick = () => {
    gaEvent({
      category: 'Webhook',
      action: 'Webhook create / update edit',
    });
    const newWebhook = this.getWebhookConfiguration();

    const { isInEditMode, currentWebhookId } = this.state;

    let apiPromise;
    if (isInEditMode) {
      newWebhook.enabled = this.props.webhook.enabled;
      apiPromise = API.updateWebhook(currentWebhookId, newWebhook);
    } else {
      apiPromise = API.createWebhook(newWebhook);
    }
    apiPromise
      .then(() => {
        this.props.flipCard();
        this.props.getWebhooks();
        this.setState({
          ...initialState,
          isInEditMode: false,
        });
      });
  };

  getTestSendConfiguration = () => {
    const {
      webhookTitle,
      webhookPayloadVersion,
      currentMethodIndex,
      apiHeaderKey,
      apiKeyValue,
      authUser,
      authPass,
      jsonUrl,
      jsonHeader,
      jsonEditorValue,
      jsonData,
      jsonMask,
      urlAddress,
      selectedHttpMethod,
      selectedJwtHttpMethod,
    } = this.state;
    const { versions } = this.props;

    let scheme = '';
    const authSettings = {};
    switch (currentMethodIndex) {
      case 0:
        scheme = authMethodNames.apiKey;
        authSettings.api_key = apiHeaderKey;
        authSettings.api_value = apiKeyValue;
        break;
      case 1:
        scheme = authMethodNames.basic;
        authSettings.basic_password = authPass;
        authSettings.basic_username = authUser;
        break;
      case 2:
        scheme = authMethodNames.jwt;
        authSettings.jwt_url = jsonUrl;
        authSettings.jwt_method = selectedJwtHttpMethod;
        authSettings.jwt_headers = jsonData;
        authSettings.jwt_payload = jsonEditorValue;
        authSettings.jwt_header_key = jsonHeader;
        authSettings.jwt_header_value_mask = jsonMask;
        break;
      default:
        break;
    }
    return {
      name: webhookTitle,
      payload_version: versions.find(v => v.field_value === webhookPayloadVersion),
      url: urlAddress || '',
      scheme,
      http_method: selectedHttpMethod,
      shipment_filters: this.state.selectedShippingStates,
      authentication_settings: authSettings,

    };
  };

  getWebhookConfiguration = () => {
    const {
      webhookTitle,
      webhookPayloadVersion,
      currentMethodIndex,
      // selectedShippingStates,
      apiHeaderKey,
      apiKeyValue,
      authUser,
      authPass,
      jsonUrl,
      jsonHeader,
      jsonEditorValue,
      jsonData,
      jsonMask,
      urlAddress,
      selectedHttpMethod,
      selectedJwtHttpMethod,
    } = this.state;
    const { versions } = this.props;

    let scheme = '';
    const authSettings = {};
    switch (currentMethodIndex) {
      case 0:
        scheme = authMethodNames.apiKey;
        authSettings.api_key = apiHeaderKey;
        authSettings.api_value = apiKeyValue;
        break;
      case 1:
        scheme = authMethodNames.basic;
        authSettings.basic_password = authPass;
        authSettings.basic_username = authUser;
        break;
      case 2:
        scheme = authMethodNames.jwt;
        authSettings.jwt_url = jsonUrl;
        authSettings.jwt_method = selectedJwtHttpMethod;
        authSettings.jwt_headers = jsonData;
        authSettings.jwt_payload = jsonEditorValue;
        authSettings.jwt_header_key = jsonHeader;
        authSettings.jwt_header_value_mask = jsonMask;
        break;
      default:
        break;
    }
    return {
      enabled: true,
      configuration: {
        name: webhookTitle,
        payload_version: versions.find(v => v.field_value === webhookPayloadVersion),
        url: urlAddress || '',
        scheme,
        http_method: selectedHttpMethod,
        shipment_filters: this.state.selectedShippingStates,
        authentication_settings: authSettings,
        locale: this.state.selectedLocale,
        headers: this.state.headersData,
      },
    };
  };

  getCurrentWebhook = () => {
    let webhookProps;
    const {
      webhooksSteps,
      currentWebhookIndex,
      webhookTempTitle,
      webhookTempTitleValid,
      webhookPayloadVersion,
      selectedShippingStates,
      locales,
      selectedLocale,
      currentMethodIndex,
      radioMethodChecked,
      isActivePost,
      headersData,
      headersKey,
      headersValue,
      headersKeyValid,
      headersValueValid,
      jsonKey,
      jsonValue,
      jsonData,
      jsonKeyValid,
      jsonValueValid,
      jsonUrl,
      jsonUrlValid,
      jsonHeader,
      jsonMask,
      jsonHeaderValid,
      jsonMaskValid,
      HttpMethodOptions,
      urlAddress,
      urlAddressValid,
      apiHeaderKey,
      apiHeaderKeyValid,
      apiKeyValue,
      apiKeyValueValid,
      authUser,
      authPass,
      authUserValid,
      authPassValid,
      jsonEditorValue,
      jsonEditorValid,
      selectedHttpMethod,
      selectedJwtHttpMethod,
    } = this.state;
    switch (currentWebhookIndex) {
      case 0:
        webhookProps = {
          title: webhookTempTitle,
          onChange: this.onWebhookTempTitleChange,
          titleValid: webhookTempTitleValid,
          payloadVersion: webhookPayloadVersion,
          onChangePayloadVersion: this.onWebhookPayloadVersionChange,
        };
        break;
      case 1:
        webhookProps = {
          selectedStates: selectedShippingStates,
          onShippingItemClick: this.onShippingItemClick,
        };
        break;
      case 2:
        webhookProps = {
          locales,
          selectedLocale,
          onLocaleClick: this.onLocaleClick,
        };
        break;
      case 3:
        webhookProps = {
          currentMethodIndex,
          radioClicked: this.chooseMethodOnClick,
          putMethodClicked: this.changeInputPut,
          isActivePost,
          jsonKey,
          jsonValue,
          jsonData,
          jsonKeyValid,
          jsonValueValid,
          jsonUrl,
          jsonUrlValid,
          onJsonInputChange: this.onJsonInputChange,
          onJsonFormSubmit: this.onJsonFormSubmit,
          onJsonRequestDelete: this.onJsonRequestDelete,
          HttpMethodOptions,
          switchActiveClass: this.switchActiveClass,
          radioMethodChecked,
          onRadioMethodChoose: this.onRadioMethodChoose,
          onRadioCreateWebhookChange: this.onRadioCreateWebhookChange,
          apiHeaderKey,
          apiHeaderKeyValid,
          apiKeyValue,
          apiKeyValueValid,
          authUser,
          authPass,
          authUserValid,
          authPassValid,
          jsonHeader,
          jsonMask,
          jsonHeaderValid,
          jsonMaskValid,
          jsonEditorValid,
          onApiHeaderInputChange: this.onApiHeaderInputChange,
          onApiValueInputChange: this.onApiValueInputChange,
          onAuthUsernameChange: this.onAuthUsernameChange,
          jsonEditorValue,
          onJsonEditorChange: this.onJsonEditorChange,
          onJsonEditorValidate: this.onJsonEditorValidate,
          selectedJwtHttpMethod,
          onJwtHttpMethodChange: this.onJwtHttpMethodChange,
        };
        break;
      case 4:
        webhookProps = {
          headersData,
          headersKey,
          headersValue,
          headersKeyValid,
          headersValueValid,
          onHeaderRequest: this.onHeaderRequest,
          onHeadersInputChange: this.onHeadersInputChange,
          onRequestHeaderDelete: this.onRequestHeaderDelete,
        };
        break;
      case 5:
        webhookProps = {
          HttpMethodOptions,
          onTestWebhookSubmit: this.onTestWebhookSubmit,
          urlAddress,
          urlAddressValid,
          onUpdateUrlAddress: this.onUpdateUrlAddress,
          switchActiveClass: this.switchActiveClass,
          headersData,
          shipment,
          sendSuccess: this.props.isWebhookSent,
          selectedHttpMethod,
          onHttpMethodChange: this.onHttpMethodChange,
        };
        break;
      default:

        break;
    }
    return webhooksSteps[currentWebhookIndex](webhookProps);
  };

  getWebhookData = (webhook) => {
    const authSettings = {};

    if (webhook.configuration.scheme.toLowerCase() === authMethodNames.apiKey.toLowerCase()) {
      authSettings.currentMethodIndex = 0;
      authSettings.apiHeaderKey = webhook.configuration.authentication_settings.api_key;
      authSettings.apiHeaderKeyValid = webhook.configuration.authentication_settings.api_key.length > 0;
      authSettings.apiKeyValue = webhook.configuration.authentication_settings.api_value;
      authSettings.apiKeyValueValid = webhook.configuration.authentication_settings.api_value.length > 0;
    } else if (webhook.configuration.scheme.toLowerCase() === authMethodNames.basic.toLowerCase()) {
      authSettings.currentMethodIndex = 1;
      authSettings.authPass = webhook.configuration.authentication_settings.basic_password;
      authSettings.authPassValid = webhook.configuration.authentication_settings.basic_password.length > 0;
      authSettings.authUser = webhook.configuration.authentication_settings.basic_username;
      authSettings.authUserValid = webhook.configuration.authentication_settings.basic_username.length > 0;
    } else if (webhook.configuration.scheme.toLowerCase() === authMethodNames.jwt.toLowerCase()) {
      authSettings.currentMethodIndex = 2;
      authSettings.jsonUrl = webhook.configuration.authentication_settings.jwt_url;
      authSettings.jsonUrlValid = webhook.configuration.authentication_settings.jwt_url.length > 0;
      authSettings.jsonData = webhook.configuration.authentication_settings.jwt_headers;
      authSettings.jsonEditorValue = webhook.configuration.authentication_settings.jwt_payload;
      authSettings.jsonEditorValid = true;
      authSettings.jsonHeader = webhook.configuration.authentication_settings.jwt_header_key;
      authSettings.jsonHeaderValid = webhook.configuration.authentication_settings.jwt_header_key.length > 0;
      authSettings.jsonMask = webhook.configuration.authentication_settings.jwt_header_value_mask;
      authSettings.jsonMaskValid = true;
      authSettings.selectedJwtHttpMethod = webhook.configuration.authentication_settings.jwt_method;
    }

    return {
      isInEditMode: true,
      currentWebhookId: webhook.configuration_id,
      webhookTitle: webhook.configuration.name,
      webhookTempTitle: webhook.configuration.name,
      webhookTempTitleValid: webhook.configuration.name && webhook.configuration.name.length > 0,
      webhookPayloadVersion: webhook.configuration.payload_version.field_value || 'V11',
      selectedShippingStates: webhook.configuration.shipment_filters,
      selectedLocale: webhook.configuration.locale,
      urlAddress: webhook.configuration.url,
      urlAddressValid: webhook.configuration.url.length > 0,
      selectedHttpMethod: webhook.configuration.http_method,
      ...authSettings,
      headersData: webhook.configuration.headers,
    };
  };

  getRightButtonText() {
    const { isInEditMode } = this.state;
    if (this.isLastStep()) {
      return isInEditMode ? 'Update' : 'Create';
    }
    return 'Next';
  }

  chooseMethodOnClick = (methodIndex) => {
    this.setState({
      currentMethodIndex: methodIndex,
    });
  };

  isNextAvailable = () => {
    const { currentWebhookIndex, stepValidators } = this.state;
    return stepValidators[currentWebhookIndex]();
  };

  webhookBack() {
    this.setState(prevState => ({ currentWebhookIndex: prevState.currentWebhookIndex - 1 }));
  }

  isLastStep() {
    const { currentWebhookIndex, webhooksSteps } = this.state;
    return currentWebhookIndex === webhooksSteps.length - 1;
  }

  renderPaginationCards = (_pag, index) => (
    <li
      key={index}
      className={cx(styles.bullet, `${this.state.currentWebhookIndex === index ? styles.active : ''}`)}
    >
      <div className={styles.bulletItem} />
    </li>
  );

  render() {
    const {
      currentWebhookIndex,
      urlAddressValid,
    } = this.state;

    return (
      <div className={styles.webhookStepsWrapper}>
        <div className={styles.contentWrapper}>
          <WebhookCardTitle webhookTitle={webhookTitles[currentWebhookIndex]} />
          <ul className={styles.paginationList}>
            {this.state.webhooksSteps.map(this.renderPaginationCards)}
          </ul>
          {this.state.currentWebhookIndex > 0 && (
            <div className={styles.webhookBackButton}>
              <BackButton onBack={() => this.webhookBack()} />
            </div>
          )}
          {this.getCurrentWebhook()}
        </div>

        <div className={styles.buttonsWrapper}>
          <DualButtons
            leftFunction={this.onCancelClick}
            leftText="Cancel"
            rightFunction={this.isLastStep()
              ? this.onCreateClick
              : () => { this.setState({ currentWebhookIndex: currentWebhookIndex + 1 }); }}
            rightText={this.getRightButtonText()}
            theme="rightPink"
            rightFunctionDisabled={this.isLastStep() ? !urlAddressValid : !this.isNextAvailable()}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isWebhookSent: state.webhookSteps.isWebhookSent,
  versions: state.webhooksStates.versions,
});

const mapDispatchToProps = dispatch => ({
  sendWebhook: (url, body) => dispatch(sendWebhookAction(url, body)),
  getWebhooks: () => dispatch(getWebhooksAction()),
  showPopup: config => dispatch(showPopupAction(config)),
  closePopup: () => dispatch(closePopupAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WebhooksSteps);
