/* eslint-disable react/no-unused-class-component-methods */
/* We probably should remove getRedirectRouteAfterLogin */
import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { event as gaEvent } from 'react-ga';
import FormErrors from 'components/FormErrors';
import { required, isEmail } from 'helpers/validate';
import { validateForm } from 'helpers/validateForm';
import {
  loginUsernamePassword as loginUsernamePasswordAction,
  forgotPasswordClick as forgotPasswordClickAction,
} from 'actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './styles.scss';
import AuthenticationInput from '../AuthenticationInput/AuthenticationInput';
import StateCategoryGreen from '../../images/stateCategoryGreen.svg';

export class Login extends React.Component {
  validationRules = {
    email: [isEmail()],
    password: [required()],
  };

  static propTypes = {
    onLogin: propTypes.func.isRequired,
    onForgotPasswordClick: propTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isLoading: false,
      errors: {},
    };
  }

  getRedirectRouteAfterLogin = (location) => {
    const currentRoute = location.pathname;
    const lastRoute = location.state ? location.state.from : '';
    return currentRoute === lastRoute ? '/' : lastRoute;
  };

  handleOnChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = validateForm(this.validationRules, this.state);
    if (errors.hasErrors) {
      this.setState({ errors });
      gaEvent({
        category: 'Login',
        action: 'Has validation error',
      });
      return;
    }

    const { email, password } = this.state;
    this.setState({ isLoading: true, errors: {} });
    this.props.onLogin(email, password)
      .then(() => {
        this.setState({ isLoading: false });
        gaEvent({
          category: 'Login',
          action: 'Login success',
        });
      })
      .catch((e) => {
        this.setState({ errors: e, isLoading: false });
        gaEvent({
          category: 'Login',
          action: 'Login failed',
        });
      });
  };

  render() {
    const {
      email,
      password,
      isLoading,
    } = this.state;

    let { errors } = this.state;
    const { message } = this.props;

    // some tabs/views send form error message to login page using props and we need to show it
    // but if there are validation errors, then show validation errors only
    if (!errors || Object.keys(errors).length === 0) {
      const propsErrors = this.props.errors;
      if (propsErrors) {
        errors = propsErrors;
      }
    }

    return (
      <div className={styles.loginForm}>
        <div className={styles.registrationPageInner}>
          <div className={styles.formErrorContainer}>
            <FormErrors errors={errors} />
          </div>
          {message && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <StateCategoryGreen width="48px" height="48px" />
            <p className={styles.infoText}>{message}</p>
          </div>
          )}
          <form>
            <div style={{ marginBottom: '30px' }} className={errors && errors.email && styles.inputErrorContainer}>
              <AuthenticationInput
                id="loginFormInputEmail"
                type="email"
                value={email}
                name="email"
                onChange={this.handleOnChange}
                label="Email address"
                error={errors && errors.email && 'You must enter a valid email address'}
              />
            </div>

            <div style={{ marginBottom: '30px' }} className={errors && errors.password && styles.inputErrorContainer}>
              <AuthenticationInput
                id="loginFormInputPassword"
                label="Password"
                type="password"
                name="password"
                value={password}
                onChange={this.handleOnChange}
                error={errors && errors.password && 'You must enter your password'}
              />
            </div>

            <div className={styles.forgotPasswordContainer}>
              <div
                role="button"
                className={styles.forgotPassword}
                onClick={this.props.onForgotPasswordClick}
                tabIndex={0}
              >
                Forgotten password
              </div>
            </div>
            <button type="submit" onClick={this.handleSubmit} disabled={isLoading} className={styles.loginBtn}>
              {isLoading && <CircularProgress thickness={8} color="inherit" size={40} variant="indeterminate" className={styles.loadingSpinner} />}
              {!isLoading && !this.state.successEmailSent && 'Login'}
            </button>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ store: { login: loginStore } }) => ({
  onLogin: loginStore.onLogin,
  errors: loginStore.errors,
  message: loginStore.message,
});

const mapDispatchToProps = dispatch => ({
  onLogin: (email, password) => dispatch(loginUsernamePasswordAction(email, password)),
  onForgotPasswordClick: () => dispatch(forgotPasswordClickAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
