import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import ReactTable from 'react-table';
import DateInput from '@material-ui/core/TextField';
import { toUnixTime } from 'helpers/datetime';
import dates, { format, isValid } from 'date-fns';
import styles from './styles.scss';
import { getSmartFilters as getSmartFiltersAction } from '../../../actions/smartFiltersActions';
import Spinner from '../../../components/Spinner';
import DateTimeStyles from '../../../components/Filters/DateTime.scss';
import TitleNavBlock from '../../../components/TitleNavBlock/TitleNavBlock';
import typography from '../../../helpers/appTypography';

const getColumnValue = (cellInfo) => {
  const colItem = cellInfo.original.stats.find(item => item.header === cellInfo.column.id);
  if (colItem) {
    return colItem.value;
  }
  return '-';
};
const columnDefaultProps = {
  minWidth: 80,
  maxWidth: 200,
  Cell: getColumnValue,
};
const columns = [
  {
    Header: 'Notification',
    id: 'filter_name',
    accessor: 'filter_name',
    minWidth: 130,
  },
  {
    ...columnDefaultProps,
    Header: 'Average Wait Time (hh:mm)',
    id: 'Avg Wait Time',
  },
  {
    ...columnDefaultProps,
    Header: 'Min Wait Time (hh:mm)',
    id: 'Min Wait Time',
  },
  {
    ...columnDefaultProps,
    Header: 'Max Wait Time (hh:mm)',
    id: 'Max Wait Time',
  },
  {
    ...columnDefaultProps,
    Header: 'Total Shipments',
    id: 'Total Shipments',
  },
  {
    ...columnDefaultProps,
    Header: 'Total Communications',
    id: 'Total Communications',
  },
  {
    ...columnDefaultProps,
    Header: 'Partial Communications',
    id: 'Partial Communications',
  },
  {
    ...columnDefaultProps,
    Header: 'Percentage Partial Communications',
    id: 'Percentage Partial Communications',
  },
];

const extractDate = dateObject => (dateObject ? format(new Date(dateObject), 'YYYY-MM-DD') : '');

const SmartFilters = () => {
  const {
    isLoading, smartFiltersStatistics, requestDidTimeout,
  } = useSelector(state => state.smartFilters);
  const dispatch = useDispatch();
  const getSmartFilters = useCallback(
    (start, end) => dispatch(getSmartFiltersAction(toUnixTime(new Date(start)), toUnixTime(new Date(end)))),
    [dispatch],
  );

  const [startDate, setStartDate] = useState(dates.subDays(dates.startOfDay(Date.now()), 6)); // initial value is 7 days ago
  const [endDate, setEndDate] = useState(dates.endOfDay(Date.now()));
  const [filteredStartDate, setFilteredStartDate] = useState();
  const [filteredEndDate, setFilteredEndDate] = useState();
  const [invalidInterval, setInvalidInterval] = useState(false);
  useEffect(() => {
    if (isValid(startDate) && isValid(endDate) && startDate.getTime() > 0 && endDate.getTime() > 0) {
      setFilteredStartDate(format(startDate, 'DD/MM/YYYY'));
      setFilteredEndDate(format(endDate, 'DD/MM/YYYY'));
      if (startDate <= endDate) {
        setInvalidInterval(false);
        getSmartFilters(startDate, endDate);
      } else {
        setInvalidInterval(true);
      }
    }
  }, [getSmartFilters, startDate, endDate]);
  const handleRetry = () => {
    getSmartFilters(startDate, endDate);
  };
  const handleDateRangePick = (e) => {
    const { name, value } = e.target;
    if (name === 'startDate') {
      setStartDate(dates.startOfDay(value));
    } else {
      setEndDate(dates.endOfDay(value));
    }
  };
  return (
    <div>
      {isLoading ? <Spinner /> : null}
      <TitleNavBlock
        title={typography.smartFilterStatistics.title}
        subTitle={typography.smartFilterStatistics.subTitle}
        onBack={() => window.history.back()}
      />
      <div className={styles.innerContainer}>
        <div className={styles.activeFilters}>
          <DateInput
            name="startDate"
            label="From: "
            inputProps={{
              className: classnames(
                DateTimeStyles.dateInput,
                DateTimeStyles.hasValue,
              ),
              title: invalidInterval ? null : `Currently Showing Data for ${filteredStartDate} to ${filteredEndDate}`,
            }}
            InputLabelProps={{ style: { fontFamily: 'RundText, Arial, sans-serif' } }}
            style={{ paddingRight: 20 }}
            value={extractDate(startDate)}
            type="date"
            onChange={handleDateRangePick}
            id="startDate"
            placeholder="--/--/--"
          />
          <DateInput
            name="endDate"
            label="To: "
            inputProps={{
              className: classnames(
                DateTimeStyles.dateInput,
                DateTimeStyles.hasValue,
              ),
              title: invalidInterval ? null : `Currently Showing Data for ${filteredStartDate} to ${filteredEndDate}`,
            }}
            InputLabelProps={{ style: { fontFamily: 'RundText, Arial, sans-serif' } }}
            value={extractDate(endDate)}
            type="date"
            onChange={handleDateRangePick}
            id="endDate"
            placeholder="--/--/--"
          />
        </div>
        {!isLoading && invalidInterval && (<p>The date range selected is incorrect</p>)}
        {!isLoading && !invalidInterval && smartFiltersStatistics?.length > 0
          && (
            <div className={styles.reactTableHolder}>
              <ReactTable
                data={smartFiltersStatistics}
                columns={columns}
                sortable={false}
                loading={isLoading}
                showPaginationBottom={false}
                defaultPageSize={smartFiltersStatistics.length}
              />
            </div>
          )}
        { !smartFiltersStatistics?.length && !requestDidTimeout && !isLoading && <p>There were no smart filters found</p>}
        {!isLoading && requestDidTimeout
          && (
            <>
              <p>We are temporarily unable to load smart filters. Please try again later.</p>
              <div
                className={styles.button}
                onClick={handleRetry}
                role="button"
              >
                Retry
              </div>
            </>
          )}
      </div>
    </div>
  );
};

export default SmartFilters;
