import React from 'react';
import { Switch } from 'react-router';
import AuthRoute from 'routes/Auth/AuthRoute';
import resources from 'constants/resources';
import SftpAccountsList from 'routes/Settings/SftpAccount/SftpAccountsList';
import AddSftpAccount from 'routes/Settings/SftpAccount/AddSftpAccount';

export default () => (
  <Switch>
    <AuthRoute
      exact
      path="/settings/sftp-account"
      component={SftpAccountsList}
      resource={resources.sftpAccount.read}
    />
    <AuthRoute
      exact
      path="/settings/sftp-account/add"
      component={AddSftpAccount}
      resource={resources.sftpAccount.edit}
    />
  </Switch>
);
