import React, { Component } from 'react';
import CardShell from 'components/CardShell';
import { event as gaEvent } from 'react-ga';
import API from 'helpers/API';
import { withRouter } from 'react-router-dom';
import MemberForm from 'routes/Users/MemberForm';
import { getFormErrorsFromApiError, mapErrorObjectPropertiesMessagesToInputNamesErrors } from 'helpers/apiError';

class EditMember extends Component {
  get userId() {
    return this.props.match.params.id;
  }

  save = async ({
    firstName, lastName, email, roleId,
  }) => {
    try {
      await API.editUser(this.userId, {
        firstName,
        lastName,
        email,
        roleId,
      });
      gaEvent({
        category: 'AccountAndUserManagement',
        action: 'User edited',
      });
    } catch (e) {
      if (e.status === 400) {
        const erorrsMap = {
          first_name: { inputName: 'firstName', label: 'First name' },
          last_name: { inputName: 'lastName', label: 'Last name' },
          email: { inputName: 'email', label: 'Email' },
          role_id: { inputName: 'roleId', label: 'User type' },
        };
        const errors = getFormErrorsFromApiError(e.json);
        throw mapErrorObjectPropertiesMessagesToInputNamesErrors(errors, erorrsMap);
      }
      throw e;
    }
  };

  load = async () => API.getUser(this.userId);

  render() {
    return (
      <div>
        <h1 className="h2">Edit user</h1>
        <div
          className="container__cards"
          style={{ background: '#f7f7f7', maxHeight: '635px' }}
        >
          <CardShell noOverflow>
            <MemberForm save={this.save} load={this.load} saveLabel="Save" isEmailReadonly />
          </CardShell>
        </div>
      </div>
    );
  }
}

export default withRouter(EditMember);
