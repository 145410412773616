import { toContractMap } from './componentTypeMap';

const getPreparedFontForBE = font => ({
  name: font.name,
  url: font.url,
  size: font.size,
  font_weight: font.fontWeight,
  colour: font.colour,
});

const getPreparedRowItemForBE = rowItem => ({
  id: rowItem.id,
  order: rowItem.order,
  base64_image: rowItem.base64Image,
  text: rowItem.text,
  row_text_position: rowItem.rowTextPosition,
  font: rowItem.font
    ? getPreparedFontForBE(rowItem.font)
    : undefined,
  redirection_url: rowItem.redirectionUrl,
  component_type: toContractMap[rowItem.componentType],
  display_option: rowItem.displayOption,
  locale: rowItem.locale,
});

const getPreparedRowForBE = row => ({
  id: row.id,
  order: row.order,
  row_height: row.rowHeight,
  row_items: row.rowItems
    ? row.rowItems.map(rowItem => getPreparedRowItemForBE(rowItem))
    : [],
});

const getPreparedLayoutForBE = layout => ({
  id: layout.id,
  layout_type: layout.layoutType,
  rows: layout.rows
    ? layout.rows.map(row => getPreparedRowForBE(row))
    : [],
});

const getPreparedNavigationBarItemForBE = navigationBarItem => ({
  id: navigationBarItem.id,
  order: navigationBarItem.order,
  text: navigationBarItem.text,
  redirection_url: navigationBarItem.redirectionUrl,
});

const getPreparedNavigationBarForBE = navigationBar => ({
  base64_logo: navigationBar.base64Logo,
  background_colour: navigationBar.backgroundColour,
  border_colour: navigationBar.borderColour,
  font: navigationBar.font ? getPreparedFontForBE(navigationBar.font) : undefined,
  root_url: navigationBar.rootUrl,
  navigation_bar_items: navigationBar.navigationBarItems
    ? navigationBar.navigationBarItems.map(navigationBarItem => getPreparedNavigationBarItemForBE(navigationBarItem))
    : [],
});

export const getPreparedTrackingPageDataForBE = trackingPageData => ({
  base64_fav_icon: trackingPageData.base64FavIcon,
  title: trackingPageData.title,
  is_carrier_link_active: trackingPageData.isCarrierLinkActive,
  font: trackingPageData.font
    ? getPreparedFontForBE(trackingPageData.font)
    : undefined,
  navigation_bar: trackingPageData.navigationBar
    ? getPreparedNavigationBarForBE(trackingPageData.navigationBar)
    : undefined,
  layouts: trackingPageData.layouts
    ? trackingPageData.layouts.map(layout => getPreparedLayoutForBE(layout))
    : [],
});

export default getPreparedTrackingPageDataForBE;
