import React from 'react';
import cx from 'classnames';
import ContextPopup from 'components/ContextPopup/ContextPopup';
import VEllipsisSvg from 'images/vEllipsis.svg';
import * as propTypes from 'prop-types';
import styles from './styles.scss';

function CardEllipsisMenu({
  primaryText, primaryClickHandler, primaryDisabled, children,
}) {
  return (
    <div className={styles.buttonsContainer}>
      <button
        type="button"
        disabled={primaryDisabled}
        className={cx(styles.textButton, styles.primaryButton)}
        onClick={primaryClickHandler}
      >
        {primaryText}
      </button>
      <div>
        <ContextPopup
          popupOwnerRender={(togglePopup, isPopupShown) => (
            <button
              type="button"
              className={cx(styles.ellipsisButton)}
              onClick={togglePopup}
            >
              <VEllipsisSvg className={cx(styles.ellipsisIcon, isPopupShown ? styles.active : null)} />
            </button>
          )}
        >
          <div className={styles.ellipsisPopup}>
            {children}
          </div>
        </ContextPopup>
      </div>
    </div>
  );
}

CardEllipsisMenu.defaultProps = {
  primaryDisabled: false,
  children: null,
};

CardEllipsisMenu.propTypes = {
  primaryText: propTypes.string.isRequired,
  primaryClickHandler: propTypes.func.isRequired,
  primaryDisabled: propTypes.bool,
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.element),
    propTypes.element]),
};

export default CardEllipsisMenu;
