import { maxLength, required } from 'helpers/validate';
import { validateNestedForm } from 'helpers/validateForm';

function getValidationRules() {
  const rules = {
    content: [
      required(() => 'Message should not be empty.'),
      maxLength(2000, () => 'The length of the message should not exceed 2000 characters.')],
  };
  return rules;
}

export default function validateShipmentNote(notification) {
  const validationRules = getValidationRules();
  return validateNestedForm(notification, validationRules);
}
