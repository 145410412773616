import React from 'react';

/* eslint-disable */
export default props => (
  <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42 28'>
      <path d='M 26.55 8.3 Q 26.6705078125 7.8994140625 26.45 7.5 25.510546875 5.7044921875 23.9 4.1 19.811328125 0.004296875 14 0 8.2423828125 0.0041015625 4.1 4.1 0.004296875 8.188671875 0 14 0.0041015625 19.757421875 4.1 23.9 8.2423828125 27.9958984375 14 28 19.811328125 27.995703125 23.9 23.9 L 23.9 23.9 Q 25.4666015625 22.3068359375 26.45 20.5 26.6703125 20.1287109375 26.55 19.7 26.43671875 19.327734375 26.05 19.1 25.7173828125 18.9294921875 25.3 19.05 24.927734375 19.151953125 24.7 19.5 23.8333984375 21.0931640625 22.45 22.5 L 22.5 22.5 Q 18.988671875 26.004296875 14 26 9.0576171875 26.004296875 5.5 22.5 1.995703125 18.9421875 2 14 1.995703125 9.011328125 5.5 5.5 9.0576171875 1.995703125 14 2 18.988671875 1.995703125 22.5 5.5 23.8892578125 6.8955078125 24.7 8.45 24.9126953125 8.83359375 25.3 8.95 25.7013671875 9.0708984375 26.05 8.85 26.434375 8.6875 26.55 8.3 M 42 14 Q 41.996484375 13.5892578125 41.7 13.3 L 34.65 6.25 Q 34.3607421875 5.953515625 33.95 5.95 33.5392578125 5.953515625 33.25 6.25 32.953515625 6.5392578125 32.95 6.95 32.953515625 7.3607421875 33.25 7.65 L 38.6 13 13 13 Q 12.5892578125 13.003515625 12.3 13.3 12.003515625 13.5892578125 12 14 12.003515625 14.4107421875 12.3 14.7 12.5892578125 14.996484375 13 15 L 38.6 15 33.25 20.35 Q 32.954296875 20.6400390625 32.95 21.05 32.9529296875 21.45859375 33.25 21.75 33.539453125 22.0462890625 33.95 22.05 34.358203125 22.046484375 34.65 21.75 L 41.7 14.7 Q 41.996484375 14.4107421875 42 14 Z' />
  </svg>
)

/* eslint-enable */
