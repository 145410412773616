/* eslint-disable no-await-in-loop */
import { parseUrl, stringify } from 'helpers/queryString';
import API from 'helpers/API';
import casing from 'casing';
import saveAs from 'helpers/saveAs';
import { parseFiltersSearchQuery } from 'helpers/parseFiltersSearchQuery';
import { flattenData, replaceDates } from 'helpers/datetime';

const buildQueryStringForExport = (
  filterQueryString,
  states,
  calculatedEvents,
  calculatedEventsOperator,
) => {
  const { query } = parseUrl(filterQueryString);

  if (states) {
    query.states = states;
  }

  if (calculatedEvents) {
    calculatedEvents.forEach((x) => {
      // Dashboard backend query string params are all camelCase. So we need to convert may_be_missing to mayBeMissing
      const queryKey = casing.camelize(x);
      query[queryKey] = calculatedEvents.includes(x).toString();
    });

    if (calculatedEventsOperator) {
      query.calculatedEventsOperator = calculatedEventsOperator;
    }
  }
  const updatedQuery = stringify(query);

  return stringify(flattenData(replaceDates(parseFiltersSearchQuery(updatedQuery))));
};

function sleep(ms) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function waitUntilStatusCompleted(exportCsvResponse) {
  let statusResponse = await API.getShipmentExportStatus(exportCsvResponse.reference);

  while (statusResponse.status !== 'Completed') {
    await sleep(1000);
    statusResponse = await API.getShipmentExportStatus(exportCsvResponse.reference);
  }

  return new Promise(resolve => {
    resolve(exportCsvResponse);
  });
}

const exportCsv = (
  queryString,
  states = null,
  calculatedEvents = null,
  calculatedEventsOperator = null,
) => {
  const exportQueryString = buildQueryStringForExport(
    queryString,
    states,
    calculatedEvents,
    calculatedEventsOperator,
  );

  return API.requestShipmentsExport(exportQueryString)
    .then((response) => waitUntilStatusCompleted(response))
    .then((response) => API.getShipmentExportCsv(response.reference))
    .then((blob) => {
      saveAs(blob, 'shipments.csv');
      return Promise.resolve();
    })
    .catch(e => Promise.reject(e));
};

const exportProactiveAlertCsv = async (
  dashboardName,
  alertName,
  queryString,
  states = null,
  calculatedEvents = null,
  calculatedEventsOperator = null,
) => {
  const exportQueryString = buildQueryStringForExport(
    queryString,
    states,
    calculatedEvents,
    calculatedEventsOperator,
  );
  const fullQueryString = `${exportQueryString}&dashboardName=${dashboardName}&alertName=${alertName}`;

  return API.requestProactiveShipmentsExport(fullQueryString)
    .then((response) => waitUntilStatusCompleted(response))
    .then((response) => API.getShipmentExportCsv(response.reference))
    .then((blob) => {
      saveAs(blob, 'shipments.csv');
      return Promise.resolve();
    })
    .catch(e => Promise.reject(e));
};

export { exportCsv, exportProactiveAlertCsv };
