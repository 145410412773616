import React, { useEffect, useState } from 'react';
import API from 'helpers/API';
import Spinner from 'components/Spinner';
import CardShell from 'components/CardShell';
import DualButtons from 'components/DualButtons';
import validateNotification from 'routes/Settings/Notifications/NotificationSetup/validateNotification';
import NotificationValues from './NotificationValues';
import Popup from '../../../../components/Layout/Popup';
import styles from '../styles.scss';
import TitleNavBlock from '../../../../components/TitleNavBlock/TitleNavBlock';
import typography from '../../../../helpers/appTypography';

export default function EditNotification({ history, match: { params: { notificationId } } }) {
  const [configurations, setConfigurations] = useState([]);
  const [notification, setNotification] = useState({});
  const [popup, setPopup] = useState({});
  const [configLoading, setConfigLoading] = useState(true);
  const [notificationLoading, setNotificationLoading] = useState(notificationId);
  const [saving, setSaving] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setConfigLoading(true);
      const configurationsResponse = await API.getSinkConfigurations();

      setConfigurations(configurationsResponse.configurations);
      setConfigLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (notificationId) {
      const fetchData = async () => {
        setNotificationLoading(true);
        const notificationResponse = await API.getNotification(notificationId);
        const loadedNotification = notificationResponse.notification;

        setNotification(loadedNotification);
        setNotificationLoading(false);
      };

      fetchData();
    }
  }, [notificationId]);

  if (configLoading || notificationLoading) {
    return (
      <Spinner />
    );
  }

  const configuration = configurations.find(x => x.id === notification.sinkConfigurationId);
  const configurationType = configuration ? configuration.type : null;
  const errors = validateNotification(notification, configurationType);
  const displayErrors = showErrors ? errors : null;

  const saveNotification = async () => {
    if (Object.values(errors).length > 0) {
      setShowErrors(true);
      return;
    }

    setSaving(true);
    try {
      await API.editNotification(notificationId, notification);
    } finally {
      setSaving(false);
    }
    setPopup({
      open: true,
      title: 'Success',
      description: <span>Notification saved.</span>,
      onDecline: () => {
        history.push('/settings/notification-centre/notification-setup');
      },
    });
  };

  return (
    <div>
      <Popup
        open={popup.open}
        title={popup.title}
        description={popup.description}
        onAccept={popup.onAccept}
        onDecline={popup.onDecline}
      />
      <TitleNavBlock
        title={typography.editNotification.title}
        subTitle={typography.editNotification.subTitle}
        onBack={() => window.history.back()}
      />
      {configurations ? (
        <div className="container__cards-large">
          <CardShell noOverflow>
            <div className={styles.container}>
              <div className={styles.cardSectionInner}>
                <NotificationValues
                  configurationType={configurationType}
                  notification={notification}
                  setNotification={setNotification}
                  errors={displayErrors}
                  history={history}
                />
              </div>
              <DualButtons
                leftFunction={() => history.push('/settings/notification-centre/notification-setup')}
                leftText="Cancel"
                rightFunction={() => saveNotification()}
                rightFunctionDisabled={saving}
                rightText="Save"
                theme="rightPink"
              />
            </div>
          </CardShell>
        </div>
      ) : (<p>No configurations found</p>)}
    </div>
  );
}
