const initialState = {
  open: false,
  keySecret: '',
  onClose: null,
  onClick: null,
};

export default function customPopupReducer(state = initialState, action) {
  switch (action.type) {
    case 'OPEN_CUSTOM_POPUP':
      return {
        open: true,
        component: action.component,
        onClose: action.onClose,
        onClick: action.onClick,
      };
    case 'CLOSE_CUSTOM_POPUP':
      return {
        open: false,
      };

    default:
      return state;
  }
}
