/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import styles from './styles.scss';
import BackButton from '../BackButton';
import SettingsItemBlock from './SettingsItemBlock/SettingsItemBlock';
import settingsDescription from './SettingsDescription';
import { getClaimsCached } from '../../helpers/auth';
import useStyles from '../Sidebar/MainMenuItems/useStyles';
import typography from '../../helpers/appTypography';

const SettingsSummaryPage = () => {
  const handleBackButtonClick = () => {
    window.history.back();
  };

  const [user, setUser] = useState();

  const userOptions = {
    // admin = 1
    // marketing = 3
    // dashboard = 4
    // basic = 5
    1: {
      gettingStarted: settingsDescription[0].gettingStarted,
      notificationSettings: settingsDescription[0].notificationSettings,
      accountSettings: settingsDescription[0].accountSettings,
    },
    3: {
      gettingStarted: [settingsDescription[0].gettingStarted[2]],
      notificationSettings: settingsDescription[0].notificationSettings,
      accountSettings: [settingsDescription[0].accountSettings[0]],
    },
    4: {
      accountSettings: [settingsDescription[0].accountSettings[0]],
    },
    5: {
      accountSettings: [settingsDescription[0].accountSettings[0]],
    },
  };

  const options = userOptions[user] || [];

  useEffect(() => {
    const userRole = getClaimsCached();
    setUser(userRole.role_id);
  }, [user]);
  return (
    <div>
      <div className={styles.titleBlock}>
        <BackButton onBack={handleBackButtonClick} />
        <Typography variant="h2" component="h2" style={useStyles.settingPageTitle}>
          {typography.settingsPage.title}
        </Typography>
        <span className={styles.subTitleText}>{typography.settingsPage.subTitle}</span>
      </div>
      {
        // block 1
        (user === '1' || user === '3') && (
          <div className={styles.settingsWrapperBlock}>
            <div>
              <Typography variant="h3" component="h3" style={useStyles.settingsPageMenuItems}>
                Getting started settings
              </Typography>
            </div>
            <div className={classNames(styles.settingsGettingStartedChildBlock, { [styles.settingsGettingStartedChildBlockOneLine]: user === '3' })}>
              {options.gettingStarted
                && options.gettingStarted.map(item => (
                  <SettingsItemBlock
                    key={item.title}
                    svg={item.svg}
                    title={item.title}
                    description={item.description}
                    navLink={item.navLink}
                  />
                ))}
            </div>
          </div>
        )
      }
      {
        // block 2
        (user === '1' || user === '3') && (
          <div className={styles.settingsWrapperBlock}>
            <div>
              <Typography variant="h3" component="h3" style={useStyles.settingsPageMenuItems}>
                Notifications settings
              </Typography>
            </div>
            <div className={styles.settingsNotificationsChildBlock}>
              {options.notificationSettings
                && options.notificationSettings.map(item => (
                  <SettingsItemBlock
                    key={item.title}
                    svg={item.svg}
                    title={item.title}
                    description={item.description}
                    navLink={item.navLink}
                  />
                ))}
            </div>
          </div>
        )
       }

      {
        // block 3
        (user === '1' || user === '3' || user === '5' || user === '4') && (
        <div className={styles.settingsWrapperBlock}>
          <div>
            <Typography variant="h3" component="h3" style={useStyles.settingsPageMenuItems}>
              Account settings
            </Typography>
          </div>
          <div className={styles.settingsAccountChildBlock}>
            {options.accountSettings
              && options.accountSettings.map(item => (
                <SettingsItemBlock
                  key={item.title}
                  svg={item.svg}
                  title={item.title}
                  description={item.description}
                  navLink={item.navLink}
                />
              ))}
          </div>
        </div>
        )
       }
    </div>
  );
};

export default SettingsSummaryPage;
