import React, { Component } from 'react';
import Clipboard from 'react-clipboard.js';
import CardShell from 'components/CardShell';
import Svg from 'components/Svg';
import styles from 'routes/Settings/RegisterShipmentWebhooks/AuthorisedEmails/AddEmail/GeneratedEmail.scss';
import { connect } from 'react-redux';
import {
  closeCustomPopup as closeCustomPopupAction,
  clearInputName as clearInputNameAction,
} from 'actions';

class GeneratedEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      copied: false,
    };
  }

  render() {
    if (!this.props.value) return null;
    return (
      <CardShell>
        <div className={styles.createdSectionWrapper}>
          <div className={styles.iconWrapperSvg}>
            <Svg name="info" />
          </div>
          <div className={styles.sectionHeaderWrapper}>
            <div className={styles.alertMessagesHeader}>
              <h3 className="textCenter">Here’s your email.</h3>
              <input id="secret" type="text" value={this.props.value} readOnly className={styles.copyInput} />
              <p className="textCenter mb-0">
                Use the email above to add to your BCC, when sending &quot;order dispatch&quot; emails to your customers.
              </p>
              {this.state.copied && (
                <div>
                  <p className="textCenter subtitle1 mb-0">Email copied successfully.</p>
                  <p className="textCenter mt-2">Please keep it safe and save.</p>
                </div>
              )}
            </div>
          </div>

          <div className={styles.dualButtons}>
            <div role="button" className={styles.dualButton}>
              <Clipboard data-clipboard-text={this.props.value} onSuccess={() => this.setState({ copied: true })}>
                <span>Copy</span>
              </Clipboard>
            </div>
            <button
              type="button"
              onClick={this.props.onClose}
              className={styles.dualButton}
            >Accept
            </button>
          </div>
        </div>
      </CardShell>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  closeCustomPopup: () => {
    dispatch(clearInputNameAction());
    dispatch(closeCustomPopupAction());
  },
});

export default connect(null, mapDispatchToProps)(GeneratedEmail);
