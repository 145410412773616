import React, { useEffect, useState } from 'react';
import ArrowsRotateRegular from '../../images/ArrowsRotateRegular';
import styles from './UpdatedAtWithRefresh.scss';

export const dateWithPmAm = ({ dateToDisplay }) => dateToDisplay.toLocaleTimeString('en-US', {
  // en-US can be set to 'default' to use user's browser settings
  hour: '2-digit',
  minute: '2-digit',
});

const UpdatedAtWithRefresh = ({ setTriggerRefresh, getUpdated }) => {
  const [dateDecorated, setDateDecorated] = useState(null);
  const onRefreshClick = () => {
    setTriggerRefresh(true);
  };
  useEffect(() => {
    const dateToChange = new Date();
    setDateDecorated(dateWithPmAm({ dateToDisplay: dateToChange }));
  }, [getUpdated]);
  return (

    <div className={styles.container}>
      <p className={styles.text}>
        {`Updated at: ${dateDecorated} `}
      </p>
      <div>
        <button type="button" className={styles.refreshIcon} onClick={() => onRefreshClick()}>
          <ArrowsRotateRegular />
        </button>
      </div>
    </div>
  );
};

export default UpdatedAtWithRefresh;
