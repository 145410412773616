import React from 'react';
import { NavLink } from 'react-router-dom';
import propTypes from 'prop-types';
import styles from './styles.scss';

const NavItem = ({
  url, title, children, onClick,
}) => {
  if (children) {
    return (
      <div className={styles.container}>
        <div className={styles.topRow}>
          {title}
        </div>
        <div className={styles.subItems}>
          {children}
        </div>
      </div>
    );
  }
  if (onClick) {
    return (
      <div className={styles.container}>
        <div className={styles.topRow} role="button" onClick={onClick}>
          {title}
        </div>
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <NavLink className={styles.topRow} to={`/${url}`}>
        {title}
      </NavLink>
    </div>
  );
};

NavItem.propTypes = {
  title: propTypes.node.isRequired,
  url: propTypes.string,
  children: propTypes.node,
  onClick: propTypes.oneOfType([
    propTypes.func,
    propTypes.bool,
  ]),
};

NavItem.defaultProps = {
  onClick: false,
  children: '',
  url: '',
};

export default NavItem;
