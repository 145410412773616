import {
  getAuthTokenValue,
  getAuthRefreshTokenValue,
  setAuthTokenValue,
  removeAuthToken,
  removeEmailAddress,
  getCustomerId,
} from 'helpers/auth';
import {
  format,
} from 'date-fns';
import { removeSelectedFilters } from 'helpers/selectedFilters';
import casing from 'casing';
import { inactivityOnLogout } from 'helpers/inactivity';
import { datadogOnLogout } from 'helpers/datadog';

const { location: { hostname, protocol, origin } } = window;
const apiPath = '/api/';
const trackingPath = '/tracking/';
const apiFullPath = process.env.NODE_ENV === 'development'
  ? `https://app-qa.sorted.com${apiPath}`
  : `${protocol}//${hostname}${apiPath}`;

export const getTrackingPagePath = (trackingPageName, version, isPreview) => {
  const customerId = getCustomerId();
  const previewPart = isPreview ? `/preview?version=${version}&` : '?';
  return process.env.NODE_ENV === 'development'
    ? `https://react-qa.sorted.com${trackingPath}${trackingPageName}${previewPart}customer_id=${customerId}`
    // it is a hotfix for TP preview routing
    // basically it should be moved to env-based config
    // but there is no proper config management for all environment for now
    : `${origin.replace('app', 'react')}${trackingPath}${trackingPageName}${previewPart}customer_id=${customerId}`;
};

class API {
  // eslint-disable-next-line no-promise-executor-return
  delay = ms => new Promise(r => setTimeout(r, ms));

  fetchPromise = (url, options) => {
    const token = getAuthTokenValue();

    return fetch(apiFullPath + url, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  };

  handleResponse = (r, url, options, isNoContentExpected) => {
    if (r.status === 202 || r.status === 204) {
      return Promise.resolve();
    }
    if (r.status >= 200 && r.status < 204) {
      if (isNoContentExpected || r.headers.get('Content-Length') === '0') {
        return Promise.resolve();
      }
      return r.json();
    }

    if (r.status === 401) {
      const refreshToken = getAuthRefreshTokenValue();
      if (!refreshToken) {
        this.handleLogout();
        return Promise.reject();
      }
      return this.handleRefreshAccessToken(refreshToken, url, options, isNoContentExpected);
    }

    return r.text().then((text) => {
      const error = { status: r.status };
      try {
        error.json = JSON.parse(text);
      } catch (ex) {
        error.text = text;
      }
      return Promise.reject(error);
    });
  };

  handleFileResponse = (response, url, options) => {
    if (response.status === 200) {
      return Promise.resolve(response.blob());
    }
    if (response.status === 401) {
      const refreshToken = getAuthRefreshTokenValue();
      if (!refreshToken) {
        this.handleLogout();
        return Promise.reject();
      }

      return this.handleRefreshAccessToken(refreshToken, url, options);
    }
    return Promise.reject();
  };

  fetch = (url, options, isNoContentExpected) => (
    this.fetchPromise(url, options).then(r => this.handleResponse(r, url, options, isNoContentExpected)));

  // we separately do fetching and handling its reponse here
  // in order to get refresh token response and check
  // if its ok => then resave new refresh token and do initial fetch again with new token
  // or it its not ok => then logout
  // we need to recheck for 401 in order to prevent cycling call to refresh token again and again
  handleRefreshAccessToken = (refreshToken, url, options, isNoContentExpected) => {
    let doNotLogout = false;

    return this.fetchPromise(
      'users/refresh-access-token',
      { method: 'POST', body: JSON.stringify({ refresh_token: refreshToken }) },
    )
      .then((r) => {
        // r is actually response info, not response body
        // so check status and work as with promise then using r.json()
        if (r.status !== 200) {
          // failed to get refresh token
          this.handleLogout();
          return Promise.reject();
        }

        return r.json().then((response) => {
          setAuthTokenValue(response.access_token, response.refresh_token);

          // redo initial fetch
          return this.fetchPromise(url, options)
            .then((secondFetchResponse) => {
              if (secondFetchResponse.status === 401) {
                this.handleLogout();
                return Promise.reject();
              }

              return this.handleResponse(secondFetchResponse, url, options, isNoContentExpected)
                .catch((e) => {
                  doNotLogout = true;
                  return Promise.reject(e);
                });
            });
        });
      })
      .catch((e) => {
        if (!doNotLogout) {
          // failed to get refresh token
          this.handleLogout();
        }
        return Promise.reject(e);
      });
  };

  handleLogout = () => {
    inactivityOnLogout();
    removeAuthToken();
    datadogOnLogout();
    removeEmailAddress();
    removeSelectedFilters();
    window.location.reload();
  };

  get = url => this.fetch(url, { method: 'GET' });

  post = (url, body) => this.fetch(url, { method: 'POST', body: JSON.stringify(body) });

  put = (url, body) => this.fetch(url, { method: 'PUT', body: JSON.stringify(body) });

  patch = (url, body) => this.fetch(url, { method: 'PATCH', body: JSON.stringify(body) });

  delete = url => this.fetch(url, { method: 'DELETE' }, true);

  getApiKeys = () => this.get('api-keys');

  getShipmentSummaries = query => this.get(`state-summaries${query ? `?${query}` : ''}`);

  getCalculatedEventSummaries = query => this.get(`calculated-event-summaries${query ? `?${query}` : ''}`)
    .then(x => casing.camelize(x));

  getShipmentById = id => this.get(`shipments/${id}`);

  getTrackingEventGroupsById = id => this.get(`shipments/${id}/tracking-event-groups`);

  getShipments = (query, shipmentIds) => this.post(
    `shipments${query ? `?${query}` : ''}`,
    shipmentIds || [],
  );

  getShipmentsMarkers = query => this.get(`shipments-markers${query ? `?${query}` : ''}`)
    .then(x => casing.camelize(x));

  getCalculatedEventMarkers = query => this.get(`calculated-event-markers${query ? `?${query}` : ''}`)
    .then(x => casing.camelize(x));

  downloadInvalidShipmentFile = (blobName) => {
    const url = `invalid-register-shipment-file?blob_name=${blobName}`;
    const options = {
      method: 'GET',
      responseType: 'blob',
    };

    return this.fetchPromise(url, options)
      .then(response => this.handleFileResponse(response, url, options))
      .catch(e => Promise.reject(e));
  };

  requestShipmentsExport = (query, content) => {
    const url = `shipments-export${query ? `?${query}` : ''}`;
    const options = {
      method: 'POST',
      body: JSON.stringify(content || []),
      responseType: 'json',
    };

    return this.fetchPromise(url, options)
      .then(response => this.handleResponse(response, url, options, false))
      .catch(err => Promise.reject(err.json));
  };

  requestProactiveShipmentsExport = (query, content) => {
    const url = `shipments-export/proactive${query ? `?${query}` : ''}`;
    const options = {
      method: 'POST',
      body: JSON.stringify(content || []),
      responseType: 'json',
    };

    return this.fetchPromise(url, options)
      .then(response => this.handleResponse(response, url, options, false))
      .catch(err => Promise.reject(err.json));
  };

  getShipmentExportStatus = reference => this.get(`shipments-export/status/${reference}`);

  getShipmentExportCsv = reference => {
    const url = `shipments-export/getdata/${reference}`;
    const options = {
      method: 'GET',
      responseType: 'blob',
    };

    return this.fetchPromise(url, options)
      .then(response => this.handleFileResponse(response, url, options))
      .catch(e => Promise.reject(e));
  };

  createApiKey = key => this.post('api-keys', { name: key.name });

  updateApiKey = key => this.put(`api-keys/${key.id}`, { name: key.name });

  deleteKey = id => this.delete(`api-keys/${id}`);

  getWebhooks = () => this.get('webhooks');

  createWebhook = newWebhook => this.post('webhooks', newWebhook);

  updateWebhook = (id, newWebhook) => this.put(`webhooks/${id}`, newWebhook);

  deleteWebhook = id => this.delete(`webhooks/${id}`);

  getShipmentFilters = () => this.get('shipment-filters').catch(err => (
    err.status === 404
      ? Promise.resolve(err.json)
      : Promise.reject(err.json)
  ));

  getSmartFilters = (startDate, endDate) => this.get(`smart-filter-processor/stats?dateFrom=${startDate}&dateTo=${endDate}`).catch(err => (
    err.status === 404
      ? Promise.resolve(err.json)
      : Promise.reject(err.json)
  ));

  getShipmentFilter = id => this.get(`shipment-filters/${id}`).catch(err => (
    err.status === 404
      ? Promise.resolve(err.json)
      : Promise.reject(err.json)
  ));

  getAvailableShipmentStates = carrierIntegrations => this.get(`shipment-filter/shipment-states${carrierIntegrations
    ? carrierIntegrations.reduce((result, current) => `${result}carrierIntegrationIds=${current}&`, '?')
    : ''}`)
    .catch(err => (
      err.status === 404
        ? Promise.resolve(err.json)
        : Promise.reject(err.json)
    ));

  getAvailableCarriers = () => this.get('carriers').catch(err => (
    err.status === 404
      ? Promise.resolve({ carriers: [] })
      : Promise.reject(err.json)
  ));

  getInvalidRegisterShipmentFiles = () => this.get('invalid-register-shipment-files').catch(err => (
    err.status === 404
      ? Promise.resolve({ files: [] })
      : Promise.reject(err.json)
  ));

  getCarrierIntegrations = () => this.get('carrier-integrations').then(x => casing.camelize(x));

  updateShipmentFilter = (id, shipmentFilter) => this.put(`shipment-filters/${id}`, shipmentFilter)
    .catch(err => (
      err.status === 404
        ? Promise.resolve(err.json)
        : Promise.reject(err.json)
    ));

  deleteShipmentFilter = id => this.delete(`shipment-filters/${id}`).catch(err => (
    err.status === 404
      ? Promise.resolve(err.json)
      : Promise.reject(err)
  ));

  createShipmentFilter = shipmentFilter => this.post('shipment-filters', shipmentFilter).catch(err => (
    err.status === 404
      ? Promise.resolve(err.json)
      : Promise.reject(err.json)
  ));

  createCustomer = (companyName, firstName, lastName, email, password) => this.post(
    'customers',
    {
      customer_name: companyName,
      first_Name: firstName,
      last_Name: lastName,
      email,
      password,
    },
  );

  activateNonPrimaryUser = (nonPrimaryUserEmailConfirmationToken, firstName, lastName, password) => (
    this.post(
      'users/activate',
      {
        email_confirmation_token: nonPrimaryUserEmailConfirmationToken,
        first_Name: firstName,
        last_Name: lastName,
        password,
      },
    ));

  resendEmailConfirmation = email => this.put(`users/resend-email-confirmation-token?email=${email}`);

  getTrackingServices = () => this.get('services');

  getTrackingService = id => this.get(`services/${id}`);

  getConfiguration = id => this.get(`configurations/${id}`);

  addConfiguration = configuration => this.post('configurations', configuration);

  updateConfiguration = (id, configuration) => this.put(`configurations/${id}`, configuration);

  deleteConfiguration = id => this.delete(`configurations/${id}`);

  enableConfiguration = id => this.patch(`configurations/${id}/enable`);

  disableConfiguration = id => this.patch(`configurations/${id}/disable`);

  loginUsernamePassword = (username, password) => this.post(
    'users/login',
    {
      user_name: username,
      password,
    },
  );

  resetPasswordByEmail = email => this.post('users/reset-password', { email });

  resetPasswordToNewPassword = (token, password) => this.put(`users/reset-password/${token}`, { password });

  confirmEmailByToken = token => this.post(`users/confirm-email?email_confirmation_token=${token}`);

  getUsers = () => this.get('users');

  getCustomStateLabels = () => this.get('custom-state-labels');

  updateCustomStateLabels = customStateLabel => this.put(
    'custom-state-labels',
    { custom_state_label: customStateLabel },
  );

  inviteUser = (firstName, lastName, email, roleId) => (
    this.post('users', {
      first_name: firstName,
      last_Name: lastName,
      email,
      role_id: roleId,
    })
  );

  deleteUser = id => this.delete(`users/${id}`);

  getUser = id => this.get(`users/${id}`).then(x => casing.camelize(x));

  editUser = (id, user) => this.put(`users/${id}`, casing.snakeize(user));

  deleteInvitation = id => this.delete(`customers/invitations/${id}`);

  getUserRoles = () => this.get('roles').then(x => casing.snakeize(x));

  createSftpAccount = ({ name, password }) => this.put('sftp/configuration', {
    user_name: name,
    passphrase: password,
  });

  getSftpAccounts = () => this.get('sftp').then(v => casing.camelize(v));

  getTrackingPages = () => this.get('tracking-pages');

  getTrackingPage = (name, version) => this.get(`tracking-pages/${name}?version=${version || ''}`);

  createNewTrackingPage = (trackingPageName, version, publishedOn, trackingPageData, invalidTrackingRefPageOverride) => this
    .post(
      `tracking-pages/${trackingPageName}?version=${version || ''}&publishedOn=${publishedOn || ''}&invalidTrackingRefPageOverride=${invalidTrackingRefPageOverride || ''}`,
      trackingPageData,
    );

  updateTrackingPage = (trackingPageName, version, publishedOn, trackingPageData, invalidTrackingRefPageOverride) => this
    .put(
      `tracking-pages/${trackingPageName}?version=${version || ''}&publishedOn=${publishedOn || ''}&invalidTrackingRefPageOverride=${invalidTrackingRefPageOverride || ''}`,
      trackingPageData,
    );

  deleteTrackingPage = trackingPageName => this.delete(`tracking-pages/${trackingPageName}`);

  getNotificationSinks = () => this.get('notificationConfigurations/notificationSinks')
    .then(v => casing.camelize(v));

  getNotificationSink = id => this.get(`notificationConfigurations/notificationSinks/${id}`)
    .then(v => casing.camelize(v));

  getSinkConfigurations = (filters) => {
    let url = 'notificationConfigurations/sinkConfigurations';

    const { areTemplatesEditable } = filters || {};
    if (areTemplatesEditable) {
      url += `?areTemplatesEditable=${areTemplatesEditable}`;
    }

    return this.get(url)
      .then(v => casing.camelize(v));
  };

  getSinkConfiguration = id => this.get(`notificationConfigurations/sinkConfigurations/${id}`)
    .then(v => casing.camelize(v));

  getServers = (filters) => {
    const url = 'notificationConfigurations/emailservers';
    const { areTemplatesEditable, areStatsAvailable } = filters || {};
    const params = [
      areTemplatesEditable && `areTemplatesEditable=${areTemplatesEditable}`,
      areStatsAvailable && `areStatsAvailable=${areStatsAvailable}`,
    ]
      .filter(Boolean)
      .join('&');

    return this.get(`${url}?${params}`)
      .then(v => casing.camelize(v));
  };

  getEmailStatistics = (id, startDate, endDate) => {
    const start = format(startDate, 'YYYY-MM-DD');
    const end = format(endDate, 'YYYY-MM-DD');
    const emailStatsURL = `notificationConfigurations/emailServers/${id}/emailStatistics`;

    return this.get(`${emailStatsURL}?fromDate=${start}&toDate=${end}`)
      .then(v => casing.camelize(v));
  };

  getEmailBounceStatistics = (id, startDate, endDate) => {
    const start = format(startDate, 'YYYY-MM-DD');
    const end = format(endDate, 'YYYY-MM-DD');
    const bounceReasonsURL = `notificationConfigurations/emailServers/${id}/emailStatisticsBounceReasons`;

    return this.get(`${bounceReasonsURL}?fromDate=${start}&toDate=${end}`)
      .then(v => casing.camelize(v));
  };

  getEmailActivityDetail = (id, emailActivityId) => {
    const url = `notificationConfigurations/emailServers/${id}/activities/${emailActivityId}/detail`;
    return this.get(url).then(v => casing.camelize(v));
  };

  getEmailSuppressions = (id, reason, email) => {
    const params = new URLSearchParams();
    if (reason) {
      params.append('reason', reason);
    }
    if (email) {
      params.append('emailAddress', email);
    }

    const suppressionURL = `notificationConfigurations/emailServers/${id}/suppression?${params.toString()}`;

    return this.get(`${suppressionURL}`)
      .then(v => casing.camelize(v));
  };

  getEmailActivity = (id, count, offset, recipient) => {
    const params = new URLSearchParams();
    if (count != null) {
      params.append('count', count);
    }
    if (offset != null) {
      params.append('offset', offset);
    }

    if (recipient != null && recipient !== '') {
      params.append('recipient', recipient);
    }

    const activityURL = `notificationConfigurations/emailservers/${id}/activities/?${params.toString()}`;

    return this.get(`${activityURL}`)
      .then(v => casing.camelize(v));
  };

  getEmailActivityByRecipient = (id, recipient, count, offset) => {
    const params = new URLSearchParams();
    if (count != null) {
      params.append('count', count);
    }
    if (offset != null) {
      params.append('offset', offset);
    }

    const activityURL = `notificationConfigurations/emailServers/${id}/activities/${recipient}/events?${params.toString()}`;

    return this.get(`${activityURL}`)
      .then(v => casing.camelize(v));
  }

  exportSuppression = (sinkConfigurationId) => {
    const url = `notificationConfigurations/emailservers/${sinkConfigurationId}/suppression/export`;
    const options = {
      method: 'GET',
      responseType: 'blob',
    };

    return this.fetchPromise(url, options)
      .then(response => this.handleFileResponse(response, url, options))
      .catch(e => Promise.reject(e));
  };

  addSuppression = (sinkConfigurationId, emailAddressList) => {
    const url = `notificationConfigurations/emailServers/${sinkConfigurationId}/suppression`;
    return this.post(url, emailAddressList).then(v => casing.camelize(v));
  };

  deleteSuppression = (sinkConfigurationId, emailAddressList) => {
    const url = `notificationConfigurations/emailservers/${sinkConfigurationId}/suppression/delete`;
    return this.post(url, emailAddressList).then(v => casing.camelize(v));
  };

  bounceDetails = (sinkConfigurationId, bounceId) => {
    const url = `notificationConfigurations/emailservers/${sinkConfigurationId}/bounce/${bounceId}`;
    return this.get(url).then(v => casing.camelize(v));
  };

  createSinkConfiguration = sinkConfiguration => (
    this.post(
      'notificationConfigurations/sinkConfigurations',
      casing.snakeize({
        sinkConfiguration,
      }),
    ));

  editSinkConfiguration = sinkConfiguration => (
    this.put(
      `notificationConfigurations/sinkConfigurations/${sinkConfiguration.id}`,
      casing.snakeize({
        sinkConfiguration,
      }),
    ));

  enableSinkConfiguration = id => this.patch(`notificationConfigurations/sinkConfigurations/${id}/enable`);

  disableSinkConfiguration = id => this.patch(`notificationConfigurations/sinkConfigurations/${id}/disable`);

  disconnectSinkConfiguration = id => this.delete(`notificationConfigurations/sinkConfigurations/${id}`);

  enableNotification = id => this.patch(`notificationConfigurations/notifications/${id}/enable`);

  disableNotification = id => this.patch(`notificationConfigurations/notifications/${id}/disable`);

  disconnectNotification = id => this.delete(`notificationConfigurations/notifications/${id}`);

  createTemplate = (sinkConfigurationId, template) => this.post(`sink-configurations/${sinkConfigurationId}/templates`, casing.snakeize(template));

  getTemplate = (sinkConfigurationId, templateReference) => this.get(`notificationConfigurations/${sinkConfigurationId}/templates/${templateReference}`)
    .then(v => casing.camelize(v));

  renderTemplate = (sinkConfigurationId, templateReference) => this.get(`notificationConfigurations/${sinkConfigurationId}/templates/${templateReference}/render`)
    .then(v => casing.camelize(v));

  sendTemplatePreview = (sinkConfigurationId, templateReference, email) => this.post(`notificationConfigurations/${sinkConfigurationId}/templates/${templateReference}/sendpreview/${email}`)
    .then(v => casing.camelize(v));

  updateTemplate = (sinkConfigurationId, templateReference, template) => this.put(`notificationConfigurations/${sinkConfigurationId}/templates/${templateReference}`, casing.snakeize(template));

  getSinkTemplates = id => this.get(`notificationConfigurations/templates/${id}`)
    .then(v => casing.camelize(v));

  createNotification =
    notification => this.post('notificationConfigurations/notifications', casing.snakeize({ notification }));

  editNotification = (id, notification) => this.put(
    `notificationConfigurations/notifications/${id}`,
    casing.snakeize({ notification }),
  );

  getNotification = id => this.get(`notificationConfigurations/notifications/${id}`)
    .then(v => casing.camelize(v));

  getNotifications = () => this.get('notificationConfigurations/notifications')
    .then(v => casing.camelize(v));

  addBulkEmailNotifications =
    ({
      sinkConfigurationId, templateReference, dashboardName, tileText,
    }) => this.post(
      `bulkNotifications/create/${dashboardName}/${tileText}`,
      casing.snakeize({ sink_configuration_id: sinkConfigurationId, template_reference: templateReference }),
    );

  verifyDomain = sinkConfigurationId => this.put(
    'notificationConfigurations/reactConfigurations/verifyDomain',
    casing.snakeize({ sinkConfigurationId }),
  )
    .then(v => casing.camelize(v));

  getTemplates = () => this.get('notificationConfigurations/templates').then(v => casing.camelize(v));

  deleteTemplate = (sinkConfigurationId, templateReference) => this.delete(
    `notificationConfigurations/${sinkConfigurationId}/templates/${templateReference}`,
  ).then(v => casing.camelize(v));

  getShipmentNotes = shipmentId => this.get(`shipmentNotes/${shipmentId}`)
    .then(v => casing.camelize(v));

  createShipmentNote = shipmentNote => this.post('shipmentNotes', casing.snakeize(shipmentNote));

  getWebhookLogs = (id, page, pageSize, filters) => {
    const params = new URLSearchParams({ page, pageSize });
    if (filters.status) {
      params.append('status', filters.status);
    }
    if (filters.from) {
      params.append('dateFrom', filters.from);
    }
    if (filters.to) {
      params.append('dateTo', filters.to);
    }
    return this.get(`webhooks/${id}/logs?${params}`).then(v => casing.camelize(v));
  }

  getWebhookLog = (webhookId, logId) => this.get(`webhooks/${webhookId}/logs/${logId}`).then(v => casing.camelize(v));

  getShipmentMetadataGroupings = () => this.get('shipment-metadata-groupings')
    .then(v => casing.camelize(v));

  updateShipmentMetadataGroupings = groupings => this.put(
    'shipment-metadata-groupings',
    casing.snakeize({
      groupings,
    }),
  );

  getAuthorisedEmails = () => this.get('authorisedEmails').then(v => casing.camelize(v));

  createAuthorisedEmails = authorisedEmail => this.post('authorisedEmails', casing.snakeize(authorisedEmail))
    .then(v => casing.camelize(v));

  updateAuthorisedEmails = authorisedEmail => this.put('authorisedEmails', casing.snakeize(authorisedEmail))
    .then(v => casing.camelize(v));

  deleteAuthorisedEmail = authorisedEmailId => this.delete(`authorisedEmails/${authorisedEmailId}`);

  getConfiguredDashboards = () => this.get('dashboardConfigurations').then(v => casing.camelize(v));

  getConfiguredDashboard = dashboardName => this.get(`dashboardConfigurations/${dashboardName}`).then(v => casing.camelize(v));

  addConfiguredDashboard = configuredDashboard => this.post('dashboardConfigurations', casing.snakeize(configuredDashboard))
    .then(v => casing.camelize(v));

  updateConfiguredDashboard = configuredDashboard => this.put('dashboardConfigurations', casing.snakeize(configuredDashboard))
    .then(v => casing.camelize(v));

  deleteConfiguredDashboard = dashboardName => this.delete(`dashboardConfigurations/${dashboardName}`);

  getConfiguredDashboardAlertData = (dashboardName, queryFilter) => this.get(`dashboardConfigurations/${dashboardName}/alertdata${queryFilter}`).then(v => casing.camelize(v));

  getConfiguredDashboardAlertShipments = ({
    dashboardName, alertName, currentPage, pageSize = '200',
  }) => this
    .get(`dashboardConfigurations/${dashboardName}/${alertName}/shipments?page=${currentPage}&limit=${pageSize}`)
    .then(v => casing.camelize(v));

  getAlertDataWithTags = (tags) => this.get(`dashboardConfigurations/alertdata${tags}`).then(v => casing.camelize(v));

  getDashboardSummaryData = (tags) => this.get(`dashboardConfigurations/summarydata${tags}`).then(v => casing.camelize(v));
}

export default new API();
