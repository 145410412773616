import React from 'react';
import styles from './ShipmentList.scss';
import { headerStyle } from './styles';

export const getColumnProps = (state, header) => {
  if (header) {
    return {
      style: {
        cursor: 'context-menu',
        background: 'white',
      },
    };
  }
  return {};
};
export const getTrProps = (state, rowInfo) => {
  if (rowInfo) {
    return {
      style: {
        cursor: 'context-menu',
        background: 'white',
      },
    };
  }
  return {};
};
export const CellComponent = ({ value }) => (
  <div className={styles.cellWrapper}>
    <span> {value} </span>
  </div>
);
export const getDecoratedText = (text) => {
  const splitTextWithSpace = text.replace(/(?!^)([A-Z]+)/g, ' $1');
  return splitTextWithSpace[0].toUpperCase() + splitTextWithSpace.slice(1).toLowerCase();
};
export const IndependentToStateColumns = ({ smallerDesktop, biggerDesktop }) => ([
  {
    Header: 'Delivery status',
    headerStyle,
    accessor: 'state',
    Cell: ({ value }) => (
      <div className={styles.cellWrapper}>
        <span className={styles.deliveryStatusCellValue}>
          {value && getDecoratedText(value)}
        </span>
      </div>
    ),
  },
  {
    Header: 'Carrier',
    headerStyle,
    accessor: 'carrier',
    Cell: ({ value }) => <CellComponent value={value} />,
    maxWidth: smallerDesktop && !biggerDesktop ? 180 : 'auto',
  }, {
    Header: 'Promised date',
    headerStyle,
    accessor: 'promisedDate',
    Cell: ({ value }) => <CellComponent value={value} />,
    maxWidth: smallerDesktop && !biggerDesktop ? 150 : 'auto',
  },
  {
    Header: 'Estimated delivery date',
    headerStyle,
    accessor: 'estimatedDeliveryDate',
    Cell: ({ value }) => <CellComponent value={value} />,
    maxWidth: smallerDesktop && !biggerDesktop ? 150 : 'auto',
  },
  {
    Header: 'Tracking number',
    headerStyle,
    accessor: 'reference',
    Cell: ({ value }) => (<CellComponent value={value} />),
    maxWidth: smallerDesktop && !biggerDesktop ? 205 : 'auto',
  },
]);
