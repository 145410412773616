import React from 'react';
import styles from './TabErrorMessage.scss';
import CircleExclamationRegular from '../../../../../images/CircleExclamationRegular.svg';

export default function TabErrorMessage({ title, message }) {
  const defaultTitle = "Oops! It's not you, it's us";
  const defaultMsg = "Unfortunately we're unable to retrieve information, we're working hard to fix this issue so please try again later. Thank you for your patience.";

  return (
    <div className={styles.errorContainer}>
      <div className={styles.warningIcon}>
        <CircleExclamationRegular width={20} height={20} />
      </div>
      <div className={styles.errorInner}>
        <div className={styles.errorInnerTitle}>{title || defaultTitle}</div>
        <div className={styles.errorInnerMessage}>{message || defaultMsg}</div>
      </div>
    </div>
  );
}
