import React, { useEffect, useState } from 'react';
import API from 'helpers/API';
import CreateCard from 'components/CreateCard';
import CardShell from 'components/CardShell';
import TemplateCard from 'routes/Settings/Notifications/NotificationTemplates/TemplateCard';
import Spinner from 'components/Spinner';
import { getErrorMessagesFromApiError } from 'helpers/apiError';
import { closePopup as closePopupAction, showPopup as showPopupAction } from 'actions';
import { connect } from 'react-redux';
import TitleNavBlock from '../../../../components/TitleNavBlock/TitleNavBlock';
import typography from '../../../../helpers/appTypography';
import styles from './styles.scss';

function TemplatesList({ history, showPopup, closePopup }) {
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const templateResponse = await API.getTemplates();
        setTemplates(templateResponse.templates);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const deleteTemplate = async (sinkConfigurationId, reference) => {
    try {
      await API.deleteTemplate(sinkConfigurationId, reference);
      setTemplates(templates.filter(x => x.reference !== reference));
    } catch (e) {
      const messages = getErrorMessagesFromApiError(e.json);
      showPopup({
        title: 'Error',
        description: messages[0],
        onDecline: () => {
          closePopup();
        },
      });
    }
  };

  const showDeleteTemplatePopup = async (sinkConfigurationId, reference) => {
    showPopup({
      title: 'Are you sure?',
      description: 'The template cannot be recovered once deleted.',
      onAccept: () => {
        deleteTemplate(sinkConfigurationId, reference);
        closePopup();
      },
      onDecline: () => closePopup(),
    });
  };

  if (isLoading) {
    return (
      <Spinner />
    );
  }

  return (
    <>
      <TitleNavBlock
        title={typography.notificationTemplates.title}
        subTitle={typography.notificationTemplates.subTitle}
        onBack={() => window.history.back()}
      />
      <div className={styles.outerWhiteContainer}>
        <div className="container__cards-large mt-5">
          <CreateCard text="Create new Template" handleClick={() => history.push('/settings/notification-centre/templates/create')} />
          {templates.map(x => (
            <CardShell key={x.reference + x.sinkConfigurationId} noOverflow>
              <TemplateCard
                template={x}
                history={history}
                deleteTemplate={showDeleteTemplatePopup}
              />
            </CardShell>
          ))}
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = dispatch => ({
  showPopup: config => dispatch(showPopupAction(config)),
  closePopup: () => dispatch(closePopupAction()),
});

export default connect(null, mapDispatchToProps)(TemplatesList);
