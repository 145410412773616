/* eslint-disable no-unused-vars */
import React from 'react';
import propTypes from 'prop-types';
import { parse as parseQuery } from 'helpers/queryString';
import Login from 'components/Login';
import Register from 'components/Register';
import { Redirect, withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
  login,
  registerLoginNewTabSelected,
  confirmEmailByToken,
  showForgotPasswordNewPassword,
  forgotPasswordClick,
} from '../../actions';
import PrimaryUserTokenExpired from './PrimaryUserTokenExpired';
import ForgotPasswordEmail from './ForgotPasswordEmail';
import ForgotPasswordNewPassword from './ForgotPasswordNewPassword';
import NonPrimaryUserTokenExpired from './NonPrimaryUserTokenExpired';
import styles from './styles.scss';
import SortedLogoHorizontal from '../../images/SortedLogoHorizontal-Colour.svg';

const ConfirmEmailUrlParameterName = 'confirmemail';
const ResetPasswordUrlParameterName = 'resetpassword';
const LoginFormTabIndex = 0;
const RegisterFormTabIndex = 2;
const PrimaryUserTokenExpiredTabIndex = 3;
const ForgotPasswordEmailTabIndex = 4;
const ForgotPasswordNewPasswordTabIndex = 5;

export class CLoginRegisterPage extends React.Component {
  static propTypes = {
    tryToLogin: propTypes.func.isRequired,
    isAuthenticated: propTypes.bool,
    location: propTypes.shape({
      pathname: propTypes.string.isRequired,
      state: propTypes.shape({
        from: propTypes.string,
      }),
    }).isRequired,
    onShowForgotPasswordNewPassword: propTypes.func.isRequired,
    resendConfirmationToEmail: propTypes.string,
    viewType: propTypes.string,
  };

  static defaultProps = {
    isAuthenticated: false,
    viewType: 'showLogin',
    resendConfirmationToEmail: '',
  };

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
    if (this.isQueryContains(ConfirmEmailUrlParameterName)) {
      const { token } = parseQuery(this.props.location.search);
      this.props.onConfirmEmailByToken(token);
      return;
    }
    if (this.isQueryContains(ResetPasswordUrlParameterName)) {
      const { token } = parseQuery(this.props.location.search);
      this.props.onShowForgotPasswordNewPassword(token);
      return;
    }

    this.props.tryToLogin();
  }

  getRedirectRouteAfterLogin = (location) => {
    const currentRoute = location.pathname;
    const lastRoute = location.state ? location.state.from : '';
    return currentRoute === lastRoute ? '/' : lastRoute;
  };

  getTabData() {
    const { viewType, tabIndex } = this.props;
    if (viewType === 'selectTab') {
      return { index: tabIndex };
    }
    if (viewType === 'showForgotPasswordEmail') {
      return { index: ForgotPasswordEmailTabIndex };
    }
    if (viewType === 'showForgotPasswordNewPassword') {
      return { index: ForgotPasswordNewPasswordTabIndex, token: this.props.forgotPasswordToken };
    }
    if (viewType === 'showPrimaryUserTokenExpired') {
      return { index: PrimaryUserTokenExpiredTabIndex, email: this.props.resendConfirmationToEmail };
    }
    if (viewType === 'showLogin') {
      return { index: LoginFormTabIndex };
    }
    if (viewType === 'showRegister') {
      return { index: RegisterFormTabIndex };
    }
    return { index: -1 };
  }

  getNonTabData() {
    const { viewType } = this.props;
    if (viewType === 'showCustomerRegisterActivationInstructions') {
      return { activationInstructions: true, email: this.props.resendConfirmationToEmail };
    }
    if (viewType === 'showUserNotActiveActivationInstructions') {
      return {
        activationInstructions: true,
        email: this.props.resendConfirmationToEmail,
        title: 'Your account is not activated yet',
      };
    }
    if (viewType === 'showNonPrimaryUserTokenExpired') {
      return { nonPrimaryUserTokenExpired: true, email: this.props.resendConfirmationToEmail };
    }
    return {};
  }

  isQueryContains = parameterName => this.props.location.search.toLowerCase().indexOf(parameterName) !== -1;

  render() {
    const {
      isAuthenticated,
      location,
    } = this.props;

    if (isAuthenticated) return <Redirect to={this.getRedirectRouteAfterLogin(location)} />;

    let nonTabData;
    const tabData = this.getTabData();
    if (tabData.index === -1) {
      nonTabData = this.getNonTabData();
    }

    return (
      <div className={styles.loginPage}>
        <div className={styles.container}>
          <div className={styles.logoWrapper}>
            <SortedLogoHorizontal />
          </div>
          <div className={styles.containerBody}>
            {(nonTabData && nonTabData.nonPrimaryUserTokenExpired)
              && <NonPrimaryUserTokenExpired {...nonTabData} />}
            {(!nonTabData && this.props.viewType === 'showLogin' && <Login />) }
            {!nonTabData && this.props.viewType === 'showRegister' && <Register />}
            {(!nonTabData && this.props.viewType === 'showForgotPasswordEmail' && <ForgotPasswordEmail />)}
            {!nonTabData && this.props.viewType === 'showForgotPasswordNewPassword' && <ForgotPasswordNewPassword {...tabData} />}
            {!nonTabData && this.props.viewType === 'showPrimaryUserTokenExpired' && <PrimaryUserTokenExpired {...tabData} />}
          </div>
        </div>
        <div className={`subtitle2 ${styles.copyright}`}>
          Copyright © {new Date().getFullYear()} Sorted Group Ltd. All rights reserved.
          <br />
          <div style={{ marginTop: '10px' }}>
            <button
              onClick={() => window.open('https://sorted.com/track-privacy-policy/', '_blank')}
              type="button"
              className={`subtitle2 ${styles.copyrightButton}`}
            >
              Privacy Policy
            </button>
          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ store, copyrightPopUp }) => ({
  isAuthenticated: store.login.isAuthenticated,
  resendConfirmationToEmail: store.login.resendConfirmationToEmail,
  forgotPasswordToken: store.login.forgotPasswordToken,
  viewType: store.viewType,
  tabIndex: store.tabIndex,
});

const mapDispatchToProps = dispatch => ({
  tryToLogin: () => dispatch(login()),
  onTabSelected: tabIndex => dispatch(registerLoginNewTabSelected(tabIndex)),
  onShowForgotPasswordNewPassword: token => dispatch(showForgotPasswordNewPassword(token)),
  onConfirmEmailByToken: token => dispatch(confirmEmailByToken(token)),
  forgotPasswordClick: () => dispatch(forgotPasswordClick()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CLoginRegisterPage));
