import React, { Component } from 'react';
import cx from 'classnames';
import styles from './styles.scss';

class Locale extends Component {
  renderItem = (element) => {
    const { onLocaleClick, selectedLocale } = this.props;
    const isSelected = selectedLocale && element.code.toLowerCase() === selectedLocale.toLowerCase();

    return (
      <li key={element.code} className={cx(styles.shippingItems, `${isSelected ? styles.selected : ''}`)}>
        <button
          type="button"
          title={element.name}
          onClick={() => onLocaleClick(element)}
        >
          {element.name}
        </button>
      </li>
    );
  };

  render() {
    return (
      <div className={styles.innerCardBody}>
        <div className={styles.descriptionArea}>
          <p>If required, select the language you want to receive shipment state labels in.</p>
          <ul className={styles.shippingList}>
            {this.props.locales.map(this.renderItem)}
          </ul>
        </div>
      </div>
    );
  }
}

export default Locale;
