import { combineReducers } from 'redux';
import availableCalculatedEventsReducer from 'reducers/availableCalculatedEventsReducer';
import customPopupReducer from 'reducers/customPopupReducer';
import shipmentFiltersReducer from './shipmentFiltersReducer';
import smartFiltersReducer from './smartFiltersReducer';
import availableStatesReducer from './availableStatesReducer';
import popupReducer from './popupReducer';
import carriersReducer from './carriersReducer';
import popClipboardReducer from './popClipboardReducer';
import createApiKeyReducer from './createApiKeyReducer';
import webHooksStatesReducer from './webHooksStatesReducer';
import shipmentsReducer from './shipmentsReducer';
import apiKeysReducer from './apiKeysReducer';
import shipmentStatesReducer from './shipmentStatesReducer';
import registerReducer from './registerReducer';
import createConfigurationReducer from './createConfigurationReducer';
import editConfigurationReducer from './editConfigurationReducer';
import trackingServicesReducer from './trackingServicesReducer';
import customStateLabelsReducer from './customStateLabelsReducer';
import editCustomStateLabelReducer from './editCustomStateLabelReducer';
import copyrightPopUpReducer from './copyrightPopUpReducer';
import usersReducer from './usersReducer';
import localesReducer from './localesReducer';
import mapboxReducer from './mapboxReducer';
import webhookStepsReducer from './webhookStepsReducer';
import shipmentDetailsReducer from './shipmentDetailsReducer';
import sftpAccountReducer from './sftpAccountReducer';
import layoutReducer from './layoutReducer';
import trackingPagesReducer from './trackingPagesReducer';
import editTrackingPageReducer from './editTrackingPageReducer';
import toggleMapReducer from './toggleMapReducer';
import emailStatisticsReducer from './emailStatisticsReducer';
import sinkConfigurationsReducer from './sinkConfigurationsReducer';
import sidebarMenuReducer from './sidebarMenuReducer';

const initialState = {
  login: {
    isAuthenticated: false,
    onLogin: null,
    onEmailConfirmationTokenNotFound: null,
    errors: null,
  },
  viewType: 'showLogin',
};

export function defaultReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOGGING_IN':
      return {
        ...state,
        login: initialState.login,
      };
    case 'LOG_IN_SUCCESS':
      return {
        ...state,
        login: {
          isAuthenticated: true,
          errors: {},
        },
      };
    case 'LOG_IN_FAIL_INACTIVE_ACCOUNT':
      return {
        ...state,
        login: {
          isAuthenticated: false,
          resendConfirmationToEmail: action.resendConfirmationToEmail,
          errors: {},
        },
        viewType: 'showUserNotActiveActivationInstructions',
      };
    case 'FORGOT_PASSWORD_EMAIL_INACTIVE_ACCOUNT':
      return {
        ...state,
        login: {
          resendConfirmationToEmail: action.email,
          errors: {},
        },
        viewType: 'showUserNotActiveActivationInstructions',
      };
    case 'FORGOT_PASSWORD_EMAIL_FOR_INVITATION':
    case 'NON_PRIMARY_USER_EMAIL_CONFIRMATION_TOKEN_EXPIRED':
      return {
        ...state,
        login: {
          resendConfirmationToEmail: action.email,
          errors: {},
        },
        viewType: 'showNonPrimaryUserTokenExpired',
      };
    case 'LOG_IN_TOKEN_EXPIRED':
      return {
        ...state,
        login: {
          isAuthenticated: false,
          errors: {},
        },
      };
    case 'LOG_OUT':
      return {
        ...initialState,
      };
    case 'REGISTER_SUCCESS':
      return {
        ...state,
        login: {
          resendConfirmationToEmail: action.resendConfirmationToEmail,
          errors: {},
        },
        viewType: 'showCustomerRegisterActivationInstructions',
      };
    case 'FORGOT_PASSWORD_CLICK':
      return {
        ...state,
        login: initialState.login,
        viewType: 'showForgotPasswordEmail',
      };
    case 'FORGOT_PASSWORD_TOKEN_IN_URL':
      return {
        ...state,
        login: {
          forgotPasswordToken: action.token,
          errors: {},
        },
        viewType: 'showForgotPasswordNewPassword',
      };
    case 'FORGOT_PASSWORD_EMAIL_SENT':
      return {
        ...state,
        viewType: 'showForgotPasswordEmail',
      };

    case 'GO_BACK_TO_LOGIN':
      return {
        ...state,
        viewType: 'showLogin',
      };

    case 'RESET_FORGOT_PASSWORD_NEW_PASSWORD_SENT':
      return {
        ...state,
        login: {
          message: 'The password was reset. Please try to login.',
        },
        viewType: 'showLogin',
      };
    case 'EMAIL_CONFIRMATION_TOKEN_UNKNOWN_ERROR':
      return {
        ...state,
        login: {
          errors: { formError: 'Unknown error occured.' },
        },
        viewType: 'showLogin',
      };
    case 'EMAIL_CONFIRMATION_TOKEN_EXPIRED':
      return {
        ...state,
        login: {
          resendConfirmationToEmail: action.resendConfirmationToEmail,
          errors: {},
        },
        viewType: 'showPrimaryUserTokenExpired',
      };
    case 'EMAIL_CONFIRMATION_TOKEN_NOT_FOUND':
    case 'RESET_FORGOT_PASSWORD_TOKEN_EXPIRED':
    case 'RESET_FORGOT_PASSWORD_TOKEN_NOT_FOUND':
      return {
        ...state,
        login: {
          errors: { formError: 'The link is invalid.' },
        },
        viewType: 'showLogin',
      };
    case 'NON_PRIMARY_USER_ACTIVATION_TOKEN_RETURNED':
      return {
        ...state,
        login: initialState.login,
        viewType: 'showRegister',
      };
    case 'LOGIN_REGISTER_TAB_SELECTED':
      return {
        ...state,
        login: initialState.login,
        tabIndex: action.tabIndex,
        viewType: 'selectTab',
      };
    default:
      return state;
  }
}

export default combineReducers({
  store: defaultReducer,
  apiKeys: apiKeysReducer,
  copyrightPopUp: copyrightPopUpReducer,
  shipmentStates: shipmentStatesReducer,
  shipments: shipmentsReducer,
  shipmentDetails: shipmentDetailsReducer,
  popup: popupReducer,
  shipmentFilters: shipmentFiltersReducer,
  smartFilters: smartFiltersReducer,
  availableStates: availableStatesReducer,
  availableCalculatedEvents: availableCalculatedEventsReducer,
  carriers: carriersReducer,
  customPopup: customPopupReducer,
  popClipboard: popClipboardReducer,
  createApiKey: createApiKeyReducer,
  webhooksStates: webHooksStatesReducer,
  register: registerReducer,
  createConfiguration: createConfigurationReducer,
  trackingServices: trackingServicesReducer,
  editConfiguration: editConfigurationReducer,
  users: usersReducer,
  customStateLabels: customStateLabelsReducer,
  editCustomStateLabel: editCustomStateLabelReducer,
  locales: localesReducer,
  mapbox: mapboxReducer,
  webhookSteps: webhookStepsReducer,
  sftpAccount: sftpAccountReducer,
  layout: layoutReducer,
  trackingPages: trackingPagesReducer,
  editTrackingPage: editTrackingPageReducer,
  toggleMap: toggleMapReducer,
  emailStatistics: emailStatisticsReducer,
  sinkConfigurations: sinkConfigurationsReducer,
  sidebarMenu: sidebarMenuReducer,
});
