export const composeShipmentFilterData = (availableShipmentStates, currentShipmentFilter) => {
  const statesArray = availableShipmentStates;
  const cardStates = currentShipmentFilter.states;
  const filtersSummary = {
    red: { count: 0 },
    amber: { count: 0 },
    green: { count: 0 },
  };

  for (let i = 0; i < statesArray.length; i += 1) {
    for (let z = 0; z < cardStates.length; z += 1) {
      if (statesArray[i].state_key === cardStates[z].state) {
        switch (statesArray[i].category) {
          case 'red':
            cardStates[z].category = 'red';
            cardStates[z].name = statesArray[i].state_name;
            filtersSummary.red.count += 1;
            break;
          case 'amber':
            cardStates[z].category = 'amber';
            cardStates[z].name = statesArray[i].state_name;
            filtersSummary.amber.count += 1;
            break;
          default:
            cardStates[z].category = 'green';
            cardStates[z].name = statesArray[i].state_name;
            filtersSummary.green.count += 1;
        }
      }
    }
  }

  return {
    ...currentShipmentFilter,
    states: cardStates,
    filtersSummary,
  };
};

export default composeShipmentFilterData;
