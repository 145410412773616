import React from 'react';

/* eslint-disable */
export default props => (
  <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 67.8 66.4'>
    <path d='M66.4,59.8L48.8,42.2c3.1-4.4,4.9-9.7,4.9-15.4c0-14.7-12-26.7-26.7-26.7c-14.7,0-26.7,12-26.7,26.7 c0,14.7,12,26.7,26.7,26.7c6.3,0,12-2.2,16.6-5.8L61,65.2c0.7,0.7,1.7,1.1,2.7,1.1c1,0,1.9-0.4,2.7-1.1 C67.9,63.7,67.9,61.3,66.4,59.8z M27,48C15.4,48,5.8,38.5,5.8,26.8C5.8,15.1,15.4,5.6,27,5.6c11.7,0,21.2,9.5,21.2,21.2 C48.2,38.5,38.7,48,27,48z'
    />
  </svg>
)

/* eslint-enable */
