const initialState = {
  loading: true,
  keys: [],
  error: '',
  isActive: false,
};

export default function trackingServicesReducer(state = initialState, action) {
  switch (action.type) {
    case 'TRACKING_SERVICES_FETCH':
      return {
        ...state,
        loading: true,
        error: '',
      };
    case 'TRACKING_SERVICES_SUCCESS':
      return {
        keys: action.keys,
        loading: false,
        error: '',
      };
    case 'TRACKING_SERVICES_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
