import {
  TRACKING_PAGES_FETCH,
  TRACKING_PAGES_FETCH_SUCCESS,
  DELETE_TRACKING_PAGE_FETCH,
  DELETE_TRACKING_PAGE_SUCCESS,
} from 'actions/types';

const initialState = {
  isLoading: false,
  trackingPagesList: null,
  error: '',
  webhookActive: true,
};

function trackingPagesReducer(state = initialState, action) {
  switch (action.type) {
    case TRACKING_PAGES_FETCH:
    case DELETE_TRACKING_PAGE_FETCH:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case TRACKING_PAGES_FETCH_SUCCESS:
      return {
        trackingPagesList: action.trackingPages,
        isLoading: false,
        error: '',
      };
    case DELETE_TRACKING_PAGE_SUCCESS:
      return {
        trackingPagesList: state.trackingPagesList
          .filter(trackingPage => trackingPage.trackingPageName !== action.trackingPageName),
      };
    default:
      return state;
  }
}

export default trackingPagesReducer;
