export const TopLeft = 'top_left';
export const TopMiddle = 'top_middle';
export const TopRight = 'top_right';
export const MiddleLeft = 'middle_left';
export const Middle = 'middle';
export const MiddleRight = 'middle_right';
export const BottomLeft = 'bottom_left';
export const BottomMiddle = 'bottom_middle';
export const BottomRight = 'bottom_right';

const RowTextPositions = [
  TopLeft,
  TopMiddle,
  TopRight,
  MiddleLeft,
  Middle,
  MiddleRight,
  BottomLeft,
  BottomMiddle,
  BottomRight,
];

export default RowTextPositions;
