const reasons = [
  {
    key: 'All',
    value: 'All',
    tooltip: '',
  },
  {
    key: 'HardBounce',
    value: 'Hard bounce',
    tooltip: 'This recipient previously hard bounced. Reactivate to send to this recipient again.',
  },
  {
    key: 'SpamComplaint',
    value: 'Spam complaint',
    tooltip: 'Recipients who mark your messages as spam cannot be reactivated. Please contact support to reactivate this recipient.',
  },
  {
    key: 'ManualSuppression',
    value: 'Manually added',
    tooltip: 'This recipient can no longer receive emails on this Message Stream because they were manually added to the suppression list. Reactivate to send to them again.',
  },
];

export default reasons;
