export const shipmentFilterNameGroupTitle = 'Create New Shipment Filter';
export const shipmentFilterEditNameGroupTitle = 'Edit Shipment Filter';

export const shipmentFilterSelectIntegrationsTitle = 'Select carrier(s) (Optional)';
export const shipmentFilterEditIntegrationsTitle = 'Edit carrier(s) (Optional)';

export const shipmentFilterSelectStatesTitle = 'Select shipment state(s) or calculated event(s)';
export const shipmentFilterEditStatesTitle = 'Edit shipment state(s) or calculated event(s)';

export const shipmentFilterStatesSummaryTitle = 'View your selection​';

export const shipmentFilterShipmentTypeTitle = 'Select shipment type(s)';
export const shipmentFilterEditShipmentTypeTitle = 'Edit shipment type(s)';

export const shipmentFilterShipmentTagsTitle = 'Add shipment tags (Optional)';
export const shipmentFilterEditShipmentTagsTitle = 'Edit shipment tags (Optional)';

export const shipmentFilterShipmentWebhook = 'Restrict Trigger (Optional)';

export const editApiKeyTitle = 'Rename your API Key';

export const sftpAccountFormTitle = 'Create an SFTP Account';
export const createSftpAccountTitle = 'Creating an SFTP Account';
export const okButtonTitle = 'Got it';
