import React from 'react';
import { datadogLogs } from '@datadog/browser-logs';

class ErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    datadogLogs.logger.error(
      'An error occurred in one of the app components. A white screen will be displayed. Look for the console error to get the JS stack trace.',
      {
        error: {
          kind: 'ReactError',
          origin: 'source',
          stack: errorInfo.componentStack,
        },
      },
    );
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
