import React, { Component } from 'react';
import { connect } from 'react-redux';
import DualButtons from 'components/DualButtons';
import BackButton from 'components/BackButton';
import Chip from 'components/Chip';
import ColouredDot from 'components/ColouredDot';
import Hr from 'components/CardShell/Hr';
import Header from 'components/CardShell/Header';
import styles from './styles.scss';

class StatesSummary extends Component {
  componentDidMount() {
    this.redirectWhenEmpty();
  }

  componentDidUpdate() {
    this.redirectWhenEmpty();
  }

  handleSectionChange = (page) => {
    this.props.gotoPage(page);
  };

  handleDeleteCalcEvent = (item) => {
    const newCalcEvents = this.props.formData.calculated_events.filter(e => e !== item.props.id);
    this.props.updateState({ calculated_events: newCalcEvents });
  };

  handleDeleteState = (item) => {
    const newStates = this.props.formData.states.filter(e => e.state !== item.props.id);
    this.props.updateState({ states: newStates });
  };

  redirectWhenEmpty() {
    if (!this.props.formData.states.length && !this.props.formData.calculated_events.length) {
      this.props.gotoPage(1);
    }
  }

  render() {
    const {
      shipmentFilterTitle,
      pageTabs,
      formData,
      cancel,
      availableCalculatedEvents,
    } = this.props;
    return (
      <div className={styles.cardsection}>
        <div className={styles.cardSectionInner}>
          <Header>{shipmentFilterTitle}</Header>
          <Hr />
          <div className={styles.buttonBack}>
            <BackButton onBack={() => this.handleSectionChange(2)} />
          </div>
          {pageTabs()}
          {formData.states.length > 0 && (
          <>
            <p className={styles.numberSection}><b>Here are the selected shipment states</b></p>
            <div className={styles.labelHolder}>
              {formData.states.map(state => (
                <div key={state.state} className={styles.chipContainer}>
                  <Chip
                    id={state.state}
                    onDelete={this.handleDeleteState}
                    icon={<ColouredDot theme={state.category} />}
                    label={state.name}
                  />
                </div>
              ))}
            </div>
          </>
          )}
          {formData.calculated_events.length > 0 && (
            <>
              <p className={styles.numberSection}><b>Here are the selected calculated events</b></p>
              <div className={styles.labelHolder}>
                {formData.calculated_events.map(id => (
                  <div key={id} className={styles.chipContainer}>
                    <Chip
                      id={id}
                      onDelete={this.handleDeleteCalcEvent}
                      label={availableCalculatedEvents.find(e => e.id === id).name}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <DualButtons
          leftFunction={cancel}
          leftText="Cancel"
          rightFunction={() => this.handleSectionChange(4)}
          rightText="Next"
          theme="rightPink"
        />
      </div>
    );
  }
}

export default connect(state => ({
  availableCalculatedEvents: state.availableCalculatedEvents.values,
}))(StatesSummary);
