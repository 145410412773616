import React from 'react';

const EmailStatisticsContext = React.createContext({
  setPageTitle: () => { },
  setPageMessage: () => { },
  setSelectedServerId: () => { },
  loadDefaultServer: () => { },
});

export default EmailStatisticsContext;
