import React from 'react';

/* eslint-disable */
export default props => (
  <svg {...props} id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 71.2 50.3'>
    <path d='M29.6,12.4V12V7V1.5c0-1.3-1.5-1.9-2.4-1.1L14.4,11.7L0.5,24c-0.7,0.6-0.7,1.6,0,2.2l13.9,12.4	c0,0,12.7,11.3,12.7,11.3c0.9,0.8,2.4,0.2,2.4-1.1l0-3.6c0,0,0-6.9,0-6.9v0c0-0.8,0.7-1.5,1.5-1.5c23.2,1,36.4,9.9,39.5,12.2	c0.2,0.2,0.6,0,0.5-0.3C64.7,16.9,38.6,12.6,29.6,12.4z'
    />
  </svg>
)

/* eslint-enable */
