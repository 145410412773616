import React from 'react';
import ContextPopup from 'components/ContextPopup/ContextPopup';
import VEllipsisSvg from 'images/vEllipsis.svg';
import ContextMenuItem from 'components/ContextMenu/ContextMenuItem';
import useMediaQuery from 'hooks/useMediaQuery';
import cx from 'classnames';
import styles from './styles.scss';
import trimLongNames from '../../helpers/trimLongNames';

const Tabs = ({ args }) => {
  const {
    data,
    selectedTabId,
    pageWidth,
    onTabClicked,
  } = args;
  const isDesktop = useMediaQuery('(min-width: 960px)');
  const isTablet = useMediaQuery('(min-width: 768px)');

  const [visibleTabsCount, setVisibleTabCount] = React.useState(0);
  const [selectedId, setSelectedId] = React.useState(selectedTabId);

  const tabsData = Array.from(data);

  React.useEffect(() => {
    if (tabsData?.length >= visibleTabsCount) {
      setVisibleTabCount(Math.floor((pageWidth - 42) / 120));
    } else {
      setVisibleTabCount(Math.floor((pageWidth) / 120));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageWidth, tabsData?.length]);

  React.useEffect(() => {
    if (selectedTabId && selectedTabId !== '') {
      setSelectedId(Number.parseInt(selectedTabId, 10));
    }
  }, [selectedTabId]);

  const handleTabClick = (tabId) => {
    setSelectedId(tabId);
    onTabClicked(tabId);
  };

  return (
    <ul className={styles.tabsList}>
      {tabsData?.slice(0, visibleTabsCount).map(tab => (
        <li
          role="tab"
          key={tab.id}
          title={tab.name}
          className={`${styles.tab} ${tab.id === selectedId ? styles.selectedTab : ''}`}
          onClick={() => handleTabClick(tab.id)}
        >
          {trimLongNames(tab.name, isDesktop, isTablet, 32, 22, 12)}
        </li>
      ))}

      {(visibleTabsCount < tabsData?.length) && (
        <div className={`${styles.tab} ${styles.showMoreTab}`}>
          <ContextPopup
            popupOwnerRender={(togglePopup, isPopupShown) => (
              <button
                type="button"
                className={styles.ellipsisButton}
                onClick={togglePopup}
              >
                <VEllipsisSvg className={cx(styles.ellipsisIcon, isPopupShown ? styles.active : null)} />
              </button>
            )}
          >
            <div>
              {data.slice(visibleTabsCount).map(
                tab => (
                  <ContextMenuItem key={tab.id} title={tab.name} clickHandler={() => handleTabClick(tab.id)}>
                    {trimLongNames(tab.name, isDesktop, isTablet, 32, 22, 12)}
                  </ContextMenuItem>
                ),
              )}
            </div>
          </ContextPopup>
        </div>
      )}
    </ul>
  );
};

export default Tabs;
