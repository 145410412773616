import React from 'react';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import styles from './styles.scss';

const ChartComponent = ({ chartProps }) => (
  <div className={styles.chartContainer}>
    <Chart type={chartProps.type} data={chartProps.chartData} options={chartProps.options} />
  </div>
);

export default ChartComponent;
