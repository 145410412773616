import React, { Component } from 'react';
import { connect } from 'react-redux';
import WebhookConfigForm from 'components/FormGroups/WebhookConfigForm';
import { event as gaEvent } from 'react-ga';
import styles from './styles.scss';

class WebhookEditForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isWebhookFound: false,
      webhook: null,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const requestedWebhook = this.props.webhooks.find(key => key.configuration_id === id);

    if (!requestedWebhook) {
      this.backToItems();
    } else {
      this.setState({
        isWebhookFound: true,
        webhook: requestedWebhook,
      });
    }
  }

  backToItems = () => {
    gaEvent({
      category: 'Webhook',
      action: 'back to landing page from edit',
    });
    this.props.history.push('/settings/webhooks/');
  };

  render() {
    return this.state.isWebhookFound
      && (
      <div className={styles.webhookFormWrapper}>
        <h1 className="h2">Editing Webhook</h1>
        <div className="container__cards-large">
          <WebhookConfigForm
            webhook={this.state.webhook}
            flipCard={this.backToItems}
            locales={this.props.locales}
          />
        </div>
      </div>
      );
  }
}

const mapStatetoProps = state => ({
  webhooks: state.webhooksStates.webhooks,
  locales: state.locales,
});

export default connect(mapStatetoProps)(WebhookEditForm);
