import React, {
  useState, useContext, useEffect, useCallback,
} from 'react';
import propTypes from 'prop-types';
import {
  FormControl,
} from '@material-ui/core';

import Header from 'components/CardShell/Header';
import styles from './styles.scss';
import Hr from '../../CardShell/Hr';
import BackButton from '../../BackButton';
import DualButtons from '../../DualButtons';
import ShipmentFilterContext from '../../../routes/Settings/ShipmentFilters/ShipmentFiltersContext';
import InputWithValidation from '../../InputWithValidation';
import { validateForm } from '../../../helpers/validateForm';
import Checkbox from '../../Checkbox';
import SelectWithValidation from '../../SelectWithValidation';

const ShipmentWebhook = (props) => {
  const {
    title, gotoPage, pageTabs, cancel, editMode, submitForm, updateState, formData,
  } = props;

  const { metadataGroupings } = useContext(ShipmentFilterContext);

  const [singleTriggerPerShipmentGroup, setSingleTriggerPerShipmentGroup] = useState(formData.single_trigger_per_shipment_group);
  const [singleTriggerGroupingMetakey, setSingleTriggerGroupingMetakey] = useState(formData.single_trigger_grouping_metakey);
  const [singleTriggerTimespan, setSingleTriggerTimeSpan] = useState(formData.single_trigger_timespan_in_minutes);

  const [errors, setErrors] = useState(null);
  const maxTriggerResetTime = 130000;

  const validationRules = {
    singleTriggerGroupingMetakey: [
      {
        name: 'required',
        message: () => 'This field is mandatory if the checkbox is ticked',
        isValid: () => !singleTriggerPerShipmentGroup || singleTriggerGroupingMetakey,
      },
      {
        name: 'changed group',
        message: () => 'This field is mandatory if the checkbox is ticked',
        isValid: () => !singleTriggerPerShipmentGroup
          || metadataGroupings.filter(g => g.metadataKey === singleTriggerGroupingMetakey).length > 0,
      },
    ],
    singleTriggerTimespan: [
      {
        name: 'required',
        message: () => 'This field is mandatory if the checkbox is ticked',
        isValid: () => !singleTriggerPerShipmentGroup || singleTriggerTimespan,
      },
      {
        name: 'minValue',
        message: () => 'Min trigger reset time is 1 minute',
        isValid: () => !singleTriggerPerShipmentGroup || singleTriggerTimespan >= 1,
      },
      {
        name: 'maxValue',
        message: () => `Max trigger reset time is ${maxTriggerResetTime} minutes (3 months)`,
        isValid: () => singleTriggerTimespan <= maxTriggerResetTime,
      },
    ],
  };

  const handleSubmit = () => {
    const formValidation = validateForm(validationRules, { singleTriggerPerShipmentGroup, singleTriggerGroupingMetakey, singleTriggerTimespan });

    if (!formValidation.hasErrors) {
      setErrors(formValidation.hasErrors);
      updateState({
        single_trigger_per_shipment_group: singleTriggerPerShipmentGroup,
        single_trigger_grouping_metakey: singleTriggerPerShipmentGroup ? singleTriggerGroupingMetakey : null,
        single_trigger_timespan_in_minutes: singleTriggerPerShipmentGroup ? singleTriggerTimespan : 0,
      }, submitForm);
    } else {
      setErrors(formValidation);
    }
  };

  const handleTimespanChange = (e) => {
    setSingleTriggerTimeSpan(e.target.value.replace(/[^0-9]/g, ''));
  };

  const saveState = useCallback(() => {
    updateState({
      single_trigger_per_shipment_group: singleTriggerPerShipmentGroup,
      single_trigger_grouping_metakey: singleTriggerPerShipmentGroup ? singleTriggerGroupingMetakey : null,
      single_trigger_timespan_in_minutes: singleTriggerPerShipmentGroup ? singleTriggerTimespan : 0,
    });
  }, [singleTriggerTimespan, singleTriggerGroupingMetakey, singleTriggerPerShipmentGroup, updateState]);

  useEffect(() => {
    saveState();
  }, [saveState]);

  const handleBack = () => {
    gotoPage(5);
  };

  return (
    <div className={styles.cardsection}>
      <div className={styles.cardSectionInnerFullSize}>
        <div className={styles.innerContent}>
          <Header>{title}</Header>
          <Hr />
          <div className={styles.buttonBack}>
            <BackButton onBack={handleBack} />
          </div>
          {pageTabs()}
          <p className={styles.innerContentTitle}>
            You can restrict triggering of this shipment filter to once per shipment grouping. Shipment Groupings must be set up first.
            This step is optional and should only be used if you know it is needed.
            Restriction is time based and can last from 1 minute to 3 months (130000 minutes).
          </p>
          <Checkbox
            label="Trigger once per grouping?"
            checked={singleTriggerPerShipmentGroup}
            onChange={() => { setSingleTriggerPerShipmentGroup(!singleTriggerPerShipmentGroup); }}
          />
          {singleTriggerPerShipmentGroup && (
            <FormControl fullWidth>
              <div className={styles.inputGroup}>
                <SelectWithValidation
                  label="Grouping Metakey"
                  name="singleTriggerGroupingMetakey"
                  value={singleTriggerGroupingMetakey}
                  defaultValue={null}
                  onChange={(e) => { setSingleTriggerGroupingMetakey(e.value); }}
                  options={[...metadataGroupings.map(x => ({
                    value: x.metadataKey,
                    label: x.metadataKey,
                  }))]}
                  errors={errors}
                />
              </div>
              <div className={styles.inputGroup}>
                <InputWithValidation
                  label="Trigger reset in minutes"
                  placeholder="Trigger reset in minutes"
                  onChange={handleTimespanChange}
                  value={singleTriggerTimespan}
                  type="text"
                  name="singleTriggerTimespan"
                  errors={errors}
                />
              </div>
            </FormControl>
          )}
        </div>
      </div>
      <DualButtons
        leftFunction={cancel}
        leftText="Cancel"
        rightFunction={handleSubmit}
        rightText={editMode ? 'Update Filter' : 'Create Filter'}
        theme="rightPink"
      />
    </div>
  );
};

ShipmentWebhook.propTypes = {
  gotoPage: propTypes.func.isRequired,
  pageTabs: propTypes.func.isRequired,
  cancel: propTypes.func.isRequired,
  editMode: propTypes.bool.isRequired,
  submitForm: propTypes.func.isRequired,
  updateState: propTypes.func.isRequired,
  formData: propTypes.shape({
    single_trigger_per_shipment_group: propTypes.bool,
    single_trigger_grouping_metakey: propTypes.string,
    single_trigger_timespan_in_minutes: propTypes.number,
  }).isRequired,
};

export default ShipmentWebhook;
