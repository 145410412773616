import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import CalculatedEventsDashboard from 'routes/Dashboards/CalculatedEvents/CalculatedEventsDashboard';
import { getAvailableCarriers as getAvailableCarriersAction } from 'actions';
import { connect } from 'react-redux';
import States from './States';
import Configured from './Configured';

const Dashboards = ({ getAvailableCarriers }) => {
  useEffect(() => {
    getAvailableCarriers();
  }, [getAvailableCarriers]);
  return (
    <Switch>
      <Route path="/dashboards/states" component={States} />
      <Route path="/dashboards/calculated-events" component={CalculatedEventsDashboard} />
      <Route path="/dashboards/proactive-alerting" component={Configured} />
      <Redirect exact from="/dashboards" to="/dashboards/states" />
      <Route render={() => 'not found'} />
    </Switch>
  );
};

const mapDispatchToProps = dispatch => ({
  getAvailableCarriers: () => dispatch(getAvailableCarriersAction()),
});

export default connect(null, mapDispatchToProps)(Dashboards);
