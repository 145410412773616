import React, { Component } from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import DualButtons from 'components/DualButtons';
import abbreviate from 'helpers/abbreviate';
import styles from './styles.scss';

class Card extends Component {
  static propTypes = {
    title: propTypes.string,
    value: propTypes.oneOfType([propTypes.string, propTypes.number, propTypes.element]).isRequired,
    description: propTypes.oneOfType([propTypes.string, propTypes.element]),
    theme: propTypes.string,
    noScale: propTypes.bool,
    onClick: propTypes.oneOfType([propTypes.func, propTypes.bool]),
  };

  static defaultProps = {
    title: '',
    description: '',
    theme: 'grey',
    noScale: false,
    onClick: false,
  };

  cardClicked = () => {
    if (this.props.onClick) {
      this.props.onClick(this.props.theme);
    }
  };

  render() {
    const valueAbbreviation = this.props.value && abbreviate(this.props.value);
    const {
      leftAction,
      leftActionText,
      leftActionDisabled,
      rightAction,
      rightActionText,
      rightActionTooltipText,
      rightActionTooltipVisible,
      rightActionDisabled,
    } = this.props;

    return (
      <div
        className={classnames(
          styles.card,
          styles[this.props.theme],
          this.props.noScale ? styles.noScale : '',
          (!this.props.title) ? styles.isCompact : '',
          styles.cardItem,
        )}
      >

        <div
          className={classnames(styles.body, this.props.onClick ? '' : styles.noAction)}
          onClick={this.cardClicked}
          role="button"
        >
          <span className={classnames(styles.title, 'h4')}>{this.props.title}</span>
          { valueAbbreviation !== undefined
            && valueAbbreviation !== null
            && (<span className={classnames(styles.value, 'h1')}>{valueAbbreviation}</span>)}
          <span className={styles.description}>{this.props.description}</span>
        </div>

        {leftAction && !rightAction && (
          <button type="button" className={styles.soloButton} onClick={() => leftAction()}>
            { leftActionText }
          </button>
        )}

        {leftAction && rightAction && (
          <DualButtons
            leftText={leftActionText}
            leftFunction={leftAction}
            leftFunctionDisabled={leftActionDisabled}
            rightText={rightActionText}
            rightTooltipText={rightActionTooltipText}
            rightTooltipVisible={rightActionTooltipVisible}
            rightFunction={rightAction}
            rightFunctionDisabled={rightActionDisabled}
          />
        )}

      </div>
    );
  }
}

export default Card;
