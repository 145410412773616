import cx from 'classnames';
import React from 'react';
import Button from '../../../../../components/ReactButton';
import ExportIcon from '../../../../../images/export2';
import styles from './buttons.scss';

export function ExportSuppressions(props) {
  return (
    <Button theme="white" data-testid="ExportSuppressions" className={cx(styles.button, styles.iconButton)} onClick={props.onClick}>
      <div>
        <ExportIcon width={20} height={20} />
        <span>Export</span>
      </div>
    </Button>
  );
}

export function ReactivateButton(props) {
  return (
    <Button
      theme="white"
      disabled={props.isDisabled}
      title={props.tooltip}
      className={cx(styles.button, styles.reactivateButton)}
      onClick={props.onClick}
    >
      Reactivate
    </Button>
  );
}
