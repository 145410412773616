import React from 'react';
import { withRouter } from 'react-router';
import Popup from 'components/Layout/Popup';

class ErrorHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    return this.state.hasError
      ? (
        <Popup
          open
          description="There was a problem rendering this document"
          onDecline={() => this.props.history.push('/settings/notification-centre/templates')}
        />
      )
      : this.props.children;
  }
}

export default withRouter(ErrorHandler);
