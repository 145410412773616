export const headerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  fontSize: '18px',
  background: 'white',
  padding: '0',
  cursor: 'context-menu',
  borderBottom: '1px solid gray',
};

export const centrePosition = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
