import React from 'react';
import propTypes from 'prop-types';
import NavItem from '../NavItem';
import SubNavItem from '../SubNavItem';

const ReactDashboard = ({ title }) => (
  <NavItem title={title} url="dashboards">
    <SubNavItem baseUrl="dashboards">
      States
    </SubNavItem>
    <SubNavItem baseUrl="dashboards" urlRename="shipments">
      All Shipments
    </SubNavItem>
    <SubNavItem baseUrl="dashboards" urlRename="calculated-events">
      Calculated Events
    </SubNavItem>
  </NavItem>
);

ReactDashboard.propTypes = {
  title: propTypes.string.isRequired,
};

export default ReactDashboard;
