import React from 'react';
import { Route, Switch } from 'react-router';
import EmailStatistics from './EmailStatistics/statistics/statistics';
import BounceStatistics from './EmailStatistics/statistics/BounceStatistics';
import Suppressions from './EmailStatistics/statistics/SuppressionStatistics';
import EmailActivityDetail from './EmailStatistics/statistics/activityDetail';
import Activity from './EmailStatistics/statistics/ActivityStatistics';
import EmailActivityEvents from './EmailStatistics/statistics/ActivityEvents';
import EmailStatisticsWrapper from './EmailStatistics/index';

const EmailStatisticsRoutes = () => (
  <EmailStatisticsWrapper>
    <Switch>
      <Route path="/reporting/email-statistics/:id/activity/:emailActivityId/detail" component={EmailActivityDetail} />
      <Route path="/reporting/email-statistics/:id/activity/:emailAddress/events" component={EmailActivityEvents} />
      <Route path="/reporting/email-statistics/:id/activity" component={Activity} />
      <Route path="/reporting/email-statistics/:id/suppressions" component={Suppressions} />
      <Route path="/reporting/email-statistics/:id/bounce" component={BounceStatistics} />
      <Route path="/reporting/email-statistics/:id" component={EmailStatistics} />
      <Route path="/reporting/email-statistics" component={EmailStatistics} />
    </Switch>
  </EmailStatisticsWrapper>
);

export default EmailStatisticsRoutes;
