const sessionStorageSelectedFilters = 'selected_filters';

function setSelectedFilters(searchQuery) {
  window.sessionStorage.setItem('selected_filters', searchQuery);
}

function getSelectedFilters() {
  return window.sessionStorage.getItem('selected_filters');
}

function removeSelectedFilters() {
  window.sessionStorage.removeItem(sessionStorageSelectedFilters);
}

export {
  setSelectedFilters,
  getSelectedFilters,
  removeSelectedFilters,
};
