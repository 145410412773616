import React, { Component } from 'react';
import SelectSearch from 'react-select';
import Button from 'components/ReactButton';
import Svg from 'components/Svg';
import BackButton from 'components/BackButton';
import classNames from 'classnames';
import styles from './styles.scss';

export const selectStyles = {
  option: (defaultStyles, props) => ({
    ...defaultStyles,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    /* eslint-disable */
    backgroundColor: props.isFocused
      ? '#f2f2f2'
      : props.isSelected ? '#ededed' : '',
    /* eslint-enable */
    color: props.isSelected ? '#00854D' : 'black',
    width: 'inherit',
    height: '40px',
    marginTop: '1px',
    marginRight: '6px',
    '&:active': {
      backgroundColor: '#f7f7f7',
    },
    '&::after': {
      content: 'none',
    },
  }),
  menu: ({ boxShadow, position, ...defaultStyles }) => ({
    ...defaultStyles,
    padding: 0,
    margin: 0,
  }),
  menuList: ({ boxShadow, ...defaultStyles }) => ({
    ...defaultStyles,
    overflowX: 'hidden',
    padding: '10px 5px 5px 10px',
  }),
  multiValue: () => ({ display: 'none' }),
  control: (defaultStyles, state) => ({
    ...defaultStyles,
    backgroundColor: '#f9f9f9',
    height: '50px',
    border: state.isFocused ? '2px solid #0065B3' : 0,
    '&:hover': {
      border: state.isFocused ? '2px solid #0065B3' : '1px solid #fc835a',
    },
    boxShadow: 'none',
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    color: '#616161',
  }),
  dropdownIndicator: (defaultStyles) => ({
    ...defaultStyles,
    color: '#616161', // Custom colour
  }),
};

const setValueActionName = 'set-value';

export default class InnerCard extends Component {
  titleStyles = classNames('h2', styles.titleAdditionalStyles)

  constructor(props) {
    super(props);
    this.state = {
      picked: this.props.defaultValues,
      inputValue: '',
    };
  }

  handleCancel = (e) => {
    e?.preventDefault();
    this.props.onCancel();
  };

  handleSubmit = (e) => {
    e?.preventDefault();
    this.props.onSubmit(this.props.name, this.state.picked);
  };

  handleChange = (value) => {
    this.setState({ picked: value });
  };

  handleInputChange = (query, { action }) => {
    // Prevents resetting our input after option has been selected
    // https://github.com/JedWatson/react-select/issues/3210
    if (action !== setValueActionName) {
      this.setState({ inputValue: query });
    }
  };

  handleRemove = option => this.setState(state => ({ picked: state.picked.filter(el => el.value !== option.value) }));

  renderSelectedOptions = selected => (
    <div className={styles.selectedOptionsContainer}>
      {selected.length > 0
        ? selected.map(option => (
          <div
            key={option.value}
            role="button"
            onClick={() => this.handleRemove(option)}
            className={styles.selectedOption}
          >
            {option.label}
            <Svg className={styles.crossIcon} name="crossCircle" />
          </div>
        ))
        : (
          <div
            className={styles.selectedOption}
          >
            All {`${this.props.name.charAt(0).toUpperCase()}${this.props.name.slice(1)}`}
          </div>
        )}
    </div>
  );

  render() {
    const {
      name,
      subText,
      title,
    } = this.props;

    return (
      <div className={classNames(styles.container, { [styles.expanded]: localStorage.getItem('sidebar.expanded') === 'true' })}>
        <div className={styles.titleContainer}>
          <div className={styles.backButtonContainer}>
            <BackButton onBack={this.handleSubmit} />
          </div>
          <h1 className={this.titleStyles}>{title}</h1>
          <div className={styles.subTitleButtonContainer}>
            {subText && (
              <p>{subText}</p>
            )}
          </div>
        </div>
        <div className={styles.selectSearchContainer}>
          <SelectSearch
            inputValue={this.state.inputValue}
            onInputChange={this.handleInputChange}
            options={this.props.options}
            defaultValue={this.props.defaultValues}
            value={this.state.picked}
            onChange={this.handleChange}
            name={name}
            placeholder="Search"
            isMulti
            autofocus
            isSearchable
            menuIsOpen
            isClearable={false}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            backspaceRemovesValue={false}
            styles={selectStyles}
            maxMenuHeight={350}
            classNamePrefix="react-select"
            className="react-select-container"
          />
          {this.renderSelectedOptions(this.state.picked)}
        </div>
        <div className={`${styles.buttonsRow}`}>
          <Button className={styles.button} onClick={this.handleSubmit}>Apply</Button>
          <Button className={styles.button} theme="grey" onClick={this.handleCancel}>Cancel</Button>
        </div>
      </div>
    );
  }
}
