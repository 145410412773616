import React from 'react';
import classnames from 'classnames';
import styles from './registration-button.scss';

const LoginFormButton = props => (
  <button
    onClick={props.onClick}
    className={classnames(
      styles.loginFormButton,
      props.className,
      props.disabled ? styles.disabled : '',
    )}
    style={props.style}
    // eslint-disable-next-line react/button-has-type
    type={props.type || 'button'}
    disabled={props.disabled}
  >
    <span className={styles.content}>{props.text ? props.text : props.children}</span>
  </button>
);

export default LoginFormButton;
