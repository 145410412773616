import React from 'react';
import cx from 'classnames';
import styles from './styles.scss';

const CardShell = ({ children, noOverflow }) => (
  <div className={cx(styles.card, noOverflow ? styles.cardNotHidden : '')}>
    { children }
  </div>
);

export default CardShell;
