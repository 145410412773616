import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import Frame from 'react-frame-component';
import classnames from 'classnames';
import API from 'helpers/API';
import Spinner from 'components/Spinner';
import InputWithValidation from 'components/InputWithValidation';
import { isEmail, maxLength, required } from 'helpers/validate';
import { getServerValidationErrors, validateForm } from 'helpers/validateForm';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorHandler from './ErrorHandler';
import styles from './styles.scss';

const DesktopViewWidth = '100%';
const MobileViewWidth = '360px';

function Preview({ match }) {
  const [html, setHtml] = useState('');
  const [isMobileView, setIsMobileView] = useState(false);
  const [sizeProp, setSizeProps] = useState({ width: DesktopViewWidth, height: '100%' });
  const [isLoading, setIsLoading] = useState(true);
  const [isSendingPreview, setIsSendingPreview] = useState(false);
  const [emailData, setEmailData] = useState({
    emailAddress: '',
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const { configurationId, templateId } = match.params;
      const response = await API.renderTemplate(configurationId, templateId);

      setIsLoading(false);
      setHtml(response.renderedContent);
    };
    fetchData();
  }, [match]);

  const validationRules = {
    emailAddress: [maxLength(100), isEmail(), required()],
  };

  const isClientErrorsExist = () => {
    const clientErrors = validateForm(validationRules, emailData);
    if (clientErrors.hasErrors) {
      setErrors(clientErrors);
      return true;
    }
    setErrors({});
    return false;
  };

  const handleMobile = () => {
    setSizeProps(prevState => ({ ...prevState, width: MobileViewWidth }));
    setIsMobileView(true);
  };

  const handleDesktop = () => {
    setSizeProps(prevState => ({ ...prevState, width: DesktopViewWidth }));
    setIsMobileView(false);
  };

  const submitSendTemplatePreview = async () => {
    if (isClientErrorsExist()) return;
    setIsSendingPreview(true);

    try {
      const { configurationId, templateId } = match.params;
      await API.sendTemplatePreview(configurationId, templateId, emailData.emailAddress);
      setEmailData(
        {
          emailAddress: '',
        },
      );
    } catch (response) {
      if (response.status === 400) {
        const serverErrors = getServerValidationErrors(response);
        setErrors({ emailAddress: serverErrors.emailStatus });
      } else {
        setErrors({
          emailAddress: ['Something went wrong. Please, check an entered email or try again later.'],
        });
      }
    } finally {
      setIsSendingPreview(false);
    }
  };

  const handleEmailAddressInputChange = (e) => {
    setEmailData(
      {
        emailAddress: e.target.value,
      },
    );
  };

  return (
    <> {isLoading && <Spinner />}
      <div className={styles.previewHeader}>
        <button
          className={classnames(isMobileView ? styles.btnMobile : styles.btnDesktop)}
          type="button"
          onClick={handleDesktop}
        >Desktop
        </button>
        <button
          className={isMobileView ? styles.btnDesktop : styles.btnMobile}
          type="button"
          onClick={handleMobile}
        >Mobile
        </button>
      </div>
      <div className={styles.previewHeaderSingleColumn}>
        <InputWithValidation
          label="Please enter an email address to receive a preview email"
          placeholder="Email address"
          type="email"
          value={emailData.emailAddress}
          name="emailAddress"
          onChange={handleEmailAddressInputChange}
          errors={errors}
        />
        <button type="button" onClick={submitSendTemplatePreview} className={styles.sendPreview}>
          <div className={styles.circularPrograss}>
            {isSendingPreview && <CircularProgress thickness={8} color="inherit" size={40} variant="indeterminate" />}
          </div>
          {!isSendingPreview && 'Send Preview'}
        </button>
      </div>
      <div className={styles.previewBody}>
        {html
          && (
          <ErrorHandler>
            <Frame
              initialContent={html}
              frameBorder="0"
              {...sizeProp}
            />
          </ErrorHandler>
          )}
      </div>
    </>
  );
}

export default withRouter(Preview);
