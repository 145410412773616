import React, { Component } from 'react';
import cx from 'classnames';
import { compareArrayByOrder } from 'helpers/common';
import {
  EmptyComponentType,
} from 'constants/trackingPage/componentTypes';
import ContextPopup, { PopupPositions } from 'components/ContextPopup/ContextPopup';
import BinSvg from 'images/bin.svg';
import EditDropdown from '../../EditDropdown';
import styles from '../../style.scss';
import DualButtons from '../../../DualButtons';

class GridEditRow extends Component {
  constructor(props) {
    super(props);
    this.state = { isEditRowDropDownShown: false };
  }

  toggleEditRowDropDown = () => this.setState(prevState => ({
    isEditRowDropDownShown: !prevState.isEditRowDropDownShown,
  }));

  deleteRow = () => {
    const {
      layoutRows,
      id,
      update,
    } = this.props;

    const removeIndex = layoutRows.map(item => item.id).indexOf(id);

    for (let i = 0; i < layoutRows.length; i += 1) {
      if (i > removeIndex) layoutRows[i].order -= 1;
    }

    const updatedRows = [...layoutRows];
    updatedRows.splice(removeIndex, 1);

    update(updatedRows);
    this.toggleEditRowDropDown();
  };

  deleteRowItem = (id) => {
    const { rowItems, layoutRows, update } = this.props;
    if (rowItems.length === 1) {
      this.deleteRow();
    } else {
      const removeIndex = rowItems.findIndex(item => item.id === id);
      for (let i = 0; i < rowItems.length; i += 1) {
        if (i > removeIndex) {
          rowItems[i].order -= 1;
        }
      }
      rowItems.splice(removeIndex, 1);
      update(layoutRows);
    }
  };

  alterOrder = (direction) => {
    const {
      id,
      layoutRows,
      update,
    } = this.props;

    const itemIndex = layoutRows.map(item => item.id).indexOf(id);
    const currentOrder = layoutRows[itemIndex].order;
    const updatedLayoutRows = [...layoutRows];

    if (direction === 'up' && itemIndex !== 1) {
      updatedLayoutRows[itemIndex].order = currentOrder - 1;
      updatedLayoutRows[itemIndex - 1].order = updatedLayoutRows[itemIndex - 1].order + 1;
    } else if (direction === 'down' && itemIndex !== updatedLayoutRows.length - 1) {
      updatedLayoutRows[itemIndex].order = currentOrder + 1;
      updatedLayoutRows[itemIndex + 1].order = updatedLayoutRows[itemIndex + 1].order - 1;
    }

    update(updatedLayoutRows);
    this.toggleEditRowDropDown();
  };

  render() {
    const {
      rowItems,
      toggleEditColumn,
      id,
      layoutRows,
      order,
    } = this.props;
    const { isEditRowDropDownShown } = this.state;

    let columnClass;
    if (rowItems) columnClass = styles[`rowItems${rowItems.length}`];

    return (
      <div className={styles.formGroup}>
        <div className={styles.rowItem}>
          <p className={styles.loginFormLabel}>{`Row ${order + 1}`}</p>
          <div className={cx(styles.rowItems, columnClass)}>
            {rowItems.sort(compareArrayByOrder).map(rowItem => (
              <div key={rowItem.id}>
                <div className={styles.rowItemBody}>
                  {
                    rowItem.componentType !== EmptyComponentType
                      ? rowItem.componentType
                      : ' '
                  }
                </div>
                <div className={styles.rowItemButtons}>
                  <button type="button" onClick={() => toggleEditColumn(rowItem, id)}>Edit</button>
                  <div>
                    <ContextPopup
                      popupPosition={PopupPositions.bottomAuto}
                      popupOwnerRender={
                        (togglePopup, isPopupShown) => (
                          <div
                            role="button"
                            className={cx(styles.removeIcon, { [styles.removeIconActive]: isPopupShown })}
                            onClick={togglePopup}
                          >
                            <BinSvg />
                          </div>
                        )
                      }
                      contentRender={
                        togglePopup => (
                          <div className={styles.removePopup}>
                            <div className={styles.removePopupText}>Are you sure you want to delete?</div>
                            <DualButtons
                              leftText="No"
                              rightText="Yes"
                              theme="rightPinkLow"
                              leftFunction={() => (togglePopup())}
                              rightFunction={() => this.deleteRowItem(rowItem.id)}
                            />
                          </div>
                        )
}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.editRowButton}>
            {order !== 0 && (<button type="button" onClick={() => this.toggleEditRowDropDown()}>Edit</button>)}
            {isEditRowDropDownShown && (
              <>
                <EditDropdown
                  canMoveUp={order >= 2}
                  canMoveDown={order <= layoutRows.length - 2}
                  alterOrder={this.alterOrder}
                  deleteFn={this.deleteRow}
                  toggleEditDropDown={this.toggleEditRowDropDown}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default GridEditRow;
