import React, { Component } from 'react';
import { parseFiltersSearchQuery } from 'helpers/parseFiltersSearchQuery';
import { stringify } from 'helpers/queryString';
import countries from 'constants/countries';
import vocabulary from 'constants/vocabulary';
import availableStates from 'helpers/availableStates';
import Button from 'components/ReactButton';
import classnames from 'classnames';
import { toUnixTime } from 'helpers/datetime';
import { hasProperty } from 'helpers/common';
import { setSelectedFilters } from 'helpers/selectedFilters';
import { withRouter } from 'react-router';
import BackButton from 'components/BackButton';
import { connect } from 'react-redux';
import Spinner from 'components/Spinner';
import FilterCard from 'components/Filters/FilterCard';
import FilterCardMetadata from 'components/Filters/FilterCardMetadata';
import DateFilter from './DateFilter';
import RadioFilter from './RadioFilter';
import InnerCard from './InnerCard';
import InnerCardCountry from './InnerCardCountry';
import styles from './styles.scss';
import InnerCardMetadata from './InnerCardMetadata';
import typography from '../../helpers/appTypography';

class Filters extends Component {
  constructor(props) {
    super(props);
    const { location: { search } } = this.props;

    this.state = {
      carriers: [],
      states: [],
      origins: [],
      destinations: [],
      metadataKey: '',
      metadataValue: '',
      ...parseFiltersSearchQuery(search, props.availableCarriers, true),
    };
  }

  stringifyDateFilter = (dateFilter) => {
    if (hasProperty(dateFilter, 'from') && hasProperty(dateFilter, 'to')) {
      return JSON.stringify({
        from: dateFilter.from ? toUnixTime(new Date(dateFilter.from)) : null,
        to: dateFilter.to ? toUnixTime(new Date(dateFilter.to)) : null,
      });
    }
    return JSON.stringify(dateFilter);
  };

  stringifyData = (dataToStringify) => {
    const {
      registeredDate,
      orderDate,
      shippedDate,
      deliveryDate,
      promisedDate,
      estimatedDate,
      shippingDirection,
      carriers,
      states,
      origins,
      destinations,
      metadataKey,
      metadataValue,
    } = dataToStringify;
    const result = {};
    // if its different from default values (currently: today)
    // TODO: match it with default values coming from props

    if (registeredDate && registeredDate.from !== 'any') {
      result.registeredDate = this.stringifyDateFilter(registeredDate);
    }
    if (orderDate && orderDate.from !== 'any') {
      result.orderDate = this.stringifyDateFilter(orderDate);
    }
    if (shippedDate && shippedDate.from !== 'any') {
      result.shippedDate = this.stringifyDateFilter(shippedDate);
    }
    if (deliveryDate && deliveryDate.from !== 'any') {
      result.deliveryDate = this.stringifyDateFilter(deliveryDate);
    }
    if (promisedDate && promisedDate.from !== 'any') {
      result.promisedDate = this.stringifyDateFilter(promisedDate);
    }
    if (estimatedDate && estimatedDate.from !== 'any') {
      result.estimatedDate = this.stringifyDateFilter(estimatedDate);
    }
    if (shippingDirection && shippingDirection !== 'all') {
      result.shippingDirection = shippingDirection;
    }
    if (carriers && carriers.length > 0) {
      result.carriers = carriers.map(carrier => carrier.value);
    }
    if (states && states.length > 0) {
      result.states = states.map(state => state.value);
    }
    if (origins && origins.length > 0) {
      result.origins = origins.reduce((prev, next) => prev + next, '');
    }
    if (destinations && destinations.length > 0) {
      result.destinations = destinations.reduce((prev, next) => prev + next, '');
    }
    if (metadataKey && metadataKey.length > 0) {
      result.metadataKey = metadataKey;
    }
    if (metadataValue && metadataValue.length > 0) {
      result.metadataValue = metadataValue;
    }

    return `?${Object.keys(result).length !== 0 ? stringify(result) : ''}`;
  };

  handleRadioChose = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  };

  handleChange = (name, data) => {
    this.setState({
      [name]: { from: data.from },
    });
  };

  handleDateRangePick = (name, data) => {
    /* EXAMPLE OF INCOMING PARAMETERS
    name = 'orderDate'
    data = {
      here we have 3 options:
      incoming field 'from': 'today'
      incoming field 'from': '2018-05-02T02:00:00'
      incoming field 'to': '2018-05-02T02:00:00'
    }
    */
    this.setState((state) => {
      if (data.from) {
        if (!state[name] || !state[name].to) {
          return {
            [name]: {
              from: data.from,
              to: data.from,
            },
          };
        }
        return {
          [name]: {
            ...state[name],
            from: data.from,
          },
        };
      }
      if (data.to) {
        if (vocabulary[state[name] ? state[name].from : 'any']) {
          return {
            [name]: {
              from: data.to,
              to: data.to,
            },
          };
        }
        return {
          [name]: {
            ...state[name],
            to: data.to,
          },
        };
      }
      return null;
    });
  };

  handleReset = (e) => {
    if (e) e.preventDefault();
    const {
      registeredDate,
      orderDate,
      shippedDate,
      deliveryDate,
      promisedDate,
      estimatedDate,
      shippingDirection,
      carriers,
      states,
      origins,
      destinations,
      metadataKey,
      metadataValue,
    } = this.state;
    let currentStateDiffersFromInitial = false;
    if (registeredDate && registeredDate.from !== 'any') currentStateDiffersFromInitial = true;
    if (orderDate && orderDate.from !== 'any') currentStateDiffersFromInitial = true;
    if (shippedDate && shippedDate.from !== 'any') currentStateDiffersFromInitial = true;
    if (deliveryDate && deliveryDate.from !== 'any') currentStateDiffersFromInitial = true;
    if (promisedDate && promisedDate.from !== 'any') currentStateDiffersFromInitial = true;
    if (estimatedDate && estimatedDate.from !== 'any') currentStateDiffersFromInitial = true;
    if (shippingDirection && shippingDirection !== 'all') currentStateDiffersFromInitial = true;
    if (carriers.length > 0) currentStateDiffersFromInitial = true;
    if (states.length > 0) currentStateDiffersFromInitial = true;
    if (origins.length > 0) currentStateDiffersFromInitial = true;
    if (destinations.length > 0) currentStateDiffersFromInitial = true;
    if (metadataKey && metadataKey.length > 0) currentStateDiffersFromInitial = true;
    if (metadataValue && metadataValue.length > 0) currentStateDiffersFromInitial = true;
    if (currentStateDiffersFromInitial) {
      this.props.showPopup({
        title: 'Are You Sure?',
        // eslint-disable-next-line
        description: 'You haven\'t saved your progress. Any changes you have made will not be saved. If you want to save your recent filter(s) please go back and Save\n',
        onAccept: () => {
          this.setState({
            registeredDate: null,
            orderDate: null,
            shippedDate: null,
            deliveryDate: null,
            promisedDate: null,
            estimatedDate: null,
            shippingDirection: null,
            carriers: [],
            states: [],
            origins: [],
            destinations: [],
            metadataKey: '',
            metadataValue: '',
          });
          this.props.closePopup();
        },
      });
    }
  };

  handleCancel = () => {
    if (
      this.stringifyData(this.state)
      !== this.stringifyData(parseFiltersSearchQuery(this.props.searchQuery, this.props.availableCarriers))
    ) {
      this.props.showPopup({
        title: 'Are You Sure?',
        // eslint-disable-next-line
        // description: 'Your current filters have not been saved. If you want to save\n' +
        //     'your filters, select the ‘Yes, save changes’ button below and\n'
        //     + 'then select the \'Apply filters & close\' button on the filters page.\n',
        // old description
        description: 'Your current filters have not been saved\n'
          + 'If you want to keep them, please go back\n'
         + 'and click \'Apply filters & close\' button',
        onAccept: () => {
          this.props.closeFilters();
          this.props.closePopup();
        },
      });
    } else {
      this.props.closeFilters();
    }
  };

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    const sq = this.stringifyData(this.state);
    if (sq) {
      setSelectedFilters(sq);
    }
    this.props.onSubmit(sq);
    this.props.closeFilters();
  };

  handleInnerCardSubmit = (name, values) => this.setState({ [name]: values, insideFilter: '' });

  handleMetadataInnerCardSubmit = (metaKey, metaValue) => {
    this.setState({ metadataKey: metaKey, metadataValue: metaValue, insideFilter: '' });
  }

  handleInnerCardCancel = () => this.setState({ insideFilter: '' });

  handleFiltersCardClick = cardTitle => this.setState({
    insideFilter: `${cardTitle.charAt(0).toLowerCase()}${cardTitle.slice(1)}`,
  });

  render() {
    const titleClasses = classnames('h2', styles.filtersTitle);
    const {
      carriersLoading,
    } = this.props;
    if (carriersLoading) {
      return <Spinner />;
    }
    switch (this.state.insideFilter) {
      case 'carriers':
        return (
          <InnerCard
            onCancel={this.handleInnerCardCancel}
            onSubmit={this.handleInnerCardSubmit}
            name="carriers"
            title="Add carrier filters"
            subText="Select the carriers you would like to filter by"
            options={this.props.availableCarriers}
            defaultValues={this.state.carriers}
          />
        );
      case 'states':
        return (
          <InnerCard
            onCancel={this.handleInnerCardCancel}
            onSubmit={this.handleInnerCardSubmit}
            name="states"
            title="Add shipment state filters"
            subText="Select the shipment states you would like to filter by"
            options={availableStates}
            defaultValues={this.state.states}
          />
        );
      case 'origins':
        return (
          <InnerCardCountry
            onCancel={this.handleInnerCardCancel}
            onSubmit={this.handleInnerCardSubmit}
            name="origins"
            title="Add origin shipping location filter"
            subText="Select the origin countries (aka from country or shipping location) you would like to filter by"
            defaultValues={this.state.origins}
          />
        );
      case 'destinations':
        return (
          <InnerCardCountry
            onCancel={this.handleInnerCardCancel}
            onSubmit={this.handleInnerCardSubmit}
            name="destinations"
            title="Add destination country filter"
            subText="Select the destination (to address) countries you would like to filter by"
            defaultValues={this.state.destinations}
          />
        );
      case 'metadata':
        return (
          <InnerCardMetadata
            onCancel={this.handleInnerCardCancel}
            onSubmit={this.handleMetadataInnerCardSubmit}
            name="metadata"
            title="Add metadata filters"
            subText="Add metadata key and value you would like to filter by"
            metaKey={this.state.metadataKey}
            metaValue={this.state.metadataValue}
          />
        );
      default:
        return (
          <div className={classnames(styles.container, { [styles.expanded]: localStorage.getItem('sidebar.expanded') === 'true' })}>
            <div className={classnames('prettyScrollBar', styles.containerInner)}>
              <h1 className={titleClasses}>{typography.filtersPopupWindow.title}</h1>
              <div className={styles.filtersSubtitle}>{typography.filtersPopupWindow.subTitle}</div>
              <div className={styles.mobileBackButton}>
                <BackButton onBack={this.handleCancel} />
              </div>
              <div className={classnames(
                // 'container__cards',
                styles.filtersContainerCards,
              )}
              >
                <FilterCard
                  title="Carriers"
                  link="Carriers"
                  amount={this.state.carriers.length}
                  total={this.props.availableCarriers.length}
                  onClick={this.handleFiltersCardClick}
                />
                <FilterCard
                  title="Shipment states"
                  link="States"
                  amount={this.state.states.length}
                  total={availableStates.length}
                  onClick={this.handleFiltersCardClick}
                />
                <FilterCard
                  title="Origin country"
                  link="Origins"
                  amount={this.state.origins.length}
                  total={countries.length}
                  onClick={this.handleFiltersCardClick}
                />
                <FilterCard
                  title="Destination country"
                  link="Destinations"
                  amount={this.state.destinations.length}
                  total={countries.length}
                  onClick={this.handleFiltersCardClick}
                />
                <FilterCardMetadata
                  title="Metadata"
                  link="Metadata"
                  metaKey={this.state.metadataKey}
                  metaValue={this.state.metadataValue}
                  onClick={this.handleFiltersCardClick}
                />
              </div>
              <div className={classnames(
                'container__cards',
                styles.condensedCards,
              )}
              >
                <DateFilter
                  name="registeredDate"
                  options={['any', 'today', 'yesterday', 'last3days', 'last7days', 'custom']}
                  values={this.state.registeredDate}
                  handleChange={this.handleChange}
                  handleDateRangePick={this.handleDateRangePick}
                />
                <DateFilter
                  name="orderDate"
                  options={['any', 'today', 'yesterday', 'last3days', 'last7days', 'custom']}
                  values={this.state.orderDate}
                  handleChange={this.handleChange}
                  handleDateRangePick={this.handleDateRangePick}
                />
                <DateFilter
                  name="shippedDate"
                  options={['any', 'today', 'yesterday', 'last3days', 'last7days', 'custom']}
                  values={this.state.shippedDate}
                  handleChange={this.handleChange}
                  handleDateRangePick={this.handleDateRangePick}
                />
                <DateFilter
                  name="promisedDate"
                  options={['any', 'today', 'yesterday', 'tomorrow', 'next3days', 'next7days', 'custom']}
                  values={this.state.promisedDate}
                  handleChange={this.handleChange}
                  handleDateRangePick={this.handleDateRangePick}
                />
                <DateFilter
                  name="deliveryDate"
                  options={['any', 'today', 'yesterday', 'custom']}
                  values={this.state.deliveryDate}
                  handleChange={this.handleChange}
                  handleDateRangePick={this.handleDateRangePick}
                />
                <DateFilter
                  name="estimatedDate"
                  options={['any', 'today', 'yesterday', 'tomorrow', 'next3days', 'next7days', 'custom']}
                  values={this.state.estimatedDate}
                  handleChange={this.handleChange}
                  handleDateRangePick={this.handleDateRangePick}
                />
                <RadioFilter
                  name="shippingDirection"
                  options={['all', 'delivery', 'return', 'pick_up', 'drop_off', 'drop_off_pick_up', 'return_drop_off']}
                  value={this.state.shippingDirection}
                  handleChange={this.handleRadioChose}
                />
              </div>
            </div>
            <div className={styles.buttonsRow}>
              <Button
                className={classnames(styles.button)}
                onClick={this.handleSubmit}
              >
                Apply filters & close
              </Button>
              <Button
                className={classnames(styles.button)}
                theme="grey"
                onClick={this.handleCancel}
              >
                Cancel
              </Button>
              {/* eslint-disable-next-line react/button-has-type */}
              <button
                className={classnames(styles.clearBtn)}
                onClick={this.handleReset}
              >
                Clear
              </button>
            </div>
          </div>
        );
    }
  }
}

const mapStateToProps = state => ({
  availableCarriers: state.carriers.carriers.map(carrier => ({ label: carrier.name, value: carrier.key })),
  carriersLoading: state.carriers.loading,
  sidebarExpanded: state.sidebarMenu.sidebarExpanded,
});

export default connect(mapStateToProps)(withRouter(Filters));
