import React from 'react';

/* eslint-disable */
export default props => (
  <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 71.2 62.3'>
    <path d='M34.8,49.1c0.4,0.4,1.2,0.4,1.6,0l10.8-10.8l9.7-9.7c0.7-0.7,0.2-1.9-0.8-1.9h-8c-0.6,0-1.1-0.5-1.1-1.1V1.1 c0-0.6-0.5-1.1-1.1-1.1H25.4c-0.6,0-1.1,0.5-1.1,1.1v24.4c0,0.6-0.5,1.1-1.1,1.1h-8c-1,0-1.5,1.2-0.8,1.9l9.7,9.7L34.8,49.1z'
    />
    <path d='M65,38.5v16.5c0,0.6-0.5,1.1-1.1,1.1H7.3c-0.6,0-1.1-0.5-1.1-1.1V38.5c0-0.6-0.5-1.1-1.1-1.1H1.1 c-0.6,0-1.1,0.5-1.1,1.1v20.7v2c0,0.6,0.5,1.1,1.1,1.1h2h65h2c0.6,0,1.1-0.5,1.1-1.1v-2V38.5c0-0.6-0.5-1.1-1.1-1.1h-3.9 C65.5,37.3,65,37.8,65,38.5z'
    />
  </svg>
)

/* eslint-enable */
