import React from 'react';
import cx from 'classnames';
import styles from './IconButton.scss';

function IconButton({ children, onClick }) {
  return (
    <div className={cx(styles.container)} role="button" onClick={onClick}>
      {children}
    </div>
  );
}

export default IconButton;
