import React from 'react';
import API from 'helpers/API';
import NotificationConfigurationForm
  from 'routes/Settings/Notifications/NotificationConnectors/NotificationConfigurationForm';
import typography from '../../../../helpers/appTypography';

function CreateNotificationConfiguration({ match: { params: { notificationSinkId } }, history }) {
  const createFromNotificationSink = async () => {
    const notificationSinkResponse = await API.getNotificationSink(notificationSinkId);
    return {
      ...notificationSinkResponse.sink,
      values: notificationSinkResponse.sink.properties,
      name: '',
      sinkName: notificationSinkResponse.sink.name,
    };
  };
  const saveSinkConfiguration = async (sinkConfiguration) => {
    const valuesArr = sinkConfiguration.values.map(x => ({
      name: x.name,
      value: x.value,
    }));

    await API.createSinkConfiguration({
      notificationSinkId,
      name: sinkConfiguration.name,
      values: valuesArr,
    });
  };

  return (
    <NotificationConfigurationForm
      load={createFromNotificationSink}
      save={saveSinkConfiguration}
      title={typography.createNotificationConnection.title}
      mode="Add"
      history={history}
    />
  );
}

export default CreateNotificationConfiguration;
