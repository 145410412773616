import {
  WEBHOOK_FETCH,
  WEBHOOK_SUCCESS,
  CREATE_WEBHOOK_FETCH,
  CREATE_WEBHOOK_SUCCESS,
  TOGGLE_WEBHOOK,
  REVOKE_WEBHOOK_SUCCESS,
} from 'actions/types';

const initialState = {
  loading: false,
  webhooks: [],
  versions: [],
  error: '',
  webhookActive: true,
};

function webHooksStatesReducer(state = initialState, action) {
  switch (action.type) {
    case WEBHOOK_FETCH:
    case CREATE_WEBHOOK_FETCH:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case WEBHOOK_SUCCESS:
      return {
        webhooks: action.webhooks,
        versions: action.versions || [],
        loading: false,
        error: '',
      };
    case REVOKE_WEBHOOK_SUCCESS:
      return {
        ...state,
        webhooks: [
          ...state.webhooks.filter(webhook => webhook.configuration_id !== action.id),
        ],
      };
    case CREATE_WEBHOOK_SUCCESS:
      return {
        webhooks: [
          ...state.webhooks,
          action.key,
        ],
        loading: false,
        error: '',
      };
    case TOGGLE_WEBHOOK:
      return {
        ...state,
        webhooks: [
          ...action.webhooks,
        ],
        webhookActive: !state.webhookActive,
      };
    default:
      return state;
  }
}

export default webHooksStatesReducer;
