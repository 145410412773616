import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import ReactTable from 'react-table';
import format from 'date-fns/format';
import reasons from './reasons';
import styles from './suppressionList.scss';
import { ReactivateButton } from './buttons';
import TablePaginator from '../../../../../components/TablePaginator';

const SuppressionList = ({ suppressions, isLoading, onReactivateClick }) => {
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [pages, setPages] = useState(Math.floor(suppressions.total / pageSize) + 1);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage + 1);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPages(Math.floor(suppressions.total / newPageSize) + 1);
    setCurrentPage(1);
  };

  const CellComponent = (value) => (
    <div className={styles.cellWrapper}>
      <span>{value}</span>
    </div>
  );

  const prettyDate = (date) => (date ? format(date, 'DD MMM YYYY') : '');

  const deleteSuppressionReason = (suppressionReason) => reasons.find(x => x.key === suppressionReason).tooltip;

  const columns = useMemo(() => {
    const headerStyle = {
      justifyContent: 'flex-start',
      fontSize: '18px',
      fontWeight: 'bold',
      background: 'white',
      borderBottom: '1px solid gray',
    };

    const cellStyle = {
      display: 'flex',
      alignItems: 'center',
      background: 'white',
      cursor: 'default',
    };
    return [
      {
        // 'Reference' is the first from custom_references and NOT Sorted reference. as told in work-item
        Header: 'Email Address',
        id: 'emailAddress',
        accessor: 'emailAddress',
        Cell: (cellInfo) => CellComponent(cellInfo.value),
        headerStyle,
        style: cellStyle,
        minWidth: 250,
      },
      {
        Header: 'Reason',
        accessor: 'suppressionReason',
        Cell: (cellInfo) => CellComponent(reasons.find((reason) => reason.key === cellInfo.value).value ?? ''),
        headerStyle,
        style: cellStyle,
        minWidth: 225,
      },
      {
        Header: 'Date Added',
        accessor: 'createdAt',
        Cell: (cellInfo) => CellComponent(prettyDate(cellInfo.value)),
        headerStyle,
        style: cellStyle,
        minWidth: 140,
      },
      {
        Header: 'Actions',
        Cell: (cellInfo) => (
          <ReactivateButton
            isDisabled={cellInfo.original.suppressionReason === 'SpamComplaint'}
            onClick={() => onReactivateClick(id, cellInfo.original)}
            tooltip={deleteSuppressionReason(cellInfo.original.suppressionReason)}
          >Reactivate
          </ReactivateButton>
        ),
        headerStyle,
        style: cellStyle,
        minWidth: 150,
        maxWidth: 200,
      },
    ];
  }, [id, onReactivateClick]);

  const data = useMemo(() => suppressions.suppressions, [suppressions.suppressions]);

  return (
    <div className={styles.suppressionsContainer}>
      {(!data || data.length === 0) && (
        <div>There were no suppressions found based on filters applied</div>
      )}
      {data && data.length > 0 && (
        <ReactTable
          data={data}
          columns={columns}
          page={currentPage - 1}
          onPageChange={(e) => handlePageChange(e)}
          onPageSizeChange={(e) => handlePageSizeChange(e)}
          showPageSizeOptions={false}
          pageSizeOptions={[5, 20, 50, 100, 200]}
          pageSize={pageSize}
          pages={pages}
          defaultPageSize={pageSize}
          sortable
          defaultSorted={[{ id: 'createdAt', desc: true }]}
          loading={isLoading}
          totalRows={suppressions.total}
          footerText="suppressions"
          className={styles.ReactTableCustom}
          minRows={0}
          PaginationComponent={TablePaginator}
          trackingComponentName="Suppression Table"
        />
      )}
    </div>
  );
};

export default SuppressionList;
