import React from 'react';
/* eslint-disable */

export default props => (
  <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 37.8 68.2'>
    <path d='M36.5,31.5L6.6,1.6c-1.4-1.4-3.8-1.4-5.2,0C0.7,2.3,0.3,3.2,0.3,4.2c0,1,0.4,1.9,1.1,2.6l27.3,27.3L1.4,61.4	C0.7,62.1,0.3,63,0.3,64c0,1,0.4,1.9,1.1,2.6C2.1,67.3,3,67.7,4,67.7s1.9-0.4,2.6-1.1l29.9-29.9C37.9,35.3,37.9,32.9,36.5,31.5z'
    />
  </svg>
)

/* eslint-enable */
