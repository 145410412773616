import React from 'react';

/* eslint-disable */
export default props => (
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 697 146.8">
<g>
	<path d="M379.4,113.6c0,4.9,4,8.9,8.9,8.9c5,0,9-4,9-8.9c0-4.9-4-8.9-9-8.9C383.4,104.8,379.4,108.8,379.4,113.6 M340.1,97.7c0-8.6,5.3-13.5,11.9-13.5c6.6,0,11.8,4.8,11.8,13.4c0,8.7-5.2,13.8-11.8,13.8C345.2,111.4,340.1,106.3,340.1,97.7 M376.4,51.2h-12.8v27.4c-1.4-2.5-5.3-6-13.6-6c-13.6,0-23,11-23,25.1c0,14.5,9.8,25.3,23.4,25.3c6.4,0,11.3-2.9,13.5-6.7 c0,2.2,0.3,4.6,0.5,5.6h12.4c-0.2-2-0.4-5.5-0.4-8.7V51.2z M313.2,104.9c-1.6,4.3-4.9,7.3-10.9,7.3c-6.4,0-11.8-4.6-12.1-10.9h34.4 c0-0.2,0.2-2.1,0.2-4c0-15.4-8.9-24.9-23.7-24.9c-12.3,0-23.6,10-23.6,25.3c0,16.2,11.6,25.7,24.8,25.7c11.8,0,19.4-6.9,21.9-15.2 L313.2,104.9z M290.5,92.4c0.3-4.4,4-9.5,10.7-9.5c7.4,0,10.5,4.7,10.7,9.5H290.5z M267.6,59.5h-11.7v6.7c0,4.3-2.3,7.6-7.4,7.6 h-2.4v11.5h8.7v22.4c0,9.3,5.9,14.8,15.2,14.8c3.8,0,6.2-0.7,7.3-1.2v-10.7c-0.7,0.2-2.4,0.4-4,0.4c-3.7,0-5.7-1.4-5.7-5.6V85.3 h9.7V73.8h-9.7V59.5z M243.5,73.6c-1-0.1-2-0.2-3-0.2c-4.1,0-10.7,1.2-13.7,7.5v-7.1h-12.6v48h13v-22c0-10.3,5.8-13.6,12.4-13.6 c1.2,0,2.4,0.1,3.9,0.4V73.6z M186.6,72.3c-14.4,0-25.2,10.6-25.2,25.5c0,14.7,10.8,25.5,25.2,25.5c14.4,0,25.2-10.7,25.2-25.5 C211.8,83,200.9,72.3,186.6,72.3 M186.6,111.5c-6.3,0-12.2-4.7-12.2-13.7c0-9.1,5.9-13.7,12.2-13.7c6.3,0,12.2,4.6,12.2,13.7 C198.8,106.9,192.9,111.5,186.6,111.5 M157.9,69.1c-1.6-7.8-8-18-24.8-18c-13.1,0-24.1,9.4-24.1,21.7c0,10.4,7.1,17.6,18.2,19.8 l9.7,2c5.4,1.1,8.4,4.2,8.4,8.2c0,4.9-4,8.5-11.2,8.5c-9.8,0-14.5-6.2-15.1-13l-12.5,3.3c1.1,9.9,9.2,21.7,27.5,21.7 c16.1,0,25-10.7,25-21.4c0-9.8-6.7-18-19.3-20.4l-9.7-1.9c-5.1-1-7.5-4-7.5-7.8c0-4.6,4.3-8.8,10.8-8.8c8.8,0,11.9,6,12.6,9.8 L157.9,69.1z"/>
	<path d="M85.1,87.9L72.4,33c0.1-0.1,0.1-0.2,0.2-0.2l0,0c1-1.3,2.6-2.2,4.3-2.3c3.2-0.1,5.9,2.4,6.1,5.6L85.1,87.9z M62.6,13.8c-1-4.1-5.1-6.6-9.2-5.6c-2.3,0.6-4,2.1-5,4l0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c-0.9,1.7-1.5,3.9-1.8,6.2l28.8,46.1 L62.6,13.8z M69.7,66.1 M69.7,66.1l-37-57.1C30.2,5,25,3.8,21.3,6.2c-2.1,1.3-3.3,3.5-3.6,5.9h0c0,0,0,0.1,0,0.1c0,0,0,0,0,0 c-1.6,11,11.7,31.1,11.7,31.1l10,15.4c1.8-0.2,3.5-0.3,5.4-0.3c5.4,0,10.6,1,15.4,2.8l-1.1,5.1c-4.4-1.7-9.3-2.7-14.3-2.7 c-20.7,0-37.7,16.1-39.1,36.5h18.3C25.2,89.7,34,81.6,44.7,81.6c11.6,0,21,9.4,21,21s-9.4,21-21,21c-10.6,0-19.3-7.8-20.8-18H5.7 c1.4,20.4,18.4,36.5,39.1,36.5c21.7,0,39.2-17.6,39.2-39.2c0-6.1-1.5-11.9-3.9-17C74.6,73.8,69.7,66.1,69.7,66.1z"/>
	<g>
		<path d="M421.1,94.9h-7.1v27h-13.7V51.8h27.4c13.6,0,22.3,9.4,22.3,21.6c0,9.8-5.7,17.2-14.9,19.9l15,28.7h-15.2 L421.1,94.9z M425,83.2c6.9,0,11-4,11-9.7c0-5.9-4.1-9.8-11-9.8h-11.1v19.5H425z"/>
		<path d="M457.1,121.9V51.8H501v12.9h-30.3v16h27.4v12.2h-27.4v16.2H501v12.9H457.1z"/>
		<path d="M551.4,105.9H523l-5.8,16h-14.4l26.9-70.1h15.8l26.7,70.1h-14.8L551.4,105.9z M527.5,93.4h19.4l-9.7-26.5 L527.5,93.4z"/>
		<path d="M568.6,86.9c0-22.3,16.9-36.6,35.7-36.6c19.4,0,29.4,11.9,32.1,23.2l-12.9,4.1c-1.7-6.7-7.1-14.1-19.3-14.1 c-10.5,0-21.6,7.5-21.6,23.4c0,14.9,10.5,23.1,21.8,23.1c12,0,17.8-7.8,19.7-14.3l12.8,3.9c-2.7,10.8-12.8,23.8-32.4,23.8 C585,123.4,568.6,109.3,568.6,86.9z"/>
		<path d="M672.1,64.8v57.2h-13.7V64.8h-22.2v-13h58.1v13H672.1z"/>
	</g>
</g>
</svg>

)

/* eslint-enable */
