import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ShipmentFilterForm from 'containers/ShipmentFilterForm';
import composeShipmentFilterData from 'helpers/composeShipmentFilterData';
import CardShell from 'components/CardShell';

class EditShipmentFilterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matchedShipmentFilter: null,
    };
  }

  componentDidMount() {
    const { match: { params: { shipmentFilterId } }, shipmentFilters } = this.props;
    const matchedShipmentFilter = shipmentFilters
      && shipmentFilterId && shipmentFilters.find(key => key.id === shipmentFilterId);

    if (!matchedShipmentFilter) {
      this.backToShipmentFilterList();
    } else {
      this.setState({
        matchedShipmentFilter,
      });
    }
  }

  getShipmentGroupInitialData = () => {
    const { availableStates } = this.props;
    const { matchedShipmentFilter } = this.state;
    return composeShipmentFilterData(availableStates, matchedShipmentFilter);
  };

  backToShipmentFilterList = () => {
    this.props.history.push('/settings/shipment-filters');
  };

  render() {
    const { matchedShipmentFilter } = this.state;
    return (
      <>
        <h2>Editing Shipment Filter</h2>
        <div className="container__cards-large">
          <CardShell
            noOverflow
          >
            {matchedShipmentFilter && (
              <ShipmentFilterForm
                editMode
                initialData={this.getShipmentGroupInitialData()}
                flipCard={this.backToShipmentFilterList}
              />
            )}
          </CardShell>
        </div>
      </>
    );
  }
}

const mapStatetoProps = ({ shipmentFilters, availableStates }) => ({
  availableStates: availableStates.states,
  shipmentFilters: shipmentFilters.groups,
});

export default withRouter(connect(mapStatetoProps)(EditShipmentFilterPage));
