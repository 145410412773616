import React, { Component } from 'react';
import { connect } from 'react-redux';
import { event as gaEvent } from 'react-ga';
import propTypes from 'prop-types';
import { logout as logoutAction } from 'actions';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import {
  List, ListItem, ListItemText, Typography,
} from '@material-ui/core';
import NavItem from './NavItem';
import styles from './styles.scss';
import MainMenuItems from './MainMenuItems';
import SortedLogoTickStackColour from '../../images/SortedLogo-TickStackColour.svg';
import SortedLogoHorizontalColourWhite from '../../images/SortedLogoHorizontal-ColourWhite.svg';
import Svg from '../Svg';
import useStyles from './MainMenuItems/useStyles';
import DropdownItemUpdated from './DropdownItem/DropDownItemUpdated';
import setSidebarMenuValue from '../../actions/sidebarMenuAction';

export class CSidebar extends Component {
  constructor() {
    super();
    const persistedValue = localStorage.getItem('sidebar.expanded');
    this.state = {
      expanded: persistedValue === null ? false : persistedValue === 'true',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.expanded !== prevState.expanded) {
      // menu width has affected screen size so publish a resize event. Mapbox will react to this and
      // update it's dimensions
      window.dispatchEvent(new Event('resize'));
      setSidebarMenuValue(this.state.expanded);
    }
  }

  getCurrentUrl = () => {
    const { pathname } = this.props;
    return pathname.split('/').filter((str) => str)[0];
  };

  handleExpandCollapse = () => {
    this.setState(
      (state) => ({ expanded: !state.expanded }),
      () => {
        const { expanded } = this.state;

        gaEvent({
          category: 'Navigation',
          action: `${expanded ? 'Expand' : 'Collapse'}  navigation with +/-`,
        });
        localStorage.setItem('sidebar.expanded', expanded);
        this.props.setSidebarMenuValue(expanded);
      },
    );
  };

  render() {
    const { expanded } = this.state;
    const currentUrl = this.getCurrentUrl();
    const path = window.location.pathname;
    const updatedPath = path.match(/^\/([\w\d-]+)/);
    const firstMatchedPath = updatedPath ? updatedPath[0] : '';

    return (
      <aside
        className={classNames(styles.layoutSidebar, {
          [styles.expanded]: expanded,
        })}
      >
        <div className={styles.cornerLogo}>
          <NavLink to="/dashboards/states">
            {expanded
              ? <SortedLogoHorizontalColourWhite className={styles.wideLogo} alt="SortedLogoHorizontalColourWhite" />
              : <SortedLogoTickStackColour className={styles.logo} alt="SortedLogoTickStackColour" />}
          </NavLink>
        </div>

        <MainMenuItems
          currentUrl={currentUrl}
          expanded={expanded}
        />

        <div className={styles.bottomItems}>
          {
            expanded && (
              <List>
                <NavLink to="/settings" style={useStyles.linksStyle}>
                  <ListItem
                    className={classNames(styles.listItem, ` ${(firstMatchedPath === '/settings') ? styles.active : ''}`)}
                  >
                    <Svg name="settings" className={styles.iconSVG} />
                    {expanded && (
                      <ListItemText
                        primary={(
                          <Typography style={useStyles.parentMenuItem}>
                            Settings
                          </Typography>
                        )}
                      />
                    )}
                  </ListItem>
                </NavLink>
              </List>
            )
          }
          {
            !expanded && (
              <>
                <NavItem title="Settings" url="settings" currentUrl={currentUrl} expanded={expanded}>
                  <DropdownItemUpdated baseUrl="/settings">
                    Settings
                  </DropdownItemUpdated>
                </NavItem>
              </>
            )
          }
          <div className={styles.expandButtons}>
            <div onClick={this.handleExpandCollapse} role="button">
              {expanded ? <span>&laquo;</span> : <span>&raquo;</span>}
            </div>
          </div>
        </div>
      </aside>
    );
  }
}

CSidebar.propTypes = {
  pathname: propTypes.string.isRequired,
};

export const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logoutAction()),
  setSidebarMenuValue: (expanded) => dispatch(setSidebarMenuValue(expanded)),
});

const Sidebar = connect(null, mapDispatchToProps)(CSidebar);
export default Sidebar;
