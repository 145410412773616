export const StatusTimelineComponentType = 'Status timeline';
export const ImageComponentType = 'Image';
export const TextComponentType = 'Text';
export const EmptyComponentType = 'Empty';
export const ImageAndTextComponentType = 'Image + text';

const ComponentTypes = [
  StatusTimelineComponentType,
  ImageComponentType,
  TextComponentType,
  EmptyComponentType,
  ImageAndTextComponentType,
];

export default ComponentTypes;
