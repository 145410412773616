import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './styles.scss';

const ColouredDot = ({ theme, large, medium }) => (
  <span className={cx(styles.dot, styles[theme], large ? styles.large : '', medium ? styles.medium : '')} />);

ColouredDot.propTypes = {
  theme: PropTypes.oneOf(['red', 'amber', 'green']).isRequired,
};

export default ColouredDot;
