import React from 'react';
import styles from './styles.scss';

const AddBtnRedesigned = ({ onClick, children }) => (
  <button
    type="button"
    onClick={onClick}
    className={styles.addFilterButton}
  >{children}
  </button>
);

export default AddBtnRedesigned;
