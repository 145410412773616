const validateEmail = (email) => String(email)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

export const emailListValid = (list) => {
  if (list == null) {
    return [];
  }

  if (list === '') {
    return [];
  }

  const items = String(list).split('\n');

  return items.filter(item => item.trim() !== '' && !validateEmail(item.trim()))
    .map(item => (`${item.trim().slice(0, 46)}${item.trim().length > 47 ? '...' : ''} is not a valid email`));
};

export const parseEmailList = (list) => {
  if (list == null) {
    return [];
  }

  if (list === '') {
    return [];
  }
  return String(list).split('\n')
    .map(item => item.trim())
    .filter(item => item !== '');
};
