const initialState = {
  loading: true,
  colours: {
    red: [],
    amber: [],
    green: [],
  },
};

export default function shipmentStatesReducer(state = initialState, action) {
  switch (action.type) {
    case 'SHIPMENT_SUMMARIES_FETCH':
      return {
        ...state,
        loading: true,
      };
    case 'SHIPMENT_SUMMARIES_SUCCESS':
      return {
        ...state,
        colours: action.states,
        loading: false,
      };
    default:
      return state;
  }
}
