import React, { Component } from 'react';
import styles from './styles.scss';

class EditDropdown extends Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef = (node) => { this.wrapperRef = node; }

  handleClickOutside = (e) => {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      this.props.toggleEditDropDown();
    }
  };

  render() {
    const {
      canMoveUp,
      canMoveDown,
      deleteFn,
      alterOrder,
    } = this.props;

    return (
      <div ref={this.setWrapperRef} className={styles.buttonHolder}>
        {canMoveUp && <button type="button" onClick={() => alterOrder('up')}>Move up</button>}
        {canMoveDown && <button type="button" onClick={() => alterOrder('down')}>Move down</button>}
        <button type="button" onClick={deleteFn}>Delete</button>
      </div>
    );
  }
}

export default EditDropdown;
