import React, { Component } from 'react';
import vocabulary from 'constants/vocabulary';
import Svg from 'components/Svg';
import cx from 'classnames';
import styles from './styles.scss';

class RadioFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggled: false,
    };
  }

  render() {
    const { value, name, ...props } = this.props;

    return (
      <form className={styles.dateFilterColumn}>
        <div
          role="button"
          onClick={() => this.setState(prevState => ({ isToggled: !prevState.isToggled }))}
          className={styles.dateAreaToggleButton}
        >
          <h4 className="mb-2">{vocabulary[name]}</h4>
          <Svg
            name="angleDown"
            className={cx(styles.downChevron, this.state.isToggled ? styles.downChevronActive : '')}
          />
        </div>
        <div className={cx(styles.dateAreaToggle, this.state.isToggled ? styles.dateAreaToggleActive : '')}>
          {props.options.map((option, i) => (
            <div className={styles.controlGroup} key={option}>
              <input
                name={name}
                type="radio"
                className={styles.radio}
                id={`${name}${option}`}
                value={option}
                checked={value ? value === option : i === 0}
                onChange={props.handleChange}
              />
              <label htmlFor={`${name}${option}`}>{vocabulary[option]}</label>
            </div>
          ))}
        </div>
      </form>
    );
  }
}

export default RadioFilter;
