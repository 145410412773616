import React, { Component } from 'react';
import CardShell from 'components/CardShell';
import CreateCard from 'components/CreateCard';
import { compareArrayByOrder } from 'helpers/common';
import Hr from 'components/CardShell/Hr';
import { ValidationErrors } from 'components/InputWithValidation';
import GridEditRow from './GridEditRow';
import GridAddRowPopup from './GridAddRowPopup';
import GridEditColumn from './GridEditColumn';

import styles from '../style.scss';

const maximumRowsInLayout = 6;
class GridEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddPopupOpened: false,
      isEditing: false,
      editingRowID: null,
      editingRowItemDetails: null,
    };
  }

  toggleAddRowPopup = () => this.setState(prevState => ({ isAddPopupOpened: !prevState.isAddPopupOpened }));

  toggleEditColumn = (item, id) => {
    this.setState(prevState => ({
      isEditing: !prevState.isEditing,
      editingRowItemDetails: item,
      editingRowID: id,
    }));
  };

  isReachedMaximumAmountOfRows = () => {
    const { layoutType, layouts } = this.props;
    const currentLayout = layouts.find(layout => layout.layoutType === layoutType);
    return currentLayout.rows.length >= maximumRowsInLayout;
  };

  handleAddRow = (newRow) => {
    const { layouts, layoutType } = this.props;
    const currentLayout = layouts.find(layout => layout.layoutType === layoutType);
    const newRowWithOrder = {
      ...newRow,
      order: currentLayout.rows.length,
    };
    const updatedLayout = {
      ...currentLayout,
      rows: [...currentLayout.rows],
    };
    updatedLayout.rows.push(newRowWithOrder);
    this.updateLayouts(updatedLayout);
  };

  updateRowItem = (updatedRowItem) => {
    const { layouts, layoutType } = this.props;
    const currentLayout = layouts.find(layout => layout.layoutType === layoutType);
    const rowsExceptEditingRow = currentLayout.rows.filter(row => row.id !== this.state.editingRowID);
    const currentRow = currentLayout.rows.find(row => row.id === this.state.editingRowID);
    const currentRowItemsExceptUpdatedRowItem = currentRow.rowItems.filter(rowItem => rowItem.id !== updatedRowItem.id);
    const updatedRow = {
      ...currentRow,
      rowItems: [...currentRowItemsExceptUpdatedRowItem, updatedRowItem],
    };
    this.updateRows([...rowsExceptEditingRow, updatedRow]);
  };

  updateRows = (updatedRows) => {
    const { layouts, layoutType } = this.props;
    const currentLayout = layouts.find(layout => layout.layoutType === layoutType);
    const updatedLayout = {
      ...currentLayout,
      rows: updatedRows,
    };
    this.updateLayouts(updatedLayout);
  };

  updateLayouts = (updatedLayout) => {
    const { onChange, layoutType, layouts } = this.props;
    onChange('layouts', [...layouts.filter(layout => layout.layoutType !== layoutType), updatedLayout]);
  };

  isFirstRow(rowId) {
    return this.props.layouts.some(l => l.rows && l.rows[0] && l.rows[0].id === rowId);
  }

  render() {
    const {
      name,
      layouts,
      layoutType,
      errors,
      innerRefs,
    } = this.props;
    const { isAddPopupOpened, isEditing, editingRowItemDetails } = this.state;
    const layout = layouts.find(selectedLayout => selectedLayout.layoutType === layoutType);
    return (
      <>
        <CardShell noOverflow>
          <div className={styles.cardInner}>
            <div className={styles.cardInnerFix}>
              <h3 className={styles.title}>{name}</h3>
              <ValidationErrors errors={errors} name="rows" />
              <Hr />
              {layout && layout.rows && layout.rows.sort(compareArrayByOrder).map(row => (
                <GridEditRow
                  key={row.id}
                  id={row.id}
                  layoutRows={layout.rows}
                  rowItems={row.rowItems}
                  toggleEditColumn={this.toggleEditColumn}
                  order={row.order}
                  update={this.updateRows}
                  layoutType={layoutType}
                />
              ))}
              {!this.isReachedMaximumAmountOfRows() && (
                <div
                  className={styles.formGroup}
                  role="button"
                  onClick={() => this.toggleAddRowPopup()}
                  tabIndex="0"
                  ref={innerRefs.rows}
                >
                  <CreateCard text="Add a row" thin />
                </div>
              )}
            </div>
          </div>
        </CardShell>
        {isEditing && (
          <GridEditColumn
            globalFont={this.props.globalFont}
            layoutType={layoutType}
            toggleEditColumn={this.toggleEditColumn}
            data={editingRowItemDetails}
            update={this.updateRowItem}
            rowId={this.state.editingRowID}
            hasStatusTimelineOption={this.isFirstRow(this.state.editingRowID)}
          />
        )}
        {isAddPopupOpened && (
          <GridAddRowPopup
            toggleAddRow={this.toggleAddRowPopup}
            update={this.handleAddRow}
            layoutType={layoutType}
          />
        )}
      </>
    );
  }
}

export default GridEdit;
