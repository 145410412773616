import React from 'react';
import propTypes from 'prop-types';
import styles from './styles.scss';
import ReactDashboards from './ReactDashboards';
import ProDashboards from './ProDashboards';
import { getActiveProducts } from '../../../helpers/auth';

function MainMenuItems({
  expanded,
  currentUrl,
}) {
  const activeProducts = getActiveProducts();
  const showExplicitDashboardTitles = activeProducts.includes('PRO') && activeProducts.includes('REACT');

  return (
    <div className={styles.topItems}>

      {activeProducts.includes('PRO')
        && (
          <ProDashboards
            expanded={expanded}
            currentUrl={currentUrl}
            title={showExplicitDashboardTitles ? 'PRO' : 'dashboards'}
          />
        )}
      {activeProducts.includes('REACT')
        && (
          <ReactDashboards
            expanded={expanded}
            currentUrl={currentUrl}
            title={showExplicitDashboardTitles ? 'REACT' : 'dashboards'}
          />
        )}
    </div>
  );
}

MainMenuItems.propTypes = {
  expanded: propTypes.bool.isRequired,
  currentUrl: propTypes.string,
};

MainMenuItems.defaultProps = {
  currentUrl: '',
};

export default MainMenuItems;
