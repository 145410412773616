import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../../../helpers/API';
import Spinner from '../../../../../components/Spinner';
import DatesDropdown from '../DatesDropdown';
import BarChart from '../../barCharts/BounceBarChart';
import { customCalculateStartDate, customPeriods } from './customCalculateStartDate';
import TabErrorMessage from '../TabErrorMessage';
import EmailStatisticsContext from '../../EmailStatisticsContext';

export default function BounceStatistics() {
  const { id } = useParams();
  const [isLoadingBounceStatistics, setIsLoadingBounceStatistics] = React.useState(true);
  const [isBounceStatisticsError, setIsBounceStatisticsError] = React.useState(false);
  const [bounceStatistics, setBounceStatistics] = React.useState();
  const [selectedPeriod, setSelectedPeriod] = React.useState('3');
  const { setPageTitle, setPageMessage, setSelectedServerId } = useContext(EmailStatisticsContext);

  setTimeout(() => {
    setSelectedServerId(id);
    setPageTitle('Bounced Emails');
    setPageMessage('This page contains high level email bounce statistics. You can choose which date range you want to view by using the datepickers above the table.');
  });

  React.useEffect(() => {
    const fetchBounceStatistics = async () => {
      try {
        const startDate = customCalculateStartDate(selectedPeriod);
        const endDate = new Date();
        const response = await API.getEmailBounceStatistics(id, startDate, endDate);
        setBounceStatistics(response.emailStatisticsBounceReasons);
      } catch (e) {
        setIsBounceStatisticsError(true);
      }
      setIsLoadingBounceStatistics(false);
    };

    fetchBounceStatistics();
  }, [id, selectedPeriod]);

  return (
    <>
      {isLoadingBounceStatistics && !bounceStatistics && <Spinner />}
      {!isLoadingBounceStatistics && isBounceStatisticsError && <TabErrorMessage />}
      {!isLoadingBounceStatistics && !isBounceStatisticsError && bounceStatistics && (
        <>
          <DatesDropdown
            setSelectedPeriod={setSelectedPeriod}
            selectedPeriod={selectedPeriod}
            customPeriods={customPeriods}
          />
          <BarChart bounces={bounceStatistics} dateKey={selectedPeriod} />
        </>
      )}
    </>
  );
}
