import React, { Component } from 'react';
import { connect } from 'react-redux';
import WebhookConfigForm from 'components/FormGroups/WebhookConfigForm';
import { event as gaEvent } from 'react-ga';
import {
  getWebhooks as getWebhooksAction,
} from 'actions/webhookActions';
import styles from './styles.scss';

class WebhookCreateForm extends Component {
  componentDidMount() {
    const { versions } = this.props;

    if (!versions || !versions.length) {
      this.props.getWebhooks();
    }
  }

  backToItems = () => {
    gaEvent({
      category: 'Webhook',
      action: 'back to landing page from create',
    });
    this.props.history.push('/settings/webhooks/');
  };

  render() {
    return (
      <div className={styles.webhookFormWrapper}>
        <h1 className="h2">Creating Webhook</h1>
        <div className="container__cards-large">
          <WebhookConfigForm flipCard={this.backToItems} locales={this.props.locales} />
        </div>
      </div>
    );
  }
}

const mapStatetoProps = state => ({
  locales: state.locales,
  versions: state.webhooksStates.versions,
});
const mapDispatchToProps = dispatch => ({
  getWebhooks: () => dispatch(getWebhooksAction()),
});

export default connect(mapStatetoProps, mapDispatchToProps)(WebhookCreateForm);
