import React from 'react';
import { connect } from 'react-redux';
import CardShell from 'components/CardShell';
import Configuration from './Configuration';
import TrackingService from './TrackingService';
import styles from './trackingServices.scss';
import TitleNavBlock from '../../../components/TitleNavBlock/TitleNavBlock';
import typography from '../../../helpers/appTypography';

export const CServicesList = (props) => {
  const trackingServicesWithConfigurations = props.keys.filter(x => x.configurations.length > 0);
  const any = trackingServicesWithConfigurations.length > 0;

  return (
    <div className={styles.listServices}>
      <TitleNavBlock
        title={typography.carriers.title}
        subTitle={typography.carriers.subTitle}
        onBack={() => window.history.back()}
      />
      <div className={styles.outerWhiteContainer}>
        <div>{props.error}</div>
        {(any) && (
          <div>
            <h3 style={{ marginTop: 0 }}>Current Carrier Connectors</h3>
            <div className="container__cards-large">
              {trackingServicesWithConfigurations.map(key => key.configurations.map(x => (
                <CardShell key={x.configurationId}>
                  <Configuration key={x.configurationId} configuration={x} trackingService={key} />
                </CardShell>
              )))}
            </div>
          </div>
        ) }

        <h3>All Carrier Connectors available</h3>
        <div className="container__cards-large">
          {props.keys.map(key => (
            <CardShell key={key.trackingServiceId}>
              <TrackingService trackingService={key} />
            </CardShell>
          ))}
        </div>
      </div>
    </div>
  );
};

export default connect(state => ({
  error: state.trackingServices.error,
  keys: state.trackingServices.keys,
  createConfiguration: state.createConfiguration,
  editConfiguration: state.editConfiguration,
}))(CServicesList);
