/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from 'react';
import Card from 'components/Card';
import Metadata from 'images/Metadata.svg';
import styles from './FiltersCard.scss';

export default function FilterCardMetadata({
  title,
  link,
  metaKey,
  metaValue,
  onClick,
  ...props
}) {
  const hasMetaKeyOrValue = (metaKey && metaKey.length > 0) || (metaValue && metaValue.length > 0);
  const cardDescription = hasMetaKeyOrValue ? '1 Metadata filtered' : '0 Metadata filtered';
  const leftActionText = hasMetaKeyOrValue ? 'Edit' : 'Add';
  return (
    <Card
      title={title}
      value={<Metadata name={title} className={styles.icon} />}
      description={cardDescription}
      leftActionText={leftActionText}
      leftAction={() => onClick(link)}
      {...props}
    />
  );
}
