/**
 * This code is taken from open source lib https://github.com/eligrey/FileSaver.js/
 *
 * */
// eslint-disable-next-line
const saveAs = (function (view) {
  // IE <10 is explicitly unsupported
  if ((typeof view === 'undefined' || typeof navigator !== 'undefined') && /MSIE [1-9]\./.test(navigator.userAgent)) {
    return false;
  }
  const doc = view && view.document;
  // only get URL when necessary in case Blob.js hasn't overridden it yet
  const getURL = () => view.URL || view.webkitURL || view;
  const saveLink = doc && doc.createElementNS('http://www.w3.org/1999/xhtml', 'a');
  const canUseSaveLink = 'download' in saveLink;
  const click = (node) => {
    const event = new MouseEvent('click');
    node.dispatchEvent(event);
  };

  const isSafari = /constructor/i.test(view.HTMLElement) || view.safari;
  const isChromeIos = /CriOS\/[\d]+/.test(navigator.userAgent);
  const setImmediate = view.setImmediate || view.setTimeout;
  const throwOutside = ex => setImmediate(() => {
    throw ex;
  }, 0);
  const forceSaveableType = 'application/octet-stream';
  // the Blob API is fundamentally broken as there is no 'downloadfinished' event to subscribe to
  const arbitraryRevokeTimeout = 1000 * 40; // in ms
  const revoke = (file) => {
    const revoker = () => {
      if (typeof file === 'string') { // file is an object URL
        getURL().revokeObjectURL(file);
      } else { // file is a File
        file.remove();
      }
    };
    setTimeout(revoker, arbitraryRevokeTimeout);
  };

  const dispatch = (filesaver, eventTypes, event) => {
    const eventTypesArray = [].concat(eventTypes);
    let i = eventTypesArray.length;
    while (i = i - 1) { //eslint-disable-line
      const listener = filesaver[`on${eventTypesArray[i]}`];
      if (typeof listener === 'function') {
        try {
          listener.call(filesaver, event || filesaver);
        } catch (ex) {
          throwOutside(ex);
        }
      }
    }
  };

  const autoBom = (blob) => {
    if (/^\s*(?:text\/\S*|application\/xml|\S*\/\S*\+xml)\s*;.*charset\s*=\s*utf-8/i.test(blob.type)) {
      return new Blob([String.fromCharCode(0xFEFF), blob], { type: blob.type });
    }
    return blob;
  };
  // eslint-disable-next-line
  const FileSaver = function (blob, name, noAutoBom) {
    if (!noAutoBom) {
      blob = autoBom(blob); // eslint-disable-line
    }
    // First try a.download, then web filesystem, then object URLs
    const filesaver = this;
    const { type } = blob;
    const force = type === forceSaveableType;
    let objectUrl;
    const dispatchAll = () => dispatch(filesaver, 'writestart progress write writeend'.split(' '));
    // on any filesys errors revert to saving with object URLs
    const fsError = function () { // eslint-disable-line
      if ((isChromeIos || (force && isSafari)) && view.FileReader) {
        // Safari doesn't allow downloading of blob urls
        const reader = new FileReader();
        reader.onloadend = function () { // eslint-disable-line
          let url = isChromeIos ? reader.result : reader.result.replace(/^data:[^;]*;/, 'data:attachment/file;');
          const popup = view.open(url, '_blank');
          if (!popup) view.location.href = url; // eslint-disable-line
          url = null; // release reference before dispatching
          filesaver.readyState = filesaver.DONE;
          dispatchAll();
        };
        reader.readAsDataURL(blob);
        filesaver.readyState = filesaver.INIT;
        return;
      }
      // don't create more object URLs than needed
      if (!objectUrl) {
        objectUrl = getURL().createObjectURL(blob);
      }
      if (force) {
        view.location.href = objectUrl; // eslint-disable-line
      } else {
        const opened = view.open(objectUrl, '_blank');
        if (!opened) {
          view.location.href = objectUrl; // eslint-disable-line
        }
      }
      filesaver.readyState = filesaver.DONE;
      dispatchAll();
      revoke(objectUrl);
    };
    filesaver.readyState = filesaver.INIT;

    if (canUseSaveLink) {
      objectUrl = getURL().createObjectURL(blob);
      setImmediate(() => {
        saveLink.href = objectUrl;
        saveLink.download = name;
        click(saveLink);
        dispatchAll();
        revoke(objectUrl);
        filesaver.readyState = filesaver.DONE;
      }, 0);
      return;
    }

    fsError();
  };
  const FSproto = FileSaver.prototype;
  const saveAs = (blob, name, noAutoBom) => new FileSaver(blob, name || blob.name || 'download', noAutoBom); // eslint-disable-line

  // IE 10+ (native saveAs)
  if (typeof navigator !== 'undefined' && navigator.msSaveOrOpenBlob) {
    return (blob, name, noAutoBom) => { // eslint-disable-line
      name = name || blob.name || 'download'; // eslint-disable-line

      if (!noAutoBom) {
        blob = autoBom(blob); // eslint-disable-line
      }
      return navigator.msSaveOrOpenBlob(blob, name);
    };
  }

  FSproto.abort = () => {}; // eslint-disable-line
  FSproto.readyState = FSproto.INIT = 0; // eslint-disable-line
  FSproto.WRITING = 1; // eslint-disable-line
  FSproto.DONE = 2; // eslint-disable-line

  FSproto.error = FSproto.onwritestart = FSproto.onprogress = FSproto.onwrite = FSproto.onabort = FSproto.onerror = FSproto.onwriteend = null; // eslint-disable-line
  return saveAs; // eslint-disable-line
}(
  typeof self !== 'undefined' && self || typeof window !== 'undefined' && window || this // eslint-disable-line
));

export default saveAs;
