import React, { Component, Fragment } from 'react';
import CardShell from 'components/CardShell';
import DualButtons from 'components/DualButtons';
import API from 'helpers/API';
import Spinner from 'components/Spinner';
import InputWithValidation, { ValidationErrors } from 'components/InputWithValidation';
import {
  editMode,
  fillGroupings,
  readMode,
  trimGroupings,
  validate,
} from 'routes/Settings/ShipmentMetadataGroupings/shipmentMetadataGroupingsUtils';
import { hasErrors } from 'helpers/validateForm';
import MediaQuery from 'react-responsive';
import uiBreakpoints from 'helpers/uiBreakpoints';
import LayoutContext from 'components/Layout/LayoutContext';
import Switch from 'components/Switch';
import styles from './ShipmentMetadataGroupings.scss';
import TitleNavBlock from '../../../components/TitleNavBlock/TitleNavBlock';
import typography from '../../../helpers/appTypography';

class ShipmentMetadataGroupings extends Component {
  static contextType = LayoutContext;

  constructor(props, context) {
    super(props, context);
    this.state = {
      mode: readMode,
      isLoading: false,
      groupings: [],
      errors: validate([]),
      showErrors: false,
    };
  }

  saveGroupings = () => {
    this.setState(s => ({
      showErrors: true,
      errors: validate(trimGroupings(s.groupings)),
    }), async () => {
      if (!hasErrors(this.state.errors.rootErrors) && !hasErrors(this.state.errors.fieldErrors)) {
        this.setState({ isLoading: true });
        await API.updateShipmentMetadataGroupings(trimGroupings(this.state.groupings));
        this.context.setShipmentMetadataGroupings(trimGroupings(this.state.groupings));
        this.setState({
          isLoading: false,
          mode: readMode,
        });
      }
    });
  };

  handleEdit = () => {
    this.setState({
      mode: editMode,
      showErrors: false,
      errors: validate([]),
      groupings: fillGroupings(this.context.shipmentMetadataGroupings),
    });
  };

  handleCancel = () => {
    this.setState({
      mode: readMode,
      showErrors: false,
      errors: validate([]),
      groupings: fillGroupings(this.context.shipmentMetadataGroupings),
    });
  };

  handleSave = () => {
    this.saveGroupings();
  };

  handleChange = (e, i) => {
    const propName = e.target.name;
    const propValue = e.target.value;

    this.setState((s) => {
      const updatedGroupings = s.groupings.length ? s.groupings : fillGroupings(this.context.shipmentMetadataGroupings);
      const groupings = updatedGroupings.map(x => ({ ...x }));
      groupings[i][propName] = propValue;
      return {
        groupings,
        errors: validate(trimGroupings(groupings)),
      };
    });
  };

  toggleAutoloading = (index, isChecked) => {
    this.setState((s) => {
      const updatedGroupings = s.groupings.length ? s.groupings : fillGroupings(this.context.shipmentMetadataGroupings);
      const groupings = updatedGroupings.map(x => ({ ...x, performAutomaticLoading: false }));
      groupings[index].performAutomaticLoading = !isChecked;
      return {
        groupings,
        errors: validate(trimGroupings(groupings)),
      };
    });
  }

  renderBottomButtons = () => {
    switch (this.state.mode) {
      case readMode:
        return (
          <DualButtons
            leftFunction={this.handleEdit}
            leftText="Edit"
            rightFunctionDisabled
            rightText="Save"
            theme="rightPink"
          />
        );
      case editMode:
        return (
          <DualButtons
            leftFunction={this.handleCancel}
            leftText="Cancel"
            rightFunction={this.handleSave}
            rightText="Save"
            theme="rightPink"
            isSubmit
          />
        );
      default:
        return null;
    }
  };

  renderDesktopItem = (grouping, index) => {
    const { errors: { fieldErrors }, mode, showErrors } = this.state;
    const groupingErrors = fieldErrors.groupings ? fieldErrors.groupings[index] : {};
    const disabled = mode === readMode;
    return (
      <Fragment key={index}>
        <div className={styles.inputContainer}>
          <InputWithValidation
            name="metadataKey"
            value={grouping.metadataKey}
            errors={showErrors ? groupingErrors : {}}
            disabled={disabled}
            onChange={e => this.handleChange(e, index)}
          />
        </div>
        <div className={styles.inputContainer}>
          <InputWithValidation
            name="displayName"
            value={grouping.displayName}
            errors={showErrors ? groupingErrors : {}}
            disabled={disabled}
            onChange={e => this.handleChange(e, index)}
          />
        </div>
        <div className={styles.switchContainer}>
          <Switch
            id={index}
            toggle={e => this.toggleAutoloading(e, grouping.performAutomaticLoading)}
            enabled={grouping.performAutomaticLoading ? grouping.performAutomaticLoading : false}
            isLabelEnabled={false}
            disabled={disabled}
          />
        </div>
      </Fragment>
    );
  }

  renderMobileItem = (grouping, index) => {
    const { errors: { fieldErrors }, mode, showErrors } = this.state;
    const groupingErrors = fieldErrors.groupings ? fieldErrors.groupings[index] : {};
    const disabled = mode === readMode;
    return (
      <Fragment key={index}>
        <div className={styles.label}>
          Metakey
        </div>
        <div className={styles.inputContainer}>
          <InputWithValidation
            name="metadataKey"
            value={grouping.metadataKey}
            errors={showErrors ? groupingErrors : {}}
            disabled={disabled}
            onChange={e => this.handleChange(e, index)}
          />
        </div>
        <div className={styles.label}>
          Name
        </div>
        <div className={styles.inputContainer}>
          <InputWithValidation
            name="displayName"
            value={grouping.displayName}
            errors={showErrors ? groupingErrors : {}}
            disabled={disabled}
            onChange={e => this.handleChange(e, index)}
          />
        </div>
        <div className={styles.label}>
          AutoLoad
        </div>
        <div className={styles.switchContainer}>
          <Switch
            id={index}
            toggle={e => this.toggleAutoloading(e, grouping.performAutomaticLoading)}
            enabled={grouping.performAutomaticLoading ? grouping.performAutomaticLoading : false}
            isLabelEnabled={false}
            disabled={disabled}
          />
        </div>
      </Fragment>
    );
  }

  renderDesktopTable = () => {
    const { groupings, showErrors, errors: { rootErrors } } = this.state;
    const updatedGroupings = groupings.length ? groupings : fillGroupings(this.context.shipmentMetadataGroupings);
    return (
      <div className={styles.formEditArea}>
        <div className={styles.rootErrors + (showErrors && rootErrors ? 'show' : '')}>
          <ValidationErrors errors={showErrors && rootErrors} name="groupings" reserveSpace />
        </div>
        <div className={styles.groupingsTable}>
          <div className={styles.label}>Metakey</div>
          <div className={styles.label}>Name</div>
          <div className={styles.label}>AutoLoad</div>
          {updatedGroupings.map((x, i) => this.renderDesktopItem(x, i))}
        </div>
      </div>
    );
  };

  renderMobileTable = () => {
    const { groupings, showErrors, errors: { rootErrors } } = this.state;
    const updatedGroupings = groupings.length ? groupings : fillGroupings(this.context.shipmentMetadataGroupings);
    return (

      <div className={styles.formEditArea}>
        <div className={styles.groupingsMobileTable}>
          {updatedGroupings.map((x, i) => this.renderMobileItem(x, i))}
        </div>
        <div className={styles.rootErrors}>
          <ValidationErrors errors={showErrors && rootErrors} name="groupings" reserveSpace />
        </div>
      </div>
    );
  };

  render() {
    const {
      isLoading,
    } = this.state;
    return (
      <>
        {(isLoading || this.context.isLoading) && <Spinner />}
        <TitleNavBlock
          title={typography.shipmentGroupings.title}
          subTitle={typography.shipmentGroupings.subTitle}
          onBack={() => window.history.back()}
        />
        <div className={styles.outerWhiteContainer}>
          <div className="container__cards-wide-single">
            <CardShell>
              <form onSubmit={e => /* prevents page reload on submit */ e.preventDefault()}>
                <MediaQuery minWidth={uiBreakpoints.Desktop}>
                  {matches => (matches
                    ? this.renderDesktopTable()
                    : this.renderMobileTable())}
                </MediaQuery>
                {this.renderBottomButtons()}
              </form>
            </CardShell>
          </div>
        </div>
      </>
    );
  }
}

export default ShipmentMetadataGroupings;
