import React, { useEffect, useState } from 'react';
import CardShell from 'components/CardShell';
import AceEditor from 'react-ace';
import cx from 'classnames';
import { useParams } from 'react-router-dom';
import API from 'helpers/API';
import Spinner from 'components/Spinner';
import { format } from 'date-fns';
import styles from './styles.scss';
import TitleNavBlock from '../../../components/TitleNavBlock/TitleNavBlock';
import typography from '../../../helpers/appTypography';

function formatJson(jsonText) {
  return JSON.stringify(JSON.parse(jsonText), null, 2);
}

function renderStatus(statusCode) {
  const statusColor = statusCode >= 200 && statusCode <= 299 ? '#00854D' : '#C12547';
  return (
    <span className={styles.detailsStatus} style={{ background: statusColor }} />
  );
}

function WebhookLogsDetails() {
  const [logDetails, setLogDetails] = useState(null);
  const { id, logId } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      setLogDetails(null);
      const webhookLog = await API.getWebhookLog(id, logId);
      setLogDetails({
        ...webhookLog,
        date: format(new Date(webhookLog.date), 'DD/MM/YYYY HH:mm:ss'),
        responseBody: webhookLog.responseBody ?? '',
        responseHeaders: webhookLog.responseHeaders ?? '',
        requestBody: webhookLog.requestBody ? formatJson(webhookLog.requestBody) : '',
        requestHeaders: webhookLog.requestHeaders ?? '',
      });
    };
    fetchData();
  }, [id, logId]);

  if (logDetails === null) {
    return <Spinner />;
  }

  return (
    <div>
      <TitleNavBlock
        title={typography.requestDetails.title}
        subTitle={typography.requestDetails.subTitle}
        onBack={() => window.history.back()}
      />
      <CardShell>
        <div className={styles.cardInner}>
          <p className={cx(styles.titleText, styles.header)}><b>URL</b>{renderStatus(logDetails.statusCode)}</p>
          {logDetails.url}
          <p className={styles.titleText}><b>Elapsed Time</b></p>
          {logDetails.elapsedTime / 1000} sec
          <p className={styles.titleText}><b>Date</b></p>
          <div>{logDetails.date}</div>
          {logDetails.error && <div className={styles.error}>{logDetails.error}</div>}
          <p className={styles.titleText}><b>Request Headers</b></p>
          <AceEditor
            theme="xcode"
            fontSize={14}
            width="100%"
            readOnly
            showPrintMargin={false}
            maxLines={Infinity}
            value={logDetails.requestHeaders}
          />
          <p className={styles.titleText}><b>Request Body</b></p>
          <AceEditor
            mode="json"
            theme="xcode"
            fontSize={14}
            width="100%"
            maxLines={Infinity}
            readOnly
            showPrintMargin={false}
            value={logDetails.requestBody}
          />
          <p className={styles.titleText}><b>Response Headers</b></p>
          <AceEditor
            theme="xcode"
            fontSize={14}
            maxLines={Infinity}
            readOnly
            width="100%"
            showPrintMargin={false}
            value={logDetails.responseHeaders}
          />
          <p className={styles.titleText}><b>Response Body</b></p>
          <AceEditor
            theme="xcode"
            fontSize={14}
            maxLines={Infinity}
            width="100%"
            readOnly
            showPrintMargin={false}
            wrapEnabled
            value={logDetails.responseBody}
          />
        </div>
      </CardShell>
    </div>
  );
}

export default WebhookLogsDetails;
