import {
  CREATE_SFTP_ACCOUNT,
  CREATE_SFTP_ACCOUNT_SUCCESS,
  CREATE_SFTP_ACCOUNT_ERROR,
} from 'actions/types';

const initialState = {
  isLoading: false,
};

const sftpAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SFTP_ACCOUNT:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_SFTP_ACCOUNT_SUCCESS:
    case CREATE_SFTP_ACCOUNT_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default sftpAccountReducer;
