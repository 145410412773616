import React from 'react';
import styles from './styles.scss';

function boldLetters(incomingStr) {
  const dividedStr = incomingStr.split(' ');
  const firstPart = dividedStr[0];
  const lastPart = dividedStr.slice(1).join(' ');
  return (
    <>
      <span className={styles.boldLetters}>{firstPart} </span>
      <span>{lastPart}</span>
    </>
  );
}

const renderStatus = (props) => {
  let bgColor = '';
  let title = '';
  // eslint-disable-next-line no-underscore-dangle
  switch (props.row._original.category) {
    case 'green':
      bgColor = 'rgba(0, 133, 77, .19)';
      title = 'Green';
      break;
    case 'amber':
      bgColor = 'rgba(199, 82, 12, .19)';
      title = 'Amber';
      break;
    case 'red':
      bgColor = 'rgba(206, 40, 60, .19)';
      title = 'Red';
      break;
    default:
  }

  return (
    <span>
      <span style={{
        background: bgColor,
        width: 70,
        borderRadius: '12px',
        padding: '3px 8px',
        display: 'inline-block',
        textAlign: 'center',
      }}
      >{title}
      </span>
      <span style={{ marginLeft: '5px' }}>{boldLetters(props.row.data)}</span>
    </span>
  );
};

export default renderStatus;
