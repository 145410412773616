import React, { useEffect, useState } from 'react';
import { hasPermission } from 'helpers/auth';
import resources from 'constants/resources';
import API from 'helpers/API';
import LocalStorageTitle from 'components/localStorageTitle';
import CreateCard from 'components/CreateCard';
import BackButton from 'components/BackButton';
import cx from 'classnames';
import AuthorisedEmailCard from 'routes/Settings/RegisterShipmentWebhooks/AuthorisedEmails/AuthorisedEmailCard';
import Spinner from 'components/Spinner';
import { closePopup as closePopupAction, showPopup as showPopupAction } from 'actions';
import { connect } from 'react-redux';
import errorConstants from 'constants/errors';
import styles from './AuthorisedEmailsList.scss';

function AuthorisedEmailsList({ showPopup, closePopup, history }) {
  const canEdit = hasPermission(resources.emailShipmentRegistration.edit);
  const [loading, setLoading] = useState(true);
  const [authorisedEmails, setAuthorisedEmails] = useState(null);

  const toggleActive = async (id) => {
    setLoading(true);
    try {
      const authorisedEmail = authorisedEmails.find(x => x.id === id);
      const index = authorisedEmails.indexOf(authorisedEmail);
      const newAuthorisedEmail = await API.updateAuthorisedEmails({
        ...authorisedEmail,
        isActive: !authorisedEmail.isActive,
      });
      setAuthorisedEmails([
        ...authorisedEmails.slice(0, index),
        newAuthorisedEmail,
        ...authorisedEmails.slice(index + 1),
      ]);
    } finally {
      setLoading(false);
    }
  };

  const deleteAuthorisedEmail = async (id) => {
    await API.deleteAuthorisedEmail(id);
    setAuthorisedEmails(authorisedEmails.filter(x => x.id !== id));
  };

  const promptDelete = (id) => {
    showPopup({
      title: 'Are you sure?',
      description: <span>Deleting this authorised email revokes the incoming email address.</span>,
      onAccept: async () => {
        try {
          await deleteAuthorisedEmail(id);
          closePopup();
        } catch (e) {
          closePopup();
          showPopup({
            title: 'Error',
            description: errorConstants.unknownErrorOccurred,
            onDecline: () => {
              closePopup();
            },
          });
        }
      },
    });
  };

  useEffect(() => {
    const loadAuthorisedEmails = async () => {
      setLoading(true);
      const { authorisedEmails: loadedAuthorisedEmails } = await API.getAuthorisedEmails();
      setAuthorisedEmails(loadedAuthorisedEmails);
      setLoading(false);
    };
    loadAuthorisedEmails();
  }, []);

  return (
    <div>
      {loading && <Spinner />}
      <BackButton onBack={() => history.push('/settings/register-shipment-webhooks')} />
      <LocalStorageTitle localStorageName="inviteAMemberLandingPageText" pageTitle="Authorised Emails">
        <div className="text_container">
          <div className="informationalTextBlock">
            <p>
              This page allows you to manage authorised email addresses.
            </p>
            <p>
              You need to set up at least one to enable registration of shipments by email.
            </p>
          </div>
        </div>
      </LocalStorageTitle>
      <div className={cx('container__cards', styles.cardContainer)}>
        {canEdit && (
          <CreateCard
            text="Add email"
            handleClick={() => {
              history.push('/settings/register-shipment-webhooks/authorised-emails/add');
            }}
          />
        )}
        {authorisedEmails?.map(item => (
          <AuthorisedEmailCard
            key={item.id}
            history={history}
            authorisedEmail={item}
            toggleActive={toggleActive}
            deleteAuthorisedEmail={promptDelete}
          />
        ))}
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  showPopup: config => dispatch(showPopupAction(config)),
  closePopup: () => dispatch(closePopupAction()),
});

export default connect(null, mapDispatchToProps)(AuthorisedEmailsList);
