import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import cx from 'classnames';
import CarouselIndicators from 'components/Carousel/CarouselIndicators';
import styles from './styles.scss';

class Carousel extends PureComponent {
  static propTypes = {
    children: propTypes.arrayOf(propTypes.node).isRequired,
    className: propTypes.string,
  };

  static defaultProps = {
    className: null,
  };

  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
  }

  handleIndicatorChange = (i) => {
    this.scrollToItem(i);
  };

  handleItemClick = (i) => {
    this.scrollToItem(i);
  };

  scrollToItem = (i) => {
    this.innerRefs[i].current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  };

  handleScroll = (e) => {
    const { target } = e;
    const scrollPortion = target.scrollLeft / (target.scrollWidth - target.clientWidth);
    const nearestIndex = Math.round(scrollPortion * (this.props.children.length - 1));
    this.setState({ activeIndex: nearestIndex });
  };

  render() {
    const {
      children,
    } = this.props;

    this.innerRefs = Array.from({ length: children.length }, () => React.createRef());
    return (
      <div className={cx(this.props.className)}>
        <ol className={styles.eventsList} onScroll={this.handleScroll}>
          {children.map((item, i) => React.cloneElement(item, {
            onClick: () => this.handleItemClick(i),
            innerRef: this.innerRefs[i],
          }))}
        </ol>
        {children.length > 1 && (
          <CarouselIndicators
            className={styles.indicators}
            activeIndex={this.state.activeIndex}
            count={children.length}
            onChange={this.handleIndicatorChange}
          />
        )}
      </div>
    );
  }
}

export default Carousel;
