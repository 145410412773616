import React, { Component } from 'react';
import cx from 'classnames';
import {
  Light,
  Medium,
  Bold,
} from 'constants/trackingPage/fontWeights';
import {
  maxLength,
  minLength,
  rangeOf,
  checkGoogleFontUrl,
  required,
  isInteger,
} from 'helpers/validate';
import ColorPicker from 'components/ColorPicker';
import InputWithValidation, { showErrorStrategies } from 'components/InputWithValidation';
import GoogleFont from '../GoogleFont';

import styles from '../style.scss';

const fontSizeFieldName = 'size';
const fontWeightFieldName = 'fontWeight';
const fontColorFieldName = 'colour';

class Font extends Component {
  static validationRules = {
    name: [required()],
    size: [required(() => 'Please enter a font size between 10pt and 28pt'),
      rangeOf(10, 28, () => 'Please enter a font size between 10pt and 28pt'),
      isInteger()],
    colour: [minLength(7), maxLength(7)],
    url: [checkGoogleFontUrl()],
  };

  static mergeFonts(font, globalFont) {
    // Check only for undefined and null to exclude empty string ''.
    // Both means that the property not defined and globalFont property should be used.
    // The difference is that empty string should be displayed in input,
    // But null or undefined should display property from globalFont
    return {
      name: font.name || font.name === '' ? font.name : globalFont.name,
      size: font.size || font.size === '' ? font.size : globalFont.size,
      fontWeight: font.fontWeight || font.fontWeight === '' ? font.fontWeight : globalFont.fontWeight,
      colour: font.colour || font.colour === '' ? font.colour : globalFont.colour,
    };
  }

  handleChange = (field, value) => {
    const font = {
      ...this.props.value,
      [field]: value,
    };
    this.props.onChange(font);
  };

  handleTouch = (field, value) => {
    const touched = {
      ...this.props.touched,
      [field]: value,
    };
    this.props.onTouch(touched);
  };

  handleGoogleFontChange = (name, url) => {
    const font = {
      ...this.props.value,
      name,
      url,
    };
    this.props.onChange(font);
  };

  clearFontProperties = () => {
    const font = {};
    this.props.onChange(font);
  };

  render() {
    const {
      hideReset,
      globalFont = {},
      value,
      touched,
      errors,
      innerRefs,
    } = this.props;
    const font = Font.mergeFonts(value, globalFont);
    return (
      <>
        <div className={cx(styles.formGroup)}>
          <label className={styles.formLabel}>Font</label>
          <GoogleFont selectedFontName={font.name} onChange={this.handleGoogleFontChange} />
        </div>
        <div className={cx(styles.formGroup)}>
          <InputWithValidation
            value={font.size}
            touched={touched && touched.size}
            name={fontSizeFieldName}
            label="Font size"
            type="number"
            placeholder="Enter your font size (px)"
            showErrorStrategy={showErrorStrategies.touched}
            errors={errors}
            innerRef={innerRefs && innerRefs.size}
            onChange={e => this.handleChange('size', e.target.value ? Number(e.target.value) : null)}
            onBlur={() => this.handleTouch('size', true)}
          />
        </div>
        <div
          className={cx(
            styles.formGroup,
            {
              [styles.colorPickerErrorBorder]: errors && errors[fontColorFieldName]
                && errors[fontColorFieldName].length > 0,
            },
          )}
        >
          <ColorPicker
            title="Font colour"
            color={font.colour}
            update={selectedHexColor => this.handleChange(fontColorFieldName, selectedHexColor)}
          />
        </div>
        <div
          className={cx(styles.formGroup, {
            [styles.selectWithErrorsContainer]: errors && errors[fontWeightFieldName]
              && errors[fontWeightFieldName].length > 0,
          })}
        >
          <label htmlFor="generalFontweight" className={styles.loginFormLabel}>Font weight</label>
          <select
            value={font.fontWeight || ''}
            id="generalFontweight"
            className={styles.formElement}
            onChange={e => this.handleChange(fontWeightFieldName, e.target.value || null)}
          >
            <option value={Light}>Light</option>
            <option value={Medium}>Medium</option>
            <option value={Bold}>Bold</option>
          </select>
        </div>
        {!hideReset && (
          <div className={styles.formGroup}>
            <button
              className={cx(styles.miniFormGroupButton)}
              type="button"
              onClick={this.clearFontProperties}
            >
              Reset
            </button>
          </div>
        )}
      </>
    );
  }
}

export default Font;
