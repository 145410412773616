import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { event as gaEvent } from 'react-ga';
import CreateCard from 'components/CreateCard';
import CardFront from 'components/FormGroups/ShipmentFilterForm/CardFront';
import classnames from 'classnames';
import composeShipmentFilterData from 'helpers/composeShipmentFilterData';
import styles from './ShipmentFilterList.scss';
import TitleNavBlock from '../../../components/TitleNavBlock/TitleNavBlock';
import typography from '../../../helpers/appTypography';

const getExistingCards = (availableStates, groups, history) => {
  const cards = [];

  groups.map((group) => {
    const cardsGroup = composeShipmentFilterData(availableStates, group);
    return cards.push(
      <div
        key={group.id}
        className={classnames(styles.cardFrontContainer)}
      >
        <CardFront
          states={availableStates}
          cardData={cardsGroup}
          flipCard={() => {
            gaEvent({
              category: 'ShipmentFilters',
              action: 'Edit shipment filter',
            });
            history.push(`/settings/shipment-filters/${group.id}`);
          }}
        />
      </div>,
    );
  });

  return cards;
};

const ShipmentFilterList = ({ states, groups, history }) => (
  <div>
    <TitleNavBlock
      title={typography.shipmentFilters.title}
      subTitle={typography.shipmentFilters.subTitle}
      onBack={() => window.history.back()}
    />
    <div className={styles.outerWhiteContainer}>
      <div className="container__cards-large">
        <div
          role="button"
          onClick={() => {
            gaEvent({
              category: 'ShipmentFilters',
              action: 'Create a new shipment filter',
            });
            history.push('/settings/shipment-filters/create');
          }}
        >
          <CreateCard text="Create new Shipment Filter" />
        </div>
        { states && (
          getExistingCards(states, groups, history)
        )}
      </div>
    </div>
  </div>
);

const mapStateToProps = ({ shipmentFilters, availableStates }) => ({
  groups: shipmentFilters.groups,
  states: availableStates.states,
});

export default withRouter(connect(mapStateToProps)(ShipmentFilterList));
