import React, { Component } from 'react';
import cx from 'classnames';
import InputWithValidation from 'components/InputWithValidation';
import styles from './styles.scss';

class CreateWebhook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowingMore: false,
    };
  }

  render() {
    const {
      HttpMethodOptions,
      onTestWebhookSubmit,
      urlAddress,
      onUpdateUrlAddress,
      shipment,
      sendSuccess,
      urlAddressValid,
      onHttpMethodChange,
      selectedHttpMethod,
    } = this.props;

    const shimpmentJSON = JSON.stringify(shipment, null, 2);

    return (
      <div className={styles.innerCardBody}>
        <div className={styles.descriptionArea}>
          <p>Enter the URL the webhook will send data to & the HTTP method it should use.</p>
          <p>If required, send a test payload by clicking <strong>Send Test Webhook</strong>.</p>
          <p>When you are happy with your webhook setup, click <strong>Create</strong>.</p>
        </div>
        <form onSubmit={event => onTestWebhookSubmit(event)} className={styles.testWebhookForm}>
          <div className={styles.methodsForm}>
            <div className={styles.formRow}>
              <InputWithValidation
                label="Enter Request URL"
                placeholder="Request URL"
                onChange={onUpdateUrlAddress}
                value={urlAddress}
                type="text"
                required
              />
            </div>
            <div className={styles.formRow}>
              <label
                className={cx(styles.loginFormLabel)}
              >
                <span className={styles.asterisk}>*</span> HTTP method
              </label>
              <div className={styles.methodRadioButtons}>
                {HttpMethodOptions.map(item => (
                  <div
                    onClick={() => onHttpMethodChange(item.value)}
                    role="button"
                    key={item.id}
                    className={cx(styles.radioContainer, `${selectedHttpMethod === item.value ? styles.isActive : ''}`)}
                  >
                    <label htmlFor={item.value} className={styles.labelBtn}>
                      <input
                        id={item.value}
                        className={styles.formCheckInput}
                        type="radio"
                        value={item.value}
                        checked={selectedHttpMethod === item.value}
                        onChange={() => onHttpMethodChange(item.value)}
                      />
                      <span className={styles.radioBtnText}>{item.label}</span>
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <div className={styles.formRow}>
              <p>We will send the following payload to your URL</p>
              <div className={cx(styles.summaryFormArea, this.state.isShowingMore ? styles.summaryFormAreaMore : '')}>
                <div
                  role="button"
                  onClick={() => this.setState(prevState => ({ isShowingMore: !prevState.isShowingMore }))}
                  className={styles.showMore}
                >
                  {this.state.isShowingMore ? 'Show less' : 'Show more'}
                </div>
                <div className={styles.innerSummary}>
                  <div className={styles.checkedRadio}>

                    <div className={styles.dataFormContainer}>
                      <div className={styles.jsonCodeWrapper}>
                        <pre>{shimpmentJSON}</pre>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div className={styles.formRow}>
              <div className={styles.testWebhookWrapper}>
                <button
                  type="submit"
                  disabled={!urlAddressValid}
                  className={styles.requestBtn}
                >
                  Send test webhook
                </button>
                {sendSuccess ? (
                  <div className={styles.successAlert}>Webhook was sent!</div>
                ) : null}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default CreateWebhook;
