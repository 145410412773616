import React from 'react';
import CardShell from 'components/CardShell';
import DualButtons from 'components/DualButtons';
import LoginFormButton from 'components/RegistrationButton/LoginFormButton';
import { Helmet } from 'react-helmet';
import cx from 'classnames';
import styles from './styles.scss';

export default (props) => {
  if (!props.open) return null;
  return (
    <div className={styles.popUpWindow}>
      <Helmet>
        <body className={styles.disableScroll} />
      </Helmet>
      <div className={styles.popUpInner}>
        <CardShell>
          <div className={styles.messageWrapper}>
            <h3 className={styles.popUpTitle}>{props.title}</h3>
            <div className={styles.popUpDescription}>
              <p>{props.description}</p>
            </div>
          </div>
          {(props.onAccept && props.onDecline) && (
            <DualButtons
              rightFunction={props.onAccept}
              rightText="Yes"
              leftText="No"
              leftFunction={props.onDecline}
              theme="rightPink"
            />
          )}
          {(!props.onAccept) && (
            <LoginFormButton
              onClick={props.onDecline}
              className={cx(styles.okButton, 'button-pink')}
              text="Ok"
            />
          )}
        </CardShell>
      </div>
    </div>
  );
};
