import {
  StatusTimelineComponentType,
  ImageComponentType,
  TextComponentType,
  ImageAndTextComponentType,
} from 'constants/trackingPage/componentTypes';

export const toContractMap = {
  [StatusTimelineComponentType]: 'status_timeline',
  [ImageComponentType]: 'image',
  [TextComponentType]: 'text',
  [ImageAndTextComponentType]: 'standard',
};

export const fromContractMap = {
  status_timeline: StatusTimelineComponentType,
  image: ImageComponentType,
  text: TextComponentType,
  standard: ImageAndTextComponentType,
};
