import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import styles from './styles.scss';
import Svg from '../../Svg';

const NavItem = ({
  url,
  title,
  children,
  direction,
  imageOverride,
  svgOverride,
  currentUrl,
  expanded,
  isSidebarAccount,
}) => {
  const isActive = currentUrl === url;
  const itemClasses = classNames(styles.navItem, { [styles.active]: isActive }, { [styles.expanded]: expanded });

  if (children) {
    return (
      <div className={itemClasses} style={{ cursor: 'default' }}>
        {!imageOverride && (
          <Svg
            className={styles.icon}
            name={url}
            isSidebarAccount={isSidebarAccount}
          />
        )}
        {imageOverride && !svgOverride && (
          <img className={classNames(styles.icon, styles.avatar)} alt={title} name={title} src={imageOverride} />)}
        {imageOverride && svgOverride && (
          <Svg className={styles.icon} name={imageOverride} />)}
        <div className={styles.titleWrapper}>
          {expanded && (
            <span className={styles.title}>
              {title}
            </span>

          )}
          <div className={styles.chevronRight} />
        </div>
        <div className={styles.dropdown} style={{ [direction]: 0 }}>
          {children}
        </div>
      </div>
    );
  }

  return (
    <NavLink
      className={itemClasses}
      to={`/${url}`}
    >
      <Svg name={title} className={styles.icon} />
      {expanded && (
        <div className={styles.titleWrapper}>
          <span className={styles.title}>
            {title}
          </span>
        </div>
      )}
    </NavLink>
  );
};

NavItem.defaultProps = {
  children: undefined,
  currentUrl: '',
  direction: 'top',
  imageOverride: null,
  expanded: true,
  url: '',
};

NavItem.propTypes = {
  url: propTypes.string,
  title: propTypes.string.isRequired,
  currentUrl: propTypes.string,
  direction: propTypes.string,
  children: propTypes.node,
  imageOverride: propTypes.string,
  expanded: propTypes.bool,
};

export default NavItem;
