import React from 'react';
import cx from 'classnames';
import styles from './styles.scss';

const CarouselIndicators = ({
  count,
  activeIndex,
  onChange,
  className,
}) => {
  const items = [];
  for (let i = 0; i < count; i += 1) {
    const item = (
      <div
        key={i}
        role="button"
        className={cx(styles.item, i === activeIndex ? styles.active : null)}
        onClick={() => onChange(i)}
      />
    );
    items.push(item);
  }
  return (
    <div className={cx(styles.container, className)}>
      {items}
    </div>
  );
};

export default CarouselIndicators;
