import React, { Component } from 'react';
import { connect } from 'react-redux';
import { event as gaEvent } from 'react-ga';
import { Switch, Route, withRouter } from 'react-router';
import { compose } from 'redux';
import classnames from 'classnames';
import Filters from 'components/Filters';
import Svg from 'components/Svg';
import AngleUp from 'images/AngleUp.svg';
import Spinner from 'components/Spinner';
import joinQueries from 'helpers/joinQueryStrings';
import {
  getSelectedFilters,
} from 'helpers/selectedFilters';
import {
  getShipmentsMarkers as getShipmentsMarkersAction,
  getShipmentSummaries as getShipmentSummariesAction,
  showPopup as showPopupAction,
  closePopup as closePopupAction,
  toggleMap as toggleMapAction,
} from 'actions';
import Summary from './Summary';
import FilteredByColor from './FilteredByColor';
import styles from './styles.scss';
import shipmentsExportLimit from '../../../constants/shipmentsExport';

export class CStates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtersOpen: false,
      hasReloaded: false,
    };
  }

  componentDidMount() {
    let { searchQuery } = this.props;
    if (getSelectedFilters() && !searchQuery) {
      searchQuery = getSelectedFilters();
      this.handleSubmit(searchQuery);
    }

    this.props.getShipmentSummaries(searchQuery);

    if (this.props.showMap) {
      this.props.getShipmentsMarkers(this.props.searchQuery);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showMap !== this.props.showMap && this.props.showMap) {
      this.props.getShipmentsMarkers(this.props.searchQuery);
    }
    if (this.props.searchQuery !== prevProps.searchQuery) {
      this.props.getShipmentSummaries(this.props.searchQuery);

      if (this.props.showMap) {
        this.props.getShipmentsMarkers(this.props.searchQuery);
      }
    }

    if (!this.state.hasReloaded && (this.props.searchQuery !== prevProps.searchQuery)) {
      window.location.reload();
      this.setState({ hasReloaded: true });
    }
  }

  handleSubmit = (searchQuery) => {
    this.props.history.push({
      search: joinQueries(searchQuery),
    });
  };

  handleOpenFilters = () => {
    gaEvent({
      category: 'Navigation',
      action: 'Filters dropdown',
    });
    this.setState({ filtersOpen: true });
  };

  handleToggleMap = () => {
    this.props.toggleMap();
  };

  render() {
    const {
      states,
      markers,
      loading,
      searchQuery,
    } = this.props;

    return (
      <div>
        {loading ? <Spinner /> : null}
        <Switch>
          <Route
            path="/dashboards/states/:color"
            render={({ history, match }) => (
              <FilteredByColor
                loading={loading}
                states={states[match.params.color]}
                markers={markers}
                searchQuery={searchQuery}
                redirect={route => history.push({
                  ...route,
                  search: joinQueries(route.search || '', searchQuery),
                })}
                goBack={history.goBack}
                color={match.params.color}
                rightActionTooltipText={shipmentsExportLimit.message}
                onSubmit={this.handleSubmit}
                handleOpenFilters={this.handleOpenFilters}
              />
            )}
          />
          <Route
            path="/dashboards/states"
            render={({ history }) => (
              <Summary
                handleOpenFilters={this.handleOpenFilters}
                loading={loading}
                states={states}
                markers={markers}
                searchQuery={searchQuery}
                redirect={route => history.push({
                  ...route,
                  search: joinQueries(route.search || '', searchQuery),
                })}
              />
            )}
          />
        </Switch>
        <div
          className={classnames(styles.filtersContainer, this.state.filtersOpen ? styles.open : styles.closed)}
        >
          <div
            className={classnames(styles.filtersShowMapButton, 'subtitle2', styles.centeredText)}
            role="button"
            onClick={this.handleToggleMap}
          >
            <div className={styles.showMapBlock}>
              {this.props.showMap
                ? <>Hide map <AngleUp className={styles.icon} /></>
                : <>Show map <Svg name="angleDown" className={styles.icon} /></>}
            </div>
          </div>
          {this.state.filtersOpen && (
            <Filters
              showPopup={this.props.showPopup}
              closePopup={this.props.closePopup}
              onSubmit={this.handleSubmit}
              closeFilters={() => this.setState({ filtersOpen: false })}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, { location }) => ({
  states: state.shipmentStates.colours,
  loading: state.shipmentStates.loading,
  markers: state.mapbox.markers,
  searchQuery: location.search,
  showMap: state.toggleMap.showMap,
});

const mapDispatchToProps = dispatch => ({
  getShipmentsMarkers: searchQuery => dispatch(getShipmentsMarkersAction(searchQuery)),
  getShipmentSummaries: searchQuery => dispatch(getShipmentSummariesAction(searchQuery)),
  showPopup: config => dispatch(showPopupAction(config)),
  closePopup: () => dispatch(closePopupAction()),
  toggleMap: () => dispatch(toggleMapAction()),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(CStates);
