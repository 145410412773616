import React from 'react';
import ReactTable from 'react-table';
import format from 'date-fns/format';
import { useHistory, NavLink } from 'react-router-dom';
import styles from './activityList.scss';
import EventType from '../EventLabel';

const ActivityList = ({ activities, isLoading }) => {
  const history = useHistory();

  const CellComponent = (value) => (
    <div className={styles.cellWrapper}>
      <span>{value}</span>
    </div>
  );

  const HyperLinkCellComponent = (link, cellValue) => (
    <div className={styles.cellWrapper}>
      <NavLink
        exact
        to={link}
        className={styles.cellLink}
      >
        <span>{cellValue}</span>
      </NavLink>
    </div>
  );

  const prettyDate = (date) => (date ? format(date, 'DD MMM YYYY HH:mm:ss') : '');

  const LinkToDetail = (id) => (`./activity/${id}/detail`);
  const LinkToEvents = (id) => (`./activity/${id}/events`);

  const HandleEventClick = (event) => {
    history.push(LinkToDetail(event));
  };

  const headerStyle = {
    justifyContent: 'flex-start',
    fontSize: '18px',
    fontWeight: 'bold',
    background: 'white',
    borderBottom: '1px solid gray',
    cursor: 'default',
  };

  const cellStyle = {
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    cursor: 'default',
  };

  const columns = [
    {
      Header: 'Event',
      id: 'event',
      accessor: 'event',
      Cell: (cellInfo) => (
        <div
          className={styles.eventTypeLink}
          role="button"
          onClick={() => HandleEventClick(cellInfo.original.id)}
        ><EventType value={cellInfo.value} />
        </div>
      ),
      headerStyle,
      style: cellStyle,
      minWidth: 140,
    },
    {
      Header: 'Recipient',
      accessor: 'recipient',
      Cell: (cellInfo) => HyperLinkCellComponent(LinkToEvents(cellInfo.value), cellInfo.value),
      headerStyle,
      style: cellStyle,
      minWidth: 250,
    },
    {
      Header: 'Subject',
      accessor: 'subject',
      Cell: (cellInfo) => HyperLinkCellComponent(LinkToDetail(cellInfo.original.id), cellInfo.value),
      headerStyle,
      style: cellStyle,
      minWidth: 250,
    },
    {
      Header: 'Date',
      accessor: 'eventTime',
      Cell: (cellInfo) => CellComponent(prettyDate(cellInfo.value)),
      headerStyle,
      style: cellStyle,
      minWidth: 150,
    },
  ];

  return (
    <>
      {activities
    && (
    <ReactTable
      data={activities}
      columns={columns}
      sortable={false}
      loading={isLoading}
      totalRows={activities.length}
      className={styles.ReactTableCustom}
      minRows={0}
      showPagination={false}
      pageSize={activities.length}
      trackingComponentName="Activities Table"
    />
    )}
    </>
  );
};

export default ActivityList;
