import React from 'react';
import { useParams } from 'react-router-dom';
import buttonStyles from './buttons.scss';
import styles from './createSuppression.scss';
import Button from '../../../../../components/ReactButton';
import Modal from '../../../../../components/Modal';
import { emailListValid, parseEmailList } from './emailList';
import API from '../../../../../helpers/API';

const CreateSuppression = ({ onSuccess, onFailure }) => {
  const { id } = useParams();

  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [recipients, setRecipients] = React.useState(null);
  const [disableAdd, setDisableAdd] = React.useState(false);
  const recipientsText = React.useRef(null);

  const HandleClick = () => {
    setOpen(true);
    setDisableAdd(false);
    setErrorMessage(null);
    setTimeout(() => {
      recipientsText.current.focus();
    });
  };

  const isValidSoft = (input) => {
    const validationError = emailListValid(input);

    if (validationError.length > 0) {
      return false;
    }

    const emails = parseEmailList(input);
    return emails.length <= 50;
  };

  const isValid = (input) => {
    const validationError = emailListValid(input);

    if (validationError.length > 0) {
      setErrorMessage(validationError.slice(0, 5).join(', '));
      setDisableAdd(true);
      return false;
    }

    const emails = parseEmailList(input);
    if (emails.length > 50) {
      setErrorMessage('You can only add up to 50 email addresses at one time');
      setDisableAdd(true);
      return false;
    }
    setDisableAdd(false);
    setErrorMessage(null);
    return true;
  };

  const HandleAdd = async () => {
    if (!isValid(recipients)) {
      return;
    }
    const emails = parseEmailList(recipients);
    if (emails.length === 0) {
      return;
    }

    setDisableAdd(true);
    try {
      const response = await API.addSuppression(id, emails);
      onSuccess(emails.length, response.suppressions);
    } catch (e) {
      onFailure(emails.length, e);
    }
    setOpen(false);
  };

  const HandleChange = (e) => {
    const input = e.target.value;
    if (isValidSoft(input)) {
      setErrorMessage(null);
      setDisableAdd(false);
    }
    setRecipients(input);
  };

  const HandleKeyPress = (e) => {
    if (e.key === 'Enter') {
      isValid(e.target.value);
    }
  };

  const HandleBlur = (e) => {
    isValid(e.target.value);
  };

  const HandlePaste = () => {
    setTimeout(() => {
      isValid(recipientsText.current.value);
    });
  };

  const textComponent = (
    <textarea
      placeholder="sally@example.com"
      className={styles.recipientsBox}
      ref={recipientsText}
      cols={64}
      rows={15}
      onChange={HandleChange}
      onKeyPress={HandleKeyPress}
      onBlur={HandleBlur}
      onPaste={HandlePaste}
    />
  );

  const subHeader = 'Add recipients to suppression list. Recipients on the suppression list will not'
                  + ' be able to recieve emails on the Message Stream. Add up to 50 email addresses each on a new line';

  return (
    <>
      <Button theme="pink" data-testid="createSuppressions" className={buttonStyles.button} onClick={HandleClick}>
        Add suppressed recipients
      </Button>
      <Modal
        open={open}
        setOpen={setOpen}
        header="Add suppression recipients"
        subHeader={subHeader}
        actionText="Add suppression recipients"
        mainContent={textComponent}
        size="small"
        errorMessage={errorMessage}
        handleAction={HandleAdd}
        disableAction={disableAdd}
        headerTextSize="small"
        actionButtonSize="large"
      />
    </>
  );
};

export default CreateSuppression;
