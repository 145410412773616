import React from 'react';
import * as propTypes from 'prop-types';
import cx from 'classnames';
import ContextPopupContext from 'components/ContextPopup/ContextPopupContext';
import styles from './ContextPopup.scss';

export const PopupPositions = {
  bottomLeft: 'bottomLeft',
  bottomCenter: 'bottomCenter',
  bottomRight: 'bottomRight',
  /** Initialize to bottom center. If the popup overlaps the window then shift it to left or right */
  bottomAuto: 'bottomAuto',
};

class ContextPopup extends React.PureComponent {
  static initialPopupPosition(popupPosition) {
    if (popupPosition === PopupPositions.bottomAuto) {
      return PopupPositions.bottomCenter;
    }
    return popupPosition;
  }

  static propTypes = {
    popupOwnerRender: propTypes.func.isRequired,
    contentRender: propTypes.func,
    popupPosition: propTypes.oneOf(Object.values(PopupPositions)),
    children: propTypes.oneOfType([
      propTypes.arrayOf(propTypes.element),
      propTypes.element]),
  };

  static defaultProps = {
    popupPosition: PopupPositions.bottomLeft,
    contentRender: null,
    children: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      isPopupShown: false,
      popupPosition: ContextPopup.initialPopupPosition(props.popupPosition),
      context: { togglePopup: this.togglePopup },
    };
    this.container = React.createRef();
    this.popup = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideMenuClick);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isPopupShown,
    } = this.state;
    const {
      popupPosition,
    } = this.props;
    if (prevState.isPopupShown !== isPopupShown && isPopupShown && popupPosition === PopupPositions.bottomAuto) {
      const { right, left } = this.popup.current.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      let resultPosition;
      if (right + 20 > windowWidth) {
        resultPosition = PopupPositions.bottomLeft;
      } else if (left - 20 < 0) {
        resultPosition = PopupPositions.bottomRight;
      }

      if (resultPosition && this.state.popupPosition !== resultPosition) {
        // Reactjs docs tolerate call to setState to measure DOM nodes
        // https://reactjs.org/docs/react-component.html#componentdidmount
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ popupPosition: resultPosition });
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideMenuClick);
  }

  handleOutsideMenuClick = (e) => {
    if (this.state.isPopupShown && this.container.current && !this.container.current.contains(e.target)) {
      this.setState({ isPopupShown: false });
    }
  };

  togglePopup = () => {
    this.setState(state => ({ isPopupShown: !state.isPopupShown }));
  };

  renderContent() {
    const {
      children,
      contentRender,
    } = this.props;

    if (contentRender) {
      return contentRender(this.togglePopup);
    }
    return children;
  }

  render() {
    const {
      popupOwnerRender,
    } = this.props;
    const {
      isPopupShown,
      popupPosition,
      context,
    } = this.state;
    return (
      <div className={styles.container} ref={this.container}>
        {popupOwnerRender(this.togglePopup, isPopupShown)}
        {isPopupShown && (
          <div ref={this.popup} className={cx(styles.popup, styles[popupPosition])}>
            <ContextPopupContext.Provider value={context}>
              {this.renderContent()}
            </ContextPopupContext.Provider>
          </div>
        )}
      </div>
    );
  }
}

export default ContextPopup;
