const initialState = {
  visible: false,
  onAccept: null,
  onDecline: null,
  title: 'Not copied to clipboard',
  description: 'Please confirm that you have copied and saved your API key. You cannot view the key again.',
};

export default function popClipboardReducer(state = initialState, action) {
  switch (action.type) {
    case 'OPEN_CLIPBOARD':
      return {
        ...state,
        visible: true,
        onDecline: action.onDecline,
        onAccept: action.onAccept,
      };
    case 'CLOSE_CLIPBOARD':
      return {
        ...state,
        visible: false,
      };
    default:
      return state;
  }
}
