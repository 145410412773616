import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import LoginFormButton from 'components/RegistrationButton/LoginFormButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormErrors from 'components/FormErrors';
import { isEmail } from 'helpers/validate';
import { validateForm } from 'helpers/validateForm';
import {
  resetPasswordByEmail as resetPasswordByEmailAction,
  goBackToLogin as goBackToLoginAction,
} from 'actions';
import styles from './forgot-password.scss';
import StateCategoryGreen from '../../images/stateCategoryGreen.svg';
import AuthenticationInput from '../../components/AuthenticationInput/AuthenticationInput';

class ForgotPasswordEmail extends React.Component {
  validationRules = {
    email: [isEmail()],
  };

  static propTypes = {
    onResetPasswordByEmail: propTypes.func.isRequired,
  };

  static defaultProps = {
  };

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isLoading: false,
    };
  }

  handleOnChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const errors = validateForm(this.validationRules, this.state);
    if (errors.hasErrors) {
      this.setState({ errors });
      return;
    }

    this.setState({ isLoading: true, errors: {} });
    this.props.onResetPasswordByEmail(this.state.email)
      .then(() => {
        this.setState({ isLoading: false, successEmailSent: true });
      })
      .catch((e) => {
        this.setState({ isLoading: false, errors: e });
        return Promise.resolve();
      });
  }

  render() {
    const {
      isLoading,
      errors,
      email,
    } = this.state;

    return (
      <div className={styles.forgotPasswordForm}>
        <div className={styles.registrationPageInner}>
          <div className={styles.welcome}>
            <h1 className={`${styles.welcomeHeader}`}>Forgotten password?</h1>
          </div>
          {!this.state.successEmailSent && <p className={styles.infoText}>A temporary reset link will be sent to your registered email address.</p>}
          <form onSubmit={this.handleSubmit}>
            {!this.state.successEmailSent && (
            <div className={errors && errors.email && styles.inputErrorContainer}>
              <AuthenticationInput
                label="Email address"
                type="email"
                value={email}
                name="email"
                onChange={this.handleOnChange}
                error={errors && errors.email && errors.email.join(', ')}
              />
            </div>
            )}
            {this.state.successEmailSent && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <StateCategoryGreen width="48px" height="48px" fill="#71d54c" />
              <p className={styles.infoText}>A temporary reset link has been sent to your registered email address.</p>
            </div>
            )}
            <LoginFormButton
              type="submit"
              onClick={(event) => {
                if (this.state.successEmailSent) {
                  this.props.goBackToLogin();
                } else {
                  this.handleSubmit(event);
                }
              }}
              className={classnames(styles.button, { [styles.buttonDisabled]: isLoading })}
              disabled={isLoading}
            >
              {isLoading && <CircularProgress thickness={8} color="inherit" size={40} variant="indeterminate" className={styles.loadingSpinner} />}
              {!isLoading && !this.state.successEmailSent && 'Send me a temporary reset link'}
              {!isLoading && this.state.successEmailSent && 'Return to login'}
            </LoginFormButton>
            {!this.state.successEmailSent
            && (
            <button type="button" className={classnames(styles.secondaryButton)} onClick={this.props.goBackToLogin}>
              Return to login
            </button>
            )}
          </form>
          <FormErrors errors={errors} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onResetPasswordByEmail: email => dispatch(resetPasswordByEmailAction(email)),
  goBackToLogin: () => dispatch(goBackToLoginAction()),
});

export default connect(null, mapDispatchToProps)(ForgotPasswordEmail);
