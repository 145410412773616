import React from 'react';
import CardShell from 'components/CardShell';
import ColorPicker from 'components/ColorPicker';
import FilePicker from 'components/FilePicker';
import SupportedImageExtensions from 'constants/trackingPage/supportedImageExtensions';
import InputWithValidation, { showErrorStrategies } from 'components/InputWithValidation';
import Hr from 'components/CardShell/Hr';
import Font from '../Font';

import styles from '../style.scss';

function NavigationBar(props) {
  const handleChange = (field, value) => {
    const font = {
      ...props.value,
      [field]: value,
    };
    props.onChange(font);
  };

  const handleTouch = (field, value) => {
    const touched = {
      ...props.touched,
      [field]: value,
    };
    props.onTouch(touched);
  };

  const {
    value: {
      base64Logo,
      backgroundColour,
      borderColour,
      font,
      rootUrl,
    },
    errors,
    touched,
    innerRefs,
  } = props;

  return (
    <CardShell>
      <div className={styles.cardInner}>
        <h3 className={styles.title}>Navbar</h3>
        <Hr />

        <div className={styles.formGroup}>
          <label className={styles.formLabel}>Logo</label>
          <FilePicker
            value={base64Logo}
            imgPreviewBackground={backgroundColour}
            multiple={false}
            accept={SupportedImageExtensions}
            onChange={image => handleChange('base64Logo', image)}
          />
        </div>

        <div className={styles.formGroup}>
          <InputWithValidation
            value={rootUrl}
            id="navRooturl"
            label="Root url"
            name="rootUrl"
            onChange={e => handleChange('rootUrl', e.target.value)}
            onBlur={() => handleTouch('rootUrl', true)}
            className={styles.formElement}
            type="text"
            placeholder="Enter root url"
            errors={errors}
            touched={touched && touched.rootUrl}
            showErrorStrategy={showErrorStrategies.touched}
            innerRef={innerRefs.rootUrl}
          />
        </div>

        <div className={styles.formGroup}>
          <ColorPicker
            title="Background colour"
            color={backgroundColour}
            update={color => handleChange('backgroundColour', color)}
          />
        </div>

        <div className={styles.formGroup}>
          <ColorPicker
            title="Border colour"
            color={borderColour}
            update={color => handleChange('borderColour', color)}
          />
        </div>

        <Font
          value={font}
          errors={errors && errors.font}
          touched={touched && touched.font}
          onChange={f => handleChange('font', f)}
          onTouch={v => handleTouch('font', v)}
          hideReset
          innerRefs={innerRefs.font}
        />
      </div>
    </CardShell>
  );
}

export default NavigationBar;
