class CancellationToken {
  #isCancelled = false;

  get isCancelled() {
    return this.#isCancelled;
  }

  cancel() {
    this.#isCancelled = true;
  }
}

export default CancellationToken;
