import React, { useRef } from 'react';
import cx from 'classnames';
import {
  Modal, Grid, IconButton,
} from '@material-ui/core';
// import useOnClickOutsideHook from 'hooks/useOnClickOutsideHook';
import CrossIcon from 'images/cross.svg';
import StateCategoryRed from 'images/stateCategoryRed.svg';
import styles from './styles.scss';

const getWidth = (size) => {
  if (size === 'small') {
    return {
      width: '600px',
    };
  }
  return {
    width: '800px',
  };
};

const getHeaderSize = (size) => {
  if (size === 'small') {
    return {
      fontSize: '1.4rem',
      margin: 'auto 0px',
    };
  }
  return {
    fontSize: '1.8rem',
  };
};

const getActionButtonSizeXs = (size) => {
  if (size === 'large') {
    return 8;
  }
  return 6;
};

const getActionButtonSizeMd = (size) => {
  if (size === 'large') {
    return 6;
  }
  return 4;
};

export const EmptyContent = () => <div />;

const CustomModal = ({
  open,
  setOpen,
  label,
  describedBy,
  actionText,
  cancelText = 'Cancel',
  handleAction,
  mainContent = <EmptyContent />,
  header,
  subHeader,
  errorMessage,
  hideFooter = false,
  size = 'large',
  headerTextSize = 'large',
  actionButtonSize = 'small',
  disableAction,
}) => {
  const wrapperRef = useRef(null);
  // useOnClickOutsideHook(wrapperRef, () => setOpen(false), false, true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby={label}
        aria-describedby={describedBy}
      >
        <div
          className={styles.mainWrapper}
          ref={wrapperRef}
          style={{
            ...getWidth(size),
          }}
        >
          <Grid container item xs={12}>
            <Grid container item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Grid item xs={11} md={7} className={styles.textWrapper}>
                <p
                  role="heading"
                  aria-level="1"
                  className={styles.modalHeader}
                  style={{
                    ...getHeaderSize(headerTextSize),
                  }}
                >
                  {header}
                </p>
              </Grid>
              <Grid item xs={1} md={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton onClick={handleClose}>
                  <CrossIcon
                    className={styles.icon}
                    data-tip="Close modal"
                  />
                </IconButton>
              </Grid>
            </Grid>
            {errorMessage && (
              <Grid container item xs={12} style={{ marginBottom: '10px' }}>
                <Grid item xs={12} className={cx(styles.textWrapper, styles.errorWrapper)}>
                  <div style={{ display: 'flex' }}>
                    <span className={styles.errorIcon}>
                      <StateCategoryRed />
                    </span>
                    <span className={styles.errorMessage}>
                      {errorMessage}
                    </span>
                  </div>
                </Grid>
              </Grid>
            )}
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <p className={styles.modalSubHeader}>
                  {subHeader}
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} className="modalMainWrapper">
            {mainContent}
          </Grid>
          <hr className={hideFooter ? cx(styles.modalDivider, styles.hidden) : undefined} />
          <Grid container item xs={12} className={hideFooter ? styles.hidden : undefined}>
            <Grid container item xs={12} md={size === 'small' ? 12 : 6}>
              <Grid item xs={getActionButtonSizeXs(actionButtonSize)} md={getActionButtonSizeMd(actionButtonSize)} style={{ padding: '0 0.25rem' }}>
                <button
                  onClick={handleAction}
                  className={cx(
                    styles.modalButton,
                    styles.modalButtonAction,
                    disableAction ? styles.disabled : '',
                  )}
                  type="button"
                >
                  {actionText}
                </button>
              </Grid>
              <Grid item xs={6} md={4} style={{ padding: '0 0.25rem' }}>
                <button
                  onClick={handleClose}
                  className={cx(styles.modalButton, styles.modalButtonCancel)}
                  type="button"
                  id="modal_close_button"
                >
                  {cancelText}
                </button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
};

export default CustomModal;
