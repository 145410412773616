import React from 'react';

/* eslint-disable */
export default props => (
  <svg {...props} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 18'>
    <title>Filter Icon</title>
    <defs/>
    <g id='Managing-shipment-states' stroke='none' strokeWidth='1'  fill='inherit' fillRule='evenodd' fillOpacity='0.501811594'>
      <g id='Desktop-HD-Copy-3' transform='translate(-1156.000000, -447.000000)' fill='inherit'>
        <g id='02---Stage-2' transform='translate(742.000000, 213.000000)'>
          <g id='Fill-1391-+-Fill-1392-Copy' transform='translate(414.000000, 234.000000)'>
            <path d='M11,6 L9,6 L9,2 L11,2 L11,6 Z M13,3 L13,1 C13,0.448 12.552,0 12,0 L8,0 C7.448,0 7,0.448 7,1 L7,3 L0,3 L0,5 L7,5 L7,7 C7,7.552 7.448,8 8,8 L12,8 C12.552,8 13,7.552 13,7 L13,5 L20,5 L20,3 L13,3 Z' id='Fill-1391'/>
            <path d='M16,16 L14,16 L14,12 L16,12 L16,16 Z M18,11 C18,10.448 17.552,10 17,10 L13,10 C12.448,10 12,10.448 12,11 L12,13 L0,13 L0,15 L12,15 L12,17 C12,17.552 12.448,18 13,18 L17,18 C17.552,18 18,17.552 18,17 L18,15 L20,15 L20,13 L18,13 L18,11 Z' id='Fill-1392'/>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

/* eslint-enable */
