import React, { Component } from 'react';
import { generateId } from 'helpers/guid';
import PopupCentered from 'components/PopupCentered';
import CardShell from 'components/CardShell';
import DualButtons from 'components/DualButtons';
import Hr from 'components/CardShell/Hr';
import styles from '../../style.scss';
import { getAllTouched, validateNestedForm } from '../../../../helpers/validateForm';
import { isUrl, maxLength, required } from '../../../../helpers/validate';
import InputWithValidation, { showErrorStrategies } from '../../../InputWithValidation';

class NavigationBarItemAdd extends Component {
  static validationRules = {
    text: [required(() => 'Please enter some text for your nav item.'),
      maxLength(50, () => 'Nav item text should be 50 characters or less.')],
    redirectionUrl: [required(() => 'Please enter the URL your nav item should link to.'),
      maxLength(200, () => 'Please enter a shorter URL. Nav item URLs should be 200 characters or less.'),
      isUrl()],
  };

  constructor(props) {
    super(props);
    const item = {
      id: generateId(),
      text: '',
      redirectionUrl: '',
    };
    this.state = {
      item,
      touched: {},
      errors: validateNestedForm(item, NavigationBarItemAdd.validationRules),
    };
  }

  handleSubmit = () => {
    const {
      errors,
      item,
    } = this.state;
    const {
      onAdd,
    } = this.props;
    if (Object.keys(errors).length === 0) {
      onAdd(item);
    } else {
      this.setState({ touched: getAllTouched(item, NavigationBarItemAdd.validationRules) });
    }
  };

  handleTouch = (field, value) => {
    this.setState(state => ({
      touched: {
        ...state.touched,
        [field]: value,
      },
    }));
  };

  handleChange = (field, value) => {
    this.setState((state) => {
      const item = {
        ...state.item,
        [field]: value,
      };
      const errors = validateNestedForm(item, NavigationBarItemAdd.validationRules);
      return {
        item,
        errors,
      };
    });
  };

  render() {
    const { onClose } = this.props;
    const {
      item: {
        text,
        redirectionUrl,
      },
      errors,
      touched,
    } = this.state;

    return (
      <PopupCentered>
        <CardShell>
          <div className={styles.cardInner}>
            <h3 className={styles.title}>Add Link</h3>
            <Hr />

            <div className={styles.formGroup}>
              <InputWithValidation
                value={text}
                name="text"
                label="Text"
                id="generalFonturl"
                className={styles.formElement}
                type="text"
                placeholder="Text"
                errors={errors}
                touched={touched.text}
                onChange={e => this.handleChange('text', e.target.value)}
                onBlur={() => this.handleTouch('text', true)}
                showErrorStrategy={showErrorStrategies.touched}
              />
            </div>
            <div className={styles.formGroup}>
              <InputWithValidation
                value={redirectionUrl}
                name="redirectionUrl"
                label="Url"
                id="generalFonturl2"
                className={styles.formElement}
                type="text"
                placeholder="Enter your URL"
                errors={errors}
                touched={touched.redirectionUrl}
                onChange={e => this.handleChange('redirectionUrl', e.target.value)}
                onBlur={() => this.handleTouch('redirectionUrl', true)}
                showErrorStrategy={showErrorStrategies.touched}
              />
            </div>
          </div>
          <DualButtons
            leftText="Cancel"
            leftFunction={onClose}
            rightText="Update"
            rightFunction={this.handleSubmit}
          />
        </CardShell>
      </PopupCentered>
    );
  }
}

export default NavigationBarItemAdd;
