import React from 'react';
import styles from './styles.scss';

export default (props) => {
  if (!props.visible) return null;
  return (
    <div className={styles.popUpWindow}>
      <div className={styles.popUpInner}>
        <div className={styles.messageWrapper}>
          <h3 className={styles.popUpTitle}>{props.title}</h3>
          <div className={styles.popUpDescription}>
            {props.description}
          </div>
        </div>
        <div className={styles.btnWrapper}>
          <button type="button" className={styles.btnDecline} onClick={props.onAccept}><span>confirm</span></button>
          <button type="button" className={styles.btnAccept} onClick={props.onDecline}><span>go back</span></button>
        </div>
      </div>
    </div>
  );
};
