import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import InputWithValidation from 'components/InputWithValidation';
import { maxLength } from 'helpers/validate';
import { validateForm } from 'helpers/validateForm';
import { editApiKeyTitle } from 'constants/titles';
import CardShell from 'components/CardShell';
import DualButtons from 'components/DualButtons';
import Hr from 'components/CardShell/Hr';
import Header from 'components/CardShell/Header';
import {
  updateApiKey as updateApiKeyAction,
} from 'actions/apikeyActions';

import styles from './ApiKeyDetailsStyles.scss';

class EditApiKeyPage extends React.Component {
  validationRules = {
    name: [maxLength(50)],
  }

  constructor(props) {
    super(props);

    this.state = {
      apiKeyId: null,
      name: '',
      errors: {},
    };

    // eslint-disable-next-line react/no-unused-class-component-methods
    this.apiKeyId = props.match.params.apiKeyId;
  }

  componentDidMount() {
    const { apiKeyId } = this.props.match.params;
    const matchedKey = this.props.apiKeys.find(key => key.id === apiKeyId);

    if (!matchedKey) {
      this.backToMenu();
    } else {
      this.setState({
        apiKeyId,
        name: matchedKey.name,
      });
    }
  }

  handleNameInputChange = e => this.setState({ name: e.target.value });

  submitUpdate = () => {
    const errors = validateForm(this.validationRules, this.state);
    if (errors.hasErrors) {
      this.setState({ errors });
      return;
    }

    this.props.updateApiKey(this.state.apiKeyId, this.state.name).then(() => {
      this.backToMenu();
    }).catch(() => {
      this.backToMenu();
    });
  };

  backToMenu = () => this.props.history.push('/settings/api-keys');

  render() {
    return (
      <div>
        <h2>Editing API Key</h2>
        <div className="container__cards-large">
          <CardShell>
            <div className={styles.container}>
              <div className={styles.createKeyWrapper}>
                <Header>{editApiKeyTitle}</Header>
                <Hr className={styles.fieldWithMargin} />
                <InputWithValidation
                  label="API Key Name"
                  placeholder="API Key Name"
                  type="text"
                  value={this.state.name}
                  name="name"
                  onChange={this.handleNameInputChange}
                  errors={this.state.errors}
                />
              </div>
            </div>
            <DualButtons
              leftFunction={this.backToMenu}
              leftText="Cancel"
              rightFunction={this.submitUpdate}
              rightText="Update"
              theme="rightPink"
            />
          </CardShell>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = state => ({
  apiKeys: state.apiKeys.keys,
});

const mapDispatchToProps = dispatch => ({
  updateApiKey: (id, name) => dispatch(updateApiKeyAction({ id, name })),
});

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(EditApiKeyPage));
