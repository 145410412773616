import React from 'react';
import styles from './styles.scss';

const Checkbox = (props) => {
  const { label, checked, onChange } = props;
  return (
    <label className={styles.checboxRow}>
      <input
        name="isActive"
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <span className={styles.checkBoxLabel}>{label}</span>
    </label>
  );
};

export default Checkbox;
