import {
  CUSTOM_STATE_LABELS_FETCH,
  CUSTOM_STATE_LABELS_SUCCESS,
  CUSTOM_STATE_LABELS_ERROR,
  CUSTOM_STATE_LABELS_FILTER_SET,
} from 'actions/types';

const initialState = {
  loading: true,
  keys: [],
  error: '',
  isActive: false,
  filter: 'None',
  filters: [
    {
      category: 'None',
      name: 'All',
    },
    {
      category: 'Green',
      name: 'All Good',
    },
    {
      category: 'Amber',
      name: 'Warning',
    },
    {
      category: 'Red',
      name: 'Critical',
    },
  ],
};

export default function customStateLabelsReducer(state = initialState, action) {
  switch (action.type) {
    case CUSTOM_STATE_LABELS_FETCH:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case CUSTOM_STATE_LABELS_SUCCESS:
      return {
        ...state,
        keys: action.keys,
        loading: false,
        error: '',
      };
    case CUSTOM_STATE_LABELS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CUSTOM_STATE_LABELS_FILTER_SET:
      return {
        ...state,
        filter: action.filter,
      };
    default:
      return state;
  }
}
