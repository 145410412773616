import { hasPermission } from 'helpers/auth';

function RestrictedAccess(props) {
  if (Array.isArray(props.resource)) {
    for (let i = 0; i < props.resource.length; i += 1) {
      if (hasPermission(props.resource[i])) {
        return props.children;
      }
    }
  }

  if (hasPermission(props.resource)) {
    return props.children;
  }

  return null;
}

export default RestrictedAccess;
