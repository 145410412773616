import { Base64 } from 'js-base64';
import authMethods from 'constants/authMethods';

const { apiKey, basic, jwt } = authMethods;

const fetchWithBasicAuth = (
  fetchUsingCredentials,
  { username, password },
) => fetchUsingCredentials({
  Authorization: `Basic ${Base64.encode(`${username}:${password}`)}`,
});

const fetchWithApiKeyAuth = (
  fetchUsingCredentials,
  { apiKeyHeader, apiKeyValue },
) => fetchUsingCredentials({
  [apiKeyHeader]: apiKeyValue,
});

const fetchWithJWTRequest = (
  fetchUsingCredentials,
  {
    jwtRequestUrl,
    jwtRequestMethod,
    jwtRequestBody,
    jwtRequestHeaders,
    jwtHeaderKey,
    jwtHeaderMask,
  },
) => fetch(jwtRequestUrl, {
  method: jwtRequestMethod,
  body: JSON.stringify(jwtRequestBody),
  headers: new Headers({
    ...jwtRequestHeaders,
  }),
}).then(response => fetchUsingCredentials({
  [jwtHeaderKey]: jwtHeaderMask.replace(/(\$JWT\$)/, response.token),
}));

const authMethodHandlers = {
  [apiKey]: (fetch, authData) => fetchWithApiKeyAuth(fetch, authData),
  [basic]: (fetch, authData) => fetchWithBasicAuth(fetch, authData),
  [jwt]: (fetch, authData) => fetchWithJWTRequest(fetch, authData),
};

const fetchWithAuth = (
  url,
  authMethod,
  authData,
  { method, body },
) => {
  const fetchUsingCredentials = headers => fetch(url, {
    method,
    body: JSON.stringify(body),
    headers: new Headers({
      ...headers,
    }),
  });

  return authMethodHandlers[authMethod](fetchUsingCredentials, authData);
};

export default fetchWithAuth;
