import React, {
  useState,
  useEffect,
} from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { getEmailAddress } from 'helpers/auth';
import GravatarService from 'helpers/gravatarService';
import CustomPopup from 'components/Layout/CustomPopup';
import { withRouter } from 'react-router';
import API from 'helpers/API';
import cx from 'classnames';
import Spinner from '../Spinner';
import Sidebar from '../Sidebar';
import SidebarMobile from '../SidebarMobile';
import SearchBar from '../SearchBar';
import Popup from './Popup';
import PopUpClipboard from './PopUpClipboard';
import styles from './styles.scss';
import LayoutContext from './LayoutContext';

export function Layout({
  children,
  customPopup,
  popup,
  popClipboard,
  location: { pathname },
  isLoading,
}) {
  const sideBarLoadState = localStorage.getItem('sidebar.expanded') === 'true';
  const [menuExpanded, setMenuExpanded] = useState(sideBarLoadState);
  const [gravatar, setGravatar] = useState(null);
  const [shipmentMetadataGroupings, setShipmentMetadataGroupings] = useState([]);
  const [isLoadingShipmentMetadataGroupings, setIsLoadingShipmentMetadataGroupings] = useState(true);

  useEffect(() => {
    const emailAddress = getEmailAddress();
    if (emailAddress) {
      const hash = GravatarService.getEmailHash(emailAddress);

      const fetchGravatar = async () => {
        const response = await GravatarService.doesGravatarHashExist(hash);
        if (response) {
          setGravatar(GravatarService.getGravatarUrlForHash(hash));
        }
      };
      fetchGravatar();
    }

    const getShipmentMetadataGroupings = async () => {
      const response = await API.getShipmentMetadataGroupings();
      setShipmentMetadataGroupings(response.groupings);
      setIsLoadingShipmentMetadataGroupings(false);
    };
    getShipmentMetadataGroupings();
  }, []);

  function handleMenuExpanded(isMenuExpanded) {
    setMenuExpanded(isMenuExpanded);
  }

  return (
    <div className={styles.layoutWrapper}>
      <Popup {...popup} />
      <Sidebar gravatar={gravatar} pathname={pathname} handleExpanded={handleMenuExpanded} />
      <main className={cx(menuExpanded === true ? styles.sidebarExpanded : styles.sidebarCollapsed)}>
        <LayoutContext.Provider value={{
          shipmentMetadataGroupings,
          setShipmentMetadataGroupings,
          isLoading: isLoadingShipmentMetadataGroupings,
        }}
        >
          <div>
            <SearchBar />
          </div>
          <div className={styles.layoutTopbarMobile}>
            <SidebarMobile />
          </div>
          <div className={styles.content}>
            {children}
          </div>
        </LayoutContext.Provider>
      </main>
      <CustomPopup {...customPopup} />
      <PopUpClipboard {...popClipboard} />
      {isLoading && <Spinner menuExpanded={menuExpanded} />}
    </div>
  );
}

Layout.propTypes = {
  children: propTypes.node,
  location: propTypes.shape({
    pathname: propTypes.string.isRequired,
  }).isRequired,
};

Layout.defaultProps = {
  children: 'empty screen',
};

export default withRouter(connect((state, { location }) => ({
  location,
  popup: state.popup,
  customPopup: state.customPopup,
  popClipboard: state.popClipboard,
  isLoading: state.layout.isLoading,
}
))(Layout));
