function getFormErrorsFromApiError(apiError) {
  const formErrors = {};

  if (!apiError) {
    return formErrors;
  }

  if (apiError.details) {
    apiError.details.forEach((element) => {
      const propertyName = element.property;

      if (!formErrors[propertyName]) {
        formErrors[propertyName] = [];
      }

      formErrors[propertyName].push(element.message);
    });
  }

  formErrors.formError = [apiError.message];

  return formErrors;
}

function getErrorMessagesFromApiError(apiError) {
  if (apiError && Array.isArray(apiError.details)) {
    return apiError.details.map(x => x.message);
  }
  return [];
}

// sometimes we have names like: user._last_name and we need only last_name without first '_' underscore
function getNestedPropertyName(text) {
  const lastPropertyName = text.split('.').pop();
  if (lastPropertyName[0] === '_') {
    return lastPropertyName.substring(1);
  }
  return lastPropertyName;
}

function renameLabelNameInErrorMessage(errorMessage, currentLabelName, newLabelName) {
  if (!newLabelName) {
    return errorMessage;
  }

  const fixedCurrentLabelName = getNestedPropertyName(currentLabelName);
  return errorMessage.replace(fixedCurrentLabelName, newLabelName);
}

function mapErrorObjectPropertiesMessagesToInputNamesErrors(source, propertyNameMapObj) {
  const result = {};

  Object.keys(source).forEach((property) => {
    if (Object.prototype.hasOwnProperty.call(source, property)) {
      const propertyMap = propertyNameMapObj[property];

      if (propertyMap) {
        const errorMessage = source[property];
        const fixedErrorMessage = errorMessage
          .map(err => renameLabelNameInErrorMessage(err, property, propertyMap.label));
        result[propertyMap.inputName] = fixedErrorMessage;
      } else {
        result[property] = source[property];
      }
    }
  });

  return result;
}

export { getFormErrorsFromApiError, getErrorMessagesFromApiError, mapErrorObjectPropertiesMessagesToInputNamesErrors };
