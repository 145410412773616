import React, { Component } from 'react';
import { SketchPicker } from 'react-color';

import cx from 'classnames';
import styles from './styles.scss';

const defaultColor = '#000000';
class ColorPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      background: this.props.color || defaultColor,
      pickerActive: false,
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.color !== this.props.color) {
      this.setState({
        background: nextProps.color || defaultColor,
      });
    }
  }

  handleChangeComplete = color => this.setState({ background: color.hex });

  pickColor = () => {
    this.setState({ pickerActive: true });
  };

  handleColorSave = () => {
    this.props.update(this.state.background);
    this.setState({ pickerActive: false });
  };

  renderButtons = () => {
    const {
      background,
      pickerActive,
    } = this.state;
    let html;

    if (!pickerActive) {
      html = (
        <div className={pickerActive ? styles.buttonsActive : styles.buttonsInactive}>
          <button
            className={styles.button}
            type="button"
            onClick={this.pickColor}
          >
            Select
          </button>
          <div className={styles.square} style={{ background }} onClick={this.pickColor} role="button" />
        </div>
      );
    } else {
      html = (
        <div className={pickerActive ? styles.buttonsActive : styles.buttonsInactive}>
          <button
            className={cx(styles.button, styles.buttonPink)}
            type="button"
            onClick={this.handleColorSave}
          >
            Save
          </button>
          <div className={styles.square} style={{ background }} onClick={this.pickColor} role="button" />
        </div>
      );
    }

    return html;
  };

  render() {
    const { pickerActive } = this.state;
    const { title } = this.props;

    return (
      <div>
        <div
          role="button"
          className={styles.loginFormLabel}
          onClick={() => this.pickColor}
        >
          { title }
        </div>
        {this.renderButtons()}

        {pickerActive && (
          <div className={styles.colorPicker}>
            <SketchPicker
              color={this.state.background}
              onChangeComplete={this.handleChangeComplete}
            />
          </div>
        )}
      </div>
    );
  }
}

export default ColorPicker;
