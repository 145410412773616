import React, { Component } from 'react';
import EditDropdown from '../EditDropdown';
import styles from '../style.scss';
import InputWithValidation, { showErrorStrategies } from '../../InputWithValidation';

class LinkItem extends Component {
  constructor(props) {
    super(props);
    this.state = { isEditDropDownOpened: false };
  }

  toggleEditDropDown = () => this.setState(prevState => ({ isEditDropDownOpened: !prevState.isEditDropDownOpened }));

  render() {
    const { isEditDropDownOpened } = this.state;
    const {
      redirectionUrl,
      text,
      errors,
      touched,
      onChange,
      onTouch,
      alterOrder,
      deleteLink,
      canAlterOrder,
      innerRefs,
    } = this.props;

    return (
      <div className={styles.formGroup}>
        <div className={styles.link}>
          <div className={styles.miniFormGroup}>
            <InputWithValidation
              value={text}
              onChange={e => onChange('text', e.target.value)}
              onBlur={() => onTouch('text', true)}
              name="text"
              label="Text"
              className={styles.formElement}
              type="text"
              placeholder="Enter link name"
              errors={errors}
              touched={touched && touched.text}
              showErrorStrategy={showErrorStrategies.touched}
              innerRef={innerRefs.text}
            />
          </div>
          <div className={styles.miniFormGroup}>
            <InputWithValidation
              value={redirectionUrl}
              onChange={e => onChange('redirectionUrl', e.target.value)}
              onBlur={() => onTouch('redirectionUrl', true)}
              name="redirectionUrl"
              label="Url"
              className={styles.formElement}
              type="text"
              placeholder="Enter your link"
              errors={errors}
              touched={touched && touched.redirectionUrl}
              showErrorStrategy={showErrorStrategies.touched}
              innerRef={innerRefs.redirectionUrl}
            />
          </div>
          <div className={styles.miniFormGroup}>
            <button
              type="button"
              className={styles.miniFormGroupButton}
              onClick={this.toggleEditDropDown}
            >
              Edit
            </button>
            {isEditDropDownOpened && (
              <EditDropdown
                canMoveUp={canAlterOrder('up')}
                canMoveDown={canAlterOrder('down')}
                alterOrder={(e) => {
                  alterOrder(e);
                  this.toggleEditDropDown();
                }}
                deleteFn={deleteLink}
                toggleEditDropDown={this.toggleEditDropDown}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default LinkItem;
