export const Locale = {
  label: 'Locale',
  code: 'locale',
};
export const ShipmentState = {
  label: 'Shipment State',
  code: 'shipment_state',
};

const DisplayOptions = [
  Locale,
  ShipmentState,
];

export default DisplayOptions;
