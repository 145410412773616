import React, { Component } from 'react';
import Clipboard from 'react-clipboard.js';
import { connect } from 'react-redux';
import CardShell from 'components/CardShell';
import cx from 'classnames';
import Svg from 'components/Svg';

import {
  closeCustomPopup as closeCustomPopupAction,
  clearInputName as clearInputNameAction,
} from 'actions';

import styles from './ApiKeysList.scss';

class GeneratedKey extends Component {
  constructor(props) {
    super(props);

    this.state = {
      copied: false,
    };
  }

  render() {
    if (!this.props.value) return null;
    return (
      <CardShell>
        <div className={styles.createdSectionWrapper}>
          <div className={styles.iconWrapperSvg}>
            <Svg name="apiKey" />
          </div>
          <div className={styles.sectionHeaderWrapper}>
            <div className={styles.alertMessagesHeader}>
              <h3 className="textCenter">Here’s your API key.</h3>
              <input id="secret" type="text" value={this.props.value} readOnly className={styles.copyInput} />
              <p className="textCenter mb-0">
                Please copy and save it securely, as you won’t be able to view it again.
              </p>
              <p className="textCenter mt-2">To use your key, add it to the header of any requests you make.</p>
              {this.state.copied && (
                <div>
                  <p className="textCenter subtitle1 mb-0">API Key copied successfully.</p>
                  <p className="textCenter mt-2">Please keep it safe and save.</p>
                </div>
              )}
            </div>
          </div>

          <div className={styles.dualButtons}>
            <div
              role="button"
              className={cx(styles.webhookCtaBtn, styles.buttonLeft, 'firstDualButton')}
            >
              <Clipboard data-clipboard-text={this.props.value} onSuccess={() => this.setState({ copied: true })}>
                <span>Copy</span>
              </Clipboard>
            </div>
            <button
              type="button"
              onClick={this.props.onClose}
              className={styles.dualButton}
            >
              Accept
            </button>
          </div>
        </div>
      </CardShell>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  closeCustomPopup: () => {
    dispatch(clearInputNameAction());
    dispatch(closeCustomPopupAction());
  },
});

export default connect(state => ({ apiKeys: state.apiKeys }), mapDispatchToProps)(GeneratedKey);
