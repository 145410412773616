const mapObjectPropertiesToArray = source => Object.keys(source)
  .filter(propertyName => Object.prototype.hasOwnProperty.call(source, propertyName))
  .map(x => ({ propertyName: x, value: source[x] }));

const hasProperty = (obj, name) => Object.prototype.hasOwnProperty.call(obj, name);

const compareArrayByOrder = (a, b) => {
  if (a.order < b.order) return -1;
  if (a.order > b.order) return 1;
  return 0;
};

export {
  mapObjectPropertiesToArray,
  hasProperty,
  compareArrayByOrder,
};
