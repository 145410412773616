import React from 'react';
import LocalStorageTitle from 'components/localStorageTitle';
import BackButton from 'components/BackButton';
import Spinner from 'components/Spinner';
import ReactTable from 'react-table';
import StateCategoryRedSvg from 'images/stateCategoryRed.svg';
import ReactTooltip from 'react-tooltip';
import 'react-table/react-table.css';
import API from 'helpers/API';
import Svg from 'components/Svg';
import saveAs from 'helpers/saveAs';
import styles from '../styles.scss';

export class InvalidShipmentWebhooks extends React.Component {
  columns = [
    {
      Header: 'File Name',
      accessor: 'blob_name',
      Cell: cellInfo => this.downloadLink(cellInfo.value),
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      invalidShipmentFiles: [],
      isLoading: true,
      showError: false,
      errorFileName: '',
    };
  }

  componentDidMount() {
    const fetchData = async () => {
      await API.getInvalidRegisterShipmentFiles()
        .then((response) => {
          this.setState({ invalidShipmentFiles: response.invalid_register_shipment_files, isLoading: false });
        });
    };

    fetchData();
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  get shipmentIds() {
    return this.props.location.state ? this.props.location.state.shipmentIds : null;
  }

  downloadLink = blobName => (
    <div className={styles.downloadLinkContainer} onClick={() => this.handleClick(blobName)} role="button">
      <label>{blobName.substr(blobName.indexOf('/') + 1)}</label><Svg className={styles.icon} name="export" />
      {this.state.showError && this.state.errorFileName === blobName
        && (
          <>
            <div data-tip="There was a problem downloading this file.">
              <div className={styles.error}>
                <StateCategoryRedSvg />
              </div>
            </div>
            <ReactTooltip type="dark" border effect="solid" multiline />
          </>
        )}
    </div>
  )

  handleClick = (blobName) => {
    const downloadFileName = blobName.substr(blobName.indexOf('/') + 1);
    this.setState({ isLoading: true, showError: false }, () => {
      API.downloadInvalidShipmentFile(blobName)
        .then((blob) => {
          saveAs(blob, downloadFileName);
        })
        .catch(() => this.setState({ isLoading: false, showError: true, errorFileName: blobName }))
        .finally(() => this.setState(prev => ({ ...prev, isLoading: false })));
    });
  };

  handleRetry = () => {
    this.getData();
  };

  handleBackButtonClick = () => {
    const { history } = this.props;
    history.push('/settings/register-shipment-webhooks/');
  };

  render() {
    const {
      requestDidTimeout,
    } = this.props;
    const {
      isLoading,
    } = this.state;

    const { invalidShipmentFiles } = this.state;

    const totalInvalidShipmentFilesCount = invalidShipmentFiles ? invalidShipmentFiles.length : 0;

    const hasInvalidShipmentFiles = invalidShipmentFiles && invalidShipmentFiles.length > 0;

    return (
      <div className={styles.mainArea}>
        {isLoading ? <Spinner /> : null}
        <div><BackButton onBack={this.handleBackButtonClick} /></div>
        <LocalStorageTitle localStorageName="invalidRegisterShipmentPageText" pageTitle="Invalid Register Shipment Emails">
          <div className="text_container">
            <div className="informationalTextBlock">
              <p>Here are all your files from last 30 days containing any invalid register shipment emails. The files are named after the date they are created. Every file contains all invalid register shipment emails from the day it was created.</p>
            </div>
          </div>
        </LocalStorageTitle>

        <div className={styles.buttonBlock}>
          <div><p>Total Files: {totalInvalidShipmentFilesCount}</p></div>
        </div>

        {hasInvalidShipmentFiles
          && (
            <div className={styles.reactTableHolder}>
              <ReactTable
                data={invalidShipmentFiles}
                columns={this.columns}
                multiSort={false}
                loading={isLoading}
                showPaginationBottom={false}
                defaultPageSize={invalidShipmentFiles.length}
                defaultSorted={[
                  {
                    id: 'file_name',
                    desc: true,
                  },
                ]}
              />
            </div>
          )}
        {!isLoading && !hasInvalidShipmentFiles && !requestDidTimeout && <p>There were no invalid shipment emails received for last 30 days, which most probably means that all the emails sent for registering shipments, have been processed successfully.</p>}
        {requestDidTimeout
          && (
            <>
              <p>We are temporarily unable to load data. Please try again later.</p>
              <div
                className={styles.button}
                onClick={this.handleRetry}
                role="button"
              >
                Retry
              </div>
            </>
          )}
      </div>
    );
  }
}

export default InvalidShipmentWebhooks;
