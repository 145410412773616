import React from 'react';
/* eslint-disable */

export default props => (
  <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 100 100'>
    <circle fill='#00854D' cx='50' cy='50' r='46.7'/>
    <g>
      <g>
        <g>
          <path fill='#FFFFFF' d='M49.9,0.3C22.5,0.3,0.2,22.6,0.2,50s22.3,49.7,49.7,49.7S99.6,77.4,99.6,50C99.6,22.6,77.4,0.3,49.9,0.3     L49.9,0.3z M49.9,93.5C25.9,93.5,6.4,74,6.4,50S25.9,6.5,49.9,6.5S93.4,26,93.4,50S73.9,93.5,49.9,93.5L49.9,93.5z'/>
        </g>
      </g>
      <g>
        <path fill='#FFFFFF' d='M45,69.9c-0.6,0-1.2-0.2-1.7-0.7L25.9,52.6c-1-0.9-1-2.5-0.1-3.5c1-1,2.5-1,3.5-0.1l15.4,14.7l25.6-32.6    c0.8-1.1,2.4-1.3,3.5-0.4c1.1,0.8,1.3,2.4,0.4,3.5L46.9,68.9c-0.4,0.6-1.1,0.9-1.8,0.9C45.1,69.9,45,69.9,45,69.9z'/>
      </g>
    </g>
  </svg>
)

/* eslint-enable */
