import React, { Component } from 'react';
import cx from 'classnames';
import Svg from 'components/Svg';
import { event as gaEvent } from 'react-ga';
import Switch from 'components/Switch';
import StateItem from './StateItem';
import OutsideClickAlerter from '../OutsideClickAlerter';
import StateFilter from './StateFilter';
import styles from './styles.scss';

class StatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: 'all',
      regexFilter: '[a-c]',
      isFilterMenuOpened: false,
      filterTerm: '',
    };
  }

  setFilter = (filterTerm) => {
    gaEvent({
      category: 'ShipmentStatPicker',
      action: 'Filter by character',
    });
    this.setState({ regexFilter: filterTerm });
  };

  getActiveMenuStyles(regexTerm) {
    const { regexFilter, filterTerm } = this.state;
    if (regexFilter === regexTerm && !filterTerm) {
      return cx(styles.headerButton, styles.headerButtonActive);
    }
    return styles.headerButton;
  }

  handleInput = (event) => {
    gaEvent({
      category: 'ShipmentStatPicker',
      action: 'Manual search',
    });
    this.setState({ filterTerm: event.target.value });
  };

  handleSelectCategoryCloseFilter = (category) => {
    gaEvent({
      category: 'ShipmentStatPicker',
      action: 'Filter by RAG status',
    });
    if (category) {
      this.setState({ filter: category, isFilterMenuOpened: false });
    } else {
      this.setState({ isFilterMenuOpened: false });
    }
  };

  renderList = () => {
    // eslint-disable-next-line prefer-regex-literals
    const regex = this.state.filterTerm ? new RegExp('.', 'g') : new RegExp(this.state.regexFilter, 'g');

    const filteredArray = this.props.states.filter(el => (
      el.state_name.substring(0, 1).toLowerCase().match(regex)
      && (
        this.state.filter !== 'all'
          ? el.category === this.state.filter
          : true
      )
      && (!this.props.formData.is_carrier_state_filter_enabled || el.is_supported_by_selected_carriers)
    ));

    const searchTerm = this.state.filterTerm;
    return (
      searchTerm.length > 0
        ? filteredArray.filter(el => el.state_name.toLowerCase().includes(searchTerm.toLowerCase()))
        : filteredArray
    );
  };

  render() {
    const states = this.renderList();
    const { filterTerm, isFilterMenuOpened } = this.state;
    const { formData, applyCarrierStateFilter } = this.props;
    return (
      <div>
        <div className={styles.supportedByAllCarriers}>
          <span>States supported by all selected carriers</span>
          <Switch
            enabled={formData.is_carrier_state_filter_enabled}
            toggle={applyCarrierStateFilter}
            isLabelEnabled={false}
            disabled={!formData.carrier_integrations.length}
          />
        </div>

        <div className={styles.filterSearch}>
          <Svg className={styles.filterSearchGlass} name="searchGlass" />
          <input
            className={styles.filterSearchInput}
            type="text"
            value={filterTerm}
            onChange={this.handleInput}
            placeholder="Search Shipment states"
          />
        </div>
        <div className={styles.headerOuter}>
          <div className={styles.header}>
            <button
              type="button"
              className={this.getActiveMenuStyles('[a-c]')}
              onClick={() => this.setFilter('[a-c]')}
            >
              A - C
            </button>
            <button
              type="button"
              className={this.getActiveMenuStyles('[d-e]')}
              onClick={() => this.setFilter('[d-e]')}
            >
              D - E
            </button>
            <button
              type="button"
              className={this.getActiveMenuStyles('[f-l]')}
              onClick={() => this.setFilter('[f-l]')}
            >
              F - L
            </button>
            <button
              type="button"
              className={this.getActiveMenuStyles('[m-z]')}
              onClick={() => this.setFilter('[m-z]')}
            >
              M - Z
            </button>
            <button
              type="button"
              className={styles.headerButtonLight}
              onClick={() => this.setState({ isFilterMenuOpened: true })}
            >
              <div className={styles.filterButtonInner}>
                <div>Filter</div>
                <Svg className={styles.filterIcon} name="filter" />
              </div>
            </button>
          </div>
          {isFilterMenuOpened && (
            <OutsideClickAlerter onClickOutside={this.handleSelectCategoryCloseFilter}>
              <StateFilter
                onHandleCategorySelect={this.handleSelectCategoryCloseFilter}
              />
            </OutsideClickAlerter>
          )}
        </div>
        <ul className={styles.stateList}>
          {states && states.map(state => (
            <li key={state.state_key} className={styles.stateListItem}>
              <StateItem
                updateState={this.props.updateState}
                formData={this.props.formData}
                stateKey={state.state_key}
                category={state.category}
                name={state.state_name}
              />
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default StatePicker;
