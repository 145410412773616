import React from 'react';

/* eslint-disable */
export default props => (
  <svg {...props}  xmlns='http://www.w3.org/2000/svg' viewBox='0 0 59.6 42.1'>
    <path d='M0.7,23.1l18.7,18.6c0.6,0.6,1.7,0.6,2.3,0l0.6-0.6c0.6-0.6,0.6-1.7,0-2.3L6.7,23.2H58c0.9,0,1.6-0.7,1.6-1.6v-0.8 c0-0.9-0.7-1.6-1.6-1.6H6.6L22.3,3.4C23,2.7,23,1.7,22.3,1l-0.6-0.6c-0.6-0.6-1.7-0.6-2.3,0L0.7,19.2'/>
    <path d='M0.7,19.2C0.3,19.5,0,20.1,0,20.6l0,1c0,0.6,0.3,1.1,0.7,1.4'/>
  </svg>
)

/* eslint-enable */
