import React from 'react';
import styles from './styles.scss';
import TriangleExclamationRegular from '../../images/TriangleExclamationRegular.svg';

const PageErrorTitle = ({ title, message }) => (
  <div>
    <h1 className="h2">{title}</h1>

    <div className={styles.errorContainer}>
      <div className={styles.warningIcon}>
        <TriangleExclamationRegular width={20} height={20} />
      </div>
      <div className={styles.errorInner}>
        <div className={styles.errorInnerMessage}>
          {message}
        </div>
      </div>
    </div>
  </div>
);

export default PageErrorTitle;
