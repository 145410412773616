import React from 'react';
import Svg from 'components/Svg';
import styles from './styles.scss';

const CreateCard = ({ text, handleClick, thin }) => (
  <div
    role="button"
    onClick={handleClick}
    className={styles.btnCreateNewWrapper}
  >
    <div className={styles.btnHeightFix}>
      <div className={!thin ? styles.btnCreateNew : styles.btnCreateNewThin}>
        <div>
          <Svg className={styles.plusCircleIcon} name="plusCircle" />
        </div>
        <span className={`${styles.btnCreateInner} h4`}>{text}</span>
      </div>
    </div>
  </div>
);

export default CreateCard;
