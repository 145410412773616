const spreadStateToShipments = (states) => {
  const accumulator = [];
  states.forEach((state) => state.shipments.forEach((shipment) => accumulator.push({
    ...shipment,
    name: state.name,
  })));
  return accumulator;
};

const initialState = {
  isLoading: false,
  markers: {
    red: {
      color: 'rgb(193, 37, 71)',
      points: [],
    },
    amber: {
      color: 'rgb(199, 82, 12)',
      points: [],
    },
    green: {
      points: [],
      color: 'rgb(0, 133, 77)',
    },
  },
};

export default function mapboxReducer(state = initialState, action) {
  switch (action.type) {
    case 'SHIPMENTS_MARKERS_FETCH':
      return {
        ...state,
        isLoading: true,
      };
    case 'SHIPMENTS_MARKERS_SUCCESS':
      return {
        markers: {
          red: {
            ...state.markers.red,
            points: spreadStateToShipments(action.markers.red),
          },
          amber: {
            ...state.markers.amber,
            points: spreadStateToShipments(action.markers.amber),
          },
          green: {
            ...state.markers.green,
            points: spreadStateToShipments(action.markers.green),
          },
        },
        isLoading: false,
      };
    case 'SHIPMENTS_MARKERS_ERROR':
      return {
        isLoading: false,
        markers: initialState.markers,
      };
    default:
      return state;
  }
}
