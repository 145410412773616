import React from 'react';
import ReactDOM from 'react-dom';
import { trackInactivity } from 'helpers/inactivity';
import { Route, Switch } from 'react-router';
import { initialize } from 'react-ga';
import { initDatadog } from 'helpers/datadog';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import ReduxRouterWrapper from './ReduxRouterWrapper';
import LoginRegisterPage from './routes/LoginRegisterPage';
import routes from './routes';

import './index.scss';

initDatadog();
initialize('UA-132237414-1');

ReactDOM.render(
  <ErrorBoundary>
    <ReduxRouterWrapper>
      <Switch>
        <Route exact path="/login" component={LoginRegisterPage} />
        <Route path="/" component={routes} />
      </Switch>
    </ReduxRouterWrapper>
  </ErrorBoundary>,
  document.getElementById('root'),
);
trackInactivity();
