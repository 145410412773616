import { splitSnakeCaseString } from 'helpers/letterCase';

function getSinkTypeName(type) {
  if (type === 'sms') {
    return 'SMS';
  }
  return splitSnakeCaseString(type);
}

export default getSinkTypeName;
