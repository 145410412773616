import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import API from 'helpers/API';
import casing from 'casing';
import Spinner from 'components/Spinner';
import InputWithValidation from 'components/InputWithValidation';
import SelectWithValidation from 'components/SelectWithValidation';
import { Link } from 'react-router-dom';
import { closePopup as closePopupAction, showPopup as showPopupAction } from 'actions';
import styles from '../styles.scss';

function NotificationValues({
  notification: {
    name, filterId, templateReference, template, trackingPageName, sinkConfigurationId, isActive = false,
  }, setNotification,
  configurationType,
  errors,
  showPopup,
  closePopup,
  history,
}) {
  const [shipmentFilters, setShipmentFilters] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [trackingPages, setTrackingPages] = useState([]);
  const [loading, setLoading] = useState(true);

  const setNotificationProp = (propName, value) => {
    setNotification(n => ({
      ...n,
      [propName]: value,
    }));
  };

  const handleInputChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setNotificationProp(e.target.name, value);
  };

  const handleSelectChange = (e) => {
    setNotificationProp(e.name, e.value);
  };

  const getSinkTemplates = useCallback(async (id) => {
    try {
      return await API.getSinkTemplates(id);
    } catch (e) {
      showPopup({
        title: 'Error',
        description: 'We couldn\'t retrieve email templates. Please check the connector configuration.',
        onDecline: () => {
          history.push('/settings/notification-centre/notification-setup');
          closePopup();
        },
      });
    }
    return null;
  }, [history, showPopup, closePopup]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      const [trackingPagesResponse, shipmentFiltersResponse, templatesResponse] = await Promise.all([
        API.getTrackingPages(),
        API.getShipmentFilters(),
        configurationType === 'email' ? getSinkTemplates(sinkConfigurationId) : Promise.resolve(),
      ]);
      const pages = casing.camelize(trackingPagesResponse).trackingPageList || [];
      setTrackingPages(pages);

      const filters = casing.camelize(shipmentFiltersResponse).shipmentFilters || [];
      setShipmentFilters(filters);

      if (templatesResponse) {
        const loadedTemplates = templatesResponse.sinkTemplates || [];
        setTemplates(loadedTemplates);
      }

      setLoading(false);
    }

    fetchData();
  }, [sinkConfigurationId, configurationType, getSinkTemplates]);

  if (loading) {
    return (
      <Spinner />
    );
  }

  if (templateReference && templates.every(x => x.reference !== templateReference)) {
    setNotificationProp('templateReference', null);
  }
  if (trackingPageName && trackingPages.every(x => x.trackingPageName !== trackingPageName)) {
    setNotificationProp('trackingPageName', null);
  }
  if (filterId && shipmentFilters.every(x => x.id !== filterId)) {
    setNotificationProp('filterId', null);
  }

  return (
    <div>
      <div className={styles.formItem}>
        <InputWithValidation
          label="Notification name"
          placeholder="Notification Name"
          onChange={handleInputChange}
          value={name}
          type="text"
          name="name"
          errors={errors}
          required
        />
      </div>
      <div className={styles.formItem}>
        <SelectWithValidation
          label="Select shipment filter"
          name="filterId"
          value={filterId}
          onChange={handleSelectChange}
          options={shipmentFilters.map(x => ({ value: x.id, label: x.name }))}
          required
          errors={errors}
        />
        <Link to="/settings/shipment-filters" className={styles.shippingLink}>
          Create shipment filter
        </Link>
      </div>
      {configurationType === 'email' ? (
        <div className={styles.formItem}>
          <SelectWithValidation
            label="Select email template"
            name="templateReference"
            value={templateReference}
            onChange={handleSelectChange}
            options={templates.map(x => ({ value: x.reference, label: x.name }))}
            required
            errors={errors}
          />
        </div>
      ) : (
        <div className={styles.formItem}>
          <InputWithValidation
            name="template"
            value={template}
            inputTag="textarea"
            onChange={handleInputChange}
            errors={errors}
            label="Enter text for SMS"
            placeholder="Hello..."
            required
          />
        </div>
      )}
      <div className={styles.formItem}>
        <SelectWithValidation
          label="Select tracking page (optional)"
          name="trackingPageName"
          value={trackingPageName}
          defaultValue={null}
          onChange={handleSelectChange}
          options={[{
            value: null,
            label: 'None',
          }, ...trackingPages.map(x => ({
            value: x.trackingPageName,
            label: x.trackingPageName,
          }))]}
          errors={errors}
        />
      </div>
      <div className={styles.formItem}>
        <label>
          <input
            className={styles.checkbox}
            name="isActive"
            type="checkbox"
            checked={isActive}
            onChange={handleInputChange}
          />
          <span className={styles.checkBoxLabel}>Make this notification active</span>
        </label>
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  showPopup: config => dispatch(showPopupAction(config)),
  closePopup: () => dispatch(closePopupAction()),
});

export default connect(null, mapDispatchToProps)(NotificationValues);
