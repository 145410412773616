import API from 'helpers/API';

import {
  WEBHOOK_FETCH,
  WEBHOOK_SUCCESS,
  REVOKE_WEBHOOK_SUCCESS,
  CREATE_WEBHOOK_FETCH,
  CREATE_WEBHOOK_SUCCESS,
  TOGGLE_WEBHOOK,
  SEND_WEBHOOK,
  SEND_WEBHOOK_SUCCESS,
  SEND_WEBHOOK_ERROR,
} from './types';

export function getWebhooks() {
  return (dispatch) => {
    dispatch({ type: WEBHOOK_FETCH });

    API.getWebhooks('webhooks').then((response) => {
      dispatch({ type: WEBHOOK_SUCCESS, webhooks: response.webhook_summaries, versions: response.payload_versions });
    });
  };
}

export function revokeWebhook(id) {
  return dispatch => API.deleteWebhook(id).then(() => Promise.resolve(() => dispatch(
    { type: REVOKE_WEBHOOK_SUCCESS, id },
  )));
}

export function createWebhook(webhook) {
  return (dispatch) => {
    dispatch({ type: CREATE_WEBHOOK_FETCH, webhook });

    return API.createWebhook({ name: webhook.configuration.name }).then((response) => {
      dispatch({ type: CREATE_WEBHOOK_SUCCESS, webhook: response.webhook });
      return Promise.resolve(response);
    });
  };
}

export function toggleWebhook(id, webhookActive) {
  return (dispatch, getState) => {
    const { webhooks } = getState().webhooksStates;
    const webhook = webhooks.find(item => item.configuration_id === id);
    webhook.enabled = !webhook.enabled;
    API.updateWebhook(webhook.configuration_id, webhook, webhookActive)
      .then(() => {
        dispatch({
          type: TOGGLE_WEBHOOK,
          webhooks,
          webhookActive,
        });
      });
  };
}

export const sendWebhook = (url, body) => (dispatch) => {
  dispatch({ type: SEND_WEBHOOK });
  return API.put(url, body)
    .then((result) => {
      dispatch({ type: SEND_WEBHOOK_SUCCESS, result });
    })
    .catch((error) => {
      dispatch({ type: SEND_WEBHOOK_ERROR });
      return Promise.reject(error);
    });
};
