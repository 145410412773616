/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useResizeDetector } from 'react-resize-detector';
import API from '../../../helpers/API';
import Spinner from '../../../components/Spinner';
import Tabs from '../../../components/Tabs';
import PageErrorTitle from '../../../components/PageErrorTitle';
import 'chart.js/auto';
import NavHorizontal from '../../../components/NavHorizontal';
import buildStatisticsPageLinks from './emailStatisticsMenuPaths';
import EmailStatisticsContext from './EmailStatisticsContext';
import TitleNavBlock from '../../../components/TitleNavBlock/TitleNavBlock';
import typography from '../../../helpers/appTypography';
import styles from './styles.scss';

/**
 * This has been refactored to become a higher order component which wraps the content required
 * for statistics pages. The page route should point to the sub pages, which in turn will
 * import this component - this provides the server load logic, tabs and menu structure to each page
 *
 * @param {*} { children, pageTitle, pageMessage, id, emailActivityId }
 * @returns
 */
const EmailStatistics = ({ children }) => {
  const { width, ref } = useResizeDetector();
  const [isLoadingServers, setIsLoadingServers] = useState(true);
  const [servers, setServers] = useState([]);
  const [isServerError, setIsServerError] = useState(false);
  const [selectedServerId, setSelectedServerId] = useState();
  // eslint-disable-next-line no-unused-vars
  const [, setPageTitle] = useState([]);
  const [, setPageMessage] = useState([]);
  const [prevPage, setPrevPage] = useState('/');

  const history = useHistory();

  const processFetchServersResponse = useCallback((response) => {
    const filtered = response.emailServers.filter((el) => el != null && el.isActive === true);
    setServers(filtered);
    return filtered;
  }, []);

  const fetchServers = async () => {
    setIsServerError(false);
    setIsLoadingServers(true);

    let serverList = [];
    try {
      const response = await API.getServers();
      serverList = processFetchServersResponse(response);
    } catch (e) {
      setIsServerError(true);
    }
    setIsLoadingServers(false);
    return serverList;
  };

  useEffect(() => {
    fetchServers();
    setPrevPage(history.location?.state?.prevPath);
  }, []);

  const handleTabClick = (serverId) => {
    history.push(`/reporting/email-statistics/${serverId}`);
  };

  const loadDefaultServer = async () => {
    const list = await fetchServers();
    history.push(`/reporting/email-statistics/${list[0].id}`);
    setSelectedServerId(list[0].id);
  };

  const providerProps = useMemo(() => ({
    setPageTitle, setPageMessage, setSelectedServerId, loadDefaultServer,
  }), []);

  const goBack = () => {
    history.push(prevPage);
  };

  return (
    <EmailStatisticsContext.Provider value={providerProps}>
      <div ref={ref}>
        {isLoadingServers ? <Spinner /> : null}

        {!isLoadingServers && (servers?.length === 0 || isServerError) && (
        <PageErrorTitle title="Email Statistics" message="You currently do not have access to Email statistics" />
        )}

        {!isLoadingServers && servers?.length > 0 && (
        <>
          <TitleNavBlock
            title={typography.emailStatistics.title}
            subTitle={typography.emailStatistics.subTitle}
            onBack={goBack}
          />
          <div className={styles.innerContainer}>
            <Tabs
              args={{
                data: servers,
                selectedTabId: selectedServerId,
                pageWidth: width,
                onTabClicked: handleTabClick,
              }}
            />
            <NavHorizontal data={buildStatisticsPageLinks(selectedServerId)} />
            {children}
          </div>
        </>
        )}
      </div>
    </EmailStatisticsContext.Provider>
  );
};

export default EmailStatistics;
