import React, { Component } from 'react';
import {
  string, func, arrayOf, shape,
} from 'prop-types';
import cx from 'classnames';
import BackButton from 'components/BackButton';
import Svg from 'components/Svg';
import DualButtons from 'components/DualButtons';
import InputButtonGroup from 'components/InputButtonGroup';
import { ValidationErrors } from 'components/InputWithValidation';
import {
  maxLength,
  required,
} from 'helpers/validate';
import { validateForm } from 'helpers/validateForm';
import Hr from 'components/CardShell/Hr';
import Header from 'components/CardShell/Header';
import styles from './styles.scss';

const maxItemsCount = 20;

class ShipmentTags extends Component {
  static propTypes = {
    title: string.isRequired,
    pageTabs: func.isRequired,
    cancel: func.isRequired,
    gotoPage: func.isRequired,
    formData: shape({
      tags: arrayOf(string).isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.validationRules = {
      inputValue: [
        required(),
        maxLength(30),
        // component specific rules
        {
          name: 'noDuplicates',
          message: () => 'You have already added this tag',
          isValid: value => this.state.tags
            && this.state.tags
              .findIndex(t => t.toLowerCase() === value.trim().toLowerCase()) === -1,
        },
        {
          name: 'itemsCount',
          message: () => `Tags must contain less than ${
            maxItemsCount + 1
          } items. The list contains ${this.state.tags.length} element(s)`,
          isValid: () => this.state.tags
            && this.state.tags.length < maxItemsCount,
        },
      ],
    };

    this.state = {
      inputValue: '',
      tags: this.props.formData.tags,
      errors: null,
    };
  }

  handleInputValueChange = ({ target: { value } }) => {
    this.setState({ inputValue: value });
  };

  handleClick = () => {
    const errors = validateForm(this.validationRules, this.state);
    if (errors.hasErrors) {
      this.setState({ errors });
      return;
    }

    const { tags, inputValue } = this.state;
    this.setState({
      inputValue: '',
      tags: [inputValue.trim(), ...tags],
      errors: null,
    }, this.saveCurrentState);
  };

  handleItemClick = (tag) => {
    this.setState(prevState => ({
      ...prevState,
      tags: prevState.tags.filter(t => t !== tag),
    }), this.saveCurrentState);
  };

  updateSelectedItems = (callback) => {
    this.props.updateState({
      tags: this.state.tags,
    }, callback);
  };

  handleSectionChange = () => {
    this.updateSelectedItems(() => this.props.gotoPage(6));
  };

  saveCurrentState = () => this.props.updateState({
    tags: this.state.tags,
  });

  render() {
    const {
      title,
      pageTabs,
      gotoPage,
      cancel,
    } = this.props;
    const isValid = this.state.errors === null;

    return (
      <div className={styles.cardsection}>
        <div className={styles.cardSectionInnerFullSize}>
          <div className={styles.innerContent}>
            <Header>{title}</Header>
            <Hr />
            <div className={styles.buttonBack}>
              <BackButton onBack={() => gotoPage(4)} />
            </div>
            {pageTabs()}
            <p className={cx(
              styles.innerContentTitle,
            )}
            >
              Add the name of your tags below
            </p>
            <div
              className={!isValid && styles.inputErrorContainer}
            >
              <div className={styles.inputGroup}>
                <ValidationErrors
                  errors={this.state.errors}
                  label="tag"
                  name="inputValue"
                />
                <InputButtonGroup
                  value={this.state.inputValue}
                  onChange={this.handleInputValueChange}
                  onClick={this.handleClick}
                  isValid={isValid}
                  buttonTitle="+"
                  placeholder="Add tag name"
                />
              </div>
            </div>

          </div>
          <div className={styles.tags}>
            {this.state.tags.map(tag => (
              <div key={tag} className={styles.tag}>
                <div className={styles.tagTitle}>{ tag }</div>
                <button
                  type="button"
                  className={styles.labelClear}
                  onClick={() => this.handleItemClick(tag)}
                >
                  <Svg name="circleXmarkSolid" />
                </button>
              </div>
            ))}
          </div>
        </div>
        <DualButtons
          leftFunction={cancel}
          leftText="Cancel"
          rightFunction={this.handleSectionChange}
          rightText="Next"
          theme="rightPink"
        />
      </div>
    );
  }
}

export default ShipmentTags;
