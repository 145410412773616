import React from 'react';
import CardShell from 'components/CardShell';
import FilePicker from 'components/FilePicker';
import SupportedImageExtensions from 'constants/trackingPage/supportedImageExtensions';
import InputWithValidation, { showErrorStrategies } from 'components/InputWithValidation';
import Hr from 'components/CardShell/Hr';
import Switch from 'components/Switch';
import Font from '../Font';

import styles from '../style.scss';

function General({
  name,
  title,
  icon,
  touched,
  errors,
  font,
  isCarrierLinkActive,
  isCarrierLinkActiveToggle,
  noRename,
  onChange,
  onTouch,
  innerRefs,
}) {
  return (
    <CardShell>
      <div className={styles.cardInner}>
        <h3 className={styles.title}>General</h3>
        <Hr />
        <div className={styles.formGroup}>
          <InputWithValidation
            value={name}
            name="name"
            label="Tracking page name"
            type="text"
            placeholder="Enter tracking page name"
            errors={errors}
            touched={touched && touched.name}
            showErrorStrategy={showErrorStrategies.touched}
            disabled={noRename}
            onChange={e => onChange('name', e.target.value)}
            onBlur={() => onTouch('name', true)}
            innerRef={innerRefs.name}
          />
        </div>
        <div className={styles.formGroup}>
          <InputWithValidation
            value={title}
            name="title"
            label="Title"
            type="text"
            placeholder="Enter your title"
            errors={errors}
            touched={touched && touched.title}
            showErrorStrategy={showErrorStrategies.touched}
            onChange={e => onChange('title', e.target.value)}
            onBlur={() => onTouch('title', true)}
            innerRef={innerRefs.title}
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.formLabel}>Carrier link</label>
          <div className={styles.switch}>
            <Switch toggle={isCarrierLinkActiveToggle} enabled={isCarrierLinkActive} />
          </div>
        </div>
        <div className={styles.formGroup}>
          <label className={styles.formLabel}>Favicon</label>
          <FilePicker
            value={icon}
            onChange={image => onChange('base64FavIcon', image)}
            multiple={false}
            accept={SupportedImageExtensions}
          />
        </div>
        <Font
          value={font}
          errors={errors && errors.font}
          touched={touched && touched.font}
          hideReset
          onChange={v => onChange('font', v)}
          onTouch={v => onTouch('font', v)}
          innerRefs={innerRefs.font}
        />
      </div>
    </CardShell>
  );
}

export default General;
