import React, { Component } from 'react';
import { connect } from 'react-redux';
import { event as gaEvent } from 'react-ga';
import { withRouter } from 'react-router-dom';
import Switch from 'components/Switch';
import DualButtons from 'components/DualButtons';
import {
  showPopup as showPopupAction,
  closePopup as closePopupAction,
  getEditConfiguration as getEditConfigurationAction,
  setEditConfiguration as setEditConfigurationAction,
  deleteConfiguration as deleteConfigurationAction,
  enableConfiguration as enableConfigurationAction,
  disableConfiguration as disableConfigurationAction,
} from 'actions';
import styles from './styles.scss';

export class CConfiguration extends Component {
  handleEditClick = () => {
    gaEvent({
      category: 'CarrierConnector',
      action: 'Edit existing configuration',
    });
    this.props.history.push(`/settings/carrier-connectors/edit/${this.props.configuration.configurationId}`);
  };

  handleDeleteClick = () => this.props.deleteConfiguration(this.props.configuration.configurationId);

  handleEnableDisableClick = () => {
    if (this.props.configuration.isEnabled) {
      gaEvent({
        category: 'CarrierConnector',
        action: 'Enabled configuration',
      });
      this.props.disableConfiguration(this.props.configuration.configurationId);
    } else {
      gaEvent({
        category: 'CarrierConnector',
        action: 'Disabled configuration',
      });
      this.props.enableConfiguration(this.props.configuration.configurationId);
    }
  };

  render() {
    return (
      <div className={styles.container}>
        <h3 className={`textCenter subtitle1 ${styles.cardTitle}`}>{this.props.trackingService.carrier}</h3>
        <p className={`textCenter ${styles.cardCopy}`}>{this.props.configuration.name}</p>
        <div className={styles.logoHolder}>
          <img
            className={styles.logo}
            src={this.props.trackingService.carrierLogoUrl}
            alt=""
          />
        </div>
        <div className={styles.switcherHolder}>
          <Switch
            id={this.props.configuration.name}
            enabled={this.props.configuration.isEnabled}
            toggle={this.handleEnableDisableClick}
          />
        </div>
        {!this.props.configuration.name.length && (<div className={styles.dualButttonsPush} />)}
        <DualButtons
          rightText="Disconnect"
          rightFunction={() => {
            gaEvent({
              category: 'CarrierConnector',
              action: 'Delete existing connector',
            });
            this.props.showPopup({
              title: 'Are you sure?',
              description: 'Deleting this connector is permanent.',
              onAccept: () => this.handleDeleteClick().then(() => {
                this.props.closePopup();
              }),
            });
          }}
          leftText="Edit"
          leftFunction={this.handleEditClick}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showPopup: config => dispatch(showPopupAction(config)),
  closePopup: config => dispatch(closePopupAction(config)),
  getEditConfiguration: id => dispatch(getEditConfigurationAction(id)),
  deleteConfiguration: id => dispatch(deleteConfigurationAction(id)),
  enableConfiguration: id => dispatch(enableConfigurationAction(id)),
  disableConfiguration: id => dispatch(disableConfigurationAction(id)),
  setEditConfiguration: configuration => dispatch(setEditConfigurationAction(configuration)),
});

export default withRouter(connect(state => ({
  editConfiguration: state.editConfiguration,
}), mapDispatchToProps)(CConfiguration));
