import React from 'react';
import cx from 'classnames';
import styles from './styles.scss';

const LandingSummaryBox = ({ style, title, shipmentCount }) => (
  <div
    className={cx(styles.boxWrapper)}
    style={style}
  >
    <span className={styles.boxTitle}>{title}</span>
    <span className={styles.boxCount}>{shipmentCount}</span>
  </div>
);

export default LandingSummaryBox;
