import React from 'react';
import API from 'helpers/API';
import NotificationConfigurationForm
  from 'routes/Settings/Notifications/NotificationConnectors/NotificationConfigurationForm';
import typography from '../../../../helpers/appTypography';

function EditNotificationConfiguration({ match: { params: { configurationId } }, history }) {
  const loadSinkConfiguration = async () => {
    const sinkConfigurationResponse = await API.getSinkConfiguration(configurationId);
    const notificationSinkResponse = await API.getNotificationSink(
      sinkConfigurationResponse.configuration.notificationSinkId,
    );

    sinkConfigurationResponse.values = sinkConfigurationResponse.configuration.values.map((value) => {
      const extendedValue = value;
      extendedValue.description = notificationSinkResponse.sink.properties.find(x => x.name === value.name).description;
      return extendedValue;
    });

    return {
      ...sinkConfigurationResponse.configuration,
      sinkName: notificationSinkResponse.sink.name,
    };
  };

  const saveSinkConfiguration = async (sinkConfiguration) => {
    const valuesArr = sinkConfiguration.values.map(x => ({
      name: x.name,
      value: x.value,
    }));

    await API.editSinkConfiguration({
      id: configurationId,
      name: sinkConfiguration.name,
      values: valuesArr,
    });
  };

  return (
    <NotificationConfigurationForm
      load={loadSinkConfiguration}
      save={saveSinkConfiguration}
      title={typography.editNotificationConnection.title}
      mode="Edit"
      history={history}
    />
  );
}

export default EditNotificationConfiguration;
