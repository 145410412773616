import React from 'react';
import Svg from 'components/Svg';

import styles from './styles.scss';

const BackButton = ({ onBack }) => (
  <div
    className={styles.button}
    onClick={onBack}
    role="button"
  >
    <Svg name="backArrow" /> Back
  </div>
);

export default BackButton;
