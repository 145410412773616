import React from 'react';
import { format } from 'date-fns';
import cx from 'classnames';
import CategoryIcon from 'components/CategoryIcon';
import { splitCamelCaseString } from 'helpers/letterCase';
import ReactTooltip from 'react-tooltip';
import styles from './style.scss';
import Svg from '../../Svg';

function EventItem(props) {
  const {
    timestamp,
    description,
    eventCode,
    location,
    receivedByCarrierTimestamp,
    // Border highlight depends on isActive flag
    isActive,
    // Icon depends on isActiveState flag
    isActiveState,
  } = props;

  const category = props.category ? props.category.toLowerCase() : null;

  return (
    <div
      className={cx(
        styles.container,
        isActive
          ? [styles.active, styles[category]]
          : null,
      )}
    >
      <div className={styles.categoryContainer}>
        <CategoryIcon
          category={category}
          isActive={isActiveState}
          className={styles.categoryIcon}
        />
      </div>
      <div className={cx(styles.time, styles[category])}>
        <Svg
          data-for="info-tooltip"
          data-class={styles.tooltipWrapper}
          data-scroll-hide
          data-place="top"
          data-tip={`Received from the carrier at ${format(receivedByCarrierTimestamp, 'DD/MM/YY HH:mma')}`}
          name="info"
          className={styles.infoIcon}
        />
        {format(timestamp, 'HH:mma')}
      </div>
      <div className={styles.date}>{format(timestamp, 'DD/MM/YY')}<br /><span>{location}</span></div>
      <div className={styles.content}>{splitCamelCaseString(eventCode)}<br />{description}</div>
      <ReactTooltip id="info-tooltip" isCapture />
    </div>
  );
}

export default EventItem;
