export default {
  accountUsers: {
    readUsers: 'account-users:read',
    editUsers: 'account-users:edit',
  },
  apiKeys: {
    read: 'api-keys:read',
    edit: 'api-keys:edit',
  },
  sftpAccount: {
    read: 'sftp-account:read',
    edit: 'sftp-account:edit',
  },
  shipmentFilters: {
    read: 'shipment-filters:read',
    edit: 'shipment-filters:edit',
  },
  smartFilters: {
    read: 'smart-filters:read',
    edit: 'smart-filters:edit',
  },
  webhooks: {
    read: 'webhooks:read',
    edit: 'webhooks:edit',
  },
  carrierConnectors: {
    read: 'carrier-connectors:read',
    edit: 'carrier-connectors:edit',
  },
  customStateLabels: {
    read: 'custom-state-labels:read',
    edit: 'custom-state-labels:edit',
  },
  trackingPages: {
    read: 'tracking-pages:read',
    edit: 'tracking-pages:edit',
  },
  notificationCentre: {
    read: 'notification-centre:read',
    edit: 'notification-centre:edit',
  },
  emailShipmentRegistration: {
    read: 'email-shipment-registration:read',
    edit: 'email-shipment-registration:edit',
  },
  shipmentMetadataGroupings: {
    read: 'shipment-metadata-groupings:read',
    edit: 'shipment-metadata-groupings:edit',
  },
  bulkNotifications: {
    read: 'bulk-notifications:read',
    edit: 'bulk-notifications:edit',
  },
  settings: {
    read: 'settings:read',
    edit: 'settings:edit',
  },
};
