import React from 'react';

/* eslint-disable */
export default props => (
  <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 39.7 39.8'>
    <path d='M38.8,35.2c1,1,1,2.7,0,3.7c-0.5,0.5-1.2,0.8-1.9,0.8c-0.7,0-1.3-0.3-1.9-0.8L19.9,23.6L4.6,38.9 c-0.5,0.5-1.2,0.8-1.9,0.8s-1.3-0.3-1.9-0.8c-1-1-1-2.7,0-3.7l15.3-15.3L0.9,4.7c-1-1-1-2.7,0-3.7c1-1,2.7-1,3.7,0l15.3,15.3 L35.1,0.9c1-1,2.7-1,3.7,0c1,1,1,2.7,0,3.7L23.6,19.9L38.8,35.2z'
    />
    <path d='M38.8,0.9c-1-1-2.7-1-3.7,0L19.9,16.2L4.6,0.9c-1-1-2.7-1-3.7,0c-1,1-1,2.7,0,3.7l15.3,15.3L0.9,35.2 c-1,1-1,2.7,0,3.7c0.5,0.5,1.2,0.8,1.9,0.8s1.3-0.3,1.9-0.8l15.3-15.3l15.3,15.3c0.5,0.5,1.2,0.8,1.9,0.8c0.7,0,1.3-0.3,1.9-0.8 c1-1,1-2.7,0-3.7L23.6,19.9L38.8,4.7C39.9,3.6,39.9,2,38.8,0.9z'
    />
  </svg>
)

/* eslint-enable */
