import React from 'react';
import styles from './SuccessMessage.scss';
import StateCategoryGreen from '../../../../../images/stateCategoryGreen.svg';

export default function SuccessMessage({ title, message }) {
  const defaultTitle = 'Success';
  const defaultMsg = 'Success';

  return (
    <div className={styles.successContainer}>
      <div className={styles.successIcon}>
        <StateCategoryGreen width={20} height={20} fill="#00854D" />
      </div>
      <div className={styles.successInner}>
        <div className={styles.successInnerTitle} id="success-panel-title">{title || defaultTitle}</div>
        <div className={styles.successInnerMessage} id="success-panel-message">{message || defaultMsg}</div>
      </div>
    </div>
  );
}
