/* eslint-disable max-len */
import React from 'react';
import { Switch } from 'react-router';
import { connect } from 'react-redux';
import {
  getCustomStateLabels as getCustomStateLabelsAction,
} from 'actions/customStateLabelsActions';
import Spinner from 'components/Spinner';
import AuthRoute from 'routes/Auth/AuthRoute';
import resources from 'constants/resources';
import CustomStateLabelsList from './CustomStateLabelsList';

class Services extends React.Component {
  componentDidMount() {
    this.props.getCustomStateLabels();
  }

  render() {
    if (!this.props.customStateLabels.keys.length && this.props.customStateLabels.loading) {
      return <Spinner />;
    }
    return (
      <Switch>
        <AuthRoute exact path="/settings/custom-state-labels" component={CustomStateLabelsList} resource={resources.customStateLabels.edit} />
      </Switch>
    );
  }
}

const mapDispatchToProps = dispatch => ({ getCustomStateLabels: () => dispatch(getCustomStateLabelsAction()) });

export default connect(state => ({ customStateLabels: state.customStateLabels }), mapDispatchToProps)(Services);
