import React from 'react';
import CardShell from 'components/CardShell';
import WebhooksSteps from './WebhooksSteps';

const WebhookConfigForm = props => (
  <CardShell>
    <WebhooksSteps {...props} />
  </CardShell>
);

export default WebhookConfigForm;
