import { datadogLogs } from '@datadog/browser-logs';
import { getCustomerId, getUserId, getUsername } from 'helpers/auth';
import { environmentLabels, getEnvironmentLabel } from 'helpers/environments';
import { generateRandomNumber } from 'helpers/guid';

const AppName = 'dashboard';
const CustomerIdKey = 'CustomerId';
const UserIdKey = 'UserId';
const UsernameKey = 'Username';

const DevelopmentClientToken = 'pubec2835a0e6c6e7874f9ae9a955ee636a';
const ProductionClientToken = 'pub3c288b285e79f48d5a2fcd494e643b35';

function getClientToken() {
  switch (getEnvironmentLabel()) {
    case environmentLabels.Prod:
      return ProductionClientToken;
    case environmentLabels.Int:
    case environmentLabels.Qa:
    case environmentLabels.Perf:
      return DevelopmentClientToken;
    default:
      return null;
  }
}

const clientToken = getClientToken();

export function datadogOnLogin() {
  if (!clientToken) {
    return;
  }

  datadogLogs.addLoggerGlobalContext(CustomerIdKey, getCustomerId());
  datadogLogs.addLoggerGlobalContext(UserIdKey, getUserId());
  datadogLogs.addLoggerGlobalContext(UsernameKey, getUsername());
}

export function datadogOnLogout() {
  if (!clientToken) {
    return;
  }

  datadogLogs.removeLoggerGlobalContext(CustomerIdKey);
  datadogLogs.removeLoggerGlobalContext(UserIdKey);
  datadogLogs.removeLoggerGlobalContext(UsernameKey);
}

export function initDatadog() {
  if (!clientToken) {
    return;
  }

  datadogLogs.init({
    clientToken: getClientToken(),
    site: 'datadoghq.com',
    service: AppName,
    env: getEnvironmentLabel(),
    forwardErrorsToLogs: true,
    sampleRate: 100,
    version: process.env.REACT_APP_CODE_VERSION,
  });
  const logTraceId = Math.trunc(generateRandomNumber() * 1000000000000000).toString(36)
    + new Date().getTime().toString(36);
  datadogLogs.addLoggerGlobalContext('LogTraceId', `DASH_${logTraceId}`);
}
