import React from 'react';
import styles from 'routes/Auth/AccessRestrictedPage.scss';
import { withRouter } from 'react-router';

function AccessRestrictedPage({ history }) {
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.content}>
          {'Oops! You don\'t have access to this.'} <br />
          Please contact an admin user if you need access.
        </div>
        <div>
          <button type="button" onClick={() => history.push('/dashboards')}>Back to dashboard</button>
        </div>
      </div>
    </div>
  );
}

export default withRouter(AccessRestrictedPage);
