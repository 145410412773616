import React, { Component } from 'react';
import { connect } from 'react-redux';
import { event as gaEvent } from 'react-ga';
import CustomStateLabelCard from 'components/CustomStateLabelCard';
import CardShell from 'components/CardShell';
import {
  getCustomStateLabels as getCustomStateLabelsAction,
  getEditCustomStateLabel as getEditCustomStateLabelAction,
  setCustomStateLabelsFilter as setCustomStateLabelsFilterAction,
} from 'actions/customStateLabelsActions';
import EditCustomStateLabel from './EditCustomStateLabel';
import styles from './customStateLabels.scss';
import TitleNavBlock from '../../../components/TitleNavBlock/TitleNavBlock';
import typography from '../../../helpers/appTypography';

export class CCustomStateLabelsList extends Component {
  getFilteredCustomStateLabels = () => {
    if (this.props.customStateLabels.filter === 'None') {
      return this.props.customStateLabels.keys;
    }
    return this.props.customStateLabels.keys.filter(x => x.category === this.props.customStateLabels.filter);
  };

  handleEditClick = (key) => {
    gaEvent({
      category: 'CustomStaterLabel',
      action: 'Edit hit',
    });
    this.props.getEditCustomStateLabel({ ...key });
  };

  handleFilterClick = (filter) => {
    gaEvent({
      category: 'CustomStaterLabel',
      action: `Filter by ${filter.category}`,
    });
    this.props.setCustomStateLabelsFilter(filter.category);
  };

  render() {
    if (this.props.editCustomStateLabel.customStateLabel) {
      return <EditCustomStateLabel />;
    }

    return (
      <div>
        <TitleNavBlock
          title={typography.customiseShipmentStates.title}
          subTitle={typography.customiseShipmentStates.subTitle}
          onBack={() => window.history.back()}
        />
        <div className={styles.outerWhiteContainer}>
          <div className={styles.filters}>
            <p>Filter states</p>
            <div className={styles.filterButtons}>
              {this.props.customStateLabels.filters.map(filter => (
                <button
                  key={filter.category}
                  type="button"
                  name="create"
                  className={this.props.customStateLabels.filter === filter.category ? styles.selectedFilter : ''}
                  onClick={() => this.handleFilterClick(filter)}
                >
                  {filter.name}
                </button>
              ))}
            </div>
          </div>
          <div>{this.props.customStateLabels.error}</div>
          <div className="container__cards-large">
            {this.getFilteredCustomStateLabels().map(key => (
              <CardShell key={key.stateKey}>
                <CustomStateLabelCard
                  customStateLabel={key}
                  handleEditClick={() => this.handleEditClick(key)}
                  locales={this.props.locales}
                />
              </CardShell>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getEditCustomStateLabel: customStateLabel => dispatch(getEditCustomStateLabelAction(customStateLabel)),
  getCustomStateLabels: () => dispatch(getCustomStateLabelsAction()),
  setCustomStateLabelsFilter: filter => dispatch(setCustomStateLabelsFilterAction(filter)),
});

export default connect(state => ({
  customStateLabels: state.customStateLabels,
  editCustomStateLabel: state.editCustomStateLabel,
  locales: state.locales,
}), mapDispatchToProps)(CCustomStateLabelsList);
