import React from 'react';

/* eslint-disable */
export default props => (
  <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 72.1 71.9'>
    <path d='M41.7,43.6v18.1c0,0.6,0.5,1.1,1.1,1.1h7.4c0.6,0,1.1-0.5,1.1-1.1V43.1c0-0.8-0.9-1.4-1.6-1 C48.6,42.7,47.4,43,46,43c-1,0-1.9-0.2-2.7-0.5C42.5,42.3,41.7,42.8,41.7,43.6z'
    />
    <path d='M66.4,8.9c-1.8,0.4-3.3,1.9-3.6,3.8c-0.1,0.9,0,1.8,0.3,2.5l-16,15.2c-0.3-0.1-0.7-0.1-1.1-0.1 c-0.2,0-0.4,0-0.6,0l-4.2-5c0.1-0.4,0.1-0.7,0.1-1.1c0-2.8-2.5-5.1-5.4-4.6c-2.3,0.4-4,2.6-3.8,5c0,0,0,0,0,0.1L16.4,39.3 c-0.4-0.1-0.9-0.2-1.3-0.2c-2.6,0-4.6,2.1-4.6,4.6c0,2.6,2.1,4.7,4.7,4.6c2.6,0,4.8-2.5,4.6-5.1c0-0.2,0-0.3-0.1-0.5L35,28.6 c0.5,0.2,1.1,0.3,1.7,0.3c0.4,0,0.8,0,1.1-0.1l3.8,4.5c-0.2,0.5-0.3,1.1-0.3,1.7c0,2.8,2.6,5.1,5.5,4.5c1.8-0.3,3.2-1.7,3.6-3.4 c0.2-0.8,0.2-1.6,0-2.3L67,18.1c0.1,0,0.2,0,0.4,0c2.6,0,4.6-2.1,4.6-4.6C72.1,10.5,69.4,8.3,66.4,8.9z M15.1,45.9 c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S16.2,45.9,15.1,45.9z M36.8,25.9c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2 S37.9,25.9,36.8,25.9z M46.1,37c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S47.2,37,46.1,37z M67.5,15.7c-1.1,0-2-0.9-2-2s0.9-2,2-2 s2,0.9,2,2S68.6,15.7,67.5,15.7z'
    />
    <path d='M59.5,30.5v31.2c0,0.6,0.5,1.1,1.1,1.1H68c0.6,0,1.1-0.5,1.1-1.1V23.4c0-1-1.2-1.5-1.9-0.8l-7.4,7.1 C59.6,29.9,59.5,30.2,59.5,30.5z'
    />
    <path d='M23.9,44.1v17.7c0,0.6,0.5,1.1,1.1,1.1h7.4c0.6,0,1.1-0.5,1.1-1.1V37c0-1-1.2-1.5-1.9-0.8l-7.4,7.1 C24.1,43.5,23.9,43.8,23.9,44.1z'
    />
    <path d='M70,71.9H2c-1.1,0-2-0.9-2-2V2c0-1.1,0.9-2,2-2h1.9C5,0,6,0.9,6,2v62c0,1.1,0.9,2,2,2h62c1.1,0,2,0.9,2,2v1.9 C72.1,71,71.1,71.9,70,71.9z'
    />
  </svg>
)

/* eslint-enable */
