const webhookTitles = [
  'Name your webhook',
  'Choose shipment filters',
  'Choose the language',
  'Webhook details',
  'Webhook details',
  'Webhook details',
];

export default webhookTitles;
