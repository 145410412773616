import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import VEllipsisSvg from 'images/vEllipsis.svg';
import BackButton from 'components/BackButton';
import {
  getShipmentById as getShipmentByIdAction,
  getTrackingEventGroupsById as getTrackingEventGroupsByIdAction,
} from 'actions';
import Timeline from 'components/Timeline';
import Spinner from 'components/Spinner';
import { parse, stringify } from 'helpers/queryString';
import API from 'helpers/API';
import ResizeDetector from 'react-resize-detector';
import cx from 'classnames';
import ContextPopup from 'components/ContextPopup/ContextPopup';
import { Link } from 'react-router-dom';
import ContextMenuItem from 'components/ContextMenu/ContextMenuItem';
import ShipmentSummary from 'routes/Dashboards/Shipments/ShipmentSummary';
import ShipmentDetails from 'routes/Dashboards/Shipments/ShipmentDetails';
import ShipmentNotes from 'routes/Dashboards/Shipments/ShipmentNotes/ShipmentNotes';
import ShipmentStatus from 'routes/Dashboards/Shipments/ShipmentStatus';
import styles from 'routes/Dashboards/Shipments/Shipment/styles.scss';
import LayoutContext from 'components/Layout/LayoutContext';

function getMetadataParams(queryString) {
  const { metadataKey, metadataValue } = parse(queryString);
  return {
    metadataKey,
    metadataValue,
  };
}

class Shipment extends Component {
  static contextType = LayoutContext;

  tabsContainerRef = createRef();

  prevWidth = null;

  constructor(props, context) {
    super(props, context);
    this.state = {
      relatedShipments: null,
    };
  }

  componentDidMount() {
    this.getData();
    this.loadRelatedShipments();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getData();
    }
    const prevParams = getMetadataParams(prevProps.location.search);
    const curParams = getMetadataParams(this.props.location.search);
    if ((curParams.metadataKey !== prevParams.metadataKey)
      || (curParams.metadataValue !== prevParams.metadataValue)) {
      this.loadRelatedShipments();
    }

    if ((!curParams.metadataKey && this.props.match.params.id && this.props.match.params.id !== prevProps.match.params.id)
      || (this.props.shipmentDetails.shipmentData !== prevProps.shipmentDetails.shipmentData)) {
      this.autoLoadRelatedShipments();
    }
  }

  get shouldDisplayRelated() {
    const queryParams = getMetadataParams(this.props.location.search);
    return Boolean((queryParams.metadataKey && queryParams.metadataValue) || this.state.relatedShipments);
  }

  get relatedShipmentsToRender() {
    const {
      relatedShipments,
    } = this.state;
    const {
      shipmentData,
    } = this.props.shipmentDetails;
    if (relatedShipments) {
      return relatedShipments;
    }
    if (this.shouldDisplayRelated && shipmentData) {
      // show a single tab while we loading related shipments
      return [{ id: shipmentData.id }];
    }
    return [];
  }

  get selectedTabIndex() {
    const { relatedShipmentsToRender } = this;
    const { match: { params: { id } } } = this.props;
    if (relatedShipmentsToRender) {
      return relatedShipmentsToRender.findIndex(x => x.id === id);
    }
    return -1;
  }

  getData = async () => {
    const { match: { params: { id } } } = this.props;
    const { getShipmentById, getTrackingEventGroupsById } = this.props;
    getShipmentById(id);
    getTrackingEventGroupsById(id);
  };

  loadShipmentsByMetadata = async (metadataKey, metadataValue) => {
    if (metadataKey && metadataValue) {
      const { shipments } = await API.getShipments(stringify({
        metadataKey,
        metadataValue,
        sort: 'created_on',
      }));
      this.setState({ relatedShipments: shipments });
    } else {
      this.setState({ relatedShipments: null });
    }
  }

  loadRelatedShipments = async () => {
    const { location: { search } } = this.props;
    const { metadataKey, metadataValue } = getMetadataParams(search);
    await this.loadShipmentsByMetadata(metadataKey, metadataValue);
  }

  autoLoadRelatedShipments = async () => {
    const { shipmentData } = this.props.shipmentDetails;
    const { groupings } = await API.getShipmentMetadataGroupings();
    if (shipmentData && groupings && groupings.length > 0) {
      const filteredGrouping = groupings.find(x => x.performAutomaticLoading === true);
      if (filteredGrouping) {
        const shipmentMetadata = shipmentData.metadata.find(x => x.key.toLocaleLowerCase() === filteredGrouping.metadataKey.toLocaleLowerCase());
        const { match: { params: { id } } } = this.props;
        if (shipmentMetadata && (this.state.relatedShipments === null || !this.state.relatedShipments.some(x => x.id === id))) {
          await this.loadShipmentsByMetadata(filteredGrouping.metadataKey, shipmentMetadata.value);
        }
      }
    }
  }

  renderShipment() {
    const {
      shipmentData,
      statesData,
      signee,
      eventsDataError,
    } = this.props.shipmentDetails;
    return (
      <div className={styles.shipmentSummary}>
        <ShipmentStatus shipmentData={shipmentData} />
        <ShipmentSummary shipmentData={shipmentData} />
        <Timeline
          items={statesData}
          signee={signee}
          errorMessage={eventsDataError}
        />
        <ShipmentDetails shipmentData={shipmentData} />
        <ShipmentNotes shipmentId={shipmentData.id} />
      </div>
    );
  }

  render() {
    const {
      isShipmentLoading,
      isEventsLoading,
      shipmentData,
      shipmentDataError,
    } = this.props.shipmentDetails;
    const {
      relatedShipmentsToRender,
      selectedTabIndex,
    } = this;

    if (shipmentDataError) {
      return (
        <div>
          <BackButton onBack={this.props.history.goBack} />
          <p>{shipmentDataError}</p>
        </div>
      );
    }

    if (!shipmentData || isShipmentLoading || isEventsLoading) {
      return <Spinner />;
    }
    return (
      <div ref={this.tabsContainerRef}>
        <BackButton onBack={this.props.history.goBack} />
        <h1 style={{ marginTop: 0 }} className="h2 break-all">Shipment | {shipmentData.custom_references[0]}</h1>
        {relatedShipmentsToRender ? (
          <>
            <ResizeDetector handleWidth targetRef={this.tabsContainerRef}>
              {({ width = this.prevWidth }) => {
                this.prevWidth = width;
                let visibleTabsCount = Math.floor((width) / 120);
                if (relatedShipmentsToRender.length > visibleTabsCount) {
                  visibleTabsCount = Math.floor((width - 42) / 120);
                }
                return (
                  <ul className={styles.tabsContainer}>
                    <>
                      {relatedShipmentsToRender.slice(0, visibleTabsCount)
                        .map((x, i) => (
                          <li
                            key={x.id}
                            className={cx(
                              styles.tab,
                              styles.shipmentTab,
                              i === selectedTabIndex ? styles.selectedTab : null,
                            )}
                          >
                            <Link to={`/shipments/${x.id}${this.props.location.search}`}>
                              Shipment {i + 1}
                            </Link>
                          </li>
                        ))}
                      {(visibleTabsCount < relatedShipmentsToRender.length)
                        && (
                          <div className={cx(styles.tab, styles.showMoreTab)}>
                            <ContextPopup
                              popupOwnerRender={(togglePopup, isPopupShown) => (
                                <button
                                  type="button"
                                  className={styles.ellipsisButton}
                                  onClick={togglePopup}
                                >
                                  <VEllipsisSvg
                                    className={cx(styles.ellipsisIcon, isPopupShown ? styles.active : null)}
                                  />
                                </button>
                              )}
                            >
                              <div className={styles.dropdownContainer}>
                                {relatedShipmentsToRender.slice(visibleTabsCount)
                                  .map((x, i) => (
                                    <ContextMenuItem
                                      key={x.id}
                                    >
                                      <div className={cx(
                                        styles.option,
                                        i + visibleTabsCount === selectedTabIndex ? styles.selectedOption : null,
                                      )}
                                      >
                                        <Link to={`/shipments/${x.id}${this.props.location.search}`}>
                                          Shipment {visibleTabsCount + i + 1}
                                        </Link>
                                      </div>
                                    </ContextMenuItem>
                                  ))}
                              </div>
                            </ContextPopup>
                          </div>
                        )}
                    </>
                  </ul>
                );
              }}
            </ResizeDetector>
            <div className={cx(styles.tabPanel, styles.additionalBG)}>
              {this.renderShipment()}
            </div>
          </>
        )
          : this.renderShipment()}
      </div>
    );
  }
}

const mapStateToProps = ({ shipmentDetails }) => ({
  shipmentDetails,
});

const mapDispatchToProps = dispatch => ({
  getShipmentById: id => dispatch(getShipmentByIdAction(id)),
  getTrackingEventGroupsById: id => dispatch(getTrackingEventGroupsByIdAction(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Shipment);
