import React, { useEffect, useReducer, useState } from 'react';
import API from 'helpers/API';
import Spinner from 'components/Spinner';
import CardShell from 'components/CardShell';
import CreateCard from 'components/CreateCard';
import NotificationCard from './NotificationCard';
import TitleNavBlock from '../../../../components/TitleNavBlock/TitleNavBlock';
import typography from '../../../../helpers/appTypography';

export const notificationsReducer = (notifications, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'LOAD':
      return payload.notifications;
    case 'ACTIVATE':
      return notifications.map(notification => (notification.id === payload.id
        ? { ...notification, isActive: true }
        : notification));
    case 'DEACTIVATE':
      return notifications.map(notification => (notification.id === payload.id
        ? { ...notification, isActive: false }
        : notification));
    case 'DISCONNECT':
      return notifications.filter(notification => notification.id !== payload.id);
    default: return notifications;
  }
};

export default function NotificationSetup({ history }) {
  const [notifications, dispatch] = useReducer(notificationsReducer, []);
  const [loading, setLoading] = useState(false);

  const callDispatch = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const toggleActive = async (id) => {
    const config = notifications.find(x => x.id === id);

    if (config.isActive) {
      await API.disableNotification(id);
    } else {
      await API.enableNotification(id);
    }

    callDispatch(config.isActive ? 'DEACTIVATE' : 'ACTIVATE', { id });
  };

  const disconnect = async (id) => {
    await API.disconnectNotification(id);
    callDispatch('DISCONNECT', { id });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const configurationsResponse = await API.getNotifications();

      callDispatch('LOAD', { notifications: configurationsResponse.notifications });
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Spinner />
    );
  }

  return (
    <div>
      <TitleNavBlock
        title={typography.notificationSetUp.title}
        subTitle={typography.notificationSetUp.subTitle}
        onBack={() => window.history.back()}
      />
      <div>
        <div className="container__cards-large">
          <div
            role="button"
            onClick={() => history.push('/settings/notification-centre/notifications/create')}
          >
            <CreateCard text="Add new communication" />
          </div>
          {notifications.length > 0
            && notifications.map(x => (
              <CardShell key={x.id}>
                <NotificationCard
                  notification={x}
                  history={history}
                  toggleActive={toggleActive}
                  disconnect={disconnect}
                />
              </CardShell>
            ))}

        </div>
      </div>
    </div>
  );
}
