const initialState = {
  loading: true,
  carriers: [],
};

export default function carriersReducer(state = initialState, action) {
  switch (action.type) {
    case 'CARRIERS_FETCH':
      return {
        ...state,
        loading: true,
        carriers: [],
      };
    case 'CARRIERS_SUCCESS':
      return {
        ...state,
        loading: false,
        carriers: action.carriers,
      };
    default:
      return state;
  }
}
