import React from 'react';
import {
  Route, Switch, Redirect, withRouter,
} from 'react-router';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from 'components/Layout';
import AuthRoute from 'routes/Auth/AuthRoute';
import Preview from 'routes/Settings/Notifications/NotificationTemplates/Preview';
import resources from 'constants/resources';
import Shipment from './Dashboards/Shipments/index';
import Dashboards from './Dashboards';
import Reporting from './Reporting';
import Settings from './Settings';
import TrackingPages from './Settings/TrackingPages';

function renderLayout() {
  return (
    <Layout>
      <Switch>
        <Route path="/dashboards" component={Dashboards} />
        <Route path="/shipments" component={Shipment} />
        <Route path="/tracking-pages" component={TrackingPages} />
        <Route path="/settings" component={Settings} />
        <Route path="/reporting" component={Reporting} />
        <Redirect exact from="/" to="/dashboards/states" />
        <Route component={() => 'not found'} />
      </Switch>
    </Layout>
  );
}

export const Routes = ({ isAuthenticated, location: { pathname, search, hash } }) => {
  if (!isAuthenticated) {
    return (
      <Redirect to={{
        pathname: '/login',
        state: { from: pathname + search + hash },
      }}
      />
    );
  }
  return (
    <Switch>
      <AuthRoute
        exact
        path="/settings/notification-centre/notification-connectors/:configurationId/templates/:templateId/preview"
        component={Preview}
        resource={resources.notificationCentre.read}
      />
      <Route path="/" render={renderLayout} />
    </Switch>
  );
};

Routes.propTypes = {
  isAuthenticated: propTypes.bool,
  location: propTypes.shape({
    pathname: propTypes.string.isRequired,
  }).isRequired,
};

Routes.defaultProps = {
  isAuthenticated: false,
};

export default withRouter(connect(({ store }, { location }) => ({
  isAuthenticated: store.login.isAuthenticated,
  wasOn: location.pathname,
}))(Routes));
