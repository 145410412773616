import cx from 'classnames';
import React from 'react';
import Button from '../../../../../components/ReactButton';
import ExportIcon from '../../../../../images/export2';
import styles from './buttons.scss';

export function ExportActivities(props) {
  return (
    <Button theme="white" className={cx(styles.button, styles.iconButton)} onClick={props.onClick}>
      <div>
        <ExportIcon width={20} height={20} />
        <span>Export</span>
      </div>
    </Button>
  );
}

export function ShowMoreActivities(props) {
  return (
    <Button theme="white" disabled={props.disabled} className={cx(styles.button, styles.showMoreButton, props.hidden ? styles.hidden : '')} onClick={props.onClick}>
      Show more...
    </Button>
  );
}

export function ReactivateButton(props) {
  return (
    <Button theme="white" className={cx(styles.button, styles.reactivateButton)} onClick={props.onClick}>
      Reactivate
    </Button>
  );
}
