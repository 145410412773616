import React from 'react';
import styles from './styles.scss';

const NonPrimaryUserTokenExpired = () => (
  <div className={styles.registrationPageInner}>
    <div className={styles.welcome}>
      <h1 className={`${styles.welcomeHeader}`}>Invitation expired</h1>
    </div>
    <div className={styles.confirmationWelcomeWrapper}>
      <p className="subtitle2">
        Your invitation has expired. Please contact your administrator to re-send your invitation.
      </p>
    </div>
  </div>
);

export default NonPrimaryUserTokenExpired;
