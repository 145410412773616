/* eslint-disable prefer-regex-literals */
function getDomain(email) {
  const atIndex = email.indexOf('@');
  if (atIndex === -1 || atIndex === email.length - 1) {
    return null;
  }
  return email.substring(atIndex + 1);
}

function validateFieldValue(fieldValue, validationRule) {
  let isValid = true;

  if (validationRule.ruleType === 'required') {
    isValid = !!fieldValue;
  } else if (validationRule.ruleType === 'min_length') {
    isValid = !fieldValue
      || fieldValue.length >= Number(validationRule.parameterValue);
  } else if (validationRule.ruleType === 'max_length') {
    isValid = !fieldValue
      || fieldValue.length <= Number(validationRule.parameterValue);
  } else if (validationRule.ruleType === 'exact_length') {
    isValid = !fieldValue
      || fieldValue.length === Number(validationRule.parameterValue);
  } else if (validationRule.ruleType === 'pattern') {
    isValid = !fieldValue
      || new RegExp(validationRule.parameterValue).test(fieldValue);
  } else if (validationRule.ruleType === 'url') {
    isValid = !fieldValue
      || new RegExp('^(ftps|s?ftp|https?)://[^\\s:/]+(:\\d+)?(/[^\\s/]+)*/?$').test(fieldValue);
  } else if (validationRule.ruleType === 'email') {
    isValid = !fieldValue
      || new RegExp('^[^\\s@]+@[^\\s@.]+.[^\\s@.]+$').test(fieldValue);
  } else if (validationRule.ruleType === 'range') {
    isValid = !fieldValue
      || validationRule.acceptableValues.indexOf(fieldValue) >= 0;
  } else if (validationRule.ruleType === 'email_address_maintain_domain') {
    isValid = !fieldValue
      || !validationRule.parameterValue
      || getDomain(fieldValue) === validationRule.parameterValue;
  }

  return isValid;
}

function validateField(errors, fieldName, fieldValue, validationRule) {
  const isValid = validateFieldValue(fieldValue, validationRule);
  if (!isValid) {
    if (!(fieldName in errors)) {
      // eslint-disable-next-line
      errors[fieldName] = [];
    }
    errors[fieldName].push(validationRule.errorMessage);
  }
  return isValid;
}

export default function validateConfiguration(root) {
  let isValid = true;

  // eslint-disable-next-line
  root.errors = {};

  isValid = validateField(root.errors, 'permission', root.configuration.permission, {
    ruleType: 'required',
    errorMessage: 'Please confirm that you give permission',
  }) && isValid;

  isValid = validateField(root.errors, 'name', root.configuration.name, {
    ruleType: 'max_length',
    parameterValue: '100',
    errorMessage: 'Name cannot be longer than 100 charachters',
  }) && isValid;

  root.configuration.integrationProperties.forEach((x) => {
    x.validationRules.forEach((y) => {
      isValid = validateField(root.errors, `integrationProperties.${x.fieldName}`, x.fieldValue, y) && isValid;
    });
  });

  return isValid;
}

function validateProperty(value, rules) {
  return rules.reduce((errors, rule) => {
    const isValid = validateFieldValue(value, rule);
    if (!isValid) {
      errors.push(rule.errorMessage);
    }
    return errors;
  }, []);
}

export function validateObject(obj, rules) {
  return Object.keys(rules).reduce((acc, x) => {
    const propErrors = validateProperty(obj[x], rules[x]);
    if (propErrors.length > 0) {
      acc[x] = propErrors;
    }
    return acc;
  }, {});
}
