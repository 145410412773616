/* eslint-disable max-len */
import React, { Component } from 'react';
import { Switch } from 'react-router';
import { connect } from 'react-redux';
import Spinner from 'components/Spinner';
import AuthRoute from 'routes/Auth/AuthRoute';
import resources from 'constants/resources';
import TrackingServicesList from './TrackingServicesList';
import EditCarrierConnector from './EditCarrierConnector';
import CreateCarrierConnector from './CreateCarrierConnector';
import { getTrackingServices as getTrackingServicesAction } from '../../../actions';

class Services extends Component {
  componentDidMount() {
    this.props.getServices();
  }

  render() {
    // if its a cold start and we dont yet have any info about keys show loader so that
    // children componentDidMount didnt try to look into yet unexisting data
    if (!this.props.trackingServices.keys.length && this.props.trackingServices.loading) {
      return <Spinner />;
    }
    return (
      <Switch>
        <AuthRoute exact path="/settings/carrier-connectors" component={TrackingServicesList} resource={resources.carrierConnectors.read} />
        <AuthRoute exact path="/settings/carrier-connectors/edit/:id" component={EditCarrierConnector} resource={resources.carrierConnectors.edit} />
        <AuthRoute exact path="/settings/carrier-connectors/create/:id" component={CreateCarrierConnector} resource={resources.carrierConnectors.edit} />
      </Switch>
    );
  }
}

const mapDispatchToProps = dispatch => ({ getServices: () => dispatch(getTrackingServicesAction()) });

export default connect(state => ({ trackingServices: state.trackingServices }), mapDispatchToProps)(Services);
