import React from 'react';
import styles from './styles.scss';

const DashboardTotalViewTableModal = ({
  visible = false,
  content,
  onClose,
}) => {
  if (!visible) return null;
  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div className={styles.modal} onClick={e => e.stopPropagation()}>
        <div className={styles.modalHeader}>
          <div />
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div className={styles.modalBody} onClick={onClose}>&times;</div>
        </div>
        <div className={styles.modalContent}>
          {content}
        </div>
      </div>
    </div>
  );
};
export default DashboardTotalViewTableModal;
