import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import InputWithValidation from 'components/InputWithValidation';
import { maxLength } from 'helpers/validate';
import { validateForm } from 'helpers/validateForm';
import CardShell from 'components/CardShell';
import DualButtons from 'components/DualButtons';
import Hr from 'components/CardShell/Hr';
import Header from 'components/CardShell/Header';
import {
  showCustomPopup as showCustomPopupAction,
  closeCustomPopup as closeCustomPopupAction,
} from 'actions';
import {
  createApiKey as createApiKeyAction,
  getApiKeys as getApiKeysAction,
} from 'actions/apikeyActions';
import GeneratedKey from './GeneratedKey';
import styles from './ApiKeyDetailsStyles.scss';

class CreateApiKeyPage extends React.Component {
  validationRules = {
    name: [maxLength(50)],
  };

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      errors: {},
    };
  }

  handleNameInputChange = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  submitCreate = () => {
    const errors = validateForm(this.validationRules, this.state);
    if (errors.hasErrors) {
      this.setState({ errors });
      return;
    }

    const { name } = this.state;

    const closePopup = () => {
      this.props.closeCustomPopup();
      this.props.getApiKeys().then(() => this.backToMenu());
    };

    this.props.createApiKey({ name }).then((response) => {
      this.props.showCustomPopup(<GeneratedKey onClose={closePopup} value={response.api_key} />);
    });
  };

  backToMenu = () => {
    this.props.history.push('/settings/api-keys');
  };

  render() {
    return (
      <div>
        <h2>Create API Key</h2>
        <div className="container__cards-large">
          <CardShell>
            <div className={styles.container}>
              <div className={styles.createKeyWrapper}>
                <Header>Create New API Key</Header>
                <Hr />
                <p>Enter an identifying name for your API key and click Create to generate it.</p>
                <InputWithValidation
                  label="API Key Name"
                  placeholder="API Key Name"
                  type="text"
                  value={this.state.name}
                  name="name"
                  onChange={this.handleNameInputChange}
                  errors={this.state.errors}
                />
              </div>
            </div>
            <DualButtons
              leftFunction={this.backToMenu}
              leftText="Cancel"
              rightFunction={this.submitCreate}
              rightText="Create"
              theme="rightPink"
            />
          </CardShell>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getApiKeys: () => dispatch(getApiKeysAction()),
  createApiKey: key => dispatch(createApiKeyAction(key)),
  showCustomPopup: (component, onClosed) => dispatch(showCustomPopupAction(component, onClosed)),
  closeCustomPopup: () => dispatch(closeCustomPopupAction()),
});

export default withRouter(connect(null, mapDispatchToProps)(CreateApiKeyPage));
