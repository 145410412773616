import React from 'react';
import { Switch } from 'react-router';
import resources from 'constants/resources';
import AuthRoute from 'routes/Auth/AuthRoute';
import WebhooksList from './WebhooksList';
import WebhookEditForm from './WebhookEditForm';
import WebhookCreateForm from './WebhookCreateForm';
import WebhookLogs from './WebhookLogs';
import WebhookLogsDetails from './WebhookLogsDetails';

function Webhooks() {
  return (
    <Switch>
      <AuthRoute exact path="/settings/webhooks/" component={WebhooksList} resource={resources.webhooks.read} />
      <AuthRoute exact path="/settings/webhooks/create" component={WebhookCreateForm} resource={resources.webhooks.edit} />
      <AuthRoute exact path="/settings/webhooks/:id" component={WebhookEditForm} resource={resources.webhooks.edit} />
      <AuthRoute exact path="/settings/webhooks/:id/logs" component={WebhookLogs} resource={resources.webhooks.read} />
      <AuthRoute exact path="/settings/webhooks/:id/logs/:logId" component={WebhookLogsDetails} resource={resources.webhooks.read} />
    </Switch>
  );
}

export default Webhooks;
