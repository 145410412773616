import React from 'react';
import ChartComponent from 'components/ChartComponent';
import styles from './EmailClientsChart.scss';
import colors from '../../colors';
import ErrorMessage from '../components/ErrorMessage/ErrorMessage';

export default function EmailClientsChart({ clients, dateKey }) {
  const chartData = {
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'right',
          padding: '100px',
        },
        tooltip: {
          callbacks: {
            label(item) {
              return item.label;
            },
          },
        },
      },
    },
    type: 'pie',
    chartData: {
      labels: Array.from(clients).map(el => `${el.key} ${el.value}%`),
      datasets: [
        {
          label: '#',
          data: Array.from(clients)?.map(el => el.value),
          backgroundColor: colors,
          borderWidth: 0,
        },
      ],
    },
  };

  const hasData = !!Array.from(clients).some(elt => elt.value > 0);

  return (
    <div className={styles.pieChart}>
      <div className={styles.chartContainerTitle}>Email clients</div>
      <div className={styles.chartContainer}>
        {hasData && <ChartComponent chartProps={chartData} />}

        {!hasData && <ErrorMessage dateKey={dateKey} />}
      </div>
    </div>
  );
}
