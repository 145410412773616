const useStyles = {
  parentMenuItem: {
    color: 'white',
    fontSize: '1.25em',
    fontWeight: 'regular',
    fontFamily: '"RundText", "Arial", sans-serif',
  },
  nestedMenuItem: {
    color: 'white',
    fontSize: '1em',
    fontFamily: '"RundText", "Arial", sans-serif',
    paddingLeft: '48px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    marginRight: '5px',
  },
  linksStyle: {
    textDecoration: 'none',
  },
  paddings: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  settingPageTitle: {
    fontWeight: 'regular',
    fontFamily: '"RundText", "Arial", sans-serif',
    color: '#121212',
  },
  settingsPageMenuItems: {
    fontSize: '1.75em',
    fontWeight: 'regular',
    fontFamily: '"RundText", "Arial", sans-serif',
    marginBottom: '10px',
    color: '#121212',
  },
};

export default useStyles;
