import React, { useEffect, useState } from 'react';
import Spinner from 'components/Spinner';
import CardShell from 'components/CardShell';
import InputWithValidation from 'components/InputWithValidation';
import DualButtons from 'components/DualButtons';
import Hr from 'components/CardShell/Hr';
import Header from 'components/CardShell/Header';
import { getErrorMessagesFromApiError } from 'helpers/apiError';
import { isRequired, validateSinkConfiguration } from './validation';
import Popup from '../../../../components/Layout/Popup';
import styles from '../styles.scss';
import TitleNavBlock from '../../../../components/TitleNavBlock/TitleNavBlock';

function changeValue(values, name, value) {
  const prop = values.find(x => x.name === name);
  const newProp = {
    ...prop,
    value,
  };
  return values.map(x => (x.name === prop.name ? newProp : x));
}

function NotificationConfigurationForm({
  load, save, title, mode, history,
}) {
  const [popup, setPopup] = useState({});
  const [sinkConfiguration, setConfiguration] = useState({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const loadedSinkConfiguration = await load();
      const mappedValues = loadedSinkConfiguration.values.map(value => (
        {
          ...value,
          value: value.value || value.default || '',
        }
      ));
      loadedSinkConfiguration.values = mappedValues;

      setConfiguration(loadedSinkConfiguration);
      setLoading(false);
    };

    fetchData();
  }, [load]);

  const handlePropChange = (e) => {
    const { name, value } = e.target;
    setConfiguration(c => ({
      ...c,
      values: changeValue(c.values, name, value),
    }));
  };

  const handleNameChange = (e) => {
    const { value } = e.target;
    setConfiguration(c => ({
      ...c,
      name: value,
    }));
  };

  if (loading) {
    return (
      <Spinner />
    );
  }

  const errors = validateSinkConfiguration(sinkConfiguration);
  const displayErrors = showErrors ? errors : null;

  const handleErrors = (e) => {
    const messages = getErrorMessagesFromApiError(e.json);
    if (messages.length > 0) {
      setPopup({
        open: true,
        title: 'Error',
        description: <span>{messages[0]}</span>,
        onDecline: () => {
          setPopup({ open: false });
        },
      });
    }
  };

  const handleSave = async () => {
    if (Object.values(errors).length > 0) {
      setShowErrors(true);
      return;
    }
    setSaving(true);
    try {
      await save(sinkConfiguration);
      setPopup({
        open: true,
        title: 'Success',
        description: <span>Connection saved.</span>,
        onDecline: () => {
          history.push('/settings/notification-centre/notification-connectors');
        },
      });
    } catch (e) {
      handleErrors(e);
    } finally {
      setSaving(false);
    }
  };

  return (
    <div>
      {saving && <Spinner />}
      <Popup
        open={popup.open}
        title={popup.title}
        description={popup.description}
        onAccept={popup.onAccept}
        onDecline={popup.onDecline}
      />
      <TitleNavBlock
        title={title}
        onBack={() => window.history.back()}
      />
      {/* <LocalStorageTitle */}
      {/*  localStorageName="createNotificationConnectorPageText" */}
      {/*  pageTitle={title} */}
      {/* > */}
      {/*  <div className="text_container"> */}
      {/*    <div className="informationalTextBlock"> */}
      {/*      <p> */}
      {/*        This page enables you to set up a notification connection. */}
      {/*      </p> */}
      {/*    </div> */}
      {/*  </div> */}
      {/* </LocalStorageTitle> */}
      {sinkConfiguration ? (
        <div className="container__cards-large">
          <CardShell>
            <div className={styles.container}>
              <div className={styles.cardSectionInner}>
                <Header>{sinkConfiguration.sinkName} Connection</Header>
                <Hr />
                <div>
                  <p>{mode} connection values</p>
                  <InputWithValidation
                    label="Name your connection"
                    placeholder="Name your connection"
                    onChange={handleNameChange}
                    value={sinkConfiguration.name}
                    type="text"
                    name="configurationName"
                    errors={displayErrors}
                    required
                  />
                  {sinkConfiguration.values ? (
                    sinkConfiguration.values.map(x => (
                      <InputWithValidation
                        key={x.name}
                        label={x.description}
                        placeholder={x.description}
                        onChange={handlePropChange}
                        value={x.value}
                        type="text"
                        name={x.name}
                        errors={displayErrors}
                        required={isRequired(sinkConfiguration, x.name)}
                      />
                    ))

                  ) : (
                    <p>No configuration values found</p>
                  )}
                </div>
              </div>
              <DualButtons
                leftFunction={() => {
                  history.push('/settings/notification-centre/notification-connectors');
                }}
                leftText="Cancel"
                rightFunction={handleSave}
                rightFunctionDisabled={saving}
                rightText="Save"
                theme="rightPink"
              />
            </div>
          </CardShell>
        </div>
      ) : (
        <p>Connector not found.</p>
      )}
    </div>
  );
}

export default NotificationConfigurationForm;
