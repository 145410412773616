import React, { Component } from 'react';
import Svg from 'components/Svg';
import { stringify } from 'helpers/queryString';
import joinQueries from 'helpers/joinQueryStrings';
import { parseFiltersSearchQuery } from 'helpers/parseFiltersSearchQuery';
import { setSelectedFilters } from 'helpers/selectedFilters';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  getShipmentSummaries as getShipmentSummariesAction,
} from 'actions';
import styles from './style.scss';

class FilterSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.updateState();
  }

  componentDidUpdate(prevProps) {
    const { searchQuery, availableCarriers } = this.props;

    if (searchQuery !== prevProps.searchQuery || availableCarriers !== prevProps.availableCarriers) {
      this.updateState();
    }
  }

  getRenderedStates() {
    const {
      registeredDate,
      orderDate,
      shippedDate,
      deliveryDate,
      promisedDate,
      estimatedDate,
      shippingDirection,
      carriers,
      states,
      origins,
      destinations,
      metadataKey,
      metadataValue,
    } = parseFiltersSearchQuery(this.props.searchQuery, this.props.availableCarriers);

    const result = [];

    if (registeredDate && registeredDate.from !== 'any') {
      result.push({ name: 'Registered Date', map: 'registeredDate' });
    }
    if (orderDate && orderDate.from !== 'any') {
      result.push({ name: 'Order Date', map: 'orderDate' });
    }
    if (shippedDate && shippedDate.from !== 'any') {
      result.push({ name: 'Shipped Date', map: 'shippedDate' });
    }
    if (deliveryDate && deliveryDate.from !== 'any') {
      result.push({ name: 'Delivery Date', map: 'deliveryDate' });
    }
    if (promisedDate && promisedDate.from !== 'any') {
      result.push({ name: 'Promised Date', map: 'promisedDate' });
    }
    if (estimatedDate && estimatedDate.from !== 'any') {
      result.push({ name: 'Estimated Date', map: 'estimatedDate' });
    }
    if (shippingDirection && shippingDirection !== 'any') {
      result.push({ name: 'Shipment Type', map: 'shippingDirection' });
    }
    if (carriers && carriers.length > 0) {
      result.push({ name: 'Carriers', map: 'carriers' });
    }
    if (states && states.length > 0) {
      result.push({ name: 'States', map: 'states' });
    }
    if (origins && origins.length > 0) {
      result.push({ name: 'Origins', map: 'origins' });
    }
    if (destinations && destinations.length > 0) {
      result.push({ name: 'Destinations', map: 'destinations' });
    }
    if (metadataKey && metadataKey.length > 0 && metadataValue && metadataValue.length > 0) {
      result.push({ name: 'Metadata', map: 'metadataKey' });
    }

    return result;
  }

  stringifyData = () => {
    const {
      registeredDate,
      orderDate,
      shippedDate,
      deliveryDate,
      promisedDate,
      estimatedDate,
      shippingDirection,
      carriers,
      states,
      origins,
      destinations,
      page,
      pageSize,
      metadataKey,
      metadataValue,
    } = this.state;
    const result = {};

    if (registeredDate && registeredDate.from !== 'any') result.registeredDate = JSON.stringify(registeredDate);
    if (orderDate && orderDate.from !== 'any') result.orderDate = JSON.stringify(orderDate);
    if (shippedDate && shippedDate.from !== 'any') result.shippedDate = JSON.stringify(shippedDate);
    if (deliveryDate && deliveryDate.from !== 'any') result.deliveryDate = JSON.stringify(deliveryDate);
    if (promisedDate && promisedDate.from !== 'any') result.promisedDate = JSON.stringify(promisedDate);
    if (estimatedDate && estimatedDate.from !== 'any') result.estimatedDate = JSON.stringify(estimatedDate);
    if (shippingDirection && shippingDirection !== 'any') result.shippingDirection = shippingDirection;
    if (carriers && carriers.length > 0) result.carriers = carriers.map(carrier => carrier.key);
    if (states && states.length > 0) result.states = states.map(state => state.value);
    if (origins && origins.length > 0) result.origins = origins.reduce((prev, next) => prev + next, '');
    if (page) result.page = page;
    if (pageSize) result.pageSize = pageSize;
    if (destinations && destinations.length > 0) {
      result.destinations = destinations.reduce((prev, next) => prev + next, '');
    }
    if (metadataKey && metadataKey.length > 0 && metadataValue && metadataValue.length > 0) {
      result.metadataKey = metadataKey;
      result.metadataValue = metadataValue;
    }

    return `?${Object.keys(result).length !== 0 ? stringify(result) : ''}`;
  };

  handleChange = (name, data) => {
    this.setState({
      [name]: data,
    }, () => this.submit());
  };

  submit = () => {
    const searchQuery = this.stringifyData();
    if (searchQuery) {
      setSelectedFilters(searchQuery);
    }

    this.props.history.push({
      search: joinQueries(searchQuery),
      state: {
        ...this.props.location.state,
      },
    });
  };

  updateState = () => {
    this.setState({
      ...parseFiltersSearchQuery(this.props.searchQuery, this.props.availableCarriers),
    });
  };

  render() {
    const selectedFilters = this.getRenderedStates();

    return (
      <div
        className={styles.filterSummaryWrapper}
      >
        {
          selectedFilters.map(filter => (
            <span
              key={filter.name}
              className={styles.filterSelection}
            >
              {filter.name}
              {filter.map
                && (
                  <Svg
                    className={styles.clear}
                    name="cross"
                    onClick={() => this.handleChange(filter.map, undefined)}
                  />
                )}
            </span>
          ))
        }
      </div>
    );
  }
}

const mapStateToProps = ({ carriers }, { location }) => ({
  searchQuery: location.search,
  availableCarriers: carriers,
});

const mapDispatchToProps = dispatch => ({
  getShipmentSummaries: searchQuery => dispatch(getShipmentSummariesAction(searchQuery)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterSummary));
