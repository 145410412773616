import React from 'react';
import styles from '../styles.scss';

function ConfigurationSelector({ highlighted, selected, configuration: { name, logoUrl } }) {
  const componentClasses = highlighted
    ? [styles.configurationSelector, styles.highlighted].join(' ')
    : styles.configurationSelector;

  return (
    <div
      className={componentClasses}
      role="button"
      onClick={() => selected()}
    >
      <img
        className={styles.logo}
        src={logoUrl}
        alt=""
      />
      <p className={styles.configurationTitle}>{name}</p>
    </div>
  );
}

export default ConfigurationSelector;
