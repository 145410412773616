import React, { Component } from 'react';
import Button from 'components/ReactButton';
import BackButton from 'components/BackButton';
import { required, maxLength } from 'helpers/validate';
import { validateForm } from 'helpers/validateForm';
import classNames from 'classnames';
import styles from './styles.scss';
import InputWithValidation from '../InputWithValidation';

const validationRules = {
  metaKey: [
    required(() => 'You must enter a key'),
    maxLength(30, () => 'Please enter a shorter metakey. Metakey should be 30 characters or less'),
  ],
  metaValue: [
    required(() => 'You must enter a value'),
    maxLength(50, () => 'Please enter a shorter value. Metavalue should be 50 characters or less'),
  ],
};
export default class InnerCardMetadata extends Component {
  titleStyles = classNames('h2', styles.titleAdditionalStyles)

  constructor(props) {
    super(props);

    this.state = {
      metaKey: this.props.metaKey,
      metaValue: this.props.metaValue,
      errors: null,
    };
  }

  handleCancel = () => {
    this.props.onCancel();
  };

  handleSubmit = () => {
    if ((this.state.metaKey && this.state.metaKey.length > 0) || (this.state.metaValue && this.state.metaValue.length > 0)) {
      const errors = validateForm(validationRules, this.state);
      if (errors.hasErrors) {
        this.setState({ errors });
        return;
      }
    }

    this.props.onSubmit(this.state.metaKey, this.state.metaValue);
  };

  render() {
    const {
      subText,
      title,
    } = this.props;
    const { metaKey, metaValue, errors } = this.state;
    return (
      <div className={classNames(styles.container, { [styles.expanded]: localStorage.getItem('sidebar.expanded') === 'true' })}>
        <div className={styles.titleContainer}>
          <div className={styles.backButtonContainer}>
            <BackButton onBack={this.handleSubmit} />
          </div>
          <h1 className={this.titleStyles}>{title}</h1>
          <div className={styles.subTitleButtonContainer}>
            {subText && (
              <p>{subText}</p>
            )}
          </div>
        </div>
        <div className={styles.containerInner}>
          <div>
            <InputWithValidation
              id="metaKeyId"
              type="text"
              value={metaKey}
              name="metaKey"
              onChange={e => this.setState({ metaKey: e.target.value })}
              label="Key"
              errors={errors}
            />
          </div>
          <div style={{ marginTop: '20px' }}>
            <InputWithValidation
              id="metaKeyId"
              type="text"
              value={metaValue}
              name="metaValue"
              onChange={e => this.setState({ metaValue: e.target.value })}
              label="Value"
              errors={errors}
            />
          </div>
        </div>

        <div className={`${styles.buttonsRow}`}>
          <Button className={styles.button} onClick={this.handleSubmit}>Apply</Button>
          <Button className={styles.button} theme="grey" onClick={this.handleCancel}>Cancel</Button>
        </div>
      </div>
    );
  }
}
