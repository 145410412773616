import React, { Component } from 'react';
import cx from 'classnames';
import styles from './styles.scss';

export class CarouselItem extends Component {
  render() {
    return (
      <li ref={this.props.innerRef} className={cx(styles.container, this.props.className)}>
        <div role="button" className={styles.clickArea} onClick={this.props.onClick}>
          {this.props.children}
        </div>
      </li>
    );
  }
}

export default CarouselItem;
