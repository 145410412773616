import React from 'react';

/* eslint-disable */
export default props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.1 26.3">
    <g>
      <path className="st0" d="M9.3,17h12.9c0.7,0,1.4-0.3,1.9-0.8c0.5-0.5,0.8-1.2,0.8-1.9V2.9c0-0.7-0.3-1.4-0.8-1.9s-1.2-0.8-1.9-0.8H2.9
        C2.2,0.2,1.5,0.4,1,1S0.2,2.2,0.2,2.9v11.4c0,0.7,0.3,1.4,0.8,1.9C1.5,16.7,2.2,17,2.9,17h0.9v3.5c0,0.3,0.1,0.5,0.3,0.7
        c0.3,0.3,0.8,0.4,1.2,0.2c0.1-0.1,0.3-0.2,0.4-0.3L9.3,17z M5.2,19.7v-3.4c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2H2.9
        c-0.4,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.6-0.4-1V2.9c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4h19.2c0.4,0,0.7,0.1,1,0.4
        c0.3,0.3,0.4,0.6,0.4,1v11.4c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-1,0.4H9c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.2,0.2L5.2,19.7z
        "/>
      <path className="st0" d="M28.2,4.8L28.2,4.8h-0.8v1.4h0.7c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1v11.4c0,0.4-0.1,0.7-0.4,1
        c-0.3,0.3-0.6,0.4-1,0.4h-1.6c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5v3.4l-3.3-3.8c-0.1-0.1-0.1-0.1-0.2-0.2
        c-0.1,0-0.2-0.1-0.3-0.1H9.7v1.4l12.1,0l3.6,4.2c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0.1,0.4,0.1,0.6,0.1c0.1,0,0.1,0,0.2-0.1
        c0.2-0.1,0.4-0.2,0.5-0.4c0.1-0.2,0.2-0.4,0.2-0.6v-3.5h0.9c0.7,0,1.4-0.3,1.9-0.8c0.5-0.5,0.8-1.2,0.8-1.9V7.6
        c0-0.7-0.3-1.4-0.8-1.9C29.6,5.1,28.9,4.8,28.2,4.8z"/>
      <path className="st0" d="M11.7,8.9c0,0.2,0.1,0.4,0.2,0.6c0.3,0.3,0.9,0.3,1.2,0c0.2-0.2,0.2-0.4,0.2-0.6c0-0.2-0.1-0.4-0.2-0.6
        C13,8.1,12.8,8,12.5,8c-0.2,0-0.4,0.1-0.6,0.2C11.8,8.4,11.7,8.6,11.7,8.9z"/>
      <path className="st0" d="M15,9.4c0.3,0.3,0.9,0.3,1.2,0c0.2-0.2,0.2-0.4,0.2-0.6s-0.1-0.4-0.2-0.6C16.1,8.1,15.9,8,15.6,8
        c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.4-0.2,0.6S14.9,9.3,15,9.4z"/>
      <path className="st0" d="M8.9,9.4c0.3,0.3,0.9,0.3,1.2,0c0.2-0.2,0.2-0.4,0.2-0.6c0-0.2-0.1-0.4-0.2-0.6C9.9,8.1,9.7,8,9.5,8
        C9.2,8,9,8.1,8.9,8.3C8.7,8.4,8.6,8.6,8.6,8.9C8.6,9.1,8.7,9.3,8.9,9.4z"/>
    </g>
  </svg>
)

/* eslint-enable */
