import React, { Component } from 'react';
import { connect } from 'react-redux';
import { event as gaEvent } from 'react-ga';
import API from 'helpers/API';
import {
  shipmentFilterNameGroupTitle,
  shipmentFilterEditNameGroupTitle,
  shipmentFilterSelectStatesTitle,
  shipmentFilterEditStatesTitle,
  shipmentFilterStatesSummaryTitle,
  shipmentFilterShipmentTypeTitle,
  shipmentFilterEditShipmentTypeTitle,
  shipmentFilterShipmentTagsTitle,
  shipmentFilterEditShipmentTagsTitle,
  shipmentFilterSelectIntegrationsTitle,
  shipmentFilterEditIntegrationsTitle,
  shipmentFilterShipmentWebhook,
} from 'constants/titles';
import { isNavagationValid } from 'helpers/navigationValidators';
import MultiPageForm from 'components/MultiPageForm';
import NameGroup from 'components/FormGroups/ShipmentFilterForm/NameGroup';
import ShipmentStates from 'components/FormGroups/ShipmentFilterForm/ShipmentStates';
import StatesSummary from 'components/FormGroups/ShipmentFilterForm/StatesSummary';
import ShipmentType from 'components/FormGroups/ShipmentFilterForm/ShipmentType';
import ShipmentTags from 'components/FormGroups/ShipmentFilterForm/ShipmentTags';
import {
  createShipmentFilter,
  updateShipmentFilter,
} from 'actions/shipmentFiltersActions';
import CarrierIntegrations from 'components/FormGroups/ShipmentFilterForm/CarrierIntegration';
import { closeSpinner, showSpinner } from 'actions';
import ShipmentWebhook from '../../components/FormGroups/ShipmentFilterForm/ShipmentWebhook';

class ShipmentFilterForm extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      name: '',
      carrier_integrations: [],
      states: [],
      calculated_events: [],
      shipment_types: [],
      tags: [],
      is_carrier_state_filter_enabled: false,
      single_trigger_per_shipment_group: false,
      single_trigger_grouping_metakey: null,
      single_trigger_timespan_in_minutes: 0,
    };

    if (this.props.initialData) {
      this.initialState = {
        id: this.props.initialData.id,
        carrier_integrations: this.props.initialData.carrier_integrations,
        name: this.props.initialData.name,
        states: this.props.initialData.states,
        calculated_events: this.props.initialData.calculated_events || [],
        shipment_types: this.props.initialData.shipment_types || [],
        tags: this.props.initialData.tags || [],
        is_carrier_state_filter_enabled: this.props.initialData.is_carrier_state_filter_enabled,
        single_trigger_per_shipment_group: this.props.initialData.single_trigger_per_shipment_group || false,
        single_trigger_grouping_metakey: this.props.initialData.single_trigger_grouping_metakey || null,
        single_trigger_timespan_in_minutes: this.props.initialData.single_trigger_timespan_in_minutes || 0,
      };
    }

    this.navigationStepValidators = [
      () => this.state.formData.name
        && this.state.formData.name.length > 0,
      () => true,
      () => (this.state.formData.states
        && this.state.formData.states.length > 0)
        || (this.state.formData.calculated_events
          && this.state.formData.calculated_events.length > 0),
      () => (this.state.formData.states
        && this.state.formData.states.length > 0)
        || (this.state.formData.calculated_events
          && this.state.formData.calculated_events.length > 0),
      () => this.state.formData.shipment_types
        && this.state.formData.shipment_types.length > 0,
      () => true,
      () => true,
    ];

    this.state = {
      formData: {
        ...this.initialState,
      },
      page: 0,
      supportedStates: null,
      isCarrierStateFilterDirty: this.props.initialData != null, // eslint-disable-line react/no-unused-state
    };

    this.prevCarrierIntegrations = null;
  }

  applyCarrierStateFilter =
    () => this.setState(prev => (
      {
        ...prev,
        formData: {
          ...prev.formData,
          is_carrier_state_filter_enabled: !prev.formData.is_carrier_state_filter_enabled,
          states: prev.formData.states.filter(x => prev.supportedStates.find(y => y.state_key === x.state).is_supported_by_selected_carriers),
        },
        isCarrierStateFilterDirty: true,
      }
    ));

  gotoPage = (targetPage) => {
    const { page } = this.state;
    if (isNavagationValid(page, targetPage, this.navigationStepValidators)) {
      if (this.prevCarrierIntegrations !== this.state.formData.carrier_integrations) {
        this.prevCarrierIntegrations = this.state.formData.carrier_integrations;
        this.updateSupportedStates();
      }
      this.setState({ page: targetPage });
    }
  };

  updateSupportedStates = async () => {
    this.props.showSpinner();
    this.setState({ supportedStates: null });
    const { shipment_states: supportedStates } = await API.getAvailableShipmentStates(
      this.state.formData.carrier_integrations.map(x => x.id),
    );

    this.setState((state) => {
      let { is_carrier_state_filter_enabled: isCarrierStateFilterEnabled } = state.formData;
      if (state.isCarrierStateFilterDirty) {
        if (state.formData.carrier_integrations.length === 0) {
          isCarrierStateFilterEnabled = false;
        }
      } else {
        isCarrierStateFilterEnabled = state.formData.carrier_integrations.length > 0;
      }

      return {
        supportedStates,
        formData: {
          ...state.formData,
          states: state.formData.states
            .filter(x => supportedStates.some(supportedState => supportedState.state_key === x.state
              && (!isCarrierStateFilterEnabled
                || supportedState.is_supported_by_selected_carriers))),
          is_carrier_state_filter_enabled: isCarrierStateFilterEnabled,
        },
      };
    }, () => {
      this.props.closeSpinner();
    });
  };

  updateState = (val, callback) => {
    this.setState(
      prevState => ({ formData: { ...prevState.formData, ...val } }),
      () => typeof callback === 'function' && callback(),
    );
  };

  submitForm = () => {
    gaEvent({
      category: 'ShipmentFilters',
      action: 'New shipment filter created',
    });
    if (this.props.initialData) {
      this.props.updateShipmentFilter(this.state.formData.id, this.state.formData);
    } else {
      this.props.createShipmentFilter(this.state.formData);
    }
    this.props.flipCard();
    this.gotoPage(0);
    this.setState({ formData: { ...this.initialState } });
  };

  cancel = () => {
    gaEvent({
      category: 'ShipmentFilters',
      action: 'Cancel shipment filter',
    });
    this.setState({ formData: { ...this.initialState } });
    this.props.flipCard();
    this.gotoPage(0);
  };

  render() {
    const { editMode } = this.props;
    return (
      <MultiPageForm
        page={this.state.page}
        goToPage={this.gotoPage}
        formPages={pageTabs => [
          <NameGroup
            shipmentFilterTitle={
              !editMode ? shipmentFilterNameGroupTitle : shipmentFilterEditNameGroupTitle
            }
            pageTabs={pageTabs}
            gotoPage={this.gotoPage}
            updateState={this.updateState}
            cancel={this.cancel}
            formData={this.state.formData}
          />,
          <CarrierIntegrations
            shipmentFilterTitle={
              !editMode ? shipmentFilterSelectIntegrationsTitle : shipmentFilterEditIntegrationsTitle
            }
            pageTabs={pageTabs}
            gotoPage={this.gotoPage}
            updateState={this.updateState}
            cancel={this.cancel}
            formData={this.state.formData}
          />,
          <ShipmentStates
            shipmentFilterTitle={
              !editMode ? shipmentFilterSelectStatesTitle : shipmentFilterEditStatesTitle
            }
            pageTabs={pageTabs}
            gotoPage={this.gotoPage}
            updateState={this.updateState}
            cancel={this.cancel}
            formData={this.state.formData}
            supportedStates={this.state.supportedStates}
            applyCarrierStateFilter={this.applyCarrierStateFilter}
          />,
          <StatesSummary
            shipmentFilterTitle={shipmentFilterStatesSummaryTitle}
            pageTabs={pageTabs}
            gotoPage={this.gotoPage}
            updateState={this.updateState}
            cancel={this.cancel}
            formData={this.state.formData}
          />,
          <ShipmentType
            shipmentFilterTitle={
              !editMode ? shipmentFilterShipmentTypeTitle : shipmentFilterEditShipmentTypeTitle
            }
            pageTabs={pageTabs}
            gotoPage={this.gotoPage}
            updateState={this.updateState}
            cancel={this.cancel}
            formData={this.state.formData}
          />,
          <ShipmentTags
            title={
              editMode
                ? shipmentFilterEditShipmentTagsTitle
                : shipmentFilterShipmentTagsTitle
            }
            gotoPage={this.gotoPage}
            pageTabs={pageTabs}
            formData={this.state.formData}
            updateState={this.updateState}
            cancel={this.cancel}
          />,
          <ShipmentWebhook
            title={shipmentFilterShipmentWebhook}
            gotoPage={this.gotoPage}
            pageTabs={pageTabs}
            formData={this.state.formData}
            updateState={this.updateState}
            submitForm={this.submitForm}
            cancel={this.cancel}
            editMode={!!editMode}
          />,
        ]}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createShipmentFilter: shipmentFilter => dispatch(createShipmentFilter(shipmentFilter)),
  updateShipmentFilter: (id, shipmentFilter) => dispatch(updateShipmentFilter(id, shipmentFilter)),
  showSpinner: () => dispatch(showSpinner()),
  closeSpinner: () => dispatch(closeSpinner()),
});

export default connect(null, mapDispatchToProps)(ShipmentFilterForm);
