import React from 'react';
import Svg from 'components/Svg';
import styles from './styles.scss';
import AddBtnRedesigned from '../../../components/AddFiltersBtnRedesigned/AddBtnRedesigned';

function ActiveFilterBar({
  filters, onClose, setFiltersButtonState, customBtnName,
}) {
  filters.sort((a, b) => {
    if (a.name === 'Date' && b.name === 'Status') {
      return -1;
    } if (a.name === 'Status' && b.name === 'Date') {
      return 1;
    }
    return 0;
  });

  return (
    <div className={styles.filters}>
      <AddBtnRedesigned
        onClick={() => {
          setFiltersButtonState(true);
        }}
      >
        {customBtnName?.length > 0 ? customBtnName : '+ Add filters'}
      </AddBtnRedesigned>
      {
                filters.map(filter => (
                  <span
                    key={filter.name}
                    className={styles.filterSelection}
                  >
                    {filter.name}
                    <Svg
                      className={styles.clear}
                      name="cross"
                      onClick={() => onClose(filter.value)}
                    />
                  </span>
                ))
            }
    </div>
  );
}

export default ActiveFilterBar;
