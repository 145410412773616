import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from 'components/Spinner';
import Svg from 'components/Svg';
import CreateCard from 'components/CreateCard';
import { event as gaEvent } from 'react-ga';
import { closePopup as closePopupAction, showPopup as showPopupAction } from 'actions';
import {
  getWebhooks as getWebhooksAction,
  revokeWebhook as revokeWebhookAction,
  toggleWebhook as toggleWebhookAction,
} from 'actions/webhookActions';
import Switch from 'components/Switch';
import DualButtons from 'components/DualButtons';
import CardShell from 'components/CardShell';
import { hasPermission } from 'helpers/auth';
import resources from 'constants/resources';
import styles from './WebhooksList.scss';
import TitleNavBlock from '../../../components/TitleNavBlock/TitleNavBlock';
import typography from '../../../helpers/appTypography';

export class CWebhooksList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasEditPermission: hasPermission(resources.webhooks.edit),
    };
  }

  componentDidMount() {
    this.props.getWebhooks();
  }

  render() {
    const {
      webhooks, history, loading, ...otherProps
    } = this.props;
    const {
      hasEditPermission,
    } = this.state;
    if (loading) {
      return <Spinner />;
    }

    return (
      <div>
        <TitleNavBlock
          title={typography.webhooks.title}
          subTitle={typography.webhooks.subTitle}
          onBack={() => window.history.back()}
        />
        <div className={styles.outerWhiteContainer}>
          <div className="container__cards-large">
            {hasEditPermission && (
              <div
                role="button"
                onClick={() => {
                  gaEvent({
                    category: 'Webhook',
                    action: 'Create new webhook',
                  });
                  history.push('/settings/webhooks/create');
                }}
              >
                <CreateCard text="Create new Webhook" />
              </div>
            )}
            {webhooks.map(webhook => (
              <CardShell key={webhook.configuration_id}>
                <div className={styles.bodyFront}>
                  <div className={styles.createdBody}>
                    <header role="button" className={styles.cardTitleHeader} onClick={() => history.push(`/settings/webhooks/${webhook.configuration_id}/logs`)}>
                      <h4 className={styles.cardTitle}>
                        {webhook.configuration.name}
                      </h4>
                    </header>
                    <div role="button" className={styles.iconInnerContainer} onClick={() => history.push(`/settings/webhooks/${webhook.configuration_id}/logs`)}>
                      <Svg className={styles.webhookIcon} name="WebHook" />
                    </div>
                    <div className={styles.switcherHolder}>
                      <Switch
                        id={webhook.configuration_id}
                        enabled={webhook.enabled}
                        toggle={otherProps.toggleWebhook}
                        disabled={!hasEditPermission}
                      />
                    </div>
                    <footer className={styles.webhookFooter}>
                      <DualButtons
                        rightFunction={() => {
                          gaEvent({
                            category: 'Webhook',
                            action: 'Edit webhook',
                          });
                          this.props.history.push(`/settings/webhooks/${webhook.configuration_id}`);
                        }}
                        rightText="Edit"
                        rightFunctionDisabled={!hasEditPermission}
                        leftFunction={() => otherProps.showPopup({
                          title: 'Are you sure?',
                          description: <span>Revoking of webhooks <u>deletes</u> it completely.</span>,
                          onAccept: () => otherProps.revokeWebhook(webhook.configuration_id).then((cb) => {
                            gaEvent({
                              category: 'Webhook',
                              action: 'Delete webhook',
                            });
                            otherProps.closePopup();
                            cb();
                          }),
                        })}
                        leftText="Delete"
                        leftFunctionDisabled={!hasEditPermission}
                      />
                    </footer>
                  </div>
                </div>
              </CardShell>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = state => ({
  webhooks: state.webhooksStates.webhooks,
  loading: state.webhooksStates.loading,
  locales: state.locales,
});

const mapDispatchToProps = dispatch => ({
  getWebhooks: () => dispatch(getWebhooksAction()),
  revokeWebhook: id => dispatch(revokeWebhookAction(id)),
  showPopup: config => dispatch(showPopupAction(config)),
  closePopup: () => dispatch(closePopupAction()),
  toggleWebhook: (id, webhookActive) => dispatch(toggleWebhookAction(id, webhookActive)),
});

export default connect(mapStatetoProps, mapDispatchToProps)(CWebhooksList);
