import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import styles from 'routes/Settings/SftpAccount/SftpAccountsList.scss';
import CreateCard from 'components/CreateCard';
import Sftp from 'images/sftp.svg';
import API from 'helpers/API';
import CardShell from 'components/CardShell';
import ReactTooltip from 'react-tooltip';
import { hasPermission } from 'helpers/auth';
import resources from 'constants/resources';
import Spinner from 'components/Spinner';
import TitleNavBlock from '../../../components/TitleNavBlock/TitleNavBlock';
import typography from '../../../helpers/appTypography';

export const SftpAccountsList = (props) => {
  const { history } = props;
  const [sftpAccounts, setSftpAccounts] = useState(null);
  const canEdit = hasPermission(resources.emailShipmentRegistration.edit);
  const [loading, setLoading] = useState(true);

  const handlerAddSftpAccount = () => {
    history.push('/settings/sftp-account/add');
  };

  useEffect(() => {
    const loadSftpAccounts = async () => {
      setLoading(true);
      const { sftpAccounts: loadedSftpAccounts } = await API.getSftpAccounts();
      setSftpAccounts(loadedSftpAccounts);
      setLoading(false);
    };
    loadSftpAccounts();
  }, []);

  return (
    <div>
      {loading && <Spinner />}
      <TitleNavBlock
        title={typography.SFTPAccounts.title}
        subTitle={typography.SFTPAccounts.subTitle}
        onBack={() => window.history.back()}
      />
      <div className={cx('container__cards', styles.cardContainer)}>
        {canEdit && (
          <CreateCard
            text="Create SFTP Account"
            handleClick={handlerAddSftpAccount}
            thin
          />
        )}
        {sftpAccounts?.map(item => (
          <CardShell key={item.id}>
            <ReactTooltip />
            <div className={styles.container}>
              <h3 data-tip={item.userName} className={`textCenter subtitle1 ${styles.cardTitle}`}>{item.userName}</h3>
              <Sftp className={styles.logoHolder} />
            </div>
          </CardShell>
        ))}
      </div>
    </div>
  );
};

export default connect(null, null)(SftpAccountsList);
