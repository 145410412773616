import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import DateInput from '@material-ui/core/TextField';
import classnames from 'classnames';
import vocabulary from 'constants/vocabulary';
import DateTimeStyles from './DateTime.scss';

export default class DateTime extends Component {
  static defaultProps = {
    value: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    };
  }

  extractDateAndHours = (dateObject) => {
    let date = '';
    let hours = '';
    if (dateObject && !vocabulary[dateObject]) {
      [date, hours] = new Date(dateObject).toISOString().split('T');
      hours = hours.substr(0, 2);
    }
    return [date, hours];
  };

  handleChange = (e) => {
    const { type, value } = e.target;

    if (value) {
      const [previousDate, previousHours] = this.extractDateAndHours(this.props.value);
      this.props.onChange({
        target: {
          name: this.props.name,
          value: type === 'date'
            ? new Date(value).setUTCHours(+previousHours)
            : new Date(previousDate).setUTCHours(+value),
        },
      });
    } else {
      this.props.onChange({
        target: { value, name: this.props.name },
      });
    }
  };

  handleTimePick = (e) => {
    // we cant pick time until we pick date
    if (!this.props.value) return;

    const { value } = e.target;
    this.handleChange({ target: { value, type: 'hours' } });
  };

  render() {
    const {
      label,
      value,
      pickerName,
      ...props
    } = this.props;
    const [dateValue, hoursValue] = this.extractDateAndHours(value);
    return (
      <div>
        <DateInput
          {...props}
          inputProps={{
            className: classnames(
              DateTimeStyles.dateInput,
              (props.name === 'to') && DateTimeStyles.withShortLabel,
              dateValue && DateTimeStyles.hasValue,
            ),
            label,
          }}
          style={{ width: 'calc(100% - 75px)' }}
          value={dateValue}
          type="date"
          onChange={this.handleChange}
          id={`${pickerName}${props.name}`}
          placeholder="--/--/--"
        />
        <FormControl disabled={!value}>
          <InputLabel htmlFor={`${pickerName}${props.name}Hours`}>Hours</InputLabel>
          <Select
            inputProps={{
              id: `${pickerName}${props.name}Hours`,
            }}
            style={{ width: '73px' }}
            value={hoursValue}
            open={this.state.dropdownOpen}
            onChange={this.handleTimePick}
            onOpen={() => this.setState({ dropdownOpen: true })}
            onClose={() => this.setState({ dropdownOpen: false })}
          >
            <MenuItem value="00">00:00</MenuItem>
            <MenuItem value="01">01:00</MenuItem>
            <MenuItem value="02">02:00</MenuItem>
            <MenuItem value="03">03:00</MenuItem>
            <MenuItem value="04">04:00</MenuItem>
            <MenuItem value="05">05:00</MenuItem>
            <MenuItem value="06">06:00</MenuItem>
            <MenuItem value="07">07:00</MenuItem>
            <MenuItem value="08">08:00</MenuItem>
            <MenuItem value="09">09:00</MenuItem>
            <MenuItem value="10">10:00</MenuItem>
            <MenuItem value="11">11:00</MenuItem>
            <MenuItem value="12">12:00</MenuItem>
            <MenuItem value="13">13:00</MenuItem>
            <MenuItem value="14">14:00</MenuItem>
            <MenuItem value="15">15:00</MenuItem>
            <MenuItem value="16">16:00</MenuItem>
            <MenuItem value="17">17:00</MenuItem>
            <MenuItem value="18">18:00</MenuItem>
            <MenuItem value="19">19:00</MenuItem>
            <MenuItem value="20">20:00</MenuItem>
            <MenuItem value="21">21:00</MenuItem>
            <MenuItem value="22">22:00</MenuItem>
            <MenuItem value="23">23:00</MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  }
}
