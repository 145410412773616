import React, { useEffect, useState, useRef } from 'react';
import CardShell from 'components/CardShell';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import API from 'helpers/API';
import TablePaginator from 'components/TablePaginator';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from 'components/Spinner';
import { format } from 'date-fns';
import { closeSpinner as closeSpinnerAction, showSpinner as showSpinnerAction } from 'actions';
import { matchStringToDate } from 'helpers/datetime';
import vocabulary from 'constants/vocabulary';
import FilterBar from './FilterBar';
import styles from './styles.scss';
import ActveFilterBar from './ActiveFilterBar';
import typography from '../../../helpers/appTypography';
import TitleNavBlock from '../../../components/TitleNavBlock/TitleNavBlock';

const defaultPageSize = 200;
const defaultFilters = {
  page: 0,
  pageSize: defaultPageSize,
  status: 'all',
  date: { from: 'any' },
  activeFilters: [],
};

function renderStatus(row) {
  const statusCode = row.value;
  const statusColor = statusCode >= 200 && statusCode <= 299 ? '#00854D' : '#C12547';
  return (
    <span>
      <span className={styles.status} style={{ background: statusColor }} />
      {row.value ? row.value : <span style={{ visibility: 'hidden' }}>XXX</span>}
    </span>
  );
}

function WebhookLogs({ showSpinner, closeSpinner }) {
  const [data, setData] = useState({ total: 0, webhookLogs: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState(defaultFilters);
  const [filtersButtonState, setFiltersButtonState] = useState(false);

  const history = useHistory();
  const { id } = useParams();
  const isInitialMount = useRef(true);

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const urlPage = params.get('page');
    const urlPageSize = params.get('pageSize');
    const urlStatus = params.get('status');
    const urlDateFrom = params.get('date_from');
    const urlDateTo = params.get('date_to');

    const requestFilters = {};
    const updatedFilters = {
      ...defaultFilters,
      date: { from: 'any' },
      activeFilters: [],
    };

    if (urlPage) {
      updatedFilters.page = parseInt(urlPage, 10) - 1;
    }

    if (urlPageSize) {
      updatedFilters.pageSize = parseInt(urlPageSize, 10);
    }

    if (urlStatus) {
      requestFilters.status = urlStatus;
      updatedFilters.activeFilters.push({ name: 'Status', value: 'status' });
      updatedFilters.status = Object.keys(vocabulary).find(key => vocabulary[key] === urlStatus);
    }

    if (urlDateFrom) {
      if (vocabulary[urlDateFrom]) {
        const filterDate = matchStringToDate(urlDateFrom);
        requestFilters.from = filterDate.from;
        requestFilters.to = filterDate.to;
      } else {
        requestFilters.from = urlDateFrom;
      }

      updatedFilters.date.from = urlDateFrom;
    }

    if (urlDateTo) {
      requestFilters.to = urlDateTo;
      updatedFilters.date.to = requestFilters.to;
      if (updatedFilters.date.from === defaultFilters.date.from) {
        updatedFilters.date.from = 'custom';
      }
    }

    if (urlDateFrom || urlDateTo) {
      updatedFilters.activeFilters.push({ name: 'Date', value: 'date' });
    }

    const fetchData = async () => {
      if (!isInitialMount.current) {
        showSpinner();
      }
      const response = await API.getWebhookLogs(id, updatedFilters.page, updatedFilters.pageSize, requestFilters);
      const responseData = {
        ...response,
        webhookLogs: response.webhookLogs.map(x => ({
          ...x,
          date: format(new Date(x.date), 'DD/MM/YYYY HH:mm:ss'),
        })),
      };
      setData(responseData);

      if (isInitialMount.current) {
        isInitialMount.current = false;
        setIsLoading(false);
      } else {
        closeSpinner();
      }
    };

    fetchData();
    setFilters(updatedFilters);
  }, [id, showSpinner, closeSpinner, history.location.search]);

  function changePage(newPage) {
    const params = new URLSearchParams(history.location.search);
    const safePage = newPage + 1;
    params.set('page', safePage);

    history.push(({
      pathname: 'logs',
      search: `?${params.toString()}`,
    }));
  }

  function removeActiveFilter(filter) {
    const params = new URLSearchParams(history.location.search);

    // eslint-disable-next-line default-case
    switch (filter) {
      case 'status':
        params.delete(filter);
        break;
      case 'date':
        params.delete('date_from');
        params.delete('date_to');
    }
    history.push(({
      pathname: 'logs',
      search: `?${params.toString()}`,
    }));
  }

  function chnagePageSize(newPageSize) {
    const params = new URLSearchParams(history.location.search);
    params.set('pageSize', newPageSize);

    const urlPage = parseInt((params.get('page') || 1), 10);
    const pageInRange = Math.min(urlPage, Math.ceil(data.total / newPageSize));
    params.set('page', pageInRange);

    history.push(({
      pathname: 'logs',
      search: `?${params.toString()}`,
    }));
  }

  function applyFilters(formFilters) {
    const params = new URLSearchParams();
    if (formFilters.status) {
      params.set('status', formFilters.status);
    }

    if (formFilters.date && formFilters.date.from && formFilters.date.from !== 'custom') {
      params.set('date_from', formFilters.date.from);
    }

    if (formFilters.date && formFilters.date.to) {
      params.set('date_to', formFilters.date.to);
    }

    history.push(({
      pathname: 'logs',
      search: `?${params.toString()}`,
    }));
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <TitleNavBlock
        title={typography.webhookLogs.title}
        subTitle={typography.webhookLogs.subTitle}
        onBack={() => window.history.back()}
      />
      <FilterBar
        status={filters.status}
        date={filters.date}
        onApply={applyFilters}
        filtersButtonState={filtersButtonState}
        setFiltersButtonState={setFiltersButtonState}
      />
      <ActveFilterBar
        filters={filters.activeFilters}
        onClose={removeActiveFilter}
        setFiltersButtonState={setFiltersButtonState}
        customBtnName="+ Add webhook filters"
      />
      {
        data.webhookLogs.length
          ? (
            <CardShell>
              <ReactTable
                className="webhooks"
                data={data.webhookLogs}
                columns={[
                  {
                    Header: 'Id',
                    accessor: 'id',
                    show: false,
                  },
                  {
                    Header: 'Status',
                    accessor: 'statusCode',
                    Cell: row => renderStatus(row),
                    maxWidth: 150,
                  },
                  {
                    Header: 'URL',
                    accessor: 'url',
                  },
                  {
                    Header: 'Elapsed Time',
                    accessor: 'elapsedTime',
                    maxWidth: 200,
                  },
                  {
                    Header: 'Date',
                    accessor: 'date',
                    maxWidth: 200,
                  },
                ]}
                totalRows={data.total}
                getTdProps={(state, rowInfo) => ({
                  onClick: () => history.push(`logs/${rowInfo.row.id}`),
                })}
                sortable={false}
                page={filters.page}
                footerText="records"
                onPageChange={changePage}
                onPageSizeChange={x => chnagePageSize(x)}
                minRows={0}
                defaultPageSize={filters.pageSize}
                pageSizeOptions={[20, 50, 100, 200]}
                PaginationComponent={TablePaginator}
                pages={Math.ceil(data.total / filters.pageSize)}
                manual
                trackingComponentName="Webhook Logs"
              />
            </CardShell>
          )
          : <div>There were no records found based on filters applied</div>
      }
    </div>
  );
}

export default connect(null, { showSpinner: showSpinnerAction, closeSpinner: closeSpinnerAction })(WebhookLogs);
