export const arePreviousStepsValid = (targerStep, navigationStepValidators) => {
  let isValid = true;
  for (let i = 0; i < targerStep; i += 1) {
    isValid = isValid && navigationStepValidators[i] && (navigationStepValidators[i])();
  }

  return isValid;
};

export const isNavagationValid = (currentStep, targetStep, navigationStepValidators) => {
  const isCurrentStepValid = (navigationStepValidators[currentStep])();
  const isNextStepAllowed = currentStep === targetStep - 1
      && isCurrentStepValid;
  const isPreviousStepsAllowed = currentStep > targetStep;
  const isNextStepsAllowed = currentStep < targetStep - 1
      && isCurrentStepValid
      && arePreviousStepsValid(targetStep, navigationStepValidators);

  return isNextStepAllowed || isPreviousStepsAllowed || isNextStepsAllowed;
};
