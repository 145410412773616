import React from 'react';
import InputWithValidation from 'components/InputWithValidation';
import styles from '../styles.scss';

const ApiKey = (props) => {
  const {
    apiHeaderKey,
    onApiHeaderInputChange,
    onApiValueInputChange,
    apiKeyValue,
  } = props;

  return (
    <div className={styles.formSection}>
      <div className={styles.methodHeader}>
        <p> If required, enter authentication headers for your API by entering their Key and Value.</p>
      </div>
      <div className={styles.methodsForm}>
        <div className={styles.formRow}>
          <InputWithValidation
            label="Request header"
            placeholder="Header"
            onChange={onApiHeaderInputChange}
            value={apiHeaderKey}
            type="text"
            name="Key"
          />
        </div>
        <div className={styles.formRow}>
          <InputWithValidation
            label="API Key value"
            placeholder="API Key value"
            onChange={onApiValueInputChange}
            value={apiKeyValue}
            type="text"
            name="Value"
          />
        </div>
      </div>
    </div>
  );
};

export default ApiKey;
