import {
  EDIT_CUSTOM_STATE_LABEL_CLEAR,
  EDIT_CUSTOM_STATE_LABEL_SET,
  EDIT_CUSTOM_STATE_LABEL_SUCCESS,
  EDIT_CUSTOM_STATE_LABEL_ERROR,
} from 'actions/types';

const initialState = {
  customStateLabel: null,
  errors: [],
  error: '',
};

export default function editCustomStateLabelReducer(state = initialState, action) {
  switch (action.type) {
    case EDIT_CUSTOM_STATE_LABEL_CLEAR:
      return {
        customStateLabel: null,
        errors: [],
        error: '',
      };
    case EDIT_CUSTOM_STATE_LABEL_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case EDIT_CUSTOM_STATE_LABEL_SUCCESS:
      return {
        customStateLabel: action.customStateLabel,
        errors: [],
        error: '',
      };
    case EDIT_CUSTOM_STATE_LABEL_SET:
      return action.editCustomStateLabel;
    default:
      return state;
  }
}
