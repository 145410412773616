import API from 'helpers/API';

import {
  SHIPMENT_FILTERS_FETCH,
  SHIPMENT_FILTERS_SUCCESS,
  SHIPMENT_AVAILABLE_STATES_FETCH,
  SHIPMENT_AVAILABLE_STATES_SUCCESS,
  SHIPMENT_AVAILABLE_STATES_ERROR,
} from './types';

export function getShipmentFilters() {
  return (dispatch) => {
    dispatch({ type: SHIPMENT_FILTERS_FETCH });

    return API.getShipmentFilters().then((response) => {
      const groups = response.shipment_filters;
      dispatch({ type: SHIPMENT_FILTERS_SUCCESS, groups });
      return Promise.resolve(groups);
    });
  };
}

export function getAvailableShipmentStates() {
  return (dispatch) => {
    dispatch({ type: SHIPMENT_AVAILABLE_STATES_FETCH });
    return API.getAvailableShipmentStates()
      .then((response) => {
        dispatch({ type: SHIPMENT_AVAILABLE_STATES_SUCCESS, states: response.shipment_states });
      })
      .catch((errorResponse) => {
        dispatch({ type: SHIPMENT_AVAILABLE_STATES_ERROR });
        return Promise.resolve(errorResponse);
      });
  };
}

export function updateShipmentFilter(id, shipmentFilter) {
  return dispatch => API.updateShipmentFilter(id, shipmentFilter).then(() => {
    dispatch(getShipmentFilters());
  });
}

export function createShipmentFilter(shipmentFilter) {
  return dispatch => API.createShipmentFilter(shipmentFilter).then((response) => {
    dispatch(getShipmentFilters());
    return Promise.resolve(response);
  });
}

export function deleteShipmentFilter(id) {
  return dispatch => API.deleteShipmentFilter(id).then(() => {
    dispatch(getShipmentFilters());
  });
}
