function isRegexMatch(value, regex) {
  return regex.test(String(value));
}

function isCheckedValid(value) {
  return value;
}

function isRequiredValid(value) {
  return value && (!value.trim || value.trim());
}

function isMaxLengthValid(value, length) {
  return !value || !value.trim() || value.trim().length <= length;
}

function isMinLengthValid(value, length) {
  return value && value.trim() && value.trim().length >= length;
}

function isCompareValuesValid(value, otherFieldValue) {
  return value && value === otherFieldValue;
}

function isChecked(message) {
  return { name: 'isChecked', message: message || (() => '\'{0}\' must be checked'), isValid: isCheckedValid };
}

function required(message) {
  return { name: 'required', message: message || (() => '\'{0}\' should not be empty'), isValid: isRequiredValid };
}

function isEmail(message) {
  return {
    name: 'isEmail',
    message: message || (() => 'Please enter a valid email address'),
    isValid: (value) => {
      // eslint-disable-next-line
      const re = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
      return isRegexMatch(value.toLowerCase(), re);
    },
  };
}

function inRangeOf(value, min, max) {
  return !value || (value >= min && value <= max);
}

function isGoogleFontUrl(value) {
  return value && value.startsWith('https://fonts.googleapis.com/css?family=');
}

function validUrl(value) {
  // eslint-disable-next-line max-len
  return !value || /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,63}(:[0-9]{1,5})?(\/[a-z0-9$\-_.+!*'(),%?#=&]*)*$/gi.test(value);
}

function validUrlPart(value, isEscapedCharsAllowed) {
  return !value || new RegExp(`^[a-zA-Z0-9$\\-_.+!*'(),${isEscapedCharsAllowed ? '%' : ''}]+$`).test(value);
}

function validInteger(value) {
  return !value || Number.isInteger(Number(value));
}

function isMaxArrayLengthValid(value, length) {
  return !value || value.length <= length;
}

function isMinArrayLengthValid(value, length) {
  return !value || value.length >= length;
}

function maxLength(length, message) {
  return {
    name: 'maxLength',
    length,
    message: message || (
      () => `'{0}' must be a maximum of ${length} characters.`),
    isValid: value => isMaxLengthValid(value, length),
  };
}

function minLength(length, message) {
  return {
    name: 'minLength',
    length,
    message: message || (
      value => `The length of '{0}' must be at least ${length} characters.`
        + ` You entered ${value ? value.trim().length : 0} characters.`),
    isValid: value => isMinLengthValid(value, length),
  };
}

function compareValues(fieldLabel1, fieldLabel2, message, getOtherFieldValueFunc) {
  return {
    name: 'compareValues',
    message: message || (() => `'${fieldLabel1}' should be equal to '${fieldLabel2}'.`),
    isValid: (value, propertyName) => isCompareValuesValid(value, getOtherFieldValueFunc(propertyName)),
  };
}

function rangeOf(minimumValue, maximumValue, message) {
  return {
    name: 'isBetweenTwoNumber',
    message: message || (() => `must be in range between ${minimumValue} and ${maximumValue}`),
    isValid: value => inRangeOf(value, minimumValue, maximumValue),
  };
}

function checkGoogleFontUrl(message) {
  return {
    name: 'isGoogleFontUrl',
    message: message || (() => 'should be a Google Font from https://fonts.googleapis.com'),
    isValid: value => isGoogleFontUrl(value),
  };
}

function isUrl(message) {
  return {
    name: 'isUrl',
    message: message || (() => 'Should be a valid URL'),
    isValid: value => validUrl(value),
  };
}

function isUrlPart(isEscapedCharsAllowed, message) {
  return {
    name: 'isUrl',
    message: message || (() => `You can use letters, numbers, and the special characters $-_.+!*\\'(),
    ${isEscapedCharsAllowed ? '%' : ''}`),
    isValid: value => validUrlPart(value, isEscapedCharsAllowed),
  };
}

function isInteger(message) {
  return {
    name: 'isInteger',
    message: message || (() => 'Should be an integer number'),
    isValid: value => validInteger(value),
  };
}

function maxArrayLength(length, message) {
  return {
    name: 'maxArrayLength',
    message: message || (() => `The length of '{0}' must be ${length} or fewer.`),
    isValid: value => isMaxArrayLengthValid(value, length),
  };
}

function minArrayLength(length, message) {
  return {
    name: 'minArrayLength',
    message: message || (() => `The length of '{0}' must be at least ${length}.`),
    isValid: value => isMinArrayLengthValid(value, length),
  };
}

function matchRegex(regex, message) {
  return {
    name: 'matchRegex',
    message,
    isValid: value => isRegexMatch(value, regex),
  };
}

function customValidator(isValid, messageFunc) {
  return {
    name: 'customValidator',
    message: messageFunc,
    isValid,
  };
}

function validateEach(rules) {
  return {
    name: 'validateEach',
    arrayItemRules: rules,
  };
}

export {
  isChecked,
  required,
  isEmail,
  maxLength,
  minLength,
  compareValues,
  rangeOf,
  checkGoogleFontUrl,
  isUrl,
  isUrlPart,
  isInteger,
  maxArrayLength,
  minArrayLength,
  validateEach,
  matchRegex,
  customValidator,
};
