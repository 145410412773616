import React, { Component } from 'react';
import cx from 'classnames';
import * as propTypes from 'prop-types';
import styles from './styles.scss';

class ListItem extends Component {
  static propTypes = {
    isSelected: propTypes.bool,
    isSelectable: propTypes.bool,
    selectedItemClassName: propTypes.string,
    className: propTypes.string,
    children: propTypes.node,
  };

  static defaultProps = {
    isSelected: false,
    isSelectable: false,
    selectedItemClassName: null,
    className: null,
    children: null,
  };

  handleClick = () => {
    this.props.onClick(this);
  };

  render() {
    const {
      isSelected,
      isSelectable,
      selectedItemClassName,
      className,
      children,
    } = this.props;

    const buttonStyles = isSelected ? [styles.selected, selectedItemClassName] : null;
    return (
      <li className={cx(styles.listItemContainer, className)}>
        {isSelectable
          ? (
            <div
              role="button"
              className={cx(styles.listItem, buttonStyles)}
              onClick={this.handleClick}
            >
              {children}
            </div>
          )
          : children}
      </li>
    );
  }
}

export default ListItem;
