import React, { Component } from 'react';
import cx from 'classnames';
import Svg from 'components/Svg/index';
import ColouredDot from 'components/ColouredDot';
import styles from '../styles.scss';

class StateItem extends Component {
  constructor(props) {
    super(props);
    this.state = { cardAdded: false };
  }

  componentDidMount() {
    if (this.props.formData.states.filter(state => state.state === this.props.stateKey).length > 0) {
      this.setState({ cardAdded: true });
    }
  }

  addCard = () => {
    const { stateKey, category, name } = this.props;
    const savedStates = this.props.formData.states;
    const stateObject = {
      state: stateKey,
      name,
      category,
    };

    this.props.updateState({ states: [...savedStates, stateObject] });

    this.setState({ cardAdded: true });
  };

  toggleCard = () => {
    if (!this.state.cardAdded) {
      this.addCard();
    } else {
      this.clearCard();
    }
  };

  clearCard() {
    const savedStates = this.props.formData.states;
    const currentState = this.props.stateKey;

    const removeState = savedStates.filter(state => state.state !== currentState);

    this.props.updateState({ states: [...removeState] });

    this.setState({ cardAdded: false });
  }

  showClear() {
    if (this.state.cardAdded) {
      return (
        <div>
          <button type="button" onClick={this.toggleCard} className={styles.buttonNotObvious}>
            <Svg className={styles.closeIcon} name="tickWithStroke" />
          </button>
        </div>
      );
    }
    return (
      <div>
        <button type="button" onClick={this.toggleCard} className={styles.buttonNotObvious}>
          <div className={styles.closeIconDeselected} />
        </button>
      </div>
    );
  }

  render() {
    const { category, name } = this.props;
    const { cardAdded } = this.state;
    return (
      <>
        <div
          role="button"
          onClick={this.toggleCard}
          className={
            !cardAdded ? styles.stateListItemHeader : cx(styles.stateListItemHeader, styles.stateListItemHeaderActive)
          }
        >
          <ColouredDot theme={category} />
          <div className={styles.stateTitle}>{ name }</div>
          {this.showClear()}
        </div>
      </>
    );
  }
}

export default StateItem;
