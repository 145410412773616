import React, { Component } from 'react';
import DualButtons from 'components/DualButtons';
import BackButton from 'components/BackButton';
import cx from 'classnames';
import List from 'components/List';
import ListItem from 'components/List/ListItem';
import Hr from 'components/CardShell/Hr';
import Header from 'components/CardShell/Header';
import MultiSelectedSvg from 'images/multiSelected.svg';
import MultiUnselectedSvg from 'images/multiUnselected.svg';
import ShipmentFilterContext from 'routes/Settings/ShipmentFilters/ShipmentFiltersContext';
import Spinner from 'components/Spinner';
import styles from './styles.scss';

class CarrierIntegrations extends Component {
  static contextType = ShipmentFilterContext;

  static MapToResponse(carrierIntegration) {
    return {
      id: carrierIntegration.id,
      carrier_id: carrierIntegration.carrier.id,
    };
  }

  get carrierIntegrations() {
    return this.props.formData.carrier_integrations || [];
  }

  get availableCarrierIntegrations() {
    return this.context.carrierIntegrations;
  }

  handleCarrierIntegrationSelect = (e) => {
    this.props.updateState({
      carrier_integrations: e.map(i => CarrierIntegrations.MapToResponse(this.availableCarrierIntegrations[i])),
    });
  };

  handleSelectAllEventsClick = () => {
    this.props.updateState({
      carrier_integrations: this.availableCarrierIntegrations.map(CarrierIntegrations.MapToResponse),
    });
  };

  handleUnselectAllEventsClick = () => {
    this.props.updateState({ carrier_integrations: [] });
  };

  handleSectionChange = (page) => {
    this.props.gotoPage(page);
  };

  areAllCarrierSelected = () => this.availableCarrierIntegrations.length !== 0
    && this.carrierIntegrations.length === this.availableCarrierIntegrations.length
    && this.carrierIntegrations.every(x => this.availableCarrierIntegrations.some(y => x.id === y.id));

  render() {
    const {
      shipmentFilterTitle,
      pageTabs,
      cancel,
    } = this.props;
    return (
      <div className={styles.cardsection}>
        {!this.availableCarrierIntegrations && <Spinner />}
        <div className={styles.cardSectionInner}>
          <Header>{shipmentFilterTitle}</Header>
          <Hr />
          <div className={styles.buttonBack}>
            <BackButton onBack={() => this.handleSectionChange(0)} />
          </div>
          {pageTabs()}
          <p className={styles.numberSection}>
            Select the carriers you want updates on.<br />
            You can skip this step if you want to be notified about shipments from any carrier.
          </p>
          {this.availableCarrierIntegrations && (
            <>
              <div className={styles.actionsContainer}>
                {this.areAllCarrierSelected()
                  ? (
                    <button
                      type="button"
                      className={cx(styles.action, 'button-outline')}
                      onClick={this.handleUnselectAllEventsClick}
                    >
                      Unselect all
                    </button>
                  )
                  : (
                    <button
                      type="button"
                      className={cx(styles.action, 'button-outline')}
                      onClick={this.handleSelectAllEventsClick}
                    >
                      Select all
                    </button>
                  )}
              </div>
              <List
                className={styles.list}
                isSelectable
                isMultiSelect
                selectedIndexes={this.carrierIntegrations.map(carrierIntegration => this.availableCarrierIntegrations
                  .findIndex(x => x.id === carrierIntegration.id))}
                onSelectionChange={this.handleCarrierIntegrationSelect}
              >
                {this.availableCarrierIntegrations.map(carrierIntegration => (
                  <ListItem key={carrierIntegration.id} selectedItemClassName={styles.selectedCalculatedEvent}>
                    <div className={styles.listItem}>
                      <span className={styles.listItemTitle}>{carrierIntegration.name}</span>
                      <img alt="" className={styles.carrierLogo} src={carrierIntegration.carrier.logoUrl} />
                      <MultiSelectedSvg className={cx(styles.selectIcon, styles.selectedIcon)} />
                      <MultiUnselectedSvg className={cx(styles.selectIcon, styles.unselectedIcon)} />
                    </div>
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </div>
        <DualButtons
          leftFunction={cancel}
          leftText="Cancel"
          rightFunction={() => this.handleSectionChange(2)}
          rightText="Next"
          theme="rightPink"
        />
      </div>
    );
  }
}

export default CarrierIntegrations;
