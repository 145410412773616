// eslint-disable-next-line no-multi-str
export const downloadFileMessage = 'Please download the private key file at a safe location for future use. \
You will need this file, along with the username and password, just submitted, \
to be able to use SFTP Uploads for shipment details.';
export const createSftpAccountDescription = 'Enter your username and password';

// eslint-disable-next-line operator-linebreak
export const sftpAccountNameValidationMessage =
  'Username length should be at least 5 but not more than 100 characters.';
// eslint-disable-next-line operator-linebreak
export const sftpAccountPasswordValidationMessage =
  'Password length should be at least 8 but not more than 200 characters.';
// eslint-disable-next-line operator-linebreak
export const ShipmentFilterNameValidationMessage =
  'Name length should be not more than 50 characters.';
