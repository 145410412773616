const generatorMaximum = 100000;
const generatorMinimum = 1;

export const generateRandomNumber = () => {
  const crypto = window.crypto || window.msCrypto;
  const array = new Uint32Array(1);
  crypto.getRandomValues(array); // Compliant for security-sensitive use cases
  return array[0] / 4294967295;
};

const s4 = () => Math
  .floor((1 + generateRandomNumber()) * 0x10000)
  .toString(16)
  .substring(1);

const guid = () => `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;

// This method was added like temporary solution because now we use both Ids and Indexes in TrackingPage Admin Panel
// There is no reason to use both on Create and Edit TrackingPages, we have to refactor it to use only one from them.
export const generateId = () => Math.floor(
  generateRandomNumber() * (generatorMaximum - generatorMinimum + 1),
) + generatorMinimum;

export default guid;
