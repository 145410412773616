import { stringify, parse as parseUrl } from './queryString';

const removeDuplicates = (object, whatIsConsideredDuplicate) => {
  const result = { ...object };
  whatIsConsideredDuplicate.forEach((item) => {
    if (object[item]) {
      if (Array.isArray(object[item])) result[item] = object[item][object[item].length - 1];
    }
  });
  return result;
};

const joinQueryStrings = (...queries) => {
  const flatArrayOfQueries = queries
    .map(query => query.replace(/\?&*/g, '').trim())
    .filter(query => !!query)
    .map(query => stringify(parseUrl(query)));
  const longQueryUrl = flatArrayOfQueries.length > 0 ? `?${flatArrayOfQueries.join('&')}` : '';
  const parsedUrlData = parseUrl(longQueryUrl);
  const removedDuplicates = removeDuplicates(parsedUrlData, ['page', 'pageSize', 'sort', 'searchString']);
  return stringify(removedDuplicates).length > 0 ? `?${stringify(removedDuplicates)}` : '';
};

export default joinQueryStrings;
