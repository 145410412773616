const initialState = {
  // It is used to keep a global loader state
  // The global loader is used to wait for app level operations
  isLoading: false,
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_APPLICATION_LOADER':
      return {
        ...state,
        isLoading: true,
      };
    case 'HIDE_APPLICATION_LOADER':
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default layoutReducer;
