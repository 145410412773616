import md5 from 'md5';

const GravatarService = {
  getEmailHash(email) {
    return md5(email.trim().toLowerCase());
  },

  doesGravatarHashExist(hash) {
    return fetch(`https://gravatar.com/avatar/${hash}?d=404`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(true);
        }
        return Promise.resolve(false);
      })
      .catch(() => Promise.resolve(false));
  },

  getGravatarUrlForHash(hash) {
    return `https://gravatar.com/avatar/${hash}`;
  },
};

export default GravatarService;
