import React from 'react';
import classnames from 'classnames';
import AceEditor from 'react-ace';
import styles from './json-editor.scss';
import 'brace/mode/json';
import 'brace/theme/tomorrow';

const JsonEditor = ({
  className, height, width, jsonEditorValue, onJsonEditorChange, onJsonEditorValidate,
}) => (
  <AceEditor
    className={classnames(className, styles.editor)}
    mode="json"
    theme="tomorrow"
    height={height}
    width={width}
    fontSize={12}
    fontFamily={'Circular Pro", "Arial'}
    value={jsonEditorValue}
    onChange={onJsonEditorChange}
    onValidate={onJsonEditorValidate}
  />
);

export default JsonEditor;
