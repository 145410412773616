import React from 'react';
import { Grid } from '@material-ui/core';
import SelectWithValidation from 'components/SelectWithValidation';
import { EMAIL_SENDER_MODAL_FORM_CONNECTOR, EMAIL_SENDER_MODAL_FORM_TEMPLATE } from './constants';
import styles from './styles.scss';

export const FORM_FIELD_EMAIL_TEMPLATE = 'emailTemplate';
export const FORM_FIELD_EMAIL_CONNECTOR = 'emailConnector';

const EmailSenderForm = ({
  setConnectorValue, setTemplateValue, validationError, templateValue, setValidationError, connectorOptions, connectorValue, filteredTemplates,
}) => {
  const connectorChangeHandler = event => {
    setConnectorValue(event.value);
    if (templateValue) {
      setTemplateValue('');
    }
    if (event.value && validationError?.[FORM_FIELD_EMAIL_CONNECTOR]?.length > 0) {
      setValidationError({ ...validationError, [FORM_FIELD_EMAIL_CONNECTOR]: null });
    }
  };
  const templateChangeHandler = event => {
    setTemplateValue(event.value);
    if (event.value && validationError?.[FORM_FIELD_EMAIL_TEMPLATE]?.length > 0) {
      setValidationError({ ...validationError, [FORM_FIELD_EMAIL_TEMPLATE]: null });
    }
  };
  return (
    <div className={styles.inputGroupWrapper}>
      <Grid item xs={12} md={6} className={styles.inputWrapper}>
        <SelectWithValidation
          name={FORM_FIELD_EMAIL_CONNECTOR}
          errors={validationError}
          label={EMAIL_SENDER_MODAL_FORM_CONNECTOR}
          required
          placeholder="Select connector"
          options={connectorOptions}
          value={connectorValue}
          onChange={(e) => connectorChangeHandler(e)}
        />
      </Grid>
      <Grid item xs={12} md={6} className={styles.inputWrapper}>
        <SelectWithValidation
          name={FORM_FIELD_EMAIL_TEMPLATE}
          label={EMAIL_SENDER_MODAL_FORM_TEMPLATE}
          placeholder="Select template"
          options={filteredTemplates}
          required
          errors={validationError}
          value={templateValue}
          defaultValue={templateValue}
          onChange={(e) => templateChangeHandler(e)}
        />
      </Grid>
    </div>
  );
};

export default EmailSenderForm;
