import React from 'react';
import cx from 'classnames';
import JsonEditor from 'components/JsonEditor/JsonEditor';
import InputWithValidation from 'components/InputWithValidation';
import styles from '../styles.scss';

const Jwt = (props) => {
  const {
    jsonData,
    onJsonInputChange,
    jsonKey,
    jsonValue,
    jsonUrl,
    jsonHeader,
    jsonMask,
    onJsonFormSubmit,
    onJsonRequestDelete,
    HttpMethodOptions,
    jsonEditorValue,
    onJsonEditorChange,
    onJsonEditorValidate,
    selectedJwtHttpMethod,
    onJwtHttpMethodChange,
  } = props;
  return (
    <div className={styles.formSection}>
      <div className={styles.methodHeader}>
        <p>
          Enter the details of your JWT authentication API.
          SortedREACT will use these details to get a JWT token, which it will send along with its webhook data.
        </p>
      </div>
      <div className={styles.methodsForm}>
        <div className={styles.formRow}>
          <InputWithValidation
            label="Request URL – Enter the URL of your JWT API."
            name="Url"
            value={jsonUrl}
            onChange={onJsonInputChange}
            type="text"
            placeholder="URL"
          />
        </div>
        <div className={styles.formRow}>
          <div className={styles.jsonUpdaterWrapper}>
            <label
              className="mb-2 displayInlineBlock"
              htmlFor="valueJson"
            >
              JSON Request Body – Enter the body of the JWT request.
            </label>
            <JsonEditor
              width="100%"
              height="180px"
              className={styles.editor}
              jsonEditorValue={jsonEditorValue}
              onJsonEditorChange={value => onJsonEditorChange('Value', value)}
              onJsonEditorValidate={onJsonEditorValidate}
            />
          </div>
        </div>
        <div className={styles.formRow}>

          <p className="mb-2">
            HTTP Method – Specify the HTTP method SortedREACT should use when requesting a token
          </p>
          <div className={styles.methodRadioButtons}>
            {HttpMethodOptions.map(item => (
              <div
                onClick={() => onJwtHttpMethodChange(item.value)}
                role="button"
                key={item.id}
                className={cx(styles.radioContainer, `${selectedJwtHttpMethod === item.value ? styles.isActive : ''}`)}
              >
                <label htmlFor={item.value} className={styles.labelBtn}>
                  <input
                    id={item.value}
                    className={styles.formCheckInput}
                    type="radio"
                    value={item.value}
                    checked={selectedJwtHttpMethod === item.value}
                    onChange={() => onJwtHttpMethodChange(item.value)}
                  />
                  <span className={styles.radioBtnText}>{item.label}</span>
                </label>
              </div>
            ))}

          </div>
        </div>
        <div className={styles.formRow}>
          <InputWithValidation
            label="Enter the header that the webhook should send the JWT token in when making requests"
            onChange={onJsonInputChange}
            value={jsonHeader}
            type="text"
            name="Header"
            placeholder="Request"
          />
        </div>
        <div className={styles.formRow}>
          <InputWithValidation
            label="Enter the value mask that REACT should use when sending the token. The token itself should be represented as `$JWT$`"
            name="Mask"
            onChange={onJsonInputChange}
            value={jsonMask}
            type="text"
            placeholder="Request"
          />
        </div>
        <div className={styles.jsonAddHeader}>
          <p>
            <strong>JSON Request Header</strong><br />
            Enter a header key and value to be sent when requesting a token.
          </p>
          <form onSubmit={onJsonFormSubmit} className={styles.requestHeaderForm}>
            <div className={styles.formRow}>
              <InputWithValidation
                label="Key"
                name="Key"
                type="text"
                onChange={onJsonInputChange}
                value={jsonKey}
                placeholder="API name"
              />
            </div>
            <div className={styles.formRow}>
              <InputWithValidation
                label="Value"
                type="text"
                name="Value"
                onChange={onJsonInputChange}
                value={jsonValue}
                placeholder="Enter new API value"
              />
            </div>
            <div className={styles.formRow}>
              <button type="submit" className={styles.requestBtn}>Add</button>
            </div>
          </form>
          <div className={styles.renderedResultsWrapper}>
            <p className="mt-0">Created headers</p>
            <ul className={styles.requestedDataList}>
              {Object.entries(jsonData).map((keyValue, index) => (
                <li className={styles.renderedResults} key={keyValue[0]}>
                  <div className={styles.resulst}>
                    <span className={styles.apiName}>{keyValue[0]}</span>
                    <span className={styles.apiValue}>{keyValue[1]}</span>
                  </div>
                  <button
                    type="button"
                    onClick={event => onJsonRequestDelete(event, index)}
                    className={styles.deleteBtnIcon}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jwt;
