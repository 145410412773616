import React from 'react';
import ChartComponent from 'components/ChartComponent';
import styles from './PlatformsChart.scss';
import colors from '../../colors';
import ErrorMessage from '../components/ErrorMessage/ErrorMessage';
import concatenatedPlatformLabel from './helpers/concatenatedPlatformLabel';

export default function PlatformsChart({ platform, dateKey }) {
  const platformLabels = concatenatedPlatformLabel(platform);
  const chartData = {
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'right',
          padding: '100px',
        },
        tooltip: {
          callbacks: {
            label(item) {
              return item.label;
            },
          },
        },
      },
    },
    type: 'pie',
    chartData: {
      labels: platformLabels,
      datasets: [
        {
          label: '#',
          data: Object.values(platform || {}).filter(x => x !== 0),
          backgroundColor: platformLabels.map((_, i) => colors[i + 1]),
          borderWidth: 0,
        },
      ],
    },
  };

  const hasData = !!(platform.Desktop > 0 || platform.Mobile > 0 || platform.Unknown > 0 || platform.Webmail > 0);

  return (
    <div className={styles.pieChart}>
      <div className={styles.chartContainerTitle}>Platforms</div>
      <div className={styles.chartContainer}>
        {hasData && <ChartComponent chartProps={chartData} />}

        {!hasData && <ErrorMessage dateKey={dateKey} /> }
      </div>
    </div>
  );
}
