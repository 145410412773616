/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './styles.scss';
import Svg from '../../Svg';

const SettingsItemBlock = ({
  svg, title, description, navLink,
}) => (
  <NavLink to={navLink} className={styles.settingsItemLink}>
    <div className={styles.SettingsItemBlockWrapper}>
      <div className={styles.titleBlock}>
        <Svg name={svg} className={styles.iconSVG} />
        <div className={styles.settingItemTitle}>{title}</div>
      </div>
      <div className={styles.settingItemDescription}>{description}</div>
    </div>
  </NavLink>
);

export default SettingsItemBlock;
