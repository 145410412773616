import React, { Component } from 'react';
import styles from './styles.scss';
import AddNewHeaderForm from './RequestHeaderMethods/AddNewHeaderForm';

class RequestHeader extends Component {
    renderHeadersList = (header) => {
      const { name, value } = header;

      return (
        <li className={styles.renderedResults} key={header.key}>
          <div className={styles.resulst}>
            <span className={styles.apiName}>{name}</span>
            <span className={styles.apiValue}>{value}</span>
          </div>
          <button
            type="button"
            onClick={event => this.props.onRequestHeaderDelete(event, header.key)}
            className={styles.deleteBtnIcon}
          />
        </li>
      );
    };

    render() {
      const {
        onHeaderRequest,
        onHeadersInputChange,
        headersKey,
        headersValue,
        headersKeyValid,
        headersValueValid,
        addHeader,
        headersData,
      } = this.props;
      return (
        <div className={styles.innerCardBody}>

          <div className={styles.renderdHeadersArea}>
            <div className={styles.requestedAreaInner}>
              <p>If required, enter any headers you want to add to the webhook’s payload.</p>
              <AddNewHeaderForm
                onHeaderRequest={onHeaderRequest}
                onHeadersInputChange={onHeadersInputChange}
                headersKey={headersKey}
                headersValue={headersValue}
                headersKeyValid={headersKeyValid}
                headersValueValid={headersValueValid}
                addHeader={addHeader}
              />
              <div className={styles.renderedResultsWrapper}>
                <p className="mt-0">Created headers</p>
                <ul className={styles.requestedDataList}>
                  {headersData.map(this.renderHeadersList)}
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    }
}

export default RequestHeader;
