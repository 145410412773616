import React, { useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import useOnClickOutsideHook from 'hooks/useOnClickOutsideHook';
import TriangleExclamationRegular from 'images/TriangleExclamationRegular.svg';
import StateCategoryGreen from 'images/stateCategoryGreen.svg';
import StateCategoryRed from 'images/stateCategoryRed.svg';

import styles from './styles.scss';

export const WARNING_COLOR = '#C7520C';
export const ERROR_COLOR = '#C12547';
export const SUCCESS_COLOR = '#00854D';

const getThemeColor = type => {
  let colorCode;
  switch (type) {
    case 'warning':
      colorCode = WARNING_COLOR;
      break;
    case 'error':
      colorCode = ERROR_COLOR;
      break;
    default:
      colorCode = SUCCESS_COLOR;
      break;
  }
  return colorCode;
};

const Alert = ({ type = 'success', message, alertControl }) => {
  const alertRef = useRef(null);
  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false);
    alertControl({
      on: false,
      type: '',
      message: '',
    });
  };
  useOnClickOutsideHook(alertRef, handleClose);
  return (
    <Grid
      item
      xs={12}
      className={styles.alertMainWrapper}
      style={{ borderLeftColor: getThemeColor(type) }}
    >
      <div ref={alertRef} style={{ display: show ? 'flex' : 'none' }}>
        <div id={type} style={{ width: '16px', height: '16px', fill: getThemeColor(type) }}>
          {type === 'success' && (
            <StateCategoryGreen />
          )}
          {type === 'warning' && (
            <TriangleExclamationRegular />
          )}
          {type === 'error' && (
            <StateCategoryRed />
          )}
        </div>
        <span style={{ color: getThemeColor(type), marginLeft: '10px' }}>{message}</span>
      </div>
    </Grid>
  );
};

export default Alert;
