import React from 'react';
import cx from 'classnames';
import styles from './styles.scss';

const DualButtons = ({
  leftFunction,
  leftText,
  leftFunctionDisabled,
  rightFunction,
  rightText,
  rightTooltipText,
  rightTooltipVisible,
  rightFunctionDisabled,
  isSubmit,
  theme,
}) => (
  <div className={cx(styles.webhookCtaFooterList, theme ? styles[theme] : '')}>
    <button
      type="button"
      disabled={leftFunctionDisabled || false}
      onClick={() => leftFunction()}
      className={cx(styles.webhookCtaBtn, styles.buttonLeft, 'firstDualButton')}
    >
      {leftText}
    </button>
    {/* eslint-disable-next-line react/button-has-type */}
    {
      rightFunction !== null && (
        <button
          {...(rightTooltipVisible ? { title: rightTooltipText } : {})}
          type={isSubmit ? 'submit' : 'button'}
          disabled={rightFunctionDisabled || false}
          onClick={e => rightFunction(e)}
          className={cx(styles.webhookCtaBtn, styles.buttonRight)}
        >
          {rightText}
        </button>
      )
    }
  </div>
);

DualButtons.defaultProps = {
  rightType: 'button',
};

export default DualButtons;
