import React from 'react';

import cx from 'classnames';
import styles from './styles.scss';

const pageTabs = (formPages, page, goToPage) => {
  const list = [];

  for (let i = 0; i < formPages().length; i += 1) {
    if (goToPage) {
      list.push(<button
        className={cx(
          styles.multiformicon,
          { [styles.multiformiconActive]: i === page },
          styles.selectableMultiformicon,
        )}
        key={i}
        onClick={() => goToPage(i)}
        type="button"
      />);
    } else {
      list.push(<span
        className={cx(
          styles.multiformicon,
          { [styles.multiformiconActive]: i === page },
        )}
        key={i}
      />);
    }
  }

  return (
    <div className={styles.multiformiconHolder}>
      {list}
    </div>
  );
};

function MultiPageForm({ formPages, page, goToPage }) {
  const pages = formPages(() => pageTabs(formPages, page, goToPage));

  return (
    <>{pages[page]}</>
  );
}

export default MultiPageForm;
