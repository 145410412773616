import API from 'helpers/API';

import {
  CREATE_SFTP_ACCOUNT,
  CREATE_SFTP_ACCOUNT_SUCCESS,
  CREATE_SFTP_ACCOUNT_ERROR,
} from './types';

const createSftpAccount = (name, password) => (dispatch) => {
  dispatch({ type: CREATE_SFTP_ACCOUNT });
  return API.createSftpAccount({ name, password })
    .then((result) => {
      dispatch({ type: CREATE_SFTP_ACCOUNT_SUCCESS });
      return result.private_key;
    })
    .catch((error) => {
      dispatch({ type: CREATE_SFTP_ACCOUNT_ERROR });
      return Promise.reject(error
        && error.json
        && error.json.details
        && error.json.details.length > 0
        && error.json.details[0]);
    });
};

export default createSftpAccount;
