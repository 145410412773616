function splitCamelCaseString(camelCaseString) {
  return camelCaseString.replace(/([A-Z])/g, ' $1').trim();
}

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function splitSnakeCaseString(snakeCaseString) {
  const str = snakeCaseString.replace(/(_.)/g, c => ` ${c.slice(1).toUpperCase()}`);
  return capitalize(str);
}

export { splitCamelCaseString, splitSnakeCaseString };
