const localStorageAuthToken = 'token';
const localStorageAuthRefreshToken = 'refresh_token';
const localStorageEmailAddress = 'email_address';
const activeProductsKey = 'active_products';
let claims = null;

function getAuthTokenValue() {
  const token = window.localStorage.getItem(localStorageAuthToken);
  return token || '';
}

function getAuthRefreshTokenValue() {
  const token = window.localStorage.getItem(localStorageAuthRefreshToken);
  return token || '';
}

function setAuthTokenValue(accessToken, refreshToken) {
  claims = null;
  window.localStorage.setItem(localStorageAuthToken, accessToken);
  window.localStorage.setItem(localStorageAuthRefreshToken, refreshToken);
}

function removeAuthToken() {
  claims = null;
  window.localStorage.removeItem(localStorageAuthToken);
  window.localStorage.removeItem(localStorageAuthRefreshToken);
  window.localStorage.removeItem('activeTab');
}

function setEmailAddress(emailAddress) {
  window.localStorage.setItem(localStorageEmailAddress, emailAddress);
}

function getActiveProducts() {
  const activeProducts = window.localStorage.getItem(activeProductsKey);
  return activeProducts ? activeProducts.split(',') : ['REACT'];
}

function removeEmailAddress() {
  window.localStorage.removeItem(localStorageEmailAddress);
}

function getEmailAddress() {
  return window.localStorage.getItem(localStorageEmailAddress);
}

function parseJwt(token) {
  if (!token) {
    return '';
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url ? base64Url.replace('-', '+').replace('_', '/') : '';
  const json = JSON.parse(window.atob(base64));
  window.clarity('set', 'customer_id', json.customer_id);
  return json;
}

export function getClaimsCached() {
  if (!claims) {
    claims = parseJwt(getAuthTokenValue());
  }
  return claims;
}

function getUserId() {
  return getClaimsCached().sub;
}

function getCustomerId() {
  return getClaimsCached().customer_id;
}

function getUsername() {
  return getClaimsCached().username;
}

function hasPermission(resourceName) {
  return getClaimsCached().permissions.includes(resourceName);
}

export {
  getAuthTokenValue,
  setAuthTokenValue,
  removeAuthToken,
  getAuthRefreshTokenValue,
  setEmailAddress,
  removeEmailAddress,
  getEmailAddress,
  getUserId,
  getCustomerId,
  getUsername,
  hasPermission,
  getActiveProducts,
};
