import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './styles.scss';

const NavHorizontal = ({ data }) => (
  <div className={styles.navContainer}>
    <ul className={styles.navList}>
      {data.map((nav) => (
        <li role="tab" key={nav.name} title={nav.name} className={styles.navItem}>
          <NavLink exact={nav.exact} to={nav.pathName} activeClassName={styles.linkActive} className={styles.link}>
            {nav.name}
          </NavLink>
        </li>
      ))}
    </ul>
  </div>
);

export default NavHorizontal;
