const initialState = {
  onRegister: null,
};

export default function registerReducer(state = initialState, action) {
  if (action.type === 'NON_PRIMARY_USER_ACTIVATION_TOKEN_RETURNED') {
    return {
      ...state,
      nonPrimaryUserEmailConfirmationToken: action.token,
      customerName: action.customerName,
      firstName: action.firstName,
      lastName: action.lastName,
      email: action.email,
    };
  }
  return state;
}
