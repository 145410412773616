import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../../../helpers/API';
import Spinner from '../../../../../components/Spinner';
import TabErrorMessage from '../TabErrorMessage';
import EventTimeline from '../EventTimeline';
import { ShowMoreActivities } from '../ActivityStatistics/buttons';
import styles from './styles.scss';
import EmailStatisticsContext from '../../EmailStatisticsContext';

const pageSize = 10;

const EmailActivityEvents = () => {
  const { id, emailAddress } = useParams();
  const [isLoadingActivityEvents, setIsLoadingActivityEvents] = React.useState(true);
  const [isActivityEventsError, setIsActivityEventsError] = React.useState(false);
  const [activityEvents, setActivityEvents] = React.useState([]);
  const [offset, setOffset] = React.useState(0);
  const [isDisabled, setShowMoreIsDisabled] = React.useState(false);
  const [isHidden, setShowMoreIsHidden] = React.useState(false);
  const [processedCount, setProcessCount] = React.useState(0);
  const [spamCount, setSpamCount] = React.useState(0);
  const [bouncesCount, setBouncesCount] = React.useState(0);
  const [city, setCity] = React.useState(null);
  const { setPageTitle, setPageMessage, setSelectedServerId } = useContext(EmailStatisticsContext);

  setTimeout(() => {
    setSelectedServerId(id);
    setPageTitle('Activity Events');
    setPageMessage('This page contains email activity details.');
  });

  const processResponse = (response, sinkId) => response.events.map(x => ({
    timeStamp: x.receivedAt,
    status: x.type,
    details: x.details.DeliveryMessage ?? x.details.Summary,
    destinationServer: x.details.DestinationServer,
    subject: x.subject,
    id: x.emailActivityId,
    bounceId: x.details.BounceID ? x.details.BounceID : null,
    sinkConfigurationId: sinkId,
  })).reverse();

  React.useEffect(() => {
    const fetchActivity = async () => {
      setIsLoadingActivityEvents(true);
      try {
        setShowMoreIsDisabled(false);
        setShowMoreIsHidden(false);
        const response = await API.getEmailActivityByRecipient(id, emailAddress, pageSize, 0);
        setActivityEvents(processResponse(response, id));
        setProcessCount(response.processedCount);
        setSpamCount(response.spamCount);
        setBouncesCount(response.bouncedCount);
        setCity(response.geo.city);
        if (response.processedCount < pageSize) {
          setShowMoreIsHidden(true);
        }
      } catch (e) {
        setIsActivityEventsError(true);
      }
      setIsLoadingActivityEvents(false);
    };

    fetchActivity();
  }, [id, emailAddress]);

  const showMore = async () => {
    setShowMoreIsDisabled(true);
    const calcOffset = offset + pageSize;
    const response = await API.getEmailActivityByRecipient(id, emailAddress, pageSize, calcOffset);
    setActivityEvents(activityEvents.concat(processResponse(response)));
    setShowMoreIsDisabled(false);
    if (response.processedCount < (pageSize + calcOffset)) {
      setShowMoreIsHidden(true);
    }
    setOffset(calcOffset);
  };

  return (
    <>
      {isLoadingActivityEvents && <Spinner />}
      {!isLoadingActivityEvents && isActivityEventsError && <TabErrorMessage />}
      {!isLoadingActivityEvents && !isActivityEventsError && (
        <>
          <div className={styles.header}>
            <div className={styles.emailSection}>
              <div className={styles.icon}><span>{emailAddress[0].toUpperCase()}</span></div>
              <div className={styles.emailDetails}>
                <div className={styles.emailAddress}>{emailAddress}</div>
                {city && (
                  <div className={styles.location}>Last seen in {city}</div>
                )}
              </div>
            </div>
            <div className={styles.statsSection}>
              <div><span>{processedCount}</span>processed</div>
              <div><span>{spamCount}</span>spam</div>
              <div><span>{bouncesCount}</span>bounces</div>
            </div>
          </div>
          <EventTimeline events={activityEvents} showtop />
          <ShowMoreActivities onClick={showMore} disabled={isDisabled} hidden={isHidden} />
        </>
      )}
    </>
  );
};

export default EmailActivityEvents;
