import React from 'react';
import Dashboards from '../../images/Dashboards';
import Grid from '../../images/Grid2';
import Billing from '../../images/Billing';
import Support from '../../images/Support';
import Cross from '../../images/Cross';
import Sortedlogo from '../../images/SortedLogo';
import SortedWideLogo from '../../images/SortedWideLogo';
import SortedReactLogo from '../../images/SortedReactLogo';
import UkFlag from '../../images/UkFlag';
import Burger from '../../images/Burger';
import ViewArrow from '../../images/ViewArrow';
import Export from '../../images/Export';
import SearchGlass from '../../images/SearchGlass';
import SearchGlassBold from '../../images/SearchGlassBold';
import Info from '../../images/Info';
import CircleInfoLight from '../../images/CircleInfoLight';
import CircleXmarkSolid from '../../images/CircleXmarkSolid';
import Share from '../../images/Share';
import Expand from '../../images/Expand';
import ApiKey from '../../images/ApiKey';
import WebHook from '../../images/WebHook';
import PlusCircle from '../../images/PlusCircle';
import FilterIcon from '../../images/FilterIcon';
import Carriers from '../../images/Carriers';
import States from '../../images/States';
import Origins from '../../images/Origins';
import Destinations from '../../images/Destinations';
import CrossCircle from '../../images/CrossCircle';
import AngleDown from '../../images/AngleDown';
import Account from '../../images/Account';
import TickWithStroke from '../../images/TickWithStroke';
import LeftArrow from '../../images/LeftArrow';
import BackArrow from '../../images/BackArrow';
import Floppy from '../../images/Floppy';
import RubberRing from '../../images/RubberRing';
import MapPin from '../../images/MapPin';
import ThumbUpCircle from '../../images/thumbUpCircle';
import Shipments from '../../images/Shipments';
import TrackingPage from '../../images/TrackingPage';
import ChartLine from '../../images/ChartLine';
import SettingsNavIcon from '../../images/SettingsNavIcon';
import TableEditUser from '../../images/TableEditUser';
import TableDeleteUser from '../../images/TableDeleteUser';
import styles from './styles.scss';
import Code from '../../images/Code';
import RightLeft from '../../images/RightLeft';
import LargeGroup from '../../images/LargeGroup';
import Tag from '../../images/Tag';
import Bell from '../../images/Bell';
import Filter from '../../images/Filter';
import Users from '../../images/Users';
import CircleQuestion from '../../images/CircleQuestion';
import CircleUser from '../../images/CircleUser';
import LogOutIcon from '../../images/LogOutIcon';
import NewCSVExport from '../../images/NewCSVExport';
import WebhooksNew from '../../images/WebhooksNew';
import CheckmarkDropdown from '../../images/CheckmarkDropdown';

/**
 * @deprecated Instead import svg file and use it as a component.
 * E.g. import BinSvg from 'images/bin.svg'; ... <BinSvg />
 */
const icons = props => ({
  dashboards: <div {...props}><Grid /></div>,
  shipments: <div {...props}><Shipments /></div>,
  trackingPage: <div {...props}><TrackingPage /></div>,
  settings: <div {...props}><SettingsNavIcon /></div>,
  code: <div {...props}><Code /></div>,
  chartLine: <div {...props}><ChartLine /></div>,
  rightLeft: <div {...props}><RightLeft /></div>,
  largeGroup: <div {...props}><LargeGroup /></div>,
  tag: <div {...props}><Tag /></div>,
  bell: <div {...props}><Bell /></div>,
  filterSettings: <div {...props}><Filter /></div>,
  users: <div {...props}><Users /></div>,
  circleQuestion: <div {...props}><CircleQuestion /></div>,
  circleUser: <div {...props}><CircleUser /></div>,
  logOutIcon: <div {...props}><LogOutIcon /></div>,
  webhooksNew: <div {...props}><WebhooksNew /></div>,
  checkmarkDropdown: <div {...props}><CheckmarkDropdown /></div>,
  newCSVExport: <div {...props}><NewCSVExport /></div>,
  TableEditUser: <div {...props}><TableEditUser /></div>,
  TableDeleteUser: <div {...props}><TableDeleteUser /></div>,
  Floppy: <div {...props}><Floppy /></div>,
  // settings: <div {...props}><Settings /></div>,
  reporting: <div {...props}><Dashboards /></div>,
  rubberRing: <div {...props}><RubberRing /></div>,
  billing: <div {...props}><Billing /></div>,
  support: <div {...props}><Support /></div>,
  sortedlogo: <div {...props}><Sortedlogo /></div>,
  sortedReactLogo: <div {...props}><SortedReactLogo /></div>,
  cross: <div {...props}><Cross /></div>,
  language: <div {...props}><UkFlag /></div>,
  burger: <div {...props}><Burger /></div>,
  viewArrow: <div {...props}><ViewArrow /></div>,
  export: <div {...props}><Export /></div>,
  searchGlass: <div {...props}><SearchGlass /></div>,
  searchGlassBold: <div {...props}><SearchGlassBold /></div>,
  info: <Info {...props} />,
  circleInfoLight: <CircleInfoLight {...props} />,
  circleXmarkSolid: <CircleXmarkSolid {...props} />,
  share: <Share {...props} />,
  expand: <Expand {...props} />,
  apiKey: <div {...props}><ApiKey /></div>,
  plusCircle: <div {...props}><PlusCircle /></div>,
  WebHook: <div {...props}><WebHook /></div>,
  filter: <FilterIcon {...props} />,
  Carriers: <div {...props}><Carriers /></div>,
  States: <div {...props}><States /></div>,
  Origins: <div {...props}><Origins /></div>,
  Destinations: <div {...props}><Destinations /></div>,
  crossCircle: <div {...props}><CrossCircle /></div>,
  angleDown: <div {...props}><AngleDown /></div>,
  account: <div {...props}><Account {...props} /></div>,
  sortedWideLogo: <div {...props}><SortedWideLogo /></div>,
  tickWithStroke: <div {...props}><TickWithStroke /></div>,
  leftArrow: <div {...props}><LeftArrow /></div>,
  backArrow: <div {...props}><BackArrow /></div>,
  mapPin: <div {...props}><MapPin /></div>,
  thumbUpCircle: <div {...props}><ThumbUpCircle /></div>,
});

export default ({ name, ...props }) => {
  if (icons(props)[name] === undefined) {
    return <div style={{ opacity: 0 }} className={styles.svgLogo}>{name}</div>;
  }
  return <div style={{ opacity: 0 }} className={styles.svgLogo}>{icons(props)[name]}</div>;
};
