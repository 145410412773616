import React from 'react';

/* eslint-disable */
export default props => (
  <svg xmlns='http://www.w3.org/2000/svg' id='Layer_1' viewBox='0 0 124.5 54.5'>
    <g transform='matrix( 0.9113922119140625, 0, 0, 0.903778076171875, 1.55,-14.4)'>
      <g>
        <g id='Layer0_0_FILL'>
          <path fill='#333333' d='M78.2,55l3,0.1l2.5-4.7l8.6-0.1l2.7,4.7L99,55l1.9-3.6h6.6l2.2,3.7h4.2l4.4-5.6l3.2,5.6h5.2l6.1-7.9     c0.5-0.7,0.5-1.6,0-2.5l-4.7-6.4c-0.7-1-1.9-1.4-3.5-1.4H66.5l-0.1-2.6c0.2-1.3-0.6-2-2.4-2l-10.7-1.2c-1.5-2.4-3.4-4.6-5.5-6.5     c-0.4-0.3-0.8-0.6-1.1-0.9c-0.7-0.6-1.4-1.1-2.2-1.7c-5.7-3.8-12.1-5.3-19.1-4.7C17.5,18.1,11,21.6,6,27.9     c-0.8,1.1-1.6,2.2-2.4,3.4c-0.8,1.2-1.5,2.5-2,3.9c-0.1,0.2-0.2,0.4-0.3,0.6h17.3l8.4-4.5l10.8,0.1c0.1-0.3,0.3-0.6,0.4-0.8     c0.7-1,1.7-1.4,3-1.4c1.1-0.2,2.1,0.1,3,0.8c0.9,0.8,1.4,1.8,1.5,2.9c0.2,1.2-0.1,2.2-0.9,3.1c-0.8,0.9-1.8,1.4-2.9,1.5     c-1.2,0.1-2.3-0.3-3.2-1c-0.4-0.4-0.7-0.9-0.9-1.4l-10.1,0.1l-8.3,4.4H0.2c-0.4,1.5-0.6,3-0.7,4.6h45.7c0.1-0.3,0.3-0.6,0.6-0.8     c0.7-0.9,1.7-1.4,2.9-1.6c1-0.1,2,0.2,3,1c0.9,0.8,1.4,1.8,1.5,2.9c0.1,1.2-0.3,2.2-1,3.1c-0.8,0.8-1.7,1.4-2.8,1.5     c-1.2,0.2-2.2-0.1-3-0.8c-0.5-0.4-0.9-0.8-1.2-1.4l-45.8-0.1c0.1,0.3,0.1,0.6,0.2,1.1c0.1,1.3,0.3,2.5,0.6,3.6h15.4l8.6,6.5h8.1     c0.1-0.3,0.3-0.6,0.6-0.8c0.7-1,1.7-1.5,2.8-1.6c1.1-0.1,2.1,0.2,3,0.9c1,0.7,1.5,1.7,1.7,2.9c0.1,1.2-0.3,2.2-1,3     c-0.8,0.9-1.7,1.4-2.8,1.6c-1.2,0.1-2.2-0.3-3.1-0.9c-0.5-0.3-0.8-0.8-1-1.4h-9.7l-8.6-6.4H1.3c0.2,0.8,0.6,1.6,1.1,2.5     c0.6,1.4,1.4,2.7,2.2,3.9c1.5,2,3.3,4,5.4,5.8c6.2,5.1,13.3,7.3,21.4,6.4c2-0.2,4-0.5,6-1.1c1.5-0.5,2.9-1,4.1-1.8     c3.5-1.7,6.6-4.2,9.3-7.5c1.1-1.3,2-2.6,2.7-3.9l11-1.2c1.2-0.1,1.8-0.8,1.8-1.9v-2.5l4-0.1l4.1-4.6L78.2,55 M71.9,41.2l32.3-0.1     c0.5,0,0.9,0.2,1.4,0.5c0.3,0.4,0.4,0.9,0.4,1.4c0,0.6-0.1,1-0.4,1.4c-0.5,0.3-0.9,0.5-1.4,0.5H72c-0.7,0-1.2-0.1-1.6-0.4     c-0.3-0.5-0.5-1-0.5-1.5c0-0.6,0.2-1,0.5-1.4C70.8,41.3,71.3,41.1,71.9,41.2 M123.1,41.2c0.6,0,1,0.1,1.4,0.4     c0.4,0.4,0.6,0.9,0.6,1.4c0,0.6-0.2,1-0.6,1.4c-0.4,0.3-0.9,0.4-1.4,0.4L110.5,45c-0.6,0-1.1-0.2-1.5-0.5     c-0.3-0.4-0.4-0.9-0.4-1.4c0-0.6,0.1-1,0.4-1.4c0.5-0.3,0.9-0.4,1.4-0.4H123.1z'/>
        </g>
      </g>
    </g>
  </svg>
)

/* eslint-enable */
