const initialState = [
  {
    code: 'en-GB',
    name: 'English (Great Britain)',
  },
  {
    code: 'en-US',
    name: 'English (United States)',
  },
  {
    code: 'de-DE',
    name:
     'German (Germany)',
  },
  {
    code: 'fr-FR',
    name:
     'French (France)',
  },
  {
    code: 'es-ES',
    name:
     'Spanish (Spain)',
  },
  {
    code: 'it-IT',
    name:
     'Italian (Italy)',
  },
  {
    code: 'ru',
    name:
     'Russian',
  },
  {
    code: 'ja',
    name:
     'Japanese',
  },
];

export default function localesReducer(state = initialState) {
  return state;
}
