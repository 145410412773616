import React from 'react';
import classnames from 'classnames';
import { event as gaEvent } from 'react-ga';
import Svg from 'components/Svg';
import OutsideClickAlerter from 'components/OutsideClickAlerter';
import styles from './TablePaginator.scss';

const RowCountSelector = ({ onSelect }) => (
  <div className={styles.rowCountMenu}>
    <ul>
      <li><div role="menuitem" onClick={() => onSelect(20)}>20</div></li>
      <li><div role="menuitem" onClick={() => onSelect(50)}>50</div></li>
      <li><div role="menuitem" onClick={() => onSelect(100)}>100</div></li>
      <li><div role="menuitem" onClick={() => onSelect(200)}>200</div></li>
    </ul>
  </div>
);

export default class TablePaginator extends React.Component {
  constructor(props) {
    super(props);
    const rows = this.getCurrentRows(props);

    this.state = {
      page: props.page,
      selectingPageSize: false,
      rowInfo: rows,
    };
  }

  // componentWillRecieveProps is deprecated and we need to fix this
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const rows = this.getCurrentRows(nextProps);
    this.setState({
      page: nextProps.page,
      rowInfo: rows,
    });
  }

  setIsSelectingPageSize = value => this.setState({ selectingPageSize: value })

  getCurrentRows = props => (
    (
      typeof props.sortedData !== 'undefined'
      && typeof props.page !== 'undefined'
      && typeof props.pageSize !== 'undefined'
    ) ? {
        rowCount: props.totalRows,
        rowMin: props.page * props.pageSize + 1,
        rowMax: Math.min((props.page + 1) * props.pageSize, props.totalRows),
      } : {}
  );

  getSafePage = (page) => {
    let safePage = '';
    if (Number.isNaN(Number(page))) {
      safePage = this.props.page;
    }

    return Math.min(Math.max(safePage, 0), this.props.pages - 1);
  };

  getPageNumbersToShow = () => {
    const pageNumbersResult = [];
    const currentPage = this.props.page + 1;
    const totalPages = this.props.pages;
    const window = 3;

    let startPage;
    let endPage;

    startPage = (currentPage < window + 1) ? 1 : currentPage - window;

    endPage = (window * 2) + startPage;
    endPage = (totalPages < endPage) ? totalPages : endPage;

    const diff = startPage - endPage + (window * 2);
    startPage -= (startPage - diff > 0) ? diff : 0;

    for (let i = startPage; i <= endPage; i += 1) {
      pageNumbersResult.push(i);
    }

    return pageNumbersResult;
  };

  setPageSize = (rowCount) => {
    const { trackingComponentName } = this.props;
    gaEvent({
      category: `${trackingComponentName} Pagination`,
      action: 'Changed items per page',
    });
    this.props.onPageSizeChange(rowCount);
    this.setIsSelectingPageSize(false);
  };

  showNextPage = () => this.props.canNext && this.changePage(+this.state.page + 1);

  showPreviousPage = () => this.props.canPrevious && this.changePage(+this.state.page - 1);

  changePage = (newPage) => {
    const { page } = this.state;
    const { trackingComponentName } = this.props;
    if (newPage === page) {
      gaEvent({
        category: `${trackingComponentName} Pagination`,
        action: 'Changed page to same page',
      });
    } else if (newPage > page) {
      gaEvent({
        category: `${trackingComponentName} Pagination`,
        action: 'Show next page',
      });
    } else {
      gaEvent({
        category: `${trackingComponentName} Pagination`,
        action: 'Show previous page',
      });
    }
    this.setState({ page: this.getSafePage(newPage) });
    if (this.props.page !== newPage) {
      this.props.onPageChange(newPage);
    }
  };

  render() {
    const { page, pageSize, footerText } = this.props;

    const { selectingPageSize, rowInfo: { rowMin, rowMax, rowCount } } = this.state;

    return (
      rowCount > 0 && (
        <div className={styles.container}>
          <div className={styles.pageNumbersContainer}>

            <span role="button" onClick={this.showPreviousPage}>
              <Svg className={classnames(styles.pageArrow, styles.flipped)} name="angleDown" />
            </span>

            <div className={styles.pageNumbers}>
              {this.getPageNumbersToShow().map(pageNumber => (
                <span
                  key={pageNumber}
                  role="button"
                  onClick={() => this.changePage(pageNumber - 1)}
                  className={classnames(styles.pageNumber, page + 1 === pageNumber ? styles.activePage : '')}
                >
                  {pageNumber}
                </span>
              ))}
            </div>

            <span role="button" onClick={this.showNextPage}>
              <Svg className={classnames(styles.pageArrow)} name="angleDown" />
            </span>
          </div>

          <div className={styles.itemCountSelector}>
            <span
              role="button"
              onClick={() => this.setIsSelectingPageSize(!selectingPageSize)}
              className={styles.itemCount}
            >
              {pageSize}
              <Svg
                className={classnames(styles.itemCountArrow, selectingPageSize ? styles.vFlipped : '')}
                name="angleDown"
              />
            </span>
            {selectingPageSize && (
            <OutsideClickAlerter onClickOutside={() => this.setIsSelectingPageSize(false)}>
              <RowCountSelector onSelect={this.setPageSize} />
            </OutsideClickAlerter>
            )}
            <span> items per page </span>
          </div>

          <div className={styles.rangeIndicator}>
            <span>{rowMin} - {rowMax} of {rowCount} {footerText} </span>
          </div>
        </div>
      )
    );
  }
}
