import React from 'react';
import { connect } from 'react-redux';
import Svg from 'components/Svg';
import InputWithValidation from 'components/InputWithValidation';
import {
  required,
  isEmail,
  maxLength,
} from 'helpers/validate';
import { validateForm } from 'helpers/validateForm';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import {
  getUsers as getUsersAction,
} from 'actions';
import DualButtons from 'components/DualButtons';
import { event as gaEvent } from 'react-ga';
import SelectWithValidation from 'components/SelectWithValidation';
import Spinner from 'components/Spinner';
import UsersContext from 'routes/Users/UsersContext';
import styles from './add-new-member-form.scss';

class MemberForm extends React.Component {
  static contextType = UsersContext;

  validationRules = {
    email: [maxLength(100), isEmail()],
    firstName: [required(), maxLength(100)],
    lastName: [required(), maxLength(100)],
    roleId: [required()],
  };

  constructor(props) {
    super(props);
    this.state = {
      user: null,
      isSaving: false,
      errors: {},
    };
  }

  componentDidMount() {
    const { invitationItems, userItems } = this.props.users;
    if (!invitationItems && !userItems) {
      this.props.getUsers();
    }
    this.loadUser();
  }

  loadUser = async () => {
    const user = await this.props.load();
    this.setState({ user });
  };

  handleOnChange = ({ target }) => {
    this.handlePropChange(target);
  };

  handlePropChange = ({ name, value }) => {
    this.setState(s => ({
      user: {
        ...s.user,
        [name]: value,
      },
    }));
    gaEvent({
      category: 'AccountAndUserManagement',
      action: `Altered field ${name}`,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { user } = this.state;
    const { checkEmailUniquines } = this.props;
    const { invitationItems, userItems } = this.props.users;
    const errors = validateForm(this.validationRules, user);
    if (errors.hasErrors) {
      gaEvent({
        category: 'AccountAndUserManagement',
        action: 'Errors trying to create user',
      });
      this.setState({ errors });
      return;
    }

    if (checkEmailUniquines && (invitationItems || userItems)) {
      const exists = (
        invitationItems && invitationItems.find(x => x.email.toLowerCase() === user.email.toLowerCase()))
        || (userItems && userItems.find(x => x.email.toLowerCase() === user.email.toLowerCase()));

      if (exists) {
        gaEvent({
          category: 'AccountAndUserManagement',
          action: 'Create, email already exists',
        });
        this.setState(prevState => ({ errors: { email: [`'${prevState.user.email}' email is already in use.`] } }));
        return;
      }
    }

    this.setState({ isSaving: true });
    this.props.save(user)
      .then(() => {
        gaEvent({
          category: 'AccountAndUserManagement',
          action: 'New user created',
        });
        this.props.history.push('/settings/users/');
      })
      .catch((e) => {
        this.setState({ errors: e, isSaving: false });
        return Promise.resolve();
      });
  };

  render() {
    if (!this.state.user) {
      return <Spinner />;
    }
    const {
      user: {
        firstName,
        lastName,
        email,
        roleId,
        isPrimary = false,
      },
      isSaving,
      errors,
    } = this.state;
    const {
      isEmailReadonly,
      saveLabel,
    } = this.props;
    const { roles } = this.context;
    return (
      <div>
        {isSaving && <Spinner />}
        <div className={styles.addContainer}>
          <Svg name="account" className={styles.userAvatar} />
          <p className={cx('subtitle1 textCenter pb-3', styles.mainTitle)}>
            Name
          </p>
          <p className="textCenter subtitle1">SortedREACT</p>
          <p className={cx('mb-2', styles.formDetails)}>Details:</p>
          <form onSubmit={this.handleSubmit}>
            <InputWithValidation
              label="First name"
              placeholder="First name"
              type="text"
              value={firstName}
              name="firstName"
              onChange={this.handleOnChange}
              errors={errors}
            />

            <InputWithValidation
              label="Last name"
              placeholder="Last name"
              type="text"
              value={lastName}
              name="lastName"
              onChange={this.handleOnChange}
              errors={errors}
            />

            <InputWithValidation
              label="User email"
              placeholder="Email address"
              type="text"
              value={email}
              name="email"
              disabled={isEmailReadonly}
              onChange={this.handleOnChange}
              errors={errors}
            />
            <SelectWithValidation
              label="User type"
              options={roles.map(x => ({ value: x.id, label: x.name }))}
              value={roleId}
              name="roleId"
              disabled={isPrimary}
              defaultValue={roles[0].id}
              onChange={this.handlePropChange}
              errors={errors}
            />
            <input type="submit" className="hidden-submit" />
          </form>
        </div>
        <DualButtons
          leftFunction={() => {
            gaEvent({
              category: 'AccountAndUserManagement',
              action: 'Cancel adding user',
            });
            this.props.history.push('/settings/users/');
          }}
          leftText="Cancel"
          rightFunctionDisabled={isSaving}
          rightText={saveLabel}
          rightFunction={this.handleSubmit}
          theme="rightPink"
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  users: state.users,
});

const mapDispatchToProps = dispatch => ({
  getUsers: () => dispatch(getUsersAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberForm));
