import { SET_SIDEBAR_MENU_VALUE } from '../actions/types';

const initialState = {
  sidebarExpanded: null,
};

const sidebarMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SIDEBAR_MENU_VALUE:
      return {
        ...state,
        sidebarExpanded: action.payload,
      };
    default:
      return state;
  }
};

export default sidebarMenuReducer;
