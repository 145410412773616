import React, { Component } from 'react';
import CloseIcon from 'images/CloseIcon.svg';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './styles.scss';

class Chip extends Component {
  static propTypes = {
    onDelete: PropTypes.func,
    label: PropTypes.string,
    icon: PropTypes.element,
    theme: PropTypes.oneOf(['light', 'dark']),
  };

  static defaultProps = {
    onDelete: null,
    theme: 'light',
    label: '',
    icon: null,
  };

  handleDelete = () => {
    this.props.onDelete(this);
  };

  render() {
    const {
      onDelete, theme, icon, label,
    } = this.props;
    return (
      <div className={cx(styles.container, styles[theme])}>
        {icon}
        <span className={styles.label}>{label}</span>
        {onDelete && <CloseIcon role="button" onClick={this.handleDelete} className={styles.deleteIcon} /> }
      </div>
    );
  }
}

export default Chip;
