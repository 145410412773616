import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { format, parse } from 'date-fns';
import cx from 'classnames';
import styles from './styles.scss';

const dateFormat = 'DD/MM/YYYY HH:mm';
const TOOLTIP_SHIPMENT_REGISTERED = `The date that is created by Track
at the point of shipment registration.`;
const TOOLTIP_ORDER_DATE = `The date on which the order was placed -
this date is static and provided by you "The customer".`;
const TOOLTIP_SHIPPED_DATE = `The date on which the shipment was (or will be) shipped -
this date is static and provided by you "The customer".`;
const TOOLTIP_PROMISED_DATE = `The date range on which the shipment should have been
delivered to the customer.
This date is static and provided by you "The customer"
and never gets updated. If a shipment has not progressed
into a final state by this date / timestamp,
then Track will mark this shipment as ‘late’. `;
const TOOLTIP_EXPECTED_DATE = `The date range that the shipment is expected to be delivered by the carrier.
This date gets updated where possible by the carrier or when the shipment goes into an "out for delivery" status.
This date is displayed on the “Tracking page”.`;
const TOOLTIP_DELIVERY_DATE = `The date range that the shipment was delivered,
captured from the carrier.`;
const TOOLTIP_COLLECTION_DATE = `The date on which the shipment was collected,
captured from the carrier.`;
const TOOLTIP_OUT_FOR_DELIVERY = `The date & time range that the shipment is due to be delivered.
This is captured from the carrier and only shows
when the shipment is "out for delivery" and has
been provided by the carrier.`;

const getDeliverySlotInfo = (deliverySlotInterval) => {
  const parsedStartDate = parse(deliverySlotInterval.start);
  const parsedEndDate = parse(deliverySlotInterval.end);
  return (
    <p data-for="dates-tooltip" data-tip={TOOLTIP_OUT_FOR_DELIVERY}>
      Out for delivery: {format(deliverySlotInterval.start, dateFormat)} between {format(parsedStartDate, 'HH:mm')} - {format(parsedEndDate, 'HH:mm')}
    </p>
  );
};

const ShipmentSummary = ({ shipmentData }) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [shipmentData]);

  return (
    <div className={cx(styles.shipmentSummaryGrid, 'break-all')}>
      <div className={styles.shipmentSummary}>
        <div>
          <p><b>Consumer:</b></p>
          {shipmentData.consumer && (
            <>
              <p>{shipmentData.consumer.title} {shipmentData.consumer.first_name} {shipmentData.consumer.last_name}</p>
              <p>{shipmentData.consumer.email}</p>
              <p>phone: {shipmentData.consumer.phone}</p>
              <p>mobile phone: {shipmentData.consumer.mobile_phone}</p>
            </>
          )}
        </div>
        <div className={styles.references}>
          <p><b>References:</b></p>
          {shipmentData.custom_references && shipmentData.custom_references.length ? shipmentData.custom_references.map(
            ref => <p key={ref}>{ref}</p>,
          ) : null}
        </div>
        <div className={styles.dates}>
          <p><b>Dates:</b></p>
          <p data-for="dates-tooltip" data-tip={TOOLTIP_SHIPMENT_REGISTERED}>
            Shipment registered date: {shipmentData.created_on && format(shipmentData.created_on, dateFormat)}
          </p>
          <p data-for="dates-tooltip" data-tip={TOOLTIP_ORDER_DATE}>
            Order date: {shipmentData.order_date && format(shipmentData.order_date, dateFormat)}
          </p>
          <p data-for="dates-tooltip" data-tip={TOOLTIP_SHIPPED_DATE}>
            Shipped date: {shipmentData.shipped_date && format(shipmentData.shipped_date, dateFormat)}
          </p>
          <p data-for="dates-tooltip" data-tip={TOOLTIP_PROMISED_DATE}>
            Promised date: {shipmentData.promised_date
            && shipmentData.promised_date.end
            && format(shipmentData.promised_date.end, dateFormat)}
          </p>
          <p data-for="dates-tooltip" data-tip={TOOLTIP_EXPECTED_DATE}>
            Expected date: {shipmentData.expected_delivery_date
            && shipmentData.expected_delivery_date.end && format(shipmentData.expected_delivery_date.end, dateFormat)}
            {shipmentData.shipment_state.state === 'out_for_delivery'
              && shipmentData.delivery_slot_interval
              && getDeliverySlotInfo(shipmentData.delivery_slot_interval)}
          </p>
          <p data-for="dates-tooltip" data-tip={TOOLTIP_DELIVERY_DATE}>
            Delivery date: {shipmentData.delivered_date && format(shipmentData.delivered_date, dateFormat)}
            {shipmentData.collection_date && shipmentData.collection_date.end
              && (
                <p data-for="dates-tooltip" data-tip={TOOLTIP_COLLECTION_DATE}>
                  Collection date: {format(shipmentData.collection_date.end, dateFormat)}
                </p>
              )}
          </p>
          <ReactTooltip
            id="dates-tooltip"
            isCapture
            className={styles.shipmentTooltip}
          />
        </div>
      </div>
    </div>
  );
};

export default ShipmentSummary;
