import React from 'react';

/* eslint-disable */
export default props => (
  <svg {...props} id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 68.1'>
    <path d='M19.4,0C15.3,0,12,3.3,12,7.4c0,4.1,3.3,7.4,7.4,7.4c4.1,0,7.4-3.3,7.4-7.4C26.9,3.3,23.5,0,19.4,0z M16.6,46.9L16.6,46.9l2.2-7.5c0,0,0,0,0-0.1l2.8-9.5c0.5-1.8,0.8-3.3,0.8-4.5c0-1.9-0.7-3.5-2-4.7c-1.3-1.2-3-1.9-5.1-1.9 c-5.4,0-10.5,4.1-15.1,12.2c0,0-0.6,1.2,0.4,2c0.1,0.1,0.1,0.1,0.2,0.1c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0 c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0,0,0 c0.2-0.1,0.3-0.2,0.5-0.4c0,0,0,0,0,0c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0 c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c2-3.2,3.4-4.3,4.7-5.4c0.4-0.3,0.8-0.5,1.1-0.5c0.1,0,0.2,0.1,0.3,0.2 c0.1,0.2,0.2,0.3,0.2,0.4c0,0.4-0.1,1.3-0.7,3.5l-2.8,9.7h0l-2.2,7.5c0,0,0,0,0,0.1l-2.8,9.5C0.3,58.9,0,60.4,0,61.6 c0,1.9,0.7,3.5,2,4.7c1.3,1.2,3,1.9,5.1,1.9c5.4,0,10.5-4.1,15.1-12.2c0,0,0.6-1.2-0.4-2c-0.1-0.1-0.1-0.1-0.2-0.1 c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0 c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.3,0.1c0,0,0,0,0,0c-0.2,0.1-0.3,0.2-0.5,0.4c0,0,0,0,0,0c-0.1,0.1-0.1,0.1-0.2,0.2c0,0,0,0,0,0 c-0.1,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c-2,3.2-3.4,4.3-4.7,5.4 c-0.4,0.3-0.8,0.5-1.1,0.5c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.2-0.2-0.3-0.2-0.4c0-0.4,0.1-1.3,0.7-3.4L16.6,46.9z'
    />
  </svg>
)

/* eslint-enable */
