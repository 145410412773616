import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Svg from 'components/Svg';
import { format, parse } from 'date-fns';
import classnames from 'classnames';
import Card from 'components/Card';
import CreateCard from 'components/CreateCard';
import { closePopup as closePopupAction, showPopup as showPopupAction } from 'actions';
import {
  getApiKeys as getApiKeysAction,
  revokeApiKey as revokeApiKeyAction,
  updateApiKey as updateApiKeyAction,
} from 'actions/apikeyActions';
import styles from './ApiKeysList.scss';
import TitleNavBlock from '../../../components/TitleNavBlock/TitleNavBlock';
import typography from '../../../helpers/appTypography';

export const CApiKeysList = ({ keys, ...props }) => {
  const revokeKey = (id) => {
    props.showPopup({
      title: 'Are you sure?',
      description: <span>Revoking of api key <u>deletes</u> it completely.</span>,
      onAccept: () => props.revokeApiKey(id).then((cb) => {
        props.closePopup();
        cb();
      }),
    });
  };

  return (
    <div>
      <TitleNavBlock
        title={typography.APIKeys.title}
        subTitle={typography.APIKeys.subTitle}
        onBack={() => window.history.back()}
      />
      <div className={styles.outerWhiteContainer}>
        <div className="container__cards-large">
          <div
            role="button"
            onClick={() => props.history.push('/settings/api-keys/create')}
            className={classnames(styles.btnCreateNew, `${props.isActive ? styles.active : ''}`)}
          >
            <CreateCard text="Create new API key" />
          </div>

          {keys.length > 0 && keys.map(key => (
            <Card
              key={key.id}
              title={key.name}
              value={(
                <div className={styles.iconSvg}>
                  <Svg name="apiKey" />
                </div>
              )}
              description={(
                <div>
                  <div className={styles.apiKeyInfo}>Created By: {key.created_by}</div>
                  <div className={styles.apiKeyInfo}>Created At: {format(parse(key.created_on), 'DD/MM/YYYY HH:mm')}</div>
                </div>
              )}
              leftActionText="Revoke"
              leftAction={() => revokeKey(key.id)}
              rightActionText="Rename"
              rightAction={() => props.history.push(`/settings/api-keys/${key.id}`)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  keys: state.apiKeys.keys,
  isActive: state.apiKeys.isActive,
});

const mapDispatchToProps = dispatch => ({
  getApiKeys: () => dispatch(getApiKeysAction()),
  showPopup: config => dispatch(showPopupAction(config)),
  revokeApiKey: id => dispatch(revokeApiKeyAction(id)),
  updateApiKey: (id, name) => dispatch(updateApiKeyAction({ id, name })),
  closePopup: () => dispatch(closePopupAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CApiKeysList));
