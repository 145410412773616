import React, { useContext } from 'react';
import cx from 'classnames';
import * as propTypes from 'prop-types';
import ContextPopupContext from 'components/ContextPopup/ContextPopupContext';
import styles from './styles.scss';

function ContextMenuItem({
  clickHandler, disabled, children,
}) {
  const { togglePopup } = useContext(ContextPopupContext);
  return (
    <button
      type="button"
      disabled={disabled}
      className={cx(styles.button)}
      onClick={() => {
        clickHandler();
        togglePopup();
      }}
    >
      {children}
    </button>
  );
}

ContextMenuItem.defaultProps = {
  clickHandler: () => {},
  disabled: false,
};

ContextMenuItem.propTypes = {
  clickHandler: propTypes.func,
  disabled: propTypes.bool,
};

export default ContextMenuItem;
