/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Spinner from 'components/Spinner';
import { withRouter } from 'react-router';
import API from 'helpers/API';
import { useHistory } from 'react-router-dom';
import styles from './ConfiguredDashboard.scss';
import UpdatedAtWithRefresh from '../../../components/UpdatedAtWithRefresh/UpdatedAtWithRefresh';
import DashboardTable from '../../../components/DashboardTable';
import LandingSummaryBox from '../../../components/LandingSummaryBox/LandingSummaryBox';
import DashboardSummaryTable from '../../../components/DashboardSummaryTable/DashboardSummaryTable';
import BackButton from '../../../components/BackButton/index';
import typography from '../../../helpers/appTypography';

export const getDashboardName = () => {
  const queryParams = new URLSearchParams(window.location.search);
  return {
    urlDashboardName: queryParams.get('dashboardName'),
    tabValue: Number(queryParams.get('tabNumber')),
  };
};

function ConfiguredDashboard() {
  const queryParamValues = getDashboardName();
  const [loading, setLoading] = useState(true);
  const [fetchedDashboards, setFetchedDashboards] = useState([]);
  const [summaryAlerts, setSummaryAlerts] = useState({});
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  const history = useHistory();

  const getDashboardsAndSummaryAlerts = async (currentDashboardName) => {
    setLoading(true);
    try {
      setFetchedDashboards(await API.getConfiguredDashboards());
      setSummaryAlerts(await API.getDashboardSummaryData('?tags=summary[true]'));
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDashboardsAndSummaryAlerts(queryParamValues?.urlDashboardName);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (triggerRefresh) {
      getDashboardsAndSummaryAlerts(queryParamValues?.urlDashboardName);
      setTriggerRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParamValues?.urlDashboardName, triggerRefresh]);

  let refresh;
  let summaryContent;
  let dashboardTables;
  if (loading) {
    dashboardTables = <Spinner />;
  } else {
    refresh = (
      <UpdatedAtWithRefresh
        setTriggerRefresh={setTriggerRefresh}
      />
    );
    summaryContent = (summaryAlerts.totalVolume > 0 ? (
      <>
        <div className={styles.summaryBoxes}>
          <LandingSummaryBox
            title="Total volume of monitored shipments"
            shipmentCount={summaryAlerts.totalVolume}
          />
          <LandingSummaryBox
            title="Monitored shipments requiring attention"
            shipmentCount={summaryAlerts.redTotalVolume}
            style={{ border: '5px solid #c12547', borderWidth: '5px 5px 5px 15px' }}
          />
          <LandingSummaryBox
            title="Monitored shipments potentially impacted"
            shipmentCount={summaryAlerts.amberTotalVolume}
            style={{ border: '5px solid #c7520c', borderWidth: '5px 5px 5px 15px' }}
          />
          <LandingSummaryBox
            title="Monitored shipments on track"
            shipmentCount={summaryAlerts.greenTotalVolume}
            style={{ border: '5px solid #00854d', borderWidth: '5px 5px 5px 15px' }}
          />
        </div>
        <div>
          <DashboardSummaryTable
            summaryAlerts={summaryAlerts}
          />
        </div>
      </>
    ) : <p>There are no configured alerts to summarise</p>
    );

    dashboardTables = (fetchedDashboards.dashboards.length > 0 ? (
      <div className={styles.gridContainer}>
        <div className={styles.item}>
          <DashboardTable
            dashboardName="summary_page_dashboard_1"
          />
        </div>
        <div className={styles.item}>
          <DashboardTable
            dashboardName="summary_page_dashboard_2"
          />
        </div>
      </div>
    ) : <p>There are no configured dashboards</p>
    );
  }

  return (
    <div className={styles.mainContaiter}>
      <BackButton onBack={() => {
        history.push(
          {
            pathname: '/dashboards/states',
          },
        );
      }}
      />
      <div className={styles.title}>
        <h1 className="h2" style={{ margin: '0 0 0 5px' }}>{typography.proactiveAlerting.title}</h1>
        {refresh}
      </div>
      <div className={styles.subTitle}>{typography.proactiveAlerting.subTitle}</div>
      <div className={styles.innerContainer}>
        {summaryContent}
        {dashboardTables}
      </div>
    </div>
  );
}

export default withRouter((ConfiguredDashboard));
