import React from 'react';
import styles from './pieCharts.scss';
import 'chart.js/auto';
import EmailClientsChart from './EmailClientsChart';
import PlatformsChart from './PlatformsChart';

const PieCharts = ({ platform, clients, dateKey }) => (
  <div className={styles.pieChartCotainer}>
    <h2>Open tracking</h2>
    <div className={styles.pieCharts}>
      <PlatformsChart platform={platform} dateKey={dateKey} />
      <EmailClientsChart clients={clients} dateKey={dateKey} />
    </div>
  </div>
);

export default PieCharts;
