import React from 'react';

/* eslint-disable */
export default props => (
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
<g>
	<path fill={props.isSidebarAccount? "" : '#E8E8E8'} 
	d="M50,6.6C26,6.6,6.6,26,6.6,50c0,11.9,4.8,22.7,12.6,30.5c3.9-2.7,9-4.6,12.4-5.8c4.9-1.8,6.5-2.8,7.8-4.5c1.3-1.7,0.7-8.9,0.7-8.9s-2.7-3.7-3.6-6.1c-0.9-2.3-1.1-6.8-1.1-6.8s-2.3-1.5-2.9-3.4c-0.6-1.9-1.1-5-0.7-6.4c0.4-1.4,2.2-1,2.2-1s0-2.5-0.1-4.5c-0.1-2.6,0.6-15.7,15.9-15.7s16,13.1,15.9,15.7c-0.1,2-0.1,4.5-0.1,4.5s1.8-0.4,2.2,1c0.4,1.4-0.1,4.5-0.7,6.4c-0.6,1.9-2.9,3.4-2.9,3.4s-0.2,4.5-1.1,6.8c-0.9,2.3-3.6,6.1-3.6,6.1s-0.5,7.2,0.7,8.9c1.3,1.7,2.9,2.7,7.8,4.5c3.4,1.2,8.6,3.1,12.4,5.8c7.8-7.8,12.6-18.6,12.6-30.5C93.4,26,74,6.6,50,6.6z"/>
	<path fill="#333333" d="M50,0.4C22.6,0.4,0.4,22.6,0.4,50c0,27.3,22.1,49.5,49.4,49.6c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c27.4,0,49.6-22.2,49.6-49.6C99.6,22.7,77.3,0.4,50,0.4z M68.3,74.7c-4.9-1.8-6.5-2.8-7.8-4.5c-1.3-1.7-0.7-8.9-0.7-8.9s2.7-3.7,3.6-6.1c0.9-2.3,1.1-6.8,1.1-6.8s2.3-1.5,2.9-3.4c0.6-1.9,1.1-5,0.7-6.4c-0.4-1.4-2.2-1-2.2-1s0-2.5,0.1-4.5C66,30.6,65.3,17.5,50,17.5S33.9,30.6,34,33.2c0.1,2,0.1,4.5,0.1,4.5s-1.8-0.4-2.2,1C31.5,40,32,43.2,32.6,45c0.6,1.9,2.9,3.4,2.9,3.4s0.2,4.5,1.1,6.8c0.9,2.3,3.6,6.1,3.6,6.1s0.5,7.2-0.7,8.9c-1.3,1.7-2.9,2.7-7.8,4.5c-3.4,1.2-8.6,3.1-12.4,5.8C11.4,72.7,6.6,61.9,6.6,50C6.6,26,26,6.6,50,6.6C74,6.6,93.4,26,93.4,50c0,11.9-4.8,22.7-12.6,30.5C76.9,77.8,71.8,75.9,68.3,74.7z"/>
</g>
</svg>
)

/* eslint-enable */
