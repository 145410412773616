import React from 'react';
import { Switch } from 'react-router';
import AuthRoute from 'routes/Auth/AuthRoute';
import resources from 'constants/resources';
import AuthorisedEmailsList from 'routes/Settings/RegisterShipmentWebhooks/AuthorisedEmails/AuthorisedEmailsList';
import AddEmail from 'routes/Settings/RegisterShipmentWebhooks/AuthorisedEmails/AddEmail/AddEmail';
import EditEmail from 'routes/Settings/RegisterShipmentWebhooks/AuthorisedEmails/EditEmail/EditEmail';
import RegisterShipmentWebhooksCentre from './RegisterShipmentWebhooksCentre';
import { InvalidShipmentWebhooks } from './InvalidShipmentWebhooks/InvalidShipmentWebhooks';

function RegisterShipmentWebhooks() {
  return (
    <Switch>
      <AuthRoute
        exact
        path="/settings/register-shipment-webhooks"
        component={RegisterShipmentWebhooksCentre}
        resource={resources.emailShipmentRegistration.read}
      />
      <AuthRoute
        exact
        path="/settings/register-shipment-webhooks/invalid-shipment-webhooks"
        component={InvalidShipmentWebhooks}
        resource={resources.emailShipmentRegistration.read}
      />
      <AuthRoute
        exact
        path="/settings/register-shipment-webhooks/authorised-emails"
        component={AuthorisedEmailsList}
        resource={resources.emailShipmentRegistration.read}
      />
      <AuthRoute
        exact
        path="/settings/register-shipment-webhooks/authorised-emails/add"
        component={AddEmail}
        resource={resources.emailShipmentRegistration.edit}
      />
      <AuthRoute
        exact
        path="/settings/register-shipment-webhooks/authorised-emails/:emailId/edit"
        component={EditEmail}
        resource={resources.emailShipmentRegistration.edit}
      />
    </Switch>
  );
}

export default RegisterShipmentWebhooks;
