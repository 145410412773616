import React from 'react';
import { Route } from 'react-router';
import { hasPermission } from 'helpers/auth';
import AccessRestrictedPage from 'routes/Auth/AccessRestrictedPage';

function AuthRoute({ component: C, resource, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => (hasPermission(resource)
        ? <C {...props} />
        : <AccessRestrictedPage />)}
    />
  );
}

AuthRoute.propTypes = Route.propTypes;

export default AuthRoute;
