import React from 'react';

/* eslint-disable */
export default props => (
  <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 39.5 68.1'>
    <path d='M39.5,41.2l-6.3-27.2c0,0,0.1-0.1,0.1-0.1l0,0c0.5-0.6,1.3-1.1,2.2-1.1c1.6-0.1,2.9,1.2,3,2.8L39.5,41.2z M28.3,4.4c-0.5-2-2.6-3.3-4.6-2.8c-1.1,0.3-2,1-2.5,2l0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.5,0.8-0.7,1.9-0.9,3.1l14.3,22.9L28.3,4.4z M31.8,30.4 M31.8,30.4L13.4,2c-1.3-2-3.8-2.6-5.7-1.4C6.7,1.3,6.1,2.4,6,3.5h0c0,0,0,0,0,0c0,0,0,0,0,0c-0.8,5.5,5.8,15.5,5.8,15.5	l5,7.7c0.9-0.1,1.8-0.2,2.7-0.2c2.7,0,5.3,0.5,7.7,1.4l-0.5,2.6c-2.2-0.9-4.6-1.3-7.1-1.3c-10.3,0-18.7,8-19.4,18.1h9.1	c0.6-5.2,5-9.2,10.3-9.2c5.8,0,10.4,4.7,10.4,10.4S25.2,59,19.4,59c-5.3,0-9.6-3.9-10.3-8.9H0c0.7,10.1,9.1,18.1,19.4,18.1	c10.8,0,19.5-8.7,19.5-19.5c0-3-0.7-5.9-1.9-8.5C34.2,34.2,31.8,30.4,31.8,30.4z'
    />
  </svg>
)

/* eslint-enable */
