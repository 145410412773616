import React from 'react';
import getCountryNameByIsoCode from 'helpers/countries';
import vocabulary from 'constants/vocabulary';
import styles from './style.scss';

const getHoursLate = (hours) => {
  if (!hours) {
    return 'Not Available';
  }

  const remainderHours = hours % 1;
  const absoluteHours = hours - remainderHours;

  let friendlyString = '';

  if (absoluteHours > 0) {
    friendlyString = `${absoluteHours} hours `;
  }

  if (remainderHours > 0) {
    const minutes = (60 * remainderHours);
    friendlyString += `${Math.floor(minutes)} minutes`;
  }

  return `${friendlyString.trimRight()} late`;
};

const ShipmentDetails = ({ shipmentData }) => {
  const destinationAddress = shipmentData.addresses.find(element => element.address_type === 'destination');
  const originAddress = shipmentData.addresses.find(element => element.address_type === 'origin');

  return (
    <div className={styles.detailsBlockGrid}>
      <div className={styles.detailsBlock}>
        <div className={styles.detailsGroup}>
          <div className={styles.detailsItem}>
            <div>
              <p><b>From:</b></p>
            </div>
            {originAddress
              && (
                <div>
                  {(originAddress.property_name != null && originAddress.property_number != null) && (
                    <p>{originAddress.property_name}, {originAddress.property_number}</p>
                  )}
                  <p>{originAddress.address_line1}</p>
                  <p>{originAddress.address_line2}</p>
                  <p>{originAddress.address_line3}</p>
                  <p>{originAddress.postal_code}</p>
                  <p>{originAddress.locality}</p>
                  <p>{originAddress.region}</p>
                  <p>{getCountryNameByIsoCode(originAddress.country_iso_code)}</p>
                </div>
              )}
          </div>
          {destinationAddress && (
            <div className={styles.detailsItem}>
              <div>
                <p><b>To:</b></p>
              </div>
              <div>
                {(destinationAddress.property_name != null && destinationAddress.property_number != null) && (
                  <p>
                    {
                      destinationAddress.property_name != null
                        ? destinationAddress.property_name : ''
                    }, {
                      destinationAddress.property_number != null
                        ? destinationAddress.property_number : ''
                    }
                  </p>
                )}
                <p>{destinationAddress.address_line1}</p>
                <p>{destinationAddress.address_line2}</p>
                <p>{destinationAddress.address_line3}</p>
                <p>{destinationAddress.postal_code}</p>
                <p>{destinationAddress.locality}</p>
                <p>{destinationAddress.region}</p>
                <p>{getCountryNameByIsoCode(destinationAddress.country_iso_code)}</p>
              </div>
            </div>
          )}
        </div>
        {(shipmentData.lateness && shipmentData.lateness.is_late) && (
          <div className={styles.detailsGroup}>
            <div className={styles.detailsItem}>
              <div>
                <p><b>Lateness:</b></p>
              </div>
              <div>
                <p>
                  {shipmentData.lateness && shipmentData.lateness.is_late
                    ? getHoursLate(shipmentData.lateness.hours_late)
                    : 'No'}
                </p>
              </div>
            </div>
          </div>
        )}
        <div className={styles.detailsGroup}>
          <div className={styles.detailsItem}>
            <div>
              <p><b>Shipment Type:</b></p>
            </div>
            <div>
              <p>{vocabulary[shipmentData.shipment_type]}</p>
            </div>
          </div>
        </div>
        <div className={styles.detailsGroup}>
          <div className={styles.detailsItem}>
            <div>
              <p><b>Retailer:</b></p>
            </div>
            <div>
              <p>{shipmentData.retailer}</p>
            </div>
          </div>
        </div>
        {shipmentData.may_be_missing && (
        <div className={styles.detailsGroup}>
          <div className={styles.detailsItem}>
            <div>
              <p><b>May Be Missing:</b></p>
            </div>
            <div>
              <p>
                Yes
              </p>
            </div>
          </div>
        </div>
        )}
        {shipmentData.metadata.length > 0 && (
          <div className={styles.detailsGroup}>
            <div className={styles.detailsItem}>
              <div>
                <p><b>Metadata:</b></p>
              </div>
              <div>
                {shipmentData.metadata.map(
                  meta => <p key={meta.key}>{meta.key}: {meta.value}</p>,
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShipmentDetails;
