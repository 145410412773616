import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import FormErrors from 'components/FormErrors';
import {
  required,
  isEmail,
  maxLength,
  minLength,
  compareValues,
  isChecked,
} from 'helpers/validate';
import { validateForm } from 'helpers/validateForm';
import {
  register as registerAction,
  activateNonPrimaryUser as activateNonPrimaryUserAction,
} from 'actions';
import { event as gaEvent } from 'react-ga';
import CircularProgress from '@material-ui/core/CircularProgress';
import LoginFormButton from 'components/RegistrationButton/LoginFormButton';
import styles from './styles.scss';
import AuthenticationInput from '../AuthenticationInput/AuthenticationInput';

export class Register extends React.Component {
  validationRules = {
    companyName: [minLength(3, () => 'You must enter your company name'), maxLength(100, () => 'Company name must be a maximum of 100 characters')],
    firstName: [required(() => 'You must enter your first name'), maxLength(100, () => 'First name must be a maximum of 100 characters')],
    lastName: [required(() => 'You must enter your last name'), maxLength(100, () => 'Last name must be a maximum of 100 characters')],
    email: [maxLength(100), isEmail()],
    password: [minLength(6, () => 'Password must be between 6 and 100 characters'), maxLength(100, () => 'Password must be between 6 and 100 characters')],
    confirmPassword: [compareValues('Password', 'Confirm password', () => 'Password and confirm password must match', () => this.state.password)],
    isPolicyAgreementChecked: [isChecked()],
  };

  static propTypes = {
    onRegister: propTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      companyName: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      isLoading: false,
      errors: {},
      isPolicyAgreementChecked: false,
    };
  }

  componentDidMount() {
    const {
      customerName,
      firstName,
      lastName,
      email,
      nonPrimaryUserEmailConfirmationToken,
    } = this.props;

    if (nonPrimaryUserEmailConfirmationToken) {
      this.setState({
        companyName: customerName,
        firstName,
        lastName,
        email,
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      companyName,
      firstName,
      lastName,
      email,
      password,
      isPolicyAgreementChecked,
    } = this.state;
    const { nonPrimaryUserEmailConfirmationToken, onActivateNonPrimaryUser, onRegister } = this.props;
    const errors = validateForm(this.validationRules, this.state);

    if (errors.hasErrors) {
      this.setState({ errors });
      return;
    }

    this.setState({
      isLoading: true,
      errors: {},
    });
    if (nonPrimaryUserEmailConfirmationToken) {
      this.handleResponsePromise(onActivateNonPrimaryUser(nonPrimaryUserEmailConfirmationToken, firstName, lastName, password, email));
    } else {
      this.handleResponsePromise(onRegister(companyName, firstName, lastName, email, password, isPolicyAgreementChecked));
    }
  };

  handleOnChange = ({ target: { name, value } }) => {
    gaEvent({
      category: 'Register',
      action: `Field ${name} changed`,
    });
    this.setState({ [name]: value });
  };

  handleIsChecked = (event) => {
    const value = event.currentTarget.checked;
    gaEvent({
      category: 'Register',
      action: `policy checked ${value}`,
    });
    this.setState({
      isPolicyAgreementChecked: value,
    });
  };

  handleResponsePromise(responsePromise) {
    responsePromise
      .then(() => {
        gaEvent({
          category: 'Register',
          action: 'Register successful',
        });
        this.setState({
          errors: {},
          isLoading: false,
        });
      })
      .catch((e) => {
        gaEvent({
          category: 'Register',
          action: 'Register error',
        });
        this.setState({
          errors: e,
          isLoading: false,
        });
      });
  }

  getErrorMessageForField(fieldName, text) {
    const { errors } = this.state;
    if (errors && errors[fieldName] && errors[fieldName].length > 0) {
      return errors[fieldName].map(e => e.replace('{0}', text)).join();
    }
    return '';
  }

  getErrorStyleForField(fieldName) {
    const { errors } = this.state;
    if (errors && errors[fieldName] && errors[fieldName].length > 0) {
      return styles.inputErrorContainer;
    }
    return '';
  }

  render() {
    const {
      nonPrimaryUserEmailConfirmationToken,
    } = this.props;
    const {
      companyName,
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      isLoading,
      errors,
      isPolicyAgreementChecked,
    } = this.state;
    const isNonPrimaryRegistration = !!nonPrimaryUserEmailConfirmationToken;

    return (
      <div className={styles.loginForm}>
        <div className={styles.registrationPageInner}>
          <div className={styles.welcome}>
            <h1 className={`${styles.welcomeHeader}`}>
              Create an account
            </h1>
          </div>
          <p className={styles.subtitle}>Welcome to Sorted. To create an account please fill out the information below:</p>
          <div className={styles.formErrorContainer}>
            <FormErrors errors={errors} />
          </div>

          <form onSubmit={this.handleSubmit}>

            <div className={`${styles.inputContainer} ${this.getErrorStyleForField('companyName')}`}>
              <AuthenticationInput
                label="Company name"
                type="text"
                value={companyName}
                name="companyName"
                onChange={this.handleOnChange}
                disabled={isNonPrimaryRegistration}
                error={this.getErrorMessageForField('companyName', 'Company name')}
              />
            </div>
            <div className={`${styles.inputContainer} ${this.getErrorStyleForField('email')}`}>
              <AuthenticationInput
                label="Email address"
                type="email"
                value={email}
                name="email"
                onChange={this.handleOnChange}
                disabled={isNonPrimaryRegistration}
                error={this.getErrorMessageForField('email', 'Email')}
              />
            </div>
            <div className={`${styles.inputContainer} ${this.getErrorStyleForField('firstName')}`}>
              <AuthenticationInput
                label="First name"
                type="text"
                value={firstName}
                name="firstName"
                onChange={this.handleOnChange}
                error={this.getErrorMessageForField('firstName', 'First name')}
              />
            </div>
            <div className={`${styles.inputContainer} ${this.getErrorStyleForField('lastName')}`}>
              <AuthenticationInput
                label="Last name"
                type="text"
                value={lastName}
                name="lastName"
                onChange={this.handleOnChange}
                error={this.getErrorMessageForField('lastName', 'Last name')}
              />
            </div>

            <div className={`${styles.inputContainer} ${this.getErrorStyleForField('password')}`}>
              <AuthenticationInput
                label="Password"
                type="password"
                value={password}
                name="password"
                onChange={this.handleOnChange}
                error={this.getErrorMessageForField('password', 'Password')}
              />
            </div>
            <div className={`${styles.inputContainer} ${this.getErrorStyleForField('confirmPassword')}`}>
              <AuthenticationInput
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                name="confirmPassword"
                onChange={this.handleOnChange}
                error={this.getErrorMessageForField('confirmPassword', 'Confirm Password')}
              />
            </div>

            <div className={styles.checkboxWrapper}>
              {errors && errors.isPolicyAgreementChecked
              && <p className={styles.checkboxError}>You must agree to our Privacy Policy to create an account</p>}
              <input
                tabIndex={0}
                id="formPolicyConfirmation"
                name="formPolicyConfirmation"
                type="checkbox"
                checked={isPolicyAgreementChecked}
                value={isPolicyAgreementChecked}
                onChange={this.handleIsChecked}
                className={styles.formCheckbox}
              />
              <label htmlFor="formPolicyConfirmation" style={{ pointerEvents: 'none' }}>
                I agree to the &nbsp;
                <span
                  role="button"
                  onClick={() => window.open('https://sorted.com/track-privacy-policy/', '_blank')}
                  className={styles.actionText}
                >
                  Privacy Policy
                </span>
              </label>
            </div>
            <LoginFormButton type="submit" onClick={this.handleSubmit} disabled={isLoading} className={styles.loginBtn}>
              {isLoading && <CircularProgress thickness={8} color="inherit" size={40} variant="indeterminate" className={styles.loadingSpinner} />}
              {!isLoading && !this.state.successEmailSent && 'Create account'}
            </LoginFormButton>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ register: registerStore }) => ({
  onRegister: registerStore.onRegister,
  nonPrimaryUserEmailConfirmationToken: registerStore.nonPrimaryUserEmailConfirmationToken,
  customerName: registerStore.customerName,
  firstName: registerStore.firstName,
  lastName: registerStore.lastName,
  email: registerStore.email,
});

const mapDispatchToProps = dispatch => ({
  onRegister: (companyName, firstName, lastName, email, password) => (
    dispatch(registerAction(companyName, firstName, lastName, email, password))),
  onActivateNonPrimaryUser: (nonPrimaryUserEmailConfirmationToken, firstName, lastName, password, email) => (
    dispatch(activateNonPrimaryUserAction(
      nonPrimaryUserEmailConfirmationToken,
      firstName,
      lastName,
      password,
      email,
    ))
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
