import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import MobileMenu from './MobileMenu';
import MobileSearch from './MobileSearch';
import Svg from '../Svg';
import styles from './styles.scss';
import SortedLogoHorizontalColourWhite from '../../images/SortedLogoHorizontal-ColourWhite.svg';

export default class SidebarMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navIsOpen: false,
      searchIsOpen: false,
    };
  }

  openNav = () => this.setState({ navIsOpen: true });

  openSearch = () => this.setState({ searchIsOpen: true });

  closeNav = () => this.setState({ navIsOpen: false });

  closeSearch = () => this.setState({ searchIsOpen: false });

  // eslint-disable-next-line react/no-unused-class-component-methods
  renderSearchButton = () => {
    if (this.state.searchIsOpen) {
      return (
        <Svg name="cross" className={styles.searchIcon} onClick={this.closeSearch} role="button" />
      );
    }
    return (
      <Svg name="searchGlassBold" className={styles.searchIcon} onClick={this.openSearch} role="button" />
    );
  };

  render() {
    const { navIsOpen, searchIsOpen } = this.state;
    return (
      <div className={styles.container}>
        <MobileSearch isOpen={searchIsOpen} closeCallback={this.closeSearch} />
        {!searchIsOpen && (
          <div className={styles.mobileHeaderLogoHolder}>
            <NavLink to="/dashboards/states">
              <SortedLogoHorizontalColourWhite className={styles.mobileHeaderLogo} alt="SortedLogoHorizontalColourWhite" />
            </NavLink>
          </div>
        )}
        <this.renderSearchButton />
        <Svg name="burger" className={styles.burgerIcon} onClick={this.openNav} role="button" />
        <MobileMenu isOpen={navIsOpen} closeCallback={this.closeNav} />
      </div>
    );
  }
}
