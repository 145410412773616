import React from 'react';

/* eslint-disable */
export default props => (
  <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30.4 30.5'>
    <defs>
      <linearGradient id='grad1' x1='0%' y1='0%' x2='0%' y2='100%'>
        <stop offset='0%' stopColor='#ae1214' />
        <stop offset='100%' stopColor='#f5115a' />
      </linearGradient>
    </defs>
    <g fill='url(#grad1)'>
      <path d='M25.8,4.7c-2.8-2.8-6.6-4.4-10.6-4.4c-4,0-7.8,1.6-10.6,4.4c-2.8,2.8-4.4,6.6-4.4,10.6S1.8,23,4.6,25.8 c2.8,2.8,6.6,4.4,10.6,4.4c0,0,0,0,0,0c4,0,7.7-1.6,10.6-4.4c2.8-2.8,4.4-6.6,4.4-10.6C30.1,11.3,28.6,7.5,25.8,4.7z M24.6,24.7 c-2.5,2.5-5.8,3.9-9.4,3.9c0,0,0,0,0,0c-3.5,0-6.9-1.4-9.4-3.9c-2.5-2.5-3.9-5.8-3.9-9.4s1.4-6.9,3.9-9.4C8.3,3.4,11.6,2,15.2,2 c3.5,0,6.9,1.4,9.4,3.9c2.5,2.5,3.9,5.8,3.9,9.4C28.5,18.8,27.1,22.1,24.6,24.7z'
      />
      <path d='M21.3,9.2c-0.3-0.3-0.9-0.3-1.2,0l-4.9,4.9l-4.9-4.9c-0.3-0.3-0.9-0.3-1.2,0c-0.3,0.3-0.3,0.9,0,1.2l4.9,4.9 l-4.9,4.9c-0.3,0.3-0.3,0.9,0,1.2c0.2,0.2,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l4.9-4.9l4.9,4.9c0.2,0.2,0.4,0.2,0.6,0.2 s0.4-0.1,0.6-0.2c0.3-0.3,0.3-0.9,0-1.2l-4.9-4.9l4.9-4.9C21.6,10.1,21.6,9.5,21.3,9.2z'
      />
    </g>
  </svg>
)

/* eslint-enable */
