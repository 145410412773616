import React, { Component } from 'react';
import SelectSearch from 'react-select';
import Button from 'components/ReactButton';
import Svg from 'components/Svg';
import BackButton from 'components/BackButton';
import countries from 'constants/countries';
import classNames from 'classnames';
import styles from './styles.scss';
import { selectStyles } from './InnerCard';

const setValueActionName = 'set-value';

export default class InnerCardCountry extends Component {
  titleStyles = classNames('h2', styles.titleAdditionalStyles)

  constructor(props) {
    super(props);
    this.state = {
      // from array ['AS', 'KZ']
      // to array [{ name: 'Country Name', value: 'CN' }, ...]
      picked: this.props.defaultValues.map(defaultValue => (
        countries.find(country => country.value === defaultValue)
      )),
      inputValue: '',
    };
  }

  handleCancel = (e) => {
    if (e) e.preventDefault();
    this.props.onCancel();
  };

  handleSubmit = (e) => {
    if (e) e.preventDefault();
    this.props.onSubmit(this.props.name, this.state.picked.map(value => value.value));
  };

  handleChange = (value) => {
    this.setState({ picked: value });
  };

  handleInputChange = (query, { action }) => {
    // Prevents resetting our input after option has been selected
    // https://github.com/JedWatson/react-select/issues/3210
    if (action !== setValueActionName) {
      this.setState({ inputValue: query });
    }
  };

  handleRemove = option => this.setState(state => ({ picked: state.picked.filter(el => el.value !== option.value) }));

  renderSelectedOptions = selected => (
    <div className={styles.selectedOptionsContainer}>
      {selected.length > 0
        ? selected.map(option => (
          <div
            key={option.value}
            role="button"
            onClick={() => this.handleRemove(option)}
            className={styles.selectedOption}
          >
            {option.label}
            <Svg className={styles.crossIcon} name="crossCircle" />
          </div>
        ))
        : (
          <div
            className={styles.selectedOption}
          >
            All {`${this.props.name.charAt(0).toUpperCase()}${this.props.name.slice(1)}`}
          </div>
        )}
    </div>
  );

  render() {
    const {
      name,
      subText,
      title,
    } = this.props;

    // eslint-disable-next-line no-nested-ternary
    const orderedCountries = name === 'destinations'
      ? ['GB', 'US', 'IE', 'FR', 'AU', 'IT', 'NL', 'SE', 'DE', 'ES', 'IM', 'CA', 'NZ', 'JE', 'BE', 'GG', 'NO', 'IL', 'DK', 'FI']
      : name === 'origins'
        ? ['GB', 'US', 'IE', 'IT', 'DE', 'ES', 'UA', 'FR', 'CA', 'AU', 'SE', 'FI', 'NO', 'PL', 'HU', 'SI', 'BE', 'LV', 'EE', 'NL', 'DK', 'CZ']
        : [];

    const orderedCountriesData = orderedCountries.map(countryCode => countries.find(country => country.value === countryCode));
    const otherCountriesData = countries.filter(country => !orderedCountries.includes(country.value));
    const sortedCountries = [...orderedCountriesData, ...otherCountriesData];
    return (
      <div className={classNames(styles.container, { [styles.expanded]: localStorage.getItem('sidebar.expanded') === 'true' })}>
        <div className={styles.titleContainer}>
          <div className={styles.backButtonContainer}>
            <BackButton onBack={this.handleSubmit} />
          </div>
          <h1 className={this.titleStyles}>{title}</h1>
          <div className={styles.subTitleButtonContainer}>
            {subText && (
              <p>{subText}</p>
            )}
          </div>
        </div>
        <div className={styles.selectSearchContainer}>
          <SelectSearch
            inputValue={this.state.inputValue}
            onInputChange={this.handleInputChange}
            options={sortedCountries}
            defaultValue={this.props.defaultValues.map(defaultValue => (
              sortedCountries.find(country => country.value === defaultValue)
            ))}
            value={this.state.picked}
            onChange={this.handleChange}
            name={name}
            placeholder="Search"
            isMulti
            autofocus
            isSearchable
            menuIsOpen
            isClearable={false}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            backspaceRemovesValue={false}
            styles={selectStyles}
            maxMenuHeight={350}
            classNamePrefix="react-select"
            className="react-select-container"
          />
          {this.renderSelectedOptions(this.state.picked)}
        </div>
        <div className={`${styles.buttonsRow}`}>
          <Button className={styles.button} onClick={this.handleSubmit}>Apply</Button>
          <Button className={styles.button} theme="grey" onClick={this.handleCancel}>Cancel</Button>
        </div>
      </div>
    );
  }
}
