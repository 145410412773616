import { reactLocalStorage } from 'reactjs-localstorage';

const initialState = {
  showMap: reactLocalStorage.getObject('showMap') === true,
  showCalcEventsMap: reactLocalStorage.getObject('showCalcEventsMap') === true,
};

export default function toggleMapReducer(state = initialState, action) {
  switch (action.type) {
    case 'TOGGLE_MAP': {
      const newShowMapState = !reactLocalStorage.getObject('showMap') === true;
      reactLocalStorage.setObject('showMap', newShowMapState);
      return {
        ...state,
        showMap: newShowMapState,
      };
    }
    case 'TOGGLE_CALC_EVENTS_MAP': {
      const newShowCalcEventsState = !reactLocalStorage.getObject('showCalcEventsMap') === true;
      reactLocalStorage.setObject('showCalcEventsMap', newShowCalcEventsState);
      return {
        ...state,
        showCalcEventsMap: newShowCalcEventsState,
      };
    }
    default:
      return state;
  }
}
