import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'components/Spinner';
import { withRouter } from 'react-router-dom';
import { event as gaEvent } from 'react-ga';
import { getUsers as getUsersAction } from 'actions';
import { getClaimsCached, hasPermission } from 'helpers/auth';
import resources from 'constants/resources';
import cx from 'classnames';
import BackButton from '../../components/BackButton';
import typography from '../../helpers/appTypography';
import styles from './users-list.scss';
import AddBtnRedesigned from '../../components/AddFiltersBtnRedesigned/AddBtnRedesigned';
import CustomUsersTable from '../../components/DashboardSummaryTable/CustomUsersTable/CustomUsersTable';

const CUsersList = ({ history }) => {
  const users = useSelector(state => state.users);
  const dispatch = useDispatch();
  const canAddUsers = hasPermission(resources.accountUsers.editUsers);

  const [activeTab, setActiveTab] = useState(0);
  const [user, setUser] = useState();

  const handleClickAddNewUser = () => {
    gaEvent({
      category: 'AccountAndUserManagement',
      action: 'Add a new user',
    });
    history.push('/settings/users/invite-a-user');
  };

  useEffect(() => {
    dispatch(getUsersAction());
  }, [dispatch]);

  useEffect(() => {
    const savedActiveTab = localStorage.getItem('activeTab');
    if (savedActiveTab !== null) {
      setActiveTab(parseInt(savedActiveTab, 10));
    }
  }, []);

  useEffect(() => {
    const userRole = getClaimsCached();
    setUser(userRole.role_id);
  }, [user]);

  if (!users || users.loading) return <Spinner />;

  const { userItems, invitationItems } = users;
  const items = userItems.map((x) => ({
    ...x,
    is_invitation: false,
  }));

  invitationItems.forEach((x) => {
    items.push({
      ...x,
      is_invitation: true,
    });
  });

  const handleTabClick = (index) => {
    setActiveTab(index);
    localStorage.setItem('activeTab', index);
  };

  const adminUsers = items.filter(i => i.role_id === 1 && i.is_invitation !== true);
  const dashboardUsers = items.filter(i => i.role_id === 4 && i.is_invitation !== true);
  const dashboardBasicUsers = items.filter(i => i.role_id === 5 && i.is_invitation !== true);
  const marketingUsers = items.filter(i => i.role_id === 3 && i.is_invitation !== true);
  const pendingUsers = items.filter(i => i.is_invitation === true);

  const tabs = [
    {
      label: 'All',
      recordsLength: items.length,
      content: items,
    },
    {
      label: 'Admin',
      recordsLength: adminUsers.length,
      content: adminUsers,
    },
    {
      label: 'Dashboard',
      recordsLength: dashboardUsers.length,
      content: dashboardUsers,
    },
    {
      label: 'Dashboard basic',
      recordsLength: dashboardBasicUsers.length,
      content: dashboardBasicUsers,
    },
    {
      label: 'Marketing',
      recordsLength: marketingUsers.length,
      content: marketingUsers,
    },
    {
      label: 'Pending users',
      recordsLength: pendingUsers.length,
      content: pendingUsers,
    },
  ];

  return (
    <div>
      <div className={styles.titleBlock}>
        <div>
          <BackButton onBack={() => window.history.back()} />
          <h1 className="h2" style={{ margin: 0 }}>{typography.users.title}{' '}<span className={styles.spanTitleSecondText}>({items.length})</span></h1>
          <div style={{ margin: '0 0 20px 0' }}>{typography.users.subTitle}</div>
        </div>
        <div className={styles.btnAdditionalStyles}>
          {
            canAddUsers && (
            <AddBtnRedesigned
              onClick={() => handleClickAddNewUser()}
            >
              + New user
            </AddBtnRedesigned>
            )
          }
        </div>
      </div>
      <div
        // className={cx('container__cards', styles.usersListListAdditionalWrapper)}
        className={styles.pageContainer}
      >
        <div
          className={styles.tabsContainer}
        >
          {tabs.map((tab, index) => (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/no-static-element-interactions
            <div
              key={tab.label}
              onClick={() => handleTabClick(index)}
              className={cx(styles.tabItem, activeTab === index ? styles.active : '')}
            >
              {`${tab.label} (${tab.recordsLength})`}
            </div>
          ))}
        </div>
        <CustomUsersTable
          data={tabs[activeTab].content}
          user={user}
        />
      </div>
    </div>
  );
};

export default withRouter(CUsersList);
