import React, { useEffect, useState } from 'react';
import Button from 'components/ReactButton';
import vocabulary from 'constants/vocabulary';
import cx from 'classnames';
import DateFilter from 'components/Filters/DateFilter';
import { toDate, toUnixTime } from 'helpers/datetime';
import RadioFilter from 'components/Filters/RadioFilter';
import styles from './FilterBar.scss';
import typography from '../../../helpers/appTypography';

const defaultStatus = 'all';
const defaultDate = { from: 'any' };

function FilterBar({
  status: urlStatus, date: urlDate, onApply, filtersButtonState, setFiltersButtonState,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState(defaultStatus);
  const [date, setDate] = useState(defaultDate);
  const [isCancelClicked, setIsCancelClicked] = useState(false);

  const titleStyles = cx('h2', styles.titlePageStyles);

  useEffect(() => {
    const transformedDate = { ...urlDate };
    if (!vocabulary[urlDate.from]) {
      transformedDate.from = toDate(urlDate.from);
    }

    if (urlDate.to) {
      transformedDate.to = toDate(urlDate.to);
    }

    setStatus(urlStatus);
    setDate(transformedDate);
  }, [urlStatus, urlDate, isCancelClicked]);

  function changeStatusCode(e) {
    setStatus(e.target.value);
  }

  function changeDate(name, value) {
    setDate(value);
  }

  function changeRange(name, value) {
    setDate({ ...date, ...value });
  }

  function reset() {
    setFiltersButtonState([]);
    setStatus(defaultStatus);
    setDate(defaultDate);
  }

  function cancel() {
    setFiltersButtonState(false);
    setIsOpen(false);
    setIsCancelClicked(!isCancelClicked);
  }

  function apply() {
    setFiltersButtonState(false);
    setIsOpen(false);
    const filters = {
      status: null,
      date: null,
    };
    if (status !== 'all') {
      filters.status = vocabulary[status];
    }

    if (date.from !== 'any') {
      const filterDate = {
        from: vocabulary[date.from] ? date.from : toUnixTime(new Date(date.from)),
      };
      if (date.to) {
        filterDate.to = toUnixTime(new Date(date.to));
      }
      filters.date = filterDate;
    }

    onApply(filters);
  }

  return (
    <div
      style={{ position: 'relative' }}
      className={cx(
        styles.filtersContainer,
        isOpen ? styles.open : styles.closed,
      )}
    >
      <div />
      {filtersButtonState && (
        <div
          className={cx(
            styles.container,
            styles.containerAdditionalStyles,
            // { [styles.containerExpanded]: localStorage.getItem('sidebar.expanded') === 'true' },
          )}
        >
          <div
            className={cx(styles.containerInner)}
          >
            <div>
              <h1 className={titleStyles}>{typography.webhooksPopup.title}</h1>
            </div>
            <div
              className={cx('container__cards', styles.containerCardAdditionalStyles)}
            >
              <DateFilter
                name="webhook_date"
                options={['any', 'today', 'yesterday', 'last3days', 'last7days', 'custom']}
                values={date}
                handleChange={changeDate}
                handleDateRangePick={changeRange}
              />
              <RadioFilter
                name="webhook_status_code"
                options={['all', 'webhook_state_code_success', 'webhook_state_code_failed']}
                value={status}
                handleChange={changeStatusCode}
              />
            </div>
          </div>
          <div className={styles.buttonsRow}>
            <Button
              className={cx(styles.button)}
              onClick={apply}
            >
              Apply filters & close
            </Button>
            <Button
              className={cx(styles.button)}
              theme="grey"
              onClick={cancel}
            >
              Cancel
            </Button>
            <button
              type="button"
              className={cx(styles.clearBtn)}
              onClick={reset}
            >
              Clear
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default FilterBar;
