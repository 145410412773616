const TEXT_CHARACTER_SIZE_DESKTOP = 32;
const TEXT_CHARACTER_SIZE_TABLET = 22;
const TEXT_CHARACTER_SIZE_MOBILE = 12;

const getCharacterSize = ({ isDesktop, isTablet }) => {
  let cs = TEXT_CHARACTER_SIZE_MOBILE;
  if (isDesktop) {
    cs = TEXT_CHARACTER_SIZE_DESKTOP;
  } else if (isTablet) {
    cs = TEXT_CHARACTER_SIZE_TABLET;
  }
  return cs;
};

export const textTrimmer = ({ text, isDesktop, isTablet }) => {
  const characterSize = getCharacterSize({ isDesktop, isTablet });
  let tabName = text;

  if (text?.length > characterSize) {
    tabName = `${tabName.substring(0, characterSize)}...`;
  }
  return tabName;
};

export default function trimLongNames(tabName, isDesktop, isTablet, desktopCharacters, tabletCharacters, mobileCharacters) {
  let maxNameLength = 0;
  let newTabName = tabName;
  if (isDesktop) {
    maxNameLength = desktopCharacters;
  } else if (isTablet) {
    maxNameLength = tabletCharacters;
  } else {
    maxNameLength = mobileCharacters;
  }
  if (tabName?.length > maxNameLength) {
    newTabName = `${tabName.substring(0, maxNameLength)}...`;
  }
  return newTabName;
}
