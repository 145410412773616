import React from 'react';
import CardShell from 'components/CardShell';
import InputWithValidation from 'components/InputWithValidation';
import DualButtons from 'components/DualButtons';
import { withRouter } from 'react-router';
import API from 'helpers/API';
import Spinner from 'components/Spinner';
import { isEmail, maxLength } from 'helpers/validate';
import { getServerValidationErrors, validateForm } from 'helpers/validateForm';
import IconButton from 'components/IconButton/IconButton';
import copy from 'copy-to-clipboard';
import CopyToClipboardIcon from 'images/CopyToClipboard.svg';
import cx from 'classnames';
import { showPopup as showPopupAction } from 'actions';
import { connect } from 'react-redux';
import styles from './EditEmail.scss';

class EditEmail extends React.Component {
  validationRules = {
    authorisedSenderEmailAddress: [maxLength(100), isEmail()],
  };

  constructor(props) {
    super(props);

    this.state = {
      authorisedEmailData: null,
      isUpdating: false,
      errors: {},
    };
  }

  componentDidMount() {
    this.loadAuthorisedEmail();
  }

  loadAuthorisedEmail = async () => {
    const { emailId } = this.props.match.params;
    const result = await API.getAuthorisedEmails();
    if (result && result.authorisedEmails) {
      const authorisedEmail = result.authorisedEmails.find(x => x.id === Number(emailId));
      if (authorisedEmail) {
        this.setState(() => ({
          authorisedEmailData: authorisedEmail,
        }));
      }
    }
  };

  backToMenu = () => {
    this.props.history.push('/settings/register-shipment-webhooks/authorised-emails/');
  };

  submitUpdate = async () => {
    const clientErrors = validateForm(this.validationRules, this.state.authorisedEmailData);
    if (clientErrors.hasErrors) {
      this.setState({ errors: clientErrors });
      return;
    }

    try {
      this.setState({ isUpdating: true });

      const result = await API.updateAuthorisedEmails({
        id: this.state.authorisedEmailData.id,
        authorisedSenderEmailAddress: this.state.authorisedEmailData.authorisedSenderEmailAddress,
        isActive: this.state.authorisedEmailData.isActive,
      });

      this.setState(() => ({
        authorisedEmailData: result,
      }));

      this.backToMenu();
    } catch (response) {
      if (response.status === 400) {
        const serverErrors = getServerValidationErrors(response);
        this.setState({ errors: serverErrors });
      } else {
        this.props.showPopup({
          title: 'Error',
          description: 'We couldn\'t update your authorised email.',
        });
      }
    } finally {
      this.setState({ isUpdating: false });
    }
  };

  handleAuthorisedEmailInputChange = (e) => {
    const authorisedSenderEmailAddressValue = e.target.value;
    return this.setState(state => ({
      authorisedEmailData: { ...state.authorisedEmailData, authorisedSenderEmailAddress: authorisedSenderEmailAddressValue },
    }));
  };

  handleIsActiveInputChange = (e) => {
    const isActiveStatus = e.target.checked;
    return this.setState(state => ({
      authorisedEmailData: { ...state.authorisedEmailData, isActive: isActiveStatus },
    }));
  };

  render() {
    if (!this.state.authorisedEmailData) return <Spinner />;

    return (
      <div>
        {this.state.isUpdating && <Spinner />}
        <h2>Editing authorised email</h2>
        <div className="container__cards-large">
          <CardShell>
            <div className={styles.container}>
              <div className={cx(styles.createEmailWrapper, styles.formItem)}>
                <InputWithValidation
                  label="Authorised email address"
                  placeholder="Authorised email address"
                  type="text"
                  value={this.state.authorisedEmailData.authorisedSenderEmailAddress}
                  name="authorisedSenderEmailAddress"
                  onChange={this.handleAuthorisedEmailInputChange}
                  errors={this.state.errors}
                />
              </div>
              <div className={cx(styles.createEmailWrapper, styles.formItem)}>
                <InputWithValidation
                  label="Incoming email address"
                  type="text"
                  value={this.state.authorisedEmailData.incomingEmailAddress}
                  name="incomingEmailAddress"
                  action={(
                    <IconButton onClick={() => copy(this.state.authorisedEmailData.incomingEmailAddress)}>
                      <CopyToClipboardIcon
                        height="20"
                        data-tip="Copy to clipboard"
                      />
                    </IconButton>
                  )}
                  disabled
                />
              </div>
              <div className={styles.formItem}>
                <label>
                  <input
                    name="isActive"
                    type="checkbox"
                    checked={this.state.authorisedEmailData.isActive}
                    onChange={this.handleIsActiveInputChange}
                  />
                  <span className={styles.checkBoxLabel}>Make this active</span>
                </label>
              </div>
            </div>
            <DualButtons
              leftFunction={this.backToMenu}
              leftText="Cancel"
              rightFunction={this.submitUpdate}
              rightText="Update"
              theme="rightPink"
            />
          </CardShell>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showPopup: config => dispatch(showPopupAction(config)),
});

export default withRouter(connect(null, mapDispatchToProps)(EditEmail));
