import React from 'react';

/* eslint-disable */
export default () => (
  <svg id="circlePlus" preserveAspectRatio="none" width="42" height="42"
viewBox="0 0 72.6 71.7">
    <path d="M36.3,1.4c-19,0-34.4,15.4-34.4,34.4c0,19,15.4,34.4,34.4,34.4c19,0,34.4-15.4,34.4-34.4 C70.7,16.9,55.3,1.4,36.3,1.4z M36.3,66.3c-16.8,0-30.4-13.6-30.4-30.4c0-16.8,13.6-30.4,30.4-30.4c16.8,0,30.4,13.6,30.4,30.4 C66.7,52.6,53.1,66.3,36.3,66.3z"
    />
    <polygon points="39,24.3 33.6,24.3 33.6,33.7 24.4,33.7 24.4,39 33.6,39 33.6,48.5 39,48.5 39,39 48.2,39 48.2,33.7 39,33.7"
    />
</svg>
)
/* eslint-disable */