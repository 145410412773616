import React from 'react';
import LocalStorageTitle from 'components/localStorageTitle';
import { event as gaEvent } from 'react-ga';
import CardShell from 'components/CardShell';
import InvalidWebhooksIcon from 'images/invalidRegisterShipmentWebhooks.svg';
import AuthorisedEmailIcon from 'images/authorisedEmail.svg';
import styles from './styles.scss';

function RegisterShipmentWebhooksCentreCard({
  title, icon: Icon, buttonText, handleOnClick,
}) {
  return (
    <CardShell>
      <div className={styles.container}>
        <h3 className={`textCenter subtitle1 ${styles.cardTitle}`}>{title}</h3>
        <div className={styles.iconInnerContainer}>
          <Icon className={styles.registerShipmentWebhookIcon} />
        </div>
        <div>
          <button
            type="button"
            name="edit"
            className={styles.cardButton}
            onClick={handleOnClick}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </CardShell>
  );
}

function RegisterShipmentWebhooksCentre(props) {
  return (
    <div>
      <LocalStorageTitle
        localStorageName="invalidShipmentWebhooksPageText"
        pageTitle="Email Shipment Registration"
      >
        <div className="text_container">
          <div className="informationalTextBlock">
            <p>
              REACT’s <b>Register Shipments by Email</b> enables you to set up registration of shipments by emails.
            </p>
            <p>
              Email Shipment Registration centre allows you to:
            </p>
            <ul>
              <li>Authorise email addresses that you will use to register shipments.</li>
              <li>View any invalid emails received by REACT for registering shipments.</li>
            </ul>
          </div>
        </div>
      </LocalStorageTitle>
      <div>
        <div className="container__cards-large">
          <RegisterShipmentWebhooksCentreCard
            title="Authorised Emails"
            icon={AuthorisedEmailIcon}
            buttonText="Edit"
            handleOnClick={() => {
              props.history.push('/settings/register-shipment-webhooks/authorised-emails');
            }}
          />
          <RegisterShipmentWebhooksCentreCard
            title="Invalid Register Shipment Files"
            icon={InvalidWebhooksIcon}
            buttonText="View"
            handleOnClick={() => {
              gaEvent({
                category: 'InvalidRegisterShipmentWebhooks',
                action: 'Invalid register shipment files',
              });
              props.history.push('/settings/register-shipment-webhooks/invalid-shipment-webhooks');
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default RegisterShipmentWebhooksCentre;
