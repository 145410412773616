const countMessage = (selectedOption, sortedTableByTags, pageSize) => {
  if (!selectedOption?.length) {
    return pageSize;
  }
  if (sortedTableByTags?.length && sortedTableByTags?.length < 10) {
    return sortedTableByTags?.length;
  }
  if ((pageSize === sortedTableByTags?.length) && selectedOption) {
    return sortedTableByTags?.length;
  }
  //   if (selectedOption?.length && sortedTableByTags?.length > 10) {
  //     return 10;
  //   }
  if (selectedOption?.length && sortedTableByTags?.length === 0) {
    return 0;
  }
  return 10;
};

export default countMessage;
