const initialState = {
  loading: true,
  userItems: null,
  invitationItems: null,
  error: '',
};

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case 'USERS_FETCH':
      return {
        ...state,
        loading: true,
        error: initialState.error,
      };
    case 'USERS_SUCCESS':
      return {
        ...state,
        userItems: action.userItems,
        invitationItems: action.invitationItems,
        loading: false,
      };
    case 'USER_DELETE_SUCCESS': {
      const { userItems } = state;
      const userItemsUpdated = userItems && userItems.filter(x => x.id !== action.userId);
      return {
        ...state,
        userItems: userItemsUpdated,
      };
    }
    case 'INVITATION_DELETE_SUCCESS': {
      const { invitationItems } = state;
      const invitationItemsUpdated = invitationItems && invitationItems.filter(x => x.id !== action.invitationId);
      return {
        ...state,
        invitationItems: invitationItemsUpdated,
      };
    }
    default:
      return state;
  }
}
