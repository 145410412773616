import React, { Component } from 'react';
import CardShell from 'components/CardShell';
import { event as gaEvent } from 'react-ga';
import { inviteUser as inviteUserAction } from 'actions';
import { connect } from 'react-redux';
import MemberForm from 'routes/Users/MemberForm';
import UsersContext from 'routes/Users/UsersContext';

class AddMember extends Component {
  static contextType = UsersContext;

  save = async ({
    firstName, lastName, email, roleId,
  }) => {
    await this.props.onInviteUser(firstName, lastName, email, roleId)
      .then(() => {
        gaEvent({
          category: 'AccountAndUserManagement',
          action: 'New user created',
        });
      });
  };

  load = () => Promise.resolve({
    firstName: '',
    lastName: '',
    email: '',
    roleId: this.context.roles[0].id,
  });

  render() {
    return (
      <div>
        <h1 className="h2">Add a new user</h1>
        <div
          className="container__cards"
          style={{ background: '#f7f7f7', maxHeight: '635px' }}
        >
          <CardShell noOverflow>
            <MemberForm save={this.save} load={this.load} saveLabel="Invite" checkEmailUniquines />
          </CardShell>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onInviteUser: (firstName, lastName, email, roleId) => dispatch(inviteUserAction(firstName, lastName, email, roleId)),
});

export default connect(null, mapDispatchToProps)(AddMember);
