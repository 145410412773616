const initialState = {
  isLoading: false,
  states: undefined,
  errorMessage: '',
};

export default function availableStatesReducer(state = initialState, action) {
  switch (action.type) {
    case 'SHIPMENT_AVAILABLE_STATES_FETCH':
      return {
        isLoading: true,
        states: initialState.states,
        errorMessage: initialState.errorMessage,
      };
    case 'SHIPMENT_AVAILABLE_STATES_SUCCESS':
      return {
        ...state,
        isLoading: false,
        states: action.states,
      };
    case 'SHIPMENT_AVAILABLE_STATES_ERROR':
      return {
        ...state,
        isLoading: false,
        errorMessage: 'Failed to load shipment states',
      };
    default:
      return state;
  }
}
