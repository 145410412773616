import React from 'react';
import propTypes from 'prop-types';
import NavItem from '../NavItem';
import SubNavItem from '../SubNavItem';

const ProDashboards = ({ title }) => (
  <NavItem title={title} url="dashboards">
    <SubNavItem baseUrl="dashboards">
      NEW ITEM HERE!
    </SubNavItem>
    <SubNavItem baseUrl="dashboards" urlRename="shipments">
      NEW ITEM HERE!
    </SubNavItem>
    <SubNavItem baseUrl="dashboards" urlRename="calculated-events">
      NEW ITEM HERE!
    </SubNavItem>
  </NavItem>
);

ProDashboards.propTypes = {
  title: propTypes.string.isRequired,
};

export default ProDashboards;
