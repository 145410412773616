const initialState = {
  emailStatistics: null,
  isLoading: false,
  requestDidTimeout: false,
};

export default function emailStatisticsReducer(state = initialState, action) {
  switch (action.type) {
    case 'EMAIL_STATISTICS_FETCH':
      return {
        ...state,
        emailStatistics: null,
        isLoading: true,
      };
    case 'EMAIL_STATISTICS_SUCCESS':
      return {
        ...state,
        emailStatistics: action.emailStatistics,
        isLoading: false,
        requestDidTimeout: false,
      };
    case 'EMAIL_STATISTICS_ERROR':
      return {
        ...state,
        emailStatistics: {},
        isLoading: false,
        requestDidTimeout: true,
      };
    default:
      return state;
  }
}
