import {
  REVOKE_API_KEY_SUCCESS,
  UPDATE_API_KEY,
  UPDATE_API_KEY_SUCCESS,
  API_KEYS_FETCH,
  API_KEYS_SUCCESS,
  CREATE_API_KEY_FETCH,
  CREATE_API_KEY_SUCCESS,
} from 'actions/types';

const initialState = {
  loading: true,
  keys: [],
  error: '',
  isActive: false,
};

export default function apiKeysReducer(state = initialState, action) {
  switch (action.type) {
    case API_KEYS_FETCH:
    case UPDATE_API_KEY:
    case CREATE_API_KEY_FETCH:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case API_KEYS_SUCCESS:
      return {
        keys: action.keys,
        loading: false,
        error: '',
      };
    case CREATE_API_KEY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case UPDATE_API_KEY_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: '',
        keys: state.keys.map(key => (
          key.id === action.id
            ? { ...key, name: action.name }
            : key
        )),
      };
    }
    case REVOKE_API_KEY_SUCCESS:
      return {
        ...state,
        keys: [
          ...state.keys.filter(key => key.id !== action.id),
        ],
      };
    default:
      return state;
  }
}
