import React from 'react';
import cx from 'classnames';
import StateCategories from 'constants/stateCategories';
import StateCategoryRedSvg from 'images/stateCategoryRed.svg';
import StateCategoryAmberSvg from 'images/stateCategoryAmber.svg';
import StateCategoryGreenSvg from 'images/stateCategoryGreen.svg';
import StateCategoryGreenActiveSvg from 'images/stateCategoryGreenActive.svg';
import styles from './styles.scss';

function icon(category, isActive) {
  switch (category) {
    case StateCategories.red:
      return <StateCategoryRedSvg className={styles.redIcon} />;
    case StateCategories.amber:
      return <StateCategoryAmberSvg className={styles.amberIcon} />;
    case StateCategories.green:
      return isActive ? <StateCategoryGreenActiveSvg className={styles.greenIcon} /> : <StateCategoryGreenSvg className={styles.greenIcon} />;
    default:
      return <StateCategoryGreenSvg className={styles.greenIcon} />;
  }
}

const CategoryIcon = ({
  category, isActive, isActiveHighlighted = false, isOutlined = false, className,
}) => {
  const categoryLower = category ? category.toLowerCase() : '';
  return (
    <div className={cx(
      styles.categoryContainer,
      className,
      isOutlined ? styles.outline : null,
      isActive && isActiveHighlighted ? styles[categoryLower] : null,
    )}
    >
      {icon(categoryLower, isActive)}
    </div>
  );
};

export default CategoryIcon;
