import React from 'react';
import InputWithValidation from 'components/InputWithValidation';
import styles from '../styles.scss';

const AddNewHeaderForm = (props) => {
  const {
    onHeaderRequest,
    headersKey,
    headersValue,
    onHeadersInputChange,
  } = props;

  return (
    <div className={styles.formContainer}>
      <form onSubmit={onHeaderRequest}>
        <div className={styles.formRow}>
          <InputWithValidation
            label="Key"
            placeholder="Key"
            onChange={onHeadersInputChange}
            value={headersKey}
            type="text"
            name="Key"
          />
        </div>
        <div className={styles.formRow}>
          <InputWithValidation
            label="Value"
            placeholder="Value"
            onChange={onHeadersInputChange}
            value={headersValue}
            type="text"
            name="Value"
          />
        </div>
        <div className={styles.formRow}>
          <button type="submit" className={styles.requestBtn}>Add</button>
        </div>
      </form>
    </div>
  );
};

export default AddNewHeaderForm;
