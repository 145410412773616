import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import saveAs from 'helpers/saveAs';
import API from '../../../../../helpers/API';
import Spinner from '../../../../../components/Spinner';
import TabErrorMessage from '../TabErrorMessage';
import SuccessMessage from '../SuccessMessage';
import ReasonDropdown from './reasonDropdown';
import SearchInput from './searchInput';
import { ExportSuppressions } from './buttons';
import CreateSuppression from './createSuppression';
import styles from './styles.scss';
import SuppressionList from './suppressionList';
import EmailStatisticsContext from '../../EmailStatisticsContext';

const Suppressions = () => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = React.useState(true);
  const [isSuppresionsError, setIsSuppresionsError] = React.useState(false);
  const [suppressions, setSuppressions] = React.useState([]);
  const [selectedReason, setSelectedReason] = React.useState(null);
  const [filterEmail, setFilterEmail] = React.useState('');
  const [suppressionStatus, setSuppressionStatus] = React.useState(null);
  const [suppressionStatusTitle, setSuppressionStatusTitle] = React.useState('');
  const [suppressionStatusMessage, setSuppressionStatusMessage] = React.useState('');
  const { setPageTitle, setPageMessage, setSelectedServerId } = useContext(EmailStatisticsContext);

  setTimeout(() => {
    setPageTitle('Suppressions');
    setPageMessage('This page contains the emails that are suppressed with reasons');
    setSelectedServerId(id);
  });

  useEffect(() => {
    const fetchSuppressions = async () => {
      try {
        setIsLoading(true);
        setIsSuppresionsError(false);
        setSuppressionStatus(null);
        setSuppressionStatusMessage('');
        const response = await API.getEmailSuppressions(
          id,
          selectedReason,
          filterEmail,
        );
        setSuppressions(response.suppressions);
      } catch (e) {
        setIsSuppresionsError(true);
      }

      setIsLoading(false);
    };

    fetchSuppressions();
  }, [id, selectedReason, filterEmail]);

  const handleConfirmValue = (value) => {
    setFilterEmail(value);
  };

  const AddSuppressionSuccess = (x, emails) => {
    setIsLoading(true);
    setSuppressionStatus('Added');
    setSuppressionStatusTitle(`${x === 1 ? '1 recipient' : `${x} recipients`} added to the suppression list`);
    setSuppressionStatusMessage('It can take a minute for newly added recipients to appear.');
    suppressions.suppressions = suppressions.suppressions.concat(emails.map(email => (
      {
        emailAddress: email.emailAddress,
        suppressionReason: 'ManualSuppression',
        createdAt: Date.now(),
        origin: 'Customer',
      })));
    setIsLoading(false);
  };

  const AddSuppressionFailure = (x, e) => {
    setSuppressionStatus('Failed');
    setSuppressionStatusTitle(`${x === 1 ? '1 recipient' : `${x} recipients`} recipient could not be added to the suppression list`);
    setSuppressionStatusMessage(e.message ?? '');
  };

  const DeleteSuppression = async (sinkId, cellData) => {
    try {
      setIsSuppresionsError(false);
      setIsLoading(true);
      const response = await API.deleteSuppression(sinkId, [cellData.emailAddress]);
      if (response && response.suppressions && response.suppressions.length > 0) {
        // always doing only first item as we don't an option to remove multiples ones from UI
        const delSuppression = response.suppressions[0];
        setSuppressionStatus(delSuppression.status);

        setSuppressionStatusTitle(delSuppression.status === 'Deleted' ? 'Recipient will be activated' : '1 recipient could not be added to the suppression list');
        const statusMessage = `${delSuppression.emailAddress}  - ${delSuppression.status === 'Deleted'
          ? 'It can take a minute for recipient to become active' : `${delSuppression.message}`}`;
        setSuppressionStatusMessage(statusMessage);
      }
      response.suppressions.forEach(y => {
        if (y.status === 'Deleted') {
          suppressions.suppressions = suppressions.suppressions.filter(x => x.emailAddress !== y.emailAddress);
        }
      });
    } catch (error) {
      setIsSuppresionsError(true);
    }
    setIsLoading(false);
  };

  const ExportSuppression = async (sinkId) => {
    try {
      setIsSuppresionsError(false);
      setIsLoading(true);
      await API.exportSuppression(sinkId)
        .then((blob) => {
          saveAs(blob, `suppressions_${(new Date()).toLocaleDateString('en-GB')}.csv`);
          return Promise.resolve();
        })
        .catch(() => {
          setIsSuppresionsError(true);
        });
    } catch (error) {
      setIsSuppresionsError(true);
    }
    setIsLoading(false);
  };

  return (
    <>
      {!isLoading && isSuppresionsError && <TabErrorMessage />}
      {suppressionStatus && (suppressionStatus === 'Deleted' || suppressionStatus === 'Added') && (
        <SuccessMessage
          id="suppressionSuccessMessage"
          title={suppressionStatusTitle}
          message={suppressionStatusMessage}
        />
      )}
      {suppressionStatus && suppressionStatus === 'Failed' && (
        <TabErrorMessage
          id="suppressionErrorMessage"
          title={suppressionStatusTitle}
          message={suppressionStatusMessage}
        />
      )}
      <div className={styles.filterBar}>
        <div className={styles.filters}>
          <SearchInput confirmValue={handleConfirmValue} />
          <ReasonDropdown selectedReason={selectedReason} setSelectedReason={setSelectedReason} />
        </div>
        <div className={styles.actions}>
          <ExportSuppressions onClick={() => ExportSuppression(id)} />
          <CreateSuppression onSuccess={AddSuppressionSuccess} onFailure={AddSuppressionFailure} />
        </div>
      </div>
      {isLoading && !isSuppresionsError ? (
        <Spinner />
      ) : (
        <SuppressionList id="suppressionList" loading={isLoading} suppressions={suppressions} onReactivateClick={DeleteSuppression} />
      )}
    </>
  );
};

export default Suppressions;
