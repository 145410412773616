import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Switch from 'components/Switch';
import BinIcon from 'images/bin.svg';
import { event as gaEvent } from 'react-ga';
import CardEllipsisMenu from 'components/CardEllipsisMenu';
import CategoryIcon from 'components/CategoryIcon';
import Popup from 'components/Layout/Popup';
import ReactTooltip from 'react-tooltip';
import {
  showCustomPopup as showCustomPopupAction,
  closeCustomPopup as closeCustomPopupAction,
  showPopup as showPopupAction,
  closePopup as closePopupAction,
} from 'actions';
import TextMenuItem from 'components/ContextMenu/TextMenuItem';
import getSinkTypeName from './getSinkTypeName';
import VerifyDomain from './VerifyDomain';
import styles from '../styles.scss';

function NotificationConfigurationCard({
  notificationConfiguration: {
    id,
    name,
    type,
    isActive,
    logoUrl,
    dkimVerified,
    dkimHost,
    dkimTextValue,
  },
  history,
  toggleActive,
  disconnect,
  showPopup,
  closePopup,
  showCustomPopup,
  closeCustomPopup,
}) {
  const [popup, setPopup] = useState({});
  useEffect(() => closePopup, [closePopup]);

  const handleVerified = () => {
    showPopup({
      title: 'Domain verification',
      description: 'Domain was successfully verified.',
      onDecline: () => window.location.reload(),
    });
    closeCustomPopup();
  };

  const promptDisconnect = async () => {
    setPopup({
      open: true,
      title: 'Are you sure?',
      description: <span>The configuration cannot be recovered once disconnected.</span>,
      onAccept: () => disconnect(id).then(() => {
        setPopup({ open: false });
      }),
      onDecline: () => setPopup({ open: false }),
    });
  };

  const handleVerify = () => {
    showCustomPopup(
      <VerifyDomain
        sinkConfigurationId={id}
        dkimHost={dkimHost}
        dkimTextValue={dkimTextValue}
        onCanceled={closeCustomPopup}
        onVerified={handleVerified}
      />,
    );
  };

  const getMenuItems = () => {
    const menuItems = [];
    if (dkimVerified === false) {
      menuItems.push(<TextMenuItem text="Verify" clickHandler={handleVerify} />);
    }
    menuItems.push(<TextMenuItem text="Disconnect" clickHandler={promptDisconnect} icon={BinIcon} />);
    return menuItems;
  };

  const handleEditClick = () => {
    gaEvent({
      category: 'Notifications',
      action: 'Notification Connector Edit',
    });
    history.push(`/settings/notification-centre/notification-configuration/edit/${id}`);
  };

  return (
    <div className={styles.container}>
      <Popup
        open={popup.open}
        title={popup.title}
        description={popup.description}
        onAccept={popup.onAccept}
        onDecline={popup.onDecline}
      />
      <h3 className={`textCenter subtitle1 ${styles.cardTitle}`}>{name}</h3>
      {dkimVerified === false
        && (
          <div
            data-tip="Please verify your domain.<br/>Use the option inside the menu for further instructions."
            className={styles.statusIconContainer}
          >
            <CategoryIcon category="red" isActive className={styles.statusIcon} isActiveHighlighted />
            <ReactTooltip type="dark" border effect="solid" multiline />
          </div>
        )}
      <p className={`textCenter ${styles.cardCopy}`}>{getSinkTypeName(type)}</p>
      <div className={styles.logoHolder}>
        <img
          className={styles.logo}
          src={logoUrl}
          alt=""
        />
      </div>
      <div className={styles.switcherHolder}>
        <Switch
          id={name}
          enabled={isActive}
          toggle={() => toggleActive(id)}
        />
      </div>
      <CardEllipsisMenu
        primaryText="Edit"
        primaryClickHandler={handleEditClick}
      >
        {getMenuItems()}
      </CardEllipsisMenu>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  showCustomPopup: (component, onClosed) => dispatch(showCustomPopupAction(component, onClosed)),
  closeCustomPopup: () => dispatch(closeCustomPopupAction()),
  showPopup: config => dispatch(showPopupAction(config)),
  closePopup: () => dispatch(closePopupAction()),
});

export default connect(null, mapDispatchToProps)(NotificationConfigurationCard);
