const initialState = {
  loading: false,
  page: 1,
  pageSize: 200,
  shipments: [],
  totalShipments: 0,
  totalPages: 1,
};

export default function shipmentsReducer(state = initialState, action) {
  switch (action.type) {
    case 'SHIPMENTS_FETCH':
      return {
        ...state,
        loading: true,
      };
    case 'SHIPMENTS_SUCCESS':
      return {
        ...state,
        loading: false,
        pageSize: action.page_size,
        page: action.page,
        shipments: action.shipments.map((shipment) => {
          let address = ['', '', ''];
          if (shipment.address) {
            const destination = shipment.address.find(x => x.address_type === 'destination');
            if (destination) {
              address = [destination.address_line1, destination.address_line2, destination.address_line3];
            }
          }

          return {
            ...shipment,
            address,
          };
        }),
        totalShipments: action.total_shipments,
        totalPages: action.last_page,
        requestDidTimeout: false,
      };
    case 'SHIPMENTS_ERROR':
      return {
        ...state,
        loading: false,
        shipments: [],
        requestDidTimeout: false,
      };
    case 'SHIPMENTS_TIMEOUT':
      return {
        ...state,
        loading: false,
        shipments: [],
        requestDidTimeout: true,
      };
    default:
      return state;
  }
}
