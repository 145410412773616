import React from 'react';
import cx from 'classnames';
import useMediaQuery from '../../hooks/useMediaQuery';
import styles from './style.scss';
import trimLongNames from '../../helpers/trimLongNames';

function CustomTile({
  tile: {
    description,
    subDescription1,
    subDescription2,
    subDescription3,
    firstDataString,
    secondDataString,
    category,
    trimLength,
  },
  clickable,
  handleTileClick,
}) {
  const isDesktop = useMediaQuery('(min-width: 960px)');
  const isTablet = useMediaQuery('(min-width: 768px)');

  return (
    <div
      id="tile"
      role="presentation"
      className={cx(styles.card, styles[category], clickable ? styles.clickable : '')}
      key={description}
      onClick={clickable ? () => handleTileClick(description) : undefined}
    >
      <div className={styles.detailsWrapper}>
        <div className={styles.description} title={description}>
          <span>
            {trimLongNames(description, isDesktop, isTablet, trimLength, trimLength, trimLength)}
          </span>
        </div>
        <div className={styles.subDescription1} title={subDescription1}>
          <span>
            {trimLongNames(subDescription1, isDesktop, isTablet, trimLength, trimLength, trimLength)}
          </span>
        </div>
        <div className={styles.subDescription2} title={subDescription2}>
          <span>
            {trimLongNames(subDescription2, isDesktop, isTablet, trimLength, trimLength, trimLength)}
          </span>
        </div>
        <div title={subDescription3} className={styles.subDescription3}>
          <span>{subDescription3}</span>
        </div>
        <div className={styles.data}>
          <span className={styles.dataFirstPart}>
            {`${firstDataString} `}
          </span>
          <span>
            {secondDataString}
          </span>
        </div>
      </div>
    </div>
  );
}

export default CustomTile;
