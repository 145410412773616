import React from 'react';

import {
  resendEmailConfirmation as resendEmailConfirmationAction,
  closePopup as closePopupAction,
  showPopup as showPopupAction,
} from 'actions';

import { connect } from 'react-redux';

function withResendEmailPopup(WrappedComponent) {
  class WithResendEmailHOC extends React.Component {
    constructor(props) {
      super(props);
      this.state = { isLoading: false };
      this.onClick.bind(this);
    }

    onClick = () => {
      this.setState({ isLoading: true });
      this.props.onResendEmail(this.props.email)
        .then((message) => {
          this.setState({ isLoading: false });
          if (message) {
            this.props.showPopup({
              title: 'Resend email',
              description: message,
              onDecline: () => this.props.closePopup(),
            });
          }
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    };

    render() {
      return (<WrappedComponent {...this.props} onClick={this.onClick} isLoading={this.state.isLoading} />);
    }
  }

  const mapDispatchToProps = dispatch => ({
    onResendEmail: email => dispatch(resendEmailConfirmationAction(email)),
    showPopup: config => dispatch(showPopupAction(config)),
    closePopup: () => dispatch(closePopupAction()),
  });
  return connect(null, mapDispatchToProps)(WithResendEmailHOC);
}

export default withResendEmailPopup;
