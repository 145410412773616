import React from 'react';

/* eslint-disable */
export default props => (
  <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 71.9 68.2'>
    <path d='M58.4,45.1c3,0,5.4-2.4,5.4-5.4V5.4c0-3-2.4-5.4-5.4-5.4h-53C2.4,0,0,2.4,0,5.4v34.3c0,3,2.4,5.4,5.4,5.4H8 v14.1c0,0.5,0.6,0.7,0.9,0.3l11.3-14.4H58.4z M7.4,12.5c0-0.5,0.4-0.9,0.9-0.9h47.2c0.5,0,0.9,0.4,0.9,0.9v3.4 c0,0.5-0.4,0.9-0.9,0.9H8.3c-0.5,0-0.9-0.4-0.9-0.9V12.5z M7.4,20.9c0-0.5,0.4-0.9,0.9-0.9h47.2c0.5,0,0.9,0.4,0.9,0.9v3.4 c0,0.5-0.4,0.9-0.9,0.9H8.3c-0.5,0-0.9-0.4-0.9-0.9V20.9z M8.3,33.7c-0.5,0-0.9-0.4-0.9-0.9v-3.4c0-0.5,0.4-0.9,0.9-0.9h32 c0.5,0,0.9,0.4,0.9,0.9v3.4c0,0.5-0.4,0.9-0.9,0.9H8.3z'
    />
    <path className='st0' d='M66.5,8.5h-0.1v31.2c0,4.4-3.6,8-8,8H21.5l-4.6,5.9h34.9L63.1,68c0.3,0.4,0.9,0.2,0.9-0.3V53.6h2.6 c3,0,5.4-2.4,5.4-5.4V13.9C71.9,10.9,69.5,8.5,66.5,8.5z'
    />
  </svg>
)

/* eslint-enable */
