import React, { useState, useContext } from 'react';
import { addYears, addMonths, addDays } from 'date-fns';
import { useParams } from 'react-router-dom';
import styles from './statistics.scss';
import API from '../../../../helpers/API';
import Spinner from '../../../../components/Spinner';
import PieCharts from '../pieCharts/pieCharts';
import AreaChart from '../areaChart/areaChart';
import EmailStatisticsCards from './EmailStatisticsCards';
import DatesDropdown from './DatesDropdown';
import TabErrorMessage from './TabErrorMessage';

import EmailStatisticsContext from '../EmailStatisticsContext';

export function calculateStartDate(period) {
  const today = new Date();
  let result;
  switch (period) {
    case '1':
      result = addYears(today, -1);
      break;
    case '2':
      result = addMonths(today, -6);
      break;
    case '3':
      result = addDays(today, -30);
      break;
    case '4':
      result = addDays(today, -7);
      break;
    case '5':
      result = today;
      break;
    default:
      result = addYears(today, -1);
  }
  return result;
}

const Statistics = () => {
  const { id } = useParams();
  const [isLoadingEmailStatistics, setIsLoadingEmailStatistics] = useState(true);
  const [emailStatistics, setEmailStatistics] = useState();
  const [isEmailStatisticsError, setIsEmailStatisticsError] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState('3');
  const {
    setPageTitle, setPageMessage, setSelectedServerId, loadDefaultServer,
  } = useContext(EmailStatisticsContext);

  // This function used to be setImmediate but there was an issue with it, setTimeout is a workaround for now
  setTimeout(() => {
    if (id) {
      setSelectedServerId(id);
    }
    setPageTitle('Email Statistics');
    setPageMessage('This page contains high level email performance statistics. You can choose which date range you want to view by using the datepickers above the table.');
  });

  React.useEffect(() => {
    const fetchEmailStatistics = async () => {
      setIsEmailStatisticsError(false);
      setIsLoadingEmailStatistics(true);
      if (!id) {
        await loadDefaultServer();
        return;
      }
      try {
        const startDate = calculateStartDate(selectedPeriod);
        const endDate = new Date();
        const response = await API.getEmailStatistics(id, startDate, endDate);
        setEmailStatistics(response?.emailStatistics);
      } catch (e) {
        setIsEmailStatisticsError(true);
      }

      setIsLoadingEmailStatistics(false);
    };

    fetchEmailStatistics();
  }, [id, selectedPeriod, loadDefaultServer]);

  return (
    < >
      {isLoadingEmailStatistics && <Spinner />}

      {!isLoadingEmailStatistics && isEmailStatisticsError && <TabErrorMessage />}

      {!isLoadingEmailStatistics && !isEmailStatisticsError && emailStatistics && (
        <>
          <DatesDropdown setSelectedPeriod={setSelectedPeriod} selectedPeriod={selectedPeriod} />

          <div className={styles.statisticsContainer}>
            <EmailStatisticsCards emailStatistics={emailStatistics} id={id} />
            <AreaChart days={emailStatistics.days} />
            <PieCharts platform={emailStatistics.Platform} clients={emailStatistics.Clients} dateKey={selectedPeriod} />
          </div>
        </>
      )}
    </>
  );
};
export default Statistics;
