import React from 'react';

/* eslint-disable */
export default props => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg"viewBox="0 0 18.5 27.4">
    <path d="M13.1,7.9c0-0.3,0-0.6-0.1-1c-0.3-1.3-1.3-2.4-2.6-2.8C10,4.1,9.6,4,9.2,4C7.1,4,5.3,5.8,5.3,7.9   s1.7,3.9,3.9,3.9S13.1,10.1,13.1,7.9z M6.8,7.9c0-1.4,1.1-2.5,2.5-2.5c0.3,0,0.5,0,0.7,0.1c0.8,0.3,1.5,0.9,1.7,1.8   c0,0.2,0.1,0.4,0.1,0.6c0,1.4-1.1,2.5-2.5,2.5S6.8,9.3,6.8,7.9z"/>
    <path d="M13.1,16.6c1.8-2.5,3.8-5.8,3.8-8.8c0-4.2-3.4-7.6-7.6-7.6S1.6,3.6,1.6,7.8c0,3,1.9,6.3,3.8,8.8   c-2.8,0.7-5.2,2.7-5.2,4.7c0,3.2,3.9,5.7,9,5.7c1.2,0,2.3-0.1,3.4-0.4l-0.3-1.4c-1,0.2-2,0.4-3,0.4c-4.1,0-7.6-2-7.6-4.3   c0-1.3,2.3-3.1,4.7-3.4c0.4,0.5,0.7,0.8,1,1.2C6.5,19.5,6,20.2,6,21c0,1.2,1.1,2.5,3.3,2.5s3.3-1.2,3.3-2.5c0-0.8-0.6-1.5-1.5-2   c0.3-0.3,0.6-0.7,1-1.2c2.6,0.3,4.7,1.8,4.7,3.5c0,1.1-0.7,2.1-2,2.9l0.7,1.2c1.7-1.1,2.7-2.5,2.7-4.1   C18.2,19.2,16.1,17.2,13.1,16.6z M9.2,1.6c3.4,0,6.2,2.8,6.2,6.2c0,4.3-4.6,9.6-6.2,11.2C7.7,17.4,3,12,3,7.8   C3,4.4,5.8,1.6,9.2,1.6z M11.2,21c0,0.6-0.8,1-1.9,1s-1.9-0.4-1.9-1c0-0.3,0.4-0.6,1-0.8l0,0c0.2,0.2,0.3,0.3,0.4,0.4L9.2,21   l0.5-0.5c0,0,0.2-0.2,0.4-0.4C10.7,20.4,11.2,20.7,11.2,21z"/>
  </svg>
)

/* eslint-enable */
