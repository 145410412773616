import {
  SEND_WEBHOOK_SUCCESS,
  SEND_WEBHOOK_ERROR,
} from 'actions/types';

const initialState = {
  isWebhookSent: false,
};

const webhookStepsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_WEBHOOK_SUCCESS:
      return {
        ...state,
        isWebhookSent: true,
      };
    case SEND_WEBHOOK_ERROR:
      return {
        ...state,
        isWebhookSent: false,
      };
    default:
      return state;
  }
};

export default webhookStepsReducer;
