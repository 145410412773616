import React from 'react';
import { connect } from 'react-redux';
import InputWithValidation from 'components/InputWithValidation';
import SelectWithValidation from 'components/SelectWithValidation';

const WebhookName = ({
  title, onChange, versions, payloadVersion, onChangePayloadVersion,
}) => (
  <div>
    <InputWithValidation
      label="Name your webhook"
      placeholder="Webhook name"
      type="text"
      value={title}
      name="webhookName"
      onChange={onChange}
      required
    />
    <SelectWithValidation
      name="webhookPayloadVersion"
      label="Payload Version"
      required
      options={versions.map(x => ({ value: x.field_value, label: x.field_name }))}
      value={payloadVersion}
      onChange={onChangePayloadVersion}
    />
  </div>
);

const mapStateToProps = state => ({ versions: state.webhooksStates.versions });

export default connect(mapStateToProps)(WebhookName);
