import React from 'react';
import classnames from 'classnames';
import styles from './react-button.scss';

const ReactButton = props => (
  <button
    onClick={props.onClick}
    className={classnames(
      styles.reactButton,
      props.className,
      props.disabled ? styles.disabled : '',
      styles[props.theme || 'pink'],
    )}
    style={props.style}
    type="button"
    title={props.title}
    data-testid={props['data-testid']}
  >
    <span className={styles.content}>{props.text ? props.text : props.children}</span>
  </button>
);

export default ReactButton;
