import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import API from 'helpers/API';
import ReactTable from 'react-table';
import Select from 'react-select';
import styles from './styles.scss';
import Spinner from '../Spinner';
import UpdatedAtWithRefresh from '../UpdatedAtWithRefresh/UpdatedAtWithRefresh';
import renderStatus from '../DashboardTable/renderStatus';
import BackButton from '../BackButton';
import countMessage from './functions';
import { headerStyle } from './styles';
import DashboardTotalViewTableModal from '../DashboardTotalViewTableModal/DashboardTotalViewTableModal';

const calculateTagsLength = (rowItem) => rowItem?.filter(i => i.isUiable !== false).length;

function DashboardTotalViewTable() {
  const history = useHistory();
  const { dashboard } = useParams();
  const [pageName, setPageName] = useState('');
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(null);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [selectedOption, setSelectedOptions] = useState([]);
  const [tagDataModal, setTagDataModal] = useState(null);
  const [selectedCell, setSelectedCell] = useState(null);

  const getFriendlyTagNameValue = (tag) => {
    const {
      friendlyName,
      friendlyValue,
      name,
      value,
    } = tag;
    const namePart = (friendlyName?.trim() !== '' && friendlyName !== null) ? friendlyName : name;
    const valuePart = (friendlyValue?.trim() !== '' && friendlyValue !== null) ? friendlyValue : value;
    return `${namePart}:${valuePart}`;
  };

  const getTagNameValue = (tag) => `${tag.name}:${tag.value}`;

  const colorSelectStyles = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? '2px solid #0065b3' : '1px solid lightgrey',
      boxShadow: '#0065b3 !important',
      '&:hover': {
        border: '1px solid #fc835a !important',
      },
    }),
    multiValueRemove: (defaultStyles) => ({
      ...defaultStyles,
      color: 'red',
      backgroundColor: '#FFB197',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      ':hover': {
        backgroundColor: '#FFB197',
        color: 'black',
      },
    }),
  };

  const sortedTableByTags = useMemo(
    () => tableData.filter(tableItem => {
      const tagValues = tableItem?.tags?.map(tag => getTagNameValue(tag));
      if (!selectedOption?.length || !sortedTableByTags?.length) {
        setPageSize(tableData.length > 10 ? 10 : tableData.length);
      }
      return selectedOption?.every(option => tagValues?.includes(option));
    }),
    [selectedOption, tableData],
  );

  const handleSelectChange = v => {
    const res = v?.map(item => item.value);
    setSelectedOptions(res);
    setTagDataModal(false);
  };

  const result = [];
  tableData.forEach(data => {
    data.tags.filter(i => i.isUiable !== false)
      .forEach(ele => result.push({
        key: getTagNameValue(ele),
        element: ele,
      }));
  });

  const selectOptionsValues = [...new Map(result.map(item => [item.key, item])).values()].sort()
    .map((i) => ({
      value: i.key,
      label: getFriendlyTagNameValue(i.element),
    }));

  const onShowMoreClick = () => {
    setPageSize(tableData?.length);
    if (selectedOption) {
      setPageSize(sortedTableByTags.length);
    }
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      const dashboardAlertsToSet = await API.getConfiguredDashboardAlertData(dashboard, '?top=1000');
      dashboardAlertsToSet.dashboard.alerts = dashboardAlertsToSet.dashboard.alerts.filter(alert => alert.category !== 'unknown');
      setTableData(dashboardAlertsToSet.dashboard.alerts);
      setPageName(dashboardAlertsToSet.dashboard.text);
      setIsLoading(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const tagsGenerator = (items) => {
    const filteredTags = items.value?.filter(i => i.isUiable !== false);
    return filteredTags?.splice(0, 2)
      .map(i => (
        <span
          key={`${i.name}:${i.value}`}
          className={styles.tagsAndCounterWrapperSpan}
        >
          {getFriendlyTagNameValue(i)}
        </span>
      ));
  };

  const tagsButtonGenerator = (item) => {
    const filteredTags = item.value?.filter(i => i.isUiable !== false);
    return filteredTags?.map(i => (
      <div style={{ display: 'flex' }}>
        <div
          key={`${i.name}`}
          className={styles.tagsAndCounterWrapperSpan}
        >
          {getFriendlyTagNameValue(i)}
        </div>
      </div>
    ));
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
    setPageSize(tableData.length > 10 ? 10 : tableData.length);
    // eslint-disable-next-line
  }, [tableData.length]);

  useEffect(() => {
    if (triggerRefresh) {
      getData();
      setTriggerRefresh(false);
      setSelectedOptions([]);
      setTagDataModal(false);
    }
    // eslint-disable-next-line
  }, [triggerRefresh]);

  return (
    <>
      {
        tableData && !isLoading
          ? (
            <div>
              <BackButton onBack={() => {
                history.push(
                  {
                    pathname: '/dashboards/proactive-alerting',
                  },
                );
              }}
              />
              <div className={styles.title}>
                <h1 style={{ marginTop: '5px' }} className="h2">{pageName}</h1>
                <UpdatedAtWithRefresh
                  setTriggerRefresh={setTriggerRefresh}
                />
              </div>
              <div className={styles.reactSelectContainer}>
                <div className={styles.reactSelect}>
                  <div>
                    <Select
                      onChange={handleSelectChange}
                      isMulti
                      options={selectOptionsValues}
                      placeholder="Alert tags:"
                      noOptionsMessage={() => 'No more tags'}
                      styles={colorSelectStyles}
                    />
                  </div>
                </div>
                <div />
              </div>
              <ReactTable
                data={(selectedOption?.length === 0 || selectedOption == null) ? tableData : sortedTableByTags}
                columns={[
                  {
                    Header: 'Alert rule name',
                    accessor: 'description',
                    Cell: row => <span title={row.value}>{row.value}</span>,
                    headerStyle,
                    style: {
                      textAlign: 'left',
                      alignSelf: 'center',
                    },
                  },
                  {
                    Header: 'Alert status',
                    accessor: 'data',
                    Cell: (props) => renderStatus(props),
                    headerStyle,
                    style: {
                      textAlign: 'left',
                      alignSelf: 'center',
                    },
                  },
                  {
                    Header: 'Alert threshold',
                    accessor: 'intervalDescription',
                    Cell: row => <span title={row.value}>{row.value}</span>,
                    headerStyle,
                    style: {
                      textAlign: 'left',
                      alignSelf: 'center',
                    },
                  },
                  {
                    Header: 'Alert tags',
                    id: 'tags',
                    accessor: 'tags',
                    Cell: props => (
                      <div>
                        {tagsGenerator(props)}
                        {
                          props.value.length && calculateTagsLength(props.value) > 2
                          && (
                          <span>
                            <span
                              role="presentation"
                              onClick={() => {
                                const tagData = tagsButtonGenerator(props);
                                setTagDataModal(tagData);
                                setSelectedCell(props.viewIndex);
                              }}
                              className={styles.tagsAndCounterWrapperSpan}
                            >{`+${calculateTagsLength(props.value) - 2}`}
                            </span>
                              {
                                selectedCell === props.viewIndex
                                && (
                                  <DashboardTotalViewTableModal
                                    visible={tagDataModal}
                                    content={tagDataModal}
                                    onClose={() => setTagDataModal(false)}
                                  />
                                )
                              }
                          </span>
                          )
                        }
                      </div>
                    ),
                    headerStyle,
                    style: {
                      textAlign: 'left',
                      alignSelf: 'center',
                    },
                  },
                ]}
                sortable={false}
                getTdProps={(state, rowInfo, column) => ({
                  onClick: (_e, handleOriginal) => {
                    if (column.id === 'tags') {
                      return;
                    }
                    history.push(`/dashboards/proactive-alerting/configured-dashboards/${dashboard}/${rowInfo.original.name}`);
                    if (handleOriginal) {
                      handleOriginal();
                    }
                  },
                })}
                showPaginationBottom={false}
                defaultPageSize={tableData.length > 10 ? 10 : tableData.length}
                pageSize={(selectedOption?.length && sortedTableByTags.length < 10)
                  ? sortedTableByTags?.length
                  : pageSize}
              />
              <div className={styles.bottomBlock}>
                <div>
                  {`Showing ${countMessage(selectedOption, sortedTableByTags, pageSize)} of ${tableData?.length}`}
                </div>
                {
                  (
                    (pageSize < sortedTableByTags.length && sortedTableByTags.length !== 0)
                    || (pageSize > sortedTableByTags.length && !selectedOption?.length && sortedTableByTags.length === 0 && pageSize !== tableData.length)
                  )
                  && (
                    <div className={styles.buttonBlock}>
                      <button
                        onClick={() => onShowMoreClick()}
                        className={styles.button}
                        type="button"
                      >Show more
                      </button>
                    </div>
                  )
                }
              </div>
            </div>
          )
          : <Spinner />
      }
    </>
  );
}

export default DashboardTotalViewTable;
