import React from 'react';
import { Switch, Route } from 'react-router';
import Shipment from './Shipment/Shipment';
import Shipments from './Shipments';

export default () => (
  <Switch>
    <Route path="/shipments/:id" component={Shipment} />
    <Route path="/shipments" component={Shipments} />
  </Switch>
);
