import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../../../helpers/API';
import Spinner from '../../../../../components/Spinner';
import TabErrorMessage from '../TabErrorMessage';
import SearchInput from '../SuppressionStatistics/searchInput';
import styles from './styles.scss';
import ActivityList from './activityList';
import { ShowMoreActivities } from './buttons';
import EmailStatisticsContext from '../../EmailStatisticsContext';

const pageSize = 10;

const ActivityStatistics = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isActivityError, setIsActivityError] = React.useState(false);
  const [activities, setActivities] = React.useState([]);
  const [filterEmail, setFilterEmail] = React.useState('');
  const [offset, setOffset] = React.useState(0);
  const [isDisabled, setShowMoreIsDisabled] = React.useState(false);
  const [isHidden, setShowMoreIsHidden] = React.useState(false);
  const { setPageTitle, setPageMessage, setSelectedServerId } = useContext(EmailStatisticsContext);

  setTimeout(() => {
    setSelectedServerId(id);
    setPageTitle('Activity');
    setPageMessage('This page contains the email events. Filter by email address and show more to reveal more events.');
  });

  useEffect(() => {
    const fetchSuppressions = async () => {
      try {
        setShowMoreIsDisabled(false);
        setShowMoreIsHidden(false);
        setIsLoading(true);
        setIsActivityError(false);
        const response = await API.getEmailActivity(
          id,
          pageSize,
          0,
          filterEmail,
        );
        setActivities(response.activities);
        if (response.total < pageSize) {
          setShowMoreIsHidden(true);
        }
      } catch (e) {
        setIsActivityError(true);
      }
      setIsLoading(false);
    };
    fetchSuppressions();
  }, [id, filterEmail]);

  const handleConfirmValue = (value) => {
    setOffset(0);
    setFilterEmail(value);
  };

  const showMore = async () => {
    setShowMoreIsDisabled(true);
    const calcOffset = offset + pageSize;
    const response = await API.getEmailActivity(
      id,
      pageSize,
      calcOffset,
      filterEmail,
    );
    setActivities(activities.concat(response.activities));
    setShowMoreIsDisabled(false);
    if (response.total < (pageSize + calcOffset)) {
      setShowMoreIsHidden(true);
    }
    setOffset(calcOffset);
  };

  return (
    <>
      {!isLoading && isActivityError && <TabErrorMessage />}
      <div className={styles.filterBar}>
        <div className={styles.filters}>
          <SearchInput confirmValue={handleConfirmValue} />
        </div>
      </div>
      {isLoading && !isActivityError ? (
        <Spinner />
      ) : (
        <>
          {activities && activities.length > 0 && (
            <>
              <ActivityList id="activityList" loading={isLoading} activities={activities} />
              <ShowMoreActivities onClick={showMore} disabled={isDisabled} hidden={isHidden} />
            </>
          )}
          {activities && activities.length === 0 && (
            <div>
              <p>There were no activities found based on filters applied</p>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ActivityStatistics;
