import React, { PureComponent } from 'react';
import cx from 'classnames';
import * as PropTypes from 'prop-types';
import styles from './styles.scss';

export const showErrorStrategies = {
  always: 'always',
  dirty: 'dirty',
  touched: 'touched',
};

export const ValidationErrors = ({
  errors,
  name,
  label,
  value,
  reserveSpace,
}) => {
  if (errors
    && errors[name]
    && errors[name].length > 0) {
    return (
      <p
        key={errors[name][0]}
        className={styles.errorMessage}
      >
        {errors[name][0].replace('{0}', label)
          .replace('{1}', value)}
      </p>
    );
  }
  if (reserveSpace) {
    return <p className={styles.errorMessage}>&nbsp;</p>;
  }

  return '';
};

class InputWithValidation extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    errors: PropTypes.objectOf(PropTypes.any),
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    showErrorStrategy: PropTypes.oneOf(Object.values(showErrorStrategies)),
    innerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    autoComplete: PropTypes.bool,
    inputTag: PropTypes.node,
    reserveErrorSpace: PropTypes.bool,
    action: PropTypes.element,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    value: '',
    label: '',
    placeholder: '',
    type: 'text',
    onChange: undefined,
    onBlur: undefined,
    onKeyDown: undefined,
    errors: {},
    disabled: false,
    required: false,
    showErrorStrategy: showErrorStrategies.always,
    innerRef: undefined,
    autoComplete: undefined,
    inputTag: 'input',
    reserveErrorSpace: false,
    action: undefined,
    readOnly: false,
  };

  shouldErrorBeShown = () => {
    const {
      errors,
      name,
      dirty,
      touched,
      showErrorStrategy,
    } = this.props;
    const hasErrors = (errors && errors[name] && errors[name].length > 0);
    if (hasErrors) {
      if (showErrorStrategy === showErrorStrategies.touched) {
        return touched;
      }
      if (showErrorStrategy === showErrorStrategies.dirty) {
        return dirty;
      }
    }
    return hasErrors;
  };

  render() {
    const {
      label,
      placeholder,
      type,
      value,
      name,
      onChange,
      onKeyDown,
      onBlur,
      errors,
      disabled,
      required,
      innerRef,
      autoComplete,
      inputTag: InputTag,
      reserveErrorSpace,
      action,
      readOnly,
      tabIndex,
      ariaLabel,
    } = this.props;
    const isErrorsShown = this.shouldErrorBeShown();

    return (
      <div className={styles.inputField}>
        <div className={isErrorsShown && errors?.[name] ? styles.inputErrorContainer : styles.inputContainer}>
          {label && (
          <label
            className={cx(styles.loginFormLabel)}
          >
            {required && <span className={styles.asterisk}>*</span>} {label}
          </label>
          )}
          {(isErrorsShown || reserveErrorSpace) && (
            <ValidationErrors
              errors={errors}
              name={name}
              label={label}
              value={value}
              reserveSpace={reserveErrorSpace}
            />
          )}
          <InputTag
            placeholder={placeholder}
            type={type}
            value={value}
            name={name}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            disabled={disabled}
            className={cx({
              [styles.hasError]: isErrorsShown,
              [styles.inputWithAction]: Boolean(action),
              [styles.formInput]: true,
            })}
            ref={innerRef}
            autoComplete={autoComplete ? 'on' : 'off'}
            readOnly={readOnly}
            tabIndex={tabIndex}
            aria-label={ariaLabel}
          />
          {action && <div className={InputTag !== 'textarea' ? styles.inputAction : styles.inputActionTextArea}>{action}</div>}
        </div>
      </div>
    );
  }
}

export default InputWithValidation;
