/* eslint-disable max-len */
import React, { Component } from 'react';
import { Switch } from 'react-router';
import { connect } from 'react-redux';
import {
  getApiKeys as getApiKeysAction,
} from 'actions/apikeyActions';
import Spinner from 'components/Spinner';
import AuthRoute from 'routes/Auth/AuthRoute';
import resources from 'constants/resources';
import ApiKeysList from './ApiKeysList';
import EditApiKeyPage from './EditApiKeyPage';
import CreateApiKeyPage from './CreateApiKeyPage';

class ApiKeys extends Component {
  componentDidMount() {
    this.props.getApiKeys();
  }

  render() {
    // if its a cold start and we dont yet have any info about keys show loader so that
    // children componentDidMount didnt try to look into yet unexisting data
    if (!this.props.apiKeys.keys.length && this.props.apiKeys.loading) {
      return <Spinner />;
    }
    return (
      <Switch>
        <AuthRoute exact path="/settings/api-keys" component={ApiKeysList} resource={resources.apiKeys.read} />
        <AuthRoute exact path="/settings/api-keys/create" component={CreateApiKeyPage} resource={resources.apiKeys.edit} />
        <AuthRoute exact path="/settings/api-keys/:apiKeyId" component={EditApiKeyPage} resource={resources.apiKeys.edit} />
      </Switch>
    );
  }
}

const mapDispatchToProps = dispatch => ({ getApiKeys: () => dispatch(getApiKeysAction()) });

export default connect(state => ({ apiKeys: state.apiKeys }), mapDispatchToProps)(ApiKeys);
