import React, { useState } from 'react';
import {
  string,
} from 'prop-types';
import copy from 'copy-to-clipboard';
import { CSSTransition } from 'react-transition-group';
import IconButton from 'components/IconButton/IconButton';
import CopyToClipboardIcon from 'images/CopyToClipboard.svg';
import styles from './styles.scss';

const ClipboardCopy = ({
  value,
}) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    copy(value);
    setIsActive(true);
  };
  return (
    <div className={styles.container}>
      <input
        readOnly="readonly"
        value={value}
        className={styles.input}
      />
      <CSSTransition
        in={isActive}
        timeout={{ enter: 2000 }}
        classNames={{ enterActive: styles.isActive }}
        onEntered={() => setIsActive(false)}
      >
        <div className={styles.copy}>
          <IconButton onClick={() => handleClick()}>
            <CopyToClipboardIcon
              height="20"
              data-tip="Copy to clipboard"
            />
          </IconButton>
        </div>
      </CSSTransition>
    </div>
  );
};

ClipboardCopy.propTypes = {
  value: string.isRequired,
};

export default ClipboardCopy;
