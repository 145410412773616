/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import ReactTable from 'react-table';
import { format } from 'date-fns';
import resources from 'constants/resources';
import API from 'helpers/API';
import TablePaginator from 'components/TablePaginator';
import CustomTile from 'components/CustomTile';
import UpdatedAtWithRefresh from 'components/UpdatedAtWithRefresh/UpdatedAtWithRefresh';
import Spinner from 'components/Spinner';
import Alert from 'components/Alert';
import SortedLogo from 'images/SortedLoading.png';
import useMediaQuery from 'hooks/useMediaQuery';
import { hasPermission } from 'helpers/auth';
import EmailSender from './EmailSender';
import { getFirstDataString, getSecondDataString } from '../../Helpers/ConfiguredDashboard';
import { centrePosition, headerStyle } from './styles';

import { getColumnProps, IndependentToStateColumns } from './shipmentHelper';
import styles from './ShipmentList.scss';
import commonStyles from '../../Common/styles.scss';
import BackButton from '../../../../components/BackButton';
import ExportButton from '../../../../components/ExportButton';
import { exportProactiveAlertCsv } from '../../../../helpers/exportCsv';

const alertStatusInitialValue = {
  on: false,
  type: '',
  message: '',
};

function ShipmentList() {
  const [loading, setLoading] = useState(true);
  const [shipmentsResponse, setShipmentsResponse] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [allRowsSelected, setAllRowsSelected] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(200);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRowsNo, setSelectedRowsNo] = useState(0);
  const [alertStatus, setAlertStatus] = useState(alertStatusInitialValue);
  const [pages, setPages] = useState();
  const isLargeScreen = useMediaQuery('(min-width: 1598px)');
  const isSmallerScreen = useMediaQuery('(min-width: 968px)');
  const hasEditPermission = hasPermission(resources.bulkNotifications.edit);
  const { dashboard, alert } = useParams();
  const [alertData, setAlertData] = useState();
  const [title, setTitle] = useState();

  const history = useHistory();
  const searchQuery = ''; // Fix me!

  const getShipments = async ({
    dashboardName, alertName, currentPageToLoad, pageSizeToLoad,
  }) => {
    setLoading(true);
    try {
      const shipmentListObject = await API.getConfiguredDashboardAlertShipments({
        dashboardName,
        alertName,
        currentPage: currentPageToLoad,
        pageSize: pageSizeToLoad,
      });
      if (shipmentListObject) {
        setSelectedRowsNo(shipmentListObject.totalShipments);
      }
      if (shipmentListObject && shipmentListObject.shipments?.length > 0) {
        const formattedResponse = shipmentListObject.shipments
          .map(el => ({
            ...el,
            promisedDate: el.promisedDate ? format(el.promisedDate, 'DD MMM YYYY') : el.promisedDate,
            estimatedDeliveryDate: el.estimatedDeliveryDate ? format(el.estimatedDeliveryDate, 'DD MMM YYYY') : el.estimatedDeliveryDate,

          }));
        setTableData(formattedResponse);
        if ('lastPage' in shipmentListObject) {
          setPages(shipmentListObject.lastPage);
        }
        setShipmentsResponse(shipmentListObject);
      }
      setLoading(false);
    } catch (err) {
      const errorMessage = err?.text ? err?.text : 'An error occurred while fetching data, please try again!';
      console.log('Error: ', errorMessage); // error handling will be dealt as separate ticket
      setLoading(false);
    }
  };

  const handleExport = async () => {
    try {
      await exportProactiveAlertCsv(
        dashboard,
        alert,
        searchQuery,
      );
    } catch (error) {
      const errorMessage = error?.text ? error?.text : 'An error occurred while exporting data, please try again!';
      console.log('Error: ', errorMessage);
    }
  };

  const getAlertData = async () => {
    try {
      const dashboardObject = await API.getConfiguredDashboardAlertData(dashboard, '');
      if (dashboardObject !== null) {
        const alertDataToSet = dashboardObject.dashboard.alerts.find((element) => element.name === alert);
        setAlertData(alertDataToSet);
        setTitle(alertDataToSet.description);
      }
    } catch (err) {
      const errorMessage = err?.text ? err?.text : 'An error occurred while fetching alert data, please try again!';
      console.log('Error: ', errorMessage);
    }
  };

  const columns = [
    {
      Header: (
        <div
          style={{
            ...centrePosition,
            padding: '0',
          }}
        >
          <input
            type="checkbox"
            disabled
            onChange={() => setAllRowsSelected(!allRowsSelected)}
            checked={allRowsSelected}
          />
        </div>
      ),
      headerStyle: {
        ...headerStyle,
        justifyContent: 'center',
      },
      Cell: () => (
        <div
          style={{
            padding: '0', ...centrePosition,
          }}
        >
          <input
            disabled
            type="checkbox"
            checked={allRowsSelected}
          />
        </div>
      ),
      style: {
        ...centrePosition, padding: '0',
      },
      maxWidth: 60,
      sortable: false,
      filterable: false,
    },
    ...IndependentToStateColumns(
      { smallerDesktop: isSmallerScreen, biggerDesktop: isLargeScreen },
    ),
  ];

  const handlePageSizeChange = (prevPageSize) => {
    setPageSize(prevPageSize);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage + 1);
  };

  const getMinRows = () => {
    if (pageSize > shipmentsResponse.totalShipments) {
      return shipmentsResponse.totalShipments;
    }
    return pageSize;
  };

  useEffect(() => {
    if (dashboard && alert) {
      getShipments({
        searchQuery,
        dashboardName: dashboard,
        alertName: alert,
        currentPageToLoad: currentPage,
        pageSizeToLoad: pageSize,
      });
      if (triggerRefresh) {
        setTriggerRefresh(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard, alert, currentPage, triggerRefresh, pageSize]);

  useEffect(() => {
    if (dashboard && alert) {
      getAlertData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard, alert]);

  return (
    <>
      <div>
        <BackButton onBack={() => {
          history.push(
            {
              pathname: `/dashboards/proactive-alerting/configured-dashboards/${dashboard}`,
            },
          );
        }}
        />
        <div className={styles.title}>
          <h1 className="h2" style={{ margin: 0 }}>{title}</h1>
          <UpdatedAtWithRefresh
            setTriggerRefresh={setTriggerRefresh}
          />
        </div>
        {alertStatus?.on && (
          <Alert
            message={alertStatus.message}
            type={alertStatus.type}
            alertControl={setAlertStatus}
          />
        )}
        <div style={
          {
            position: 'relative', marginBottom: '1rem',
          }
        }
        >
          {alertData
            && (
              <div className={`${commonStyles.cardsContainer} ${commonStyles.singleCard}`}>
                <CustomTile
                  tile={{
                    description: alertData.description,
                    subDescription1: alertData.calculatedPropertyDescription,
                    subDescription2: alertData.intervalDescription,
                    subDescription3: alertData.parameterDescription,
                    firstDataString: getFirstDataString(alertData.data),
                    secondDataString: getSecondDataString(alertData.data),
                    category: alertData.category,
                    trimLength: 68,
                  }}
                />
              </div>
            )}
          <div style={{ position: 'absolute', right: 0, top: 0 }}>
            <ExportButton onClick={handleExport} />
          </div>
        </div>
        {hasEditPermission && shipmentsResponse?.shipments?.length > 0 && (
          <div style={{ marginBottom: '10px' }}>
            <EmailSender
              modalOpen={openModal}
              modalTrigger={setOpenModal}
              dashboard={dashboard}
              tile={alert}
              numberOfFieldsSelected={selectedRowsNo}
              alertControl={setAlertStatus}
            />
          </div>
        )}
        {loading ? <Spinner />
          : (
            <div className={styles.listWrapper}>
              {shipmentsResponse && shipmentsResponse.shipments?.length > 0
                ? (
                  <ReactTable
                    data={tableData}
                    columns={columns}
                    page={currentPage - 1}
                    onPageChange={(e) => handlePageChange(e)}
                    pages={pages}
                    pageSize={pageSize}
                    showPageSizeOptions={false}
                    pageSizeOptions={[20, 50, 100, 200]}
                    minRows={getMinRows()}
                    defaultPageSize={200}
                    onPageSizeChange={(e) => handlePageSizeChange(e)}
                    sortable={false}
                    loading={loading}
                    PaginationComponent={TablePaginator}
                    totalRows={shipmentsResponse.totalShipments}
                    footerText="shipments"
                    manual
                    getColumnProps={getColumnProps}
                    trackingComponentName="Monitored Shipments Table"
                    getTrProps={(rowState, rowInfo) => ({
                      onClick: () => {
                        history.push(`/shipments/${rowInfo.original.id}`);
                      },
                    })}
                  />
                )
                : shipmentsResponse && (
                  <div className={styles.noRecords}>
                    <div>
                      <img src={SortedLogo} alt="Sorted-Loading..." style={{ width: '20em' }} />
                    </div>
                    <h4>There are currently no shipments to display</h4>
                    <h5 style={{ fontSize: '1.2em', marginTop: '0px' }}>Shipments will only appear here when they meet the
                      specific alert conditions
                    </h5>
                  </div>
                )}
            </div>
          )}
      </div>
    </>
  );
}

export default withRouter(ShipmentList);
