import React, { Component } from 'react';
import { connect } from 'react-redux';
import API from 'helpers/API';
import StatePicker from 'components/StatePicker';
import DualButtons from 'components/DualButtons';
import {
  getAvailableShipmentStates as getAvailableShipmentStatesAction,
  updateShipmentFilter as updateShipmentFilterAction,
  createShipmentFilter as createShipmentFilterAction,
  deleteShipmentFilter as deleteShipmentFilterAction,
} from 'actions/shipmentFiltersActions';
import BackButton from 'components/BackButton';
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from 'react-tabs';
import cx from 'classnames';
import List from 'components/List';
import ListItem from 'components/List/ListItem';
import Hr from 'components/CardShell/Hr';
import Header from 'components/CardShell/Header';
import MultiSelectedSvg from 'images/multiSelected.svg';
import MultiUnselectedSvg from 'images/multiUnselected.svg';
import styles from './styles.scss';

class ShipmentStates extends Component {
  handleCalculatedEventSelect = (e) => {
    this.props.updateState({ calculated_events: e.map(i => this.props.availableCalculatedEvents[i].id) });
  };

  handleSelectAllEventsClick = () => {
    this.props.updateState({ calculated_events: this.props.availableCalculatedEvents.map(e => e.id) });
  };

  handleUnselectAllEventsClick = () => {
    this.props.updateState({ calculated_events: [] });
  };

  handleSectionChange = (page) => {
    this.props.gotoPage(page);
  };

  render() {
    const {
      shipmentFilterTitle,
      formData,
      formData: { calculated_events: calcEventIds },
      pageTabs,
      supportedStates,
      updateState,
      cancel,
      availableCalculatedEvents,
      applyCarrierStateFilter,
    } = this.props;
    return (
      <div className={styles.cardsection}>
        <div className={styles.cardSectionInner}>
          <Header>{shipmentFilterTitle}</Header>
          <Hr />
          <div className={styles.buttonBack}>
            <BackButton onBack={() => this.handleSectionChange(1)} />
          </div>
          {pageTabs()}
          <p className={styles.numberSection}>
            Choose the shipment states, calculated events or both that you want to be notified about.
            You must select at least one.
          </p>
          {supportedStates && (
            <Tabs>
              <TabList className={cx(styles.tabsContainer)}>
                <Tab className={styles.tab} selectedClassName={styles.selectedTab}>Shipment states</Tab>
                <Tab className={styles.tab} selectedClassName={styles.selectedTab}>Calculated events</Tab>
              </TabList>
              <TabPanel>
                <StatePicker
                  updateState={updateState}
                  formData={formData}
                  states={supportedStates}
                  applyCarrierStateFilter={applyCarrierStateFilter}
                />
              </TabPanel>
              <TabPanel>
                <div className={styles.actionsContainer}>
                  {availableCalculatedEvents.length === 0 || calcEventIds.length !== availableCalculatedEvents.length
                    ? (
                      <button
                        type="button"
                        className={cx(styles.action, 'button-outline')}
                        onClick={this.handleSelectAllEventsClick}
                      >
                        Select all
                      </button>
                    )
                    : (
                      <button
                        type="button"
                        className={cx(styles.action, 'button-outline')}
                        onClick={this.handleUnselectAllEventsClick}
                      >
                        Unselect all
                      </button>
                    )}
                </div>
                <List
                  className={styles.list}
                  isSelectable
                  isMultiSelect
                  selectedIndexes={calcEventIds.map(id => availableCalculatedEvents.findIndex(g => g.id === id))}
                  onSelectionChange={this.handleCalculatedEventSelect}
                >
                  {availableCalculatedEvents.map(calcEvent => (
                    <ListItem key={calcEvent.id} selectedItemClassName={styles.selectedCalculatedEvent}>
                      <div className={styles.listItem}>
                        <span className={styles.listItemTitle}>{calcEvent.name}</span>
                        <MultiSelectedSvg className={cx(styles.selectIcon, styles.selectedIcon)} />
                        <MultiUnselectedSvg className={cx(styles.selectIcon, styles.unselectedIcon)} />
                      </div>
                    </ListItem>
                  ))}
                </List>
              </TabPanel>
            </Tabs>
          )}
        </div>
        <DualButtons
          leftFunction={cancel}
          leftText="Cancel"
          rightFunction={() => this.handleSectionChange(3)}
          rightText="Next"
          theme="rightPink"
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAvailableShipmentStates: () => dispatch(getAvailableShipmentStatesAction()),
  getShipmentFilterFromRoute: ownProps.match
    && ownProps.match.params && API.getShipmentFilter(ownProps.match.params.id),
  updateShipmentFilter: (id, shipmentFilter) => dispatch(
    updateShipmentFilterAction(id, shipmentFilter),
  ),
  createShipmentFilter: shipmentFilter => dispatch(createShipmentFilterAction(shipmentFilter)),
  deleteShipmentFilter: id => dispatch(deleteShipmentFilterAction(id)),
});

export default connect(state => ({
  groups: state.shipmentFilters.groups,
  availableCalculatedEvents: state.availableCalculatedEvents.values,
}), mapDispatchToProps)(ShipmentStates);
