import React from 'react';
import { format } from 'date-fns';
import Modal from '../../../../../components/Modal';
import styles from './styles.scss';
import Button from '../../../../../components/ReactButton';
import API from '../../../../../helpers/API';
import Spinner from '../../../../../components/Spinner';
import TabErrorMessage from '../TabErrorMessage';

const BounceDetails = (props) => {
  const [open, setOpen] = React.useState(false);
  const [isBounceDetailsError, setIsBounceDetailsError] = React.useState(false);
  const [bounceDetails, setBounceDetails] = React.useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoadingBounceDetails, setIsLoadingBounceDetails] = React.useState(false);

  const prettyDate = (date) => (date ? format(date, 'DD MMM YYYY HH:mm:ss') : '');

  const HandleMoreDetailsClick = async (sinkId, bounceId) => {
    handleOpen();
    setIsBounceDetailsError(false);
    setIsLoadingBounceDetails(true);
    try {
      const response = await API.bounceDetails(sinkId, bounceId);
      setBounceDetails(response);
    } catch (error) {
      setIsBounceDetailsError(true);
      setIsLoadingBounceDetails(false);
    }
    setIsLoadingBounceDetails(false);
  };

  return (
    <div className={styles.timelineContainer}>
      <div>
        <Button
          className={styles.moreDetails}
          theme="white"
          onClick={() => HandleMoreDetailsClick(props.eventDetail.sinkConfigurationId, props.eventDetail.bounceId)}
        >
          More details
        </Button>
        <Modal
          open={open}
          setOpen={handleClose}
          header="Bounce details"
          describedBy="test"
          actionText="Close"
          hideFooter="true"
          mainContent={(
            <div>
              {!isLoadingBounceDetails && isBounceDetailsError && <TabErrorMessage />}
              {!isLoadingBounceDetails && bounceDetails && (
                <div className={styles.modelContent}>
                  <div>
                    <span className={styles.label}>Type</span> <span data-testid="eventType" className={styles.value}>{bounceDetails.eventType}</span>
                  </div>
                  <div>
                    <span className={styles.label}>Date</span> <span data-testid="date" className={styles.value}> {prettyDate(bounceDetails.date)}</span>
                  </div>
                  <div>
                    <span className={styles.label}>Email</span> <span data-testid="email" className={styles.value}> {bounceDetails.email}</span>
                  </div>
                  <div>
                    <span className={styles.label}>Detail</span> <span data-testid="details" className={styles.value}> {bounceDetails.details}</span>
                  </div>
                  <div>
                    <span className={styles.label}>Description</span> <span data-testid="description" className={styles.value}>{bounceDetails.description}</span>
                  </div>
                  <div className={styles.detailContent}>
                    {bounceDetails.content}
                  </div>
                </div>
              )}
              {isLoadingBounceDetails && (
                <div><Spinner /></div>
              )}
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default BounceDetails;
