import React, { Component } from 'react';
import propTypes from 'prop-types';
import cx from 'classnames';
import styles from './styles.scss';

class List extends Component {
  static propTypes = {
    selectedIndexes: propTypes.arrayOf(propTypes.number),
    onSelectionChange: propTypes.func,
    className: propTypes.string,
    isSelectable: propTypes.bool,
    isMultiSelect: propTypes.bool,
    children: propTypes.arrayOf(propTypes.node),
  };

  static defaultProps = {
    selectedIndexes: [],
    onSelectionChange: () => {
    },
    className: null,
    isSelectable: false,
    isMultiSelect: false,
    children: [],
  };

  handleItemClick = (item) => {
    const {
      selectedIndexes,
      onSelectionChange,
      isSelectable,
      isMultiSelect,
    } = this.props;

    if (!isSelectable) {
      return;
    }
    const { index } = item.props;
    let newSelectedIndexes;
    if (selectedIndexes.includes(index)) {
      newSelectedIndexes = selectedIndexes.filter(x => x !== index);
    } else {
      newSelectedIndexes = isMultiSelect ? [...selectedIndexes, index] : [index];
    }
    onSelectionChange(newSelectedIndexes.filter(x => x !== -1));
  };

  render() {
    const {
      className,
      children,
      selectedIndexes,
      isSelectable,
    } = this.props;
    return (
      <ul className={cx(styles.list, className)}>
        {children.map((c, i) => React.cloneElement(
          c,
          {
            index: i,
            onClick: this.handleItemClick,
            isSelected: selectedIndexes.includes(i),
            isSelectable,
          },
        ))}
      </ul>
    );
  }
}

export default List;
