export const EMAIL_SENDER_MODAL_HEADER = 'Send email';
export const EMAIL_SENDER_MODAL_SUB_HEADER = 'To send an email to all consumers in the list, select the appropriate email connector and email template';
export const EMAIL_SENDER_MODAL_ACTION_TEXT = 'Send email';
export const EMAIL_SENDER_MODAL_CANCEL_TEXT = 'Cancel';
export const EMAIL_SENDER_MODAL_LABEL = 'Bulk email sender';
export const EMAIL_SENDER_MODAL_DESCRIBED_BY = 'Sending Bulk emails to affected customers by a certain rule';

export const EMAIL_SENDER_MODAL_FORM_CONNECTOR = 'Email connector';
export const EMAIL_SENDER_MODAL_FORM_TEMPLATE = 'Email template';
export const EMAIL_SENDER_ERROR_CONNECTOR = 'You must select an email connector';
export const EMAIL_SENDER_ERROR_TEMPLATE = 'You must select an email template';

export const CONFIRMATION_MODAL_HEADER = 'Send email?';
export const CONFIRMATION_MODAL_SUB_HEADER = 'Sending this email will result in an email being sent to all consumers whose shipments are currently in this list.';
export const CONFIRMATION_MODAL_ACTION_TEXT = 'Yes, send email';
export const CONFIRMATION_MODAL_CANCEL_TEXT = 'No, don\'t send email';
export const CONFIRMATION_MODAL_LABEL = 'Bulk email confirmation';
export const CONFIRMATION_MODAL_DESCRIBED_BY = 'To confirm if you are happy to send bulk emails';
