/* eslint-disable max-len */
import React from 'react';
import { Switch } from 'react-router';
import EditNotification from 'routes/Settings/Notifications/NotificationSetup/EditNotification';
import AuthRoute from 'routes/Auth/AuthRoute';
import resources from 'constants/resources';
import NotificationCentre from './NotificationCentre';
import NotificationConnectorsList from './NotificationConnectors/NotificationConnectorsList';
import CreateNotificationConfiguration from './NotificationConnectors/CreateNotificationConfiguration';
import EditNotificationConfiguration from './NotificationConnectors/EditNotificationConfiguration';
import NotificationSetup from './NotificationSetup/NotificationSetup';
import CreateNotification from './NotificationSetup/CreateNotification';
import TemplatesList from './NotificationTemplates/TemplatesList';
import CreateTemplate from './NotificationTemplates/CreateTemplate';
import EditTemplate from './NotificationTemplates/EditTemplate';

const Notifications = () => (
  <Switch>
    <AuthRoute exact path="/settings/notification-centre/" component={NotificationCentre} resource={resources.notificationCentre.read} />
    <AuthRoute exact path="/settings/notification-centre/notification-connectors/" component={NotificationConnectorsList} resource={resources.notificationCentre.read} />
    <AuthRoute exact path="/settings/notification-centre/notification-configuration/create/:notificationSinkId" component={CreateNotificationConfiguration} resource={resources.notificationCentre.edit} />
    <AuthRoute exact path="/settings/notification-centre/notification-configuration/edit/:configurationId" component={EditNotificationConfiguration} resource={resources.notificationCentre.edit} />
    <AuthRoute exact path="/settings/notification-centre/notification-connectors/:configurationId/templates/:templateId" component={EditTemplate} resource={resources.notificationCentre.edit} />
    <AuthRoute exact path="/settings/notification-centre/notification-setup" component={NotificationSetup} resource={resources.notificationCentre.read} />
    <AuthRoute exact path="/settings/notification-centre/notifications/create" component={CreateNotification} resource={resources.notificationCentre.edit} />
    <AuthRoute exact path="/settings/notification-centre/notifications/edit/:notificationId" component={EditNotification} resource={resources.notificationCentre.edit} />
    <AuthRoute exact path="/settings/notification-centre/templates/" component={TemplatesList} resource={resources.notificationCentre.read} />
    <AuthRoute exact path="/settings/notification-centre/templates/create" component={CreateTemplate} resource={resources.notificationCentre.edit} />
  </Switch>
);

export default Notifications;
