import React, { Component } from 'react';
import CardShell from 'components/CardShell';
import Hr from 'components/CardShell/Hr';
import DualButtons from 'components/DualButtons';
import InputWithValidation from 'components/InputWithValidation';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
  closeCustomPopup as closeCustomPopupAction,
  showCustomPopup as showCustomPopupAction,
  showPopup as showPopupAction,
} from 'actions';
import GeneratedEmail from 'routes/Settings/RegisterShipmentWebhooks/AuthorisedEmails/AddEmail/GeneratedEmail';
import API from 'helpers/API';
import { isEmail, maxLength } from 'helpers/validate';
import { validateForm, getServerValidationErrors } from 'helpers/validateForm';
import Spinner from 'components/Spinner';
import styles from './AddEmail.scss';

class AddEmail extends Component {
  validationRules = {
    authorisedSenderEmailAddress: [maxLength(100), isEmail()],
  };

  constructor(props) {
    super(props);

    this.state = {
      authorisedEmailData: {
        isActive: false,
        authorisedSenderEmailAddress: null,
      },
      isAdding: false,
      errors: {},
    };
  }

  backToMenu = () => {
    this.props.history.push('/settings/register-shipment-webhooks/authorised-emails/');
  };

  submitCreate = async () => {
    const clientErrors = validateForm(this.validationRules, this.state.authorisedEmailData);
    if (clientErrors.hasErrors) {
      this.setState({ errors: clientErrors });
      return;
    }
    try {
      this.setState({ isAdding: true });

      const result = await API.createAuthorisedEmails({
        authorisedSenderEmailAddress: this.state.authorisedEmailData.authorisedSenderEmailAddress,
        isActive: this.state.authorisedEmailData.isActive,
      });
      this.props.showCustomPopup(<GeneratedEmail onClose={this.closePopup} value={result.incomingEmailAddress} />);
    } catch (response) {
      if (response.status === 400) {
        const serverErrors = getServerValidationErrors(response);
        this.setState({ errors: serverErrors });
      } else {
        this.props.showPopup({
          title: 'Error',
          description: 'We couldn\'t update your authorised email.',
        });
      }
    } finally {
      this.setState({ isAdding: false });
    }
  };

  closePopup = () => {
    this.props.closeCustomPopup();
    this.backToMenu();
  };

  handleAuthorisedEmailInputChange = (e) => {
    const authorisedSenderEmailAddressValue = e.target.value;
    return this.setState(state => ({
      authorisedEmailData: { ...state.authorisedEmailData, authorisedSenderEmailAddress: authorisedSenderEmailAddressValue },
    }));
  };

  handleIsActiveInputChange = (e) => {
    const isActiveStatus = e.target.checked;
    return this.setState(state => ({
      authorisedEmailData: { ...state.authorisedEmailData, isActive: isActiveStatus },
    }));
  };

  render() {
    return (
      <div>
        {this.state.isAdding && <Spinner />}
        <h2>Add authorised email</h2>
        <div className="container__cards-large">
          <CardShell>
            <div className={styles.container}>
              <div className={styles.createEmailWrapper}>
                <Hr />
                <p>Enter an email address you will use to register shipments.</p>
                <InputWithValidation
                  label="Authorised email address"
                  placeholder="Authorised email address"
                  type="email"
                  value={this.state.authorisedEmailData.authorisedSenderEmailAddress}
                  name="authorisedSenderEmailAddress"
                  onChange={this.handleAuthorisedEmailInputChange}
                  errors={this.state.errors}
                />
              </div>
              <div className={styles.formItem}>
                <label>
                  <input
                    name="isActive"
                    type="checkbox"
                    checked={this.state.authorisedEmailData.isActive}
                    onChange={this.handleIsActiveInputChange}
                  />
                  <span className={styles.checkBoxLabel}>Make this active</span>
                </label>
              </div>
            </div>
            <DualButtons
              leftFunction={this.backToMenu}
              leftText="Cancel"
              rightFunction={this.submitCreate}
              rightText="Add"
              theme="rightPink"
            />
          </CardShell>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showCustomPopup: (component, onClosed) => dispatch(showCustomPopupAction(component, onClosed)),
  closeCustomPopup: () => dispatch(closeCustomPopupAction()),
  showPopup: config => dispatch(showPopupAction(config)),
});

export default withRouter(connect(null, mapDispatchToProps)(AddEmail));
