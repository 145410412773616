import React, { Component } from 'react';
import resources from 'constants/resources';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { logout as logoutAction } from 'actions';
import Svg from 'components/Svg';
import RestrictedAccess from 'components/RestrictedAccess';
import { withRouter } from 'react-router';
import NavItem from './NavItem';
import SubNavItem from './SubNavItem';
import styles from './styles.scss';
import { getActiveProducts } from '../../../helpers/auth';
import ProDashboards from './ProDashboards';
import ReactDashboards from './ReactDashboards';

export class CMobileMenu extends Component {
  static propTypes = {
    location: propTypes.shape({
      pathname: propTypes.string.isRequired,
    }).isRequired,
    isOpen: propTypes.bool,
    logout: propTypes.func.isRequired,
    closeCallback: propTypes.func.isRequired,
  };

  static defaultProps = {
    isOpen: false,
  };

  // componentWillRecieveProps is deprecated and we need to fix this
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.props.closeCallback();
    }
  }

  render() {
    const { isOpen, closeCallback, logout } = this.props;

    const activeProducts = getActiveProducts();
    const showExplicitDashboardTitles = activeProducts.includes('PRO') && activeProducts.includes('REACT');

    return (
      <div
        className={styles.container}
        style={{
          opacity: isOpen ? 100 : 0,
          pointerEvents: isOpen ? 'all' : 'none',
          transform: isOpen ? 'scale(1)' : 'scale(0.9)',
        }}
      >
        <Svg name="cross" className={styles.close} onClick={closeCallback} role="button" />
        <div className={styles.itemsContainer}>
          {activeProducts.includes('PRO') && <ProDashboards title={showExplicitDashboardTitles ? 'PRO' : 'dashboards'} />}
          {activeProducts.includes('REACT') && <ReactDashboards title={showExplicitDashboardTitles ? 'REACT' : 'dashboards'} />}
          {/* TODO: To be placed back once we have the email statistics in place */}
          <NavItem title="Reporting" url="reporting">
            <SubNavItem baseUrl="reporting" urlRename="email-statistics">
              Email
            </SubNavItem>
            <SubNavItem baseUrl="reporting" urlRename="smart-filters">
              Smart Filters
            </SubNavItem>
          </NavItem>
          <RestrictedAccess resource={[
            resources.shipmentFilters.read,
            resources.sftpAccount.read,
            resources.shipmentFilters.read,
            resources.webhooks.read,
            resources.carrierConnectors.read,
            resources.customStateLabels.read,
            resources.trackingPages.read,
            resources.notificationCentre.read,
            resources.emailShipmentRegistration.read,
          ]}
          >
            <NavItem title="settings" url="settings">
              <RestrictedAccess resource={resources.apiKeys.read}>
                <SubNavItem baseUrl="settings" urlRename="api-keys">
                  API Keys
                </SubNavItem>
              </RestrictedAccess>
              <RestrictedAccess resource={resources.sftpAccount.read}>
                <SubNavItem baseUrl="settings" urlRename="sftp-account">
                  SFTP Account
                </SubNavItem>
              </RestrictedAccess>
              <RestrictedAccess resource={resources.emailShipmentRegistration.read}>
                <SubNavItem baseUrl="settings" urlRename="register-shipment-webhooks">
                  Shipments By Email
                </SubNavItem>
              </RestrictedAccess>
              <RestrictedAccess resource={resources.shipmentFilters.read}>
                <SubNavItem baseUrl="settings" urlRename="shipment-filters">
                  Shipment Filters
                </SubNavItem>
              </RestrictedAccess>
              <SubNavItem baseUrl="reporting" urlRename="smart-filters">
                Smart Filters
              </SubNavItem>
              <RestrictedAccess resource={resources.webhooks.read}>
                <SubNavItem baseUrl="settings">
                  Webhooks
                </SubNavItem>
              </RestrictedAccess>
              <RestrictedAccess resource={resources.carrierConnectors.read}>
                <SubNavItem baseUrl="settings" urlRename="carrier-connectors">
                  Carrier Connectors
                </SubNavItem>
              </RestrictedAccess>
              <RestrictedAccess resource={resources.customStateLabels.read}>
                <SubNavItem baseUrl="settings" urlRename="custom-state-labels">
                  Custom State Labels
                </SubNavItem>
              </RestrictedAccess>
              <RestrictedAccess resource={resources.trackingPages.read}>
                <SubNavItem baseUrl="settings" urlRename="tracking-pages">
                  Tracking Pages
                </SubNavItem>
              </RestrictedAccess>
              <RestrictedAccess resource={resources.notificationCentre.read}>
                <SubNavItem baseUrl="settings" urlRename="notification-centre">
                  Notification Centre
                </SubNavItem>
              </RestrictedAccess>
              <RestrictedAccess resource={resources.shipmentMetadataGroupings.edit}>
                <SubNavItem baseUrl="settings" urlRename="shipment-metadata-groupings">
                  Shipment Groupings
                </SubNavItem>
              </RestrictedAccess>
            </NavItem>
          </RestrictedAccess>
          <NavItem title="Help &amp; Support">
            <SubNavItem external="https://docs.sorted.com/react/">
              REACTDocs
            </SubNavItem>
          </NavItem>
          <NavItem title="account" url="account">
            <RestrictedAccess resource={resources.accountUsers.readUsers}>
              <SubNavItem baseUrl="account" urlRename="users">
                Manage Users
              </SubNavItem>
            </RestrictedAccess>
            <SubNavItem baseUrl="logout" onClick={logout}>
              Log Out
            </SubNavItem>
          </NavItem>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({ logout: () => dispatch(logoutAction()) });

export default withRouter(connect((state, { location }) => ({ location }), mapDispatchToProps)(CMobileMenu));
