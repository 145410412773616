import React from 'react';
import { components } from 'react-select';
import SelectWithValidation from '../../../../../components/SelectWithValidation';
import styles from './reasonDropdown.scss';
import reasons from './reasons';

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>Reason: {children}</components.SingleValue>
);

export default function ReasonDropdown({ setSelectedReason, selectedReason }) {
  const handlePeriodSelection = (e) => {
    if (e.value === 'All') {
      setSelectedReason(null);
      return;
    }

    setSelectedReason(e.value);
  };

  return (
    <div className={styles.reasonContainer}>
      <div className={styles.reason}>
        <SelectWithValidation
          value={selectedReason}
          id="reasons"
          name="reasons"
          onChange={(e) => handlePeriodSelection(e)}
          options={reasons.map((el) => ({
            value: el.key,
            label: el.value,
          }))}
          components={{ SingleValue }}
          defaultValue="All"
        />
      </div>
    </div>
  );
}
