import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  showPopClipboard as showPopClipboardAction,
} from 'actions';
import styles from './styles.scss';

const CustomPopup = ({ component, ...props }) => {
  if (!props.open) return null;

  return (
    <div className={styles.popUpWindow}>
      <Helmet>
        <body className={styles.disableScroll} />
      </Helmet>
      <div className={styles.popUpInner}>
        <div className={styles.messageWrapper}>
          <h3 className={styles.popUpTitle}>{props.title}</h3>
          <div>
            { component }
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ customPopup }) => ({
  secret: customPopup.keySecret,
});

const mapDispatchToProps = dispatch => ({
  closePopup: onClose => dispatch(showPopClipboardAction(onClose)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomPopup);
