import { maxLength, required } from 'helpers/validate';
import { validateNestedForm } from 'helpers/validateForm';

function getValidationRules(configurationType) {
  const rules = {
    name: [
      required(() => 'Notification name should not be empty.'),
      maxLength(100, () => 'The length of the Notification name should not exceed 100 characters.')],
    filterId: [required(() => 'A filter should be selected.')],
  };
  if (configurationType === 'sms') {
    rules.template = [
      required(() => 'SMS text should not be empty.'),
      maxLength(255, () => 'The length of the SMS text should not exceed 255 characters.')];
  } else if (configurationType === 'email') {
    rules.templateReference = [required(() => 'An email template should be selected.')];
  }
  return rules;
}

export default function validateNotification(notification, configurationType) {
  const validationRules = getValidationRules(configurationType);
  return validateNestedForm(notification, validationRules);
}
