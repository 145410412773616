import React from 'react';
import styles from './ErrorMessage.scss';

export default function ErrorMessage({ dateKey, customPeriods = null }) {
  let period = 'today';

  if (!customPeriods) {
    switch (dateKey) {
      case '1':
        period = 'in the last 12 months';
        break;
      case '2':
        period = 'in the last 6 months';
        break;
      case '3':
        period = 'in the last 30 days';
        break;
      case '4':
        period = 'in the last 7 days';
        break;
      default:
        break;
    }
  } else {
    const index = customPeriods.findIndex((el) => el.key === dateKey);
    period = customPeriods[index].periodStr;
  }

  return (
    <div className={styles.errorContainer}>
      <div className={styles.errorInner}>
        <div className={styles.errorInnerMessage}>There hasn&#39;t been any outbound activity {period}</div>
      </div>
    </div>
  );
}
