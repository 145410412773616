import errors from 'constants/errors';

const initialState = {
  isShipmentLoading: false,
  isEventsLoading: false,
  shipmentData: undefined,
  statesData: undefined,
  signee: undefined,
  shipmentDataError: '',
  eventsDataError: '',
};

export default function shipmentDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case 'SHIPMENT_FETCH':
      return {
        ...state,
        isShipmentLoading: true,
        shipmentData: initialState.shipmentData,
        shipmentDataError: initialState.shipmentDataError,
      };
    case 'SHIPMENT_SUCCESS':
      return {
        ...state,
        isShipmentLoading: false,
        shipmentData: action.shipmentData,
      };
    case 'SHIPMENT_NOT_FOUND':
      return {
        ...state,
        isShipmentLoading: false,
        shipmentDataError: 'Such shipment doesn\'t exist',
      };
    case 'SHIPMENT_ERROR':
      return {
        ...state,
        isShipmentLoading: false,
        shipmentDataError: errors.shipments.shipmentFetchError,
      };
    case 'SHIPMENT_TRACKING_EVENTS_FETCH':
      return {
        ...state,
        isEventsLoading: true,
        statesData: initialState.statesData,
        signee: initialState.signee,
        eventsDataError: initialState.eventsDataError,
      };
    case 'SHIPMENT_TRACKING_EVENTS_SUCCESS':
      return {
        ...state,
        isEventsLoading: false,
        statesData: action.states,
        signee: action.signee,
      };
    case 'SHIPMENT_TRACKING_EVENTS_ERROR':
      return {
        ...state,
        isEventsLoading: false,
        eventsDataError: errors.trackingEvents.shipmentTrackingEventsFetchError,
      };
    case 'SHIPMENT_TRACKING_EVENTS_NOT_FOUND':
      return {
        ...state,
        isEventsLoading: false,
        eventsDataError: errors.trackingEvents.shipmentTrackingEventsNotFound,
      };
    default:
      return state;
  }
}
