import React, { useRef } from 'react';
import Svg from 'components/Svg';
import styles from './searchInput.scss';

export default function SearchInput({ confirmValue }) {
  const [searchValue, setSearchValue] = React.useState('');
  const searchInput = useRef(null);
  const handleKeyPress = (key, value) => {
    if (key === 'Enter') {
      confirmValue(value);
    }
  };

  const handleClick = () => {
    confirmValue(searchInput.current.value);
  };

  return (
    <div className={styles.outerbox}>
      <input
        className={styles.searchInput}
        placeholder="Search by email address"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        onKeyPress={(e) => handleKeyPress(e.key, e.target.value)}
        ref={searchInput}
      />
      <Svg name="searchGlass" className={styles.icon} onClick={handleClick} />
    </div>
  );
}
