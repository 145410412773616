import { generateRandomNumber } from 'helpers/guid';

function key() {
  // Generate a random integer that is less then Number.MAX_SAFE_INTEGER
  return String(Math.trunc(generateRandomNumber() * 1000000000000000));
}

export default {
  key,
};
