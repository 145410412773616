import React, { useEffect, useState } from 'react';
import API from 'helpers/API';
import Spinner from 'components/Spinner';
import CardShell from 'components/CardShell';
import MultiPageForm from 'components/MultiPageForm';
import DualButtons from 'components/DualButtons';
import BackButton from 'components/BackButton';
import { isNavagationValid } from 'helpers/navigationValidators';
import validateNotification from 'routes/Settings/Notifications/NotificationSetup/validateNotification';
import Header from 'components/CardShell/Header';
import Hr from 'components/CardShell/Hr';
import ConfigurationSelector from './ConfigurationSelector';
import NotificationValues from './NotificationValues';
import Popup from '../../../../components/Layout/Popup';
import styles from '../styles.scss';
import typography from '../../../../helpers/appTypography';
import TitleNavBlock from '../../../../components/TitleNavBlock/TitleNavBlock';

export default function CreateNotification({ history }) {
  const [configurations, setConfigurations] = useState([]);
  const [notification, setNotification] = useState({});
  const [page, setPage] = useState(0);
  const [popup, setPopup] = useState({});
  const [configLoading, setConfigLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const navigationStepValidators = [
    () => notification.sinkConfigurationId,
    () => true,
  ];

  useEffect(() => {
    const fetchData = async () => {
      setConfigLoading(true);
      const configurationsResponse = await API.getSinkConfigurations();

      setConfigurations(configurationsResponse.configurations);
      setConfigLoading(false);
    };

    fetchData();
  }, []);

  const gotoPage = (newPage) => {
    if (isNavagationValid(page, newPage, navigationStepValidators)) {
      setPage(newPage);
    }
  };

  const goBack = () => {
    setNotification({});
    gotoPage(0);
  };

  if (configLoading) {
    return (
      <Spinner />
    );
  }
  const selectedConfiguration = configurations.find(x => x.id === notification.sinkConfigurationId);
  const errors = validateNotification(notification, selectedConfiguration && selectedConfiguration.type);
  const displayErrors = showErrors ? errors : null;

  const saveNotification = async () => {
    if (Object.values(errors).length > 0) {
      setShowErrors(true);
      return;
    }

    setSaving(true);
    try {
      await API.createNotification(notification);
    } finally {
      setSaving(false);
    }
    setPopup({
      open: true,
      title: 'Success',
      description: <span>Notification created.</span>,
      onDecline: () => {
        history.push('/settings/notification-centre/notification-setup');
      },
    });
  };

  return (
    <div>
      <Popup
        open={popup.open}
        title={popup.title}
        description={popup.description}
        onAccept={popup.onAccept}
        onDecline={popup.onDecline}
      />
      <TitleNavBlock
        title={typography.createNotification.title}
        subTitle={typography.createNotification.subTitle}
        onBack={() => window.history.back()}
      />
      {configurations ? (
        <div className="container__cards-large">
          <MultiPageForm
            page={page}
            goToPage={gotoPage}
            formPages={() => [
              <CardShell>
                <div className={styles.container}>
                  <div className={styles.cardSectionInner}>
                    <Header>Step 1</Header>
                    <Hr />
                    <p>Please choose which email or sms to set up</p>
                    {configurations.map(configuration => (
                      <ConfigurationSelector
                        key={configuration.id}
                        selected={() => setNotification(x => ({
                          ...x,
                          sinkConfigurationId: configuration.id,
                        }))}
                        configuration={configuration}
                        highlighted={notification.sinkConfigurationId === configuration.id}
                      />
                    ))}
                  </div>
                  <DualButtons
                    leftFunction={() => history.push('/settings/notification-centre/notification-setup')}
                    leftText="Cancel"
                    rightFunction={() => gotoPage(1)}
                    rightText="Next"
                  />
                </div>
              </CardShell>,
              <CardShell noOverflow>
                <div className={styles.container}>
                  <div className={styles.cardSectionInner}>
                    <Header>Step 2</Header>
                    <Hr />
                    <div className={styles.formItem}>
                      <BackButton onBack={goBack} />
                    </div>
                    <NotificationValues
                      configurationType={selectedConfiguration ? selectedConfiguration.type : null}
                      notification={notification}
                      setNotification={setNotification}
                      errors={displayErrors}
                      history={history}
                    />
                  </div>
                  <DualButtons
                    leftFunction={() => history.push('/settings/notification-centre/notification-setup')}
                    leftText="Cancel"
                    rightFunction={() => saveNotification()}
                    rightFunctionDisabled={saving}
                    rightText="Save"
                    theme="rightPink"
                  />
                </div>
              </CardShell>,
            ]}
          />
        </div>
      ) : (<p>No configurations found</p>)}
    </div>
  );
}
