import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getEditConfiguration as getEditConfigurationAction,
} from 'actions';
import CardShell from 'components/CardShell';
import Spinner from 'components/Spinner';
import EditConfiguration from './EditConfiguration';

class EditCarrierConnector extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getEditConfiguration(id);
  }

  render() {
    if (!this.props.editConfiguration.configuration) {
      return <Spinner />;
    }

    return (
      <div>
        <h2>Edit Configuration</h2>
        <div className="container__cards-large">
          <CardShell noOverflow>
            <EditConfiguration />
          </CardShell>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getEditConfiguration: id => dispatch(getEditConfigurationAction(id)),
});

export default connect(state => ({
  editConfiguration: state.editConfiguration,
}), mapDispatchToProps)(EditCarrierConnector);
