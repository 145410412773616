import React, { useEffect, useReducer, useState } from 'react';
import API from 'helpers/API';
import Spinner from 'components/Spinner';
import CardShell from 'components/CardShell';
import BackButton from 'components/BackButton';
import cx from 'classnames';
import styles from './styles.scss';
import NotificationConfigurationCard from './NotificationConfigurationCard';
import NotificationConnectorCard from './NotificationConnectorCard';
import typography from '../../../../helpers/appTypography';

const configurationsReducer = (configurations, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'LOAD':
      return payload.configurations;
    case 'ACTIVATE':
      return configurations.map(configuration => (
        configuration.id === payload.id
          ? { ...configuration, isActive: true }
          : configuration));
    case 'DEACTIVATE':
      return configurations.map(configuration => (
        configuration.id === payload.id
          ? { ...configuration, isActive: false }
          : configuration));
    case 'DISCONNECT':
      return configurations.filter(configuration => configuration.id !== payload.id);
    default:
      return configurations;
  }
};

export default function NotificationConnectorsList({ history }) {
  const [connectors, setConnectors] = useState([]);
  const [configurations, dispatch] = useReducer(configurationsReducer, []);
  const [loading, setLoading] = useState(false);

  const callDispatch = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const configurationsResponse = await API.getSinkConfigurations();
      const connectorsResponse = await API.getNotificationSinks();

      callDispatch('LOAD', { configurations: configurationsResponse.configurations });
      setConnectors(connectorsResponse.sinks);
      setLoading(false);
    };

    fetchData();
  }, []);

  const toggleActive = async (id) => {
    const config = configurations.find(x => x.id === id);

    if (config.isActive) {
      await API.disableSinkConfiguration(id);
    } else {
      await API.enableSinkConfiguration(id);
    }

    callDispatch(config.isActive ? 'DEACTIVATE' : 'ACTIVATE', { id });
  };

  const disconnect = async (id) => {
    await API.disconnectSinkConfiguration(id);
    callDispatch('DISCONNECT', { id });
  };

  if (loading) {
    return (
      <Spinner />
    );
  }

  return (
    <div>
      <div>
        <BackButton onBack={() => window.history.back()} />
        <h1 className="h2" style={{ margin: 0 }}>{typography.notificationConnectors.title}</h1>
        <div style={{ margin: '0 0 20px 0' }}>{typography.notificationConnectors.subTitle}</div>
      </div>
      <div className={styles.outerWhiteContainer}>
        <div>
          {configurations?.length > 0 ? (
            <div>
              <h3 style={{ marginTop: 0 }}>Configured connectors</h3>
              <div className={cx('container__cards', styles.notificationConnectorsListAdditionalWrapper)}>
                {configurations.map(x => (
                  <CardShell key={x.id} noOverflow>
                    <NotificationConfigurationCard
                      key={x.id}
                      notificationConfiguration={x}
                      history={history}
                      toggleActive={toggleActive}
                      disconnect={disconnect}
                    />
                  </CardShell>
                ))}
              </div>
            </div>
          ) : (
            <p>No existing connectors found</p>
          )}
          <h3>Available connectors</h3>
          <div className="container__cards-large">
            {connectors?.length > 0 ? (
              connectors.map(x => (
                <CardShell key={x.id}>
                  <NotificationConnectorCard key={x.id} notificationConnector={x} history={history} />
                </CardShell>
              ))) : (
              // eslint-disable-next-line react/jsx-indent
              <p>No connectors found</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
