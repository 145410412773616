import React from 'react';
import { Base64 } from 'js-base64';
import cx from 'classnames';

import styles from './styles.scss';

class FilePicker extends React.PureComponent {
  static getImageExtension(base64) {
    const decoded = Base64.decode(base64).toLowerCase();
    let extension;

    if (decoded.indexOf('png') !== -1) {
      extension = 'png';
    } else if (decoded.indexOf('jpg') !== -1 || decoded.indexOf('jpeg') !== -1) {
      extension = 'jpg';
    } else {
      extension = 'tiff';
    }
    return extension;
  }

  handleDelete = () => {
    this.props.onChange(null);
  };

  handleChange(e) {
    const { files } = e.target;
    const {
      onChange,
    } = this.props;
    const allFiles = [];
    const reader = new FileReader();

    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];
      reader.readAsDataURL(file);

      reader.onload = () => {
        const fileInfo = {
          name: file.name,
          type: file.type,
          size: `${Math.round(file.size / 1000)} kB`,
          base64: reader.result,
          file,
        };

        allFiles.push(fileInfo);

        if (allFiles.length === files.length) {
          onChange(allFiles[0].base64);
        }
      };
    }
  }

  render() {
    const { value, imgPreviewBackground } = this.props;
    let base64 = null;
    if (value) {
      if (this.props.value.search(/^data:image\/[a-z]+;base64,/, '') >= 0) {
        base64 = value;
      } else {
        base64 = `data:image/${FilePicker.getImageExtension(value)};base64,${value}`;
      }
    }
    return (
      <div>
        <div className={styles.formFileInner}>
          {base64
            ? (
              <>
                <button
                  type="button"
                  onClick={this.handleDelete}
                  className={cx(styles.deleteButton, 'button-outline')}
                >
                  Delete
                </button>
                <div style={{ backgroundColor: imgPreviewBackground }}>
                  <img alt="img" className={styles.imagePreview} src={base64} />
                </div>
              </>
            )
            : (
              <>
                <span className={styles.formFileInnerText}>Upload</span>
                <input
                  className={styles.formFileInput}
                  type="file"
                  onChange={e => this.handleChange(e)}
                  multiple={this.props.multiple}
                  accept={this.props.accept}
                />
              </>
            )}
        </div>
      </div>
    );
  }
}

export default FilePicker;
