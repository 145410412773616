import React from 'react';
import { NavLink } from 'react-router-dom';
import propTypes from 'prop-types';
import styles from './styles.scss';

const SubNavItem = ({
  baseUrl,
  urlRename,
  external,
  children,
  onClick,
}) => {
  if (external) {
    return (
      <a
        className={styles.container}
        href={external}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }
  if (onClick) {
    return (
      <div className={styles.container} role="button" onClick={onClick}>
        {children}
      </div>
    );
  }
  return (
    <NavLink className={styles.container} to={`/${baseUrl}/${urlRename || children.toLowerCase()}`}>
      {children}
    </NavLink>
  );
};

SubNavItem.propTypes = {
  baseUrl: propTypes.string,
  external: propTypes.string,
  urlRename: propTypes.string,
  children: propTypes.node.isRequired,
  onClick: propTypes.oneOfType([
    propTypes.func,
    propTypes.bool,
  ]),
};

SubNavItem.defaultProps = {
  baseUrl: '',
  external: '',
  onClick: false,
  urlRename: '',
};

export default SubNavItem;
