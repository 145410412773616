const initialState = {
  name: '',
};

export default function createApiKeyReducer(state = initialState, action) {
  switch (action.type) {
    case 'CLEAR_INPUT':
      return {
        name: '',
      };
    case 'SET_INPUT_NAME':
      return {
        name: action.inputName,
      };
    default:
      return state;
  }
}
