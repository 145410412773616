import React from 'react';
import propTypes from 'prop-types';
import DropdownItem from '../../DropdownItem';
import NavItem from '../../NavItem';

const ProDashboards = ({
  expanded, currentUrl, title,
}) => (
  <NavItem title={title} url="dashboards" currentUrl={currentUrl} expanded={expanded}>
    <DropdownItem baseUrl="dashboards">
      NEW ITEM HERE!
    </DropdownItem>
    <DropdownItem baseUrl="dashboards" urlRename="shipments">
      NEW ITEM HERE!
    </DropdownItem>
    <DropdownItem baseUrl="dashboards" urlRename="calculated-events">
      NEW ITEM HERE!
    </DropdownItem>
  </NavItem>
);

ProDashboards.propTypes = {
  expanded: propTypes.bool.isRequired,
  currentUrl: propTypes.string.isRequired,
  title: propTypes.string.isRequired,
};

export default ProDashboards;
