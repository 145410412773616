import React, { Component } from 'react';
import cx from 'classnames';
import { generateId } from 'helpers/guid';
import PopupCentered from 'components/PopupCentered';
import CardShell from 'components/CardShell';
import DualButtons from 'components/DualButtons';
import { TopLeft } from 'constants/trackingPage/rowTextPositions';
import {
  SmallRowHeightType,
  MediumRowHeightType,
  LargeRowHeightType,
} from 'constants/trackingPage/rowHeightTypes';
import {
  TextComponentType,
} from 'constants/trackingPage/componentTypes';
import { StandardLayoutType } from 'constants/trackingPage/layoutTypes';
import Hr from 'components/CardShell/Hr';

import styles from '../../style.scss';

class GridAddRowPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: SmallRowHeightType,
      columnsCount: 1,
    };
  }

  updateState = (e) => {
    const target = e.target.getAttribute('name');
    const { value } = e.target;
    this.setState({ [target]: value });
  };

  updateStateByVal = (target, value) => this.setState({ [target]: value });

  save = () => {
    const {
      toggleAddRow,
      update,
    } = this.props;

    const rowItemPreset = {
      base64Image: null,
      componentType: TextComponentType,
      redirectionUrl: '',
      rowTextPosition: TopLeft,
      text: '',
      font: {},
      locale: '',
    };

    const newRow = {
      id: generateId(),
      rowHeight: this.state.height,
      rowItems: [],
    };

    for (let i = 0; i < this.state.columnsCount; i += 1) {
      const rowColumnWithId = {
        ...rowItemPreset,
        id: generateId(),
        order: i,
      };
      newRow.rowItems.push(rowColumnWithId);
    }

    update(newRow);
    toggleAddRow();
  };

  render() {
    const { layoutType, toggleAddRow } = this.props;
    const { height, columnsCount } = this.state;
    const previewRowItems = [];

    for (let i = 0; i < columnsCount; i += 1) previewRowItems.push({});

    return (
      <PopupCentered>
        <CardShell>
          <div className={styles.cardInner}>
            <h3 className={styles.title}>Add row</h3>
            <Hr />
            <div className={styles.popupGroup}>
              <div className={styles.formGroup}>
                <label className={styles.formLabel} htmlFor="nav_fontweight">Height</label>
                <select
                  id="nav_fontweight"
                  value={height}
                  className={styles.formElement}
                  onChange={this.updateState}
                  name="height"
                >
                  <option value={SmallRowHeightType}>Small</option>
                  <option value={MediumRowHeightType}>Medium</option>
                  <option value={LargeRowHeightType}>Large</option>
                </select>
              </div>
              <div className={styles.rowCubes}>
                <div
                  role="button"
                  className={cx(styles.rowCubeSmall, height === SmallRowHeightType ? styles.rowCubeActive : '')}
                  onClick={() => this.updateStateByVal('height', SmallRowHeightType)}
                >
                  S
                  <small>(h: 130px)</small>
                </div>
                <div
                  role="button"
                  className={cx(styles.rowCubeMedium, height === MediumRowHeightType ? styles.rowCubeActive : '')}
                  onClick={() => this.updateStateByVal('height', MediumRowHeightType)}
                >
                  M
                  <small>(h: 225px)</small>
                </div>
                <div
                  role="button"
                  className={cx(styles.rowCubeLarge, height === LargeRowHeightType ? styles.rowCubeActive : '')}
                  onClick={() => this.updateStateByVal('height', LargeRowHeightType)}
                >
                  L
                  <small>(h: 500px)</small>
                </div>
              </div>
            </div>
            {layoutType === StandardLayoutType && (
              <div className={styles.popupGroup}>
                <div className={styles.formGroup}>
                  <label className={styles.formLabel} htmlFor="nav_fontweight">Columns</label>
                  <select
                    id="nav_fontweight"
                    className={styles.formElement}
                    value={columnsCount}
                    onChange={this.updateState}
                    name="columnsCount"
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </div>
                <div className={cx(styles.rowColumns, styles[`rowColumns${height}`])}>
                  <div
                    role="button"
                    className={previewRowItems.length >= 1 ? styles.rowColumnActive : null}
                    onClick={() => this.updateStateByVal('columnsCount', 1)}
                  />
                  <div
                    role="button"
                    className={previewRowItems.length >= 2 ? styles.rowColumnActive : null}
                    onClick={() => this.updateStateByVal('columnsCount', 2)}
                  />
                  <div
                    role="button"
                    className={previewRowItems.length >= 3 ? styles.rowColumnActive : null}
                    onClick={() => this.updateStateByVal('columnsCount', 3)}
                  />
                  <div
                    role="button"
                    className={previewRowItems.length >= 4 ? styles.rowColumnActive : null}
                    onClick={() => this.updateStateByVal('columnsCount', 4)}
                  />
                </div>
              </div>
            )}
          </div>
          <DualButtons
            leftText="Cancel"
            leftFunction={() => toggleAddRow()}
            rightText="Add"
            rightFunction={() => this.save()}
          />
        </CardShell>
      </PopupCentered>
    );
  }
}

export default GridAddRowPopup;
