import React from 'react';
import ColouredDot from 'components/ColouredDot';
import cx from 'classnames';
import styles from './styles.scss';

const hasCustomisation = (customisations, code) => !!customisations
  .find(x => x.localeCode.toLowerCase() === code.toLowerCase());

const getCustomisationStateName = (customisations, code) => {
  const customisation = customisations.find(x => x.localeCode.toLowerCase() === code.toLowerCase());
  return customisation == null
    ? 'no custom label provided'
    : customisation.stateName;
};

const CustomStateLabelCard = ({ customStateLabel, handleEditClick, locales }) => (
  <div className={styles.outer}>
    <div className={styles.container}>
      <ColouredDot theme={customStateLabel.category.toLowerCase()} large />
      <div>
        <h4 className={styles.title}>{customStateLabel.stateName}</h4>
        {locales.map(x => (
          <div className={styles.languageGroup} key={x.code}>
            <div className="detail2">{x.name}</div>
            {hasCustomisation(customStateLabel.customisations, x.code)
              && (
                <p className={cx(styles.customLabel, 'mt-0 mb-0')}>
                  {getCustomisationStateName(customStateLabel.customisations, x.code)}
                </p>
              )}
            {!hasCustomisation(customStateLabel.customisations, x.code)
              && <p className={cx(styles.customLabel, styles.greyed, 'italic mt-0 mb-0')}>No custom label provided</p>}
          </div>
        ))}
      </div>
    </div>
    <button
      type="button"
      name="create"
      onClick={handleEditClick}
    >Edit
    </button>
  </div>
);

export default CustomStateLabelCard;
