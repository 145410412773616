import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getCreateConfiguration as getCreateConfigurationAction,
} from 'actions';
import CardShell from 'components/CardShell';
import Spinner from 'components/Spinner';
import CreateConfiguration from './CreateConfiguration';

class CreateCarrierConnector extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getCreateConfiguration(id);
  }

  render() {
    if (!this.props.createConfiguration.configuration) {
      return <Spinner />;
    }

    return (
      <div>
        <h2>Create Configuration</h2>
        <div className="container__cards-large">
          <CardShell>
            <CreateConfiguration />
          </CardShell>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getCreateConfiguration: id => dispatch(getCreateConfigurationAction(id)),
});

export default connect(state => ({
  createConfiguration: state.createConfiguration,
}), mapDispatchToProps)(CreateCarrierConnector);
