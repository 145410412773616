export const LargeRowHeightType = 'large';
export const MediumRowHeightType = 'medium';
export const SmallRowHeightType = 'small';

const RowHeightTypes = [
  LargeRowHeightType,
  MediumRowHeightType,
  SmallRowHeightType,
];

export default RowHeightTypes;
