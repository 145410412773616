import React from 'react';
import { withRouter } from 'react-router';
import CardEllipsisMenu from 'components/CardEllipsisMenu';
import DeleteIcon from 'images/bin.svg';
import TextMenuItem from 'components/ContextMenu/TextMenuItem';
import styles from './styles.scss';

function TemplateCard({
  template: {
    name,
    logoUrl,
    sinkConfigurationName,
    sinkConfigurationId,
    reference,
  },
  history,
  deleteTemplate,
}) {
  const handleEdit = () => {
    const url = `/settings/notification-centre/notification-connectors/${sinkConfigurationId}/templates/${reference}`;
    history.push(url);
  };

  const handlePreview = () => {
    window.open(`/settings/notification-centre/notification-connectors/${sinkConfigurationId}/templates/${reference}/preview`);
  };

  return (
    <div className={styles.container}>
      <h3 className={`textCenter subtitle1 ${styles.cardTitle}`}>{name}</h3>
      <div className={styles.logoHolder}>
        <img
          className={styles.logo}
          src={logoUrl}
          alt=""
        />
      </div>
      <p className={`textCenter ${styles.cardSubtitle}`}>Notification connector: {sinkConfigurationName}</p>
      <CardEllipsisMenu
        primaryText="Edit"
        primaryClickHandler={handleEdit}
      >
        <TextMenuItem text="Preview" clickHandler={handlePreview} />
        <TextMenuItem
          text="Delete"
          clickHandler={() => deleteTemplate(sinkConfigurationId, reference)}
          icon={DeleteIcon}
        />
      </CardEllipsisMenu>
    </div>
  );
}

export default withRouter(TemplateCard);
