import React from 'react';
import { Link } from 'react-router-dom';
import EmailIcon from '../../../../../images/inbox-out.svg';
import EnvelopeIcon from '../../../../../images/envelope-open-text.svg';
import styles from './emailStatisticsCards.scss';

export default function EmailStatisticsCards({ emailStatistics, id }) {
  return (
    <div className={styles.emailStats}>
      <div className={styles.card}>
        <div className={styles.iconContainer}>
          <div className={styles.icon}>
            <EnvelopeIcon />
          </div>
        </div>
        <div className={styles.detailContainer}>
          <span>Out of</span>
          <h3>{emailStatistics?.WithOpenTracking}</h3>
          <span className={styles.spanDescription}> emails with opening tracker</span>
          <h3> {emailStatistics?.OpensPercentage}%</h3>
          <span> were opened</span>
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles.iconContainer}>
          <div className={styles.icon}>
            <EmailIcon />
          </div>
        </div>
        <div className={styles.detailContainer}>
          <span>Out of</span>
          <h3>{emailStatistics?.Sent}</h3>
          <span> emails sent</span>
          <h3> {emailStatistics?.BounceRate}%</h3>
          <span> bounced</span>
          <div className={styles.cardLinkBounce}>
            <Link to={`/reporting/email-statistics/${id}/bounce`}>View bounced email reasons</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
