import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import cx from 'classnames';
import Modal from 'components/Modal';
import EnvelopeRegular from 'images/EnvelopeRegular';
import API from 'helpers/API';
import EmailSenderForm from './EmailSenderForm';
import styles from './styles.scss';
import {
  EMAIL_SENDER_MODAL_HEADER,
  EMAIL_SENDER_MODAL_SUB_HEADER,
  EMAIL_SENDER_MODAL_ACTION_TEXT,
  EMAIL_SENDER_MODAL_CANCEL_TEXT,
  EMAIL_SENDER_MODAL_LABEL,
  EMAIL_SENDER_MODAL_DESCRIBED_BY,
  EMAIL_SENDER_ERROR_CONNECTOR,
  EMAIL_SENDER_ERROR_TEMPLATE,
  CONFIRMATION_MODAL_HEADER,
  CONFIRMATION_MODAL_SUB_HEADER,
  CONFIRMATION_MODAL_ACTION_TEXT,
  CONFIRMATION_MODAL_CANCEL_TEXT,
  CONFIRMATION_MODAL_LABEL,
  CONFIRMATION_MODAL_DESCRIBED_BY,
} from './constants';

export const generateConnectorOptions = (dataArray) => dataArray.filter(element => element.isActive && element.type !== 'sms')
  .map(connectorObject => ({ label: connectorObject.name, value: connectorObject.id }));
export const generateTemplateOptions = ({ dataArray, sinkConfigId }) => dataArray.filter(template => template.sinkConfigurationId === sinkConfigId)
  .map(templateObject => ({ label: templateObject.name, value: templateObject.reference }));

const FORM_FIELD_EMAIL_TEMPLATE = 'emailTemplate';
const FORM_FIELD_EMAIL_CONNECTOR = 'emailConnector';

const validationInitialValue = {
  [FORM_FIELD_EMAIL_TEMPLATE]: null,
  [FORM_FIELD_EMAIL_CONNECTOR]: null,
};
const dataErrorInitialValue = null;
const submitObjectInitial = {
  sinkConfigurationId: '',
  templateReference: '',
};

const EmailSender = ({
  modalOpen, modalTrigger, dashboard, tile, numberOfFieldsSelected, alertControl,
}) => {
  const [connectorValue, setConnectorValue] = React.useState(null);
  const [templateValue, setTemplateValue] = React.useState('');
  const [connectorOptions, setConnectorOptions] = React.useState([]);
  const [validationError, setValidationError] = React.useState(validationInitialValue);
  const [allTemplates, setAllTemplates] = React.useState([]);
  const [filteredTemplates, setFilteredTemplates] = React.useState([]);
  const [dataError, setDataError] = React.useState(dataErrorInitialValue);
  const [confirmBoxOpen, setConfirmBoxOpen] = React.useState(false);
  const [submitValues, setSubmitValues] = React.useState(submitObjectInitial);
  const validateFields = () => {
    if (!connectorValue) {
      setValidationError({ ...validationError, [FORM_FIELD_EMAIL_CONNECTOR]: [EMAIL_SENDER_ERROR_CONNECTOR] });
      return false;
    }
    if (!templateValue) {
      setValidationError({ ...validationError, [FORM_FIELD_EMAIL_TEMPLATE]: [EMAIL_SENDER_ERROR_TEMPLATE] });
      return false;
    }
    return true;
  };
  const clearState = () => {
    setValidationError(validationInitialValue);
    if (templateValue) setTemplateValue(null);
    if (connectorValue) setConnectorValue(null);
    setDataError(dataErrorInitialValue);
  };
  const emailSenderAction = async () => {
    try {
      await API.addBulkEmailNotifications({
        ...submitValues, tileText: encodeURIComponent(tile), dashboardName: encodeURIComponent(dashboard),
      }); // will assign it to result and check if it is valid before proceeding with the rest
      setConfirmBoxOpen(false); // For now close the modal regardless
      alertControl({
        on: true,
        type: 'success',
        message: 'Emails have been processed',
      });
      setSubmitValues(submitObjectInitial);
    } catch (err) {
      setConfirmBoxOpen(false);
      alertControl({
        on: true,
        type: 'error',
        message: 'Email sending failed! Please try again or contact administrator!',
      });
    }
  };
  const submitConfirmation = () => {
    if (validateFields()) {
      setSubmitValues({
        sinkConfigurationId: connectorValue,
        templateReference: templateValue,
      });
      modalTrigger(false);
      setConfirmBoxOpen(true);
    }
  };
  const getSinkConfigurationsData = async () => {
    try {
      const sinkConfigurationValues = await API.getSinkConfigurations();
      if (sinkConfigurationValues && 'configurations' in sinkConfigurationValues && sinkConfigurationValues?.configurations?.length) {
        const arrayOfOptions = generateConnectorOptions(sinkConfigurationValues?.configurations);
        setConnectorOptions([{ label: 'Select connector', value: '' }, ...arrayOfOptions]);
        if (arrayOfOptions.length === 1) {
          setConnectorValue(arrayOfOptions[0].value);
        }
      }
    } catch (error) {
      if (error) {
        setDataError('There is an issue in fetching data!');
      }
    }
  };
  const getTemplates = async () => {
    try {
      const result = await API.getTemplates();
      if (result && result?.templates && result?.templates?.length) {
        setAllTemplates(result?.templates);
      }
    } catch (error) {
      if (error) {
        setDataError('There is an issue in fetching data!');
      }
    }
  };
  useEffect(() => {
    getSinkConfigurationsData();
    getTemplates();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (connectorValue) {
      const filteredValues = generateTemplateOptions({ dataArray: allTemplates, sinkConfigId: connectorValue });
      setFilteredTemplates([{ label: 'Select template', value: '' }, ...filteredValues]);
    }
  }, [connectorValue, allTemplates]);
  useEffect(() => {
    if (!modalOpen) {
      clearState();
    }
    // eslint-disable-next-line
  }, [modalOpen]);
  useEffect(() => {
    if (modalOpen && connectorOptions.length <= 0) {
      getSinkConfigurationsData();
      // Another solution should be applied when templates not loading based on the changed functionality
    }
  }, [modalOpen, connectorOptions]);
  useEffect(() => {
    if (connectorOptions?.length && allTemplates?.length && dataError) {
      setDataError(null);
    }
  }, [connectorOptions, allTemplates, dataError]);
  return (
    <Grid container item xs={12}>
      <Grid container item xs={12} lg={3} xl={2} style={{ display: 'flex', alignItems: 'center' }}>
        <Grid item lg={5} xl={6}>
          <button
            onClick={() => modalTrigger(true)}
            className={cx(styles.mainButton, styles.buttonTheme)}
            type="button"
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '16px', height: '16px' }}>
                <EnvelopeRegular />
              </div>
              <div style={{ marginLeft: '10px' }}>
                Send email
              </div>
            </div>
          </button>
        </Grid>
        <Grid item lg={4} xl={5} style={{ marginLeft: '10px' }}>
          <span>
            {numberOfFieldsSelected} selected
          </span>
        </Grid>
      </Grid>
      <Modal
        open={modalOpen}
        setOpen={modalTrigger}
        label={EMAIL_SENDER_MODAL_LABEL}
        describedBy={EMAIL_SENDER_MODAL_DESCRIBED_BY}
        actionText={EMAIL_SENDER_MODAL_ACTION_TEXT}
        cancelText={EMAIL_SENDER_MODAL_CANCEL_TEXT}
        handleAction={submitConfirmation}
        mainContent={(
          <EmailSenderForm
            setValidationError={setValidationError}
            validationError={validationError}
            setConnectorValue={setConnectorValue}
            setTemplateValue={setTemplateValue}
            templateValue={templateValue}
            connectorValue={connectorValue}
            connectorOptions={connectorOptions}
            filteredTemplates={filteredTemplates}
          />
        )}
        header={EMAIL_SENDER_MODAL_HEADER}
        subHeader={EMAIL_SENDER_MODAL_SUB_HEADER}
        errorMessage={dataError}
      />
      <Modal
        open={confirmBoxOpen}
        setOpen={setConfirmBoxOpen}
        label={CONFIRMATION_MODAL_LABEL}
        describedBy={CONFIRMATION_MODAL_DESCRIBED_BY}
        actionText={CONFIRMATION_MODAL_ACTION_TEXT}
        cancelText={CONFIRMATION_MODAL_CANCEL_TEXT}
        handleAction={emailSenderAction}
        header={CONFIRMATION_MODAL_HEADER}
        subHeader={CONFIRMATION_MODAL_SUB_HEADER}
        size="small"
      />
    </Grid>
  );
};

export default EmailSender;
