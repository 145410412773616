import React from 'react';
import BackButton from 'components/BackButton';
import classnames from 'classnames';
import { withRouter } from 'react-router';
import styles from './styles.scss';

function ButtonToolbar({ children, onSaveClick }) {
  return (
    <div className={styles.buttonToolbar}>
      <BackButton onBack={() => window.history.back()} />

      <div className={styles.actionBtnGroup}>
        {children}
        <button
          className={classnames(styles.btn)}
          type="button"
          onClick={onSaveClick}
        >Save
        </button>
      </div>
    </div>
  );
}

export default withRouter(ButtonToolbar);
