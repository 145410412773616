/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from 'react';
import * as propTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import styles from './styles.scss';

const DropdownItemUpdated = ({
  baseUrl,
  children,
  external,
}) => {
  const location = useLocation();

  if (external) {
    return (
      <a
        href={external}
        className={styles.item}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }
  return (
    <NavLink
      to={{ pathname: `${baseUrl}`, state: { prevPath: location.pathname } }}
      className={classNames(styles.itemUpdated, baseUrl === window.location.pathname ? styles.active : '')}
      activeClassName={styles.itemActive}
    >
      {children}
    </NavLink>
  );
};

DropdownItemUpdated.defaultProps = {
  baseUrl: '',
  external: '',
  children: 'item',
};

DropdownItemUpdated.propTypes = {
  baseUrl: propTypes.string,
  external: propTypes.string,
  children: propTypes.node,
};

export default DropdownItemUpdated;
