/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from 'react';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import { event as gaEvent } from 'react-ga';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import styles from './styles.scss';
import { headerStyle, style } from './styles';
import Svg from '../../Svg';
import {
  closePopup, showPopup, deleteUser, deleteInvitation, inviteUser,
} from '../../../actions';
import TablePaginator from '../../TablePaginator';

export const renderRoleName = (props) => {
  const { role_id: roleId } = props.original;
  switch (roleId) {
    case 1:
      return 'Admin';
    case 3:
      return 'Marketing';
    case 4:
      return 'Dashboard';
    case 5:
      return 'Dashboard basic';
    default:
      return '';
  }
};

const CustomUsersTable = ({ data, user }) => {
  const dispatch = useDispatch();
  const handleDeleteUser = (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (props.original.is_invitation) {
      dispatch(showPopup({
        title: 'Are you sure?',
        description: 'Removing a user deletes them completely.',
        onAccept: () => {
          gaEvent({
            category: 'AccountAndUserManagement',
            action: 'Delete invited user',
          });
          dispatch(deleteInvitation(props.original.id));
        },
      }));
    } else {
      dispatch(showPopup({
        title: 'Are you sure?',
        description: 'Removing a user deletes them completely.',
        onAccept: () => {
          gaEvent({
            category: 'AccountAndUserManagement',
            action: 'Delete user',
          });
          dispatch(deleteUser(props.original.id, () => dispatch(closePopup())));
        },
      }));
    }
  };

  const handleResendEmail = (props) => {
    console.log('click2');
    dispatch(showPopup({
      title: 'Re-send email confirmation',
      description: 'Send the email',
      onAccept: () => dispatch(inviteUser(
        props.original.first_name,
        props.original.last_name,
        props.original.email,
        props.original.role_id,
      ))
        .then(() => {
          gaEvent({
            category: 'AccountAndUserManagement',
            action: 'Resend email',
          });
          dispatch(showPopup({ title: 'Email has been resent successfully' }));
        })
        .catch((e) => {
          dispatch(showPopup({ title: 'Error', description: e.formError }));
          return Promise.resolve();
        }),
    }));
  };
  const renderSpan = (props) => (
    <div className={styles.tableButtonsBlock}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      {
                !props.original.is_invitation && (
                <span>
                  <Link to={`/settings/users/${props.original.id}`}>
                    {
                      user === '1' && <Svg name="TableEditUser" className={cx(styles.tableButtonsBlock, styles.btnEdit)} />
                    }
                  </Link>
                </span>
                )
            }
      {
                (!props.original.is_primary || props.original.is_invitation)
                && (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <span
                  className={cx(
                    styles.actionsBtnBlock,
                  )}

                >
                  {
                      user === '1' && (
                      <Svg
                        name="TableDeleteUser"
                        onClick={() => handleDeleteUser(props)}
                        className={styles.btnDelete}
                      />
                      )
                  }
                  <div className={cx(
                    styles.middleDiv,
                    { [styles.middleDivHidden]: user !== '1' },
                  )}
                  />
                  {
                    props.original.is_invitation && (user === '1' && (
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                    <span
                      className={cx(
                        styles.reSendEmail,
                        { [styles.actionsBtnBlock0Margin]: user !== '1' },
                      )}
                      onClick={() => handleResendEmail(props)}
                    >Re-send email
                    </span>
                    ))
                  }
                </span>
                )
            }
    </div>
  );

  const renderNameColumn = (props) => (
    <div className={cx(styles.renderNameColumnWrapper, styles.renderNameColumnStyles)}>
      <div className={styles.renderNameColumnStyles}>
        <span
          title={`${props.original.first_name} ${props.original.last_name}`}
        >{props.original.first_name} {props.original.last_name}
        </span>
      </div>
      <>
        {props.original.is_invitation && <span className={styles.pending}>Pending</span>}
        {props.original.is_primary && <span className={cx(styles.pending, styles.primary)}>Primary</span>}
      </>
    </div>
  );

  const renderEmailColumn = (props) => (
    <div className={cx(styles.renderNameColumnWrapper, styles.renderNameColumnStyles)}>
      <div className={styles.renderNameColumnStyles}>
        <span title={props.original.email}>{props.original.email}</span>
      </div>
    </div>
  );

  const sortedData = data.sort((a, b) => a.last_name?.localeCompare(b.last_name));
  return (
    <div className={styles.wrapper}>
      {sortedData && (
        <>
          <ReactTable
            data={sortedData}
            minRows={0}
            columns={[
              {
                Header: 'Name',
                accessor: 'last_name',
                Cell: props => renderNameColumn(props),
                headerStyle,
                style,
              },
              {
                Header: 'Email address',
                accessor: 'email',
                Cell: props => renderEmailColumn(props),
                headerStyle,
                style,
              },
              {
                Header: 'User role',
                accessor: 'role_id',
                Cell: props => renderRoleName(props),
                headerStyle,
                style,
                width: 200,
              },
              {
                Header: 'Actions',
                accessor: 'actions',
                Cell: props => renderSpan(props),
                headerStyle,
                style,
                width: 250,
                show: user === '1',
              },
            ]}
            sortable={false}
            showPageSizeOptions={false}
            resizable={false}
            howPageJump={false}
            getTrProps={() => ({
              style: { border: 'none' },
            })}
            PaginationComponent={TablePaginator}
            footerText="users"
            pageSizeOptions={[20, 50, 100, 200]}
            totalRows={sortedData.length}
          />
        </>
      )}
    </div>
  );
};

export default CustomUsersTable;
