import React from 'react';
import { func, string } from 'prop-types';
import CardShell from 'components/CardShell';
import { okButtonTitle } from 'constants/titles';
import styles from './DownloadFilePopup.scss';

const DownloadFilePopup = ({ message, onOkButtonClick }) => (
  <CardShell>
    <div className={styles.popupWrapper}>
      <div className={styles.popupMessage}>
        {message.split('\n').map(item => <span>{item}<br /></span>)}
      </div>
      <button className={styles.popupButton} onClick={onOkButtonClick} type="button">{okButtonTitle}</button>
    </div>
  </CardShell>
);

DownloadFilePopup.propTypes = {
  onOkButtonClick: func.isRequired,
  message: string.isRequired,
};

export default DownloadFilePopup;
