import React from 'react';

/* eslint-disable */
export default props => (
  <svg xmlns='http://www.w3.org/2000/svg' id='Layer_1' viewBox='0 0 137 60.1'>
    <g>
      <path d='M131.1,50.8c0,1.5,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8c0-1.5-1.3-2.8-2.8-2.8C132.3,48,131.1,49.3,131.1,50.8    M118.6,45.8c0-2.7,1.7-4.2,3.8-4.2c2.1,0,3.7,1.5,3.7,4.2c0,2.7-1.6,4.3-3.7,4.3C120.2,50.1,118.6,48.5,118.6,45.8 M130.1,31.1h-4   v8.6c-0.4-0.8-1.7-1.9-4.3-1.9c-4.3,0-7.3,3.5-7.3,7.9c0,4.6,3.1,8,7.4,8c2,0,3.6-0.9,4.2-2.1c0,0.7,0.1,1.4,0.2,1.8h3.9   c-0.1-0.6-0.1-1.7-0.1-2.7V31.1z M110.2,48.1c-0.5,1.4-1.5,2.3-3.4,2.3c-2,0-3.7-1.4-3.8-3.4h10.8c0-0.1,0.1-0.7,0.1-1.3   c0-4.9-2.8-7.8-7.5-7.8c-3.9,0-7.4,3.1-7.4,8c0,5.1,3.7,8.1,7.8,8.1c3.7,0,6.1-2.2,6.9-4.8L110.2,48.1z M103,44.1   c0.1-1.4,1.3-3,3.4-3c2.3,0,3.3,1.5,3.4,3H103z M95.8,33.7h-3.7v2.1c0,1.4-0.7,2.4-2.3,2.4H89v3.6h2.7v7c0,2.9,1.8,4.7,4.8,4.7   c1.2,0,1.9-0.2,2.3-0.4v-3.4c-0.2,0.1-0.8,0.1-1.3,0.1c-1.2,0-1.8-0.4-1.8-1.8v-6.3h3v-3.6h-3V33.7z M88.2,38.2   c-0.3,0-0.6-0.1-1-0.1c-1.3,0-3.4,0.4-4.3,2.4v-2.2h-4v15.1h4.1v-6.9c0-3.3,1.8-4.3,3.9-4.3c0.4,0,0.8,0,1.2,0.1V38.2z M70.3,37.8   c-4.5,0-7.9,3.4-7.9,8c0,4.6,3.4,8,7.9,8c4.5,0,7.9-3.4,7.9-8C78.2,41.2,74.8,37.8,70.3,37.8 M70.3,50.1c-2,0-3.8-1.5-3.8-4.3   c0-2.9,1.8-4.3,3.8-4.3c2,0,3.8,1.4,3.8,4.3C74.1,48.7,72.3,50.1,70.3,50.1 M61.2,36.8c-0.5-2.5-2.5-5.7-7.8-5.7   c-4.1,0-7.6,3-7.6,6.8c0,3.3,2.2,5.5,5.7,6.2l3,0.6c1.7,0.3,2.6,1.3,2.6,2.6c0,1.5-1.3,2.7-3.5,2.7c-3.1,0-4.6-1.9-4.8-4.1L45,47   c0.3,3.1,2.9,6.8,8.7,6.8c5.1,0,7.9-3.4,7.9-6.7c0-3.1-2.1-5.7-6.1-6.4l-3-0.6c-1.6-0.3-2.4-1.3-2.4-2.5c0-1.4,1.4-2.8,3.4-2.8   c2.8,0,3.8,1.9,4,3.1L61.2,36.8z'/>
      <path d='M12.6,2c-1.1-1.7-3.3-2.2-4.9-1.2C6.8,1.4,6.3,2.3,6.2,3.3c-0.8,4.7,5,13.4,5,13.4l4.8,7.3c0.6-0.1,1.2-0.1,1.8-0.1   c2.7,0,5.3,0.6,7.6,1.7l-0.2,0.8c-2.3-1.1-4.8-1.6-7.4-1.6c-9.5,0-17.2,7.6-17.5,17H9c0.2-4.7,4-8.5,8.8-8.5c4.9,0,8.8,3.9,8.8,8.8   c0,4.9-3.9,8.8-8.8,8.8c-4.7,0-8.5-3.7-8.8-8.3H0.3c0.2,9.5,8,17,17.5,17c9.6,0,17.5-7.8,17.5-17.5c0-3.6-1.1-6.9-2.9-9.7l0,0   L12.6,2z M32.3,31.1l-6.8-27c-0.4-1.8-2.2-2.8-4-2.4c-1,0.2-1.7,0.9-2.1,1.7c-0.9,1.5-1,3.9-0.8,6.2L32.3,31.1z M35.5,37.7l-1-24   c-0.1-1.4-1.2-2.5-2.6-2.4c-0.8,0-1.4,0.4-1.9,1l0,0c-0.5,0.5-0.8,1.3-1.1,2.1l4.9,19.2C34.6,34.8,35.1,36.2,35.5,37.7'/>
    </g>
  </svg>
)
/* eslint-enable */
