const initialState = {
  isLoading: false,
  values: [
    {
      id: '1',
      name: 'Late',
    },
    {
      id: '2',
      name: 'May Be Missing',
    },
  ],
  errorMessage: '',
};

export default function availableCalculatedEventsReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
