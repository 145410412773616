import React from 'react';
import ReactTable from 'react-table';
import styles from './styles.scss';
import { headerStyle, style } from './styles';

const renderSpan = (color, title) => (
  <span>
    <span style={{
      background: color,
      width: 70,
      height: 24.38,
      borderRadius: '12px',
      padding: '3px 8px',
      display: 'inline-block',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      fontWeight: 100,
      fontSize: '16px',
    }}
    >{title}
    </span>
  </span>
);

const renderCell = (rowValue) => (<span title={rowValue}>{rowValue}</span>);

function DashboardSummaryTable({ summaryAlerts }) {
  return (
    <div className={styles.wrapper}>
      {summaryAlerts.summaryDataItems.length && (
        <>
          <h2 style={{ fontSize: '28px' }}>Top carriers by volume of monitored shipments</h2>
          <div className={styles.tableBorder}>
            <ReactTable
              data={summaryAlerts.summaryDataItems}
              columns={[
                {
                  Header: 'Carrier',
                  accessor: 'carrier',
                  Cell: row => renderCell(row.value),
                  headerStyle,
                  style,
                  width: 400,
                },
                {
                  Header: 'Country',
                  accessor: 'country',
                  headerStyle,
                  style,
                },
                {
                  Header: 'Number of alerts',
                  accessor: 'numberOfAlerts',
                  Cell: row => renderCell(row.value),
                  headerStyle,
                  style,
                },
                {
                  Header: 'Volume of monitored shipments',
                  accessor: 'volume',
                  Cell: row => renderCell(row.value),
                  headerStyle,
                  style,
                },
                {
                  Header: renderSpan('#f6d6da', 'Red'),
                  accessor: 'red',
                  headerStyle,
                  style,
                },
                {
                  Header: renderSpan('#f4ded0', 'Amber'),
                  accessor: 'amber',
                  Cell: row => renderCell(row.value),
                  headerStyle,
                  style,
                },
                {
                  Header: renderSpan('#cee8dd', 'Green'),
                  accessor: 'green',
                  Cell: row => renderCell(row.value),
                  headerStyle,
                  style,
                },
              ]}
              sortable={false}
              showPaginationBottom={false}
              pageSize={summaryAlerts.summaryDataItems.length > 5 ? 5 : summaryAlerts.summaryDataItems.length}
              resizable={false}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default DashboardSummaryTable;
