import LayoutTypes from '../../constants/trackingPage/layoutTypes';
import RowHeightTypes from '../../constants/trackingPage/rowHeightTypes';
import RowTextPositions from '../../constants/trackingPage/rowTextPositions';
import { fromContractMap } from './componentTypeMap';
import { InvalidTrackingReferenceType } from '../../constants/trackingPage/TrackingPageOverrideTypes';

const getPreparedFont = font => ({
  name: font.name,
  url: font.url,
  size: font.size,
  fontWeight: font.font_weight,
  colour: font.colour,
});

const getPreparedRowItem = rowItem => ({
  id: rowItem.id,
  order: rowItem.order,
  base64Image: rowItem.base64_image,
  text: rowItem.text,
  rowTextPosition: RowTextPositions.find(rowTextPosition => rowTextPosition === rowItem.row_text_position),
  font: rowItem.font
    ? getPreparedFont(rowItem.font)
    : undefined,
  redirectionUrl: rowItem.redirection_url,
  componentType: fromContractMap[rowItem.component_type],
  displayOption: rowItem.display_option,
  locale: rowItem.locale,
});

const getPreparedRow = row => ({
  id: row.id,
  order: row.order,
  rowHeight: RowHeightTypes.find(rowHeightType => rowHeightType === row.row_height),
  rowItems: row.row_items
    ? row.row_items.map(rowItem => getPreparedRowItem(rowItem))
    : [],
});

const getPreparedNavigationBarItem = navigationBarItem => ({
  id: navigationBarItem.id,
  order: navigationBarItem.order,
  text: navigationBarItem.text,
  redirectionUrl: navigationBarItem.redirection_url,
});

const getPreparedNavigationBar = navigationBar => ({
  base64Logo: navigationBar.base64_logo,
  backgroundColour: navigationBar.background_colour,
  borderColour: navigationBar.border_colour,
  font: navigationBar.font
    ? getPreparedFont(navigationBar.font)
    : undefined,
  rootUrl: navigationBar.root_url,
  navigationBarItems: navigationBar.navigation_bar_items
    ? navigationBar.navigation_bar_items.map(navigationBarItem => getPreparedNavigationBarItem(navigationBarItem))
    : [],
});

const getPreparedLayout = layout => ({
  id: layout.id,
  layoutType: LayoutTypes.find(layoutType => layoutType === layout.layout_type),
  rows: layout.rows
    ? layout.rows.map(row => getPreparedRow(row))
    : [],
});

export const getPreparedTrackingPageData = trackingPageData => ({
  base64FavIcon: trackingPageData.base64_fav_icon,
  isCarrierLinkActive: trackingPageData.is_carrier_link_active,
  title: trackingPageData.title,
  font: trackingPageData.font
    ? getPreparedFont(trackingPageData.font)
    : undefined,
  navigationBar: trackingPageData.navigation_bar
    ? getPreparedNavigationBar(trackingPageData.navigation_bar)
    : undefined,
  layouts: trackingPageData.layouts
    ? trackingPageData.layouts.map(layout => getPreparedLayout(layout))
    : [],
});

const getTrackingPageSummary = trackingPageSummary => ({
  id: trackingPageSummary.id,
  trackingPageName: trackingPageSummary.tracking_page_name,
  title: trackingPageSummary.title,
  createdOn: trackingPageSummary.created_on,
  publishedOn: trackingPageSummary.published_on,
  version: trackingPageSummary.version,
});

export const getPreparedTrackingPageSummaries = (trackingPages) => {
  if (!trackingPages) return [];
  return trackingPages.map(trackingPageSummary => getTrackingPageSummary(trackingPageSummary));
};

export const getPreparedTrackingPage = (trackingPage) => {
  const preparedTrackingPage = {
    trackingPageName: trackingPage.tracking_page_name,
    createdOn: trackingPage.created_on,
    publishedOn: trackingPage.published_on,
    version: trackingPage.version,
    invalidTrackingPageOverrideName: trackingPage.tracking_page_overrides && trackingPage.tracking_page_overrides.length > 0
      ? trackingPage.tracking_page_overrides.find(pageOveride => pageOveride.type === InvalidTrackingReferenceType).tracking_page_name_for_override
      : null,
  };

  return {
    ...preparedTrackingPage,
    trackingPageData: getPreparedTrackingPageData(trackingPage.tracking_page_data),
  };
};
