import React, { Component } from 'react';
import cx from 'classnames';
import Svg from 'components/Svg';
import DualButtons from 'components/DualButtons';
import BackButton from 'components/BackButton';
import { event as gaEvent } from 'react-ga';
import Hr from 'components/CardShell/Hr';
import Header from 'components/CardShell/Header';
import styles from './styles.scss';

class ShipmentType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: [
        {
          label: 'Delivery',
          id: 'Delivery',
          isSelected: props.formData.shipment_types.includes('Delivery'),
        },
        {
          label: 'Return',
          id: 'Return',
          isSelected: props.formData.shipment_types.includes('Return'),
        },
        {
          label: 'Pick up',
          id: 'PickUp',
          isSelected: props.formData.shipment_types.includes('PickUp'),
        },
        {
          label: 'Drop off',
          id: 'DropOff',
          isSelected: props.formData.shipment_types.includes('DropOff'),
        },
        {
          label: 'Drop off pick up',
          id: 'DropOffPickUp',
          isSelected: props.formData.shipment_types.includes('DropOffPickUp'),
        },
        {
          label: 'Return drop off',
          id: 'ReturnDropOff',
          isSelected: props.formData.shipment_types.includes('ReturnDropOff'),
        },
      ],
    };
  }

  stateClick = (id) => {
    gaEvent({
      category: 'ShipmentFilters',
      action: 'Select shipment type',
    });
    this.setState((prevState) => {
      const newState = prevState.filters;

      for (let i = 0; i < newState.length; i += 1) {
        const stateId = newState[i].id;
        if (stateId === id) {
          newState[i].isSelected = !newState[i].isSelected;
        }
      }
      return { filters: newState };
    }, () => this.updateSelectedItems());
  };

  selectAllStates = () => {
    gaEvent({
      category: 'ShipmentFilters',
      action: 'Select all shipment types',
    });
    const condition = this.checkSelectedStates();
    this.setState((prevState) => {
      const newState = prevState.filters;

      for (let i = 0; i < newState.length; i += 1) {
        newState[i].isSelected = !condition;
      }

      return { filters: newState };
    }, () => this.updateSelectedItems());
  };

  checkSelectedStates = () => {
    let allSelected = true;

    this.state.filters.forEach((obj) => {
      if (!obj.isSelected) allSelected = false;
    });

    return allSelected;
  };

  handleSectionChange = () => {
    this.updateSelectedItems(() => this.props.gotoPage(5));
  };

  updateSelectedItems = (callback) => {
    const stateList = [];

    this.state.filters.forEach((obj) => {
      if (obj.isSelected) stateList.push(obj.id);
    });

    this.props.updateState({
      shipment_types: stateList,
    }, callback);
  };

  isAnyShipmentTypeSelected() {
    return this.state.filters.some(filter => filter.isSelected);
  }

  render() {
    const {
      shipmentFilterTitle,
      gotoPage,
      pageTabs,
      cancel,
    } = this.props;
    return (
      <div className={styles.cardsection}>
        <div className={styles.cardSectionInner}>
          <Header>{shipmentFilterTitle}</Header>
          <Hr />
          <div className={styles.buttonBack}>
            <BackButton onBack={() => gotoPage(3)} />
          </div>
          {pageTabs()}
          <p className={styles.numberSection}>Select the shipment types you want to include</p>
          <div className={styles.multiselectButtons}>
            <button
              type="button"
              onClick={this.selectAllStates}
              className={
                cx(styles.multiselectButton, styles.first, this.checkSelectedStates()
                  ? styles.multiselectButtonActive : '')
              }
            >
              Select all shipment types
            </button>
            <div className={styles.multiselectPair}>
              {this.state.filters.map(filter => (
                <button
                  type="button"
                  key={filter.id}
                  className={
                    filter.isSelected
                      ? cx(styles.multiselectPairButton, styles.multiselectPairButtonActive)
                      : styles.multiselectPairButton
                  }
                  onClick={() => this.stateClick(filter.id)}
                >
                  <div>
                    <div>{ filter.label }</div>
                    <div>
                      {!filter.isSelected && (<div className={styles.notSelectedButton} />)}
                      {filter.isSelected && (<Svg className={styles.SelectedButton} name="tickWithStroke" />)}
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
        <DualButtons
          leftFunction={() => cancel()}
          leftText="Cancel"
          rightFunction={this.handleSectionChange}
          rightText="Next"
          theme="rightPink"
          rightFunctionDisabled={!this.isAnyShipmentTypeSelected()}
        />
      </div>
    );
  }
}

export default ShipmentType;
