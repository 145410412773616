import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { func, shape } from 'prop-types';
import { Base64 } from 'js-base64';
import CardShell from 'components/CardShell';
import Hr from 'components/CardShell/Hr';
import Header from 'components/CardShell/Header';
import DualButtons from 'components/DualButtons';
import InputWithValidation from 'components/InputWithValidation';
import { sftpAccountFormTitle } from 'constants/titles';
import {
  downloadFileMessage,
  createSftpAccountDescription,
  sftpAccountNameValidationMessage,
  sftpAccountPasswordValidationMessage,
} from 'constants/messagesAndDescriptions';
import createSftpAccount from 'actions/sftpAccountActions';
import saveContentAs from 'helpers/saveContentAs';
import { required, minLength, maxLength } from 'helpers/validate';
import { validateForm } from 'helpers/validateForm';
import Spinner from 'components/Spinner';
import DownloadFilePopup from './DownloadFilePopup';
import styles from './styles.scss';

const privateKeyFileName = 'PrivateKeyFile.ppk';
const validationRules = {
  sftpName: [
    required(),
    minLength(
      5,
      () => sftpAccountNameValidationMessage,
    ),
    maxLength(
      100,
      () => sftpAccountNameValidationMessage,
    ),
  ],
  sftpPassword: [
    required(),
    minLength(
      8,
      () => sftpAccountPasswordValidationMessage,
    ),
    maxLength(
      200,
      () => sftpAccountPasswordValidationMessage,
    ),
  ],
};

class SftpAccountForm extends Component {
  static propTypes = {
    createSftpAccount: func.isRequired,
    history: shape({
      goBack: func.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      sftpName: '',
      sftpPassword: '',
      isKeyReceived: false,
      responseErrorMessage: null,
      errors: null,
      isAdding: false,
    };
  }

  handleNameInputChange = ({ target: { value } }) => {
    this.setState({
      sftpName: value,
    });
  };

  handlePasswordInputChange = ({ target: { value } }) => {
    this.setState({
      sftpPassword: value,
    });
  };

  backToMenu = () => {
    this.props.history.goBack();
  };

  handleCancelClick = () => {
    this.backToMenu();
  };

  handleCreateClick = () => {
    const errors = validateForm(validationRules, this.state);
    if (errors.hasErrors) {
      this.setState({ errors });
      return;
    }

    const { sftpName, sftpPassword } = this.state;
    this.setState({ isAdding: true });
    this.props.createSftpAccount(sftpName, sftpPassword)
      .then((key) => {
        saveContentAs(Base64.decode(key), 'text/plain', privateKeyFileName);
        this.setState({
          sftpName: '',
          sftpPassword: '',
          isKeyReceived: true,
          errors: null,
          isAdding: true,
        });
      })
      .catch(({ message }) => {
        this.setState({
          isKeyReceived: true,
          responseErrorMessage: message,
          errors: null,
        });
      }).finally(() => {
        this.setState({ isAdding: false });
      });
  };

  handleOkButtonClick = () => {
    this.setState({
      isKeyReceived: false,
      responseErrorMessage: null,
    });
    if (this.state.responseErrorMessage === null) {
      this.backToMenu();
    }
  };

  render() {
    const { sftpName, sftpPassword, errors } = this.state;
    return (
      <div>
        {this.state.isAdding && <Spinner />}
        <CardShell>
          <div className={styles.cardWrapper}>
            {this.state.isKeyReceived && (
              <div className={styles.overlay}>
                <div className={styles.modalWrapper}>
                  <DownloadFilePopup
                    onOkButtonClick={this.handleOkButtonClick}
                    message={this.state.responseErrorMessage || downloadFileMessage}
                  />
                </div>
              </div>
            )}
            <div className={styles.container}>
              <div className={styles.contentWrapper}>
                <Header>{sftpAccountFormTitle}</Header>
                <Hr />
                <p>{createSftpAccountDescription}</p>
                <InputWithValidation
                  label="Name"
                  placeholder="Username"
                  type="text"
                  value={sftpName}
                  name="sftpName"
                  onChange={this.handleNameInputChange}
                  errors={errors}
                  required
                />
                <InputWithValidation
                  label="Password"
                  placeholder="Password"
                  type="password"
                  value={sftpPassword}
                  name="sftpPassword"
                  onChange={this.handlePasswordInputChange}
                  errors={errors}
                  required
                />
              </div>
            </div>
            <DualButtons
              leftFunction={this.handleCancelClick}
              leftText="Cancel"
              rightFunction={this.handleCreateClick}
              rightText="Create"
              theme="rightPink"
            />
          </div>
        </CardShell>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.sftpAccount.isLoading,
  result: state.sftpAccount.result,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  createSftpAccount,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SftpAccountForm));
