import React, { Component } from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import DualButtons from 'components/DualButtons';
import ExportSummary from 'components/ExportSummary';
import abbreviate from 'helpers/abbreviate';
import styles from './styles.scss';

export default class SmallCard extends Component {
  static propTypes = {
    value: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired,
    description: propTypes.string.isRequired,
    theme: propTypes.string.isRequired,
    onClick: propTypes.func.isRequired,
    contextAction: propTypes.oneOfType([propTypes.func, propTypes.bool]),
  };

  static defaultProps = {
    contextAction: false,
  };

  cardClicked = () => {
    if (this.props.onClick) {
      this.props.onClick({ stateKey: this.props.stateKey });
    }
  };

  render() {
    const valueAbbreviation = abbreviate(this.props.value);
    const {
      showProgress,
      showError,
      contextActionTooltipText,
    } = this.props;

    return (
      <div
        className={classnames(
          styles.smallCard,
          styles.noScale,
          styles[this.props.theme],
        )}
        role="button"
      >

        <div className={styles.body} onClick={this.cardClicked} role="button">
          <div className={styles.bodyInner}>
            <div className={styles.dot} />
            <div className={styles.rightColumn}>
              <div className={styles.value}>{valueAbbreviation}</div>
              <div className={styles.description}>{this.props.description}</div>
            </div>
          </div>
        </div>

        <DualButtons
          leftText="View"
          leftFunction={this.cardClicked}
          rightText={<ExportSummary showProgress={showProgress} showError={showError} />}
          rightFunctionDisabled={this.props.contextActionDisabled || showProgress}
          rightFunction={this.props.contextAction ? this.props.contextAction : null}
          rightTooltipText={contextActionTooltipText}
          rightTooltipVisible={this.props.contextActionTooltipVisble}
        />

      </div>
    );
  }
}
