import React from 'react';
import Hr from 'components/CardShell/Hr';
import Header from 'components/CardShell/Header';

const WebhookCardTitle = (props) => {
  const { webhookTitle } = props;
  return (
    <div>
      <Header>{webhookTitle}</Header>
      <Hr />
    </div>
  );
};

export default WebhookCardTitle;
