import casing from 'casing';

function hasErrors(errors) {
  return errors && Object.keys(errors).length;
}

function validateValueByRule(value, rule, propertyName) {
  if (!rule.isValid(value, propertyName)) {
    return rule.message(value);
  }
  return null;
}

function validateNestedFieldByRules(propertyName, propertyValue, rules) {
  if (!rules) {
    return [];
  }
  return rules.map(rule => validateValueByRule(propertyValue, rule, propertyName)).filter(x => x);
}

function validateFieldByRules(propertyName, propertyValue, rules) {
  if (!rules[propertyName]) {
    return [];
  }
  return rules[propertyName].map(rule => validateValueByRule(propertyValue, rule, propertyName)).filter(x => x);
}

function validateNestedForm(value, rules, fieldName) {
  if (!rules) {
    return undefined;
  }
  // value, [required()], 'key1'
  if (Array.isArray(rules)) {
    // validate object properties
    const propRules = rules.filter(x => !x.arrayItemRules);
    const propErrors = validateNestedFieldByRules(fieldName, value, propRules);
    if (hasErrors(propErrors)) {
      return propErrors;
    }

    // validate array items if there are any 'validateEach()' rules
    const arrayItemValidator = rules.find(x => x.arrayItemRules);
    if (arrayItemValidator && value) {
      const arrayItemErrors = value.map((v) => {
        const itemErrors = validateNestedForm(v, arrayItemValidator.arrayItemRules, fieldName);
        return hasErrors(itemErrors) ? itemErrors : undefined;
      });
      return arrayItemErrors.some(x => hasErrors(x)) ? arrayItemErrors : undefined;
    }

    return undefined;
  }
  // {key1: value1}, {key1: [required()]}, null
  return Object.keys(rules)
    .reduce((acc, key) => {
      const rule = rules[key];
      const v = value ? value[key] : undefined;
      const errors = validateNestedForm(v, rule, key);
      if (hasErrors(errors)) {
        acc[key] = errors;
      }
      return acc;
    }, {});
}

function validateForm(validationRules, state) {
  const errorsObj = {
    hasErrors: false,
  };
  Object.keys(validationRules).forEach((property) => {
    const propertyValue = state[property];
    const errors = validateFieldByRules(property, propertyValue, validationRules);

    if (errors && errors.length > 0) {
      errorsObj.hasErrors = true;
      errorsObj[property] = errors;
    }
  });

  return errorsObj;
}

function getAllTouched(value, rules) {
  if (Array.isArray(value)) {
    return value.map(v => getAllTouched(v, rules));
  }
  if (typeof value === 'object' && value !== null) {
    return Object.keys(rules)
      .reduce((acc, key) => {
        acc[key] = getAllTouched(value[key], rules[key]);
        return acc;
      }, {});
  }

  return true;
}

function getRefToErrorElement(errors, refs) {
  if (Array.isArray(errors)) {
    if (errors.every(x => typeof x === 'string')) {
      return refs;
    }
    for (let i = 0; i < errors.length; i += 1) {
      const ref = getRefToErrorElement(errors[i], refs[i]);
      if (ref) {
        return ref;
      }
    }
  }
  if (typeof errors === 'object' && errors !== null) {
    const keys = Object.keys(errors);
    for (let i = 0; i < keys.length; i += 1) {
      const ref = getRefToErrorElement(errors[keys[i]], refs[keys[i]]);
      if (ref) {
        return ref;
      }
    }
  }
  return undefined;
}

function getServerValidationErrors(response) {
  const result = {};

  if (response?.json?.details) {
    return response.json.details.reduce((acc, x) => {
      const property = (acc[casing.camelize(x.property)] || []);
      property.push(x.message);
      acc[casing.camelize(x.property)] = property;
      return acc;
    }, result);
  }

  return result;
}

export {
  validateForm,
  validateNestedForm,
  validateFieldByRules,
  validateValueByRule,
  getAllTouched,
  getRefToErrorElement,
  hasErrors,
  getServerValidationErrors,
};
