import API from 'helpers/API';
import {
  getPreparedTrackingPageSummaries,
  getPreparedTrackingPage,
} from '../helpers/trackingPage/prepareTrackingPagesToFrontEnd';
import {
  getPreparedTrackingPageDataForBE,
} from '../helpers/trackingPage/prepareTrackingPagesToBackEnd';

import {
  TRACKING_PAGES_FETCH,
  TRACKING_PAGES_FETCH_SUCCESS,
  TRACKING_PAGE_FETCH,
  TRACKING_PAGE_FETCH_SUCCESS,
  UPDATE_TRACKING_PAGE_FETCH,
  UPDATE_TRACKING_PAGE_SUCCESS,
  CREATE_TRACKING_PAGE_FETCH,
  CREATE_TRACKING_PAGE_SUCCESS,
  DELETE_TRACKING_PAGE_FETCH,
  DELETE_TRACKING_PAGE_SUCCESS,
  CREATE_TRACKING_PAGE_ERROR,
  UPDATE_TRACKING_PAGE_ERROR,
  TRACKING_PAGE_RESET,
} from './types';

export function getTrackingPage(trackingPageName, version) {
  return (dispatch) => {
    dispatch({ type: TRACKING_PAGE_FETCH });
    return API.getTrackingPage(trackingPageName, version)
      .then((response) => {
        const trackingPage = getPreparedTrackingPage(response);
        dispatch({
          type: TRACKING_PAGE_FETCH_SUCCESS,
          trackingPage,
        });
      });
  };
}

export function getTrackingPages() {
  return (dispatch) => {
    dispatch({ type: TRACKING_PAGES_FETCH });
    return API.getTrackingPages()
      .then((response) => {
        const trackingPagesList = getPreparedTrackingPageSummaries(response.tracking_page_list);
        dispatch({
          type: TRACKING_PAGES_FETCH_SUCCESS,
          trackingPages: trackingPagesList,
        });
      });
  };
}

export function createNewTrackingPage(trackingPageName, version, publishedOn, trackingPageData, invalidTrackingRefPageOverride) {
  return (dispatch) => {
    dispatch({ type: CREATE_TRACKING_PAGE_FETCH });
    const preparedTrackingPageForBE = getPreparedTrackingPageDataForBE(trackingPageData);
    return API.createNewTrackingPage(trackingPageName, version, publishedOn, preparedTrackingPageForBE, invalidTrackingRefPageOverride)
      .then((response) => {
        const createdTrackingPage = getPreparedTrackingPage(response);
        dispatch({
          type: CREATE_TRACKING_PAGE_SUCCESS,
          trackingPage: createdTrackingPage,
        });
      })
      .catch((errorResponse) => {
        dispatch({
          type: CREATE_TRACKING_PAGE_ERROR,
          errorResponse,
        });
        return Promise.reject(errorResponse);
      });
  };
}

export function updateTrackingPage(trackingPageName, version, publishedOn, trackingPageData, invalidTrackingRefPageOverride) {
  return (dispatch) => {
    dispatch({ type: UPDATE_TRACKING_PAGE_FETCH });
    const preparedTrackingPageForBE = getPreparedTrackingPageDataForBE(trackingPageData);
    return API.updateTrackingPage(trackingPageName, version, publishedOn, preparedTrackingPageForBE, invalidTrackingRefPageOverride)
      .then((response) => {
        const editedTrackingPage = getPreparedTrackingPage(response);
        dispatch({
          type: UPDATE_TRACKING_PAGE_SUCCESS,
          trackingPage: editedTrackingPage,
        });
      })
      .catch((errorResponse) => {
        dispatch({
          type: UPDATE_TRACKING_PAGE_ERROR,
          error: errorResponse,
        });
        return Promise.reject(errorResponse);
      });
  };
}

export function deleteTrackingPage(trackingPageName) {
  return (dispatch) => {
    dispatch({ type: DELETE_TRACKING_PAGE_FETCH });
    return API.deleteTrackingPage(trackingPageName)
      .then(() => {
        dispatch({ type: DELETE_TRACKING_PAGE_SUCCESS, trackingPageName });
        return Promise.resolve();
      });
  };
}

export function resetTrackingPage() {
  return (dispatch) => {
    dispatch({ type: TRACKING_PAGE_RESET });
  };
}
