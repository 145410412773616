import React from 'react';
import { event as gaEvent } from 'react-ga';
import getSinkTypeName from './getSinkTypeName';
import styles from '../styles.scss';

function NotificationConnectorCard({
  notificationConnector: {
    id,
    name,
    type,
    logoUrl,
  },
  history,
}) {
  return (
    <div className={styles.container}>
      <h3 className={`textCenter subtitle1 ${styles.cardTitle}`}>{name}</h3>
      <p className={`textCenter ${styles.cardCopy}`}>{getSinkTypeName(type)}</p>
      <div className={styles.logoHolder}>
        <img
          className={styles.logo}
          src={logoUrl}
          alt=""
        />
      </div>
      <div>
        <button
          type="button"
          name="create"
          className={styles.cardButton}
          onClick={() => {
            gaEvent({
              category: 'Notifications',
              action: 'Notification Connector Create',
            });
            history.push(`/settings/notification-centre/notification-configuration/create/${id}`);
          }}
        >
          Connect
        </button>
      </div>
    </div>
  );
}

export default NotificationConnectorCard;
