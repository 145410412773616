import React from 'react';
import classNames from 'classnames';
import styles from './styles.scss';
import BackButton from '../BackButton';

const TitleNavBlock = ({ onBack, title, subTitle }) => {
  const titleClasses = classNames('h2', styles.title);

  return (
    <div>
      <BackButton onBack={onBack} />
      <h1 className={titleClasses}>{title}</h1>
      <div className={styles.subTitle}>{subTitle}</div>
    </div>
  );
};

export default TitleNavBlock;
