import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { event as gaEvent } from 'react-ga';
import ColouredDot from 'components/ColouredDot';
import {
  closePopup as closePopupAction,
  showPopup as showPopupAction,
} from 'actions';
import {
  deleteShipmentFilter,
} from 'actions/shipmentFiltersActions';
import DualButtons from 'components/DualButtons';
import Chip from 'components/Chip';
import styles from './cardFront.scss';

class CardFront extends Component {
  handleDelete = (id) => {
    this.props.showPopup({
      title: 'Are you sure?',
      description: <span>Revoking of Shipment Filter <u>deletes</u> it completely.</span>,
      onAccept: () => this.props.deleteShipmentFilter(id)
        .then(() => {
          gaEvent({
            category: 'ShipmentFilters',
            action: 'Deleted shipment filter',
          });
          this.props.closePopup();
        })
        .catch((err) => {
          if (err.status === 400) {
            this.handleFilterInUseError();
            return Promise.resolve();
          }
          return Promise.reject(err.json);
        }),
      onDecline: () => {
        this.props.closePopup();
      },
    });
  };

  handleFilterInUseError = () => {
    this.props.showPopup({
      title: 'Shipment filter in use',
      description: 'Cannot complete operation as shipment filter is being used by one or more webhooks.',
      onDecline: () => this.props.closePopup(),
    });
  };

  renderStates = () => {
    const { states, calculated_events: calcEvents } = this.props.cardData;
    const maxLines = calcEvents && calcEvents.length > 0 ? 2 : 3;

    return (states && states.length > 0
      && (
      <>
        <p>Shipment States selected</p>
        <div className={styles.frontPillColumnHolder}>
          {states.slice(0, maxLines)
            .map(s => (
              <div className={styles.frontPillDiv} key={s.state}>
                <Chip theme="dark" icon={<ColouredDot theme={s.category} />} label={s.name} />
              </div>
            ))}
          {states.length > maxLines && (
          <div className={styles.frontPillDiv} key="extraStates">
            <p>+{states.length - maxLines}</p>
          </div>
          )}
        </div>
      </>
      ));
  };

  renderCalcEvents = () => {
    const {
      cardData: { calculated_events: calcEvents },
      availableCalculatedEvents,
    } = this.props;
    return (calcEvents && calcEvents.length > 0
      && (
      <>
        <p>Calculated Events selected</p>
        <div className={styles.frontPillRowHolder}>
          {calcEvents && calcEvents.length > 0
          && calcEvents.map(e => (
            <div className={styles.frontPillDiv} key={e}>
              <Chip theme="dark" label={availableCalculatedEvents.find(x => x.id === e).name} />
            </div>
          ))}
        </div>
      </>
      ));
  };

  render() {
    const {
      cardData,
      flipCard,
    } = this.props;

    return (
      <div className={cx(styles.container, 'break-word')}>
        <h4>{cardData.name}</h4>
        {this.renderStates()}
        {this.renderCalcEvents()}
        <div className={styles.buttonGroup}>
          <DualButtons
            rightFunction={() => flipCard()}
            rightText="Edit"
            leftFunction={() => this.handleDelete(cardData.id)}
            leftText="Delete"
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  deleteShipmentFilter: id => dispatch(deleteShipmentFilter(id)),
  showPopup: config => dispatch(showPopupAction(config)),
  closePopup: () => dispatch(closePopupAction()),
});

export default connect(state => ({
  availableCalculatedEvents: state.availableCalculatedEvents.values,
}), mapDispatchToProps)(CardFront);
