import React, { useState } from 'react';
import Switch from 'components/Switch';
import DualButtons from 'components/DualButtons';
import { event as gaEvent } from 'react-ga';
import styles from '../styles.scss';
import Popup from '../../../../components/Layout/Popup';

export default function NotificationCard({
  disconnect, history, notification: {
    id, isActive, name, sinkUrl,
  }, toggleActive,
}) {
  const [popup, setPopup] = useState({});
  const [disconnecting, setDisconnecting] = useState(false);

  const promptDisconnect = async () => {
    setPopup({
      open: true,
      title: 'Are you sure?',
      description: <span>The notification cannot be recovered once disconnected.</span>,
      onAccept: async () => {
        setDisconnecting(true);
        try {
          disconnect(id);
          setPopup({ open: false });
        } finally {
          setDisconnecting(false);
        }
      },
      onDecline: () => setPopup({ open: false }),
    });
  };
  return (
    <div className={styles.container}>
      <Popup
        open={popup.open}
        title={popup.title}
        description={popup.description}
        onAccept={popup.onAccept}
        onDecline={popup.onDecline}
      />
      <h3 className={`textCenter subtitle1 ${styles.cardTitle}`}>{name}</h3>
      <div className={styles.logoHolder}>
        <img
          className={styles.logo}
          src={sinkUrl}
          alt=""
        />
      </div>
      <div className={styles.switcherHolder}>
        <Switch
          id={name}
          enabled={isActive}
          toggle={() => toggleActive(id)}
        />
      </div>
      <DualButtons
        rightFunction={() => promptDisconnect()}
        rightText="Disconnect"
        rightFunctionDisabled={disconnecting}
        leftFunction={() => {
          gaEvent({
            category: 'Notifications',
            action: 'Notification Edit',
          });
          history.push(`/settings/notification-centre/notifications/edit/${id}`);
        }}
        leftText="Edit"
      />
    </div>
  );
}
