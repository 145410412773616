const initialState = {
  groups: [],
  isLoading: false,
};

export default function shipmentFiltersReducer(state = initialState, action) {
  switch (action.type) {
    case 'SHIPMENT_FILTERS_FETCH':
      return {
        ...state,
        groups: [],
        isLoading: true,
      };
    case 'SHIPMENT_FILTERS_SUCCESS':
      return {
        ...state,
        groups: action.groups,
        isLoading: false,
      };
    default:
      return state;
  }
}
