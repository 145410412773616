import React, { Component } from 'react';
import { func, shape, string } from 'prop-types';
import DualButtons from 'components/DualButtons';
import InputWithValidation from 'components/InputWithValidation';
import { required, maxLength } from 'helpers/validate';
import { validateForm } from 'helpers/validateForm';
import {
  ShipmentFilterNameValidationMessage,
} from 'constants/messagesAndDescriptions';
import { event as gaEvent } from 'react-ga';
import Hr from 'components/CardShell/Hr';
import Header from 'components/CardShell/Header';
import styles from './styles.scss';

const validationRules = {
  name: [
    required(),
    maxLength(
      50,
      () => ShipmentFilterNameValidationMessage,
    ),
  ],
};

class NameGroup extends Component {
  static propTypes = {
    formData: shape({
      name: string,
    }).isRequired,
    updateState: func.isRequired,
    gotoPage: func.isRequired,
    cancel: func.isRequired,
    shipmentFilterTitle: string.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      errors: null,
    };
  }

  handleNextButtonClick = () => {
    const errors = validateForm(validationRules, this.props.formData);
    if (errors.hasErrors) {
      this.setState({ errors });
      return;
    }

    this.props.gotoPage(1);
  };

  render() {
    const {
      formData,
      updateState,
      cancel,
      pageTabs,
      shipmentFilterTitle,
    } = this.props;

    return (
      <div className={styles.cardsection}>
        <div className={styles.cardSectionInner}>
          <Header>{shipmentFilterTitle}</Header>
          <Hr />
          {pageTabs()}
          <InputWithValidation
            label="Shipment Filter Name"
            placeholder="Shipment Filter name"
            type="text"
            value={formData.name}
            name="name"
            onChange={(event) => {
              gaEvent({
                category: 'ShipmentFilters',
                action: 'Name',
              });
              updateState({ name: event.target.value });
            }}
            errors={this.state.errors}
            required
          />
        </div>
        <DualButtons
          leftFunction={() => cancel()}
          leftText="Cancel"
          rightFunction={this.handleNextButtonClick}
          rightText="Next"
          theme="rightPink"
          rightFunctionDisabled={formData.name.length < 1}
        />
      </div>
    );
  }
}

export default NameGroup;
