import React from 'react';
import { event as gaEvent } from 'react-ga';
import CardShell from 'components/CardShell';
import ConnectorIcon from 'images/notificationConnector.svg';
import SetupIcon from 'images/notificationSetup.svg';
import TemplateIcon from 'images/notificationTemplate.svg';
import styles from './styles.scss';
import TitleNavBlock from '../../../components/TitleNavBlock/TitleNavBlock';
import typography from '../../../helpers/appTypography';

function NotificationCentre(props) {
  return (
    <div>
      <TitleNavBlock
        title={typography.notifications.title}
        subTitle={typography.notifications.subTitle}
        onBack={() => window.history.back()}
      />
      <div className={styles.outerWhiteContainer}>
        <div className="container__cards-large">
          <CardShell>
            <div className={styles.container}>
              <h3 className={`textCenter subtitle1 ${styles.cardTitle}`}>Notification Connectors</h3>
              <div className={styles.iconInnerContainer}>
                <ConnectorIcon className={styles.notificationConnectorsIcon} />
              </div>
              <div>
                <button
                  type="button"
                  name="edit"
                  className={styles.cardButton}
                  onClick={() => {
                    gaEvent({
                      category: 'Notifications',
                      action: 'Notification Connectors',
                    });
                    props.history.push('/settings/notification-centre/notification-connectors');
                  }}
                >
                  Edit
                </button>
              </div>
            </div>
          </CardShell>
          <CardShell>
            <div className={styles.container}>
              <h3 className={`textCenter subtitle1 ${styles.cardTitle}`}>Notification Templates</h3>
              <div className={styles.iconInnerContainer}>
                <TemplateIcon className={styles.notificationSetupIcon} />
              </div>
              <div>
                <button
                  type="button"
                  name="edit"
                  className={styles.cardButton}
                  onClick={() => {
                    gaEvent({
                      category: 'Notifications',
                      action: 'Notification Templates',
                    });
                    props.history.push('/settings/notification-centre/templates');
                  }}
                >
                  Edit
                </button>
              </div>
            </div>
          </CardShell>
          <CardShell>
            <div className={styles.container}>
              <h3 className={`textCenter subtitle1 ${styles.cardTitle}`}>Notification set up</h3>
              <div className={styles.iconInnerContainer}>
                <SetupIcon className={styles.notificationSetupIcon} />
              </div>
              <div>
                <button
                  type="button"
                  name="edit"
                  className={styles.cardButton}
                  onClick={() => {
                    gaEvent({
                      category: 'Notifications',
                      action: 'Notification Setup',
                    });
                    props.history.push('/settings/notification-centre/notification-setup');
                  }}
                >
                  Edit
                </button>
              </div>
            </div>
          </CardShell>
        </div>
      </div>
    </div>
  );
}

export default NotificationCentre;
